import { useSelector } from "react-redux";
import "./WidgetConfirm.scss";
import "./WidgetCategory.scss";
import {
  PieChartIcon,
  BarChartIcon,
  StatusProgressIcon,
  DoughnutCharIcon,
  CircleProgressBarIcon,
  AreaChartIcon,
} from "../../../../assets";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import CustomCheckBox from "../../CustomCheckBox/CustomCheckBox";

const getWidget = (title) => {
  switch (title) {
    case "area":
      return { title: "Area Chart", Icon: <AreaChartIcon /> };
    case "polar":
      return { title: "Polar Chart", Icon: <PieChartIcon /> };
    case "bar":
      return { title: "Bar Chart", Icon: <BarChartIcon /> };
    case "progress":
      return { title: "Progress Chart", Icon: <CircleProgressBarIcon /> };
    case "doughnut":
      return { title: "Doughnut Chart", Icon: <DoughnutCharIcon /> };
    case "horizontal-progress":
      return {
        title: "Horizontal Progress Chart",
        Icon: <StatusProgressIcon />,
      };
    default:
      return { title: "TickIcon", Icon: <TickIcon /> };
  }
};

const WidgetConfirm = ({ selectedType, widgetInformation }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const widgetConfig = getWidget(selectedType);

  return (
    //main div container
    <div
      className={`widget-confirm-container${isDarkMode ? " dark-mode" : ""} `}
    >
      <div className="widget-confirm">
        <div className="widget-title">Widget Information</div>
        {/* WidgetCategory style with classname widget-category-container Do not remove this clsname*/}
        <div className="widget-category-container">
          <div className="widget-category">
            <div className="widget-content">
              {Object.entries(widgetInformation).map(([key, value], index) => (
                <CustomCheckBox
                  key={index}
                  checked={true}
                  disabled={true}
                  name={`${key}: ${value}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="widget-confirm">
        <div className="widget-title">Widget Option</div>

        <div className="widget-chart">
          <div className="chart-title">{widgetConfig.title}</div>
          <div className="chart-icon">{widgetConfig.Icon}</div>
        </div>
      </div>
    </div>
  );
};
export default WidgetConfirm;
