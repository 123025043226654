export const citations = (updatedDynamicStates, IdHandler, programFields) => {
  const citationStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    citationStatus: () => ({
      title: "Safety Citation Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Citations",
        field: "citationStatus",
        filterType: { type: "dashboardId" },
        noTab: true,
        secondaryField: "citationName",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.citationName || "Save Draft",
            value: a?.citationStatus,
            color:
              citationStatus?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.citationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.citationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    citationName: () => ({
      title: "Safety Citation Name",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Citations",
        field: "citationName",
        filterType: { type: "dashboardId" },
        noTab: true,
        secondaryField: "citationStatus",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.citationName || "Save Draft",
            value: a?.citationStatus,
            color:
              citationStatus?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.citationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.citationId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),

    employeeName: () => ({
      title: "Safety Citations Employee",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Citations",
        field: "employeeName",
        filterType: { type: "dashboardId" },
        secondaryField: "citationObject.employeeName",
        pathname: "/accounts",
        id: IdHandler,
        statusData: (updatedDynamicStates?.[IdHandler] || [])?.map((a) => {
          return {
            label: a?.citationObject?.employeeName,
            value: a?.citationStatus,
            color:
              citationStatus?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.citationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
            date: a?.createdAt,
            id: a?.employeeId,
          };
        }),
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
