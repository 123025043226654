import "./CardView.scss";

import {
  AbsoluteStepper,
  Address,
  DateRange,
  Information,
  List,
  Live,
  LongText,
  Members,
  MultiBox,
  Progress,
  ProgressBar,
  LineChartFull,
  Small,
  Step,
  Transfer,
  Upload,
  DobCard,
  TeamsCard,
  UserLink,
  FleetItems,
  LaborCard,
  WorkOrderItems,
  DynamicUsers,
  ViolationPieChart,
  AssignProjectGrid,
  DriverAssign,
  InactivityCard,
  ManufacturerCard,
} from "../../cardComponent";
import { ActionsIcon, CopyIcon } from "../../src";
import { Popover, Tag, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import ActionComponent from "./ActionComponent/ActionComponent";
import BarChart from "../../cardComponent/BarChart/BarChart";
import BarChartFull from "../../cardComponent/BarChartFullscreen/BarChartFullScreen";
import CircleProgresBar from "../../cardComponent/ProgressBar/CircleProgressBar";
import EditCard from "../EditCard/EditCard";
import FilterView from "../FilterView/FilterView";
import LineChart from "../../cardComponent/LineChart/LineChart";
import LongLineSteper from "../../cardComponent/LongLineSteper/LongLineSteper";
import Pie from "../../cardComponent/Pie/Pie";
import StreightProgressBar from "../../cardComponent/ProgressBar/StreightProgressBar";
import EstimationProgressOverview from "../../cardComponent/EstimationProgressOverview/EstimationProgressOverview";
import { API } from "aws-amplify";
import { programFields } from "../../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { FilterFilled } from "@ant-design/icons";
import { compareObjects } from "../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import ChangeChartType from "./ActionComponent/ChangeChartType";
import dayjs from "dayjs";
import { useCallback } from "react";

const initialFilterStates = {
  filterByDay: null,
  filterByRange: null,
  filterByTime: "This Month",
  filterByName: null,
  filterByStatus: null,
  filterByProjectManager: null,
  filterByPercentage: null,
};

const getProgramFields = (dispatch) => {
  API.get("programFields", "/programFields").then((res) => {
    dispatch(programFields(res));
  });
};

/**
 * Renders a card view component.
 *
 * /*Needed props for a new Overview Card
 * @param {string} title - The title of the card.
 * @param {React.Component} BodyComponent - The body component of the card.
 * @param {Object} BodyComponentParams - The parameters for the body component.
 * @param {string} id - The ID of the card.
 * @param {Object} preferences - Current user preferences.
 * @param {requestCallback} getEditedValue - The function to get the edited value. Not used in the Dashboard Cards.
 * @param {string} type - The type of the card.
 * @param {boolean} disableDrag - Determines if the card dragging is disabled.
 * @param {string} dataView - The data view of the card. Not used in the Dashboard Cards.
 * @param {function} setDataView - The function to set the data view. Not used in the Dashboard Cards.
 * @param {boolean} [isFilter=true] - Determines if the card has a filter.
 * @param {boolean} editable - Determines if the card is editable. Not used in the Dashboard Cards.
 * @param {function} setPreferences - The function to set the preferences.
 * @param {function} [setChanged=()=>{}] - The function to set the changed state. @argument {boolean} [value]
 * @returns {JSX.Element} The rendered CardView component.
 *
 *
 *Dashboard Card Props (also needs the props from the Overview Card)
 * @param {string} headerLabel - The header label of the card.
 * @param {string} [width="735px"] - The width of the card.
 * @param {boolean} allowHide - Determines if the card can be hidden.
 * @param {boolean} isEditable - Determines if the card is editable.
 * @param {Object} dynamicStates - The dynamic states of the card.
 * @param {function} setDynamicStates - The function to set the dynamic states.
 * @param {Object} updatedDynamicStates - The updated dynamic states of the card.
 * @param {function} setUpdatedDynamicStates - The function to set the updated dynamic states.
 * @param {Object} rowData - The row data of the card.
 * @param {function} setRowData - The function to set the row data.
 * @param {Object} programFields - The program fields from Redux.
 * @param {array} projectsData - projects table data.
 * @param {array} dispatchData - dispatch table data.
 * @param {array} fleetData - fleet table data.
 * @param {array} inspectionsData - inspections table data.
 * @param {array} maintenanceData - maintenance table data.
 * @param {array} archData - arch table data.
 * @param {array} engData - eng table data.
 * @param {array} estimationsData - The estimations table data.
 * @param {number} currentStep - The current step of the card.
 * @param {Array} hiddenCards - The hidden cards.
 * @param {function} setHiddenCards - The function to set the hidden cards.
 * @param {object} layout - The layout of the cards.
 * @param {function} setLayout - The function to set the layout when changed.
 * @param {Object} statesData - ??
 * @param {function} setStatesData - The function to set the states data.
 * @param {number} widgetRepetition - ??
 * @param {function} setWidgetRepetition - The function to set the widget repetition.
 * @param {function} setCardFilterPreferences - The function to set the card filter preferences.
 * @param {array} cardFilterPreferences - ??
 * @param {Object} tables - DB tables categorized
 * @param {Object} allFilterStates - The filter states of all cards.
 * @param {function} setAllFilterStates - The function to set the filter states of all cards.
 * @param {boolean} blur - Determines if the card is blurred.
 * @param {array} dispatchTable - The dispatch table data.
 * @returns {JSX.Element} The rendered CardView component.
 */
const CardView = ({
  title,
  BodyComponent,
  headerLabel,
  BodyComponentParams,
  id,
  width = "735px",
  dataView,
  allowHide,
  setDataView,
  isEditable,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type,
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // defectsData,
  // workOrdersData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  estimationsData,
  getEditedValue,
  currentStep,
  editable,
  disableDrag,
  isFilter = true,
  hiddenCards,
  setHiddenCards,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setPreferences,
  preferences,
  setCardFilterPreferences,
  cardFilterPreferences,
  tables,
  allFilterStates,
  setAllFilterStates,
  blur,
  setChanged = () => {},
  dispatchTable,
  descriptionLog = false,
}) => {
  const url = window.location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [collapsed, setCollapsed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chartTypeModal, setChartTypeModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [edited, setEdited] = useState();
  const [childParams, setChildParams] = useState(BodyComponentParams);
  const [isEditeble, setIsEditable] = useState(editable);
  const [actionVisible, setActionVisible] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  // //this holds the current state for each filter field
  const [filterStates, setFilterStates] = useState(
    BodyComponentParams?.filterStates || initialFilterStates
  );
  const [clearFilterChart, setClearFilterChart] = useState("");

  useEffect(() => {
    setChildParams(BodyComponentParams);
  }, [JSON.stringify(BodyComponentParams)]);

  const handleClickOutside = (event) =>
    event.target.className === "ActionElement" &&
    setTimeout(() => {
      setActionVisible(false);
    }, 200);

  document.addEventListener("mousedown", handleClickOutside);

  const onClick = () => !isEditable && setCollapsed(!collapsed);

  const setEditedData = (e, b, c, prev, next) => {
    setEdited(b || e);
    getEditedValue(
      keys?.(e)?.map((el) => ({ key: el, value: e[el] })),
      title,
      keys?.(c)?.map((ele) => {
        const label = childParams[keys(childParams)[0]]?.length
          ? childParams[keys(childParams)[0]].find(
              ({ formItemName }) => formItemName === ele
            )?.label
            ? childParams[keys(childParams)[0]].find(
                ({ formItemName }) => formItemName === ele
              )?.label
            : title
          : title;
        const Inputtype =
          childParams[keys(childParams)[0]]?.length &&
          childParams[keys(childParams)[0]].find(
            ({ formItemName }) => formItemName === ele
          )?.type === "DatePicker";
        return {
          key: ele,
          value: c[ele],
          label: label,
          type: Inputtype,
        };
      }),
      prev,
      next
    );
  };

  const alternativeAddresses = () => (
    <>
      {childParams &&
        childParams?.coordinates?.map((e, i) => (
          <div className="CardViewHeaderLabel" key={i}>
            <span
              className="headerIcon"
              onClick={() => {
                let value = document.getElementById(
                  `headerLabel${i}`
                ).innerText;
                navigator.clipboard.writeText(value);
                message.success("Copied");
              }}
            >
              <CopyIcon />
            </span>
            <span className="headerlabel" {...{ id: `headerLabel${i}` }}>
              {e.address}
            </span>
          </div>
        ))}
    </>
  );

  const setNewBodyComponentParams = (e) => {
    setChildParams(state(e));
  };

  useEffect(() => {
    editMode === false && disableDrag && disableDrag(false);
  }, [editMode]);

  //edit card title
  const [editingCardTitle, setEditingCardTitle] = useState(false);

  const handleSaveEditedValue = (idx, editedCardTitle) => {
    message.info("Editing Title...");

    const categoryConfig = preferences?.widgetConfiguration?.[url];
    setEditingCardTitle(true);
    if (!categoryConfig) {
      console.error(`Category '${url}' not found in the configuration.`);
      return { ...preferences };
    }

    const updatedCharts = categoryConfig.map((chart) => {
      if (chart.id === idx) {
        return { ...chart, title: editedCardTitle };
      }
      return chart;
    });

    const updatedPreferences = {
      ...preferences,
      widgetConfiguration: {
        ...preferences.widgetConfiguration,
        [url]: updatedCharts,
      },
    };
    API.patch(
      "programFields",
      `/programFields/34e50c41-cdad-4fe5-8d39-7b88a9d9f705`,
      {
        body: {
          fieldOptions: updatedCharts,
        },
      }
    ).then((res) => {
      if (res.status === true) {
        getProgramFields(dispatch);
        message.success("Saved Successfuly");
        setPreferences(updatedPreferences);
        setEditingCardTitle(false);
      } else {
        message.error("There was a problem Saving Data");
      }
    });
    API.patch("preferences", "/preferences", {
      body: {
        defaultConfiguration: updatedPreferences.defaultConfiguration,
        widgetConfiguration: updatedPreferences.widgetConfiguration,
        widgetRepetition: updatedPreferences.widgetRepetition,
      },
    }).catch((err) => console.log({ err }));
  };

  useEffect(() => {
    const filterStateToUse = !!allFilterStates?.[id]
      ? allFilterStates?.[id]
      : filterStates;

    // const findedNonEmptyFilterValue = Object.values(filterStateToUse)
    //   .filter((value) => value && value !== "Pick Range")
    //   .map((value) => {
    //     if (typeof value === "string") {
    //       return value;
    //     } else if (!Array?.isArray(value)) {
    //       return value?.format("LL");
    //     } else {
    //       return (
    //         value
    //           .map((val) => new Date(val).toLocaleDateString("en-US"))
    //           .join(" - ") || ""
    //       );
    //     }
    //   });

    /**
     * filterByDay: null,
     *  filterByRange: null,
     * filterByTime: "This Month",
     *filterByName: null,
     *filterByStatus: null,
     *filterByProjectManager: null,
     *filterByPercentage: null,
     */

    const stateFilterValues = Object.keys(filterStateToUse)
      .map((key) => {
        if (key === "filterByRange") {
          if (
            Array.isArray(filterStateToUse?.filterByRange) &&
            filterStateToUse?.filterByRange.every((el) => dayjs(el).isValid())
          ) {
            return `${dayjs(filterStateToUse?.filterByRange[0]).format(
              "MM/DD/YYYY"
            )} -
           ${dayjs(filterStateToUse?.filterByRange[1]).format("MM/DD/YYYY")}`;
          }
        } else if (key === "filterByDay") {
          if (dayjs(filterStateToUse?.filterByDay).isValid()) {
            return dayjs(filterStateToUse?.filterByDay).format("MM/DD/YYYY");
          }
        } else if (key === "filterByTime") {
          if (filterStateToUse?.filterByTime !== "Pick Range") {
            return filterStateToUse?.filterByTime;
          }
        } else if (!!key) {
          return filterStateToUse?.[key];
        }
      })
      ?.filter(Boolean);

    // let tmpFilterValue = findedNonEmptyFilterValue
    //   ? Array.isArray(findedNonEmptyFilterValue)
    //     ? findedNonEmptyFilterValue
    //     : !Array.isArray(findedNonEmptyFilterValue)
    //     ? findedNonEmptyFilterValue.format("LL")
    //     : findedNonEmptyFilterValue.map((val) => val.format("LL")).join(" - ")
    //   : "";

    if (stateFilterValues?.length === 0 && !clearFilterChart) {
      setFilterValue(["This Month"]);
    } else if (clearFilterChart === id) {
      setFilterValue([]);
    } else {
      setFilterValue(stateFilterValues);
      setClearFilterChart("");
    }
  }, [filterStates, allFilterStates]);

  useEffect(() => {
    if (!!blur) {
      document.getElementById(id).classList.remove("active");
      document.getElementById(id).classList.add("blur");
      document.getElementById("blur" + id).classList.add("blur");
    }

    if (!!BodyComponentParams?.filterStates) {
      // const { filterByTime, ...rest } = BodyComponentParams?.filterStates;
      if (
        !!compareObjects(
          BodyComponentParams?.filterStates,
          initialFilterStates
        ) &&
        !!compareObjects(filterStates, initialFilterStates)
      ) {
        setFilterStates(initialFilterStates);
      } else if (!!compareObjects(filterStates, initialFilterStates)) {
        setFilterStates(BodyComponentParams?.filterStates);
      }
    }
  }, [BodyComponentParams?.filterStates, !!blur]);

  return (
    <>
      <div
        key={id}
        id={id}
        style={{ width }}
        className={`${
          !collapsed ? "CardViewComponent" : "CardViewComponentCollapsed"
        } ${isDarkMode && "CardViewComponentDark"}`}
      >
        <div className="CardViewComponentHeader" id={`${id}-header`}>
          <span className="CardViewComponentHeaderTitle" onClick={onClick}>
            {title}
            {url === "dashboard" &&
              filterValue &&
              filterValue?.map((filter) => {
                return (
                  <Tag
                    key={filter}
                    color="processing"
                    icon={<FilterFilled />}
                    style={{ marginLeft: "5px" }}
                  >
                    {filter}
                  </Tag>
                );
              })}
          </span>
          {headerLabel && childParams?.coordinates[0]?.address && (
            <Tooltip
              placement="top"
              color="white"
              // style={{ width: 300 }}
              overlayInnerStyle={{ width: "fit-content" }}
              title={alternativeAddresses()}
            >
              <div className="CardViewHeaderLabel">
                <span
                  className="headerlabel"
                  {...{ id: id === "address" ? "mainAddress" : "headerLabel" }}
                >
                  {childParams.coordinates[0].address}
                </span>
                <span
                  className="headerIcon"
                  onClick={() => {
                    let value = document.getElementById(
                      id === "address" ? "mainAddress" : "headerLabel"
                    ).innerText;
                    navigator.clipboard.writeText(value);
                    message.success("Copied");
                  }}
                >
                  <CopyIcon />
                </span>
              </div>
            </Tooltip>
          )}
          {!editingCardTitle ? (
            <Popover
              overlayClassName={`CardViewComponentHeaderActionWindow ${
                isDarkMode && "CardViewComponentHeaderActionWindowDark"
              }`}
              overlayStyle={{ borderRadius: 50 }}
              // visible={actionVisible}
              // onClick={(e) =>
              //   actionVisible === true
              //     ? setActionVisible(false)
              //     : setActionVisible(true)
              // }
              placement="bottomRight"
              trigger={"hover"}
              id={`action${id}`}
              content={() => (
                <ActionComponent
                  {...{
                    editingCardTitle,
                    setEditingCardTitle,
                    id,
                    dataView,
                    setDataView,
                    editMode,
                    handleSaveEditedValue,
                    setEditMode,
                    preferences,
                    allowHide,
                    setPreferences,
                    editable: isEditeble,
                    disableDrag,
                    filter: isFilter,
                    isModalVisible,
                    setIsModalVisible,
                    hiddenCards,
                    setHiddenCards,
                    rowData,
                    setRowData,
                    dynamicStates,
                    setDynamicStates,
                    layout,
                    setLayout,
                    statesData,
                    setStatesData,
                    setUpdatedDynamicStates,
                    actionVisible,
                    setActionVisible,
                    type,
                    widgetRepetition,
                    setWidgetRepetition,
                    isDarkMode,
                    updatedDynamicStates,
                    tables,
                    programFields,
                    chartTypeModal,
                    setChartTypeModal,
                    setCardFilterPreferences,
                    setChanged,
                    descriptionLog,
                  }}
                />
              )}
            >
              <span className="CardViewComponentHeaderActions">
                <ActionsIcon />
              </span>
            </Popover>
          ) : (
            ""
          )}
        </div>
        <div
          className={
            !collapsed
              ? "CardViewComponentBody"
              : "CardViewComponentBodyCollapsed"
          }
        >
          <BodyComponent
            {...{
              params: childParams,
              id,
              edited,
              setNewValues: setNewBodyComponentParams,
              setIsEditable,
              filterValue,
              descriptionLog,
            }}
          />
        </div>
      </div>
      {editMode &&
        EditCard &&
        EditCard[type]({
          id,
          title,
          dataView,
          setDataView,
          setRowData,
          params: childParams,
          setEditMode,
          getEditedValue: setEditedData,
          currentStep,
          descriptionLog,
        })}
      {isModalVisible &&
        FilterView[type]({
          isModalVisible,
          setIsModalVisible,
          title,
          id,
          params: childParams,
          BodyComponent: BodyComponent,
          dynamicStates,
          setDynamicStates,
          updatedDynamicStates,
          setUpdatedDynamicStates,
          type,
          rowData,
          setRowData,
          programFields,
          projectsData,
          dispatchData,
          fleetData,
          // claimsData,
          // incidentsData,
          // defectsData,
          // workOrdersData,
          inspectionsData,
          maintenanceData,
          archData,
          engData,
          estimationsData,
          setChildParams,
          childParams,
          filterStates,
          setFilterStates,
          clearFilterChart,
          setClearFilterChart,
          setCardFilterPreferences,
          preferences,
          setAllFilterStates,
          tables,
          dispatchTable,
          // isFilter
        })}
      {url === "dashboard" && !!chartTypeModal && (
        <ChangeChartType
          {...{
            rowData,
            id,
            updatedDynamicStates,
            setRowData,
            type,
            widgetRepetition,
            tables,
            programFields,
            setWidgetRepetition,
            preferences,
            layout,
            setLayout,
            setPreferences,
            visible: chartTypeModal,
            setVisible: setChartTypeModal,
            statesData,
            cardFilterPreferences,
            allFilterStates,
            filterStates,
            userConfiguration,
          }}
        />
      )}
    </>
  );
};
CardView.AbsoluteStepper = ({
  params,
  type,
  getEditedValue,
  setPreferences,
}) => <AbsoluteStepper setPreferences params={params} />;
CardView.CustomStatus = ({
  title,
  params,
  id,
  width,
  dataView,
  setDataView,
  getEditedValue,
  currentStep,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Small,
      BodyComponentParams: params,
      id,
      width,
      dataView,
      setDataView,
      type: "CustomStatus",
      getEditedValue,
      currentStep,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.EstimationProgressOverview = ({
  title,
  params,
  id,
  width,
  dataView,
  setDataView,
  getEditedValue,
  currentStep,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: EstimationProgressOverview,
      BodyComponentParams: params,
      id,
      width,
      dataView,
      setDataView,
      type: "EstimationProgressOverview",
      getEditedValue,
      currentStep,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);

CardView.TeamsCard = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  width,
  allowHide,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  rowData,
  setRowData,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: TeamsCard,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      width,
      allowHide,
      type: "TeamsCard",
      rowData,
      setRowData,
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.DynamicUsers = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  rowData,
  setRowData,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: DynamicUsers,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "DynamicUsers",
      rowData,
      setRowData,
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.Small = ({
  title,
  params,
  id,
  width,
  dataView,
  setDataView,
  getEditedValue,
  currentStep,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Small,
      BodyComponentParams: params,
      id,
      width,
      dataView,
      setDataView,
      type: "Small",
      getEditedValue,
      currentStep,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.Stepper = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Step,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "Stepper",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);

CardView.MultiBox = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: MultiBox,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "MultiBox",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.Upload = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Upload,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "Upload",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);

CardView.List = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: List,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "List",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);

CardView.Members = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Members,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "Members",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.Live = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Live,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "Live",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);

CardView.LongText = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
  descriptionLog,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: LongText,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "LongText",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
      descriptionLog,
    }}
  />
);

CardView.DobCard = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
  descriptionLog,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: DobCard,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "DobCard",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
      descriptionLog,
    }}
  />
);

CardView.Information = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Information,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "Information",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.DateRange = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: DateRange,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "DateRange",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);

CardView.Address = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Address,
      BodyComponentParams: { ...params, disableDrag },
      id,
      dataView,
      setDataView,
      type: "Address",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
    headerLabel
  />
);
CardView.Progress = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  isEditable,
  width,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "Progress",
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // defectsData,
  // workOrdersData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  estimationsData,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  hiddenCards,
  setHiddenCards,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setPreferences,
  preferences,
  setCardFilterPreferences,
  tables,
  allFilterStates,
  setAllFilterStates,
  cardFilterPreferences,
  blur,
  dispatchTable,
}) => (
  <CardView
    {...{
      preferences,
      setPreferences,
      title,
      BodyComponent: Progress,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      isEditable,
      width,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      // claimsData,
      // incidentsData,
      // defectsData,
      // workOrdersData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      estimationsData,
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      hiddenCards,
      setHiddenCards,
      layout,
      setLayout,
      statesData,
      setStatesData,
      widgetRepetition,
      setWidgetRepetition,
      setCardFilterPreferences,
      tables,
      allFilterStates,
      setAllFilterStates,
      cardFilterPreferences,
      blur,
      dispatchTable,
    }}
  />
);
CardView.Pie = ({
  preferences,
  title,
  params,
  id,
  width,
  isEditable,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "Pie",
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // defectsData,
  // workOrdersData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  estimationsData,
  isFilter,
  hiddenCards,
  setHiddenCards,
  setChildParams,
  childParams,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setPreferences,
  setCardFilterPreferences,
  tables,
  allFilterStates,
  setAllFilterStates,
  cardFilterPreferences,
  blur,
  dispatchTable,
}) => (
  <CardView
    {...{
      preferences,
      setPreferences,
      id,
      width,
      BodyComponent: Pie,
      BodyComponentParams: params,
      title,
      isEditable,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      // claimsData,
      // incidentsData,
      // defectsData,
      // workOrdersData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      estimationsData,
      isFilter,
      hiddenCards,
      setHiddenCards,
      setChildParams,
      childParams,
      layout,
      setLayout,
      statesData,
      setStatesData,
      widgetRepetition,
      setWidgetRepetition,
      setCardFilterPreferences,
      tables,
      allFilterStates,
      setAllFilterStates,
      cardFilterPreferences,
      blur,
      dispatchTable,
    }}
  />
);

CardView.BarChart = ({
  preferences,
  setPreferences,
  title,
  params,
  id,
  width,
  isEditable,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "BarChart",
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // defectsData,
  // workOrdersData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  estimationsData,
  isFilter,
  hiddenCards,
  setHiddenCards,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setCardFilterPreferences,
  tables,
  allFilterStates,
  setAllFilterStates,
  cardFilterPreferences,
  blur,
  dispatchTable,
}) => (
  <CardView
    {...{
      preferences,
      setPreferences,
      id,
      width,
      BodyComponent: BarChart,
      BodyComponentParams: params,
      title,
      isEditable,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      // claimsData,
      // incidentsData,
      // defectsData,
      // workOrdersData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      estimationsData,
      isFilter,
      hiddenCards,
      setHiddenCards,
      layout,
      setLayout,
      statesData,
      setStatesData,
      widgetRepetition,
      setWidgetRepetition,
      setCardFilterPreferences,
      tables,
      allFilterStates,
      setAllFilterStates,
      cardFilterPreferences,
      blur,
      dispatchTable,
    }}
  />
);

CardView.LineChartFull = ({
  preferences,
  setPreferences,
  title,
  params,
  id,
  width,
  isEditable,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "LineChartFull",
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // defectsData,
  // workOrdersData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  estimationsData,
  isFilter,
  hiddenCards,
  setHiddenCards,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setCardFilterPreferences,
  tables,
  cardFilterPreferences,
  blur,
  dispatchTable,
}) => (
  <CardView
    {...{
      preferences,
      setPreferences,
      id,
      width,
      BodyComponent: LineChartFull,
      BodyComponentParams: params,
      title,
      isEditable,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      // claimsData,
      // incidentsData,
      // defectsData,
      // workOrdersData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      estimationsData,
      isFilter,
      hiddenCards,
      setHiddenCards,
      layout,
      setLayout,
      statesData,
      setStatesData,
      widgetRepetition,
      setWidgetRepetition,
      setCardFilterPreferences,
      tables,
      cardFilterPreferences,
      blur,
      dispatchTable,
    }}
  />
);

CardView.BarChartFull = ({
  preferences,
  title,
  params,
  id,
  width,
  isEditable,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "BarChartFull",
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // defectsData,
  // workOrdersData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  estimationsData,
  isFilter,
  hiddenCards,
  setHiddenCards,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setPreferences,
  setCardFilterPreferences,
  tables,
  cardFilterPreferences,
  dispatchTable,
}) => (
  <CardView
    {...{
      id,
      width,
      BodyComponent: BarChartFull,
      BodyComponentParams: params,
      title,
      isEditable,
      dynamicStates,
      preferences,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      // claimsData,
      // incidentsData,
      // defectsData,
      // workOrdersData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      estimationsData,
      isFilter,
      hiddenCards,
      setHiddenCards,
      layout,
      setLayout,
      statesData,
      setStatesData,
      widgetRepetition,
      setWidgetRepetition,
      setPreferences,
      setCardFilterPreferences,
      tables,
      cardFilterPreferences,
      dispatchTable,
    }}
  />
);

CardView.CircleProgresBar = ({
  title,
  params,
  id,
  width,
  isEditable,
  dynamicStates,
  preferences,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "CircleProgresBar",
  rowData,
  setRowData,
  isFilter,
  hiddenCards,
  setHiddenCards,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setPreferences,
  setCardFilterPreferences,
  programFields,
  tables,
  allFilterStates,
  setAllFilterStates,
  cardFilterPreferences,
  blur,
  dispatchTable,
}) => (
  <CardView
    {...{
      id,
      width,
      BodyComponent: CircleProgresBar,
      BodyComponentParams: params,
      title,
      isEditable,
      preferences,
      dynamicStates,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      isFilter,
      hiddenCards,
      setHiddenCards,
      layout,
      setLayout,
      statesData,
      setStatesData,
      widgetRepetition,
      setWidgetRepetition,
      setPreferences,
      setCardFilterPreferences,
      programFields,
      tables,
      allFilterStates,
      setAllFilterStates,
      cardFilterPreferences,
      blur,
      dispatchTable,
    }}
  />
);
CardView.StreightProgressBar = ({
  title,
  params,
  id,
  width,
  isEditable,
  preferences,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "StreightProgressBar",
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // defectsData,
  // workOrdersData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  estimationsData,
  isFilter,
  hiddenCards,
  setHiddenCards,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setPreferences,
  setCardFilterPreferences,
  tables,
  allFilterStates,
  setAllFilterStates,
  cardFilterPreferences,
  blur,
  dispatchTable,
}) => (
  <CardView
    {...{
      id,
      width,
      BodyComponent: StreightProgressBar,
      BodyComponentParams: params,
      title,
      isEditable,
      dynamicStates,
      preferences,
      setDynamicStates,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      // claimsData,
      // incidentsData,
      // defectsData,
      // workOrdersData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      estimationsData,
      isFilter,
      hiddenCards,
      setHiddenCards,
      layout,
      setLayout,
      statesData,
      setStatesData,
      widgetRepetition,
      setWidgetRepetition,
      setPreferences,
      setCardFilterPreferences,
      tables,
      allFilterStates,
      setAllFilterStates,
      cardFilterPreferences,
      blur,
      dispatchTable,
    }}
  />
);

CardView.LongLineSteper = ({
  title,
  params,
  id,
  width,
  isEditable,
  dynamicStates,
  setDynamicStates,
  preferences,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "LongLineSteper",
  rowData,
  setRowData,
  programFields,
  projectsData,
  dispatchData,
  fleetData,
  // claimsData,
  // incidentsData,
  // defectsData,
  // workOrdersData,
  inspectionsData,
  maintenanceData,
  archData,
  engData,
  isFilter,
  hiddenCards,
  setHiddenCards,
  layout,
  setLayout,
  statesData,
  setStatesData,
  widgetRepetition,
  setWidgetRepetition,
  setPreferences,
  setCardFilterPreferences,
  tables,
  allFilterStates,
  setAllFilterStates,
  cardFilterPreferences,
  blur,
  dispatchTable,
}) => (
  <CardView
    {...{
      id,
      width,
      BodyComponent: LongLineSteper,
      BodyComponentParams: params,
      title,
      isEditable,
      dynamicStates,
      setDynamicStates,
      preferences,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      programFields,
      projectsData,
      dispatchData,
      fleetData,
      // claimsData,
      // incidentsData,
      // defectsData,
      // workOrdersData,
      inspectionsData,
      maintenanceData,
      archData,
      engData,
      isFilter,
      hiddenCards,
      setHiddenCards,
      layout,
      setLayout,
      statesData,
      setStatesData,
      widgetRepetition,
      setWidgetRepetition,
      setPreferences,
      setCardFilterPreferences,
      tables,
      allFilterStates,
      setAllFilterStates,
      cardFilterPreferences,
      blur,
      dispatchTable,
    }}
  />
);

CardView.LineChart = ({
  title,
  params,
  id,
  width,
  isEditable,
  preferences,
  dynamicStates,
  setDynamicStates,
  updatedDynamicStates,
  setUpdatedDynamicStates,
  type = "LineChart",
  rowData,
  setRowData,
  isFilter,
  setPreferences,
  setCardFilterPreferences,
  programFields,
  tables,
  cardFilterPreferences,
  dispatchTable,
}) => (
  <CardView
    {...{
      id,
      width,
      BodyComponent: LineChart,
      BodyComponentParams: params,
      title,
      isEditable,
      dynamicStates,
      setDynamicStates,
      preferences,
      updatedDynamicStates,
      setUpdatedDynamicStates,
      type,
      rowData,
      setRowData,
      isFilter,
      setPreferences,
      setCardFilterPreferences,
      programFields,
      tables,
      cardFilterPreferences,
      dispatchTable,
    }}
  />
);
CardView.Transfer = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: Transfer,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "Transfer",
      getEditedValue,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.ProgressBar = ({
  title,
  params,
  id,
  dataView,
  setDataView,
  preferences,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setCardFilterPreferences,
  programFields,
  tables,
  allFilterStates,
  setAllFilterStates,
  cardFilterPreferences,
  setChanged,
  dispatchTable,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: ProgressBar,
      BodyComponentParams: params,
      id,
      dataView,
      setDataView,
      type: "ProgressBar",
      getEditedValue,
      preferences,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setCardFilterPreferences,
      tables,
      programFields,
      allFilterStates,
      setAllFilterStates,
      cardFilterPreferences,
      setChanged,
      dispatchTable,
    }}
  />
);
CardView.UserLink = ({
  title,
  params,
  id,
  preferences,
  getEditedValue,
  editable,
  dataView,
  setDataView,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: UserLink,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      dataView,
      setDataView,
      editable,
      disableDrag,
      isFilter,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.FleetItems = ({
  title,
  params,
  id,
  preferences,
  getEditedValue,
  dataView,
  setDataView,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: FleetItems,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      type: "FleetItems",
      disableDrag,
      dataView,
      setDataView,
      isFilter,
      editable,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.LaborCard = ({
  title,
  params,
  id,
  preferences,
  getEditedValue,
  dataView,
  setDataView,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: LaborCard,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      type: "LaborCard",
      disableDrag,
      dataView,
      setDataView,
      isFilter,
      editable,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.WorkOrderItems = ({
  title,
  params,
  id,
  preferences,
  dataView,
  setDataView,
  getEditedValue,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: WorkOrderItems,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      type: "WorkOrderItems",
      disableDrag,
      dataView,
      setDataView,
      isFilter,
      editable,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.ViolationPieChart = ({
  title,
  params,
  id,
  preferences,
  getEditedValue,
  dataView,
  setDataView,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: ViolationPieChart,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      type: "ViolationPieChart",
      disableDrag,
      dataView,
      setDataView,
      isFilter,
      editable,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.AssignProjectGrid = ({
  title,
  params,
  id,
  preferences,
  getEditedValue,
  dataView,
  setDataView,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: AssignProjectGrid,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      type: "AssignProjectGrid",
      disableDrag,
      dataView,
      setDataView,
      isFilter,
      editable,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.DriverAssign = ({
  title,
  params,
  id,
  preferences,
  getEditedValue,
  dataView,
  setDataView,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: DriverAssign,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      type: "DriverAssign",
      disableDrag,
      dataView,
      setDataView,
      isFilter,
      editable,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.InactivityCard = ({
  title,
  params,
  id,
  preferences,
  getEditedValue,
  dataView,
  setDataView,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: InactivityCard,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      type: "InactivityCard",
      disableDrag,
      dataView,
      setDataView,
      isFilter,
      editable,
      setPreferences,
      setChanged,
    }}
  />
);
CardView.ManufacturerCard = ({
  title,
  params,
  id,
  preferences,
  getEditedValue,
  dataView,
  setDataView,
  editable,
  disableDrag,
  isFilter,
  setPreferences,
  setChanged,
}) => (
  <CardView
    {...{
      title,
      BodyComponent: ManufacturerCard,
      BodyComponentParams: params,
      id,
      preferences,
      getEditedValue,
      type: "ManufacturerCard",
      disableDrag,
      dataView,
      setDataView,
      isFilter,
      editable,
      setPreferences,
      setChanged,
    }}
  />
);

// export const MemoizedCardView = React.memo(CardView)

// MemoizedCardView.Pie = React.memo(CardView.Pie)
// MemoizedCardView.LineChart = React.memo(CardView.LineChart)
// MemoizedCardView.LongLineSteper = React.memo(CardView.LongLineSteper)
// MemoizedCardView.StreightProgressBar = React.memo(CardView.StreightProgressBar)
// MemoizedCardView.CircleProgresBar = React.memo(CardView.CircleProgresBar)
// MemoizedCardView.BarChart = React.memo(CardView.BarChart)
// MemoizedCardView.Progress = React.memo(CardView.Progress)

export default CardView;
