import {
  Alert,
  Button,
  Card,
  Collapse,
  Divider,
  Input,
  List,
  Modal,
  Popover,
  Select,
  message,
} from "antd";
import React, { Children, useEffect, useState } from "react";
import "./ScheduleCrews.scss";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import { InfoIconBlue } from "../SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { CollapseArrow } from "../../../../../../assets";
import { ManIcon } from "../../../../components/Notifications/src";
import { AgGridReact } from "ag-grid-react";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { preventDuplicate } from "../../SchedulingFirstPage/helperData";
export const ScheduleCrews = (props) => {
  const {
    scheduleAddress = "",
    scheduleCrews = [],
    newCrewsUpdate = false,
  } = props;
  const [crewsModalVisible, setCrewsModalVisible] = useState(false);
  const [displayedCrews, setDisplayedCrews] = useState([]);

  const columnDefs = [
    {
      field: "day",
    },
    {
      headerName: "Date",
      field: "startDate",
      cellRenderer: (params) => params?.value,
      valueGetter: (params) =>
        dayjsNY(params?.data?.startDate).format("MM/DD/YYYY"),
    },
    {
      headerName: "Day Status",
      field: "status",
    },
    {
      headerName: "Working Hours",
      children: [
        {
          headerName: "Start Time",
          field: "startTime",
          cellRenderer: (params) => params?.value,
          valueGetter: (params) =>
            !!params?.data?.startTime
              ? dayjsNY(params?.data?.startTime).format("hh:mm A")
              : "",
        },
        {
          headerName: "End Time",
          field: "endTime",
          cellRenderer: (params) => params?.value,
          valueGetter: (params) =>
            !!params?.data?.endTime
              ? dayjsNY(params?.data?.endTime).format("hh:mm A")
              : "",
        },
      ],
    },
  ];

  const onSearchCrews = (e) => {
    if (!e) return setDisplayedCrews(scheduleCrews);
    const searchValue = e?.target?.value?.toLowerCase();
    const filteredCrews = scheduleCrews?.filter((crew) =>
      crew?.crewName?.toLowerCase().includes(searchValue)
    );
    setDisplayedCrews(filteredCrews);
  };

  const onClose = () => {
    setCrewsModalVisible(false);
    setDisplayedCrews(scheduleCrews);
  };

  const allDays = preventDuplicate(
    scheduleCrews?.flatMap(({ days = [] }) => days),
    "dayId"
  );

  const onSelectDays = (dayId) => {
    if (!dayId) return setDisplayedCrews(scheduleCrews);
    const filteredCrews = scheduleCrews?.filter((crew) =>
      crew?.days?.some((day) => day?.dayId === dayId)
    );
    setDisplayedCrews(filteredCrews);
  };

  useEffect(() => {
    if (crewsModalVisible) setDisplayedCrews(scheduleCrews);
  }, [scheduleCrews, crewsModalVisible]);

  return (
    <>
      <svg
        onClick={() =>
          !!newCrewsUpdate
            ? !scheduleCrews?.length
              ? message.warning("Not assigned any crew members yet")
              : setCrewsModalVisible(true)
            : message.warning("Not available for this schedule!")
        }
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="5" fill="#1264A3" />
        <path
          d="M16 16C14.9 16 13.9583 15.6083 13.175 14.825C12.3917 14.0417 12 13.1 12 12C12 10.9 12.3917 9.95833 13.175 9.175C13.9583 8.39167 14.9 8 16 8C17.1 8 18.0417 8.39167 18.825 9.175C19.6083 9.95833 20 10.9 20 12C20 13.1 19.6083 14.0417 18.825 14.825C18.0417 15.6083 17.1 16 16 16ZM8 22V21.2C8 20.6333 8.14583 20.1125 8.4375 19.6375C8.72917 19.1625 9.11667 18.8 9.6 18.55C10.6333 18.0333 11.6833 17.6458 12.75 17.3875C13.8167 17.1292 14.9 17 16 17C17.1 17 18.1833 17.1292 19.25 17.3875C20.3167 17.6458 21.3667 18.0333 22.4 18.55C22.8833 18.8 23.2708 19.1625 23.5625 19.6375C23.8542 20.1125 24 20.6333 24 21.2V22C24 22.55 23.8042 23.0208 23.4125 23.4125C23.0208 23.8042 22.55 24 22 24H10C9.45 24 8.97917 23.8042 8.5875 23.4125C8.19583 23.0208 8 22.55 8 22Z"
          fill="white"
        />
      </svg>
      {crewsModalVisible && (
        <Modal
          {...{
            className: "schedule-crews-modal",
            open: crewsModalVisible,
            centered: true,
            title: `Members preview for ${scheduleAddress}`,
            width: 1500,
            onCancel: () => onClose(),
            closeIcon: <XIcon />,
            maskClosable: false,
            keyboard: false,
            destroyOnClose: true,
            footer: [
              <MondayButton
                {...{
                  className: "mondayButtonRed",
                  onClick: () => onClose(),
                  Icon: <XIcon />,
                }}
              >
                Close
              </MondayButton>,
            ],
          }}
        >
          <>
            <div className="previewCrewsModal">
              <span style={{ display: "flex", alignItems: "center", gap: 7 }}>
                <InfoIconBlue />
                Review the assigned crew members for {scheduleAddress}.
              </span>
              <div className="filterSect">
                <Input
                  type="input"
                  placeholder="Search..."
                  onChange={onSearchCrews}
                />
                <Select
                  type="select"
                  placeholder="Select day..."
                  onChange={onSelectDays}
                  onClear={() => setDisplayedCrews(scheduleCrews)}
                  allowClear
                >
                  {allDays?.map((day) => (
                    <Select.Option key={day?.dayId} value={day?.dayId}>
                      Day {day?.day} <Divider type="vertical" />{" "}
                      {dayjsNY(day?.startDate).format("MM/DD/YYYY")}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                marginTop: 15,
                display: "flex",
                flexDirection: "column",
                gap: 10,
                height: "fit-content",
                maxHeight: 550,
                overflowY: "auto",
              }}
            >
              {displayedCrews?.map((crew) => {
                return (
                  <Collapse
                    destroyInactivePanel={true}
                    forceRender={false}
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CollapseArrow
                        style={{
                          transform: !isActive ? "rotate(90deg)" : "",
                        }}
                        height={12}
                        width={12}
                      />
                    )}
                  >
                    <Collapse.Panel
                      key={crew?.crewId}
                      header={
                        <div>
                          <span>{crew?.crewName}</span>
                        </div>
                      }
                      extra={
                        <div>
                          <span>{crew?.crewPosition}</span>
                          <Divider type="vertical" />
                          <span>
                            {crew?.days?.length}{" "}
                            {crew?.days?.length === 1 ? "day" : "days"}
                          </span>
                        </div>
                      }
                    >
                      <div
                        className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <AgGridReact
                          {...{
                            columnDefs,
                            rowData: crew?.days || [],
                            animateRows: true,
                            suppressDragLeaveHidesColumns: true,
                            suppressRowClickSelection: true,
                            rowSelection: "multiple",
                            rowGroupPanelShow: "always",
                            domLayout: "autoHeight",
                            pivotPanelShow: "always",
                            rowHeight: 45,
                            headerHeight: 32,
                            groupHeaderHeight: 32,
                            floatingFiltersHeight: 20,
                            enableCellChangeFlash: true,
                            pivotHeaderHeight: 32,
                            pivotGroupHeaderHeight: 32,
                            defaultColDef: {
                              resizable: true,
                              enablePivot: true,
                              enableColResize: true,
                              enableRowGroup: true,
                              editable: false,
                              sortable: true,
                              flex: 1,
                              filter: true,
                              pivot: true,
                              enableRowGroup: true,
                              enableValue: true,
                            },
                          }}
                        />
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                );
              })}
            </div>
          </>
        </Modal>
      )}
    </>
  );
};
