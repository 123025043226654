import { useSelector } from "react-redux";

import styles from "./TreeElement.module.scss";

import Image from "src/components/commonComponents/Image";

function isBase64(string) {
  return string.startsWith("data:image/");
}

const TreeElement = ({ text, itemKey, value }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      className={`${
        styles[isDarkMode ? "tree-element-item-dark" : "tree-element-item"]
      }
        ${
          (value !== 0 ? value : true) &&
          styles[
            text && text.includes("Previous")
              ? "tree-element-item-previous"
              : "tree-element-item-current"
          ]
        }`}
      data-testid="tree-element"
    >
      {typeof value === "object" ? (
        "Empty"
      ) : itemKey === "image" ? (
        <>{isBase64(value) ? <Image width={80} src={value} /> : value}</>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: value }} />
      )}
    </div>
  );
};

export default TreeElement;
