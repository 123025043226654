import moment from "moment";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { getRandomColor } from "../../../../../../Header/forms/Scheduling/helpers/creators";

//* sort the schedules by date and sortType
export const sortSchedules = (schedules, sortType) => {
  switch (sortType) {
    case "asc":
      return schedules.sort(
        (a, b) =>
          moment(a?.schedule?.createdAt) - moment(b?.schedule?.createdAt)
      );
    case "desc":
      return schedules.sort(
        (a, b) =>
          moment(b?.schedule?.createdAt) - moment(a?.schedule?.createdAt)
      );
    default:
      return schedules;
  }
};

export const getCrewsNames = (allElevationDays) => {
  let crewsNames = [],
    trucks = [],
    drivers = [];
  allElevationDays?.forEach((day) => {
    day?.crews?.forEach(({ crewName }) => {
      crewsNames.push(crewName);
    });
    day?.fleet?.forEach(({ driverName, fleetName }) => {
      drivers.push(driverName);
      trucks.push(fleetName);
    });
  });

  return {
    crewNames: crewsNames.length > 0 ? crewsNames : null,
    drivers: drivers.length > 0 ? drivers : null,
    trucks: trucks.length > 0 ? trucks : null,
  };
};

export const getScheduleDay = (scheduleDays, dayId) => {
  return scheduleDays?.find(({ id }) => dayId === id);
};

//* get the first day of the schedule and the last day of the schedule
export const schedulePeriodCalculator = (schedule) => {
  const sortedScheduleDays = schedule?.scheduleDays?.sort(
    (a, b) => dayjsNY(a?.startDate) - dayjsNY(b?.startDate)
  );
  const startDate = dayjsNY(sortedScheduleDays?.[0]?.startDate).format(
    "MM/DD/YYYY"
  );
  const endDate = dayjsNY(
    sortedScheduleDays?.[sortedScheduleDays?.length - 1]?.endDate
  ).format("MM/DD/YYYY");
  return { startDate, endDate };
};

export const aggFuncs = {
  setAgg: (params) => {
    return aggShortcut(params?.values, params?.colDef?.headerName);
  },
  multiColAggFunc: (params) => {
    return multiColAggFunc(params?.values, params?.colDef?.headerName);
  },
};

export const typeOfProgressToString = (typeOfProgress) => {
  switch (typeOfProgress) {
    case "breakdown":
      return "Breakdown";
    case "subPli":
      return "Sub Pli";
    case "dimensions":
      return "Dimensions";
    case "jumpBreakdown":
      return "Jump & Breakdown";
    default:
      return "N/A";
  }
};

export const multiColAggFunc = (values, word) => {
  const temp = new Map();
  values?.forEach((value) => {
    const groupBy = value?.groupBy;
    const tempValue = value?.value;
    const groupNode =
      value !== null && value !== undefined && typeof tempValue === "object";
    if (
      !groupNode &&
      (typeof tempValue === "string" || typeof tempValue === "number")
    ) {
      const elevation = temp.get(groupBy);
      if (!elevation) {
        temp.set(groupBy, new Set([tempValue]));
        return;
      }

      elevation.add(tempValue);
    }
  });

  let count = 0;
  temp?.forEach((val, key) => {
    if (!val) return;
    count += val?.size;
  });
  const result = {
    count,
    toString: function () {
      return exceptionsValidation(word, this.count);
    },
  };

  return result;
};

export const aggShortcut = (values, word) => {
  const temp = new Set();
  values?.forEach((value) => {
    const groupNode =
      value !== null &&
      value !== undefined &&
      typeof value === "object" &&
      !Array.isArray(value);
    if (
      !groupNode &&
      (typeof value === "string" || typeof value === "number")
    ) {
      temp.add(value);
    }
    if (!groupNode && Array.isArray(value)) {
      value?.forEach((el) => {
        temp.add(el);
      });
      // temp.add(...(value || []));
    }
  });

  const result = {
    count: temp.size,
    toString: function () {
      return exceptionsValidation(word, this.count);
    },
  };

  return result;
};

const exceptionsValidation = (word, count) => {
  const wordExceptions = wordException(word);
  const endsWithS = endsWithEs(wordExceptions);
  switch (count) {
    case 1:
      return `${count} ${wordExceptions}`;
    default:
      return `${count} ${wordExceptions}${endsWithS ? "es" : "s"}`;
  }
};

export const endsWithEs = (word) => !!word?.endsWith("s");

const wordException = (word) => {
  switch (word) {
    case "No":
      return "Schedule";

    default:
      return word;
  }
};

export const statusPanels = ({ rowData, serviceDefinitions }) => {
  const toReturn = rowData?.map((el, index) => {
    const { serviceId } = el || {};
    const service = serviceDefinitions?.find(
      (service) => service?.serviceId.toString() === serviceId?.toString()
    );
    return {
      statusId: index,
      statusName: service?.serviceName,
      statusColor: service?.colorCode || getRandomColor(),
    };
  });

  return removeDuplicates(toReturn, "statusName");
};

export const removeDuplicates = (array, key) =>
  array?.filter(function (el) {
    return !this?.has(el?.[key]) && this?.add(el?.[key]);
  }, new Set());

export const breakdownDays = (pli) => {
  const newDays = removeOldScheduleDays({ pli });

  const breakdownDaysMap = new Map();
  newDays?.forEach((day) => {
    const dayObj = getDayObj({ day, pli });
    breakdownDaysMap.set(day, dayObj);
  });
};

export const getDayObj = ({ day, pli }) => {
  const dayObj = {
    day,
    progressByDay: [],
  };
  pli?.breakdownValue?.forEach(
    ({ progressByDay = [], name = "", ...props }) => {
      const dayProgress = progressByDay?.find(
        ({ day: dayId }) => dayId === day
      );
      if (dayProgress) {
        dayObj.progressByDay.push({ ...dayProgress, name, ...props });
      }
    }
  );
  return dayObj;
};

export const removeOldScheduleDays = ({ pli }) => {
  const allDays = new Set();
  pli?.breakdownValue?.forEach(({ progressByDay = [] }) => {
    progressByDay?.forEach(({ day }) => {
      if (!day) return;
      allDays.add(day);
    });
  });

  return allDays;
};

export const typesOfProgress = [
  "dimensions",
  "breakdown",
  "subPli",
  "jumpBreakdown",
  "",
];
