import { useState } from "react";
import { Divider, Modal } from "antd";
import { useSelector } from "react-redux";
import { FilterIcon } from "../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { camelCaseToNormalText } from "../../../RowDataGridModal/components/utils";
import { generateDatePeriods } from "../../../../../utils";
import { useChartCardsContext } from "../../ChartCardsContext";
import { getNecessaryKeys } from "../../utils";
import MondayButton from "../../../MondayButton/MondayButton";

const ChartFilters = ({
  showFilterModal,
  setShowFilterModal,
  defaultFilters = [],
  setFilters,
  cardConfigs,
}) => {
  const {
    chartDatas,
    chartCategory,
    tutorialLogs,
    filterPeriod,
    filterDepartment,
  } = useChartCardsContext();

  const videoTutorialCard = cardConfigs?.hasOwnProperty("isVideoTutorial")
    ? true
    : false;

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { sampleObjects } = useSelector((state) => state.sampleObjects);

  const [updatedFilters, setUpdatedFilters] = useState(defaultFilters); //here we save updated filters until we click filter button
  const [optionsLength, setOptionsLength] = useState(50);

  const handlePopupScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setOptionsLength((prev) => (prev += 25));
    }
  };

  const usedKeys = videoTutorialCard
    ? [cardConfigs.rowFilterKey]
    : getNecessaryKeys(
        sampleObjects.find(({ objectName }) => objectName === chartCategory)
          ?.objectSample?.[0] || chartDatas?.[0]
      );

  const possibleFilters = usedKeys.reduce((acc, filterKey) => {
    const updatedFilterKey =
      videoTutorialCard &&
      (filterKey === "favourites" || filterKey === "partialWatched")
        ? "videoName"
        : filterKey;

    return {
      ...acc,
      [filterKey]: [
        ...new Set(
          (videoTutorialCard ? tutorialLogs : chartDatas)
            ?.flatMap((row) => {
              if (Array.isArray(row[updatedFilterKey])) {
                return row[updatedFilterKey]?.map((obj) =>
                  typeof obj === "object" ? obj?.nameOfUser : obj
                );
              } else if (typeof row[updatedFilterKey] === "boolean") {
                return row[updatedFilterKey] ? "yes" : "no";
              } else if (typeof row[updatedFilterKey] === "object") {
                console.log(
                  `Found Object In ${filterKey}! Please consider this!`,
                  { row }
                );
                return false;
              } else {
                return row[updatedFilterKey];
              }
            })
            .filter(Boolean)
        ),
      ],
    };
  }, {});

  const departmentsList =
    programFields.find(({ fieldName }) => fieldName === "Departments")
      .fieldOptions || [];

  return (
    <Modal
      className={`filters-modal ${isDarkMode && "filters-modal-dark"}`}
      title="Chart Filters"
      centered
      open={showFilterModal}
      onCancel={() => setShowFilterModal(false)}
      closeIcon={<XIcon />}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={() => setShowFilterModal(false)}
          >
            Cancel
          </MondayButton>
          <div style={{ display: "flex", gap: "10px" }}>
            {!!updatedFilters.length && (
              <MondayButton
                className="mondayButtonRed"
                Icon={<XIcon />}
                onClick={() => {
                  setFilters([]);
                  setShowFilterModal(false);
                }}
              >
                Clear Filters
              </MondayButton>
            )}
            <MondayButton
              className="mondayButtonBlue"
              Icon={<FilterIcon />}
              onClick={() => {
                setFilters(updatedFilters);
                setShowFilterModal(false);
              }}
            >
              Filter
            </MondayButton>
          </div>
        </div>
      }
    >
      <div className="filters-modal-inputs">
        <div className="filters-modal-element">
          <span className="filters-modal-text">Filter by period</span>
          <div className="filters-modal-input">
            <InputComponent
              style={{ textAlign: "center" }}
              type="select"
              placeholder="Select range..."
              defaultValue={
                updatedFilters.find(({ filterType }) => filterType === "period")
                  ?.label
              }
              customOptions={generateDatePeriods()}
              onChange={(_, { label, value }) => {
                setUpdatedFilters(
                  [
                    ...updatedFilters.filter(
                      (filter) => filter?.filterType !== "period"
                    ),
                    value && { label, value, filterType: "period" },
                  ].filter(Boolean)
                );
              }}
            />
          </div>
          <Divider />
        </div>
        {!cardConfigs?.hasOwnProperty("isVideoTutorial") &&
          !!filterDepartment && (
            <div className="filters-modal-element">
              <span className="filters-modal-text"> Filter by Department</span>
              <div className="filters-modal-input">
                <InputComponent
                  {...{
                    type: "select",
                    placeholder: "Select one department...",
                    mode: "multiple",
                    customOptions: departmentsList.map(
                      ({ departmentName }) => ({
                        label: departmentName,
                        value: departmentName,
                      })
                    ),
                    defaultValue: updatedFilters.find(
                      ({ filterType = "" }) => filterType === "cognitoUserId"
                    )?.label,
                    onChange: (departments) => {
                      setUpdatedFilters(
                        [
                          ...updatedFilters.filter(
                            (filter) => filter?.filterType !== "cognitoUserId"
                          ),
                          departments.length && {
                            value: userConfiguration?.allUsers?.Items?.filter(
                              ({ departmentName }) =>
                                departments.includes(departmentName)
                            )
                              .map(({ cognitoUserId }) => cognitoUserId)
                              .filter(Boolean),
                            label: departments,
                            filterType: "cognitoUserId",
                          },
                        ].filter(Boolean)
                      );
                    },
                  }}
                />
              </div>
              <Divider />
            </div>
          )}

        {!!possibleFilters &&
          Object.entries(possibleFilters)?.map(([key, options], index) => {
            return (
              <div className="filters-modal-element" key={index}>
                <div className="filters-modal-text">
                  Filter by {camelCaseToNormalText(key)}
                </div>
                <div className="filters-modal-input">
                  <InputComponent
                    style={{ textAlign: "center" }}
                    type="select"
                    mode="multiple"
                    dropdownClassName={isDarkMode && "darkDropDown"}
                    placeholder={`Select ${camelCaseToNormalText(key)}...`}
                    dropdownStyle={{ width: "100px" }}
                    defaultValue={
                      updatedFilters.find(
                        ({ filterType = "" }) => filterType === key
                      )?.value
                    }
                    onPopupScroll={handlePopupScroll}
                    customOptions={options
                      ?.slice(0, optionsLength)
                      .map((el) => ({
                        value: el,
                        label: el,
                      }))}
                    onSearch={(e) => {
                      setOptionsLength(!!e ? options?.length : 50);
                    }}
                    onChange={(value) => {
                      setUpdatedFilters((prev) =>
                        [
                          ...prev.filter(
                            (filter) => filter?.filterType !== key
                          ),
                          !!value.length && { value, filterType: key },
                        ].filter(Boolean)
                      );
                    }}
                  />
                </div>
                {index !== Object.entries(possibleFilters).length - 1 && (
                  <Divider />
                )}
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default ChartFilters;
