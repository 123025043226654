import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { appliedRentPliCalculator } from "../../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalFooter/RentalBreakdownFooterFunctions";
import { getPriceChargedCredited } from "./getPriceForCharge";
import { getServiceLegacyTaxAmount } from "./getServicesTaxAmount";
import { getServiceLegacyPriceWithoutTax } from "./servicesPricesWithoutTax";

const calculatePriceDetails = (oldPrice, newPercentage, serviceItem) => {
  const isChargedCredited = getPriceChargedCredited(serviceItem?.charges) || 0;

  const maxAllowedPrice = oldPrice - isChargedCredited;
  const maxPercentage = (maxAllowedPrice / oldPrice) * 100;
  const isPercentageMax = maxPercentage <= newPercentage;
  const currentPrice =
    ((typeof newPercentage === "number"
      ? isPercentageMax
        ? maxPercentage
        : newPercentage
      : forceToNumber(serviceItem.pricePercent)) /
      100) *
    oldPrice;
  const taxAmount = currentPrice * (serviceItem?.taxRate || 0);

  return {
    ...serviceItem,
    price: currentPrice,
    priceAmount: oldPrice,
    totalPrice: currentPrice + taxAmount,
    taxRate: serviceItem?.taxRate || 0,
    pricePercent:
      (typeof newPercentage === "number"
        ? isPercentageMax
          ? maxPercentage
          : newPercentage
        : forceToNumber(serviceItem.pricePercent)) || 0,
    taxAmount: taxAmount,
    isTaxable: serviceItem?.isTaxable
      ? serviceItem.isTaxable
      : taxAmount > 0
      ? true
      : false,
    selected: currentPrice > 0 ? true : false,
  };
};

export const validateAndSetChargeState = (
  charge,
  percentage,
  setChargeToSave,
  initialService
) => {
  const newPercentage =
    typeof percentage !== "undefined" ? forceToNumber(percentage) : undefined;

  const chargeItems = {
    ...charge,
    serviceOptions: charge.serviceOptions.map(
      (serviceOption, serviceOptionIndex) =>
        serviceOption.map((service, serviceIndex) => ({
          ...service,
          items: service.items.map((serviceItem, serviceItemIndex) => {
            const item =
              initialService.serviceOptions[serviceOptionIndex][serviceIndex]
                .items[serviceItemIndex];
            const oldPrice =
              forceToNumber(item.price) +
                (forceToNumber(item.pliAddonTotal) || 0) ||
              forceToNumber(item.Price);
            const newServiceItem =
              charge.isScope && charge.isTaxable
                ? { ...serviceItem, taxRate: charge.taxRate }
                : serviceItem;

            return calculatePriceDetails(
              oldPrice,
              newPercentage,
              newServiceItem
            );
          }),
          ...(service?.elevationAddons && {
            elevationAddons: service.elevationAddons.map(
              (elevationAddon, elevationAddonIndex) => {
                const item =
                  initialService.serviceOptions[serviceOptionIndex][
                    serviceIndex
                  ].elevationAddons[elevationAddonIndex];

                const oldPrice = forceToNumber(
                  item.includedTax.priceWithoutTax
                );

                return calculatePriceDetails(
                  oldPrice,
                  newPercentage,
                  elevationAddon
                );
              }
            ),
          }),
        }))
    ),
    serviceAddons:
      charge?.serviceAddons?.map((addon, addonIndex) => {
        const defaultValue =
          initialService?.serviceAddons[addonIndex]?.includedTax
            ?.priceWithoutTax ||
          initialService?.serviceAddons[addonIndex].totalPrice ||
          0;
        const addonItem = {
          ...addon,
          taxRate: addon?.includedTax?.taxRate || 0,
        };
        return calculatePriceDetails(defaultValue, newPercentage, addonItem);
      }) || [],
    additionalRentalTerms:
      charge?.additionalRentalTerms &&
      charge?.additionalRentalTerms?.newPrice &&
      Object.keys(charge?.additionalRentalTerms).length > 0
        ? (() => {
            const rentalTerms = charge?.additionalRentalTerms;
            const priceWithoutTax =
              rentalTerms.newPrice / (1 + rentalTerms?.taxRate || 1);
            return calculatePriceDetails(
              priceWithoutTax,
              newPercentage,
              rentalTerms
            );
          })()
        : {},
  };

  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(chargeItems);
    const newTaxAmount = getServiceLegacyTaxAmount(chargeItems);
    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount, //newPrice * (chargeItems?.taxRate || 0),
                }
              : service
          )
        : [],
    };
  });
};

export const validateAndSetChargeRentalState = (
  charge,
  percentage,
  setChargeToSave,
  initialService,
  projectTaxRate
) => {
  const newPercentage =
    typeof percentage !== "undefined" ? forceToNumber(percentage) : undefined;

  const chargeItems = {
    ...charge,
    serviceOptions: charge.serviceOptions.map((service, serviceIndex) => ({
      ...service,
      items: service.items
        // .filter((filterService) => filterService.maxWeeklyAmount)
        .map((serviceItem, serviceItemIndex) => {
          const item =
            initialService.serviceOptions[serviceIndex]?.items?.[
              serviceItemIndex
            ];
          const oldPrice = appliedRentPliCalculator({
            rentalDetails: item?.rentalDetails?.details || [],
          });
          const newServiceItem = {
            ...serviceItem,
            taxRate: projectTaxRate || serviceItem?.taxRate || 0,
          };
          return {
            ...calculatePriceDetails(oldPrice, newPercentage, newServiceItem),
            completation: 100,
          };
        }),
      ...(service?.elevationAddons && {
        elevationAddons: service.elevationAddons.map(
          (elevationAddon, elevationAddonIndex) => {
            const item =
              initialService.serviceOptions[serviceIndex].elevationAddons[
                elevationAddonIndex
              ];
            const oldPrice = forceToNumber(item.includedTax.priceWithoutTax);

            return calculatePriceDetails(
              oldPrice,
              newPercentage,
              elevationAddon
            );
          }
        ),
      }),
    })),
  };

  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(chargeItems);
    const newTaxAmount = getServiceLegacyTaxAmount(chargeItems);

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount, //newPrice * (chargeItems?.taxRate || 0),
                  totalRentalAmount:
                    charge.serviceOptions?.reduce(
                      (optionAcc, option) =>
                        optionAcc +
                        option.items.reduce(
                          (itemAcc, item) =>
                            itemAcc + (item.appliedAmount || 0),
                          0
                        ),
                      0
                    ) || 0,
                }
              : service
          )
        : [],
    };
  });
};

export const validateAndSetChargeHoistState = (
  charge,
  percentage,
  setChargeToSave,
  initialService
) => {
  const newPercentage =
    typeof percentage !== "undefined" ? forceToNumber(percentage) : undefined;
  const chargeItems = {
    ...charge,
    serviceOptions: charge.serviceOptions.map(
      (serviceOption, serviceOptionIndex) =>
        serviceOption.map((serviceItem, serviceIndex) => {
          const item =
            initialService.serviceOptions[serviceOptionIndex][serviceIndex];
          const oldPrice =
            forceToNumber(item.price) +
              (forceToNumber(item.pliAddonTotal) || 0) ||
            forceToNumber(item.Price);
          const newServiceItem = {
            ...serviceItem,
            taxRate: serviceItem.isTaxable ? serviceItem.taxRate : 0,
          };
          return calculatePriceDetails(oldPrice, newPercentage, newServiceItem);
        })
    ),
    serviceAddons:
      charge?.serviceAddons?.map((addon, addonIndex) => {
        const defaultValue =
          initialService?.serviceAddons[addonIndex].totalPrice || 0;
        return calculatePriceDetails(defaultValue, newPercentage, addon);
      }) || [],
    additionalRentalTerms:
      charge?.additionalRentalTerms &&
      charge?.additionalRentalTerms?.newPrice &&
      Object.keys(charge?.additionalRentalTerms).length > 0
        ? (() => {
            const rentalTerms = charge?.additionalRentalTerms;
            const priceWithoutTax =
              rentalTerms.newPrice / (1 + rentalTerms?.taxRate || 1);
            return calculatePriceDetails(
              priceWithoutTax,
              newPercentage,
              rentalTerms
            );
          })()
        : {},
  };
  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(chargeItems);
    const newTaxAmount = getServiceLegacyTaxAmount(chargeItems);

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount, //newPrice * (chargeItems?.taxRate || 0),
                }
              : service
          )
        : [],
    };
  });
};

export const validateAndSetChargeRentalHoistState = (
  charge,
  percentage,
  setChargeToSave,
  initialService,
  projectTaxRate
) => {
  const newPercentage =
    typeof percentage !== "undefined" ? forceToNumber(percentage) : undefined;

  const chargeItems = {
    ...charge,
    serviceOptions: charge.serviceOptions.map((service, serviceIndex) => ({
      ...service,
      items: service.items.map((serviceItem, serviceItemIndex) => {
        const item =
          initialService.serviceOptions[serviceIndex].items[serviceItemIndex];
        const oldPrice = forceToNumber(item.appliedAmount);
        const newServiceItem = {
          ...serviceItem,
          taxRate: projectTaxRate || serviceItem?.taxRate || 0,
        };
        return {
          ...calculatePriceDetails(oldPrice, newPercentage, newServiceItem),
          completation: 100,
        };
      }),
    })),
  };
  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(chargeItems);
    const newTaxAmount = getServiceLegacyTaxAmount(chargeItems);

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount, //newPrice * (chargeItems?.taxRate || 0),
                  totalRentalAmount:
                    charge.serviceOptions?.reduce(
                      (optionAcc, option) =>
                        optionAcc +
                        option.items.reduce(
                          (itemAcc, item) =>
                            itemAcc + (item.appliedAmount || 0),
                          0
                        ),
                      0
                    ) || 0,
                }
              : service
          )
        : [],
    };
  });
};

export const validateAndSetChargeSOVHoistState = (
  charge,
  percentage,
  setChargeToSave,
  initialService
) => {
  const newPercentage =
    typeof percentage !== "undefined" ? forceToNumber(percentage) : undefined;

  const chargeItems = {
    ...charge,
    serviceOptions: charge?.serviceOptions?.map(
      (serviceOption, serviceOptionIndex) =>
        serviceOption?.map((service, serviceIndex) => ({
          ...service,
          amounts: service?.amounts?.map((serviceItem, serviceItemIndex) => {
            const item =
              initialService?.serviceOptions?.[serviceOptionIndex]?.[
                serviceIndex
              ]?.amounts?.[serviceItemIndex];
            const priceWithoutTax =
              item?.amount / (1 + item?.custom_tax_amount || 0);
            const oldPrice = forceToNumber(priceWithoutTax);
            const newServiceItem = {
              ...serviceItem,
              taxRate: item?.custom_tax_amount || 0,
            };

            return calculatePriceDetails(
              oldPrice,
              newPercentage,
              newServiceItem
            );
          }),
        }))
    ),
  };

  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(
      chargeItems,
      "Schedule Of Value"
    );

    const newTaxAmount = getServiceLegacyTaxAmount(
      chargeItems,
      "Schedule Of Value"
    );

    const totalPrice = chargeItems?.serviceOptions?.reduce(
      (accOption, serviceOption) =>
        accOption +
        serviceOption?.reduce(
          (accService, service) =>
            accService +
            service?.amounts?.reduce(
              (accAmount, item) => accAmount + (item?.amount || 0),
              0
            ),
          0
        ),
      0
    );

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev?.chargeItems?.map((service) =>
            service?.label === chargeItems?.label &&
            service?.estimationId === chargeItems?.estimationId
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount,
                  totalPrice,
                }
              : service
          )
        : [],
    };
  });
};

export const validateAndSetChargeSOVState = (
  charge,
  percentage,
  setChargeToSave,
  initialService
) => {
  const newPercentage =
    typeof percentage !== "undefined" ? forceToNumber(percentage) : undefined;
  const chargeItems = {
    ...charge,
    amounts: charge.amounts.map((serviceItem, serviceItemIndex) => {
      const item = initialService.amounts[serviceItemIndex];
      const priceWithoutTax = item?.amount / (1 + item?.custom_tax_amount || 0);
      const oldPrice = forceToNumber(priceWithoutTax);
      const newServiceItem = {
        ...serviceItem,
        taxRate: item?.custom_tax_amount || 0,
      };

      return calculatePriceDetails(oldPrice, newPercentage, newServiceItem);
    }),
  };

  setChargeToSave((prev) => {
    const newPrice = getServiceLegacyPriceWithoutTax(
      chargeItems,
      "Schedule Of Value"
    );
    const newTaxAmount = getServiceLegacyTaxAmount(
      chargeItems,
      "Schedule Of Value"
    );
    const totalPrice = chargeItems.amounts.reduce(
      (accAmount, item) => accAmount + (item?.amount || 0),
      0
    );

    return {
      ...prev,
      chargeItems: prev?.chargeItems
        ? prev.chargeItems.map((service) =>
            service.label === chargeItems.label &&
            service.estimationId === chargeItems.estimationId
              ? {
                  ...chargeItems,
                  price: newPrice,
                  taxAmount: newTaxAmount,
                  totalPrice,
                }
              : service
          )
        : [],
    };
  });
};
