import "./Styles.scss";
import { Modal } from "antd";

import { XIcon } from "../../../Communication/assets";

const CustomModal = (props) => {
  const {
    title = "Modal",
    open,
    children,
    closeIcon,
    onClose,
    className = "",
    footer = null,
    centered = true,
    ...otherProps
  } = props;

  return (
    <Modal
      className={`custom-inspection-modal ${className}`}
      closeIcon={closeIcon ? closeIcon : <XIcon />}
      title={title}
      open={open}
      onCancel={onClose}
      footer={footer}
      centered={centered}
      {...otherProps}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
