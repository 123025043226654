import { isPointInsidePolygon } from "./isPointInsidePolygon";
import { CoordinateType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../Payroll/Tabs/DEG/components/modalComponents/utils";

type PropsType = {
  tolerance?: number;
  point: CoordinateType;
  jobCoordinates: CoordinateType;
  geoFence?: Array<CoordinateType>;
};

type RangeType = {
  inRange: boolean;
  distanceInFeet?: number;
};

export function getJobDistance({
  point,
  geoFence,
  jobCoordinates,
  tolerance = 300,
}: PropsType): RangeType {
  let range = {
    inRange: false,
    distanceInFeet: undefined,
  };

  if (geoFence?.length) {
    range = findDistanceFromGeofenceSide({
      point,
      tolerance,
      geofence: geoFence,
    });
    const insideGeofence = isPointInsidePolygon(geoFence, point);
    if (insideGeofence) {
      range = { inRange: true, distanceInFeet: range?.distanceInFeet };
    }
  }

  const distanceFromJob = withinRadius(point, jobCoordinates, tolerance);
  if (
    !range?.distanceInFeet ||
    range?.distanceInFeet > distanceFromJob?.distanceInFeet
  ) {
    range = {
      distanceInFeet: distanceFromJob?.distanceInFeet,
      inRange: range?.inRange ? range?.inRange : distanceFromJob?.withinRange, // in case when inRange is true from point being inside the geofence polygon
    };
  }

  return range;
}
