import { forwardRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Jodit from "jodit-react";
import "./JoditEditorComp.scss";

const joditEditorDefaultButtons = [
  "undo",
  "redo",
  "|",
  "bold",
  "italic",
  "underline",
  "|",
  "fontsize",
  "|",
  "ul",
  "ol",
];

const JoditEditorComp = forwardRef(
  (
    {
      content,
      setContent,
      placeholder = "Write here...",
      customConfig = {},
      whiteTheme = false,
      showToolbar = true,
      readonly = false,
      height = "160px",
      className = "",
    },
    proppedRef
  ) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);

    const [inputContent, setInputContent] = useState("");

    const config = useMemo(() => {
      return {
        toolbarAdaptive: false, // Disable adaptive toolbar to show all menu buttons
        buttons: joditEditorDefaultButtons,
        ...customConfig,
        placeholder,
        readonly,
        toolbar: showToolbar,
      };
    }, [JSON.stringify(customConfig), placeholder, content]);

    const popupStyle = {
      backgroundColor: isDarkMode ? "#12131b" : "#fff",
      color: isDarkMode ? "#fff" : "#000",
    };

    return (
      <div
        className={`jodit-editor-component ${className} jodit-editor-component${
          isDarkMode ? "-dark" : whiteTheme ? "-white" : ""
        }`}
      >
        <Jodit
          ref={proppedRef}
          value={content || inputContent}
          onBlur={(newContent) => {
            setContent && setContent(newContent);
            setInputContent(newContent);
          }}
          config={config}
        />
        <style jsx>{`
          .jodit-wysiwyg {
            max-height: ${height} !important;
          }

          .jodit-popup__content {
            background-color: ${popupStyle.backgroundColor} !important;
            color: ${popupStyle.color} !important;
          }

          .jodit-toolbar-button__text {
            color: ${popupStyle.color} !important;
          }
        `}</style>
      </div>
    );
  }
);

export default JoditEditorComp;
