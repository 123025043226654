import { useEffect, useState } from "react";
import { Button, Col, Flex, Image, Modal, Row, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { driveApi } from "../../../../../integrations/DriveRequest";
import AspectRatio from "../../../../commonComponents/AspectRatio";
import Headline from "../../../../commonComponents/Typography/Headline";

export default function ImageViewer({ open, questions = [], onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [questionImages, setQuestionImages] = useState([]);

  const { accessToken } = useSelector((state) => state.accessToken);

  const driveRequest = driveApi({ accessToken });

  const isMobile = useMediaQuery({ maxWidth: 900 });

  const images = questionImages?.flatMap(({ fileIds }) => fileIds);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
      setQuestionImages([]);
    }
  };

  const getImage = async (fileId) => {
    const { src, blob } = await driveRequest.getImageSrc(fileId);
    return {
      fileId,
      src,
      blob,
    };
  };

  useEffect(() => {
    if (questions.length) {
      const fetchImages = async () => {
        setIsLoading(true);

        const qns = await Promise.all(
          questions?.map(async (question) => ({
            ...question,
            fileIds: await Promise.all(
              question?.fileIds?.map(async (id) => await getImage(id))
            ),
          }))
        );

        setQuestionImages(qns);
        setIsLoading(false);
      };

      fetchImages();
    }
  }, [questions]);

  return (
    <Modal
      title="Images"
      open={open}
      centered
      onCancel={handleOnClose}
      onClose={handleOnClose}
      footer={() => (
        <Button type="primary" onClick={handleOnClose}>
          Close
        </Button>
      )}
    >
      <div
        className="images-list"
        style={{
          maxHeight: 600,
          height: "100%",
          padding: 6,
          overflowY: "auto",
          backgroundColor: "hsl(40 40% 96%)",
        }}
      >
        {isLoading ? (
          <Row gutter={[6, 6]}>
            {Array.from({ length: 4 })?.map((_, i) => (
              <Col xs={12} sm={8} md={6} key={i}>
                <AspectRatio ratio="1/1">
                  <Skeleton.Image active={isLoading} />
                </AspectRatio>
              </Col>
            ))}
          </Row>
        ) : (
          <Image.PreviewGroup
            preview={{
              imageRender: (originalNode, { current }) => {
                const currentImg = images?.find(
                  (item) => images?.indexOf(item) === current
                );
                const currentQuestion = questions.find((q) =>
                  q.fileIds.includes(currentImg?.fileId)
                );

                return (
                  <Flex justify="center" align="center">
                    <ImageQuestion
                      question={currentQuestion?.question}
                      isMobile={isMobile}
                    />
                    {originalNode}
                  </Flex>
                );
              },
            }}
          >
            <Row gutter={[6, 6]}>
              {images.map((image) => (
                <Col xs={12} sm={8} md={6} key={image?.fileId}>
                  <AspectRatio ratio="1/1">
                    <Image src={image.src} className="image" />
                  </AspectRatio>
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>
        )}
      </div>
    </Modal>
  );
}

const ImageQuestion = ({ question, isMobile }) => (
  <Flex
    gap="small"
    style={{
      maxWidth: 450,
      position: "fixed",
      top: 20,
      left: 20,
      marginRight: 90,
      padding: isMobile ? "8px 12px" : 20,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      borderRadius: "6px",
      zIndex: 999,
    }}
  >
    <Headline whiteText span style={{ minWidth: 75 }}>
      Question:
    </Headline>
    <Headline whiteText span style={{ textAlign: "left" }}>
      {question ?? ""}
    </Headline>
  </Flex>
);
