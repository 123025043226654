import { TickIcon } from "../../../Roles/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { LogsButton, MondayButton } from "../../../../../../commonComponents";

export const footerButtons = ({
  logsRef,
  saveRef,
  onCancel,
  cancelRef,
  handleUpdate,
  recordSelected,
  disabledActions,
}) => {
  return [
    <div className="footerButtons">
      <div ref={cancelRef}>
        <MondayButton
          Icon={<XIcon />}
          onClick={onCancel}
          disabled={disabledActions}
          className="mondayButtonRed"
        >
          Cancel
        </MondayButton>
      </div>
      <div ref={logsRef} className="crewLogsDiv">
        <LogsButton
          {...{
            buttonLabel: "Logs",
            logsTitle: "Employee Logs",
            filtersObj: { recordId: recordSelected?.data?.crewId },
          }}
        />
      </div>
      <div ref={saveRef}>
        <MondayButton
          onClick={handleUpdate}
          disabled={disabledActions}
          Icon={<TickIcon height={17} width={17} />}
        >
          Save Changes
        </MondayButton>
      </div>
    </div>,
  ];
};

export function employeeFields(isDarkMode, role, status, jobsites) {
  return [
    {
      type: "input",
      required: true,
      label: "Full Name",
      placeholder: "Name...",
      formItemName: "crewName",
      dataTestid: "input-name",
      className: "crewNameUpdate",
    },
    {
      label: "Role",
      type: "select",
      required: true,
      dataTestid: "select-role",
      formItemName: "crewPosition",
      placeholder: "Select role...",
      className: "crewPositionSelect",
      getPopUpContainer: document.body,
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: (role || [])?.map(({ statusName }, key) => ({
        key,
        label: statusName,
        value: statusName,
      })),
    },
    {
      type: "select",
      required: true,
      label: "Employee Type",
      formItemName: "foreman",
      getPopUpContainer: document.body,
      placeholder: "Select employee type...",
      customOptions: [
        { key: 0, label: "Member", value: false },
        { key: 1, label: "Foreman", value: true },
      ],
    },
    {
      type: "select",
      required: true,
      label: "Status",
      formItemName: "crewStatus",
      getPopUpContainer: document.body,
      placeholder: "Select employee status...",
      customOptions: (status || []).map(({ statusName }, key) => {
        return {
          key,
          label: statusName,
          value: statusName,
        };
      }),
    },
    {
      type: "select",
      allowClear: true,
      label: "Default Job",
      formItemName: "defaultJob",
      placeholder: "Select job...",
      customOptions: (jobsites || []).map((job, key) => ({
        key,
        value: job?.jobsiteId,
        label: `${job?.jobName} (${job?.accountName?.accountName})`,
      })),
    },
    {
      type: "input",
      required: true,
      addonBefore: "$",
      typeNumber: true,
      label: "Pay Rate",
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
    },
    {
      type: "select",
      required: true,
      label: "Salary Type",
      formItemName: "salaryType",
      getPopUpContainer: document.body,
      placeholder: "Select salary type...",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
    },
    {
      type: "input",
      required: true,
      label: "Employee ID",
      formItemName: "employeeId",
      className: "employeeIdEdit",
      placeholder: "Employee Id...",
    },
    {
      type: "input",
      formItemName: "fingerCheckId",
    },
    {
      type: "input",
      formItemName: "employeeNumber",
    },
  ];
}

export function employeeEmergencyContactFields() {
  return {
    disabled: false,
    type: "MultipleInputs",
    label: "Emergency Contact",
    formItemName: "emergencyContact",
    dynamicFields: [
      {
        type: "Input",
        disabled: false,
        label: "Full Name",
        formItemName: "fullName",
      },
      {
        type: "Email",
        label: "Email",
        disabled: false,
        formItemName: "email",
      },
      {
        disabled: false,
        type: "PhoneNumber",
        label: "Phone Number",
        formItemName: "phoneNumber",
        rules: [
          {
            pattern:
              /^1?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            message: "Please enter a valid phone number",
          },
        ],
      },
      {
        rules: [],
        disabled: false,
        type: "ComboBox",
        label: "Relationship",
        formItemName: "relationship",
        options: ["Parent", "Spouse", "Sibling", "Child", "Friend"],
      },
    ],
  };
}
