import {
  CrewTeamType,
  ScheduleType,
  EmployeeReportType,
} from "../payrollLiveTypes";
import {
  CoordinateType,
  JobsiteType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../Payroll/Tabs/DEG/components/modalComponents/utils";
import { getJobDistance } from "./getJobDistance";

type RangeType = {
  inRange: boolean;
  distanceInFeet: number;
};

type CoordinatesMatchProps = {
  jobs: Array<JobsiteType>;
  crewTeams: Array<CrewTeamType>;
  schedules: Array<ScheduleType>;
  employees: Array<EmployeeReportType>;
};

type CoordinatesMatchResponse = {
  matchedEmployees: Array<EmployeeReportType>;
  unmatchedReports: Array<EmployeeReportType>;
  jobsitesMatch: Array<JobsiteType | ScheduleType>;
};

export function coordinatesMatch({
  jobs,
  crewTeams,
  employees,
  schedules,
}: CoordinatesMatchProps): CoordinatesMatchResponse {
  let jobsitesMatch = [];
  let unmatchedReports = [];
  let matchedEmployees = [];

  for (let i = 0; i < employees.length; i++) {
    const emp = employees[i];

    let jobMatch: JobsiteType | ScheduleType | undefined;
    for (let j = 0; j < schedules.length; j++) {
      const schedule = schedules[j];
      const geoInfo = schedule?.geoFenceInfo;

      const empAssigned = (schedule?.employeesAssigned || []).findIndex(
        (el) => Number(el?.["ID Number"]) === Number(emp?.employeeNumber)
      );

      // #region employee assigned to this schedule by excel sheet
      if (typeof empAssigned === "number" && empAssigned > -1) {
        let range: RangeType;
        jobMatch = schedule;

        if (!!geoInfo?.length) {
          range = findDistanceFromGeofenceSide({
            point: emp?.punchCoordinates,
            geofence: geoInfo[0]?.geoFenceInfo,
            tolerance: +schedule?.radius || 300,
          });
        }

        const distanceFromJob = withinRadius(
          schedule?.addressPosition,
          emp?.punchCoordinates,
          +schedule?.radius || 300
        );
        if (
          distanceFromJob?.distanceInFeet < range?.distanceInFeet ||
          !range?.distanceInFeet
        ) {
          range = {
            inRange: distanceFromJob?.withinRange,
            distanceInFeet:
              emp?.punchCoordinates?.lat &&
              schedule?.addressPosition?.lat &&
              distanceFromJob?.distanceInFeet,
          };
        }

        let foreman = false;
        let teamIndex = crewTeams.findIndex((el) => {
          const employeeNumber = (el?.crewForeman?.employeeId || "").split(
            "-"
          )?.[1];

          const isForeman =
            Number(employeeNumber) === Number(emp?.employeeNumber);

          const isMember =
            el.crewMembers.findIndex(
              (mem) =>
                Number((mem?.employeeId || "").split("-")?.[1]) ===
                Number(emp?.employeeNumber)
            ) > -1;
          if (isForeman) {
            foreman = isForeman;
          }
          return isForeman || isMember;
        });

        const matchEmpData = {
          ...emp,
          isForeman: foreman,
          projectId: jobMatch?.projectId,
          jobsiteId: (jobMatch as unknown as JobsiteType)?.jobsiteId,
          distance: range?.distanceInFeet,
          crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
          crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
          color: !range?.inRange && emp?.liveStatus === "In" ? "#e9c466" : null,
          liveStatus:
            !range?.inRange && emp?.liveStatus === "In"
              ? "Don't Match"
              : emp?.liveStatus,
        };

        const isMultipleMatch = matchedEmployees.findIndex(
          (mch) => Number(mch?.employeeNumber) === Number(emp?.employeeNumber)
        );
        if (isMultipleMatch > -1) {
          const empMatched = matchedEmployees[isMultipleMatch];

          if (empMatched?.distance > range?.distanceInFeet) {
            matchedEmployees.splice(isMultipleMatch, 1, matchEmpData);
          }
        } else {
          matchedEmployees.push(matchEmpData);
        }
        range = undefined;
      }
    }

    if (!!jobMatch) {
      continue;
    }

    for (let i = 0; i < jobs.length; i++) {
      const jobsite = jobs[i];
      const geoInfo = jobsite?.geofenceInfo?.length
        ? [{ geoFenceInfo: jobsite?.geofenceInfo }]
        : jobsite?.geoFenceInfo;
      const empAssigned =
        jobsite?.employeesAssigned?.length &&
        jobsite?.employeesAssigned.findIndex(
          (el) => Number(el?.["ID Number"]) === Number(emp?.employeeNumber)
        );

      if (empAssigned > -1) {
        const range = getJobDistance({
          point: emp?.punchCoordinates,
          jobCoordinates: jobsite?.addressPosition,
          geoFence: geoInfo[0]?.geoFenceInfo as Array<CoordinateType>,
        });
        jobMatch = jobsite;

        // if (!!geoInfo?.length) {
        //   range = findDistanceFromGeofenceSide({
        //     point: emp?.punchCoordinates,
        //     geofence: geoInfo[0]?.geoFenceInfo,
        //     tolerance: +jobsite?.locationRadius || 300,
        //   });
        // }

        // const distanceFromJob = withinRadius(
        //   jobsite?.addressPosition,
        //   emp?.punchCoordinates,
        //   +jobsite?.locationRadius || 300
        // );

        // if (
        //   !range?.distanceInFeet ||
        //   distanceFromJob.distanceInFeet < range?.distanceInFeet
        // ) {
        //   range = {
        //     inRange: distanceFromJob?.withinRange,
        //     distanceInFeet:
        //       jobsite?.addressPosition?.lat &&
        //       emp?.punchCoordinates?.lat &&
        //       distanceFromJob.distanceInFeet,
        //   };
        // }

        let foreman = false;
        let teamIndex = crewTeams.findIndex((el) => {
          const employeeNumber = (el?.crewForeman?.employeeId || "").split(
            "-"
          )?.[1];

          const isForeman =
            Number(employeeNumber) === Number(emp?.employeeNumber);

          const isMember =
            el.crewMembers.findIndex(
              (mem) =>
                Number((mem?.employeeId || "").split("-")?.[1]) ===
                Number(emp?.employeeNumber)
            ) > -1;
          if (isForeman) {
            foreman = isForeman;
          }
          return isForeman || isMember;
        });

        const matchEmpData = {
          ...emp,
          isForeman: foreman,
          projectId: jobsite?.projectId,
          jobsiteId: jobsite?.jobsiteId,
          distance: range?.distanceInFeet,
          crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
          crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
          color: !range?.inRange && emp.liveStatus === "In" ? "#e9c466" : null,
          liveStatus:
            !range?.inRange && emp?.liveStatus === "In"
              ? "Don't Match"
              : emp?.liveStatus,
        };

        const isMultipleMatch = matchedEmployees.findIndex(
          (mch) => Number(mch?.employeeNumber) === Number(emp?.employeeNumber)
        );
        if (isMultipleMatch > -1) {
          const empMatched = matchedEmployees[isMultipleMatch];

          if (empMatched?.distance > range?.distanceInFeet) {
            matchedEmployees[isMultipleMatch] = matchEmpData;
          }
        } else {
          matchedEmployees.push(matchEmpData);
        }
        jobsitesMatch.push(jobMatch);
      } else {
        const range = getJobDistance({
          point: emp?.punchCoordinates,
          jobCoordinates: jobsite?.addressPosition,
          geoFence: (geoInfo?.[0]?.geoFenceInfo || []) as Array<CoordinateType>,
        });

        const matchedEmp = matchedEmployees.findIndex(
          (el) => el?.employeeId === emp?.employeeId && el?.liveStatus !== "Don't Match"
        );

        if (range?.inRange && matchedEmp === -1) {
          let foreman = false;
          let teamIndex = crewTeams.findIndex((el) => {
            const employeeNumber = (el?.crewForeman?.employeeId || "").split(
              "-"
            )?.[1];

            const isForeman =
              Number(employeeNumber) === Number(emp?.employeeNumber);

            const isMember =
              el.crewMembers.findIndex(
                (mem) =>
                  Number((mem?.employeeId || "").split("-")?.[1]) ===
                  Number(emp?.employeeNumber)
              ) > -1;
            if (isForeman) {
              foreman = isForeman;
            }
            return isForeman || isMember;
          });

          const matchEmpData = {
            ...emp,
            isForeman: foreman,
            projectId: jobsite?.projectId,
            jobsiteId: jobsite?.jobsiteId,
            distance: range?.distanceInFeet,
            crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
            crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
            color:
              !range?.inRange && emp.liveStatus === "In"
                ? "#e9c466"
                : "#7FB5B5",
            liveStatus:
              !range?.inRange && emp?.liveStatus === "In"
                ? "Don't Match"
                : "Unscheduled",
          };
          matchedEmployees.push(matchEmpData);
          jobsitesMatch.push(jobsite);
        }
      }
    }
  }
  unmatchedReports = employees.filter(
    (el) =>
      matchedEmployees.findIndex(
        (e) => Number(e?.employeeNumber) === Number(el?.employeeNumber)
      ) === -1
  );

  return { jobsitesMatch, matchedEmployees, unmatchedReports };
}
