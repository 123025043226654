import { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, message } from "antd";
import { API } from "aws-amplify";
import { excludesColumns } from "./data";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { ExcludesController, NewExcludeModal } from "./components";
import { GridTemplateCard } from "../../../../../pages/Settings/settingsComponents/FleetLiveSheets/components";
import { HoverButton, RedWarningModal } from "../../../../../commonComponents";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { fetchAllData, fetchData } from "../../../../../../utils";

import "./DispatchExcludesModal.scss";

/** @typedef {ReturnType<dayjsNY>} Dayjs */
/** @typedef {import("../../../FleetReportPage/data").ShiftExclude} ExcludeType */

/**
 * @typedef DispatchExcludesProps
 * @property {boolean} open
 * @property {() => void} onCancel
 * @property {boolean} [showController=true]
 * @property {(exclude: ExcludeType, action: "ADD"|"EDIT"|"DELETE") => any} [onChange]
 * @property {[Dayjs, Dayjs]} [dateRange]
 * @property {(standbyExcludes: ExcludeType[]) => void} [onDataLoad]
 */

function DispatchExcludesModal(
  /** @type {DispatchExcludesProps} */
  {
    open,
    onCancel,
    showController = true,
    onChange = () => {},
    dateRange,
    onDataLoad,
  }
) {
  //#region HOOKS
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [drivers, setDrivers] = useState([]);
  const [viewData, setViewData] = useState({
    DEPART: [],
    ARRIVE: [],
    STANDBY: [],
  });
  const [deleteWarning, setDeleteWarning] = useState(undefined);
  const [excludesDateRange, setExcludesDateRange] = useState(
    dateRange || [dayjsNY().startOf("D"), dayjsNY().startOf("D")]
  );
  const [excludeEditData, setExcludeEditData] = useState(undefined);
  const [newExcludeModal, setNewExcludeModal] = useState(
    /** @type {"DEPART"|"ARRIVE"|"STANDBY"|undefined} */ (undefined)
  );

  useEffect(() => {
    //#region DRIVERS EFFECT
    fetchData({
      endpoint: "drivers",
    })
      .then(setDrivers)
      .catch((err) => {
        console.log("Error getting drivers: ", err);
      });
  }, []);

  useEffect(() => {
    //#region RANGE EFFECT
    if (!dateRange) {
      return;
    }

    setExcludesDateRange(dateRange);
  }, [dateRange]);

  useEffect(() => {
    //#region GET DATA
    message.loading({
      content: "Loading data...",
      key: "dataLoad",
      duration: 0,
    });

    fetchAllData({
      endpoint: "excludes",
      resultId: "excludeId",
      resultPosition: "excludes",
      otherStringParams: {
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "excludeDate",
                formula: "is_between",
                value: excludesDateRange.map((e) => e.format()),
                operator: "AND",
              },
            ],
          },
        ]),
      },
    })
      .then((res) => {
        message.destroy("dataLoad");
        const tmpData = _.groupBy(res, "excludeType");
        for (const key in tmpData) {
          tmpData[key].sort(
            (a, b) => dayjsNY(a.excludeDate) - dayjsNY(b.excludeDate)
          );
        }

        if (!("DEPART" in tmpData)) {
          tmpData["DEPART"] = [];
        }
        if (!("ARRIVE" in tmpData)) {
          tmpData["ARRIVE"] = [];
        }
        if (!("STANDBY" in tmpData)) {
          tmpData["STANDBY"] = [];
        }

        setViewData(tmpData);

        const todayStandby = tmpData.STANDBY.filter((exclude) =>
          dayjsNY(exclude.excludeDate).isSame(dayjsNY(), "day")
        );
        onDataLoad?.(todayStandby);
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong, could not get data",
          key: "dataLoad",
        });

        console.log("Error getting data: ", err);
      });
  }, [excludesDateRange]);

  async function updateDateRange(params) {
    //#region UPDATE DATE RANGE
    setExcludesDateRange(params);
  }

  async function onEdit(params, save = false) {
    //#region ON EDIT
    if (!save) {
      setExcludeEditData(params);
      setNewExcludeModal(params?.excludeType);
      return;
    }

    const { excludeType, excludeDate, driverId } = params;
    if (
      viewData[excludeType].find(
        (e) =>
          e.excludeDate === excludeDate &&
          e.driverId === driverId &&
          e.excludeId !== params?.excludeId
      )
    ) {
      message.warning({
        content: "Cannot save duplicated exclude",
        key: "duplicatedExclude",
      });

      return;
    }

    message.loading({
      content: "Saving record...",
      key: "save",
      duration: 0,
    });

    const { excludeId, ...rest } = params;

    let call = API.post.bind(API, "excludes", "/excludes", {
      body: rest,
    });
    if (excludeEditData) {
      call = API.put.bind(API, "excludes", `/excludes/${excludeId}`, {
        body: rest,
      });
    }

    await call()
      .then((res) => {
        message.destroy("save");

        if (excludeEditData) {
          onChange({ ...excludeEditData, ...params }, "EDIT");
        } else {
          onChange(res, "ADD");
        }

        setViewData((prev) => {
          const tmp = { ...prev };
          if (excludeEditData) {
            const index = tmp[params["excludeType"]].findIndex(
              (e) => e.excludeId === params.excludeId
            );

            if (index > -1) {
              const newData = [...tmp[params["excludeType"]]];
              newData[index] = {
                ...params,
              };
              tmp[params["excludeType"]] = newData;
            }
          } else {
            tmp[params["excludeType"]] = [res].concat(
              tmp[params["excludeType"]] || []
            );
          }

          return tmp;
        });
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong could not save",
          key: "save",
        });
        console.log("Error saving: ", err);
      });
  }

  async function onDelete(params, confirmed = false) {
    //#region ON DELETE
    if (!confirmed) {
      setDeleteWarning(params);
      return;
    }

    message.loading({
      content: "Deleting record...",
      key: "delete",
      duration: 0,
    });

    await API.del("excludes", `/excludes/${params.excludeId}`)
      .then(() => {
        message.destroy("delete");
        onChange(params, "DELETE");

        setViewData((prev) => {
          const tmp = { ...prev };
          tmp[params["excludeType"]] = tmp[params["excludeType"]].filter(
            (e) => e.excludeId !== params.excludeId
          );
          return tmp;
        });
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong, could not delete",
          key: "delete",
        });
        console.log("Error deleting record: ", err);
      });
  }

  //#region JSX
  return (
    <Fragment>
      <Modal
        {...{
          open,
          onCancel,
          title: "Excludes",
          centered: true,
          className: `dispatch-excludes ${
            isDarkMode ? "dispatch-excludes-dark" : ""
          } ${!showController ? "no-controller" : ""}`,
          closeIcon: <XIcon />,
          footer: null,
        }}
      >
        {showController ? (
          <ExcludesController
            updateDateRange={updateDateRange}
            dateRange={excludesDateRange}
          />
        ) : null}
        <section className="excludes-grids">
          <GridTemplateCard
            rowData={viewData.DEPART}
            columnDefs={excludesColumns}
            context={{
              onEdit,
              onDelete,
            }}
            gridProps={{
              pagination: false,
            }}
            title="Excludes Depart"
            headerActions={[
              <HoverButton
                dataTestId="add-exclude-depart"
                text="Add New"
                type="new"
                onClick={() => {
                  setNewExcludeModal("DEPART");
                }}
              />,
            ]}
          />
          <GridTemplateCard
            rowData={viewData.ARRIVE}
            columnDefs={excludesColumns}
            context={{
              onEdit,
              onDelete,
            }}
            gridProps={{
              pagination: false,
            }}
            title="Excludes Arrive"
            headerActions={[
              <HoverButton
                text="Add New"
                dataTestId="add-exclude-arrive"
                type="new"
                onClick={() => {
                  setNewExcludeModal("ARRIVE");
                }}
              />,
            ]}
          />
          <GridTemplateCard
            rowData={viewData.STANDBY}
            columnDefs={excludesColumns}
            context={{
              onEdit,
              onDelete,
            }}
            gridProps={{
              pagination: false,
            }}
            title="Standby"
            headerActions={[
              <HoverButton
                text="Add New"
                dataTestId="add-standby"
                type="new"
                onClick={() => {
                  setNewExcludeModal("STANDBY");
                }}
              />,
            ]}
          />
        </section>
      </Modal>
      {newExcludeModal ? (
        <NewExcludeModal
          open
          drivers={drivers}
          editData={excludeEditData}
          excludeType={newExcludeModal}
          onConfirm={(params) => {
            onEdit(params, true);
          }}
          onCancel={() => {
            setNewExcludeModal(undefined);
            if (excludeEditData) {
              setExcludeEditData(undefined);
            }
          }}
        />
      ) : null}
      {deleteWarning ? (
        <RedWarningModal
          visible
          titleText="Delete Warning"
          footerProps={{
            onConfirm() {
              onDelete(deleteWarning, true);
              setDeleteWarning(undefined);
            },
            onCancel() {
              setDeleteWarning(undefined);
            },
          }}
        >
          Are you sure you want to delete this entry?
        </RedWarningModal>
      ) : null}
    </Fragment>
  );
}

export default DispatchExcludesModal;
