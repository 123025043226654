import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Switch, Tooltip } from "antd";

import {
  FleetsLiveContext,
  findPaginationSize,
  loadLivePreference,
  saveLocalPreferences,
} from "../../utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { columnDefs, parseRowData, gridFilters, columnKeys } from "./gridData";
import PayrollLayout from "../../../../../pages/Payroll/Layout/PayrollLayout";
import { MondayButton } from "src/components/commonComponents";
import { ExcelIconWhite } from "src/assets";

import "../LiveLocationsView/LiveLocationsView.scss";

function LiveTripsView() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    fleet,
    selectedDate,
    actualTripsForDate = {},
    actualTripsForTruck = {},
  } = useContext(FleetsLiveContext);

  const [gridApi, setGridApi] = useState();
  const [rowData, setRowData] = useState([]);
  const [dataView, setDataView] = useState(
    /** @type {"DATE"|"TODAY"} */ (loadLivePreference("liveTripsToggle"))
  );

  let isTodaySelectedCondition =
    dayjsNY(selectedDate).startOf("D").valueOf() ===
    dayjsNY().startOf("D").valueOf();

  useEffect(() => {
    if (dataView === "TODAY" || isTodaySelectedCondition) {
      setRowData(parseRowData({ actualTripsForTruck, fleet }));
    } else {
      setRowData(
        parseRowData({ actualTripsForTruck: actualTripsForDate, fleet })
      );
    }
  }, [
    fleet,
    dataView,
    actualTripsForDate,
    actualTripsForTruck,
    isTodaySelectedCondition,
  ]);

  function excelExportHandler() {
    if (!gridApi) {
      return;
    }

    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (headerName.includes("Time")) {
          return dayjsNY(value).format("MM/DD/YYYY HH:mm");
        }

        return value === undefined ? "" : `${value}`;
      },
    });
  }

  return (
    <div
      className={`live-locations-view ${
        isDarkMode ? "live-locations-dark" : ""
      }`}
      id="main-trips-view"
    >
      <div
        className={`main-payroll-view ${
          isDarkMode && "main-payroll-view-dark"
        }`}
      >
        <PayrollLayout
          {...{
            rowData,
            title: "",
            columnDefs,
            gridFilters,
            hasNew: false,
            rowHeight: 38,
            headerHeight: 40,
            getGridApi: setGridApi,
            suppressDoubleClickEdit: true,
            paginationPageSize: findPaginationSize("main-trips-view", 38),
            additionalGridProps: {
              defaultExcelExportParams: {
                columnKeys,
                sheetName: "Live Trips",
                fileName: `Live Trips Report - ${dayjsNY(
                  dataView === "TODAY" ? undefined : selectedDate
                ).format("MM/DD/YYYY")}`,
              },
            },
            additionalActionsButtons: [
              !isTodaySelectedCondition && (
                <Switch
                  className="writeReadSwitchContainer"
                  key="switch"
                  onChange={(checked) => {
                    if (checked) {
                      setDataView("DATE");
                      saveLocalPreferences({ liveTripsToggle: "DATE" });
                    } else {
                      setDataView("TODAY");
                      saveLocalPreferences({ liveTripsToggle: "TODAY" });
                    }
                  }}
                  checked={dataView === "DATE"}
                  unCheckedChildren="Today Trips"
                  checkedChildren={`${dayjsNY(selectedDate).format(
                    "MMM DD"
                  )} Trips`}
                />
              ),
              <Tooltip
                title="Excel Export"
                placement="bottom"
                key="excelExport"
              >
                <MondayButton
                  Icon={<ExcelIconWhite />}
                  className="mondayButtonBlue"
                  onClick={() => {
                    excelExportHandler();
                  }}
                >
                  {null}
                </MondayButton>
              </Tooltip>,
            ].filter(Boolean),
          }}
        />
      </div>
    </div>
  );
}

export default LiveTripsView;
