import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { uniq } from "lodash";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { Modal, message, Form, notification } from "antd";

import {
  DegStep,
  UploadStep,
  OverviewStep,
  DegShiftStep,
} from "./modalComponents";
import {
  FILTER_REDUCER,
  ANALYTICS_REDUCER,
  getShiftAnalytics,
  getNotFoundEmployees,
  getEmployeeAnalytics,
  distributeNoJobsiteCost,
  matchDataFromFingerCheck,
} from "./modalComponents/utils";
import {
  Stepper,
  MondayButton,
  WarningModal,
  MultiLevelTreeLogs,
} from "../../../../../commonComponents";
import ACTIONS_REGISTER_REDUCER, {
  ACTIONS_REGISTER_INIT,
} from "./modalComponents/utils/actionsRegister";
import {
  DeleteIcon,
  WarningTriangle,
} from "../../../../../SidebarPages/DynamicView/src";
import PayrollContext from "../../../PayrollContext";
import {
  FILTER_INIT,
  REDUCER_INIT,
} from "./modalComponents/utils/reducerInitialStates";
import { useEditLogs } from "../../../../../../hooks";
import { addDataToArray } from "./modalComponents/utils";
import { useEntriesApi } from "../../../../PayrollLive/utils";
import DegModalContext from "./modalComponents/DegModalContext";
import { OVERHEAD_ENTRY_TYPES, footerButtons } from "./degModalData";
import { keysToValidate } from "./modalComponents/utils/cellFunctions";
import { DEG_DATE_FORMAT } from "./modalComponents/utils/cellFunctions";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { preferences as setPreferences } from "../../../../../../actions";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";
import {
  lazyFetch,
  fetchByList,
  fetchAllData,
  gsiQueryTable,
} from "../../../../../../utils";
import { withinRadius } from "../../Activity/components/payrollActivityModalData";
import { getChangedData } from "src/components/SidebarPages/Accounting/components/utilities";
import {
  parseInTz,
  setHourMinute,
} from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";

import "./DegModal.scss";

const Steps = {
  0: UploadStep,
  1: DegStep,
  2: DegShiftStep,
  3: OverviewStep,
};

let shiftDegFilterObj = {
  payrollType: [],
  shiftStatus: [],
  employeeRole: [],
  timeFrame: undefined,
  selectedJobsites: [],
  selectedEmployees: [],
};

function DegModal({
  open,
  onCancel,
  editMode,
  rowToEdit,
  refreshTable,
  degStepFilters,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences } = useSelector((state) => state.preferences);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const allServiceOptions = useSelector((state) => state.serviceDefinitions);
  // const programFields = useSelector(
  //   (store) => store.programFields.programFields
  // );

  const {
    degPayrolls,
    crewTeams: teams,
    degAccessRights,
  } = useContext(PayrollContext);

  const [crews, setCrews] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [foremans, setForemans] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [jobsites, setJobsites] = useState([]);
  const [notFound, setNotFound] = useState({});
  const [editLogs, setEditLogs] = useState([]);
  const [emptyData, setEmptyData] = useState([]);
  const [degGridApi, setDegGridApi] = useState();
  const [uploading, setUploading] = useState(false);
  const [entriesLogs, setEntriesLogs] = useState({});
  const [degColumnApi, setDegColumnApi] = useState();
  const [shiftsGridApi, setShiftsGridApi] = useState();
  const [jobsiteServices, setJobsiteServices] = useState({});
  const [warningVisible, setWarningVisible] = useState(false);
  const [scheduleMatching, setScheduleMatching] = useState({});
  const [logsModalVisible, setLogsModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(rowToEdit ? 1 : 0);
  const [modalStatusVisible, setModalStatusVisible] = useState(false);
  const [filtersFromSelection, setFiltersFromSelection] = useState({});
  const [degStatus, setDegStatus] = useState(rowToEdit?.degStatus || "Draft");
  const [defaultServicesPerProject, setDefaultServicesPerProject] = useState(
    {}
  );

  const [shiftExternalFilter, updateShiftExternalFilter] =
    useState(shiftDegFilterObj);
  const [modalSteps] = useState([
    { title: "Entries", disabled: true },
    { title: "Audit", disabled: true },
    { title: "Time Cards", disabled: true },
    { title: "Overview", disabled: true },
  ]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { updateEntries, removeEntries } = useEntriesApi();
  const { saveAddedLogs } = useEditLogs();

  const saveButton = useRef();
  const initialRowData = useRef([]);
  const stopUploadingButton = useRef();

  const [analytics, analyticsUpdate] = useReducer(
    ANALYTICS_REDUCER,
    REDUCER_INIT
  );

  const [warningFilters, filtersDispatch] = useReducer(
    FILTER_REDUCER,
    FILTER_INIT
  );

  const [actionsRegister, addActionToRegister] = useReducer(
    ACTIONS_REGISTER_REDUCER,
    ACTIONS_REGISTER_INIT
  );

  const formDegName = Form.useWatch("degName", form);
  const accountName = Form.useWatch("accountName", form);

  const crewTeams = useMemo(() => {
    return teams.filter(
      (el) =>
        el?.company === accountName || el?.company === rowToEdit?.companyName
    );
  }, [teams, accountName, rowToEdit]);

  const deleteAccessRight = useMemo(() => {
    const routeConfig = userConfiguration?.routeConfig || [];
    let projectCostIndex = routeConfig.findIndex(
      ({ title }) => title === "Project Cost"
    );

    let activityIndex = routeConfig[projectCostIndex]?.children.findIndex(
      ({ title }) => title === "Activity"
    );

    let deleteAccessIndex = routeConfig[projectCostIndex]?.children[
      activityIndex
    ]?.children?.findIndex(({ title }) => title === "Delete");

    if (deleteAccessIndex === -1 || deleteAccessIndex === undefined)
      return null;

    return routeConfig[projectCostIndex].children[activityIndex].children[
      deleteAccessIndex
    ]?.write;
  }, [userConfiguration]);

  const degName = formDegName
    ? formDegName
    : rowToEdit?.degName
    ? rowToEdit?.degName
    : analytics?.dateRange?.[0]
    ? `Week(${dayjsNY(analytics?.dateRange?.[0]).week()}) (${dayjsNY(
        analytics?.dateRange?.[0]
      ).format(DEG_DATE_FORMAT)} - ${dayjsNY(analytics?.dateRange?.[1]).format(
        DEG_DATE_FORMAT
      )})`
    : "New Deg";

  const isLastStep = currentStep === 3;

  const newEditLog = {
    currentData: {},
    previousData: {},
    actionType: "Edit",
    category: "DEG Logs",
    recordId: rowToEdit?.degId,
    recordName: rowToEdit?.degName,
  };

  /**
   * @param {boolean} show
   */
  function toggleNotifications(show) {
    const hideNotificationsPref = {
      ...preferences,
      preferences: {
        ...preferences?.preferences,
        notifications: {
          ...preferences?.preferences.notifications,
          allowNotifications: show,
        },
      },
    };
    dispatch(setPreferences(hideNotificationsPref));
  }

  async function getEntryLogs(entryId) {
    message.loading({
      duration: 5,
      key: "getEntryLogs",
      content: "Getting logs...",
    });
    try {
      const logs = await fetchAllData({
        resultId: "logId",
        endpoint: "editLogs",
        resultPosition: "editLogs",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  formula: "is",
                  value: entryId,
                  column: "recordId",
                },
              ],
            },
          ]),
        },
      });
      setEntriesLogs((prev) => ({ ...prev, [entryId]: logs }));
      message.success({
        duration: 1.5,
        key: "getEntryLogs",
        content: "Logs fetched successfully!",
      });
    } catch (err) {
      console.log("Error getting entryLogs: ", err);
      message.error({
        duration: 1.5,
        key: "getEntryLogs",
        content: "There was a problem getting Logs for this entry",
      });
    }
  }

  async function getEntriesInEdit(rowToEdit) {
    let tmpData = [];

    tmpData = await gsiQueryTable({
      filterKey: "degId",
      tableName: "degEntries",
      indexName: "degId-index",
      filterValue: rowToEdit?.degId,
    });

    return tmpData;
  }

  // #region Fetch initial data
  useEffect(() => {
    const originalNotification = { ...notification };
    Object.assign(notification, {
      open: function () {},
      info: function () {},
      error: function () {},
      config: function () {},
      success: function () {},
    });

    Promise.allSettled([
      lazyFetch({
        tableName: "accounts",
        filterValue: "Subcontractors",
        filterKey: "accountRecordType",
        listOfKeys: ["accountName", "accountId", "accountRecordType"],
      }),
      API.get("crews", "/crews"),
      API.get("jobsites", "/jobsites"),
      rowToEdit?.degId &&
        fetchAllData({
          endpoint: "editLogs",
          resultPosition: "editLogs",
          resultId: "logId",
          otherStringParams: {
            getMaxLimit: "true",
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "recordId",
                    value: rowToEdit?.degId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        }),
    ])
      .then(
        ([
          { value: accountsRes },
          { value: crewsRes },
          { value: jobsitesRes },
          { value: editLogsRes },
        ]) => {
          setAccounts(accountsRes);
          setForemans(
            (crewsRes || []).filter(({ members }) => !!members?.length)
          );
          setCrews(
            (crewsRes || []).filter(
              (crew) => !!crew?.employeeId && crew?.crewStatus === "Active"
            )
          );
          setJobsites(
            (jobsitesRes || []).map((jobsite) => {
              if (Array.isArray(jobsite?.services)) {
                return jobsite;
              } else {
                return { ...jobsite, services: [] };
              }
            })
          );
          setEditLogs(editLogsRes || []);
        }
      )
      .catch((err) => {
        console.log("Could not get data: ", err);
      });

    function unloadHandler(e) {
      e.preventDefault();
      e.returnValue = "";
    }
    toggleNotifications(false);

    window.addEventListener("beforeunload", unloadHandler);

    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
      Object.assign(notification, originalNotification);
    };
  }, []);

  //#region Get services from schedules or projects that are connected with the jobsites in this payroll and checks for empty data
  useEffect(() => {
    let tmpList = [];
    let jobsiteIdsIncluded = [];

    updateStatisticsHandler();

    if (Object.keys(defaultServicesPerProject)?.length) {
      return;
    }

    for (const key in rowData) {
      let tmpRow = rowData?.[key];
      let condition = false;

      // Get all unique jobsiteId-s included in payroll rowData
      if (
        !jobsiteIdsIncluded.includes(tmpRow?.jobsiteId) &&
        !!tmpRow?.jobsiteId
      ) {
        jobsiteIdsIncluded.push(
          tmpRow?.jobsiteId ? tmpRow?.jobsiteId : tmpRow?.jobSiteId
        );
      }

      if (tmpRow?.["punchType"] !== "HR") {
        for (const entry of keysToValidate) {
          if (typeof tmpRow?.[entry] === "string") {
            tmpRow[entry] = tmpRow?.[entry].trim();
          }

          if (!tmpRow?.[entry]) {
            condition = true;
            break;
          }
        }
        if (condition) {
          tmpList.push(tmpRow?.["entryId"]);
        }
      }
    }

    if (
      !!rowData?.length &&
      !!jobsites?.length &&
      !Object.keys(jobsiteServices)?.length
    ) {
      // Object that will store jobsite connection with projects
      // where projectId will be the key and jobsiteId will be the value {[projectId]: jobsiteId}
      const projectsIncluded = {};
      const tmpJobsiteServices = Object.keys(jobsiteServices);

      // for each jobsite included in payroll get projectId of that jobsite and add to the projectsIncluded Object
      for (let jobsite of jobsites) {
        if (
          jobsiteIdsIncluded.includes(jobsite?.jobsiteId) &&
          !tmpJobsiteServices.includes(jobsite?.jobsiteId) &&
          !!jobsite?.projectId
        ) {
          Object.assign(projectsIncluded, {
            [jobsite?.projectId]: jobsite?.jobsiteId,
          });
        }
      }

      message.loading({
        duration: 8,
        key: "fetchByList",
        content: "Getting Services for all Job Sites...",
      });

      // Get all schedules that are connected to the projects of jobsites included in this payroll
      fetchByList(
        "scheduling",
        "projectId",
        Object.keys(projectsIncluded).slice(0, 20)
      )
        .then((schedules) => {
          if (!schedules?.length) {
            return;
          }
          message.success({
            duration: 1,
            key: "fetchByList",
            content: "Services fetched successfully!",
          });

          // eslint-disable-next-line no-undef
          const jobsiteServicesInstance = structuredClone(jobsiteServices);
          // eslint-disable-next-line no-undef
          const scheduleMatchInstance = structuredClone(scheduleMatching);
          for (const schedule of schedules) {
            // jobsiteId that will connect to this schedule
            let scheduleJobsiteId = projectsIncluded[schedule?.projectId];

            // map schedule per jobsiteId match
            Object.assign(scheduleMatchInstance, {
              [scheduleJobsiteId]: schedule,
            });

            let dateRange = [];
            let estimationNumber;
            let scheduleServices = [];
            const { scheduleDays, toBeScheduled } = schedule;

            // Get time range of this schedule
            for (let day of scheduleDays) {
              dateRange.push(dayjsNY(day?.startDate).valueOf());
              dateRange.push(dayjsNY(day?.endDate).valueOf());
            }

            // get services included in this schedule and estimation number
            for (let estimation in toBeScheduled) {
              scheduleServices = scheduleServices.concat(
                toBeScheduled[estimation]?.map(({ label }) => label)
              );
              estimationNumber =
                toBeScheduled[estimation]?.[0]?.estimationNumber;
            }

            dateRange = [Math.min(...dateRange), Math.max(...dateRange)];
            const scheduleServicesData = {
              dateRange,
              scheduleServices,
              estimationNumber,
            };
            // for this jobsite id add the schedule services for the dateRange of the schedule
            Object.assign(jobsiteServicesInstance, {
              [scheduleJobsiteId]: (
                jobsiteServicesInstance?.[scheduleJobsiteId] || []
              ).concat(scheduleServicesData),
            });
          }

          setJobsiteServices(jobsiteServicesInstance);
          setScheduleMatching(scheduleMatchInstance);
        })
        .catch((err) => {
          console.log("err: ", err);
          message.destroy();
        });

      // Get services per project for all projects included in this payroll
      fetchByList("projects", "projectId", Object.keys(projectsIncluded))
        .then((projects) => {
          if (!projects?.length) {
            return;
          }
          // Container that stores services per jobsiteId {[jobsiteId]: <services: string>[]}
          const tmpServicesPerProject = {};
          for (let project of projects) {
            // jobsiteId connected to this project
            let projectJobsiteId = projectsIncluded[project.projectId];
            // if project has services add services for this jobsiteId
            if (Object.keys(project?.services || {})?.length) {
              let tmpServices = [];
              Object.keys(project?.services)?.forEach((estimation) => {
                let estServices = (project?.services?.[estimation] || [])?.map(
                  ({ label }) => label
                );
                tmpServices = tmpServices.concat(estServices || []);
              });
              Object.assign(tmpServicesPerProject, {
                [projectJobsiteId]: uniq(tmpServices),
              });
            } else {
              Object.assign(tmpServicesPerProject, {
                [projectJobsiteId]: [],
              });
            }
          }
          setDefaultServicesPerProject(tmpServicesPerProject);
        })
        .catch((err) => {
          console.log("Default project services Error: ", err);
        });
    }

    const tmpEmptyData = tmpList.filter((entry) => {
      if (
        entry?.punchType !== "HR" ||
        entry?.punchType !== "CASH" ||
        entry?.punchType !== "1099"
      ) {
        const emptyFields = keysToValidate.filter((key) => {
          if (key === "jobsiteMatch") {
            return !entry?.jobsiteMatch?.jobsiteId;
          }
          return !entry[key] && !entry[key]?.length && entry[key] !== 0;
        });
        if (emptyFields?.length) {
          return true;
        }
        return false;
      }
      return false;
    });
    setEmptyData(
      // tmpList.filter((entry) => OVERHEAD_ENTRY_TYPES.includes(entry?.punchType))
      tmpEmptyData
    );
  }, [rowData, jobsites, JSON.stringify(defaultServicesPerProject)]);

  // #region Updates DEG on edit
  useEffect(() => {
    if (rowToEdit) {
      form.setFieldValue("degName", rowToEdit?.degName);
      if (rowToEdit?.degId && !!jobsites?.length) {
        // Get entries or costAnalytics if the Payroll is Completed
        getEntriesInEdit(rowToEdit)
          .then((res) => {
            let dates = [];
            const editRowData = res?.map((data) => {
              if (!isNaN(Number(data?.punchTimeStamp))) {
                dates.push(data?.punchTimeStamp);
              }

              // Find distance and duration for ID entries
              if (
                data?.jobsiteId &&
                data?.punchType === "ID" &&
                data?.punchCoordinates?.lat &&
                !data?.duration
              ) {
                const jobsiteIndex = jobsites.findIndex(
                  ({ jobsiteId }) => jobsiteId === data.jobsiteId
                );
                const jobsiteCoordinates =
                  jobsites?.[jobsiteIndex]?.addressPosition;

                if (jobsiteCoordinates?.lat) {
                  Object.assign(data, {
                    distanceFromJob: withinRadius(
                      jobsiteCoordinates,
                      data?.punchCoordinates
                    ).distanceInFeet,
                    duration:
                      withinRadius(jobsiteCoordinates, data?.punchCoordinates)
                        .distanceInFeet / 3.5,
                  });
                }
              }

              const entryEmployee = crews.find(({ employeeId }) => {
                let employeeIdSplit = employeeId.split("-");
                let modifiedEmployeeId = `${employeeIdSplit[0]}-${parseFloat(
                  employeeIdSplit[1]
                )}`;
                const employeeMatch =
                  data?.employeeId === employeeId ||
                  data?.employeeId === modifiedEmployeeId;

                return employeeMatch;
              });

              const employeeRate = data?.employeeRate
                ? data?.employeeRate
                : entryEmployee?.employeeRate;

              if (!data?.company) {
                const company = data?.employeeId?.split?.("-")?.[0];

                return {
                  ...data,
                  company,
                  employeeRate,
                  companyName: company,
                  punchTime: data?.punchTime && dayjsNY(data.punchTime),
                  punchDate: data?.punchDate && dayjsNY(data.punchDate),
                };
              } else {
                return {
                  ...data,
                  employeeRate,
                  punchTime: data?.punchTime && dayjsNY(data.punchTime),
                  punchDate: data?.punchDate && dayjsNY(data.punchDate),
                };
              }
            });

            setRowData((prev) => {
              const newRowData = editRowData.concat(prev);
              initialRowData.current = newRowData;
              return newRowData;
            });

            if (degGridApi) {
              degGridApi.hideOverlay();
            }
            analyticsUpdate({
              dateRange: [Math.min(...dates), Math.max(...dates)],
            });
          })
          .catch((err) => {
            console.log("Error getting data for this deg: ", err);
            if (degGridApi) {
              degGridApi.hideOverlay();
            }
          });
      }
    }
  }, [rowToEdit, jobsites]);

  // #region Checks for employees that does not match with any of the employees in the system
  useEffect(() => {
    if (!Object.keys(notFound).filter((el) => el !== "noEmployeeId")?.length) {
      let notFoundList = getNotFoundEmployees({
        crews,
        rowData,
        gridApi: degGridApi,
      });

      const { noEmployeeId = [] } = notFoundList;

      let notFoundIds = Object.keys(notFoundList)?.filter(
        (key) => key !== "noEmployeeId"
      );
      setNotFound(notFoundList);

      if (notFoundIds?.length || noEmployeeId?.length) {
        message.warning({
          duration: 4,
          key: "notFoundWarning",
          content: "Careful, some employee names are not registered!",
        });
      }
    }
  }, [degGridApi, crews, rowData]);

  /**
   * Function that creates a delay in runtime.
   *
   * @param {number} delay Delay time in milliseconds.
   */
  function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  // #region onSave function
  /**
   * Function that saves DEG based on status.
   *
   * DEG entries can be saved with status Completed only when all entries have the status Completed
   *
   * @param {string} status
   */
  const onSave = useCallback(
    async function (status = degStatus) {
      if ((degName || "")?.length < 3) {
        message.error("Please add a DEG name (min. 3 characters)");
        return;
      }

      setUploading(true);

      message.loading({
        duration: 0,
        key: "degSaveMessage",
        content: "Saving DEG...",
      });

      // Create an Id used for new DEG creation
      const degId = uuid();

      // Initial data of the DEG body
      const bodyObj = {
        degId: rowToEdit?.degId ? rowToEdit?.degId : degId,
        degName: degName,
        degStatus: status,
        overtimeNames: [],
        toDate: analytics.dateRange[1],
        fromDate: analytics.dateRange[0],
        companyName: rowData?.[0]?.company,
        createdBy: {
          nameOfUser: userConfiguration.nameOfUser,
          identityId: userConfiguration.identityId,
        },
      };

      // on Edit mode
      if (rowToEdit) {
        delete bodyObj?.degId;
        try {
          // Update DEG
          await API.put("deg", `/deg/${rowToEdit?.degId}`, {
            body: rowToEdit?.company
              ? bodyObj
              : { ...bodyObj, companyName: rowData?.[0]?.company },
          });

          // Get edit logs for DEG
          let currentObject = {};
          let previousObject = {};

          for (let key in bodyObj) {
            if (
              key.includes("Id") ||
              key === "createdAt" ||
              key === "createdBy"
            ) {
              continue;
            } else if (key.includes("Date")) {
              Object.assign(currentObject, {
                [key]: dayjsNY(bodyObj?.[key]).format(DEG_DATE_FORMAT),
              });
              Object.assign(previousObject, {
                [key]: dayjsNY(rowToEdit?.[key]).format(DEG_DATE_FORMAT),
              });
            } else {
              Object.assign(currentObject, { [key]: bodyObj?.[key] });
              Object.assign(previousObject, { [key]: rowToEdit?.[key] });
            }
          }

          const results = getChangedData(currentObject, previousObject);
          newEditLog.currentData = results.curr;
          newEditLog.previousData = results.prev;

          saveAddedLogs(newEditLog);

          broadcastNotification("112", "onPayrollUpdate", [
            {
              degName: bodyObj?.degName,
              degStatus: bodyObj?.degStatus,
              common: userConfiguration?.nameOfUser,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
            },
          ]);

          // Update DEG grid with the updated data
          refreshTable({ ...bodyObj, degId: rowToEdit?.degId }, "edit");
          message.success({
            content: `${degName} have been saved successfully!`,
            key: "degSaveMessage",
          });
        } catch (err) {
          message.error({
            content: "Something went wrong while trying to update the payroll",
            key: "degSaveMessage",
          });
          console.log("Error updating entries: ", err);
        }

        setUploading(false);
        toggleNotifications(true);
        analyticsUpdate({ reset: true });
        setTimeout(() => {
          onCancel();
        }, 100);
      } else {
        const bodyOfDeg = { ...bodyObj };
        if (rowData?.length) {
          updateEntries({
            entries: rowData.map((el) => ({ ...el, degId: bodyObj?.degId })),
          });
        }
        // Create New DEG
        API.post("deg", "/deg", {
          body: bodyOfDeg,
        })
          .then((res) => {
            // Get edit logs of this DEG
            let currentObject = {};
            for (let key in res) {
              if (
                key.includes("Id") ||
                key === "createdAt" ||
                key === "createdBy"
              ) {
                continue;
              } else if (key.includes("Date")) {
                Object.assign(currentObject, {
                  [key]: dayjsNY(res?.[key]).format(DEG_DATE_FORMAT),
                });
              } else {
                Object.assign(currentObject, { [key]: res?.[key] });
              }
            }
            const results = getChangedData(currentObject, {});
            newEditLog.currentData = results?.curr;
            newEditLog.previousData = results?.prev;
            newEditLog.actionType = "Create";

            saveAddedLogs(newEditLog);

            message.destroy();
            message.success({
              content: "DEG saved successfully!",
              key: "degSaveMessage",
            });

            broadcastNotification("112", "onPayrollCreation", [
              {
                common: userConfiguration?.nameOfUser,
                degName: bodyObj?.degName,
              },
              {
                userName: userConfiguration?.nameOfUser,
                currentUser: userConfiguration?.cognitoUserId,
              },
            ]);

            // Update DEG Grid with the newly created DEG
            refreshTable([res]);
            analyticsUpdate({ reset: true });
            toggleNotifications(true);
            setTimeout(() => {
              onCancel();
              setUploading(false);
            }, 100);
          })
          .catch((err) => {
            message.error({
              duration: 3,
              key: "degSaveMessage",
              content: "There was a problem saving this DEG!",
            });
            console.log("Error: ", err);
          });
      }
    },
    [rowToEdit, userConfiguration, degName, analytics, rowData]
  );

  /**
   * Function that deletes the Payroll
   */
  // #region onDelete function
  async function onDelete() {
    if (!rowToEdit?.degId) {
      let entriesChunks = [];
      for (let i = 0; i < rowData?.length; i += 1000) {
        entriesChunks.push(rowData.slice(i, i + 1000));
      }

      // Delete all entries of the deleted DEG
      for (let i = 0; i < entriesChunks?.length; i++) {
        const chunk = entriesChunks?.[i]?.map?.(({ entryId }) => entryId) || [];
        if (i > 0) {
          await sleep(1000);
        }
        await API.del("degEntries", `/degEntries/123`, {
          body: {
            entries: chunk,
          },
        })
          .then((res) => console.log("Entries deleted successfully: ", res))
          .catch((err) => console.log("Error: ", err));
      }
      onCancel();
      return;
    }

    API.del("deg", `/deg/${rowToEdit?.degId}`)
      .then(async () => {
        message.loading({
          duration: 0,
          key: "deletingEntries",
          content: "Deleting Entries...",
        });
        // Ged edit logs of the deletion
        let previousObject = {};
        for (let key in rowToEdit) {
          if (
            key.includes("Id") ||
            key === "createdAt" ||
            key === "createdBy"
          ) {
            continue;
          } else if (key.includes("Date")) {
            Object.assign(previousObject, {
              [key]: dayjsNY(rowToEdit?.[key]).format(DEG_DATE_FORMAT),
            });
          } else {
            Object.assign(previousObject, { [key]: rowToEdit?.[key] });
          }
        }
        const results = getChangedData({}, previousObject);
        newEditLog.currentData = results.curr;
        newEditLog.previousData = results.prev;
        newEditLog.actionType = "Delete";

        // Split entries into chunks of 1000 for smaller API calls
        let entriesChunks = [];
        for (let i = 0; i < rowData?.length; i += 1000) {
          entriesChunks.push(rowData.slice(i, i + 1000));
        }

        // Delete all entries of the deleted DEG
        for (let i = 0; i < entriesChunks?.length; i++) {
          const chunk =
            entriesChunks?.[i]?.map?.(({ entryId }) => entryId) || [];
          if (i > 0) {
            await sleep(1000);
          }
          await API.del("degEntries", `/degEntries/123`, {
            body: {
              entries: chunk,
            },
          })
            .then((res) => console.log("Entries deleted successfully: ", res))
            .catch((err) => console.log("Error: ", err));
        }
        saveAddedLogs(newEditLog);

        broadcastNotification("112", "onPayrollDelete", [
          {
            common: userConfiguration?.nameOfUser,
            degName: rowToEdit?.degName,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
          },
        ]);

        // Updates the DEG grid with the removed DEG
        refreshTable(rowToEdit, "delete");
        setWarningVisible(false);
        toggleNotifications(true);
        message.success({
          duration: 1.5,
          key: "deletingEntries",
          content: "DEG deleted successfully!",
        });
        onCancel();
      })
      .catch((err) => {
        console.log("Error: ", err);
        message.error({
          duration: 1.5,
          key: "deletingEntries",
          content: "There was a problem deleting this DEG!",
        });
      });
  }

  /**
   * This function changes the current step of the Stepper
   *
   * @param {number} step The next step
   */
  function stepChangeHandler(step) {
    setCurrentStep(step);

    // if (step === 2 && degGridApi && currentStep < 2) {
    if (step === 2 && currentStep < 2) {
      let tmp = [];
      let dates = [];

      // Get all nodes of entries grid and updates the date range of the Payroll and rowData with the grid state
      rowData.forEach((data) => {
        tmp.push({
          ...data,
          punchTime: data?.punchTime && parseInTz(data?.punchTime),
          punchTimeStamp:
            data?.punchTime && parseInTz(data?.punchTime).valueOf(),
        });
        if (!isNaN(Number(data?.punchTimeStamp))) {
          dates.push(data?.punchTimeStamp);
        }
      });
      // setRowData(tmp);
      // recalculates analytics for all entries
      analyticsUpdate({
        employeeList: crews,
        dateRange: [Math.min(...dates), Math.max(...dates)],
      });
    }

    if (emptyData?.length) {
      message.warning({
        duration: 3,
        key: "uncompletedData",
        content: "Careful, there is incomplete data in the DEG!",
      });
    }
  }

  /**
   * Updates entry with the given data
   *
   * @param {Object} data Entry object that will update the entry that matches the entryId
   */
  function updateData(data) {
    message.loading({
      duration: 0,
      key: "updateData",
      content: "Updating entries...",
    });
    if (data?.punchType !== "HR") {
      const emptyFields = keysToValidate.filter(
        (key) => !data[key] && !data[key]?.length && data[key] !== 0
      );
      if (emptyFields?.length) {
        setEmptyData(emptyData.concat(data.entryId));
      } else {
        const emptyDataToFilter = emptyData.filter(
          (entryId) => entryId !== data.entryId
        );
        setEmptyData(emptyDataToFilter);
      }
    }
    if (data?.employeeId) {
      updateStatisticsHandler([data?.employeeId]);
    }
    const shift =
      analytics?.employeesHoursPerDay?.[data.employeeId]?.[
        dayjsNY(data.punchDate).format("MM/DD/YYYY")
      ];

    let shiftEntries = [];
    let oldShiftEntries = [];
    if (shift) {
      rowData.forEach((activity) => {
        if (
          activity.entryId !== data.entryId &&
          activity?.employeeId === data?.employeeId &&
          dayjsNY(activity.punchDate).format("MM/DD/YYYY") ===
            dayjsNY(shift?.firstClockIn).format("MM/DD/YYYY") &&
          dayjsNY(shift.firstClockIn).valueOf() <=
            dayjsNY(activity?.punchTime).valueOf() <=
            dayjsNY(shift.firstClockIn)
              .add(
                shift.breakHours + shift.overtimeHours + shift?.workHours,
                "Hour"
              )
              .valueOf()
        ) {
          shiftEntries.push(activity);
          oldShiftEntries.push(activity);
        }

        if (activity.entryId === data.entryId) {
          oldShiftEntries.push(activity);
        }
      });
    }

    let editActions = [];
    const dataToUpdate = shiftEntries
      .map((entry) => Object.assign(entry, { sow: data?.sow }))
      .concat(data);

    dataToUpdate.forEach((data) => {
      const prevIndex = oldShiftEntries.findIndex(
        (el) => el.entryId === data.entryId
      );
      const prev = oldShiftEntries[prevIndex];
      editActions.push({ type: "edit", curr: data, prev });
    });
    // addEntryAction({ type: "edit", entry: dataToUpdate });
    updateEntries({
      entries: dataToUpdate.map((el) => {
        const { duration, userId, ...returnData } = el;
        return returnData;
      }),
      onSuccessCallback: () => {
        setRowData((prev) =>
          prev.map((el) => {
            const idx = dataToUpdate.findIndex(
              (e) => e?.entryId === el?.entryId
            );
            if (idx > -1) {
              return dataToUpdate[idx];
            } else {
              return el;
            }
          })
        );
        addActionToRegister({ type: "edit", editActions });
        message.success({
          duration: 3,
          key: "updateData",
          content: "Entry updated successfully!",
        });
      },
      onErrorCallback: (err) => {
        console.log("Error updating entry: ", { err, data });
        message.error({
          duration: 3,
          key: "updateData",
          content: "There was a problem updating this entry",
        });
      },
    });
  }

  function massUpdateHandler(data) {
    // data the object that will give the values to all the selectedNodes
    if (degGridApi) {
      message.loading({
        duration: 0,
        key: "massUpdateHandler",
        content: "Mass updating entries...",
      });
      let listOfEmptyData = [];
      let listOfRemovedEmptyData = [];
      let employeesToUpdate = [data?.employeeId];
      // get list of nodes that will be edited
      let editedNodes = [];
      let selectedNodesData = degGridApi
        .getSelectedNodes()
        ?.map(({ data: activity }) => {
          employeesToUpdate.push(data?.employeeId);
          return activity;
        });

      // get empty fields of each edited node
      for (let i = 0; i < selectedNodesData?.length; i++) {
        const node = selectedNodesData[i];
        const oldNode = { ...node };
        // delete unedited keys
        Object.keys(data).forEach((key) => {
          if (key === "jobsiteMatch") {
            if (!data?.jobsiteMatch?.jobAddress) {
              delete data[key];
            }
          } else {
            if (!data[key] && !data[key] !== 0) {
              delete data[key];
            }
          }
        });
        const jobsiteIndex = jobsites.findIndex(
          ({ jobsiteId }) => jobsiteId === data?.jobsiteId
        );

        const distanceFromJob = withinRadius(
          jobsites?.[jobsiteIndex]?.addressPosition,
          node?.punchCoordinates
        ).distanceInFeet;

        const duration = distanceFromJob / 3.5;
        // add edited values to the node object
        if (data?.timeModifier) {
          // eslint-disable-next-line no-unused-vars
          const { timeModifier, value, unit, action, ...rest } = data;
          Object.assign(node, { ...rest, distanceFromJob, duration });
        } else {
          Object.assign(node, { ...data, distanceFromJob, duration });
        }

        if (!!data["punchDate"] && !data["punchTime"] && !!node["punchTime"]) {
          let newPunch = setHourMinute(
            data["punchDate"],
            parseInTz(node["punchTime"])
          );
          node["punchTime"] = newPunch;
          node["punchTimeStamp"] = newPunch.valueOf();
        }

        if (!data["punchDate"] && !!data["punchTime"] && !!node["punchDate"]) {
          let newPunch = setHourMinute(
            node["punchDate"],
            parseInTz(data["punchTime"])
          );
          node["punchTime"] = newPunch;
          node["punchTimeStamp"] = newPunch.valueOf();
        }

        const scheduleMatch =
          scheduleMatching?.[jobsites?.[jobsiteIndex] || node?.jobsiteId];
        if (scheduleMatch?.scheduleId) {
          const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
            (day) =>
              dayjsNY(day?.startDate).format(DEG_DATE_FORMAT) ===
              dayjsNY(node?.punchDate).format(DEG_DATE_FORMAT)
          );

          Object.assign(node, {
            scheduleId: scheduleMatch?.scheduleId,
            scheduleAddress: scheduleMatch?.scheduleAddress,
            scheduleDayId: scheduleDay?.id,
          });
        }

        if (!node?.crewTeamId) {
          const team = crewTeams.find((el) => {
            let isForeman;
            if (el?.crewForeman?.crewId) {
              isForeman = el?.crewForeman?.crewId === node?.crewId;
            }
            const isMember =
              (el?.crewMembers || []).findIndex(
                (member) => member?.crewId === node?.crewId
              ) > -1;
            return isForeman || isMember;
          });
          if (team?.crewTeamId) {
            Object.assign(node, {
              crewTeamId: team?.crewTeamId,
              crewTeamName: team?.crewTeamName,
            });
          }
        }

        const emptyFields = keysToValidate.filter(
          (key) => !node[key] && !node[key]?.length && node[key] !== 0
        );
        if (!!emptyFields?.length && data?.punchType !== "HR") {
          listOfEmptyData.push(node.entryId);
        } else {
          listOfRemovedEmptyData.push(node.entryId);
        }
        editedNodes.push({ curr: node, prev: oldNode });
      }

      if (listOfEmptyData?.length) {
        //add nodes that have empty fields
        setEmptyData(emptyData.concat(listOfEmptyData));
      }
      if (listOfRemovedEmptyData?.length) {
        const emptyDataToFilter = emptyData.filter(
          (entryId) => !listOfRemovedEmptyData.includes(entryId)
        );
        // remove completed nodes from empty data
        setEmptyData(emptyDataToFilter);
      }

      updateEntries({
        entries: selectedNodesData,
        onSuccessCallback: () => {
          message.success({
            key: "massUpdateHandler",
            content: "Entries updated successfully",
            duration: 3,
          });
          setRowData((prev) => {
            let newRowData = [];
            for (let i = 0; i < prev.length; i++) {
              const stateData = prev[i];
              const editIndex = selectedNodesData.findIndex(
                (el) => el?.entryId === stateData?.entryId
              );
              if (editIndex > -1) {
                newRowData.push(selectedNodesData[editIndex]);
              } else {
                newRowData.push(stateData);
              }
            }
            return newRowData;
          });
          addActionToRegister({ type: "edit", editActions: editedNodes });
          updateStatisticsHandler();
        },
        onErrorCallback: (err) => {
          console.log("Error mass updating entries: ", err);
          message.error({
            key: "massUpdateHandler",
            content: "There was a problem updating entries",
            duration: 3,
          });
        },
      });
    }
  }

  async function updateJobsitesHandler(newJob) {
    message.loading({
      duration: 0,
      content: "Updating Entries",
      key: "updateJobsitesHandler",
    });
    let newData = [];
    let editActions = [];
    let updatedData = [];

    let newRows = addDataToArray({
      crews,
      makeCall: false,
      array: [...rowData],
      jobsites: [...jobsites, newJob],
    });

    for (let i = 0; i < rowData.length; i++) {
      const stateData = rowData[i];
      const editIndex = newRows.findIndex(
        (el) => el?.entryId === stateData?.entryId
      );
      if (editIndex > -1) {
        updatedData.push(newRows[editIndex]);
        editActions.push({ curr: newRows[editIndex], prev: stateData });
      } else {
        newData.push(stateData);
      }
    }

    await updateEntries({
      entries: updateData,
      onErrorCallback: (error) => {
        message.error({
          duration: 3,
          key: "updateJobsitesHandler",
          content: "There was a problem updating entries",
        });
        console.log("Error updating entries: ", error);
        return;
      },
      onSuccessCallback: () => {
        message.success({
          duration: 3,
          key: "updateJobsitesHandler",
          content: "Entries Updated successfully!",
        });
        setRowData((prev) => {
          let newRowData = [];
          for (let i = 0; i < prev.length; i++) {
            const stateData = prev[i];
            const editIndex = newRows.findIndex(
              (el) => el?.entryId === stateData?.entryId
            );
            if (editIndex > -1) {
              newRowData.push(newRows[editIndex]);
            } else {
              newRowData.push(stateData);
            }
          }
          return newRowData;
        });
        addActionToRegister({ type: "edit", editActions: editActions });
        setJobsites([...jobsites, newJob]);
        updateStatisticsHandler();
      },
    });
  }

  /**
   * Creates a new Employee from DEG
   *
   * @param {Object} crew New Employee object
   * @param {Object} notFoundEntry Entry that contains the not found Employee
   */
  function updateCrewHandler(crew, notFoundEntry) {
    message.loading({
      duration: 0,
      key: "updateCrewHandler",
      content: "Updating entries...",
    });
    setCrews([...crews, crew]);

    let editActions = [];
    let rowsForEmployee = [];
    rowData.forEach((data) => {
      let pushCondition = false;
      if (notFoundEntry?.employeeId) {
        if (data?.employeeId === notFoundEntry?.employeeId) {
          pushCondition = true;
        }
      } else {
        if (
          !data?.employeeId &&
          data?.employeeFullName === notFoundEntry?.employeeFullName
        ) {
          pushCondition = true;
        }
      }

      if (pushCondition) {
        let payrollType = undefined;
        let employeeRole = crew?.crewRole;
        let employeeRate = crew?.employeeRate;
        if (data?.jobsiteMatch?.jobsiteId) {
          let jobMatched = jobsites?.find(
            ({ jobsiteId }) => jobsiteId === data?.jobsiteMatch?.jobsiteId
          );
          if (jobMatched) {
            payrollType = jobMatched?.payrollType;
            if (
              jobMatched?.payrollType !== "Open Shop" &&
              !!jobMatched["rates"][employeeRole]
            ) {
              employeeRate = Number(
                parseFloat(jobMatched["rates"][employeeRole]).toFixed(2)
              );
            }
          }
        }

        const employeeRow = {
          ...data,
          payrollType,
          employeeRate,
          crewId: crew?.crewId,
          role: crew?.crewPosition,
          employeeId: crew?.employeeId,
          employeeFullName: crew?.crewName,
          salaryType: crew?.salaryType || "Hourly",
        };

        rowsForEmployee.push(employeeRow);
        editActions.push({ curr: employeeRow, prev: data });
      }
    });

    updateEntries({
      entries: rowsForEmployee,
      onSuccessCallback: () => {
        setRowData((prev) => {
          let newRowData = [];
          for (let i = 0; i < prev.length; i++) {
            const stateData = prev[i];
            const editIndex = rowsForEmployee.findIndex(
              (el) => el?.entryId === stateData?.entryId
            );
            if (editIndex > -1) {
              newRowData.push(rowsForEmployee[editIndex]);
            } else {
              newRowData.push(stateData);
            }
          }
          return newRowData;
        });
        addActionToRegister({ type: "edit", editActions });
        message.success({
          duration: 3,
          key: "updateCrewHandler",
          content: "Entries updated successfully",
        });
      },
      onErrorCallback: (err) => {
        console.log("Error updating employee entires: ", err);
        message.error({
          duration: 3,
          key: "updateCrewHandler",
          content: "Error updating entries",
        });
      },
    });

    updateStatisticsHandler([crew?.employeeId]);
  }

  /**
   * Updates analytics data for the employees inside employeeList
   * or all employees if employeeList is undefined.
   *
   * @param {Array | undefined} employeeList List of employees to update analytics
   */
  function updateStatisticsHandler(employeeList = undefined) {
    // analyticsUpdate({ reset: true });
    getEmployeeAnalytics({
      employeeList,
      degGridApi: {},
      analyticsUpdate,
      degRows: rowData,
    });
  }

  /**
   * Updates the shift data for the employees inside employeeList
   * or all employees if employeeList is undefined
   *
   * @param {Array | undefined} employeeList
   */
  function updateShiftStatistics(employeeList) {
    getShiftAnalytics({
      employeeList,
      analyticsUpdate,
      gridApi: shiftsGridApi,
    });
  }

  const incorrectData = useMemo(() => {
    let array = [];
    for (const key in analytics?.employeeIncorrectShifts || {}) {
      array = [...array, ...(analytics?.employeeIncorrectShifts?.[key] || [])];
    }
    return array;
  }, [JSON.stringify(analytics)]);

  function updateWarningFilters(type) {
    filtersDispatch({ type });
  }

  const ActiveStep = Steps[currentStep];

  // Updates the deg status
  useEffect(() => {
    if (degStatus !== "Pending") {
      let editedActivities = rowData?.some(
        (data) =>
          data?.activityStatus === "Pending" ||
          data?.activityStatus === "Completed"
      );
      if (editedActivities && rowToEdit?.degStatus !== "Completed") {
        setDegStatus("Pending");
      }
    }
  }, [rowData, emptyData, rowToEdit]);

  useEffect(() => {
    if (Object.keys(scheduleMatching)?.length) {
      let scheduledRowData = [];
      let tmpScheduleDaysIncluded = [];

      for (let i = 0; i < rowData?.length; i++) {
        const entry = rowData[i];
        const scheduleMatched = scheduleMatching?.[entry?.jobsiteId];

        let scheduleDayMatch;

        for (let j = 0; j < scheduleMatched?.scheduleDays?.length; j++) {
          const sDay = scheduleMatched?.scheduleDays[j];
          if (
            dayjsNY(entry?.punchDate).format(DEG_DATE_FORMAT) ===
            dayjsNY(sDay.startDate).format(DEG_DATE_FORMAT)
          ) {
            scheduleDayMatch = sDay;
            if (
              tmpScheduleDaysIncluded.findIndex((day) => day.id === sDay.id) ===
              -1
            ) {
              tmpScheduleDaysIncluded.push(sDay);
            }
            break;
          }
        }
        scheduledRowData.push({
          ...entry,
          scheduleDayId: scheduleDayMatch?.id,
          scheduleId: scheduleMatched?.scheduleId,
          scheduleAddress: scheduleMatched?.scheduleAddress,
        });
      }

      setRowData(scheduledRowData);
    }
  }, [JSON.stringify(scheduleMatching)]);

  // const clientsConfig = useMemo(() => {
  //   if (programFields?.length) {
  //     let index = programFields.findIndex(
  //       (el) => el.fieldName === "Payroll Configuration"
  //     );
  //     return programFields[index]?.fieldOptions;
  //   }
  //   return [];
  // }, [programFields]);


  // async function timeMassUpdate() {
  //   const selectedClient = clientsConfig.find(
  //     (el) => el.clientName === "GMNY Construction"
  //   );

  //   const fingerCheckData = await matchDataFromFingerCheck({
  //     crews,
  //     jobsites,
  //     crewTeams,
  //     selectedClient,
  //     dateRange: analytics?.dateRange,
  //     accountName: rowToEdit?.companyName,
  //   });
  //   console.log("fingerCheckData: ", fingerCheckData);
  // }

  return (
    <DegModalContext.Provider
      value={{
        form,
        crews,
        rowData,
        degName,
        jobsites,
        setCrews,
        foremans,
        notFound,
        accounts,
        crewTeams,
        degStatus,
        emptyData,
        analytics,
        uploading,
        rowToEdit,
        setRowData,
        degGridApi,
        isLastStep,
        isDarkMode,
        updateData,
        degPayrolls,
        currentStep,
        accountName,
        setNotFound,
        entriesLogs,
        getEntryLogs,
        degColumnApi,
        setEmptyData,
        setUploading,
        setDegGridApi,
        incorrectData,
        shiftsGridApi,
        degStepFilters,
        warningFilters,
        degAccessRights,
        setDegColumnApi,
        analyticsUpdate,
        actionsRegister,
        jobsiteServices,
        scheduleMatching,
        setShiftsGridApi,
        allServiceOptions,
        massUpdateHandler,
        updateCrewHandler,
        setJobsiteServices,
        setScheduleMatching,
        addActionToRegister,
        shiftExternalFilter,
        stopUploadingButton,
        updateWarningFilters,
        filtersFromSelection,
        updateJobsitesHandler,
        updateShiftStatistics,
        updateStatisticsHandler,
        setFiltersFromSelection,
        updateShiftExternalFilter,
        defaultServicesPerProject,
        setDefaultServicesPerProject,
      }}
    >
      <Modal
        {...{
          open,
          closable: true,
          title: `${degName}`,
          closeIcon: <XIcon />,
          destroyOnClose: true,
          centered: currentStep === 0,
          ["data-testid"]: "deg-modal",
          wrapClassName: "payroll-deg-modal-wrap",
          className: `payroll-deg-modal-container ${
            isDarkMode && "payroll-deg-modal-container-dark"
          }`,
          onCancel() {
            stopUploadingButton.current.click();
            message.destroy();
            setWarningVisible("CANCEL");
          },
          footer: footerButtons({
            onCancel() {
              setWarningVisible("CANCEL");
            },
            onNextStep() {
              if (!isLastStep) {
                stepChangeHandler(currentStep + 1);
              }
            },
            onSave() {
              form
                .validateFields()
                .then(() => {
                  let completedActivities = [];
                  let allEntries = currentStep === 1 ? [] : rowData;
                  if (currentStep === 1) {
                    rowData.forEach((data) => {
                      allEntries.push(data);
                      if (data.activityStatus === "Completed") {
                        completedActivities.push(data);
                      }
                    });
                  } else {
                    completedActivities = rowData.filter(
                      (data) => data?.activityStatus === "Completed"
                    );
                  }

                  if (
                    completedActivities?.length === allEntries?.length &&
                    emptyData?.length === 0 &&
                    modalStatusVisible !== "Completed"
                  ) {
                    setModalStatusVisible(true);
                  } else {
                    onSave();
                  }
                })
                .catch((error) => {
                  message.warning({
                    key: "formValidation",
                    content: error?.errorFields?.[0].errors?.[0],
                    duration: 2,
                  });
                });
            },
            currentStep,
            isLastStep,
            onShowLogs: () => setLogsModalVisible(true),
            onReduceStep() {
              stepChangeHandler(currentStep - 1);
            },
            onDelete() {
              setWarningVisible("DELETE");
            },
            degName: degName || "",
            emptyData,
            editMode,
            degStatus,
            rowToEdit,
            uploading,
            accountName,
          }),
        }}
      >
        {/* <button onClick={timeMassUpdate}>Update</button> */}
        <Stepper
          {...{
            steps: modalSteps,
            stepRenderer: false,
            currentStep: currentStep,
            stepperClassName: "deg-stepper",
            setCurrentStep: stepChangeHandler,
          }}
        />
        <ActiveStep />
      </Modal>
      <WarningModal
        closable={true}
        title="Warning Message"
        visible={warningVisible}
        setVisible={setWarningVisible}
        className="logout-warning-modal"
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>
            {warningVisible === "CANCEL"
              ? "Are you sure you want to cancel?"
              : warningVisible === "DELETE"
              ? `Are you sure you want to delete ${rowToEdit?.degName}?`
              : ""}
          </p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              className="mondayButtonRed"
              onClick={() => setWarningVisible(false)}
              data-testid={
                warningVisible === "DELETE"
                  ? "decline-delete-btn"
                  : "decline-close-btn"
              }
            >
              {warningVisible === "DELETE" ? "Cancel" : "No"}
            </MondayButton>
            <MondayButton
              data-testid="confirm-close-btn"
              onClick={() => {
                analyticsUpdate({ reset: true });
                setTimeout(() => {
                  if (warningVisible === "CANCEL") {
                    if (uploading) {
                      stopUploadingButton.current.click();
                      setWarningVisible(false);
                    } else {
                      stopUploadingButton.current.click();
                      toggleNotifications(true);
                      message.destroy();
                      if (!rowToEdit?.degId) {
                        onDelete();
                      } else {
                        onCancel();
                      }
                    }
                  } else if (warningVisible === "DELETE") {
                    onDelete();
                  }
                }, 100);
              }}
              Icon={<TickIcon />}
            >
              {warningVisible === "DELETE" ? "Delete" : "Yes"}
            </MondayButton>
            {deleteAccessRight &&
              rowToEdit?.degStatus === "Completed" &&
              warningVisible === "DELETE" && (
                <MondayButton onClick={onDelete} Icon={<DeleteIcon />}>
                  Delete With Analytics
                </MondayButton>
              )}
          </div>
        </div>
      </WarningModal>
      {modalStatusVisible !== "Completed" && modalStatusVisible && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={modalStatusVisible}
          className="logout-warning-modal"
          setVisible={setModalStatusVisible}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              All Activities in this DEG are completed. Do you wish to change
              the status to <b>Completed</b>?
            </p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => setModalStatusVisible("Completed")}
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  distributeNoJobsiteCost({
                    crews,
                    rowData,
                    jobsites,
                    analytics,
                    removeEntries,
                    updateEntries,
                  });
                  setDegStatus("Completed");
                  setModalStatusVisible(false);
                  setTimeout(() => {
                    saveButton.current.click();
                  }, 100);
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {logsModalVisible && (
        <MultiLevelTreeLogs
          {...{
            logsData: editLogs || [],
            visible: logsModalVisible,
            setVisible: setLogsModalVisible,
            title: `${rowToEdit ? rowToEdit?.degName : "DEG"} Logs`,
          }}
        />
      )}
      <button
        id="cancelButton"
        ref={stopUploadingButton}
        style={{ display: "none" }}
      ></button>
      <button
        ref={saveButton}
        style={{ display: "none" }}
        onClick={() => onSave("Completed")}
      ></button>
    </DegModalContext.Provider>
  );
}

DegModal.propTypes = {
  editMode: PropTypes.bool,
  refreshTable: PropTypes.func,
  open: PropTypes.bool.isRequired,
  degStepFilters: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  rowToEdit: PropTypes.objectOf({
    degId: PropTypes.string.isRequired,
    degName: PropTypes.string.isRequired,
    degStatus: PropTypes.string.isRequired,
  }),
};

export default DegModal;
