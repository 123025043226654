import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Popover, Tooltip, message } from "antd";
import MondayButton from "../../../../../MondayButton/MondayButton";
import { useChartCardsContext } from "../../../../ChartCardsContext";
import { updateChartPreferences, updateProgramFields } from "../../../../utils";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { lazyFetch } from "../../../../../../../utils";
import { programFields as programFieldsDispatch } from "../../../../../../../actions";

const EditCardTitle = ({ cardConfigs }) => {
  const { id, chartTitle, isPrivate = false } = cardConfigs;

  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const [editCardTitle, setEditCardTitle] = useState(chartTitle);
  const [isSaving, setIsSaving] = useState(false);

  const { chartCategory } = useChartCardsContext();

  const { preferences } = allPreferences;
  const myCardPrefs = preferences?.OverviewCards?.onlyMyCards?.[chartCategory];

  const handleEditTitle = async () => {
    const programF = await lazyFetch({ tableName: "programFields" });

    dispatch(programFieldsDispatch(programF));

    const overviewsConfig =
      programF?.find(
        ({ fieldName }) => fieldName === "Overviews Configuration"
      ) || {};

    const overviewCards =
      overviewsConfig.fieldOptions.overviewCards[chartCategory];

    if (overviewCards.some((card) => card.chartTitle === editCardTitle)) {
      message.warning(
        `Chart Title: (${editCardTitle}) alredy is used, try using another name!`,
        5
      );
      return;
    }
    message.loading("Title saving...");
    setIsSaving(true);

    if (!!isPrivate) {
      const updatedPreferences = {
        ...preferences,
        OverviewCards: {
          ...(preferences?.OverviewCards || {}),
          onlyMyCards: {
            ...(preferences?.OverviewCards?.onlyMyCards || {}),
            [chartCategory]: myCardPrefs.map(({ isPrivate, ...card }) =>
              card.id === id ? { ...card, chartTitle: editCardTitle } : card
            ),
          },
        },
      };

      await updateChartPreferences(
        updatedPreferences,
        allPreferences,
        dispatch
      );
    } else {
      const updatedFieldOptions = {
        ...overviewsConfig?.fieldOptions,
        overviewCards: {
          ...overviewsConfig?.fieldOptions?.overviewCards,
          [chartCategory]: overviewCards.map((card) =>
            card.id === id ? { ...card, chartTitle: editCardTitle } : card
          ),
        },
      };

      await updateProgramFields(
        overviewsConfig?.fieldId,
        updatedFieldOptions,
        dispatch
      );
    }

    setIsSaving(false);
    message.success("Title Saved Successfuly!");
  };

  return (
    <Popover
      overlayClassName={`chart-cards-popover ${
        isDarkMode ? "chart-cards-popover-content-dark" : ""
      }`}
      placement="right"
      trigger="hover"
      content={
        <Tooltip title={editCardTitle ? "" : "Empty title not allowed"}>
          <div style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
            <InputComponent
              label="Edit Card Title"
              placeholder="Enter Card Title"
              value={editCardTitle}
              onChange={(e) => {
                setEditCardTitle(e.target.value);
              }}
            />
            <MondayButton
              disabled={isSaving || !editCardTitle}
              hasIcon={false}
              onClick={handleEditTitle}
              className="mondayButtonBlue"
              Icon={<CheckCircleOutlined />}
            />
          </div>
        </Tooltip>
      }
    >
      <span className="ActionElementThreeDots">Edit Title</span>
    </Popover>
  );
};

export default EditCardTitle;
