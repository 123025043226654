import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Badge, Tooltip } from "antd";
import InspectionModal from "../../../../../../../../components/AgGridComponents/InspectionModal";
import { TasksWhite } from "../../../../../../../../../../icons";
import TasksBasePage from "../../../../../../../../TaskManager/Tasks/TasksBasePage";

export const Tasks = ({ darkMode }) => {
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);
  const { scheduleOfValue } = useSelector((state) => state.scheduleOfValues);
  const [visible, setVisible] = useState(false);

  const title = "Tasks";
  const filteredTasks = loggedOnTasks.filter(
    (task) => task.taskTopic === "Schedule of values"
  );
  const thisScheduleOfValueTasks = filteredTasks.filter(
    (task) => task.taskTopicId === scheduleOfValue.scheduleId
  );

  const handleModalVisibility = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <>
      <Tooltip title="Tasks">
        <Badge count={thisScheduleOfValueTasks.length} offset={[-18, 0]}>
          <Button
            onClick={handleModalVisibility}
            className={
              darkMode ? "schOfVal-task-btn-dark" : "schOfVal-task-btn-dark"
            }
            id={"tasks-btn"}
            icon={
              <span
                style={{
                  fill: "#fff",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <TasksWhite />
              </span>
            }
          />
        </Badge>
      </Tooltip>

      <InspectionModal
        {...{
          visible,
          onCancel: () => handleModalVisibility(),
          title,
          width: 1600,
          footer: false,
          closable: true,
          className: darkMode
            ? "schOfVal-tasks-modal-dark"
            : "schOfVal-tasks-modal-light",
        }}
      >
        <div style={{ height: "65dvh" }}>
          <TasksBasePage
            {...{
              customTaskProps: {
                rowData: scheduleOfValue,
                taskTopic: "Schedule of values",
                taskTopicId: scheduleOfValue?.scheduleId,
                taskRelatedTo: scheduleOfValue?.projectName,
              },
            }}
          />
        </div>
      </InspectionModal>
    </>
  );
};
