import { Modal, Tooltip } from "antd";
import { useState } from "react";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import "./ViewsInfo.scss";
import { useSelector } from "react-redux";
import { OrderedListOutlined } from "@ant-design/icons";

const ViewsInfo = ({ topUsers, totalViews }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [isOpen, setIsOpen] = useState(false);

  const onCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Tooltip title="Views info">
        <MondayButton
          className="mondayButtonBlue"
          Icon={<OrderedListOutlined style={{ width: "1.2rem" }} />}
          onClick={() => setIsOpen(true)}
        />
      </Tooltip>

      <Modal
        title="Views Info"
        open={isOpen}
        onCancel={onCancel}
        closeIcon={<XIcon />}
        className={`viewsInfo ${isDarkMode && "viewsInfo-dark"}`}
        footer={
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={onCancel}
          >
            Close
          </MondayButton>
        }
      >
        <div className="body">
          <p>Top viewed users</p>
          {topUsers.length > 0 ? (
            <ul>
              {topUsers.map((user, index) => (
                <li key={index}>
                  <span>{user.nameOfUser}</span> - <span>{user.viewCount}</span>
                </li>
              ))}
            </ul>
          ) : (
            <span>No viewers yet</span>
          )}

          <p>
            Total Views: <span>{totalViews}</span>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ViewsInfo;
