import dayjs from "dayjs";
import { filterRowDataByTeamConfig } from "../../../../../utils";
import { compareIncluding } from "../../../utils";

export const modifyInspections = (userConfiguration, rowData) => {
  return filterRowDataByTeamConfig(
    userConfiguration,
    rowData?.map((item) => ({
      ...item,
      inspectedBy: item?.inspectedBy?.nameOfUser,
      servicesInspected: item?.servicesInspected
        ?.filter(
          ({ isInspectedFull }) =>
            !compareIncluding(String(isInspectedFull), "none")
        )
        .map(({ serviceType }) => serviceType),
    })) || []
  );
};

export const colorsForDailyInspections = {
  "Personal Injury": "#9370DB",
  "Other Trade Incident": "#32CD32",
  "Vehicle Damage": "#FFA07A",
  "General Inspection": "#6A5ACD",
  "Project Executive": "#808080",
  Test: "#FF69B4",
  "Installation Inspection": "#9370DB",
  "Pre-Installation Inspection 2": "#32CD32",
  "Post-Removal Inspection": "#FFA07A",
  "General Inspection": "#6A5ACD",
  "Maintenance Log Requirements": "#808080",
  "Pre-trip": "#3A5ACD",
  "Pre-Installation Inspection": "#AF69B4",
};

// Exported function to extract all unique inspection types from the given rowData array
export function allInspectionTypes(rowData = [], keyToGeStatues = "") {
  return [...new Set(rowData?.map((row) => row[keyToGeStatues]))]?.filter(
    Boolean
  );
}

export function uniqueStructure(rowData) {
  return [...new Set(rowData)]?.filter(Boolean);
}

// Exported function to accumulate counts of each inspection type
export function accumulateInspectionStatuses(inspectionTypes, keyToGeStatues) {
  const inspectionTypeCount = inspectionTypes.reduce((acc, cur) => {
    acc[cur[keyToGeStatues]] = (acc[cur[keyToGeStatues]] || 0) + 1;
    return acc;
  }, {});

  return inspectionTypeCount;
}

export const initialDailyInspectionData = {
  startDate: dayjs().subtract(7, "days"),
  endDate: dayjs(),
};

// Exported function to calculate and return the date range between two given dates
export function getDateRange(dateButtons) {
  const startEndFrom = dayjs(dateButtons.startDate).from(
    dateButtons.endDate,
    true
  );
  const toReturn =
    startEndFrom === "7 days"
      ? "This week"
      : startEndFrom === "a few seconds"
      ? "a day"
      : startEndFrom;
  return toReturn;
}

// Exported function to find the first and last dates from a given array of data
export const findFirstAndLastDate = (data) => {
  if (data.length !== 0) {
    data.sort(
      (a, b) => new Date(a.inspectionDate) - new Date(b.inspectionDate)
    );

    const firstData = data[0];
    const lastData = data[data.length - 1];

    return {
      startDate: dayjs(firstData.inspectionDate),
      endDate: dayjs(lastData.inspectionDate),
    };
  }
};

export const createOptionsForFilteringOfInspection = (argKey, rowData = []) => {
  let toReturn = {};

  if (argKey === "inspectionType") {
    const modified = allInspectionTypes(rowData);
    toReturn[argKey] = modified;
  } else if (argKey === "service") {
    const services = rowData?.flatMap(
      (item) => Object.keys(item?.inspectionReport?.questions ?? {}) ?? {}
    );
    const uniqueServices = [...new Set(services)];

    toReturn[argKey] = uniqueServices;
  } else if (argKey === "estimation") {
    const estimations = rowData?.flatMap((inspection) =>
      inspection?.inspectionReport?.estimations?.flatMap(
        ({ estimationNumber }) => estimationNumber
      )
    );
    const uniqueEsts = [...new Set(estimations)]?.filter(Boolean);
    toReturn[argKey] = uniqueEsts;
  } else {
    toReturn[argKey] = uniqueStructure(rowData.map((row) => row[argKey]));
  }

  return toReturn;
};
