import CurrencyInput from "react-currency-input-field";
import { Popover, Select, Tooltip } from "antd";
import { DropDownArrow } from "../../../../../Fleet/components/InputComponent/assets";
import "./PPUInputs.scss";
import { checkIfServiceIsHoist } from "../../../models/Service";

function PPUInputs({
  PD,
  selectedPriceSchemesForService,
  handlePricePerUnitChange,
  service,
  isWritable,
  defaultPPPU,
  defaultPPUChange,
  handleDefaultPricePerUnitChange,
  defaultRent,
  pricePerUnit,
  ppuChange,
  PriceSchemesType,
  defaultRentChange,
}) {
  console.log("PD?.pricingObject", PD?.pricingObject);
  return (
    <>
      {PD?.pricingObject?.priceSchemesType === PriceSchemesType.LIST &&
        !checkIfServiceIsHoist(service) && (
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <span className="priceCalculationInput priceCalculationInput-select">
              {" "}
              <span className="price-input-label">Price Scheme</span>
              <Select
                placeholder="Choose a price..."
                // style={{ width: "100%" }}
                onChange={(value) => {
                  handlePricePerUnitChange(value, service.serviceId, true);
                }}
                suffixIcon={<DropDownArrow />}
                value={(() => {
                  if (
                    PD.pricingObject.priceSchemes === undefined ||
                    PD.pricingObject.priceSchemes === null
                  )
                    PD.pricingObject.priceSchemes = [];
                  //if pricingData[0].pricingObject.prices does not have data make it an empty array
                  for (const price of selectedPriceSchemesForService[
                    service.serviceId
                  ].priceScheme.prices || []) {
                    if (
                      service.pricePerUnit?.toString() ===
                      price.value.toString()
                    ) {
                      //if price is same with this pli value return price
                      return price.name;
                    }
                  }
                  return "custom";
                })()}
              >
                {selectedPriceSchemesForService[
                  service.serviceId
                ].priceScheme?.prices?.map?.((price, index) => {
                  return (
                    // <Tooltip title={price?.name || ""}>

                    <Select.Option
                      // key={index}
                      value={price.value}
                      // style={{ width: "100%" }}
                    >
                      <Popover
                        overlayClassName="priceCalculationInput-select-popover"
                        content={
                          <div>
                            {" "}
                            {price.name} ({price.value})
                          </div>
                        }
                        title={null}
                        trigger="hover"
                      >
                        {price.name} ({price.value})
                      </Popover>
                    </Select.Option>
                  );
                })}
                <Select.Option value="custom">Custom</Select.Option>
              </Select>
            </span>
            <span className="priceCalculationInput ppu-input">
              <span className="price-input-label">Price Per Unit $</span>
              <CurrencyInput
                disabled={!isWritable}
                name="price per unit"
                value={pricePerUnit}
                onValueChange={ppuChange}
                onBlur={(e) => {
                  handlePricePerUnitChange(
                    pricePerUnit,
                    service.serviceId,
                    true
                  );
                }}
                placeholder="Type in price per unit"
                // prefix={"Rent: "}
                // prefix={"$"}
                step={1}
                decimalScale={2}
                defaultValue={0}
              />
            </span>

            {service?.serviceId === 8 && (
              <div style={{ display: "flex" }}>
                <span className="priceCalculationInput ppu-input">
                  <span className="price-input-label">Initial PPU $</span>
                  {/* <CurrencyInput
                  disabled={!isWritable}
                  name="price per unit"
                  value={defaultPPPU}
                  onValueChange={defaultPPUChange}
                  onBlur={(e) => {
                    handleDefaultPricePerUnitChange(
                      defaultPPPU,
                      service.serviceId,
                      true,
                      "PPU"
                    );
                  }}
                  placeholder="Type in price per unit"
                  // prefix={"Rent: "}
                  // prefix={"$"}
                  step={1}
                  decimalScale={2}
                  defaultValue={0}
                />{" "} */}
                  <Select
                    placeholder="Choose a price..."
                    style={{ width: "150px" }}
                    onChange={(value) => {
                      console.log("value", value);
                      defaultPPUChange(value);
                      handleDefaultPricePerUnitChange(
                        value,
                        service.serviceId,
                        true,
                        "PPU"
                      );
                      // handlePricePerUnitChange(value, service.serviceId, true);
                    }}
                    value={defaultPPPU}

                    // value={(() => {
                    //   if (
                    //     PD.pricingObject.priceSchemes === undefined ||
                    //     PD.pricingObject.priceSchemes === null
                    //   )
                    //     PD.pricingObject.priceSchemes = [];
                    //   //if pricingData[0].pricingObject.prices does not have data make it an empty array
                    //   for (const price of selectedPriceSchemesForService[
                    //     service.serviceId
                    //   ].priceScheme.prices || []) {
                    //     if (
                    //       service.pricePerUnit?.toString() ===
                    //       price.value.toString()
                    //     ) {
                    //       //if price is same with this pli value return price
                    //       return price.name;
                    //     }
                    //   }
                    //   return "custom";
                    // })()}
                  >
                    {selectedPriceSchemesForService[
                      service.serviceId
                    ].priceScheme?.prices?.map?.((price, index) => {
                      return (
                        <Select.Option key={index} value={price.value}>
                          {price.name} ({price.value})
                        </Select.Option>
                      );
                    })}
                    <Select.Option value="custom">Custom</Select.Option>
                  </Select>
                </span>{" "}
                <span className="priceCalculationInput ppu-input">
                  <span className="price-input-label">Initial Rent %</span>
                  <CurrencyInput
                    disabled={!isWritable}
                    name="price per unit"
                    value={defaultRent}
                    onValueChange={defaultRentChange}
                    onBlur={(e) => {
                      handleDefaultPricePerUnitChange(
                        defaultRent,
                        service.serviceId,
                        true,
                        "rent"
                      );
                    }}
                    placeholder="Type in price per unit"
                    // prefix={"Rent: "}
                    // prefix={"$"}
                    step={1}
                    decimalScale={2}
                    defaultValue={0}
                  />
                </span>
              </div>
            )}
          </div>
        )}
    </>
  );
}

export default PPUInputs;
