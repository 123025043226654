import { Fragment } from "react";
import { Tooltip } from "antd";

import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { formatDuration } from "../../../utils";
import { ReasonEditor } from "../components";
import { NotesEditor } from "../../AfterHoursView/components";
import { MondayButton } from "src/components/commonComponents";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { OpenLinkIcon } from "src/assets";

const columnDefs = [
  {
    headerName: "Row #",
    valueGetter({ node }) {
      return node.rowIndex;
    },
    pdfExportOptions: {
      skipColumn: true,
    },
  },
  {
    headerName: "Date",
    field: "date",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      return dayjsNY(value).startOf("day").format("MM/DD/YYYY");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return dayjsNY(value).startOf("day").format("MM/DD/YYYY");
      },
    },
  },
  {
    headerName: "Vehicle",
    field: "fleetName",
  },
  {
    headerName: "Driver",
    field: "driverName",
  },
  {
    headerName: "Idle Duration",
    field: "duration",
    cellRenderer({ value }) {
      return formatDuration(value, "minutes").text;
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return formatDuration(value, "minutes").text;
      },
    },
  },
  {
    headerName: "Idle Start Date",
    field: "beginDate",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      return dayjsNY(value).format("MM/DD/YYYY HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return dayjsNY(value).format("MM/DD/YYYY HH:mm");
      },
    },
  },
  {
    headerName: "Idle End Date",
    field: "endDate",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      return dayjsNY(value).format("MM/DD/YYYY HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return dayjsNY(value).format("MM/DD/YYYY HH:mm");
      },
    },
  },
  {
    headerName: "Idle Location",
    field: "address",
  },
  {
    headerName: "Jobsite Match",
    field: "jobsiteMatch",
  },
  {
    headerName: "Coming From",
    field: "fromLocation",
  },
  {
    headerName: "Going To",
    field: "toLocation",
  },
  {
    headerName: "Reason",
    field: "reason",
    cellEditor: ReasonEditor,
    minWidth: 200,
    editable({ data, context }) {
      return !data.reason && context?.isWritable;
    },
  },
  {
    headerName: "Notes",
    field: "notes",
    cellEditor: NotesEditor,
    minWidth: 200,
    editable({ context }) {
      return context?.isWritable;
    },
  },
  {
    headerName: "Actions",
    cellRenderer({ context, data, node }) {
      if (node?.group) {
        return "";
      }

      return (
        <Fragment>
          <Tooltip title="Go to audit">
            <MondayButton
              {...{
                className: "mondayButtonBlue",
                Icon: <OpenLinkIcon />,
                onClick() {
                  if (context?.onVehicleClick) {
                    context.onVehicleClick(data);
                  }
                },
              }}
            >
              {""}
            </MondayButton>
          </Tooltip>
          {context.isWritable && (
            <Tooltip title="Discard Event">
              <MondayButton
                {...{
                  className: "mondayButtonRed",
                  Icon: <XIcon />,
                  onClick() {
                    if (context?.onLocationDiscard) {
                      context.onLocationDiscard(data);
                    }
                  },
                }}
              >
                {""}
              </MondayButton>
            </Tooltip>
          )}
        </Fragment>
      );
    },
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
    pdfExportOptions: {
      skipColumn: true,
    },
  },
];

export const columnKeys = [
  "date",
  "fleetName",
  "driverName",
  "duration",
  "beginDate",
  "endDate",
  "address",
  "jobsiteMatch",
  "fromLocation",
  "toLocation",
  "reason",
  "notes",
];

export default columnDefs;
