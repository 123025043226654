import { AgGridReact } from "ag-grid-react";
import { darkModeRowStyleRules } from "../../../../../../../../../../../../../Estimations/DataEntryGrid/tools/formatters/GridStyles";
import React, { useCallback, useMemo, useState } from "react";
import { formatCurrency } from "src/components/SidebarPages/utils";
import { getColumns } from "./columns";
import { afterAmountAndRateChange, getRowData } from "./rowData";
import { frameworkComponents } from "../../../../../../ServiceContent/GridData";
import { Alert, Button, Divider, Tag, message } from "antd";
import { forceToNumber } from "src/components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import Swal from "sweetalert2";
import { MondayButton } from "src/components/commonComponents";
import PLIDetails from "./PLIDetails/PLIDetails";
import AdditRentalsDetails from "./AdditRentalsDetails/AdditRentalsDetails";
import AgGridMathful from "../../../../../../../../../../../Applications/ApplicationView/components/ServiceList/Service/ServiceContent/AgGridSum/AgGridMathful";
import ChangeElevationPrice from "./ChangeElevationPrice/ChangeElevationPrice";

const ElevationGridComp = (props) => {
  const {
    serviceOption = {},
    darkMode = true,
    service = {},
    serviceEvents = {},
    borderColor = "#000",
    getGridApis = () => {},
    rowData = [],
  } = props;
  console.log("ElevationGridComp", props);
  const [selected, setSelected] = useState(false); // Context state & dispatch

  const validateAllRows = useCallback(
    (params) => {
      let rowData = [];
      params.api.forEachNode((node) => rowData.push(node.data));

      let valid = true;
      let amount = 0;
      console.log("rowData", serviceOption);
      rowData?.reduce((acc, curr) => {
        acc += forceToNumber(curr?.rate);
        amount += forceToNumber(curr?.amount);
        // if (acc > 100) {
        //   valid = false;
        // }
        if (amount > serviceOption?.maxPrice) {
          valid = false;
        }
        return acc;
      }, 0);
      console.log("valid", valid);
      return true;
    },
    [serviceOption]
  );

  const autoSizeStrategy = useMemo(
    () => ({
      type: "fitCellContents",
    }),
    []
  );

  const elevationCellValueChanged = useCallback((params) => {
    let applicableField = params?.colDef?.field;
    let maxiumumValue = forceToNumber(serviceOption?.maxPrice);
    if (applicableField === "rate") {
      if (!validateAllRows(params)) {
        Swal.fire({
          title: "Warning",
          text: "The sum of all rates cannot exceed 100%",
          icon: "warning",
          confirmButtonText: "Sorry, I will fix it now!",
        });
        params.data.rate = 0;
        params.data.amount = 0; //related field
        afterAmountAndRateChange(params.data);
        params.api.refreshCells();
      } else {
        let rate = forceToNumber(params?.newValue);
        let amount = (rate / 100) * maxiumumValue;
        console.log("amounteee", {
          rate,
          amount,
          maxiumumValue,
          newValue: params?.newValue,
          params,
        });
        params.data.amount = amount;
        params.data.rate = rate;
        afterAmountAndRateChange(params.data);
        params.api.refreshCells();
        // params.node.setDataValue("amount", amount);
      }
    } else if (applicableField === "amount") {
      if (!validateAllRows(params)) {
        Swal.fire({
          title: "Warning",
          text: "The sum of all amounts cannot exceed 100%",
          icon: "warning",
          confirmButtonText: "Sorry, I will fix it now!",
        });
        params.data.rate = 0;
        params.data.amount = 0; //related field
        afterAmountAndRateChange(params.data);
        params.api.refreshCells();
      } else {
        let amount = forceToNumber(params?.newValue);
        let rate = (amount / maxiumumValue) * 100;
        // let amount = (rate / 100) * maxiumumValue;
        console.log("amounteee", {
          rate,
          amount,
          maxiumumValue,
          newValue: params?.newValue,
          params,
        });
        params.data.amount = amount;
        params.data.rate = rate;
        afterAmountAndRateChange(params.data);
        params.api.refreshCells();
        // params.node.setDataValue("amount", amount);
      }
    }
  }, []);

  const onRangeSelectionChanged = (event) => {
    let cellRanges = event.api.getCellRanges();
    // get column now
    let hasAct =
      event?.cellRanges?.[0]?.columns?.[0]?.colDef?.headerName === "Actions";
    console.log("hoist selection changed", { event, cellRanges, hasAct });

    if (hasAct || cellRanges?.length === 0) return;
    else {
      if (
        cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
        cellRanges.length > 0
      ) {
        setSelected(Object.assign(event, {}));
      } else {
        if (selected) {
          setSelected(false);
        }
      }
    }
  };

  return (
    <div>
      <Divider
        orientation="left"
        className="elevation-divider"
        style={{
          borderColor: borderColor,
          borderWidth: 0,
          borderStyle: "solid",
          color: "white",
          fontWeight: "bold",
          // backgroundColor: borderColor,
        }}
      >
        <Tag
          color={borderColor}
          style={{
            fontSize: 19,
          }}
        >
          {serviceOption?.elevationLabel || ""} -{" "}
          {serviceOption?.elevationId || ""}{" "}
        </Tag>
        <PLIDetails {...props} />
        <Tag
          color={"#0766AD"}
          style={{
            fontSize: 19,
          }}
        >
          Legacy Price: {formatCurrency(serviceOption?.legacyPrice || 0)}
        </Tag>
        <ChangeElevationPrice
          {...{
            serviceOption,
            service,
          }}
        />
      </Divider>
      {serviceOption?.elevationAddonPrice !== 0 && (
        <Alert
          message={`
          This Elevation has Addon/s that come to a total Price: ${formatCurrency(
            serviceOption?.elevationAddonPrice
          )} where tax included is ${formatCurrency(
            serviceOption?.elevationAddonTax
          )}`}
          type="info"
          showIcon
        />
      )}
      <br />
      <div
        style={{
          padding: 10,
        }}
      >
        <AgGridMathful darkMode={darkMode} event={selected} />
      </div>
      <div
        className={
          darkMode
            ? "schedule-of-values-service-content-grid-dark agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
            : "schedule-of-values-service-content-grid agGridRentalsDetailsStyleLight ag-theme-alpine"
        }
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          domLayout="autoHeight"
          undoRedoCellEditing={true}
          enableRangeSelection={true}
          rowSelection="single"
          enableCellChangeFlash={true}
          undoRedoCellEditingLimit={50}
          rowHeight={40}
          rowData={rowData}
          autoSizeStrategy={autoSizeStrategy}
          suppressMoveWhenRowDragging={true}
          onRangeSelectionChanged={onRangeSelectionChanged}
          rowDragManaged={true}
          // defaultColDef={defaultColDef}
          // rowData={getRowData({ service, serviceOption })}
          columnDefs={getColumns({
            events: serviceEvents,
            service,
            serviceOption,
          })}
          onFirstDataRendered={(params) => {
            let columnApi = params.columnApi;
            const allColumnIds = [];

            columnApi.getColumns()?.forEach((column) => {
              const colId = column?.getId();
              allColumnIds.push(colId);
            });
            try {
              columnApi.autoSizeColumns(allColumnIds, false);
            } catch (e) {
              console.log(e);
            }
          }}
          // onComponentStateChanged={(params) => {
          //   let columnApi = params.columnApi;
          //   const allColumnIds = [];

          //   columnApi.getColumns()?.forEach((column) => {
          //     const colId = column?.getId();
          //     allColumnIds.push(colId);
          //   });
          //   try {
          //     columnApi.autoSizeColumns(allColumnIds, false);
          //   } catch (e) {
          //     console.log(e);
          //   }
          // }}
          onCellValueChanged={elevationCellValueChanged}
          onGridReady={(params) => {
            params.api.sizeColumnsToFit();
            // params.api.setRowData(getRowData({ service, serviceOption }));
            // getGridApis(params.api);
          }}
          // to be updated
          // onRangeSelectionChanged={(event) =>
          //   this.onRangeSelectionChanged(event)
          // }
          frameworkComponents={frameworkComponents}
          stopEditingWhenGridLosesFocus={true}
          suppressDragLeaveHidesColumns={true}
          allowDragFromColumnsToolPanel={true}
          defaultColDef={{
            minWidth: 100,
            editable: true,
            hide: false,
            filter: true,
            sortable: false,
            resizable: true,
            autoHeight: true,
            enablePivot: true,
            enableRowGroup: false,
          }}
        />
      </div>{" "}
      <Divider
        orientation="right"
        className="elevation-divider"
        style={{
          borderColor: borderColor,
          borderWidth: 0,
          borderStyle: "solid",
          color: "white",
          fontWeight: "bold",
          // backgroundColor: borderColor,
        }}
      >
        {" "}
        <Tag
          color={"#1264A3"}
          style={{
            fontSize: 19,
          }}
        >
          Elevation PLIs: {formatCurrency(serviceOption?.price || 0)}
        </Tag>
        <Tag
          color={borderColor}
          style={{
            fontSize: 19,
          }}
        >
          Elevation Addons:{" "}
          {formatCurrency(serviceOption?.elevationAddonPrice || 0)}
        </Tag>{" "}
        <Tag
          color={borderColor}
          style={{
            fontSize: 19,
          }}
        >
          Add. Rental: {formatCurrency(serviceOption?.additionalRentals || 0)}
        </Tag>{" "}
        <Tag
          color={"#D24545"}
          style={{
            fontSize: 19,
          }}
        >
          Updated Price: {formatCurrency(serviceOption?.maxPrice || 0)}{" "}
          {serviceOption?.maxPrice !== serviceOption?.legacyPrice
            ? `where difference is $${
                forceToNumber(
                  forceToNumber(serviceOption?.maxPrice) -
                    forceToNumber(serviceOption?.legacyPrice)
                )?.toFixed(2) || 0
              }`
            : ""}{" "}
        </Tag>
      </Divider>
    </div>
  );
};

export default ElevationGridComp;
