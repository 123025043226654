export const footerButtons = ({
  onCancel,
  cancelRef,
  saveRef,
  onCreateMember,
  suffix,
  employeeType,
  createTeam,
}) => {
  return [
    {
      text: "Cancel",
      cancel: true,
      onClick: onCancel,
      className: "mondayButtonRed",
      dataTestid: "cancel-button",
      tooltipCategory: "Members",
      tooltipKey: "cancel",
      ref: cancelRef,
    },
    employeeType && {
      text: "Create Crew",
      onClick: () => console.log("clicked"),
      // onClick: onCreateCrew,
      type: "checkbox",
    },
    {
      text: createTeam ? "Next" : "Create",
      primary: true,
      onClick: onCreateMember,
      className: "mondayButton",
      suffix: suffix,
      dataTestid: "save-button",
      tooltipCategory: "Members",
      tooltipKey: "save",
      ref: saveRef,
    },
  ].filter(Boolean);
};

export function employeeFields({
  role,
  jobsites,
  isDarkMode,
  accountName,
  salaryAccess = {},
}) {
  return [
    {
      label: "Full Name",
      type: "input",
      placeholder: "Name...",
      formItemName: "crewName",
      className: "crewNameInput",
      dataTestid: "nameField",
      required: true,
    },
    {
      label: "Crew Role",
      type: "select",
      placeholder: "Select role...",
      formItemName: "crewPosition",
      dataTestid: "roleField",
      popupClassName: isDarkMode && "darkDropDown",
      className: "crewPositionInput",
      customOptions: (role || [])?.map(({ statusName }, key) => ({
        key,
        label: statusName,
        value: statusName,
      })),
      required: true,
      getPopUpContainer: document.body,
      dataTestid: "select-input",
    },
    {
      label: "Employee Type",
      type: "select",
      placeholder: "Select employee type...",
      formItemName: "foreman",
      dataTestid: "employeeTypeField",
      disabled: !accountName,
      allowClear: false,
      customOptions: [
        { key: 0, label: "Member", value: false },
        { key: 1, label: "Foreman", value: true },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
    salaryAccess?.write && {
      label: "Salary Type",
      type: "select",
      disabled: !accountName,
      placeholder: "Select salary type...",
      formItemName: "salaryType",
      dataTestid: "salaryTypeField",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
      required: true,
      getPopUpContainer: document.body,
      dataTestid: "select-input",
    },
    salaryAccess?.write && {
      label: "Pay Rate",
      type: "number",
      typeNumber: true,
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
      dataTestid: "payRateField",
      prefix: "$",
      disabled: !accountName,
      min: 0,
      required: true,
    },
    {
      label: "Employee ID",
      type: "input",
      disabled: !accountName,
      placeholder: "Employee Id...",
      formItemName: "employeeId",
      className: "employeeIdInput",
      dataTestid: "employeeIdField",
      required: true,
    },
    {
      type: "select",
      allowClear: true,
      label: "Default Job",
      formItemName: "defaultJob",
      placeholder: "Select job...",
      customOptions: (jobsites || []).map((job, key) => ({
        key,
        value: job?.jobsiteId,
        label: `${job?.jobName} (${job?.accountName?.accountName})`,
      })),
    },
  ].filter(Boolean);
}

export function employeeEmergencyContactFields() {
  return {
    formItemName: "emergencyContact",
    label: "Emergency Contact",
    type: "MultipleInputs",
    disabled: false,
    dynamicFields: [
      {
        formItemName: "fullName",
        label: "Full Name",
        type: "Input",
        disabled: false,
        rules: [{ required: false, message: "Please input the Full Name" }],
      },
      {
        formItemName: "email",
        label: "Email",
        type: "Email",
        disabled: false,
        rules: [
          {
            required: false,
            type: "email",
            message: "The input is not valid e-mail!",
          },
        ],
      },
      {
        formItemName: "phoneNumber",
        label: "Phone Number",
        type: "PhoneNumber",
        rules: [
          {
            required: false,
            message: "Please input your phone number!",
          },
          {
            pattern:
              /^1?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            message: "Please enter a valid phone number",
          },
        ],
        disabled: false,
      },
      {
        formItemName: "relationship",
        label: "Relationship",
        type: "ComboBox",
        options: ["Parent", "Spouse", "Sibling", "Child", "Friend"],
        disabled: false,
        rules: [
          {
            required: false,
            message: "Please select or enter a Relationship",
          },
        ],
      },
    ],
  };
}
