import ScheduleDay from "../models/ScheduleDay";

/**
 * @typedef {string} ScheduleDayLinksDirection
 * */

/**
 * enum ScheduleDayLinksDirection
 * */
export const ScheduleDayLinksDirection = {
  ComesFrom: "ComesFrom",
  PostponedTo: "PostponedTo",
};

/**
 * A recursive function to search for all days that are linked with this day in one direction (either ComesFrom or PostponedTo)
 * @param scheduleDayList {ScheduleDay[]}
 * @param linkedList {ScheduleDay[]}
 * @param searchType {ScheduleDayLinksDirection}
 * @return {ScheduleDay[]}
 * */
export const getScheduleDayLinkChain = (
  scheduleDayList,
  linkedList,
  searchType
) => {
  // console.log("josaas", { scheduleDayList, linkedList, searchType });

  let lastScheduleDayInArray = linkedList[linkedList.length - 1];

  // if (lastScheduleDayInArray === undefined) {
  //   throw new Error(
  //     "linkedList can't be empty. Add scheduleDay you want to search for as the first element of this array"
  //   );
  // }

  let dayToBeAddedInArray;

  switch (searchType) {
    case ScheduleDayLinksDirection.ComesFrom: {
      if (lastScheduleDayInArray.comesFromPostponed()) {
        dayToBeAddedInArray =
          lastScheduleDayInArray.findWhereComesFrom(scheduleDayList);
      }
      break;
    }
    case ScheduleDayLinksDirection.PostponedTo: {
      if (lastScheduleDayInArray.isPostponed()) {
        dayToBeAddedInArray =
          lastScheduleDayInArray.findWhereIsPostponed(scheduleDayList);
      }
      break;
    }
  }

  if (dayToBeAddedInArray === undefined) {
    return linkedList;
  } else {
    linkedList.push(dayToBeAddedInArray);
    return getScheduleDayLinkChain(scheduleDayList, linkedList, searchType);
  }
};

/**
 * This function returns all days that this day have been postponed and also days that have been postponed until this day.
 * @param scheduleDayList {ScheduleDay[]} - a list with days we can search for. Normally it is all schedule days.
 * @param scheduleDay {ScheduleDay} - the days we want to seek information
 * @return {{ComesFrom: ScheduleDay[], PostponedTo: ScheduleDay[]}}
 *
 * Can be nicely displayed via ScheduleDaysLinksView
 * */
export const getScheduleDayLinks = (scheduleDayList, scheduleDay) => {
  let ComesFrom = getScheduleDayLinkChain(
    scheduleDayList,
    [scheduleDay],
    ScheduleDayLinksDirection.ComesFrom
  );
  let PostponedTo = getScheduleDayLinkChain(
    scheduleDayList,
    [scheduleDay],
    ScheduleDayLinksDirection.PostponedTo
  );

  ComesFrom.shift(); //remove itself
  PostponedTo.shift(); //remove itself

  return { ComesFrom, PostponedTo };
};

export const someTest = () => {
  let sch1 = new ScheduleDay();

  let sch2 = sch1.createAPostponeDay();

  let sch3 = sch2.createAPostponeDay();
  let sch4 = sch3.createAPostponeDay();
};

export const correctDayNumberInScheduleDays = (scheduleDays) => {
  const originalDays = scheduleDays.filter(
    (sch) => sch.comesFromPostponed() === false
  );

  for (let i = 0; i < originalDays?.length; i++) {
    const postponedDays = getScheduleDayLinkChain(
      scheduleDays,
      [originalDays[i]],
      ScheduleDayLinksDirection.PostponedTo
    ); //comes together with added day

    for (const postponedDay of postponedDays) {
      postponedDay.day = i + 1;
    }
  }
};
