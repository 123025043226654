import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const getTotalServicesTaxAmount = (services) => {
  let totalTaxAmount = 0;
  try {
    if (Array.isArray(services) && services?.length > 0) {
      for (const service of services) {
        if (service?.isScope === true) {
          totalTaxAmount += getOtherScopeTaxAmount(service);
        } else {
          totalTaxAmount += getServiceTaxAmountInclAddons(service);
        }
      }
    }
    return totalTaxAmount;
  } catch (error) {
    console.log("there was an error calculation", error);
    return totalTaxAmount;
  } finally {
    return totalTaxAmount;
  }
};

export const getOtherScopeTaxAmount = (otherScopeService, category) => {
  let otherScopeTaxAmount = 0;
  if (category === "Schedule Of Value") {
    if (otherScopeService?.isHoist) {
      if (Array.isArray(otherScopeService?.serviceOptions)) {
        const tax = [
          ...otherScopeService?.serviceOptions?.[0]?.flatMap?.(({ amounts }) =>
            amounts?.flatMap(
              ({ price, custom_tax_amount }) =>
                +forceToNumber(price * custom_tax_amount)
            )
          ),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);

        otherScopeTaxAmount += forceToNumber(tax);
      }
    } else {
      if (Array.isArray(otherScopeService?.amounts)) {
        const tax = [
          ...otherScopeService?.amounts?.flatMap(
            ({ price, custom_tax_amount }) =>
              +forceToNumber(price * custom_tax_amount)
          ),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);

        otherScopeTaxAmount += forceToNumber(tax);
      }
    }
  } else {
    if (Array.isArray(otherScopeService?.serviceOptions)) {
      const taxAmount = [
        ...otherScopeService?.serviceOptions?.[0]?.flatMap?.(({ items }) =>
          items?.flatMap(({ taxAmount }) => +forceToNumber(taxAmount))
        ),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);

      otherScopeTaxAmount += forceToNumber(taxAmount);
    }
  }
  return otherScopeTaxAmount;
};

export const getServiceTaxAmountInclAddons = (service, category) => {
  const { serviceAddons = [], serviceOptions = [] } = service || {};
  if (category === "Schedule Of Value") {
    if (service?.isHoist) {
      let serviceTotalTaxAmount;
      if (Array.isArray(serviceOptions?.[0])) {
        serviceTotalTaxAmount = [
          ...serviceOptions?.[0]?.flatMap?.((el) =>
            (el?.amounts || []).flatMap(
              (item) =>
                +forceToNumber(
                  (item?.price || 0) * (item?.custom_tax_amount || 0)
                )
            )
          ),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);

        return forceToNumber(serviceTotalTaxAmount);
      }
    } else {
      let serviceTotalTaxAmount;
      if (Array.isArray(service?.amounts)) {
        serviceTotalTaxAmount = [
          ...service?.amounts?.flatMap(
            ({ price, custom_tax_amount }) =>
              +forceToNumber(price * custom_tax_amount)
          ),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);

        return forceToNumber(serviceTotalTaxAmount);
      }
    }
  } else {
    let additional = getAdditionalServiceRentTaxAmount(service);
    let serviceTotalTaxAmount;
    if (service?.isHoist) {
      serviceTotalTaxAmount = [
        ...serviceOptions?.[0]?.flatMap?.(
          ({ taxAmount }) => +forceToNumber(taxAmount)
        ),
        ...serviceAddons?.map(({ taxAmount }) => +taxAmount),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);
    } else {
      if (Array.isArray(serviceOptions?.[0])) {
        serviceTotalTaxAmount = [
          ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
            items
              ?.filter((el) => el?.approved === true)
              .flatMap(({ taxAmount }) => +forceToNumber(taxAmount))
          ),
          ...serviceAddons?.map(({ taxAmount }) => +taxAmount),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);
      } else {
        serviceTotalTaxAmount = [
          ...serviceOptions?.flatMap?.(({ items }) =>
            items
              ?.filter((el) => el?.approved === true)
              .flatMap(({ taxAmount }) => +forceToNumber(taxAmount))
          ),
          ...serviceAddons?.map(({ taxAmount }) => +taxAmount),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0);
      }
    }
    return forceToNumber(serviceTotalTaxAmount) + forceToNumber(additional);
  }
};

export const getAdditionalServiceRentTaxAmount = (service) => {
  let additionalRentCost = 0;
  const rentalTerms = service?.additionalRentalTerms;
  if (rentalTerms?.taxAmount > 0) {
    additionalRentCost += forceToNumber(rentalTerms?.taxAmount);
  }
  return additionalRentCost;
};

export const getServiceLegacyTaxAmount = (service, category) => {
  if (service?.isScope === true) {
    return getOtherScopeTaxAmount(service, category);
  }
  return getServiceTaxAmountInclAddons(service, category);
};
