import { cellEditingStarted } from "./AgGrid/AgGridComponents/RentalDetails/rentalDetailsFunctions";
import { masterTableLockHandler } from "./AgGrid/GridUtils/masterTableLockHandler";
import { useRedux } from "../../../../../../../../../hooks";
import { defaultColDef } from "./AgGrid/defaultColumnDef";
import { columnDefs } from "./AgGrid/columnDefs";
import { ResizableHandle } from "./Components";
import "./RentalBreakdownContent.scss";
import { RentalAgGrid } from "..";

// import { useDispatchContext, useStateContext } from "../../../../../Context/Context"

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import AgGridMathful from "../../../../../../../Applications/ApplicationView/components/ServiceList/Service/ServiceContent/AgGridSum/AgGridMathful";
import { Collapse } from "antd";
import { ExpandAltOutlined } from "@ant-design/icons";
import { CollapseArrow } from "../../../../../../../../../../../assets";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import { useCallback } from "react";
import { getApplicableRentals } from "./helper";

const RentalBreakdownContent = ({ elevationData }) => {
  // const { darkMode, fakeRentals, isWritable, selectedService, color } = useStateContext()
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [isWritable] = useRedux("rentalsIsWritable");
  const [darkMode] = useRedux("rentalsDarkMode");
  const [color] = useRedux("rentalsColor");
  const [, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals");
  const [enableStatistics, setEnableStatistics] = useRedux("enableStatistics");
  const [selected, setSelected] = useState(false);
  const { programFields } = useSelector((state) => state.programFields);
  // const [activeKey, setActiveKey] = useState(["1"]);
  const getRowNodeId = (data) => data.id;
  const { isLoading } = useParams();
  const [myRentals, setMyRentals] = useRedux("myRentals");


  // const dispatchContext = useDispatchContext()

  //here we extract the elevation data so we can use them as rowData for the agGrid

  console.log("enableStatistics", { enableStatistics });

  const rowData = useMemo(() => {
    let rows = fakeRentals?.services
      ?.find(({ label }) => label === selectedService)
      ?.serviceOptions?.find(
        ({ elevationId }) => elevationId === elevationData?.elevationId
      )
      ?.items?.filter(({ approved }) => approved)
      ?.map((el) => ({
        ...el,
        taxRate: fakeRentals?.taxRate || 0,
        retainage:
          fakeRentals?.services?.find(({ label }) => label === selectedService)
            ?.retainage || 0,
      }));
    return rows;
  }, [fakeRentals.services, selectedService]);

  // console.log("rowData123", rowData);

  const elevationsLength = useMemo(() => {
    return (
      fakeRentals?.services?.find(({ label }) => label === selectedService)
        ?.serviceOptions?.length || 0
    );
  }, []);

  const agGridColumns = useMemo(() => {
    return columnDefs({
      elevationData,
      programFields,
      selectedService,
      isWritable,
      darkMode,
      enableGapsFinder: enableStatistics?.dateGaps,
      enableDateCollisionFinder: enableStatistics?.dateCollisions,
      details: enableStatistics?.details,
    });
  }, [isWritable, enableStatistics]);

  const toggleStatistics = async (key) => {
    try {
        if (!fakeRentals?.projectId) {
            console.warn("No projectId available");
            return;
        }
        
        const newRentals = await getApplicableRentals({ projectId: fakeRentals.projectId });
        setMyRentals(newRentals);
        
        const statistics = cloneDeep(enableStatistics);
        statistics[key] = !statistics[key];
        setEnableStatistics(statistics);
    } catch (error) {
        console.error("Error toggling statistics:", error);
    }
};


  const onRangeSelectionChanged = useCallback((event) => {
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
      cellRanges.length > 1
    ) {
      setSelected(Object.assign(event, {}));
    } else {
      setSelected(false);
    }
  }, []);

  console.log("elevationRentals", myRentals);

  return (
    // <ResizableBox
    //   className="rentalsBodyResizable"
    //   minConstraints={[0, 0]}
    //   maxConstraints={[0, 1500]}
    //   height={270}
    //   width={1800}
    //   axis="y"
    //   handle={
    //     <div>
    //       <ResizableHandle {...{ color }} />
    //     </div>
    //   }
    // >
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          width: "100%",
          padding: "10px",
          gap: "10px",
        }}
      >
        <MondayButton
          className="mondayButtonBlue"
          hasIcon={false}
          onClick={() => {
            toggleStatistics("dateGaps");
          }}
        >
          Verify Date Gaps
        </MondayButton>{" "}
        <MondayButton
          hasIcon={false}
          className="mondayButtonBlue"
          onClick={() => {
            toggleStatistics("dateCollisions");
          }}
        >
          Verify Date Collisions
        </MondayButton>{" "}
        <MondayButton
          hasIcon={false}
          className="mondayButtonBlue"
          onClick={() => {
            toggleStatistics("details");
          }}
        >
          Show Details
        </MondayButton>
      </div>
      {selected && (
        <AgGridMathful
          style={{
            marginTop: "10px",
            marginBottom: "15px",
            marginRight: "0px",
            display: "flex",
            justifyContent: "center",
          }}
          darkMode={darkMode}
          event={selected}
        />
      )}
      <Collapse
        bordered={false}
        destroyInactivePanel={false}
        defaultActiveKey={elevationsLength > 8 ? [] : ["1"]}
        accordion={true}
        // activeKey={["1"]}
        // expandIcon={({ isActive }) => (
        //   <CollapseArrow
        //     style={{
        //       transform: !isActive ? "rotate(90deg)" : "",
        //       fill: darkMode ? "white" : "black",
        //     }}
        //     height={12}
        //     width={12}
        //   />
        // )}
      >
        <Collapse.Panel
          header={
            <div className="tittle">
              <h3>
                {`${
                  elevationData?.elevationLabel === "Hoist"
                    ? ""
                    : elevationData?.elevationId
                } ${elevationData?.elevationLabel}`}{" "}
              </h3>
            </div>
          }
          key="1"
          className="rentalExpandPanelDark"
        >
          <RentalAgGrid
            {...{
              darkMode,
              // immutableData: true,
              getRowNodeId,
              defaultColDef,
              immutableData: true,
              enableRangeSelection: true,
              onRangeSelectionChanged: onRangeSelectionChanged,
              rowData,
              onCellEditingStarted: (params) =>
                cellEditingStarted({ params, isLoading, isWritable }),
              columnDefs: agGridColumns,
              onCellValueChanged: (params) => {
                masterTableLockHandler({
                  elevationData,
                  setFakeRentals,
                  selectedService,
                  params: params,
                  fakeRentals,
                  setUnchangingFakeRentals,
                });
              },
            }}
          />
        </Collapse.Panel>
      </Collapse>
    </>
    // </ResizableBox>
  );
};

export default RentalBreakdownContent;
