import createPDF from "../../../../../../../../../integrations/createPDF";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../../../../../../pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { compareIncluding, formatNumber } from "../../../../../../../utils";
import { generateRouteImage } from "../../../../../../Dispatch/modals/NewDispatchModal/utils/dispatchPdfData";

export const footerButtons = ({
  onCancel,
  onSave,
  saving,
  onDelete,
  disabled,
}) => [
  !!disabled && {
    text: "Delete",
    cancel: true,
    onClick: onDelete,
  },
  {
    text: "cancel",
    cancel: true,
    onClick: onCancel,
  },
  {
    text: "Save",
    primary: true,
    // disabled: !!saving,
    onClick: onSave,
  },
];

export const dateFormat = "MM/DD/YYYY";
export const timeFormat = "HH:mm";

function getDuration(durationText = "") {
  let h = durationText?.split(":")[0];
  let m = durationText?.split(":")[1];
  let text = "";
  if (!isNaN(parseInt(h))) {
    if (parseInt(h)) {
      text += formatNumber(h, { unit: "hour" });
    }
  }
  text += ` ${formatNumber(m, { unit: "minute" })}`;
  return text;
}

async function pdfBorderedSection({ body, noColor = false }) {
  const fillColor = (rowIndex) => {
    return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
  };

  async function formattedBody() {
    let columnsToReturn = await generateRouteImage({
      origin: body?.pickUpCoordinates,
      destination: body?.dropOffCoordinates,
    }).then((res) => {
      return [
        {
          columns: [
            {
              text: "Dispatched By",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.dispatchedBy,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Driver Name",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.driverName,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        body?.teamsConfiguration && {
          width: "10%",
          text: "Teams:",
          alignment: "left",
          style: ["lastColumn", "strong"],
        },
        ...(body?.teamsConfiguration || [])?.map((team) => {
          return {
            columns: [
              {
                width: "20%",
                text: team?.value,
                alignment: "left",
                style: ["lastColumn"],
              },
              {
                width: "80%",
                text: team.members
                  ?.map(({ nameOfUser }) => nameOfUser)
                  .join(team?.members?.length > 1 ? ", " : ""),
                alignment: "left",
                style: ["lastColumn"],
              },
            ],
          };
        }),
        {
          columns: [
            {
              text: "Vehicle #",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.fleetName,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Paperwork Collect Status",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.paperworkCollectStatus,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Paperwork Software Status",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.paperworkSoftwareStatus,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Paperwork Type",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.paperworkType,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Cargo",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.cargo,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Pick Up Location",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.pickUpLocation,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Drop off Location",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: body?.dropOffLocation,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Depart At",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: dayjsNY(body?.timeExit).format(
                `${DEG_DATE_FORMAT} ${DEG_TIME_FORMAT}`
              ),
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Arrive By",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: dayjsNY(body?.arriveBy).format(
                `${DEG_DATE_FORMAT} ${DEG_TIME_FORMAT}`
              ),
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Distance",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: `${
                body?.routeLength === 0
                  ? parseFloat(res.distance?.value * 0.000621371).toFixed(2)
                  : parseFloat(body?.routeLength * 0.000621371).toFixed(2)
              } miles`,
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          columns: [
            {
              text: "Duration",
              alignment: "left",
              style: ["strong", "lastColumn"],
            },
            {
              text: getDuration(body?.timeScheduled),
              alignment: "left",
              style: ["lastColumn"],
            },
          ],
        },
        {
          image: res.imageLink,
          fit: [530, 400],
        },
      ].filter(Boolean);
    });
    return columnsToReturn;
  }

  const formattedBodyData = await formattedBody();

  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  const layout = !noColor ? { ...borders, fillColor } : borders;

  return {
    table: {
      widths: ["*"],
      headerRows: 1,
      body: [
        [
          {
            table: {
              widths: ["*"],
              body: formattedBodyData.map((item) => [item]),
            },
            layout: layout,
            style: "innerTable",
          },
        ],
      ],
    },
    style: "outerTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };
}

async function activityDocDefinition({ title, companyLogo, mainData }) {
  const activityData = await pdfBorderedSection({ body: mainData });
  return {
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return [
        {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 3,
        },
      ];
    },
    content: [
      {
        columns: [
          {
            image: companyLogo,
            width: 100,
          },
          {
            stack: [
              {
                text: `${title ? title : ""}`,
                link: (
                  await generateRouteImage({
                    origin: mainData?.pickUpCoordinates,
                    destination: mainData?.dropOffCoordinates,
                  })
                )?.googleMapLink,
                margin: [10, 10, 0, 10],
              },
            ],
            width: "*",
            alignment: "center",
            style: ["strong", "large"],
          },
          {
            text: `Date : ${dayjsNY().format(DEG_DATE_FORMAT)}`,
            alignment: "right",
            width: "auto",
            margin: [10, 10, 0, 10],
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
              <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
              </svg>`,
        width: 555,
        height: 2,
        style: "subheader",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      activityData,
    ].filter(Boolean),
    images: {
      logo: companyLogo,
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 15],
      },
      subheader: { margin: [0, 0, 0, 5], fontSize: 15 },
      innerTable: { margin: 5 },
      outerTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
      category: { fontSize: 10, color: "#4a4a4a" },
    },
  };
}

export async function generateActivityPdf({ body, base64 }) {
  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  createPDF({
    action: "open",
    docDefinition: await activityDocDefinition({
      title: body?.routeName,
      companyLogo: (companyLogo = companyLogo),
      mainData: body,
    }),
  });
}
