import { API } from "aws-amplify";

/**
 * This function performs an optimized database query by utilizing {indexName} as a secondary index.
 * @param {{tableName: string, indexName: string, filterKey: string, filterValue: any}} param
 */
export async function gsiQueryTable({
  tableName,
  indexName,
  filterKey,
  filterValue,
}) {
  return API.get("queryTable", "/queryTable", {
    queryStringParameters: {
      tableName,
      indexName,
      filterKey,
      filterValue,
    },
  });
}
