import React from "react";
import { Button, Checkbox, message } from "antd";
import { checkIfRowHasValidData } from "../../../tools/formatters/validators";
import { LockFilled, UnlockOutlined } from "@ant-design/icons";

export default class CheckboxRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.node.data?.[props.column.colId],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleCheck = (e) => {
    console.log("CheckboxRenderer props", this.props);

    try {
      if (this.props.isWritable) {
        if (this.props.column.colId === "approved") {
          //if we are in approved renderer, check if row has all needed data

          // if (this.props.pliApproved === undefined) {
          //if we are not is sidewalkshed
          if (!checkIfRowHasValidData(this.props.node.data)) {
            //if row doesn't have all needed data
            message.warning(
              "Row can't be approved if there is any invalid data"
            );
            return;
          }
          // } else {
          //   //if we are in sidewalk-shed, we have passed pliApproved property
          //   if (this.props.pliApproved === false) {
          //     message.warning("Addon can't be approved if PLI is not approved");
          //     return;
          //   }
          // }
        }

        this.setState({ checked: e.target.checked });
        this.props.node.setDataValue(this.props.column.colId, e.target.checked);
        // this.props.api.redrawRows({rowNodes: [this.props.node]})
        // this.props?.handleCheckboxElementChange(e, e.target.value);
        this.props.api.refreshCells({ rowNodes: [this.props.node] });
        if (this.props.column.colId === "approved") {
          this.props.api.refreshHeader();

          //if we are in sidewalkshed
          const detail_api = this.props.api.getDetailGridInfo()?.api;
          if (detail_api) {
            if (e.target.checked === false) {
              //upapprove all addons

              if (detail_api.getColumnDef(this.props.column.colId)) {
                detail_api.forEachNode((n) => {
                  n.setDataValue(this.props.column.colId, false);
                });
                detail_api.redrawRows();
              }
            }
          }
          // this.props?.handleCheckboxElementChange(e, e.target.value);
        }
      } else {
        message.error("Please enable write mode to start editing.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    if (this.props.node.group) {
      return <span>-</span>;
    } else {
      if (this.props.column.colId === "lock") {
        return (
          <Button
            className={
              "checkboxRenderer-" +
              this.props.column.colId +
              "-" +
              this.state.checked
            }
            // component="span"
            onClick={(e) => {
              this.handleCheck({ target: { checked: !this.state.checked } });
            }}
          >
            {this.state.checked ? <LockFilled /> : <UnlockOutlined />}
          </Button>
        );
      } else {
        return (
          <Checkbox
            className={"checkboxRenderer-" + this.props.column.colId}
            onChange={this.handleCheck}
            checked={this.state.checked}
          />
        );
      }
    }
  }
}
