export function filterData(appliedFilters, rowData) {
  if (appliedFilters) {
    const allowedFilters = ["estimations", "services", "scheduleName"];

    const filteredData = rowData?.filter((row) => {
      const finalResult = Object.entries(appliedFilters).every(
        ([key, value]) => {
          if (!allowedFilters.includes(key)) return false;

          if (key === "estimations") {
            return row?.inspectionReport?.estimations?.some((est) =>
              value?.includes(est?.estimationNumber || 0)
            );
          }
          if (key === "services") {
            const services = Object.keys(
              row?.inspectionReport?.questions || {}
            );
            return services?.some((item) => value?.includes(item || ""));
          }
          if (key === "scheduleName") {
            return value?.includes(row?.scheduleName);
          }

          return false;
        }
      );

      return finalResult;
    });

    return filteredData;
  }

  return rowData;
}
