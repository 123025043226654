import React from "react";
import { Modal, Input } from "antd";
import { XIcon } from "../components/SidebarPages/Communication/assets";
import "./ErrorInfoModal.scss";
import { Tick } from "../components/pages/Settings/settingsComponents/Roles/src/index";
import ErrorButton from "./ErrorButton";
import { updateDocumentTitle } from "../utils";

const { TextArea } = Input;

type Props = {
  visible: boolean;
  setVisible: (boolean: boolean) => void;
  errorInfoProps?: { error: Error; errorInfo: React.ErrorInfo };
  explanation?: string;
  setExplanation?: (string: string) => void;
  buttonAction?: () => void;
};

const ErrorInfoModal = (props: Props): JSX.Element => {
  const {
    visible,
    setVisible,
    errorInfoProps: { error, errorInfo },
    explanation,
    setExplanation,
    buttonAction,
  } = props;

  return (
    <Modal
      title="Error Information"
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Error Information" });
      }}
      centered={true}
      open={true}
      className="ErrorInfoModal"
      closeIcon={<XIcon />}
      onCancel={() => {
        updateDocumentTitle(); // reset document title to "Lead Manager"
        setVisible(false);
      }}
      footer={[
        <ErrorButton
          {...{
            className: "",
            Icon: <Tick />,
            disabled: false,
            onClick: buttonAction,
            containerClassName: "",
            style: {},
          }}
          key="sendEmail"
        >
          Send Email
        </ErrorButton>,
      ]}
    >
      <div className="ErrorInfoModal">
        <h2 className="errorMessage">{`Error Message: ${error.message}`}</h2>
        <p className="errorStack">{`Error Stack: ${errorInfo.componentStack}`}</p>
        <div className="textAreaContainer">
          <label htmlFor="textArea" className="textAreaLabel">
            Explain what happened ?
          </label>
          <TextArea
            id="textArea"
            rows={4}
            className="textAreaExplanation"
            variant="borderless"
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ErrorInfoModal;
