import { Modal } from "antd";
import { useState } from "react";
import useReport from "./hooks/useReport";
import { useSelector } from "react-redux";
import ChooseReport from "./components/ChooseReport";
import { XIcon } from "../../../Communication/assets";
import ReportViewer from "../ReportViewer/ReportViewer";
import { Designer } from "@grapecity/activereports-react";
import SaveReportModal from "./components/SaveReportModal";
import { updateDocumentTitle } from "../../../../../utils";
import { SmallLoadableComp } from "../../../../Sidebars/components";
import EmailBox from "../../../Communication/components/EmailBox/EmailBox";
import { HoverButton, RedWarningModal } from "../../../../commonComponents";

import "./DynamicReportDesigner.scss";

const DynamicReportDesigner = ({
  visible,
  onCancel,
  recordId,
  customCategoryName,
  customPartitionKeys,
  customTemplate,
  sendByEmail,
  customData,
  rowData,
  manualPath,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [warningModal, setWarningModal] = useState(false);
  const [newReport, setNewReport] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const {
    isLoading,
    report,
    setReport,
    exportsSettings,
    designerRef,
    viewerRef,
    allDataSources,
    onReportChange,
    reportConfiguration,
    emailBoxProps,
    categoryName,
    setReportConfiguration,
  } = useReport({
    customTemplate,
    customCategoryName,
    sendByEmail,
    recordId,
    customData,
    customPartitionKeys,
    rowData,
    manualPath,
  });

  const {
    emailBoxes,
    openEmailBox,
    closeEmailBox,
    // selectedDraft,
    setAttachments,
    attachments,
  } = emailBoxProps || {};

  // console.log("report:", { report, allDataSources });

  function onSave(report) {
    setNewReport(report);
    setWarningModal("save");
  }
  async function onModalCancel() {
    const reportRef = await designerRef?.current?.getReport();

    !!reportRef?.isDirty ? setWarningModal("cancel") : onCancel();
  }
  return (
    <Modal
      {...{
        open: visible,
        onCancel: onModalCancel,
        className: `dynamicReportDesigner ${
          isDarkMode && "dynamicReportDesignerDark"
        }`,
        closable: true,
        centered: true,
        title: `${customCategoryName || "Report"} - ${report?.reportName}`,
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Report View" });
        },
        closeIcon: <XIcon />,
        destroyOnClose: true,
        footer: null,
        maskClosable: false,
      }}
    >
      <SmallLoadableComp loading={isLoading} />
      <div className="reports-toolbar">
        {/* <AddCustomDataSources
          {...{ customDataSources, updateHandler, addedDataSources }}
        /> */}
        <HoverButton
          {...{
            // alwaysShowIcon: true,
            hasIcon: false,
            type: !editMode ? "action" : "decline",
            text: !editMode ? "Open Edit Mode" : "Close Edit Mode",
            onClick: () => setEditMode((prev) => !prev),
          }}
        />
        <ChooseReport
          {...{
            reportConfiguration,
            report,
            setReport,
            categoryName,
            customCategoryName,
          }}
        />
      </div>
      <div
        style={
          editMode
            ? { gridTemplateColumns: "59% 40%" }
            : { gridTemplateColumns: "100%" }
        }
        className="reports-body"
      >
        <div id="designer-host" hidden={!editMode}>
          <Designer
            {...{
              ref: designerRef,
              onSave: onSave,
              onRender: onReportChange,
              // dataSources: categoryDataSources,
              dataSources: allDataSources,
              reportsList: reportConfiguration?.reportsAvailable || [],
            }}
          />
        </div>
        <div id="viewer-host">
          <ReportViewer
            {...{
              ref: viewerRef,
              availableExports: ["pdf", "tabular-data"],
              exportsSettings,
              zoom: "FitWidth",
            }}
          />
        </div>
        {warningModal === "cancel" && (
          <RedWarningModal
            {...{
              visible: warningModal === "cancel",
              setVisible: setWarningModal,
              onCancel: () => setWarningModal(false),
              footerProps: {
                onConfirm: () => {
                  onCancel();
                },
              },
            }}
          >
            <p>
              You have unsaved changes. Are you sure you want to leave without
              saving?
            </p>
          </RedWarningModal>
        )}
        {warningModal === "save" && (
          <SaveReportModal
            {...{
              visible: warningModal === "save",
              onCancel: () => setWarningModal(false),
              newReport,
              oldReport: report,
              exportsSettings,
              setReport,
              selectedCategory: reportConfiguration,
              setReportConfiguration,
            }}
          />
        )}
        {emailBoxes?.length > 0 && (
          <div className="safetyEmailBox">
            <EmailBox
              {...{
                rowData,
                onClose: () => {
                  if (attachments.length > 0) {
                    setAttachments([]);
                    closeEmailBox(0);
                  }
                },
                attachments,
                openEmailBox,
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DynamicReportDesigner;
