import { checkIfServiceIsHoist } from "../../../../../../../../../../Estimations/DataEntryGrid/models/Service";

//export to excel data
export const exportToExcelData = ({ fakeSOV }) => {
  try {
    const workSheet2DataExcel = fakeSOV?.services
      ?.filter(({ visible = false }) => visible === true)
      ?.flatMap(({ amounts, label, retainage, serviceOptions , ...rest}, idx, obj) => {
        console.log("rest", rest);  
        return checkIfServiceIsHoist(obj[idx])
          ? serviceOptions[0]?.map(
              ({ elevationLabel, amounts: hoistAmounts }) => ({
                label: `${label} ${elevationLabel}`,
                amounts: hoistAmounts,
                retainage,
                rentalTerms:  rest?.rentalPaymentTerms?.description?.replace(/<[^>]+>/g, "") ||
                  ""
              })
            )
          : {
              retainage,
              amounts,
              label,
               rentalTerms:  rest?.rentalPaymentTerms?.description?.replace(/<[^>]+>/g, "") ||
                  ""
            };
            
      });
      console.log("workSheet2DataExcelledjo", workSheet2DataExcel);
    return workSheet2DataExcel;
  } catch (error) {
    return [];
  }
};
