import { GoogleMap, OverlayViewF, useLoadScript } from "@react-google-maps/api";
import { NYC_CENTER } from "../mapData";
import {
  GOOGLE_API_KEY,
  MAP_DARK,
  MAP_DEFAULT,
} from "../../../SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";
import Marker from "../../MapMarker/Marker";

import { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import "./Geofence.scss";

import DrawingManagerSection from "./CommonComponents/DrawingManagerSection";

import OverlayViewSection from "./CommonComponents/OverlayViewSection";
import ShapeController from "./CommonComponents/ShapeController";
import ActionsComponent from "./helpers/ActionsComponent";

import { getRandomColor } from "../../../SidebarPages/utils";

import EditConfirmation from "./helpers/EditConfirmation.jsx";
import ShapeWidowInfo from "./helpers/ShapeWidowInfo.jsx";
import useCalculateCenterOfCoordinates from "./../../../../hooks/useCalculateCenterOfCoordinates.js";

import FenceNavigator from "./CommonComponents/FenceNavigator/FenceNavigator.jsx";
import { calculateZoomLevel, convertRectangleToLatLng } from "./utils.js";
import {
  curr,
  prev,
} from "../../../pages/Settings/settingsComponents/GeofenceConfigurations/AllProgramGeofences/utils/logsFunctions.js";
import { usePolygonFunctions } from "./usePolygonFunctions";
import { OnPolygonDraw } from "./CommonComponents/OnPolygonDraw";
import { generateCircle } from "./shapesImagesGeneratorForFences.js";

const libraries = ["drawing"];

const Geofence = ({
  places = [{ coordinates: NYC_CENTER }], // send this type of strucutre [{coordinates: lat: ..., lng:...}] or when you need to render more than one marker send this [{coordinates: [{lat: ..., lng:...}, {lat:... ,lng:...}]}]
  geoFenceInfo = [], // this comes from rowData of every record that contains geofence
  setGeoFenceInfo,
  showDrawingManager = true,
  allowEditing = true,
  polygonCompleted,
  radius, // used for marker
  employeePosition, // used for marker
  directions, // used for marker
  rowData = null,
  setUnsavedLogs = () => {}, // used for saving of geofence logs that are created outside edit mode for example from shortcuts, settings, geofenceActivity etc..
  heightOfMap = "400px",
  zoom = 16,
  showInfoWindow = true,
  handleClickedShape = () => {},
  showNotesOption = true,
  show3DotActions = true,
}) => {
  // do not duplicated useLoadScript
  const { isLoaded } = useLoadScript({
    libraries,
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  // states
  const [drawingMode, setDrawingMode] = useState(""); // this state holds the selected drawing mode from <DrawingManagerSection /> component
  const [clickedShape, setClickedShape] = useState(false); //this state holds the clicked shape info
  const [editShape, setEditShape] = useState(false); // this state enable the edit of  shape
  const [shapeInfo, setShapeInfo] = useState({
    title: "",
    description: "",
  });
  const [widowsInfoOpener, setWidowsInfoOpener] = useState("");
  const [geofenceStrucutre, setGeofenceStrucutre] = useState(geoFenceInfo);

  const [movingMouseAroundPolygon, setMovingMouseAroundPolygon] =
    useState(false);

  const [fenceNavigatorActions, setFenceNavigatorActions] = useState({
    open: false,
    clickedShape: null,
  });

  const [polygonEditEnd, setPolygonEditEnd] = useState([]);

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const getProgramFieldsField = (shape) =>
    programFields
      .find(({ fieldId }) => fieldId === "04345rwe-rw5f65sd-fw451f5s1fwef")
      ?.fieldOptions?.find(({ typeOfShape }) => typeOfShape === shape);

  const { pathPoints, onMapClick, onEdit, onLoad, onUnmount, discardChanges } =
    usePolygonFunctions(drawingMode);

  // drawing options
  const options = {
    drawingControl: false,
    drawingMode: drawingMode === "polygon" ? "" : drawingMode,
    polygonOptions: {
      fillColor: getProgramFieldsField("Polygon")?.fillColor || "blue",
      strokeColor: getProgramFieldsField("Polygon")?.strokeColor || "red",
      fillOpacity: getProgramFieldsField("Polygon")?.fillOpacity || 0.4,
      strokeWeight: getProgramFieldsField("Polygon")?.strokeWeight || 2,
      clickable: true,
    },
    circleOptions: {
      fillColor: getProgramFieldsField("Circle")?.fillColor || "blue",
      strokeColor: getProgramFieldsField("Circle")?.strokeColor || "red",
      fillOpacity: getProgramFieldsField("Circle")?.fillOpacity || 0.4,
      strokeWeight: getProgramFieldsField("Circle")?.strokeWeight || 2,
      clickable: true,
    },
    squareOptions: {
      fillColor: getProgramFieldsField("Square")?.fillColor || "blue",
      strokeColor: getProgramFieldsField("Square")?.strokeColor || "red",
      fillOpacity: getProgramFieldsField("Square")?.fillOpacity || 0.4,
      strokeWeight: getProgramFieldsField("Square")?.strokeWeight || 2,
      clickable: true,
    },
  };

  const { calculateCenter } = useCalculateCenterOfCoordinates();

  const center = !clickedShape
    ? places?.[0]?.coordinates
    : calculateCenter(
        clickedShape.geoFenceInfo,
        editShape ? true : widowsInfoOpener !== "" ? true : false
      );

  const saveEditedValues = useCallback(() => {
    const newCoordinates = geofenceStrucutre.map((geofence) => {
      if (geofence.shapeId === editShape.shapeId) {
        return {
          ...geofence,
          geoFenceInfo: polygonEditEnd,
        };
      }
      return geofence;
    });

    !!setUnsavedLogs &&
      setUnsavedLogs((prev) => [
        ...prev,
        { ...editShape, prevGeoFenceInfo: polygonEditEnd, actionType: "Edit" },
      ]);

    if (!!setGeoFenceInfo) {
      setGeoFenceInfo(newCoordinates);
      setGeofenceStrucutre(newCoordinates);
    } else {
      polygonCompleted(
        newCoordinates,
        prev({ ...editShape, actionType: "Edit" }),
        curr(
          {
            ...editShape,
            actionType: "Edit",
          },
          polygonEditEnd
        ),
        "Geofence",
        "Edit",
        {
          onAction: "onEdition",
          commonNext: shapeInfo.title,
        }
      );
      setGeofenceStrucutre(newCoordinates);
    }

    closeInfoWindow();
  }, [
    geofenceStrucutre,
    geoFenceInfo,
    editShape,
    polygonEditEnd,
    setGeoFenceInfo,
    polygonCompleted,
  ]);

  function closeInfoWindow() {
    setClickedShape(false);
    setEditShape(false);
    setShapeInfo({ title: "", description: "" });
    handleClickedShape();
  }

  const renderMarkers = Array.isArray(center)
    ? places[0].coordinates
    : [places[0].coordinates];

  const dynamicCenter = clickedShape
    ? calculateCenter(clickedShape.geoFenceInfo)
    : fenceNavigatorActions.open && fenceNavigatorActions.clickedShape
    ? calculateCenter(fenceNavigatorActions.clickedShape.geoFenceInfo)
    : Array.isArray(center)
    ? center[0]
    : center;

  const dynamicPointsLatLng = useMemo(() => {
    if (fenceNavigatorActions.clickedShape) {
      if (fenceNavigatorActions.clickedShape.type === "Circle") {
        const findedCircle = fenceNavigatorActions.clickedShape;
        const circlePoints = generateCircle(
          findedCircle?.geoFenceInfo?.[0]?.circleRadius,
          {
            ...findedCircle?.geoFenceInfo[0],
          }
        );
        return circlePoints.map((point) => {
          const [lat, lng] = point.split(",");
          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        });
      } else {
        return fenceNavigatorActions.clickedShape.geoFenceInfo;
      }
    }
  }, [fenceNavigatorActions.clickedShape]);

  const dynamicZoom =
    (!fenceNavigatorActions.open && clickedShape) ||
    drawingMode !== "" ||
    editShape
      ? zoom
      : fenceNavigatorActions.open && fenceNavigatorActions.clickedShape
      ? calculateZoomLevel(dynamicPointsLatLng)
      : zoom;

  useEffect(() => {
    if (fenceNavigatorActions.clickedShape) {
      setClickedShape(fenceNavigatorActions.clickedShape);
      setShapeInfo({
        title: fenceNavigatorActions.clickedShape.title,
        description: fenceNavigatorActions.clickedShape.description,
      });
    }
    return () => {
      setClickedShape(false);
      setShapeInfo({
        title: "",
        description: "",
      });
    };
  }, [fenceNavigatorActions.clickedShape]);

  return (
    <div
      className="app-map-geofence"
      style={{
        height: heightOfMap,
      }}
    >
      {isLoaded && (
        <GoogleMap
          onClick={onMapClick}
          mapContainerClassName="app-map-geofence"
          mapContainerStyle={{ height: heightOfMap }}
          zoom={dynamicZoom}
          center={dynamicCenter}
          options={{
            styles: isDarkMode ? MAP_DARK : MAP_DEFAULT,
          }}
        >
          {/* Header of map */}
          <DrawingManagerSection
            {...{
              drawingMode,
              setDrawingMode,
              showDrawingManager,
              options,
              setGeofenceStrucutre,
              geofenceStrucutre,
              setClickedShape,
              shapeInfo,
              setWidowsInfoOpener,
              setGeoFenceInfo,
              setShapeInfo,
              rowData,
              getProgramFieldsField,
              setUnsavedLogs,
              showNavigator: () => {
                setFenceNavigatorActions({
                  ...fenceNavigatorActions,
                  open: !fenceNavigatorActions.open,
                });
              },
              closeNavigator: () => {
                setFenceNavigatorActions((prev) => ({ ...prev, open: false }));
                // setFenceNavigatorActions({ clickedShape: null, open: false });
              },
              show3DotActions,
              pathPoints,
              discardChanges,
              saveEditedValues,
              polygonEditEnd,
            }}
          />

          {/* Overlay View - marker section */}
          {(radius || employeePosition) && (
            <OverlayViewSection
              {...{ radius, employeePosition, directions, places }}
            />
          )}

          {/* display marker */}
          {places?.length &&
            renderMarkers.map((coords, idx) => {
              return (
                <OverlayViewF
                  key={idx}
                  position={coords}
                  mapPaneName={"markerLayer"}
                >
                  <Marker key={idx} color={getRandomColor()} />
                </OverlayViewF>
              );
            })}

          {/* the drawed shapes are rendered here */}
          {geofenceStrucutre.map((type) => (
            <ShapeController
              key={type.shapeId}
              {...{
                type,
                options,
                setClickedShape: () => {
                  setClickedShape(type);
                  handleClickedShape(type);
                },
                editShape,
                setShapeInfo,
                setMovingMouseAroundPolygon,
                clickedShape,
                showInfoWindow,
                getProgramFieldsField,
                setPolygonEditEnd,
                setEditShape,
                allowEditing,
              }}
            />
          ))}

          {pathPoints.length && (
            <OnPolygonDraw {...{ pathPoints, onLoad, onUnmount, onEdit }} />
          )}

          {/* here is rendered the infoModal of clicked shape and other some crud operations(delete, edit) */}
          <ActionsComponent
            {...{
              clickedShape,
              setClickedShape,
              geofenceStrucutre,
              setShapeInfo,
              shapeInfo,
              setEditShape,
              widowsInfoOpener,
              editShape,
              polygonCompleted,
              setWidowsInfoOpener,
              allowEditing,
              setGeoFenceInfo,
              setGeofenceStrucutre,
              closeInfoWindow,
              setUnsavedLogs,
              showNotesOption,
            }}
          />

          {/* tooltip || hover shapeInfo info */}
          {movingMouseAroundPolygon && (
            <ShapeWidowInfo
              {...{
                movingMouseAroundPolygon,
                setClickedShape,
                setShapeInfo,
                setMovingMouseAroundPolygon,
                allowEditing,
                showInfoWindow,
                handleClickedShape,
                getProgramFieldsField,
              }}
            />
          )}
        </GoogleMap>
      )}
      {fenceNavigatorActions.open && (
        <FenceNavigator
          geofences={geofenceStrucutre}
          clickedFence={(value) => {
            const isArray = Array.isArray(value.geoFenceInfo);

            const proceedValue = {
              ...value,
              geoFenceInfo: isArray
                ? value.geoFenceInfo
                : convertRectangleToLatLng(value.geoFenceInfo),
            };

            setFenceNavigatorActions({
              ...fenceNavigatorActions,
              clickedShape: proceedValue,
            });
          }}
          close={() => {
            setFenceNavigatorActions((prev) => ({ ...prev, open: false }));
            // setFenceNavigatorActions({ clickedShape: null, open: false })
          }}
          height={heightOfMap}
        />
      )}
    </div>
  );
};

export default Geofence;
