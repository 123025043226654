import { CrewTeamType, EmployeeReportType } from "../payrollLiveTypes";
import { JobsiteType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { camelCaseToNormalText } from "src/components/commonComponents/RowDataGridModal/components/utils.js";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

type ReportGeneratorProps = {
  jobsites: JobsiteType[];
  crewTeams: CrewTeamType[];
  employeesReport: EmployeeReportType[];
  dataType?:
    | "LateClockIn"
    | "NoPunch"
    | "UnMatchedEntries"
    | "MatchedEntries"
    | "AllEntries";
};

export const payrollReportGenerator = ({
  dataType,
  jobsites,
  crewTeams,
  employeesReport,
}: ReportGeneratorProps) => {
  let reportData = [];
  if (!dataType) {
    return;
  }
  if (dataType === "LateClockIn") {
    const todayDate = parseInTz().set("hour", 7).set("minute", 0).valueOf();
    reportData = employeesReport.filter((el) => {
      if (el?.liveStatus === "No Punch" || !el?.punchTime) {
        return false;
      }

      const time = parseInTz(el?.punchTime);
      return time.valueOf() > todayDate;
    });
  }
  if (dataType === "NoPunch") {
    reportData = employeesReport.filter((el) => {
      return el?.liveStatus === "No Punch";
    });
  }
  if (dataType === "UnMatchedEntries") {
    reportData = employeesReport.filter((el) => {
      return el?.liveStatus === "Don't Match";
    });
  }
  if (dataType === "MatchedEntries") {
    reportData = employeesReport.filter((el) => {
      return el?.liveStatus === "In" || el?.liveStatus === "Unscheduled";
    });
  }
  if (dataType === "AllEntries") {
    reportData = employeesReport;
  }

  const liveData = reportData.map((el) => {
    const jobIndex = jobsites.findIndex(
      (jb) => jb?.jobsiteId === el?.jobsiteId
    );
    const teamIndex = crewTeams.findIndex((tm) => {
      if (tm?.crewTeamId === el?.crewTeamId) {
        return true;
      }
      if (tm?.crewForeman?.crewId === el?.crewId) {
        return true;
      }
      if (tm?.crewMembers.some((mem) => mem?.crewId === el?.crewId)) {
        return true;
      }
      return false;
    });

    let link = el.punchCoordinates?.lat
      ? `https://www.google.com/maps/dir/?api=1&origin=${el.punchCoordinates?.lat},${el?.punchCoordinates?.lng}`
      : null;

    if (jobsites?.[jobIndex]?.addressPosition?.lat) {
      link += `&destination=${jobsites?.[jobIndex]?.addressPosition?.lat},${jobsites?.[jobIndex]?.addressPosition?.lng}`;
    }

    return {
      Company: el?.company,
      Status: el?.liveStatus,
      "Google Map Link": link,
      "Employee Id": el?.employeeId,
      "Employee Name": el?.employeeName,
      "Punch Location": el?.punchLocation,
      "Employee Number": el?.employeeNumber,
      Duration: el?.distance ? Math.round(Number(el?.distance / 4.4) / 60) : 0,
      "Jobsite Match": jobsites?.[jobIndex]?.jobName,
      "Report Title": camelCaseToNormalText(dataType),
      Distance: Number(Number(el?.distance).toFixed(2)),
      "Punch Type":
        el?.liveStatus !== "Out" && el?.liveStatus !== "No Punch"
          ? "In"
          : el?.liveStatus,
      Foreman: !!crewTeams?.[teamIndex]?.crewTeamId
        ? crewTeams?.[teamIndex]?.crewForeman?.crewName ||
          crewTeams?.[teamIndex]?.crewTeamName
        : "No Team",
      "Punch Time": el?.punchTime
        ? parseInTz(el?.punchTime).format("hh:mm A")
        : "",
    };
  });

  return liveData;
};
