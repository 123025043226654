import { MondaySwitch } from "../../../../../commonComponents";
import { Tick } from "../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import { LockOutlined } from "@ant-design/icons";

const TranscribeSwitch = ({ checked, setChecked }) => {
  return (
    <MondaySwitch
      {...{
        className: "switch",
        checked,
        onToggleSwitch: () => setChecked((prev) => !prev),
        onText: "Transcript",
        offText: "Transcript",
        onIcon: <Tick />,
        offIcon: <LockOutlined />,
        removeIcon: false,
      }}
    />
  );
};

export default TranscribeSwitch;
