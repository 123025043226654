import { useState, useEffect, useMemo, useCallback } from "react";
import { Collapse, Switch, Dropdown, message } from "antd";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import dataEntryGrid from "../../../dataEntryGrid";
import { Button } from "antd";
import InclExclRichText from "../RichText/InclExclRichText";
import { CollapseIcon } from "../../../../../../../icons";

function Excludes({
  particulateOptions = {},
  updateStateAndSave,
  gridData,
  service,
  serviceIndex,
  agGridKey,
}) {
  console.log("particulateOptions", particulateOptions);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    setRowData(
      !!service?.excludes
        ? service.excludes
        : [
            {
              name: "",
            },
          ]
    );
  }, [service?.excludes]);

  const onAddRow = () => {
    if (rowData.length > 0 && rowData[rowData.length - 1].name === "") return;
    setRowData((prev) => [...prev, { name: "" }]);
  };

  const onDelRow = (params) => {
    console.log("delete params", params);
    setRowData((prev) => {
      const myPrev = prev.filter((_, i) => i !== params.rowIndex);
      cellValueChange({ rows: myPrev });
      return myPrev;
    });

    // if (params?.rowIndex !== 0) {
    //   setRowData((prev) => prev.filter((_, i) => i !== params.rowIndex));
    // } else if (params?.rowIndex === 0 && rowData.length > 1) {
    //   setRowData((prev) => prev.filter((_, i) => i !== params.rowIndex));
    // }
  };

  const addItem = (add, row) => {
    setRowData((prev) => {
      const newPrev = [...prev];
      newPrev[row.rowIndex] = { ...newPrev[row], name: add.label || "" };
      cellValueChange({ rows: newPrev });
      return newPrev;
    });
  };

  const cellValueChange = ({ rows }) => {
    //clone deep
    const newGridData = JSON.parse(JSON.stringify(gridData));
    newGridData[serviceIndex].excludes = !!rows ? rows : rowData;
    updateStateAndSave(newGridData);
    message.success("Excludes updated");
    // console.log("cellValueChange", {
    //   params,
    //   gridData,
    //   service,
    //   serviceIndex,
    //   newGridData,
    // });
  };

  // useCallback([]);

  return (
    <>
      <Collapse
        expandIcon={({ isActive }) => (
          <div
            className="dropdownIconContainer"
            style={{
              // color: "#fff",
              // fill: "#fff",
              // backgroundColor: isActive
              //   ? "#5b5b5b"
              //   : "#1264A3",
              // background: "red",
              // width: "32px",
              // height: "32px",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              // borderRadius: "50%",
            }}
          >
            {!isActive ? (
              <CollapseIcon
                style={{
                  transform: "rotate(-90deg)",
                }}
              />
            ) : (
              <CollapseIcon />
            )}
          </div>
        )}
      >
        <Collapse.Panel
          header={
            <div className="addonsHeader elevation-panel-header ag-theme-alpine-dark">
              <div className="addonsHeader elevation-panel-header ag-theme-alpine-dark">
                {" "}
                Excludes{" "}
                {Array.isArray(service?.excludes) &&
                  service?.excludes?.length > 0 &&
                  ` (${service?.excludes.length})`}
              </div>
            </div>
          }
        >
          <div
            style={{ width: "100%" }}
            className="ag-theme-alpine-dark dataEntryGrid"
          >
            {/* <Button
              style={{
                padding: "0.375rem 0.75rem",
                color: "#fff",
                backgroundColor: "#6c757d",
                border: "1px solid #6c757d",
              }}
              onClick={onAddRow}
            >
              Add New Row
            </Button> */}

            <AgGridReact //ag grid for addons in bottom
              key={agGridKey}
              columnDefs={[
                {
                  headerName: "ID",
                  // field: "id",
                  minWidth: 35,
                  autoHeight: true,
                  flex: 1,
                  editable: false,
                  cellClass: "id-column",
                  valueGetter: "node.rowIndex + 1",
                },
                {
                  headerName: "Exclude",
                  field: "name",
                  width: 220,
                  flex: 7,
                  editable: true,
                  height: 100,
                  editable: true,
                  // cellRenderer: "GeneralAddonEditor",
                  // cellRendererParams: {
                  //   addonsList: addonsList,
                  //   onAddonChange: whenAddonChange,
                  //   addonType: addonType,
                  //   isWritable: isWritable,
                  // }
                  cellRendererFramework: (params) => {
                    return (
                      <InclExclRichText
                        {...{
                          params,
                          setRowData,
                          updateStateAndSave,
                          gridData,
                          service,
                          serviceIndex,
                          type: "excludes",
                        }}
                      />
                    );
                  },
                },

                {
                  headerName: "Actions",
                  field: "price",
                  flex: 2.5,
                  editable: false,
                  autoHeight: true,
                  height: 100,
                  cellRendererFramework: (params) => {
                    return (
                      <div
                        className="action-buttons"
                        style={{
                          display: "flex",
                          height: "100%",
                          width: "100%",
                          gap: "20px",
                          padding: "5px 0 5px 0",
                        }}
                      >
                        {particulateOptions?.excludes?.length > 0 && (
                          <Dropdown
                            menu={{
                              items:
                                particulateOptions?.excludes?.map((el) => ({
                                  ...el,
                                  onClick: () => addItem(el, params),
                                })) ?? [],
                            }}

                            // placement="bottomLeft"
                          >
                            <Button
                              style={{
                                padding: "0.375rem 0.75rem",
                                color: "#fff",
                                backgroundColor: "#6c757d",
                                border: "1px solid #6c757d",
                              }}
                            >
                              Choose one
                            </Button>
                          </Dropdown>
                        )}
                        {/* <Button
                        style={{ width: "100px", backgroundColor: "green" }}
                        onClick={() => onAddRow()}
                      >
                        Add Row
                      </Button>{" "} */}
                        <Button
                          style={{
                            width: "100px",
                            backgroundColor: "#FE4C4A",
                            color: "#fff",
                            border: "1px solid #FE4C4A",
                          }}
                          onClick={() => onDelRow(params)}
                        >
                          Delete Row
                        </Button>
                        <Button
                          style={{
                            padding: "0.375rem 0.75rem",
                            color: "#fff",
                            background: "rgb(0, 137, 123)",
                            border: "1px solid #6c757d",
                          }}
                          onClick={onAddRow}
                        >
                          Add
                        </Button>
                      </div>
                    );
                  },
                  getQuickFilterText: () => "",
                },
              ]}
              rowData={rowData}
              // defaultColDef={dataEntryGrid.defaultColDef}
              frameworkComponents={dataEntryGrid.frameworkComponents}
              // animateRows={true}
              // immutableData={true}
              rowHeight={45}
              // // enableFillHandle={true}
              // enableCellChangeFlash={true}
              // domLayout={"normal"}
              // getRowNodeId={(data) => data.id}
              onGridReady={(params) => {
                setGridApi(params.api);
              }}
              // onCellEditingStarted={dataEntryGrid.addonsCellEditingStarted}
              // onCellEditingStopped={(params) => {
              // 	dataEntryGrid.cellEditingStopped(params);
              // }}
              // onCellValueChanged={(params) =>
              // 	dataEntryGrid.addonsCellValueChanged(params, serviceIndex, AddonType.serviceAddon)
              // }
              onCellValueChanged={cellValueChange}
              suppressDragLeaveHidesColumns={true}
            />
          </div>
        </Collapse.Panel>
      </Collapse>{" "}
    </>
  );
}

export default Excludes;
