import { useEffect } from "react";
import dayjs from "dayjs";
import { Button } from "antd";

function PopoverSuggestion({
  visibleType,
  setTrafficModel,
  setSuggestion,
  ignoreSuggestion,
}) {
  let condition = true;
  if (typeof visibleType === "string") {
    condition = visibleType?.includes("traffic");
  } else {
    condition = false;
  }

  useEffect(() => {
    if (condition) {
      setTrafficModel("Best Guess");
    }
  }, [visibleType]);

  return (
    <>
      {!condition && (
        <div
          className="timeSuggestionPopover"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "nowrap",
            clear: "both",
            overflow: "hidden",
            whiteSpace: "nowrap",
            // height: "3rem",
            // padding: "1rem",
          }}
          role="alertdialog"
        >
          <span>Suggested Time:&nbsp;</span>
          <strong style={{ marginRight: "1rem" }}>
            {dayjs.tz(visibleType).format("HH:mm")}
          </strong>
          <Button
            onClick={setSuggestion}
            style={{
              backgroundColor: "#71cf48",
              color: "white",
              fontWeight: "400",
              marginRight: "0.5rem",
            }}
            data-testid="time-confirm-button"
          >
            Update
          </Button>{" "}
          <Button
            onClick={ignoreSuggestion}
            style={{
              backgroundColor: "red",
              color: "white",
              fontWeight: "400",
            }}
          >
            Ignore
          </Button>
        </div>
      )}
    </>
  );
}

export default PopoverSuggestion;
