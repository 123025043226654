import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";
import { BorderedTextCard } from "../../../../SidebarPages/Fleet/components";
import { SelectFilter } from "../../../../SidebarPages/XComponents";
import "./FormGrid.scss";
import { clearAllFilters } from "../../../../SidebarPages/utils";
import { ClearFilterIcon } from "../../../../SidebarPages/DynamicView/src";
import { useState } from "react";
import { FilterIcon } from "../../../../SidebarPages/BasePage/src";
import { SmallLoadableComp } from "../../../../Sidebars/components";

const FormGrid = ({
  gridData = [],
  gridColumns,
  form,
  gridApi,
  className,
  onGridReady,
  gridClassName = "",
  gridFilters = [],
  gridSidebar,
  gridStyle = {},
  style = {},
  filtersStyle = {},
  showFilters = false,
  gridLoading = false,
  isDarkMode,
  ...rest
}) => {
  //Clears all filters
  const [filterColor, setFilterColor] = useState({
    color: "#1264A3",
    active: false,
  });
  const onClearAllFilters = () => {
    setFilterColor({ color: "#1264A3", active: false });
    form.resetFields(gridFilters.map(({ formItemName }) => formItemName));
    clearAllFilters({
      filters: gridColumns.map(({ field }) => field),
      gridApi,
    });
  };
  return (
    <div className={`formGridContainer ${className}`.trim()} style={style}>
      {!!showFilters && (
        <BorderedTextCard
          className="filtersCard"
          title="Filter By"
          childrenClassName="filtersContainer"
          style={filtersStyle}
        >
          {gridFilters.map(
            ({ label, formItemName, column, filterType, options }, i) => (
              <SelectFilter
                {...{
                  label,
                  formItemName,
                  defaultValue: "All",
                  column,
                  gridApi,
                  form,
                  filterType,
                  options,
                  setFilterColor,
                  isDarkMode,
                }}
              />
            )
          )}
          {/* <Button className="clearFiltersBtn" onClick={onClearAllFilters}>
            Clear Filters
          </Button> */}
          <div
            style={{
              borderRadius: "5px",
              backgroundColor: filterColor.color,
              width: "32px",
              height: "32px",
              padding: filterColor.active ? "5px 0 0 5px" : "8px 0 0 7px",
            }}
            onClick={onClearAllFilters}
            className="clearFiltersBtn"
          >
            {filterColor.active ? <ClearFilterIcon /> : <FilterIcon />}
          </div>
        </BorderedTextCard>
      )}
      <SmallLoadableComp darkMode={isDarkMode} loading={gridLoading}>
        <div
          className={`ag-theme-custom-react ${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          } ${gridClassName}`.trim()}
          style={{
            width: "100%",
            ...gridStyle,
            overflowY: "auto",
            display: gridLoading && "flex",
            justifyContent: gridLoading && "center",
            alignItems: gridLoading && "center",
            overflow: gridLoading ? "hidden" : "auto",
            height: "50dvh",
          }}
        >
          {/* {gridLoading ? (
          <SmallLoadableComp darkMode={isDarkMode} loading={gridLoading}>
            <></>
          </SmallLoadableComp>
          // <LoadingOutlined />
        ) : ( */}
          <AgGridReact
            onGridReady={onGridReady}
            debounceVerticalScrollbar={true}
            columnDefs={gridColumns}
            animateRows
            rowData={gridData}
            sideBar={gridSidebar}
            suppressDragLeaveHidesColumns={true}
            {...rest}
          />
          {/* )} */}
        </div>
      </SmallLoadableComp>
    </div>
  );
};

export default FormGrid;
