import React, {
  useMemo,
  useState,
  useEffect,
  useReducer,
  useContext,
} from "react";
import { API } from "aws-amplify";
import { message, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";

import {
  ANALYTICS_REDUCER,
  getEmployeeAnalytics,
} from "../DEG/components/modalComponents/utils";
import PayrollContext from "../../PayrollContext";
import { gridFilters } from "../Activity/AgGridData";
import AnalyticsChartsContext from "./AnalyticsChartsContext";
import { getRandomColor } from "../../../../SidebarPages/utils";
import { initialChartsLayout, getDataSets } from "./utils/chartsHelpersData";
import AnalyticsChartsGridLayout from "./components/AnalyticsChartsGridLayout";
import AnalyticsChartsController from "./components/AnalyticsChartsController";
import Filter from "../../../../SidebarPages/BasePage/components/Filter/Filter";
import { REDUCER_INIT } from "../DEG/components/modalComponents/utils/degReducer";
import { preferences as setPreferences } from "../../../../../actions/preferences";
import { saveLocalPreferences } from "../../../../SidebarPages/Fleet/fleetsLive/utils";
import { getCardAccess } from "../../../../SidebarPages/HrManagement/utils/getAccessRight";

import "./AnalyticsCharts.scss";

function AnalyticsCharts() {
  const { analytics } = useContext(PayrollContext);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences } = useSelector((state) => state.preferences);

  const [analyticsData, analyticsUpdate] = useReducer(
    ANALYTICS_REDUCER,
    REDUCER_INIT
  );

  const employeePaymentRateAccess = getCardAccess(
    "Project Cost",
    "Employees",
    "Payment Rate"
  );
  const jobsiteRateAccess = getCardAccess("Project Cost", "Jobsites", "Rate");

  const initialLayout = useMemo(() => {
    return {
      cards:
        preferences?.preferences?.analyticsCharts?.chartsLayout ||
        initialChartsLayout(employeePaymentRateAccess, jobsiteRateAccess),
      hiddenCards:
        preferences?.preferences?.analyticsCharts?.hiddenCharts || [],
    };
  }, []);

  const [crews, setCrews] = useState([]);
  const [jobsites, setJobsites] = useState([]);
  const [dataSets, setDataSets] = useState({});
  const [loading, setLoading] = useState(true);
  const [changes, setChanges] = useState(false);
  const [filtersData, setFiltersData] = useState({});
  const [filterOpen, setFilterOpen] = useState(false);
  const [layout, setLayout] = useState(initialLayout.cards);
  const [individualFilters, setIndividualFilters] = useState({});
  const [individualFilterModal, setIndividualFilterModal] = useState(false);
  const [hiddenCards, setHiddenCards] = useState(initialLayout.hiddenCards);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const selectedDataOptions = useMemo(() => {
    const dataOptions = {
      Employees: {
        "Regular Hours": dataSets["employeeCardsData"]?.employeesWorkHours,
        "Overhead Hours": dataSets["employeeCardsData"]?.employeesOverheadHours,
        "Regular Amount": dataSets["employeeCardsData"]?.employeesTotalAmount,
        "Overhead Amount":
          dataSets["employeeCardsData"]?.employeesOverheadAmount,
        "Ovh + Reg Amount":
          dataSets["employeeCardsData"]?.employeesRegWithOvhAmount,
        "Ovh + Reg Hours":
          dataSets["employeeCardsData"]?.employeesRegWithOvhHours,
        "Overtime Total": dataSets["employeeCardsData"]?.employeesOvertime,
        "Overtime Hours": dataSets["employeeCardsData"]?.employeesOvertimeHours,
      },
      Jobsites: {
        "Regular Amount": dataSets["jobsiteCardsData"]?.totalJobsiteAmount,
        "Regular Hours": dataSets["jobsiteCardsData"]?.totalJobsiteWorkHours,
        "Overtime Amount": dataSets["jobsiteCardsData"]?.jobsiteOvertime,
        "Overtime Hours": dataSets["jobsiteCardsData"]?.jobsiteOvertimeHours,
        "Ovh + Reg Amount":
          dataSets["jobsiteCardsData"]?.jobsiteRegWithOvhAmount,
        "Ovh + Reg Hours": dataSets["jobsiteCardsData"]?.jobsiteRegWithOvhHours,
      },
      Teams: {
        "Regular Hours": dataSets.teamCardsData?.teamRegularHours,
        "Regular Amount": dataSets.teamCardsData?.teamRegularAmount,
        "Overhead Hours": dataSets.teamCardsData?.teamOverheadHours,
        "Overhead Amount": dataSets.teamCardsData?.teamOverheadHours,
        "Overtime Hours": dataSets.teamCardsData?.teamOvertimeHours,
        "Overtime Amount": dataSets.teamCardsData?.teamOvertimeAmount,
        "Ovh + Reg Hours": dataSets.teamCardsData?.teamTotalHours,
        "Ovh + Reg Amount": dataSets.teamCardsData?.teamTotalAmount,
      },
    };

    if (layout?.length) {
      let newLayout = [];
      const labels = {
        ["Teams"]: dataSets?.teamLabels || [],
        ["Jobsites"]: dataSets?.jobsiteLabels || [],
        ["Employees"]: dataSets?.employeesLabels || [],
      };

      for (const card of layout) {
        let cardDataSets = [];
        const dataToLoop = card?.chartData?.datasets || {};
        for (const dataset of dataToLoop) {
          const borderColor = (labels?.[dataset?.showDataFor] || []).map(() =>
            getRandomColor()
          );

          const newDataSet = {
            labels: labels?.[dataset?.showDataFor],
            borderColor,
            backgroundColor: borderColor.map((color) => color + "66"),
            data: dataOptions?.[dataset?.showDataFor]?.[
              dataset?.selectedDataOption
            ],
          };
          cardDataSets.push(Object.assign(dataset, newDataSet));
        }
        const modifiedCard = Object.assign(card, {
          chartData: {
            ...card.chartData,
            datasets: cardDataSets,
            labels: labels?.[cardDataSets?.[0]?.showDataFor] || [],
          },
        });
        newLayout.push(modifiedCard);
      }
      const formFields = {};
      for (const card in newLayout) {
        Object.assign(formFields, { [card?.cardKey]: null });
      }

      form.setFieldsValue(formFields);
      setLayout(newLayout);
    }

    return dataOptions;
  }, [JSON.stringify(dataSets), hiddenCards]);

  function clearFilters() {
    setFiltersData({});
    setFilterOpen(false);
    setIndividualFilters({});
  }

  function revertChanges() {
    setLayout([]);
    let newLayout = [];
    const labels = {
      ["Employees"]: dataSets?.["employeesLabels"] || [],
      ["Jobsites"]: dataSets?.["jobsiteLabels"] || [],
    };

    setTimeout(() => {
      for (const card of initialLayout.cards) {
        let cardDataSets = [];
        const dataToLoop = card?.chartData?.datasets || {};
        for (const dataset of dataToLoop) {
          const borderColor = (labels?.[dataset?.showDataFor] || []).map(() =>
            getRandomColor()
          );

          const newDataSet = {
            labels: labels?.[dataset?.showDataFor],
            borderColor,
            backgroundColor: borderColor.map((color) => color + "66"),
            data: selectedDataOptions?.[dataset?.showDataFor]?.[
              dataset?.selectedDataOption
            ],
          };
          cardDataSets.push(Object.assign(dataset, newDataSet));
        }
        const modifiedCard = Object.assign(card, {
          chartData: {
            ...card.chartData,
            datasets: cardDataSets,
            labels: labels?.[cardDataSets?.[0]?.showDataFor] || [],
          },
        });
        newLayout.push(modifiedCard);
      }

      setLayout(newLayout);
      setHiddenCards(initialLayout.hiddenCards);
      setChanges(false);
    }, 1);
  }

  function onRemove(cardKey) {
    setLayout((prev) => prev.filter((el) => el.i !== cardKey));
    setChanges(true);
  }

  function onHide(cardKey) {
    const cardToHide = layout.find((el) => el.i === cardKey);
    setHiddenCards((prev) => {
      const newHiddenCards = [...prev, cardToHide];
      saveLocalPreferences({ analyticsHiddenCharts: newHiddenCards });
      return newHiddenCards;
    });
    setLayout((prev) => prev.filter((el) => el.i !== cardKey));
    setChanges(true);
  }

  function saveLayout() {
    message.loading({ key: "saveLayout", duration: 0, content: "Saving..." });
    let chartsLayout = [];
    let hiddenCharts = [];

    for (const card of layout) {
      const compressedCard = {
        ...card,
        chartData: {
          ...card.chartData,
          labels: [],
          datasets: [],
        },
      };
      let cardDatasets = [];
      for (const dataset of card?.chartData?.datasets || []) {
        const compressedDataset = {
          ...dataset,
          backgroundColor: [],
          borderColor: [],
          labels: [],
          data: [],
        };
        cardDatasets.push(compressedDataset);
      }
      Object.assign(compressedCard.chartData, { datasets: cardDatasets });
      chartsLayout.push(compressedCard);
    }

    for (const card of hiddenCards) {
      const compressedCard = {
        ...card,
        chartData: {
          ...card.chartData,
          labels: [],
          datasets: [],
        },
      };
      let cardDatasets = [];
      for (const dataset of card?.chartData?.datasets || []) {
        const compressedDataset = {
          ...dataset,
          backgroundColor: [],
          borderColor: [],
          labels: [],
          data: [],
        };
        cardDatasets.push(compressedDataset);
      }

      Object.assign(compressedCard.chartData, { datasets: cardDatasets });
      hiddenCharts.push(compressedCard);
    }

    const newPreferences = {
      preferences: {
        ...preferences.preferences,
        analyticsCharts: {
          chartsLayout,
          hiddenCharts,
        },
      },
    };

    API.put("preferences", "/preferences", {
      body: newPreferences,
    })
      .then(() => {
        dispatch(setPreferences(newPreferences));
        message.success({
          key: "saveLayout",
          duration: 1.8,
          content: "Layout saved successfully!",
        });
      })
      .catch(() => {
        message.error({
          key: "saveLayout",
          duration: 1.8,
          content: "There was a problem saving the Layout!",
        });
      });
  }

  useEffect(() => {
    if (!crews?.length) {
      API.get("crews", "/crews")
        .then((res) => {
          setCrews(res);
        })
        .catch((err) => console.log("Error getting crews: ", err));
      API.get("jobsites", "/jobsites")
        .then((res) => {
          setJobsites(res);
        })
        .catch((err) => console.log("Error getting jobsites: ", err));
    }

    if (!!analytics?.length && crews?.length) {
      getEmployeeAnalytics({
        employeeList: crews,
        degGridApi: {},
        analyticsUpdate,
        degRows: analytics,
      });
    }
  }, [JSON.stringify(analytics), preferences, crews]);

  useEffect(() => {
    const filterKeys = Object.keys(filtersData).flatMap((key) =>
      filtersData?.[key] ? key : []
    );

    if (filterKeys?.length) {
      let filteredAnalytics = [];

      for (const entry of analytics) {
        let pass = [];

        for (const key in filtersData) {
          const values = filtersData[key];

          if (key === "punchDatestart" && !!values) {
            pass.push(entry.punchTimeStamp >= values);
          }
          if (key === "punchDateend" && !!values) {
            pass.push(entry.punchTimeStamp <= values);
          }
          if (Array.isArray(values)) {
            let keyValue =
              key === "jobsiteMatch" ? entry[key].jobAddress : entry[key];

            pass.push(values.includes(keyValue));
          }
        }

        if (!pass.some((el) => !el)) {
          filteredAnalytics.push(entry);
        }
      }
      getEmployeeAnalytics({
        employeeList: undefined,
        degGridApi: {},
        analyticsUpdate,
        degRows: filteredAnalytics,
      });
    } else {
      getEmployeeAnalytics({
        employeeList: undefined,
        degGridApi: {},
        analyticsUpdate,
        degRows: analytics,
      });
    }
  }, [filtersData, analytics]);

  useEffect(() => {
    if (!jobsites?.length || !crews?.length) {
      return;
    }
    const newDataSet = getDataSets({
      jobsites,
      crews,
      analytics,
      analyticsProp: analyticsData,
    });

    setDataSets(newDataSet);
    setLoading(false);
  }, [JSON.stringify(analyticsData), jobsites, crews]);

  useEffect(() => {
    if (!layout?.length && JSON.stringify(dataSets)?.length > 2) {
      const layoutCards =
        preferences?.preferences?.analyticsCharts?.chartsLayout || [];
      const hiddenLayoutCards =
        preferences?.preferences?.analyticsCharts?.hiddenCharts || [];
      const labelOptions = {
        ["Employees"]: dataSets?.["employeesLabels"] || [],
        ["Jobsites"]: dataSets?.["jobsiteLabels"] || [],
        ["Teams"]: dataSets?.["teamLabels"] || [],
      };

      let populatedLayout = [];
      let populatedHiddenCards = [];

      if (!!layoutCards?.length && hiddenLayoutCards?.length) {
        return;
      }

      for (const card of layoutCards) {
        const labels = labelOptions[card?.chartData?.showDataFor];
        const borderColor = labels.map(() => getRandomColor());
        const backgroundColor = borderColor.map((color) => color + "66");
        const newCard = {
          ...card,
          chartData: {
            ...card?.chartData,
            labels,
          },
        };

        let newDatasets = [];
        const dataToLoop = card?.chartData?.datasets || {};
        for (const dataset of dataToLoop) {
          const newDataset = {
            ...dataset,
            backgroundColor,
            borderColor,
            data:
              selectedDataOptions?.[dataset?.showDataFor]?.[
                dataset?.selectedDataOption
              ] || [],
          };
          newDatasets.push(newDataset);
        }
        Object.assign(newCard.chartData, { datasets: newDatasets });
        populatedLayout.push(newCard);
      }

      for (const card of hiddenLayoutCards) {
        const labels = labelOptions[card?.chartData?.showDataFor];
        const borderColor = labels.map(() => getRandomColor());
        const backgroundColor = borderColor.map((color) => color + "66");
        const newCard = {
          ...card,
          chartData: {
            ...card?.chartData,
            labels,
          },
        };

        let newDatasets = [];
        const dataToLoop = card?.chartData?.datasets || {};
        for (const dataset of dataToLoop) {
          const newDataset = {
            ...dataset,
            backgroundColor,
            borderColor,
            data:
              selectedDataOptions?.[dataset?.showDataFor]?.[
                dataset?.selectedDataOption
              ] || [],
          };
          newDatasets.push(newDataset);
        }
        Object.assign(newCard.chartData, { datasets: newDatasets });
        populatedHiddenCards.push(newCard);
      }

      setLayout(populatedLayout);
      setHiddenCards(populatedHiddenCards);
    }
  }, [JSON.stringify(preferences), JSON.stringify(dataSets)]);

  return (
    <AnalyticsChartsContext.Provider
      value={{
        form,
        crews,
        layout,
        onHide,
        loading,
        changes,
        dataSets,
        jobsites,
        onRemove,
        setLayout,
        isDarkMode,
        setChanges,
        saveLayout,
        hiddenCards,
        filtersData,
        clearFilters,
        revertChanges,
        analyticsData,
        initialLayout,
        setFilterOpen,
        setHiddenCards,
        individualFilters,
        selectedDataOptions,
        setIndividualFilters,
        individualFilterModal,
        setIndividualFilterModal,
      }}
    >
      <main
        className={`analytics-charts ${isDarkMode && "analytics-charts-dark"}`}
        data-testid="analytics-charts"
      >
        <Form form={form}></Form>
        <AnalyticsChartsController />
        <AnalyticsChartsGridLayout />
        {filterOpen && (
          <Filter
            rowData={analytics}
            filters={gridFilters}
            setOpen={setFilterOpen}
            title="Analytics Charts"
            filtersData={filtersData}
            clearFilters={clearFilters}
            getFilters={(val) => {
              setFiltersData(structuredClone(val));
            }}
          />
        )}
      </main>
    </AnalyticsChartsContext.Provider>
  );
}

export default AnalyticsCharts;
