import { useEffect, useState } from "react";
import { Collapse, Checkbox, theme, Flex } from "antd";

import Text from "src/components/commonComponents/Typography/Text";
import {
  progressForService,
  progressInPerc,
} from "src/components/Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { get_ProgressDimensionsByServiceId } from "src/components/Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";

import ElevationCollapse from "./ElevationCollapse";
import { handleServiceValues, retrieveProgress } from "./utils/functions";

const ServiceCollapse = ({
  service,
  displayProgress,
  progressLabel,
  totalValues,
  setDescription,
  setDescriptionModalVisible,
  setServiceValues,
}) => {
  const {
    label,
    serviceOptions,
    estimationId,
    disabled,
    isChangeOrder = false,
  } = service;

  const { d1, d2 } = get_ProgressDimensionsByServiceId(service?.serviceId);

  const { token } = theme.useToken();

  const [serviceChecked, setServiceChecked] = useState({
    label,
    isChecked: false,
    isIndeterminate: false,
  });
  const [elevationsChecked, setElevationsChecked] = useState(
    serviceOptions[0].map(({ elevationLabel }) => ({
      label,
      elevationLabel,
      isChecked: false,
      isIndeterminate: false,
    }))
  );
  const [plisChecked, setPlisChecked] = useState(
    serviceOptions[0]?.map((elevation) => ({
      elevationLabel: elevation?.elevationLabel ?? "",
      items: elevation?.items?.map((pli) => ({
        id: pli?.id,
        totalProgress: progressInPerc(pli?.totalProgress, d1, d2, pli),
        service: label,
        isChecked: false,
      })),
    }))
  );

  const serviceName = !!isChangeOrder ? `${label} (Change Order)` : `${label}`;

  const serviceProgress = progressForService(service);

  const handleLabelClick = (e) => {
    e.stopPropagation();
  };

  // Function to handle checkbox change for service
  const handleServiceChange = (e) => {
    const isChecked = e.target.checked;

    if (displayProgress) {
      // Can't select service if at least a single pli's totalProgress is not 100,
      // while displaying progress
      const canBeChecked = serviceOptions[0]
        .map((elevation) =>
          elevation?.items?.map(
            (item) => progressInPerc(item?.totalProgress, d1, d2, item) == 100
          )
        )
        .flat()
        .some((canBeChecked) => canBeChecked);

      if (!canBeChecked) return;

      const updatedCheckedPlis = plisChecked?.map((elev) => ({
        ...elev,
        items: elev?.items?.map((pli) => ({
          ...pli,
          isChecked:
            pli?.totalProgress == 100 &&
            !(serviceChecked.isChecked || serviceChecked.isIndeterminate),
        })),
      }));
      const updatedCheckedElevations = elevationsChecked?.map((elev) => {
        const correspondingElevation = updatedCheckedPlis?.find(
          ({ elevationLabel }) => elev?.elevationLabel === elevationLabel
        );
        const checkedStatus =
          correspondingElevation?.items?.every((pli) => pli.isChecked) &&
          !elev.isChecked;
        return {
          ...elev,
          isChecked: checkedStatus,
          isIndeterminate:
            correspondingElevation?.items?.some((pli) => pli.isChecked) &&
            !checkedStatus,
        };
      });

      const updatedCheckedService = {
        label,
        isChecked: updatedCheckedElevations?.every((elev) => elev.isChecked),
        isIndeterminate:
          updatedCheckedElevations?.some(
            ({ isIndeterminate }) => isIndeterminate
          ) && !serviceChecked.isIndeterminate,
      };

      setServiceChecked(updatedCheckedService);
      setElevationsChecked(updatedCheckedElevations);
      setPlisChecked(updatedCheckedPlis);
      setServiceValues((prev) => {
        const serviceValues = handleServiceValues({
          serviceValues: prev,
          service: updatedCheckedService,
          elevations: updatedCheckedElevations,
          plis: updatedCheckedPlis,
          estimationId,
        });

        return serviceValues;
      });
    } else {
      const updatedCheckedService = {
        ...serviceChecked,
        isChecked,
        isIndeterminate: isChecked ? false : serviceChecked?.isIndeterminate,
      };
      const updatedCheckedElevations = elevationsChecked?.map((elev) => ({
        ...elev,
        isChecked,
        isIndeterminate: isChecked ? false : elev?.isIndeterminate,
      }));
      const updatedCheckedPlis = plisChecked?.map((elev) => ({
        ...elev,
        items: elev?.items?.map((pli) => ({
          ...pli,
          isChecked,
        })),
      }));

      setServiceChecked(updatedCheckedService);
      setElevationsChecked(updatedCheckedElevations);
      setPlisChecked(updatedCheckedPlis);
      setServiceValues((prev) => {
        const serviceValues = handleServiceValues({
          serviceValues: prev,
          service: updatedCheckedService,
          elevations: updatedCheckedElevations,
          plis: updatedCheckedPlis,
          estimationId,
        });

        return serviceValues;
      });
    }
  };

  // Function to handle checkbox change for elevations
  const handleElevationChange = (index, isChecked, elevation) => {
    const newChildrenChecked = [...elevationsChecked];
    newChildrenChecked[index] = isChecked;

    if (displayProgress) {
      // Can't select elevation if at least a single pli's totalProgress is not 100,
      // while displaying progress
      const canBeChecked = plisChecked
        ?.find((elev) => elev.elevationLabel === elevation?.elevationLabel)
        ?.items?.map((item) => item.totalProgress == 100)
        .some((canBeChecked) => canBeChecked);

      if (!canBeChecked) return;

      const updatedCheckedPlis = plisChecked?.map((elev) => {
        if (elev.elevationLabel === elevation?.elevationLabel) {
          const el = elevationsChecked?.find(
            ({ elevationLabel }) => elevationLabel === elev.elevationLabel
          );
          const elevationStatus = el?.isChecked || el?.isIndeterminate;

          return {
            ...elev,
            items: elev?.items?.map((pli) => {
              if (
                pli?.totalProgress == 100 &&
                !pli?.isChecked &&
                !elevationStatus
              ) {
                return {
                  ...pli,
                  isChecked: true,
                };
              }
              return {
                ...pli,
                isChecked: false,
              };
            }),
          };
        }
        return elev;
      });
      const updatedCheckedElevations = elevationsChecked?.map((elev) => {
        if (elev?.elevationLabel === elevation?.elevationLabel) {
          const correspondingElevation = updatedCheckedPlis?.find(
            (item) =>
              item?.elevationLabel === elev?.elevationLabel &&
              elev?.elevationLabel === elevation?.elevationLabel
          );

          const checkedStatus = correspondingElevation?.items?.every(
            ({ isChecked }) => isChecked
          );
          return {
            ...elev,
            isChecked: correspondingElevation ? checkedStatus : elev?.isChecked,
            isIndeterminate: correspondingElevation
              ? correspondingElevation?.items?.some(
                  ({ isChecked }) => isChecked
                ) &&
                !elev.isIndeterminate &&
                !checkedStatus
              : elev?.isChecked,
          };
        }
        return elev;
      });

      const serviceCheckedStatus = updatedCheckedElevations?.every(
        ({ isChecked }) => isChecked
      );
      const updatedCheckedService = {
        label,
        isChecked: updatedCheckedElevations?.every(
          ({ isChecked }) => isChecked
        ),
        isIndeterminate:
          updatedCheckedElevations?.some(
            ({ isChecked, isIndeterminate }) => isChecked || isIndeterminate
          ) && !serviceCheckedStatus,
      };

      setServiceChecked(updatedCheckedService);
      setElevationsChecked(updatedCheckedElevations);
      setPlisChecked(updatedCheckedPlis);
      setServiceValues((prev) => {
        const serviceValues = handleServiceValues({
          serviceValues: prev,
          service: updatedCheckedService,
          elevations: updatedCheckedElevations,
          plis: updatedCheckedPlis,
          estimationId,
        });

        return serviceValues;
      });
    } else {
      const updatedCheckedElevations = [...elevationsChecked];
      updatedCheckedElevations[index].isChecked = isChecked;
      updatedCheckedElevations[index].isIndeterminate = false;

      const serviceChecked = updatedCheckedElevations.every(
        ({ isChecked }) => isChecked
      );
      const serviceIndeterminateTest = updatedCheckedElevations.some(
        ({ isChecked, isIndeterminate }) => isChecked || isIndeterminate
      );

      const updatedCheckedService = {
        label,
        isChecked: serviceChecked,
        isIndeterminate: serviceIndeterminateTest && !serviceChecked,
      };
      const updatedCheckedPlis = plisChecked?.map((elev) => ({
        ...elev,
        items:
          elev?.elevationLabel === elevation?.elevationLabel
            ? elev?.items?.map((pli) => ({
                ...pli,
                isChecked,
              }))
            : elev?.items,
      }));

      setServiceChecked(updatedCheckedService);
      setElevationsChecked(updatedCheckedElevations);
      setPlisChecked(updatedCheckedPlis);
      setServiceValues((prev) => {
        const serviceValues = handleServiceValues({
          serviceValues: prev,
          service: updatedCheckedService,
          elevations: updatedCheckedElevations,
          plis: updatedCheckedPlis,
          estimationId,
        });

        return serviceValues;
      });
    }
  };

  // Function to handle checkbox change for elevation items
  const handleElevationItemChange = (e, text, currentElevation, itemId) => {
    if (displayProgress) {
      const id = text.slice(-1);
      const pliProgress = retrieveProgress(
        serviceOptions,
        currentElevation,
        id,
        service?.serviceId
      );

      if (pliProgress < 100) return;
    }

    const isChecked = e.target.checked;

    const updatedElevationChecked = plisChecked?.find(
      ({ elevationLabel }) => elevationLabel === currentElevation
    );
    const newPlisChecked = [
      ...plisChecked?.filter(
        ({ elevationLabel }) => elevationLabel !== currentElevation
      ),
      {
        ...updatedElevationChecked,
        items: updatedElevationChecked?.items?.map((pli) => ({
          ...pli,
          isChecked: pli?.id === itemId ? isChecked : pli?.isChecked,
        })),
      },
    ];
    setPlisChecked(newPlisChecked);

    const elevationCheckStateTest = newPlisChecked
      ?.find(({ elevationLabel }) => elevationLabel === currentElevation)
      ?.items?.every(({ isChecked }) => isChecked);
    const elevationIndeterminateTest = newPlisChecked
      ?.find(({ elevationLabel }) => elevationLabel === currentElevation)
      ?.items?.some(({ isChecked }) => isChecked && !elevationCheckStateTest);
    const updatedElevationsChecked = elevationsChecked?.map((elev) => ({
      ...elev,
      isChecked:
        elev?.elevationLabel === currentElevation
          ? elevationCheckStateTest
          : elev?.isChecked,
      isIndeterminate:
        elev?.elevationLabel === currentElevation
          ? elevationIndeterminateTest
          : elev?.isIndeterminate,
    }));
    setElevationsChecked(updatedElevationsChecked);

    const serviceCheckStateTest = updatedElevationsChecked?.every(
      ({ isChecked }) => isChecked
    );
    const serviceIndeterminateStateTest = updatedElevationsChecked?.some(
      ({ isIndeterminate, isChecked }) => isIndeterminate || isChecked
    );
    const updatedCheckedService = {
      label,
      isChecked: serviceCheckStateTest,
      isIndeterminate: serviceIndeterminateStateTest && !serviceCheckStateTest,
    };
    setServiceChecked(updatedCheckedService);

    setServiceValues((prev) => {
      const serviceValues = handleServiceValues({
        serviceValues: prev,
        service: updatedCheckedService,
        elevations: updatedElevationsChecked,
        plis: newPlisChecked,
        estimationId,
      });

      return serviceValues;
    });
  };

  const items = [
    {
      key: "1",
      label: (
        <Flex
          justify="space-between"
          align="center"
          className="service-accordion"
          data-testid={`${label}-data-testid`}
        >
          <div onClick={handleLabelClick}>
            <Checkbox
              indeterminate={serviceChecked.isIndeterminate}
              checked={serviceChecked.isChecked}
              // disabled={serviceOptions[0]}
              data-testid={`${label}-checkbox-data-testid`}
              onChange={handleServiceChange}
            >
              {serviceName}
            </Checkbox>
          </div>

          {displayProgress && (
            <div>
              <Text>{`${progressLabel}: ${serviceProgress}%`}</Text>
            </div>
          )}
        </Flex>
      ),
      children: serviceOptions[0].map((elevation, index) => (
        <ElevationCollapse
          key={index}
          elevation={elevation}
          label={label}
          displayProgress={displayProgress}
          progressLabel={progressLabel}
          serviceId={service?.serviceId}
          elevationsChecked={elevationsChecked?.find(
            ({ elevationLabel }) => elevation?.elevationLabel === elevationLabel
          )}
          plisChecked={plisChecked}
          onElevationChange={(isChecked) =>
            handleElevationChange(
              index,
              isChecked,
              elevationsChecked?.find(
                ({ elevationLabel }) =>
                  elevation?.elevationLabel === elevationLabel
              )
            )
          }
          onElevationItemChange={(e, text, itemId) =>
            handleElevationItemChange(
              e,
              text,
              elevation?.elevationLabel,
              itemId
            )
          }
          setDescription={setDescription}
          setDescriptionModalVisible={setDescriptionModalVisible}
        />
      )),
      style: {
        marginBottom: 5,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: "none",
      },
    },
  ];

  useEffect(() => {
    setServiceChecked({
      label,
      isChecked: false,
      isIndeterminate: false,
    });
    setElevationsChecked(
      serviceOptions[0].map(({ elevationLabel }) => ({
        label,
        elevationLabel,
        isChecked: false,
        isIndeterminate: false,
      }))
    );
    setPlisChecked(
      serviceOptions[0]?.map((elevation) => ({
        elevationLabel: elevation?.elevationLabel ?? "",
        items: elevation?.items?.map((pli) => ({
          id: pli?.id,
          totalProgress: progressInPerc(pli?.totalProgress, d1, d2, pli),
          service: label,
          isChecked: false,
        })),
      }))
    );
    setServiceValues(totalValues);
  }, [displayProgress]);

  return (
    <Collapse
      accordion
      collapsible={disabled}
      items={items}
      expandIconPosition="end"
      style={{ width: "100%" }}
    />
  );
};

export default ServiceCollapse;
