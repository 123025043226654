import "./ExecutedAutomations.scss";
import { useSelector } from "react-redux";
import AutomationResponses from "../AutomationResponses/AutomationResponses";

const ExecutedAutomations = ({ responses }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { groupName } = userConfiguration;

  const isAdmin = groupName.toLowerCase() === "admin";

  return (
    <div className="executed-automations-container">
      <AutomationResponses
        {...{
          title: (isAdmin ? "All Automation" : "My Automation") + " Responses",
          responses,
        }}
      />
    </div>
  );
};

export default ExecutedAutomations;
