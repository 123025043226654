import {
  dispatching,
  documentation,
  estimations,
  fleet,
  fleetInspections,
  leads,
  maintenance,
  opportunity,
  projects,
  scheduling,
  violations,
  applications,
  rentals,
  charges,
  invoices,
  claims,
  incidents,
  defects,
  workOrders,
  safety,
  todos,
  projectCost,
  approvals,
  inspections,
  citations,
} from "./subCategory";

export const barChart = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  projectsData,
  engData,
  archData,
  inspectionsData,
  fleetData,
  rentalsData,
  dispatchData,
  applicationsData,
  chargesData,
  invoicesData,
  incidentsData,
  defectsData,
  workOrdersData,
  jobsites,
  reducedUsers
) => {
  return {
    Leads: () => leads(updatedDynamicStates, IdHandler, programFields),
    Opportunities: () =>
      opportunity(IdHandler, programFields, updatedDynamicStates),
    Projects: () =>
      projects(programFields, updatedDynamicStates, IdHandler, projectsData),
    Documentation: () => documentation(IdHandler, updatedDynamicStates),
    Scheduling: () =>
      scheduling(programFields, updatedDynamicStates, IdHandler),
    Dispatching: () =>
      dispatching(dispatchData, updatedDynamicStates, IdHandler),
    Fleet: () => fleet(fleetData, updatedDynamicStates, IdHandler),
    "Work Orders": () =>
      workOrders(
        workOrdersData,
        updatedDynamicStates,
        IdHandler,
        programFields
      ),
    "Fleet Inspections": () =>
      fleetInspections(
        inspectionsData,
        updatedDynamicStates,
        IdHandler,
        programFields
      ),
    Incidents: () => incidents(updatedDynamicStates, IdHandler, programFields),
    Defects: () => defects(defectsData, updatedDynamicStates, IdHandler),
    Maintenance: () => maintenance(updatedDynamicStates, IdHandler),
    Violations: () => violations(IdHandler, updatedDynamicStates),
    Estimations: () =>
      estimations(
        programFields,
        updatedDynamicStates,
        IdHandler,
        archData,
        engData
      ),
    Applications: () =>
      applications(
        programFields,
        updatedDynamicStates,
        applicationsData,
        IdHandler
      ),
    Rentals: () =>
      rentals(rentalsData, programFields, updatedDynamicStates, IdHandler),
    Charges: () =>
      charges(programFields, updatedDynamicStates, chargesData, IdHandler),
    Invoices: () =>
      invoices(programFields, updatedDynamicStates, invoicesData, IdHandler),
    Claims: () => claims(updatedDynamicStates, IdHandler, programFields),
    Safety: () => safety(updatedDynamicStates, IdHandler, programFields),
    "To Do": () => todos(updatedDynamicStates, IdHandler, programFields),
    "Project Cost": () =>
      projectCost(IdHandler, programFields, updatedDynamicStates, jobsites),
    Approvals: () =>
      approvals(programFields, updatedDynamicStates, IdHandler, reducedUsers),
    Inspections: () =>
      inspections(updatedDynamicStates, IdHandler, programFields),
    Citations: () => citations(updatedDynamicStates, IdHandler, programFields),
  };
};
