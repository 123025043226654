// import { useDispatchContext, useStateContext } from "./Context/Context"

import RentalBreakdownList from "./components/RentalBreakdownList/RentalBreakdownList";
import { getRental, getRentals } from "../../../../../../actions/rentals";
import SideBar from "./components/SideBar/SideBar";
import Header from "./components/Header/Header";
import "./RentalsView.scss";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useRedux } from "../../../../hooks";
import { useEffect, useState } from "react";
import { a, API } from "aws-amplify";
import { requestAllPages } from "../../../../../../utils/paginationFetchData";
import { useUndoRedoState } from "../../../../../../hooks/useUndoRedoState";
import NotFound from "../../../../NotFound/NotFound";
import { Button, Empty, message, Skeleton } from "antd";
import Swal from "sweetalert2";

const RentalsView = ({ isNextStep, rentId, handleCloseForm }) => {
  const { proposedTypeOfWork, rental, error, programFields } = useSelector(
    (state) => {
      return {
        proposedTypeOfWork: state?.proposedTypeOfWork?.proposedTypeOfWork,
        rental: state.rentals.rental,
        error: state.rentals.error,
        programFields: state?.programFields?.programFields,
      };
    }
  );

  const [retract, setRetract] = useState(false);
  // const dispatchContext = useDispatchContext()
  // const { darkMode } = useStateContext()
  const { rentalsId } = useParams();
  const dispatch = useDispatch();

  // rentals
  // useRedux("rentalsSelectedService"
  //creates all redux

  const [, setUnchangingFakeRentals] = useRedux(
    "unchangingFakeRentals",
    false,
    true
  );
  const [, setSelectedService] = useRedux(
    "rentalsSelectedService",
    false,
    true
  );
  const [, setFakeRentals] = useRedux("fakeRentals");
  const [darkMode] = useRedux("rentalsDarkMode", true);
  const [, setColor] = useRedux("rentalsColor", false);
  // const [allProjects, setAllProjects] = useRedux("allProjects", false, true);

  // const { programFields } = useSelector((state) => state.programFields);

  //Undo Redo Limit for Rentals
  const undoRedoLimit = programFields.find(
    (item) => item.fieldName === "Statuses Of Categories"
  ).fieldOptions?.["Rental"];

  //undo redo Functions
  const {
    itemToSaveActiveState: newFakeRental,
    changeStateHandler,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    undoClickHandler,
    redoClickHandler,
  } = useUndoRedoState(undoRedoLimit);

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;

  // Fetch Application on mount or whenever company or application changes
  useEffect(() => {
    if (rentalsId || rentId) {
      dispatch(getRental(rentalsId || rentId));
    }
  }, []);

  let colorSelected =
    proposedTypeOfWork?.find(
      ({ workName }) => workName === rental?.services[0]?.label
    )?.colorCode || "#fff";

  console.log("rentalSelected", { rental });

  // Keep Fake Rentals in Sync whenever Real rentals updates
  useEffect(() => {
    if (rental) {
      // dispatchContext({ type: "SET_SELECTED_SERVICE", payload: rental?.services[0]?.label })
      // dispatchContext({ type: "SET_UNCHANGING_FAKE_RENTALS", payload: rental })
      // dispatchContext({ type: "SET_FAKE_RENTALS", payload: rental })
      // dispatchContext({ type: "SET_COLOR", payload: color })
      setSelectedService(rental?.services[0]?.label);
      setUnchangingFakeRentals(rental);
      setColor(colorSelected);
      setFakeRentals({
        ...rental,
        // projectTaxRate:
        // 	allProjects?.find(({ projectId }) => projectId === rental?.projectId)?.taxRate || 0,
      });
    }
    window.addEventListener("beforeunload", (event) => {
      event.returnValue = `Are you sure you want to leave?`;
    });
  }, [rental]);

  // useEffect(() => {
  //   if (newFakeRental)
  //     setFakeRentals({
  //       ...newFakeRental,
  //     });
  // }, [newFakeRental]);

  // useEffect(() => {
  //   if (fakeRentals)
  //     changeStateHandler(JSON.parse(JSON.stringify(fakeRentals)));
  // }, [fakeRentals]);

  if (!rental && error.status === 404) {
    return <NotFound />;
  }

  if (!rental) {
    Swal.fire({
      title: "Hold On",
      text: "We are gathering all the information for you, please wait...",
      icon: "info",
      timer: 2000,
      showConfirmButton: false,
    });
    return <></>;
  }

  if (rental) {
    return (
      <>
        <div className={darkMode ? "rental-view-dark" : "rental-view-light"}>
          <Header
            {...{
              setRetract,
              retract,
              canUndo,
              canRedo,
              undoClickHandler,
              redoClickHandler,
              isNextStep,
              handleCloseForm,
            }}
          />

          <div className="rentalViewBodyContainer">
            <SideBar {...{ proposedTypeOfWork, retract }} />
            <RentalBreakdownList canRedo={canRedo} />
          </div>
        </div>
      </>
    );
  }
};

export default RentalsView;
