import { useEffect, useMemo } from "react";
import {
  compareAddressesWithRgx,
  compareIncludingAccurate,
} from "../utils/compareIncluding";

export const useDuplicatePrevention = ({
  listOfRecords = [],
  filter = {},
  notEqualFilter = {},
  compareIncludingKeys = [],
  onDuplication = () => {},
  doNotPrevent,
  startFiltering = true,
}) => {
  const existingRecord = useMemo(() => {
    const filteredRecords = listOfRecords?.filter((record) =>
      Object.entries(notEqualFilter).every(
        ([key, value]) => value !== record?.[key]
      )
    );

    const filterEntries = Object.entries(filter);

    return (
      filterEntries.length &&
      filteredRecords.find((record) =>
        filterEntries.every(([key, value]) =>
          compareIncludingKeys?.includes(key)
            ? key?.toUpperCase?.().includes("ADDRESS") &&
              compareIncludingKeys?.includes(key)
              ? compareAddressesWithRgx(value, record?.[key])
              : compareIncludingAccurate(value, record[key])
            : value === record[key]
        )
      )
    );
  }, [listOfRecords, filter]);

  useEffect(() => {
    if (!existingRecord || !!doNotPrevent || !startFiltering) return;

    onDuplication();
  }, [existingRecord]);

  return !!doNotPrevent ? null : existingRecord;
};
