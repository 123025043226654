import Card from "../ServiceTemplates/Card";
import { mapTagToData } from "../../../ProposalHeader/components/RealTimeTakeOffPreview/mappers/dataMapper";
import { useProposalContext } from "../../../../WaitingRoomProposal/ProposalContext";

function addStringToDoubleBracesTags(html, keyToAdd) {
  const regex = /\{\{(\w+)\}\}/g;
  const updatedHtml = html.replace(
    regex,
    `<span class="proposalTag" contenteditable="false" style="cursor: not-allowed;">{{$1_${keyToAdd}}}</span>`
  );
  return updatedHtml;
}

export const getMyMadeSenseData = (html, proposalData) => {
  return mapTagToData({
    proposalData,
    html,
  });
};

function GeneralTemplatesSidebar({ editorRef, proposalTemplates }) {
  const { proposalData, setProposalData } = useProposalContext();

  const onDragEnd = (event, item) => {
    const droppedHTML = item?.templateContent || "";
    const x = event.clientX;
    const y = event.clientY;
    const elements = document.elementsFromPoint(x, y);
    const isOverTarget = elements?.some((element) =>
      element?.classList?.contains("jodit-workplace")
    );
    event.preventDefault();
    if (editorRef.current && isOverTarget) {
      const wrappedHTML = `<div>${droppedHTML}</div>`;
      const myMadeSenseData = getMyMadeSenseData(wrappedHTML, proposalData);

      editorRef.current.component.selection.insertHTML(myMadeSenseData);
      setProposalData({
        ...(proposalData || {}),
        templateData: {
          ...(proposalData?.templateData || {}),
        },
        wrappedHTML,
        parentTemplateContent: item?.parentTemplateContent || "",
      });
    }
  };

  return (
    <div>
      <div className="serviceTemplates">
        {Array.isArray(proposalTemplates) &&
          proposalTemplates?.map((template, i) => (
            <div key={i} className="serviceTemplateItem">
              <Card template={template} onDragEnd={onDragEnd} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default GeneralTemplatesSidebar;
