import "./Styles.scss";

import * as React from "react";
import { Button, ConfigProvider, Grid } from "antd";
import { useSelector } from "react-redux";

const { useBreakpoint } = Grid;

enum SizeClassName {
  small = "small-icon-button",
  middle = "middle-icon-button",
  large = "large-icon-button",
}

enum ColorClassName {
  blue = "blue-icon-button",
  green = "green-icon-button",
  red = "red-icon-button",
  gray = "gray-icon-button",
  white = "white-icon-button",
}

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  size?: "small" | "middle" | "large";
  color?: "blue" | "green" | "red" | "gray" | "white";
}
export default function IconButton(props: IconButtonProps) {
  const {
    icon,
    size,
    color = "blue",
    type,
    className = "",
    ...otherProps
  } = props;

  const { isDarkMode } = useSelector((state: any) => state.darkMode);

  const screens = useBreakpoint();

  let breakpointSize = "middle"; // Default size

  if ((screens.xs || screens.sm) && !screens.md) {
    breakpointSize = "large";
  } else {
    breakpointSize = "middle";
  }

  return (
    <ConfigProvider wave={{ disabled: true }}>
      <Button
        data-testid="icon-button"
        className={`custom-icon-button 
          ${size ? SizeClassName[size] : SizeClassName[breakpointSize]} 
          ${ColorClassName[color]} 
          ${className} ${isDarkMode ? "dark-icon-button" : ""}
        `}
        icon={icon}
        {...otherProps}
      />
    </ConfigProvider>
  );
}
