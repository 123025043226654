import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { message } from "antd";
import { API } from "aws-amplify";
import broadcastNotification from "../helpers/controllers/broadcastNotification";
import { getCognitosForNotification } from "./notificationHelpers";

const nameKeys = {
  projects: { name: "projectName", category: "project" },
  scheduling: { name: "scheduleName", category: "schedule" },
  accounts: { name: "accountName", category: "account" },
  leads: { name: "leadCompany", category: "lead" },
  estimations: { name: "jobSiteAddress", category: "estimation" },
  opportunities: { name: "opportunityAddress", category: "opportunity" },
  permitDrawings: { name: "sow", category: "permit drawing" },
};

async function enableOnCustomerPortalFn({
  setRowData,
  oldValue,
  recordId,
  postLogs,
  apiName,
  category,
  rowName,
  sendWsMessage,
  userConfiguration,
  rowData,
}) {
  message.loading({
    content: "Updating record...",
    key: "enable on customer portal",
    duration: 0,
  });
  const newVal = !oldValue;
  const logsObj = {
    prev: { enableOnCustomerPortal: oldValue },
    curr: { enableOnCustomerPortal: newVal },
    label: "Enable on Customer Portal",
    action: "Edit",
  };
  await API.patch(`${apiName}`, `/${apiName}/${recordId}`, {
    body: { enableOnCustomerPortal: newVal },
  })
    .then((res) => {
      message.success({
        content: `Record ${newVal ? "enabled" : "disabled"} on customer portal`,
        key: "enable on customer portal",
        duration: 5,
      });
      setRowData((prev) => ({ ...prev, enableOnCustomerPortal: newVal }));
      postLogs(logsObj.prev, logsObj.curr, logsObj.label, logsObj.action);

      if (newVal) {
        sendWsMessage({
          notification: {
            templateCategory: "Customer Portal",
            action: "enabledOnCustomerPortal",
            keysConfig: { recordId, category, recordName: rowData[rowName] },
            cognitoUserIds: [rowData?.cognitoUserId],
          },
        });
      }

      broadcastNotification(
        "116",
        "onEnableOnCustomerPortal",
        [
          {
            common: `${userConfiguration?.nameOfUser} ${
              !!newVal ? "Enabled" : "Disabled"
            } ${
              nameKeys?.[apiName]?.category +
              " " +
              rowData?.[nameKeys?.[apiName]?.name]
            } on Customer Portal`,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
            cognitos: getCognitosForNotification(
              userConfiguration,
              rowData?.teamsConfiguration
            ),
          },
        ],
        `${apiName}/${recordId}`
      );
    })
    .catch((err) => {
      console.error("err:", { err });
      message.error({
        content: "Error updating record",
        key: "enable on customer portal",
        duration: 5,
      });
    });
}

function enableOnCustomerPortalButton({
  setRowData,
  postLogs,
  rowData,
  recordId,
  apiName,
  category,
  rowName,
  sendWsMessage = () => {},
  userConfiguration,
}) {
  const oldValue = rowData?.enableOnCustomerPortal || false;
  return {
    label: `${!oldValue ? "Enable" : "Disable"} on Customer Portal`,
    onClick: () => {
      enableOnCustomerPortalFn({
        setRowData,
        oldValue,
        recordId,
        postLogs,
        apiName,
        category,
        rowName,
        sendWsMessage,
        userConfiguration,
        rowData,
      });
    },
    className: oldValue ? "mondayButtonGreen" : "mondayButtonRed",
    Icon: oldValue ? CheckCircleOutlined : CloseCircleOutlined,
  };
}

export default enableOnCustomerPortalButton;
