import { Button, Collapse, Input, Popover } from "antd";
import React from "react";
import { ScheduleDayInnerView } from "./ScheduleDayInnerView";
import { extractContent } from "../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rtfEditor";
import moment from "moment";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

export const CanceledDayView = (props) => {
  const { scheduleDay, notesToAddForScheduleDays } = props;

  const thisScheduleDayNotesToAdd =
    notesToAddForScheduleDays?.[scheduleDay?.id];

  let lastNoteView = "";

  if (thisScheduleDayNotesToAdd) {
    const shortText = extractContent(thisScheduleDayNotesToAdd?.text);
    lastNoteView = (
      <span className={"postponed-day-button-note-short-view"}>
        {shortText}
      </span>
    );
  }

  console.log("scheduleDay", { scheduleDay });

  return (
    <>
      <Popover
        trigger="click"
        content={<ScheduleDayInnerView {...props} />}
        overlayClassName="canceled-day-popover"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 32,
            background: "#88929e",
            color: "#fff",
            cursor: "pointer",
            fontWeight: 500,
          }}
        >
          <b>Day {scheduleDay?.day}</b>
          <span>{dayjsNY(scheduleDay?.startDate).format("MM/DD/YYYY")}</span>
          <span>Canceled</span>
          <span style={{ fontWeight: 600 }}>{lastNoteView}</span>
        </div>
      </Popover>
    </>
  );
};
