import {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { Dayjs } from "dayjs";
import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { unstable_batchedUpdates } from "react-dom";
import { MenuOutlined, ReloadOutlined } from "@ant-design/icons";
import { Form, Popconfirm, Tooltip, Statistic, message } from "antd";

import { useResponsive } from "src/hooks";
import { getWeekDateRange } from "../../utils";
import { getLatLngFromAddress } from "src/utils";
import { FullScreenIcon } from "src/assets/icons";
import { CrewTeamType } from "../../payrollLiveTypes";
import PayrollLiveContext from "../../PayrollLiveContext";
import PopoverLegend from "../PopoverLegend/PopoverLegend";
import UploadExcelSchedule from "../../modals/UploadExcelSchedule";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton, HoverButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { BackIcon, RightArrow } from "src/components/SidebarPages/BasePage/src";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { getEmployeeAnalytics } from "../../../Payroll/Tabs/DEG/components/modalComponents/utils";
import { getPayrollLiveReport } from "../../../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./PayrollLiveControlPanel.scss";

const FETCH_DATE_FORMAT = "YYYY-MM-DD";
const timeout = 300_000; // five minutes in seconds

const PayrollLiveControlPanel = forwardRef((_, ref) => {
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const {
    filters,
    jobsites,
    sidebarRef,
    setFilters,
    clearFilters,
    goFullScreen,
    setDegEntries,
    clientConfigs,
    clientCompany,
    analyticsUpdate,
    controlPanelForm,
    setTodaySchedules,
    setSelectedWeekDeg,
    getEmployeeReports,
    setEmployeesReport,
  } = useContext(PayrollLiveContext);

  const [countDown, setCountDown] = useState<number>(
    JSON.parse(localStorage.getItem("lastReportFetch")) + timeout || 0
  );
  const [lastFetch, setLastFetch] = useState<number>(
    JSON.parse(localStorage.getItem("lastReportFetch")) || 0
  );
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const { width } = useResponsive();

  function sidebarToggle() {
    if (sidebarRef?.current) {
      sidebarRef.current.toggleSidebar();
    }
  }

  function onTimeChange(time: Date, formItemName: string) {
    setFilters((prev) => ({
      ...prev,
      [formItemName]: !!time ? time.valueOf() : null,
    }));
  }

  function resetFields() {
    controlPanelForm.resetFields();
    resetTimeFilter();
    setFilters((prev) => ({
      ...prev,
      punchTimeEnd: null,
      punchTimeStart: null,
    }));
  }

  function resetTimeFilter() {
    controlPanelForm.setFieldValue("punchTimeEnd", null);
    controlPanelForm.setFieldValue("punchTimeStart", null);
    setFilters((prev) => ({
      ...prev,
      punchTimeEnd: null,
      punchTimeStart: null,
    }));
  }

  function updateReportData() {
    const button = document.getElementById("loading-button");
    const nowTime = Date.now();
    const deadline = nowTime + timeout;
    setLastFetch(nowTime);
    setCountDown(deadline);
    button.classList.add("animation");
    getEmployeeReports(true);
    setTimeout(() => {
      button.classList.remove("animation");
    }, timeout);
  }

  function disabledDates(current: Dayjs) {
    return (
      current &&
      parseInTz(current).startOf("d").valueOf() >
        parseInTz().startOf("d").valueOf()
    );
  }

  async function onDateChange(e: Dayjs) {
    const eventDate = parseInTz(e).startOf("d");
    message.loading({
      duration: 0,
      key: "resetTodayData",
      content: "Matching data...",
    });

    const selectedClient = clientConfigs.find(
      (el) => el?.configId === clientCompany
    );
    console.log("selectedClient: ", { selectedClient, clientCompany });
    try {
      const selectedDateSchedules = await API.get(
        "fetchSchedulesByDay",
        `/fetchSchedulesByDay`,
        {
          queryStringParameters: {
            searchDates: JSON.stringify([
              eventDate.toISOString(),
              eventDate.toISOString(),
            ]),
          },
        }
      );

      let modifiedSchedules = [];
      for (let i = 0; i < selectedDateSchedules?.items?.length; i++) {
        const schedule = selectedDateSchedules?.items?.[i];
        const jIndex = jobsites.findIndex(
          (job) => job.projectId === schedule.projectId
        );

        const scheduleCrews = (schedule?.scheduleCrews || []).flatMap(
          (crewConfig: CrewTeamType) => {
            const todayTeamConfig = (crewConfig?.days || []).filter(
              (el) =>
                parseInTz(el?.startDate || 0)
                  .startOf("d")
                  .valueOf() === parseInTz().startOf("d").valueOf()
            );
            if (todayTeamConfig?.length) {
              return { ...crewConfig, days: todayTeamConfig };
            } else {
              return [];
            }
          }
        );

        const includedEmployeeIds = scheduleCrews.flatMap((el) =>
          el?.days?.length ? el?.crewId : []
        );

        const scheduleObject = {
          ...schedule,
          includedEmployeeIds,
          jobsiteId: jobsites?.[jIndex]?.jobsiteId,
          geoFenceInfo: jobsites?.[jIndex]?.geoFenceInfo,
          radius: jobsites?.[jIndex]?.locationRadius || 300,
          addressPosition: jobsites?.[jIndex]?.addressPosition,
        };

        if (!jobsites?.[jIndex]?.addressPosition?.lat) {
          const addressCoordinates = await getLatLngFromAddress(
            schedule?.scheduleAddress
          );
          Object.assign(scheduleObject, {
            addressPosition: addressCoordinates,
          });
        }
        if (
          modifiedSchedules.findIndex(
            (el) => el?.projectId === scheduleObject?.projectId
          ) === -1
        ) {
          modifiedSchedules.push(scheduleObject);
        }
      }

      const tmpEmployeeReports = await getPayrollLiveReport({
        excludedEmployees: [],
        clientKey: selectedClient?.clientKey,
        companyName: selectedClient?.clientName,
        selectedDate: eventDate.format(FETCH_DATE_FORMAT),
      });

      const weekDateRange = getWeekDateRange(eventDate);
      const degFilter = [
        {
          id: uuid(),
          operator: "AND",
          conditions: [
            {
              id: uuid(),
              operator: "AND",
              column: "fromDate",
              dataType: "number",
              columnType: "number",
              value: weekDateRange?.[0]?.valueOf?.(),
              formula: "is_greater_than_or_equal_to",
            },
            {
              id: uuid(),
              formula: "is",
              operator: "AND",
              dataType: "string",
              columnType: "string",
              column: "companyName",
              value: selectedClient.clientName,
            },
          ],
        },
      ];

      API.get("deg", "/deg", {
        queryStringParameters: {
          getMaxLimit: "true",
          withPagination: "true",
          ExclusiveStartKey: undefined,
          filters: JSON.stringify(degFilter),
        },
      }).then((degRes) => {
        if (degRes?.deg?.length) {
          const deg = degRes?.deg.find(
            (el) => el.toDate <= weekDateRange[1].valueOf()
          );
          if (deg?.degStatus === "Completed") {
            message.warning({
              duration: 4,
              content: "This week`s DEG have been completed.",
            });
          }
          setSelectedWeekDeg(deg);
          const appliedFilter = [
            {
              id: uuid(),
              operator: "AND",
              conditions: [
                {
                  id: uuid(),
                  formula: "is",
                  operator: "AND",
                  column: "company",
                  dataType: "string",
                  columnType: "string",
                  value: selectedClient?.clientName,
                },
                {
                  id: uuid(),
                  formula: "is",
                  operator: "AND",
                  column: "degId",
                  value: deg?.degId,
                  dataType: "string",
                  columnType: "string",
                },
                {
                  id: uuid(),
                  operator: "AND",
                  dataType: "number",
                  columnType: "number",
                  column: "punchTimeStamp",
                  formula: "is_greater_than_or_equal_to",
                  value: eventDate
                    .format(FETCH_DATE_FORMAT)
                    .startOf("d")
                    .valueOf(),
                },
              ],
            },
          ];
          API.get("degEntries", "/degEntries", {
            queryStringParameters: {
              getMaxLimit: "true",
              withPagination: "true",
              ExclusiveStartKey: undefined,
              filters: JSON.stringify(appliedFilter),
            },
          })
            .then((res) => {
              getEmployeeAnalytics({
                degGridApi: {},
                analyticsUpdate,
                degRows: res?.degEntries || [],
              });
              setDegEntries(
                res.degEntries.map((el) => ({
                  ...el,
                  jobAddress: el?.jobsiteMatch?.jobAddress,
                }))
              );
              message.destroy();
            })
            .catch((err) => {
              console.log("error: ", err);
              message.destroy();
            });
        }
      });

      unstable_batchedUpdates(() => {
        setTodaySchedules(modifiedSchedules);
        setEmployeesReport(tmpEmployeeReports?.data || []);
      });
      message.success({
        duration: 2,
        key: "resetTodayData",
        content: "Live data updated successfully",
      });
    } catch (error) {
      console.log("Error updating today data: ", error);
      message.error({
        duration: 2,
        key: "resetTodayData",
        content: "Error updating today`s data",
      });
    }
  }

  const lastFetchTimeFormat =
    width > 1080 ? DEG_DATE_FORMAT + " " + DEG_TIME_FORMAT : "hh:mm A";

  const customOptions = useMemo(() => {
    return clientConfigs.map((el, key) => ({
      key,
      value: el.configId,
      label: el?.clientName,
    }));
  }, [clientConfigs]);

  useEffect(() => {
    if (controlPanelForm) {
      controlPanelForm.setFieldValue(
        "clientCompany",
        clientConfigs[0]?.configId
      );
      controlPanelForm.setFieldValue("selectedDate", dayjsNY().startOf("d"));
    }
  }, [clientConfigs, controlPanelForm]);

  const timeFilter = useMemo(() => {
    return (
      <Form form={controlPanelForm}>
        <div className="time-range">
          <InputComponent
            secondaryDarkMode
            type="customTimeInput"
            form={controlPanelForm}
            formItemName="punchTimeStart"
            onChange={(e: Date) => onTimeChange(e, "punchTimeStart")}
          />
          <RightArrow
            width={13}
            height={13}
            fill={darkMode ? "#fff" : "#323338"}
          />
          <InputComponent
            secondaryDarkMode
            type="customTimeInput"
            form={controlPanelForm}
            formItemName="punchTimeEnd"
            onChange={(e: Date) => onTimeChange(e, "punchTimeEnd")}
          />
          <button
            type="button"
            onClick={resetTimeFilter}
            className="clear-time-btn"
          >
            <XIcon height={10} width={10} />
          </button>
        </div>
      </Form>
    );
  }, [controlPanelForm, darkMode, setFilters, width, customOptions]);

  const getDataBtnContent = useMemo(() => {
    const countDownActive = countDown - Date.now();
    if (countDownActive >= 0) {
      return (
        <Statistic.Countdown
          format="mm:ss"
          value={countDown}
          onFinish={() => setCountDown(0)}
        />
      );
    } else {
      return <span className="btn-content">Get Latest Data</span>;
    }
  }, [countDown]);

  const filterActive: boolean = useMemo(() => {
    const {
      distance,
      schedules,
      crewSearch,
      liveStatus,
      employeeId,
      employeeName,
      punchTimeEnd,
      scheduleMatch,
      employeeSearch,
      employeeNumber,
      punchTimeStart,
      scheduleAddress,
    } = filters;

    return (
      distance > 0 ||
      !!crewSearch ||
      !!punchTimeEnd ||
      !!employeeSearch ||
      !!scheduleAddress ||
      !!schedules?.length ||
      !!liveStatus?.length ||
      !!employeeId?.length ||
      !!employeeName?.length ||
      !!scheduleMatch?.length ||
      !!employeeNumber?.length ||
      !!punchTimeStart
    );
  }, [JSON.stringify(filters)]);

  useImperativeHandle(
    ref,
    () => {
      return {
        resetFields,
        setLastFetch,
      };
    },
    []
  );

  return (
    <section
      data-testid="payroll-live-control-panel"
      className={`payroll-live-control-panel ${
        darkMode ? "payroll-live-control-panel-dark" : ""
      }`}
    >
      <div className="title-section">
        {width > 1180 ? (
          <div className="menu-icon" onClick={sidebarToggle}>
            <MenuOutlined />
          </div>
        ) : null}
        <span className="title-text">Payroll Live</span>
      </div>
      <div className="control-section">
        <Form
          form={controlPanelForm}
          className="control-panel-form"
          initialValues={{
            clientCompany: "",
          }}
        >
          <span className="today-date">
            <InputComponent
              type="datePicker"
              allowClear={false}
              onChange={onDateChange}
              formItemName="selectedDate"
              disabledDate={disabledDates}
              defaultValue={dayjsNY().startOf("d")}
            />
          </span>

          <Popconfirm
            title={null}
            placement="bottom"
            description={timeFilter}
            rootClassName={`popup-filter ${
              darkMode ? "popup-filter-dark" : ""
            }`}
          >
            <HoverButton
              type="action"
              hasIcon={false}
              onClick={() => {}}
              text={width > 1400 ? "Time Filter" : "Filter"}
            />
          </Popconfirm>

          <HoverButton
            type="decline"
            hasIcon={false}
            text="Clear Filters"
            className={filterActive ? "active-filters" : ""}
            onClick={() => {
              clearFilters();
              resetTimeFilter();
            }}
          />
          <InputComponent
            type="select"
            allowClear={false}
            form={controlPanelForm}
            formItemName="clientCompany"
            customOptions={customOptions}
            placeholder="Chose Subcontractor..."
            label={width > 1400 ? "Company: " : null}
          />
        </Form>
        {lastFetch ? (
          <Tooltip title="Last updated reports">
            <span className="last-updated-reports">
              {dayjsNY(lastFetch).format(lastFetchTimeFormat)}
            </span>
          </Tooltip>
        ) : null}
        <div className="buttons-wrapper">
          <PopoverLegend />
          <button
            id="loading-button"
            className="loading-button"
            onClick={updateReportData}
            disabled={countDown >= Date.now()}
          >
            {width > 1200 ? <div className="progress"></div> : null}
            {width > 1200 ? getDataBtnContent : <ReloadOutlined />}
          </button>
          {/* ) : null} */}
          {width > 1200 ? (
            <Tooltip title="Full Screen">
              <MondayButton
                onClick={goFullScreen}
                Icon={<FullScreenIcon />}
                className={`mondayButton${
                  darkMode ? "Blue" : "White"
                } full-screen-btn`}
              />
            </Tooltip>
          ) : null}
          <Tooltip title="Back to Payroll">
            <MondayButton
              hasIcon={false}
              onClick={() => navigate("/projectCost?tab=DEG")}
              className={`mondayButton${darkMode ? "Blue" : "White"} back-btn`}
            >
              <BackIcon fill={darkMode ? "#fff" : "#231f20"} />
            </MondayButton>
          </Tooltip>
        </div>
      </div>
      {uploadVisible ? (
        <UploadExcelSchedule
          open={uploadVisible}
          onCancel={() => setUploadVisible(false)}
        />
      ) : null}
    </section>
  );
});

export default PayrollLiveControlPanel;
