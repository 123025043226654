import { ReloadOutlined } from "@ant-design/icons";
import { useChartCardsContext } from "../../../ChartCardsContext";
import MondayButton from "../../../../MondayButton/MondayButton";

const ReloadCards = () => {
  const { handleReFetch } = useChartCardsContext();

  return handleReFetch ? (
    <div className="chart-cards-reload">
      <MondayButton
        className="mondayButtonBlue"
        tooltipCategory="CHART CARDS"
        tooltipKey="reloadCards"
        Icon={<ReloadOutlined />}
        onClick={() => {
          typeof handleReFetch === "function" && handleReFetch();
        }}
      />
    </div>
  ) : (
    <></>
  );
};

export default ReloadCards;
