import React, { createContext, useContext, useRef, useState } from "react";

export const ChartRefsContext = createContext({
  chartRefs: [],
  registerChartRef: () => {},
});

export const useChartRefsContext = () => {
  const context = useContext(ChartRefsContext);
  if (!context) {
    throw new Error(
      "useChartRefsContext must be used within a ChartRefsProvider"
    );
  }
  return context;
};

export const ChartRefsProvider = ({ children }) => {
  const [chartRefs, setChartRefs] = useState([]);

  const registerChartRef = (ref, title) => {
    if (ref && !chartRefs.some((r) => r.ref === ref)) {
      setChartRefs((prevRefs) => [...prevRefs, { ref, title }]);
    }
  };

  const contextValue = {
    chartRefs,
    registerChartRef,
  };

  return (
    <ChartRefsContext.Provider value={contextValue}>
      {children}
    </ChartRefsContext.Provider>
  );
};
