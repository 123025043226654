import { useState } from "react";
import { Modal, Switch } from "antd";
import { useSelector } from "react-redux";
import { modifyLogDataForComponents } from "./utils";
import { ViewLogs } from "./components";
import { XIcon } from "../../SidebarPages/Communication/assets";
import styles from "./MultiLevelTreeLogs.module.scss";
import "./MultiLevelTreeModal.scss";
import ModalHeader from "./components/ModalHeader/ModalHeader.jsx";
import DisplayLogs from "./components/DisplayLogs/DisplayLogs.jsx";

const MultiLevelTreeLogs = ({
  title,
  visible,
  logsData,
  viewLogs,
  setVisible,
  isCostumed = false,
  pdfDownloadLogName = false,
  logsName = false,
  onLogsErase = () => {},
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const newLogData = isCostumed
    ? logsData?.sort((a, b) => {
        return new Date(b?.updatedAt) - new Date(a?.updatedAt);
      })
    : modifyLogDataForComponents(logsData);

  return (
    <Modal
      {...{
        title,
        open: visible,
        onCancel: () => setVisible(false),
        footer: false,
        destroyOnClose: true,
        closable: true,
        closeIcon: <XIcon />,
        centered: true,
        ["data-testid"]: "multi-level-tree-logs-modal",
        className: `multiLevelTreeLogModal ${
          isDarkMode && "multiLevelTreeLogModalDark"
        }`,
      }}
    >
      {viewLogs && (
        <div className={styles["multi-logs-header"]}>
          <Switch
            checkedChildren="Last Seen Logs"
            unCheckedChildren=" Edit Logs"
            checked={isEnabled}
            onChange={() => setIsEnabled((prev) => !prev)}
          />
        </div>
      )}
      {!isEnabled ? (
        <div
          className={
            styles[isDarkMode ? "multi-logs-body-dark" : "multi-logs-body"]
          }
        >
          <ModalHeader
            {...{
              setVisible,
              newLogData,
              title: logsName || title,
              searchText,
              setSearchText,
              pdfDownloadLogName,
              onLogsErase,
            }}
          />
          {newLogData?.length > 0 ? (
            <DisplayLogs {...{ newLogData, searchText, isCostumed }} />
          ) : (
            <div className={styles[isDarkMode ? "no-logs-dark" : "no-logs"]}>
              No logs available
            </div>
          )}
        </div>
      ) : (
        <ViewLogs {...{ viewLogs }} />
      )}
    </Modal>
  );
};

export default MultiLevelTreeLogs;
