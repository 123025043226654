import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, message, Modal, Select } from "antd";
import { v4 } from "uuid";
import {
  AreaChartOutlined,
  BarChartOutlined,
  DotChartOutlined,
  LineChartOutlined,
  PieChartOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { MondayButton } from "../../../../../commonComponents";
import {
  WidgetCategory,
  WidgetConfirm,
  WidgetTitle,
} from "../../../../NewWidgetModal/components";
import { useChartCardsContext } from "../../../ChartCardsContext";
import {
  CardWidths,
  getNecessaryKeys,
  updateChartPreferences,
  updateProgramFields,
} from "../../../utils";
import NewWidgetModal from "../../../../NewWidgetModal/NewWidgetModal";
import { lazyFetch } from "../../../../../../utils";
import { programFields as programFieldsDispatch } from "../../../../../../actions";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { DropDownArrow } from "../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import "./NewCardWidget.scss";

const allIcons = [
  <AreaChartOutlined />,
  <PlusOutlined />,
  <BarChartOutlined />,
  <PlusOutlined />,
  <LineChartOutlined />,
  <PlusOutlined />,
  <PieChartOutlined />,
  <PlusOutlined />,
  <DotChartOutlined />,
];

const getWidgetSteps = ({
  isAdmin,
  filledWidgets,
  setFilledWidgets,
  widgetKeys,
}) => {
  return [
    {
      key: 0,
      title: "Chart Title",
      disabled: true,
      Comp: WidgetTitle,
      filledWidgets,
      setFilledWidgets,
      widgetConfigs: {},
    },
    {
      key: 1,
      title: "Widget Keys",
      disabled: true,
      Comp: WidgetCategory,

      //Component Props
      filledWidgets,
      setFilledWidgets,
      widgetConfigs: {
        widgetTitle: "Key to filter",
        widgetEntities: widgetKeys,
      },
      className: "widget-keys-container",
    },
    {
      key: 2,
      title: "Widget Config",
      disabled: true,
      Comp: WidgetCategory,

      //Component Props
      filledWidgets,
      setFilledWidgets,
      widgetConfigs: {
        widgetTitle: "Width",
        widgetEntities: CardWidths?.map((width) => ({
          ...width,
          key: width.value,
        })),

        subWidgetTitle: "Widget Type",
        subWidgetEntities: [
          { key: "doughnut", label: "Doughnut" },
          { key: "progress", label: "Progress" },
          { key: "bar", label: "Bar" },
          { key: "horizontal-progress", label: "Horizontal-Progress" },
          { key: "stackedBar", label: "stackedBar" },
          { key: "polar", label: "Polar" },
          { key: "area", label: "Area" },
        ],
        rulesToFill: 2, //this tells when to enable button, example enable next btn after 2 selects are finished
      },
    },
    {
      key: 3,
      title: "Widget Visibility",
      disabled: true,
      Comp: WidgetCategory,

      //Component Props
      filledWidgets,
      setFilledWidgets,
      widgetConfigs: {
        widgetTitle: "Visibility",
        widgetEntities: [
          { key: "Private", label: "Private" },
          { key: "Public", label: "Public" },
        ],

        ...(isAdmin && {
          subWidgetTitle: "Permanent or Temporary",
          subWidgetEntities: [
            { key: "Permanent", label: "Permanent" },
            { key: "Temporary", label: "Temporary" },
          ],
          rulesToFill: 2, //this tells when to enable button, example enable next btn after 2 selects are finished}
        }),
      },
    },
    {
      key: 4,
      title: "Confirm",
      disabled: true,
      Comp: WidgetConfirm,

      //Component Props
      filledWidgets,
      widgetConfigs: {},
      selectedType: filledWidgets?.["Widget Config"]?.["Widget Type"],
      widgetInformation: {
        Title: filledWidgets?.["Chart Title"],
        "Key to filter":
          widgetKeys?.find(
            (item) =>
              item.key === filledWidgets?.["Widget Keys"]?.["Key to filter"]
          )?.label ?? "",
        Visibility: `${filledWidgets?.["Widget Visibility"]?.["Visibility"]} & ${filledWidgets?.["Widget Visibility"]?.["Permanent or Temporary"]}`,
      },
    },
  ];
};

const NewCardWidget = ({ modalTitle }) => {
  const { chartType, setChartType } = useChartCardsContext();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [typeModal, setTypeModal] = useState(false);
  const [newWidgetModal, setNewWidgetModal] = useState(false);
  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  const pathName = window.location.pathname;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % allIcons.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [allIcons.length]);

  const onTypeSelect = () => {
    setTypeModal(false);
    setNewWidgetModal(true);
  };

  return (
    <div className="new-card-widget">
      <MondayButton
        tooltipCategory="CHART CARDS"
        tooltipKey="newChart"
        Icon={allIcons[currentIconIndex]}
        onClick={() =>
          pathName === "/settings/userLogsCharts"
            ? setTypeModal(true)
            : setNewWidgetModal(true)
        }
      />
      {typeModal && (
        <Modal
          open={typeModal}
          onCancel={() => {
            setTypeModal(false);
            setChartType(false);
          }}
          closeIcon={<XIcon />}
          centered={true}
          title={"Chart Type"}
          className={`chartType-select ${
            isDarkMode && "chartType-select-dark"
          }`}
          footer={[
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MondayButton
                {...{
                  onClick: () => {
                    setTypeModal(false);
                    setChartType(false);
                  },
                  className: "mondayButtonRed",
                  Icon: <XIcon />,
                }}
              >
                Cancel
              </MondayButton>
              <MondayButton
                {...{
                  onClick: () => onTypeSelect(),
                  Icon: <TickIcon />,
                  className: !chartType
                    ? "mondayButtonGray"
                    : "mondayButtonGreen",
                  disabled: !chartType,
                }}
              >
                Select
              </MondayButton>
            </div>,
          ]}
        >
          <div className="chartType-container">
            <label className="fieldLabel">Type</label>
            <Select
              showSearch
              placeholder="Select an option..."
              suffixIcon={<DropDownArrow />}
              options={[
                { value: "default", label: "All Logs" },
                { value: "tutorial", label: "Video Tutorials" },
              ]}
              onChange={(e) => setChartType(e)}
            />
          </div>
        </Modal>
      )}
      {newWidgetModal && (
        <NewCardWidgetContent
          key={modalTitle}
          {...{
            modalTitle,
            setNewWidgetModal,
            type: chartType,
          }}
        />
      )}
    </div>
  );
};

export default NewCardWidget;

const NewCardWidgetContent = ({ modalTitle, setNewWidgetModal, type }) => {
  const dispatch = useDispatch();

  const { programFields } = useSelector((state) => state.programFields);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );
  const { preferences } = allPreferences;
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { sampleObjects } = useSelector((state) => state.sampleObjects);

  const [filledWidgets, setFilledWidgets] = useState({});

  const { chartCategory, chartType, setChartType, customKeys } =
    useChartCardsContext();

  const overviewCards =
    programFields?.find(
      ({ fieldName }) => fieldName === "Overviews Configuration"
    ).fieldOptions.overviewCards[chartCategory] || [];

  const isAdmin = userConfiguration.groupName.toLowerCase() === "admin";

  const usedKeys =
    customKeys ||
    getNecessaryKeys(
      sampleObjects.find(({ objectName }) => objectName === chartCategory)
        ?.objectSample?.[0],
      type === "tutorial" ? true : false
    );

  const widgetSteps = getWidgetSteps({
    isAdmin,
    filledWidgets,
    setFilledWidgets,
    widgetKeys: usedKeys.map((key) => ({
      key,
      label: key,
    })),
  });

  const onFinish = async () => {
    message.loading("Card is saving...!");
    const privateCard =
      filledWidgets?.["Widget Visibility"]?.Visibility === "Private";

    const cardToSave = {
      id: v4(),
      chartTitle: filledWidgets?.["Chart Title"],
      showDataFrom: "rowData",
      cardWidth:
        filledWidgets?.["Widget Config"].Width === "50%" ? "49%" : "100%",
      width: filledWidgets?.["Widget Config"].Width,
      type: filledWidgets?.["Widget Config"]?.["Widget Type"],
      rowFilterKey: filledWidgets?.["Widget Keys"]?.["Key to filter"],
      rowNameKey: filledWidgets?.["Widget Keys"]?.["Key to accumulate"],
      ...(chartType === "tutorial" && { isVideoTutorial: true }),
      permanentCard:
        filledWidgets?.["Widget Visibility"]?.["Permanent or Temporary"] ===
        "Permanent",
      createdBy: {
        cognitoUserId: userConfiguration.cognitoUserId,
        nameOfUser: userConfiguration.nameOfUser,
      },
    };

    if (!!privateCard) {
      const updatedPreferences = {
        ...preferences,
        OverviewCards: {
          ...(preferences?.OverviewCards || {}),
          onlyMyCards: {
            ...(preferences?.OverviewCards?.onlyMyCards || {}),
            [chartCategory]: [
              ...(preferences?.OverviewCards?.onlyMyCards?.[chartCategory] ||
                []),
              cardToSave,
            ],
          },
        },
      };

      updateChartPreferences(updatedPreferences, allPreferences, dispatch);
    } else {
      const programF = await lazyFetch({ tableName: "programFields" });

      dispatch(programFieldsDispatch(programF));

      const overviewsConfig =
        programF?.find(
          ({ fieldName }) => fieldName === "Overviews Configuration"
        ) || {};

      const updatedFieldOptions = {
        ...overviewsConfig?.fieldOptions,
        overviewCards: {
          ...overviewsConfig?.fieldOptions?.overviewCards,
          [chartCategory]: [
            ...(overviewsConfig?.fieldOptions?.overviewCards?.[chartCategory] ||
              []),
            cardToSave,
          ],
        },
      };

      updateProgramFields(
        overviewsConfig?.fieldId,
        updatedFieldOptions,
        dispatch
      ).then(() => {
        message.success("Chart Saved Successfully!");
      });
    }

    setNewWidgetModal(false);
    setFilledWidgets({});
  };

  const titleExitst = overviewCards.some(
    ({ chartTitle }) => chartTitle === filledWidgets?.["Chart Title"]
  );

  return (
    <div className="new-card-widget">
      <NewWidgetModal
        {...{
          modalTitle,
          newWidgetModal: true,
          closeModal: () => {
            setFilledWidgets({});
            setNewWidgetModal(false);
            setChartType("");
          },
          widgetSteps,
          filledWidgets,
          onFinish,
          overviewCards,
          disableNext: titleExitst
            ? `Chart title: (${filledWidgets?.["Chart Title"]}) already exists!`
            : "",
        }}
      />
    </div>
  );
};
