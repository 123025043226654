import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

/**
 * This function calculates analytics for overhead entries as CASH or 1099
 *
 * @typedef props
 * @property {GridApi | undefined} overheadGridApi Overhead table gridApi used when working
 * in Overhead Entries step to get the last changes that has happened in the grid table
 * @property {Object[] | []} degRows List of overheadRowData is used to get the state of the data
 * when user is not in Overhead Entries step
 *
 * @param {props} param0
 */
function getOverheadEntriesAnalytics({
  overheadGridApi = undefined,
  degRows = [],
}) {
  let tmpRows = [];

  if (overheadGridApi) {
    overheadGridApi.forEachNode(({ data }) => {
      tmpRows.push(data);
    });
  } else {
    tmpRows = degRows;
  }

  let groupedRows = _.groupBy(tmpRows, ({ employeeId }) => employeeId);

  // separate groupedRowsWithDate rows in dates per employeeId
  for (const employeeId in groupedRows) {
    const listOfEntriesPerEmployee = groupedRows[employeeId];
    const entriesByDate = _.groupBy(
      listOfEntriesPerEmployee,
      ({ punchDate }) => {
        return punchDate ? dayjsNY(punchDate).format("MM/DD/YYYY") : "NoDate";
      }
    );

    for (const day in entriesByDate) {
      const entries = entriesByDate[day];
      if (entries?.length <= 1) {
        entriesByDate[day] = Number(entries[0]?.totalOvh);
      } else {
        let totalOvh = 0;
        for (const entry of entries) {
          totalOvh += Number(entry.totalOvh);
        }
        entriesByDate[day] = Number(totalOvh);
      }
    }
    groupedRows[employeeId] = entriesByDate;
  }

  return groupedRows;
}

export default getOverheadEntriesAnalytics;
