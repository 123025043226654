function findTemplateIssues(proposalData = {}, proposalTemplates = []) {
  let toReturn = {
    missingServices: [],
    appliedServices: [],
    takeOffServices: [],
  };

  let wrappedHTML = proposalData?.parentTemplateContent || "";
  if (!wrappedHTML) return toReturn;
  let pattern = /\{\{([^_]+_[^}]+)\}\}/g;
  let matches =
    wrappedHTML.match(pattern)?.map((e) => {
      let parts = e.replace(/[{}]/g, "").split("_");
      return parts[parts.length - 1];
    }) || [];
  if (!matches) return toReturn;
  let takeOffServices = proposalData?.takeOff?.services || [];
  let filteredServices = proposalTemplates.filter((service) => {
    return matches.includes(service.templateId);
  });
  let missingServices = takeOffServices.filter((service) => {
    return !filteredServices.find(
      (filteredService) =>
        filteredService.additionalDetails?.service?.workId?.toString() ===
        service.serviceId?.toString()
    );
  });

  toReturn = {
    missingServices,
    appliedServices: filteredServices,
    takeOffServices,
  };

  return toReturn;
}

export default findTemplateIssues;
