import { message } from "antd";
import { API } from "aws-amplify";
import { deleteNotification } from "../actions/notificationsActions";

const _ = require("lodash");

function recursiveAccessTitle(el, accessTitle) {
  if ("children" in el) {
    return el.children.find((e) => recursiveAccessTitle(e, accessTitle));
  } else {
    return accessTitle === el?.title && !!el?.write;
  }
}

export const getCognitosForNotificationByAccess = (
  userConfiguration = {},
  accessTitle = "",
  settings = false
) => {
  const cognitosOfAdminUsers = userConfiguration.allUsers.Items.reduce(
    (acc, user) =>
      user.groupName?.toLowerCase() === "admin"
        ? [...acc, user?.cognitoUserId]
        : acc,
    []
  );

  let cognitosForUsersWithAccess = [];

  if (!!userConfiguration) {
    for (const user of userConfiguration.allUsers.Items) {
      if (settings === false) {
        const tmpCognito = user?.routeConfig?.find(
          (el) => el?.title === accessTitle && !!el?.write
        )?.cognitoUserId;

        !!tmpCognito && cognitosForUsersWithAccess.push(tmpCognito);
      } else {
        const tmpCognito = user?.routeConfig?.find((el) =>
          recursiveAccessTitle(el, accessTitle)
        )?.cognitoUserId;

        !!tmpCognito && cognitosForUsersWithAccess.push(tmpCognito);
      }
    }
  }

  return [...new Set([...cognitosOfAdminUsers, ...cognitosForUsersWithAccess])];
};

export const getNameOfUsersForNotificationByAccess = (
  userConfiguration = {},
  accessTitle = "",
  settings = false
) => {
  const nameOfAdminUsers = userConfiguration.allUsers.Items.reduce(
    (acc, user) =>
      user.groupName.toLowerCase() === "admin"
        ? [...acc, user?.nameOfUser]
        : acc,
    []
  );

  let nameOfUsersWithAccess = [];

  if (!!userConfiguration) {
    for (const user of userConfiguration.allUsers.Items) {
      if (settings === false) {
        const tmpCognito = user?.routeConfig?.find(
          (el) => el?.title === accessTitle && !!el?.write
        )?.nameOfUser;

        !!tmpCognito && nameOfUsersWithAccess.push(tmpCognito);
      } else {
        const tmpCognito = user?.routeConfig?.find((el) =>
          recursiveAccessTitle(el, accessTitle)
        )?.nameOfUser;

        !!tmpCognito && nameOfUsersWithAccess.push(tmpCognito);
      }
    }
  }

  return [...new Set([...nameOfAdminUsers, ...nameOfUsersWithAccess])];
};

export const getCognitosForNotification = (
  userConfiguration = {},
  teamsConfiguration = [],
  groupName = "admin"
) => {
  // get admin users
  const cognitosOfAdminUsers = userConfiguration.allUsers.Items.reduce(
    (acc, user) =>
      user?.groupName?.toLowerCase() === "admin" ||
      user?.groupName?.toLowerCase() === groupName?.toLowerCase()
        ? [...acc, user?.cognitoUserId]
        : acc,
    []
  );

  const cognitosOfUsersInsideTeam =
    teamsConfiguration
      ?.flatMap((item) => item?.members?.map((member) => member?.cognitoUserId))
      .filter(Boolean) || [];

  return [...new Set([...cognitosOfAdminUsers, ...cognitosOfUsersInsideTeam])];
};

// returns admin users and users inside team
export const getAdminAndTeamUsers = (
  userConfiguration,
  teamsConfiguration = []
) => {
  const filteredUsers = userConfiguration.allUsers.Items.filter(
    ({ isSuspended }) => !isSuspended
  );

  // get admin users
  const adminUsers = filteredUsers.filter(
    ({ groupName }) => groupName && groupName?.toLowerCase() === "admin"
  );

  //get cognitos of team users
  const teamUsers =
    teamsConfiguration
      ?.flatMap((item) => item.members.map((member) => member?.cognitoUserId))
      .filter(Boolean) || [];

  const findedTeamUsers = filteredUsers.filter((user) =>
    teamUsers.includes(user?.cognitoUserId)
  );

  return _.uniqBy([...adminUsers, ...findedTeamUsers], "cognitoUserId");
};

export const deleteNotifications = (notifications, id, dispatch) => {
  if (Array.isArray(notifications)) {
    message.loading({
      content: "Deleting Notifications...",
      key: "notifications",
    });

    Promise.allSettled(
      notifications.map(({ notificationBody, id: notificationId }) => {
        if (notificationBody.notificationObject.id === id) {
          dispatch(deleteNotification(notificationId));
          return API.del("notifications", `/notifications/${notificationId}`);
        }
        return [];
      })
    )
      .then((res) => {
        message.success({
          content: "Notifications Deleted Successfully",
          key: "notifications",
        });
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while deleting notifications",
          key: "notifications",
        });
        console.log("Error Deleting Notifications: ", err);
      });
  }
};
