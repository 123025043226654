import React, { useMemo } from "react";
import DynamicAgGridTable from "../../../../commonComponents/DynamicAgGridTable/DynamicAgGridTable";
import { newBillColumnDefs } from "../utils/newBillColumnDefs";
import { getRowNodeId, updateCellValue } from "../GridData/gridHelpers";
import { message } from "antd";
import { useRedux } from "../../../hooks";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { frameworkComponents } from "../../../Projects/Accounting/Applications/ApplicationView/components/ServiceList/GridData";
import { useTableContext } from "../../../../commonComponents/DynamicAgGridTable/context/AgGridTableContext";
import { forceToNumber } from "../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const BillTable = () => {
  const [isWritable] = useRedux("billIsWritable");

  const { form, billData, vendor } = useInitialBillContext();
  const { rowData, setRowData } = useCreateBillContext();
  const { gridApi } = useTableContext();

  const columnDefs = useMemo(() => {
    const newColumnDefs = newBillColumnDefs();
    return newColumnDefs;
  }, []);

  const onCellClicked = (params) => {
    if (!isWritable && billData) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
  };

  const onCellEditingStopped = (params) => {
    if (!isWritable && billData) return;
    const appliedAmount = rowData
      .filter((row) => row.itemId !== params.data.itemId)
      .reduce((acc, row) => (acc += row.total), 0);
    const totalAmount = form.getFieldValue("billAmount") || 0;
    const remainingValue =
      totalAmount - (appliedAmount + forceToNumber(params.newValue));

    if (remainingValue < 0) {
      message.error("You can't apply more than the total  amount.");

      gridApi.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.colDef.field,
      });
      return;
    }
    if (!!!rowData.length) return;

    setRowData((prev) => updateCellValue(prev, params, vendor, remainingValue));
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DynamicAgGridTable
        rowData={rowData}
        components={frameworkComponents}
        autoResize={false}
        columnDefs={columnDefs}
        rowHeight={36}
        rowBuffer={5}
        context={{form}}
        customClassName="new-bill-ag-grid-table"
        onCellEditingStopped={onCellEditingStopped}
        onCellClicked={onCellClicked}
        getRowNodeId={getRowNodeId}
      />
    </div>
  );
};

export default BillTable;
