import { Tag } from "antd";
import { memo } from "react";
import { ReportIcon } from "../../../../../icons";
import { StarFilled } from "../../../../commonComponents/StarCheckbox/assets";
import { formatCategoryName } from "../../../../pages/Settings/settingsComponents/DocumentationConfiguration/View/utils";
import { categoriesIcons } from "../../configuration/reportsConfigurationData";
import "./ReportLabel.scss";
import { useSelector } from "react-redux";

const ReportLabel = ({
  report,
  categoryName,
  department,
  history = false,
  onClick,
}) => {
  const CategoryIcon = categoriesIcons[categoryName] || categoriesIcons.default;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const dividerClassName = `verticalDivider ${
    !report ? "favoriteCategory" : ""
  }`.trim();

  return (
    <div
      className={`favoriteReportLabel ${
        isDarkMode && "favoriteReportLabelDark"
      }`}
      {...{ onClick }}
    >
      {/* <div className="reportsLabel"> */}
      {!history ? (
        <StarFilled fill={"#f5c600"} />
      ) : (
        <ReportIcon className="reportIcon" />
      )}
      {!!report && (
        <>
          <div className="reportName">{report.reportName}</div>
          <div className={dividerClassName} />
        </>
      )}
      {/* </div> */}
      <div className="categoryLabel">
        <CategoryIcon className="categoryIcon" />
        <div className="categoryName">{formatCategoryName(categoryName)}</div>
      </div>
      <div className={dividerClassName} />
      <Tag className="departmentTag">{formatCategoryName(department)}</Tag>
    </div>
  );
};

export default memo(ReportLabel);
