import { useEffect, useState, useCallback, useMemo } from "react";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "@aws-amplify/api";
import dayjs from "dayjs";

import {
  fetchByList,
  filterRowDataByTeamConfig,
  filterTables,
  getCognitosForNotification,
  getFiltersObject,
  getPanelObject,
  lazyFetch,
} from "../../../../utils";
import BasePage from "../../BasePage";
import { compareIncluding } from "../../utils";
import { LoadableComp } from "../../XComponents";
import {
  columnDefs,
  chartingOptions,
  excelColumnsConfig,
  formatCellValueHandler,
} from "../AgGridData";
import { DispatchModal, ForceToInspectModal } from "./modals";
import {
  DescriptionModal,
  NewInspectionModal,
} from "../components/BasePage/modals";
import InspectionDaysOverview from "./InspectionDaysOverview/InspectionDaysOverview";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import { getExcelColumnKeys } from "../../../../utils/getExcelColumnKeys";
import { getChartingOptions } from "./utils/getChartingOptions";
import { filterData } from "./utils/functions";
import FooterFilterInfo from "../components/FooterFilterInfo";

const InspectionBasePage = ({
  locationState,
  filterKey = null,
  filterValue = null,
  filterProjectId = null,
  isTab = false,
}) => {
  const {
    inspectionStatus = null,
    inspectedBy = null,
    service = null,
    periodFilter = null,
  } = locationState || {};
  // redux
  const { programFields } = useSelector((state) => state.programFields);

  const panelObject = getPanelObject(programFields, "Safety Inspection");
  const filtersObject = getFiltersObject(programFields, "Inspections", {
    inspectionStatus,
    service,
    inspectedBy,
    periodFilter,
  });
  const navigate = useNavigate();
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const [form] = Form.useForm();
  const [rowData, setRowData] = useState();
  const [projects, setProjects] = useState([]);
  const [estimations, setEstimations] = useState([]);
  const [allDimensions, setAllDimensions] = useState([]);
  const [currentProject, setCurrentProject] = useState("");
  const [inspectors, setInspectors] = useState(null);
  const [isDaysOverviewOpen, setIsDaysOverviewOpen] = useState(false);

  const [description, setDescription] = useState("");
  const [dispatchModalVisible, setDispatchModalVisible] = useState(false);
  const [newButton, setNewButton] = useState(false);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [gridApi, setGridApi] = useState();
  const [accessRight, setAccessRight] = useState();
  const [viewAccessRight, setViewAccessRight] = useState();
  const [forceToInspectModalVisible, setForceToInspectModalVisible] =
    useState(false);
  const [descriptionModalVisible, setDescriptionModalVisible] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState();

  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);

  const modifiedRowData = rowData?.map((row) => ({
    ...row,
    linkTo: `${window?.location?.pathname}/${row.inspectionId}`,
    linkToProject: `/projects/${row?.projectId}`,
  }));

  const additionalStatusPanels = useMemo(() => {
    return [
      {
        statusPanel: FooterFilterInfo,
        key: "footerFilterInfo",
        align: "left",
      },
    ];
  }, []);

  const handleApplyFilters = (filters) => {
    const allowedFilters = ["estimations", "services", "scheduleName"];
    const filterKeys = Object.keys(filters ?? {});

    const filteredFilters = filterKeys?.some((key) =>
      allowedFilters.includes(key)
    );

    if (filteredFilters) {
      const tt = Object.entries(filters)?.filter(([key]) => {
        return allowedFilters.includes(key);
      });
      const filteredObject = Object.fromEntries(tt);

      setAppliedFilters(filteredObject);
    }
  };

  const handleClearFilters = () => {
    setAppliedFilters(undefined);
  };

  const onStatusChange = async ({ projectId, status, inspectionId }) => {
    await API.patch("projects", `/projects/${projectId}`, {
      body: {
        inspectionStatus: status,
        // key: "inspectionStatus",
        // value: status,
      },
    });
    await API.patch("inspections", `/inspections/${inspectionId}`, {
      body: {
        inspectionStatus: status,
        // key: "inspectionStatus",
        // value: status,
      },
    }).then(
      () => message.success("Inspection dispatched", 10),
      broadcastNotification(
        "16",
        "onInspectionDispatch",
        [
          {
            common: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
            inspectionType: currentProject?.inspectionType,
            projectName: currentProject?.projectName,
          },
          {
            userName: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
            currentUser: authenticatedUser?.sub,
            cognitos: getCognitosForNotification(userConfiguration),
          },
        ],
        inspectionId
      )
    );
    setProjects(
      [...projects]?.map((item) =>
        compareIncluding(projectId, item?.projectId)
          ? { ...item, inspectionStatus: status }
          : { ...item }
      )
    );
    chooseTable();
  };

  useEffect(() => {
    chooseTable();
  }, []);

  useEffect(() => {
    setAccessRight(
      userConfiguration?.routeConfig?.find(
        ({ title }) => title === "Inspections"
      )
    );
    setViewAccessRight(
      userConfiguration?.routeConfig?.find(
        ({ title }) => title === "Projects/View"
      )
    );
  }, [userConfiguration]);

  useEffect(() => {
    setAllDimensions(
      serviceDefinitions?.map(({ serviceName, dimensions }) => ({
        serviceName,
        dimensions,
      }))
    );
  }, [serviceDefinitions]);

  useEffect(() => {
    if (!!projects.length) {
      const fetchSchedules = async () => {
        const projectIds = projects?.map(({ projectId }) => projectId);

        const schedules = await fetchByList(
          "scheduling",
          "projectId",
          projectIds
        );

        setSchedules(schedules);
      };

      fetchSchedules();
    }
  }, [projects]);

  const navigateAfterCompanyChange = (data, openProject) => {
    if (openProject === false) {
      navigate(`/inspectionsView/${data?.inspectionId}`);
    } else {
      navigate(openProject?.pathname, { ...openProject?.state });
    }
  };

  const changeCompany = async ({
    // user,
    data,
    openProject,
  }) => {
    navigateAfterCompanyChange(data, openProject);
  };

  const chooseTable = useCallback(async () => {
    Promise.allSettled(
      !!filterKey && !!filterValue
        ? [
            filterTables("inspections", filterKey, filterValue),
            API.get("projects", `/projects`),
            filterTables("userConfiguration", "groupName", "Inspector"),
            lazyFetch({
              tableName: "estimations",
              listOfKeys: ["estimationId", "isChangeOrder", "estimationNumber"],
            }),
          ]
        : [
            API.get("inspections", `/inspections`),
            API.get("projects", `/projects`),
            filterTables("userConfiguration", "groupName", "Inspector"),
            lazyFetch({
              tableName: "estimations",
              listOfKeys: ["estimationId", "isChangeOrder", "estimationNumber"],
            }),
          ]
    )
      .then(
        ([
          { value: inspections = [] },
          { value: projectsList = [] },
          { value: inspector = [] },
          { value: estimations = [] },
        ]) => {
          setProjects(projectsList?.projects);
          setEstimations(estimations);
          setInspectors(inspector);

          if (!!filterProjectId) {
            setCurrentProject(
              projects?.find(({ projectId }) => projectId === filterProjectId)
            );
          }
          const projectInspections =
            inspections
              // ?.filter((el) => el.inspectionFolderId !== "")
              ?.map((item) => ({
                ...item,
                inspectedBy: item?.inspectedBy?.nameOfUser,
                // servicesInspected: item?.servicesInspected
                //   ?.filter(
                //     ({ isInspectedFull }) =>
                //       !compareIncluding(String(isInspectedFull), "none")
                //   )
                //   .map(({ serviceType }) => serviceType),
              })) || [];
          setRowData(
            filterRowDataByTeamConfig(userConfiguration, projectInspections)
          );
        }
      )
      ?.catch((err) => {
        console.error("Error Getting Data: ", err);
      });

    // setProjects(
    //   projects?.map((item) => {
    //     // item.isChangeOrder = estimations?.find(
    //     return item;
    //   })
    // );
    // setEstimations(estimations);
    // setInspectors(inspector);

    // if (!!filterProjectId) {
    //   setCurrentProject(
    //     projects?.find(({ projectId }) => projectId === filterProjectId)
    //   );
    // }
    // const projectInspections =
    //   inspections
    //     // ?.filter((el) => el.inspectionFolderId !== "")
    //     ?.map((item) => ({
    //       ...item,
    //       inspectedBy: item?.inspectedBy?.nameOfUser,
    //       servicesInspected: item?.servicesInspected
    //         ?.filter(
    //           ({ isInspectedFull }) =>
    //             !compareIncluding(String(isInspectedFull), "none")
    //         )
    //         .map(({ serviceType }) => serviceType),
    //     })) || [];

    // getImagesPerInspection(
    //   inspections.find(
    //     (item) => item.inspectionId === "91a78430-0455-11ee-96df-03c501c8d43a"
    //   )
    // );

    // setRowData(
    //   filterRowDataByTeamConfig(userConfiguration, projectInspections)
    // );
  }, []);

  function reloadGrid(gridApi) {
    gridApi.showLoadingOverlay();
    chooseTable();
  }

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (headerName == "Inspection Date") {
          return dayjs(value).format("MM/DD/YYYY");
        } else if (headerName === "Estimations") {
          const estimations = value?.estimations?.map(
            ({ estimationNumber }) => estimationNumber
          );

          const uniqueEstimations = [
            ...new Set(
              estimations?.map((estimationNumber) => estimationNumber)
            ),
          ];

          const result = uniqueEstimations
            ?.map((item) => (!!item ? item : ""))
            ?.join(", ");
          return result ? result : "";
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  };

  const columnDefsMemo = useMemo(() => {
    return columnDefs({
      setDispatchModalVisible,
      setForceToInspectModalVisible,
      setCurrentProject,
      view: viewAccessRight,
      changeCompany,
      navigate,
      inspectionView: userConfiguration?.routeConfig?.find(
        ({ title }) => title === "Inspections/View"
      ),
      estimations,
    });
  }, [viewAccessRight, estimations]);

  return (
    <LoadableComp loading={!!!rowData}>
      <Form form={form}>
        <BasePage
          {...{
            title: "Inspections",
            rowData: filterData(appliedFilters, modifiedRowData),
            originalRowData: modifiedRowData || [],
            filtersObject,
            videoLinks: true,
            hasGalleryItems: true,
            columnDefs: columnDefsMemo,
            formatCellValueHandler,
            exportGridToExcel,
            defaultExcelExportParams: {
              columnKeys: excelColumnsConfig,
              fileName: "Inspections",
            },
            additionalStatusPanels,
            panelObject,
            getGridApi: (e) => {
              setGridApi(e);
            },
            customNew: (e) => {
              setNewButton(e);
            },
            getChartingOptions: (data) => getChartingOptions(data),
            hasNew: accessRight?.write,
            paginationPageSize: 10,
            reloadGrid,
            setDaysOverview: setIsDaysOverviewOpen,
            onApplyFilters: (filters) => handleApplyFilters(filters),
            onClearFilters: () => handleClearFilters(),
          }}
        />
        {!!inspectors && (
          <DispatchModal
            {...{
              visible: dispatchModalVisible,
              setVisible: setDispatchModalVisible,
              inspectors: inspectors?.map(({ nameOfUser }) => `${nameOfUser}`),
              form,
              currentProject,
              onStatusChange,
              refreshTable: chooseTable,
            }}
          />
        )}
        {newButton && (
          <NewInspectionModal
            {...{
              title: "Inspection",
              visible: newButton,
              setVisible: setNewButton,
              form,
              setRowData,
              isTab: isTab,
              currentProject,
              setCurrentProject,
              otherProps: {
                inspectors: inspectors?.map(
                  ({ cognitoUserId, nameOfUser }) => ({
                    name: nameOfUser,
                    cognitoUserId,
                  })
                ),
                propedRowData: rowData,
                projects,
                estimations,
                allDimensions,
                schedules,
                setDescription,
                setDescriptionModalVisible,
              },
            }}
          />
        )}
        <DescriptionModal
          visible={descriptionModalVisible}
          setVisible={setDescriptionModalVisible}
          currentProject={currentProject}
          description={description}
        />
        <ForceToInspectModal
          {...{
            visible: forceToInspectModalVisible,
            setVisible: setForceToInspectModalVisible,
            currentProject,
            onStatusChange,
            setDispatchModalVisible,
          }}
        />
      </Form>

      <InspectionDaysOverview
        title="Inspection Days Overview"
        inspections={rowData || []}
        isDaysOverviewOpen={isDaysOverviewOpen}
        setIsDaysOverviewOpen={setIsDaysOverviewOpen}
      />
    </LoadableComp>
  );
};
export default InspectionBasePage;
