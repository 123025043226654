import React from "react";
import lazyRetry from "../utils/LazyRetry";

const DynamicDriverView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicDriverView" */ "./SidebarPages/Fleet/view/driverView/DynamicDriverView"
      ),
    "DynamicDriverView"
  )
);

const HrManagementBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "HrManagement" */ "./SidebarPages/HrManagement/HrManagementBasePage"
      ),
    "HrManagementBasePage"
  )
);

const DynamicEmployeeView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "HrManagement" */ "./SidebarPages/HrManagement/Pages/Employee/DynamicEmployeeView.jsx"
      ),
    "DynamicEmployeeView"
  )
);

const FleetManagementDynamicView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "FleetManagementDynamicView" */ "./SidebarPages/Fleet/page/FleetManagementDynamicView"
      ),
    "FleetManagementDynamicView"
  )
);
const ForceChangePassword = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ForceChangePassword" */ "./Header/forms/ForceChangePassword"
      ),
    "ForceChangePassword"
  )
);
const MainDynamicApprovalsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicApprovalsView" */ "./SidebarPages/Approvals/MainDynamicApprovalsView"
      ),
    "MainDynamicApprovalsView"
  )
);
const MainDynamicFleetInspectionView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicApprovalsView" */ "./SidebarPages/Fleet/inspection/MainDynamicFleetInspectionView"
      ),
    "MainDynamicFleetInspectionView"
  )
);
const ReportsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ReportsPage" */ "./SidebarPages/Reports/page/ReportsDynamicView"
      ),
    "ReportsDynamicView"
  )
);
const AccountingDynamicView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AccountingDynamicView" */ "./SidebarPages/Accounting/Page/AccountingDynamicView"
      ),
    "AccountingDynamicView"
  )
);
const MappingDynamicView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MappingDynamicView" */ "./SidebarPages/Mapping/MappingDynamicView"
      ),
    "MappingDynamicView"
  )
);
const InspectionBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "InspectionBasePage" */ "./SidebarPages/Fleet/inspection/InpectionBasePage"
      ),
    "InspectionBasePage"
  )
);
const ViolationDetails = React.lazy(() =>
  lazyRetry(
    () =>
      /* webpackChunkName: "ViolationDetails" */ import(
        "./SidebarPages/Fleet/ViolationDetails/ViolationsDetails"
      ),
    "ViolationDetails"
  )
);
const WrapperViolationView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "WrapperViolationView" */ "./SidebarPages/Fleet/view/violationView"
      ),
    "WrapperViolationView"
  )
);
const NewViolationView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NewViolationView" */ "./SidebarPages/Fleet/view/violationView/NewViolationView"
      ),
    "NewViolationView"
  )
);
const DynamicFleetView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicFleetView" */ "./SidebarPages/Fleet/view/pageView/DynamicFleetView"
      ),
    "DynamicFleetView"
  )
);
const DynamicScheduleView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicScheduleView" */ "./SidebarPages/Scheduling/DynamicScheduleView"
      ),
    "DynamicScheduleView"
  )
);
const Pdf = React.lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "Pdf" */ "./SidebarPages/Projects/Pdf/Pdf"),
    "Pdf"
  )
);

const MainDynamicTasksView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicTasksView" */ "./SidebarPages/TaskManager/MainDynamicTasksView"
      ),
    "MainDynamicTasksView"
  )
);
const DynamicProjectsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicProjectsView" */ "./SidebarPages/Projects/DynamicProjectsView"
      ),
    "DynamicProjectsView"
  )
);

const DynamicAccountView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicAccountView" */ "./SidebarPages/Accounts/DynamicAccountView"
      ),
    "DynamicAccountView"
  )
);
const DynamicOpportunitiesView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicOpportunitiesView" */ "./SidebarPages/Opportunities/DynamicOpportunitiesView"
      ),
    "DynamicOpportunitiesView"
  )
);
const DynamicEstimationsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicEstimationsView" */ "./SidebarPages/Estimations/Tabs/EstimationsView/DynamicEstimationsView"
      ),
    "DynamicEstimationsView"
  )
);
const RentalsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "RentalsView" */ "./SidebarPages/Projects/Accounting/Rentals/RentalsView/RentalsView"
      ),
    "RentalsView"
  )
);
const PDFTemplates = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PDFTemplates" */ "./SidebarPages/GeneratePDF/PDFTemplates"
      ),
    "PDFTemplates"
  )
);
const DynamicContactView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicContactView" */ "./SidebarPages/Contacts/DynamicContactView"
      ),
    "DynamicContactView"
  )
);

const GeneralProjectOverview = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicDriverView" */ "./SidebarPages/GenerealProjectOverview/Tabs/GeneralProjectOverviewTabView.jsx"
      ),
    "GeneralProjectOverview"
  )
);
const ApplicationView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ApplicationView" */ "./SidebarPages/Projects/Accounting/Applications/ApplicationView"
      ),
    "ApplicationView"
  )
);

const ScheduleOfValueView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ApplicationView" */ "./SidebarPages/Projects/Accounting/ScheduleOfValues/ScheduleOfValueView"
      ),
    "View"
  )
);

const ClaimsBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ClaimsBasePage" */ "./SidebarPages/Claims/ClaimsBasePage.jsx"
      ),
    "ClaimsBasePage"
  )
);
const HearingsBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "HearingsBasePage" */ "./SidebarPages/Claims/Hearings/HearingsBasePage.jsx"
      ),
    "HearingsBasePage"
  )
);
const DynamicLeadsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicLeadsView" */ "./SidebarPages/Leads/DynamicLeadsView"
      ),
    "DynamicLeadsView"
  )
);
const DynamicPermitDrawingView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicPermitDrawingView" */ "./SidebarPages/PermitDrawings/DynamicDrawingsPage"
      ),
    "DynamicPermitDrawingView"
  )
);
const SubLeadsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SubLeadsPage" */ "./SidebarPages/SubLeads/NewSubLeadsPage"
      ),
    "SubLeadsPage"
  )
);
// import PermitDrawingsDynamicView from "./SidebarPages/PermitDrawings/PermitDrawingsDynamicView";
const PermitDrawingsDynamicView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PermitDrawingsDynamicView" */ "./SidebarPages/PermitDrawings/PermitDrawingsDynamicView"
      ),
    "PermitDrawingsDynamicView"
  )
);
const MainDynamicScheduleView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicScheduleView" */ "./SidebarPages/Scheduling/MainDynamicScheduleView"
      ),
    "MainDynamicScheduleView"
  )
);
const SubcontractorsOverviewBase = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SubcontractorsBasePage" */ "./SidebarPages/Subcontractors/overviewBasePage/SubcontractorsOverviewBase"
      ),
    "SubcontractorsOverviewBase"
  )
);
const NewDashboard = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NewDashboard" */ "./SidebarPages/Dashboard/NewDashboard"
      ),
    "NewDashboard"
  )
);
const MainDynamicLeadsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicLeadsView" */ "./SidebarPages/Leads/MainDynamicLeadsView"
      ),
    "MainDynamicLeadsView"
  )
);

const MainDynamicEstimationsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicEstimationsView" */ "./SidebarPages/Estimations/MainDynamicEstimationsView"
      ),
    "MainDynamicEstimationsView"
  )
);
const MainDynamicOpportunityView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicOpportunityView" */ "./SidebarPages/OpportunitiesOverview/MainDynamicOpportunityView"
      ),
    "MainDynamicOpportunityView"
  )
);
const MainDynamicProjectsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicProjectsView" */ "./SidebarPages/Projects/MainDynamicProjectsView"
      ),
    "MainDynamicProjectsView"
  )
);
const MainDynamicClientsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ClientBasePage" */ "./SidebarPages/Accounts/MainDynamicClientsView"
      ),
    "MainDynamicClientsView"
  )
);
const ContactsOverview = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ContactsBasePage" */ "./SidebarPages/contactsOverview/ContactsOverview"
      ),
    "ContactsOverview"
  )
);
const MainDynamicInspectionView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicInspectionView" */ "./SidebarPages/Inspections/MainDynamicInspectionView"
      ),
    "MainDynamicInspectionView"
  )
);
const DynamicInspectionView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicInspectionView" */ "./SidebarPages/Inspections/DynamicInspectionView"
      ),
    "DynamicInspectionView"
  )
);

const DynamicNotesView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicNotesView" */ "./SidebarPages/Notes/DynamicNotesView"
      ),
    "DynamicNotesView"
  )
);

const InvoiceModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicInspectionView" */ "./SidebarPages/Accounting/components/CreateInvoice/InvoiceModal.jsx"
      ),
    "InvoiceModal"
  )
);

const EditCharge = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicInspectionView" */ "./SidebarPages/Projects/Accounting/Charges/components/EditCharge.jsx"
      ),
    "EditCharge"
  )
);

const EditPayment = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "EditPayment" */ "./SidebarPages/Accounting/Tabs/Payments/components/EditPayment.jsx"
      ),
    "EditPayment"
  )
);

const MaintenanceDynamicView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MaintenanceDynamicView" */ "./SidebarPages/FleetsMaintenances/MaintenanceDynamicView.jsx"
      ),
    "MaintenanceDynamicView"
  )
);

const DefectsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DefectsView" */ "./SidebarPages/FleetsMaintenances/DefectsView.jsx"
      ),
    "DefectsView"
  )
);

const DynamicWorkOrderView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicWorkOrderView" */ "./SidebarPages/FleetsMaintenances/WorkOrder/DynamicWorkOrderView.jsx"
      ),
    "DynamicWorkOrderView"
  )
);

const FleetsInventoryOverviewBage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "FleetInventory" */ "./SidebarPages/FleetsMaintenances/Tabs/FleetInventory/fleetOverviewBage/FleetsInventoryOverviewBage.jsx"
      ),
    "FleetsInventoryOverviewBage"
  )
);

const ClaimsDynamicView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ClaimsDynamicView" */ "./SidebarPages/Claims/Page/ClaimsDynamicView.jsx"
      ),
    "ClaimsDynamicView"
  )
);
const HearingsDynamicView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "HearingsDynamicView" */ "./SidebarPages/Claims/Hearings/Page/HearingsDynamicView.jsx"
      ),
    "HearingsDynamicView"
  )
);

const VehicleDamage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "VehicleDamage" */ "./SidebarPages/Safety/VehicleDamage/VehicleDamage"
      ),
    "VehicleDamage"
  )
);

const PropertyDamage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PropertyDamage" */ "./SidebarPages/Safety/PropertyDamage/PropertyDamage"
      ),
    "PropertyDamage"
  )
);

const PersonalInjury = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PersonalInjury" */ "./SidebarPages/Safety/PersonalInjury/PersonalInjury"
      ),
    "PersonalInjury"
  )
);

const OtherTradeIncident = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "OtherTradeIncident" */ "./SidebarPages/Safety/OtherTradeIncident/OtherTradeIncident"
      ),
    "OtherTradeIncident"
  )
);

const SafetyBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SafetyBasePage" */ "./SidebarPages/Safety/SafetyBasePage/SafetyBasePage"
      ),
    "SafetyBasePage"
  )
);

const MainDynamicDocumentationsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicDocumentationsView" */ "./SidebarPages/Documentation/MainDynamicDocumentationsView"
      ),
    "MainDynamicDocumentationsView"
  )
);

const IncidentsBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "IncidentsBasePage" */ "./SidebarPages/Incidents/IncidentsBasePage"
      ),
    "IncidentsBasePage"
  )
);

const SafetyModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SafetyModal" */ "./SidebarPages/Safety/SafetyModal/SafetyModal"
      ),
    "SafetyModal"
  )
);

const SafetyInspectionModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SafetyInspectionModal" */ "./SidebarPages/Safety/Inspections/Modal/NewSafetyInspection"
      ),
    "SafetyInspectionModal"
  )
);

// const MaintenanceBasePage = React.lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         /* webpackChunkName: "MaintenanceBasePage" */ "./SidebarPages/FleetsMaintenances/Tabs/Maintenance/MaintenanceBasePage"
//       ),
//     "MaintenanceBasePage"
//   )
// );

const MaintenanceBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MaintenanceBasePage" */ "./SidebarPages/FleetMaintenanceView/FleetMaintenanceView"
      ),
    "MaintenanceBasePage"
  )
);

const MaintenanceModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MaintenanceModal" */ "./SidebarPages/Fleet/view/pageView/components/SingleTruckMaintenance/modals/maintenanceModal/MaintenanceModal"
      ),
    "MaintenanceModal"
  )
);

const Defects = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "Defects" */ "./SidebarPages/FleetsMaintenances/Tabs/Defects/Defects"
      ),
    "Defects"
  )
);

const ScheduleMaintenance = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ScheduleMaintenance" */ "./SidebarPages/FleetsMaintenances/Tabs/ScheduleMaintenance/ScheduleMaintenance"
      ),
    "ScheduleMaintenance"
  )
);

const WorkOrderBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "WorkOrderBasePage" */ "./SidebarPages/FleetsMaintenances/Tabs/WorkOrder/WorkOrderBasePage"
      ),
    "WorkOrderBasePage"
  )
);

const ScheduleMaintenanceModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ScheduleMaintenanceModal" */ "./SidebarPages/FleetsMaintenances/Tabs/ScheduleMaintenance/ScheduleMaintenanceModal/ScheduleMaintenanceModal"
      ),
    "ScheduleMaintenanceModal"
  )
);

const IncidentModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "IncidentModal" */ "./SidebarPages/Incidents/Modals/IncidentModal"
      ),
    "IncidentModal"
  )
);

const DynamicSafetyInspection = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicSafetyInspection" */ "./SidebarPages/Safety/Inspections/DynamicSafetyInspection"
      ),
    "DynamicSafetyInspection"
  )
);

const MainSafetyInspectionView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainSafetyInspectionView" */ "./SidebarPages/Safety/Inspections/MainSafetyInspectionView"
      ),
    "MainSafetyInspectionView"
  )
);

const SafetyCitations = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SafetyCitations" */ "./SidebarPages/Safety/Citations/SafetyCitations.jsx"
      ),
    "SafetyCitations"
  )
);
const DynamicCitationView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicCitationView" */ "./SidebarPages/Safety/Citations/DynamicCitationView"
      ),
    "DynamicCitationView"
  )
);

const FleetMaintenanceOverview = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "FleetMaintenanceOverview" */ "./SidebarPages/FleetsMaintenances/Tabs/Overview/FleetMaintenanceOverview"
      ),
    "FleetMaintenanceOverview"
  )
);

const NewDispatchModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NewDispatchModal" */ "./SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/NewDispatchModal"
      ),
    "NewDispatchModal"
  )
);

const ToDoBasePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ToDoBasePage" */ "./SidebarPages/ToDos/ToDoView"
      ),
    "ToDoBasePage"
  )
);

const DynamicMaintenanceView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicMaintenanceView" */ "./SidebarPages/FleetMaintenanceView/pages/DynamicMaintenanceView/DynamicMaintenanceView"
      ),
    "DynamicMaintenanceView"
  )
);

const DynamicFleetInspectionView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicFleetInspectionView" */ "./SidebarPages/FleetMaintenanceView/pages/DynamicFleetInspectionView/DynamicFleetInspectionView"
      ),
    "DynamicFleetInspectionView"
  )
);

const ToDoPathModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ToDoPathModal" */ "./SidebarPages/ToDos/ToDoPathModal"
      ),
    "ToDoPathModal"
  )
);

const MainDynamicVendorsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MainDynamicVendorsView" */ "./SidebarPages/Vendors/MainDynamicVendorsView"
      ),
    "MainDynamicVendorsView"
  )
);
const DynamicVendorsView = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DynamicVendorsView" */ "./SidebarPages/Vendors/DynamicVendorsView"
      ),
    "DynamicVendorsView"
  )
);

const NewBillModal = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NewBillModal" */ "./SidebarPages/Vendors/tabs/NewBillModal"
      ),
    "NewBillModal"
  )
);

const NewRequestPath = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NewRequestPath" */ "./Header/forms/RequestFormModal/NewRequestPath.jsx"
      ),
    "NewRequestPath"
  )
);

export {
  ForceChangePassword,
  NewDashboard,
  MainDynamicLeadsView,
  DynamicLeadsView,
  MainDynamicEstimationsView,
  DynamicEstimationsView,
  MainDynamicOpportunityView,
  DynamicOpportunitiesView,
  MainDynamicProjectsView,
  GeneralProjectOverview,
  DynamicProjectsView,
  ClaimsBasePage,
  ClaimsDynamicView,
  HearingsBasePage,
  HearingsDynamicView,
  MainDynamicClientsView,
  DynamicAccountView,
  ContactsOverview,
  DynamicContactView,
  MainDynamicInspectionView,
  DynamicInspectionView,
  SubcontractorsOverviewBase,
  MainDynamicScheduleView,
  DynamicScheduleView,
  SubLeadsPage,
  PermitDrawingsDynamicView,
  DynamicPermitDrawingView,
  AccountingDynamicView,
  ScheduleOfValueView,
  ApplicationView,
  EditCharge,
  InvoiceModal,
  RentalsView,
  EditPayment,
  PDFTemplates,
  MainDynamicTasksView,
  Pdf,
  FleetManagementDynamicView,
  HrManagementBasePage,
  DynamicEmployeeView,
  VehicleDamage,
  PropertyDamage,
  PersonalInjury,
  OtherTradeIncident,
  MainSafetyInspectionView,
  FleetMaintenanceOverview,
  DynamicFleetView,
  DynamicDriverView,
  ViolationDetails,
  // WrapperViolationView,
  NewViolationView,
  MainDynamicDocumentationsView,
  MainDynamicApprovalsView,
  ReportsPage,
  MappingDynamicView,
  DynamicNotesView,
  Defects,
  DefectsView,
  WorkOrderBasePage,
  DynamicWorkOrderView,
  ScheduleMaintenance,
  ScheduleMaintenanceModal,
  FleetsInventoryOverviewBage,
  SafetyBasePage,
  SafetyModal,
  SafetyInspectionModal,
  IncidentsBasePage,
  IncidentModal,
  DynamicSafetyInspection,
  MaintenanceBasePage,
  MaintenanceModal,
  InspectionBasePage,
  NewDispatchModal,
  MainDynamicFleetInspectionView,
  SafetyCitations,
  DynamicCitationView,
  ToDoBasePage,
  DynamicMaintenanceView,
  DynamicFleetInspectionView,
  ToDoPathModal,
  MainDynamicVendorsView,
  DynamicVendorsView,
  NewBillModal,
  NewRequestPath,
};
