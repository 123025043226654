const getSortedObjects = (items) => {
  const order = ["Favorites", "Clear order preferance", "Search"];
  const excludedDefault = [];
  const includedDefault = [];

  items.forEach((item) => {
    if (
      item.title !== "Favorites" &&
      item.title !== "Search" &&
      item.title !== "Clear order preferance"
    ) {
      excludedDefault.push(item);
    } else {
      includedDefault.push(item);
    }
  });

  includedDefault.sort(
    (a, b) => order.indexOf(a.title) - order.indexOf(b.title)
  );

  return [...includedDefault, ...excludedDefault];
};

export const sortObjectsByTitles = (objects, sortedArray) => {
  // Create a map for quick lookup of title indices in sortedArray
  const titleIndexMap = new Map();
  sortedArray.forEach((title, index) => {
    titleIndexMap.set(title, index);
  });

  // Sort the objects based on the title's index in sortedArray
  objects.sort((a, b) => {
    const indexA = titleIndexMap.get(a.title);
    const indexB = titleIndexMap.get(b.title);

    // If a title is not in the sortedArray, place it at the end
    if (indexA === undefined) return 1;
    if (indexB === undefined) return -1;

    return indexA - indexB;
  });

  return getSortedObjects(objects);
};
