import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

export const forecastingRaport = ({
  forecastEstimations = {},
  scheduleEstimations = {},
}) => [
  {
    header: "FORECASTING",
    borderBottomColor: "#ff6347",
    display: forecastingData(forecastEstimations),
  },
  {
    header: "SCHEDULING",
    borderBottomColor: "#483d8b",
    display: forecastingData(scheduleEstimations),
  },
  {
    header: "ACTUAL PROGRESS",
    borderBottomColor: "#228b22",
    progress: true,
    display: forecastingData(scheduleEstimations),
  },
];

export const forecastingData = (projectServices = {}) => {
  let toReturn = [];
  Object.entries(projectServices || {})?.forEach(([estimationId, values]) => {
    toReturn.push({
      estimation:
        values?.[0]?.quickbooksNumber || values?.[0]?.estimationNumber || "N/A",
      services: values?.sort((a, b) => a?.serviceId - b?.serviceId),
    });
  });
  return toReturn;
};

export const transformData = (data, key) => {
  const result = {};

  Object.keys(data)?.forEach((id) => {
    const services = data?.[id];
    const groupedServices = {};

    services?.forEach((service) => {
      const { serviceId, serviceOptions, ...rest } = service;

      if (!groupedServices[serviceId]) {
        groupedServices[serviceId] = {
          serviceId,
          [`${key}potentialStartDate`]: service?.[`${key}potentialStartDate`],
          [`${key}plannedNrOfDays`]: service?.[`${key}plannedNrOfDays`],
          [`${key}plannedNrOfHours`]: service?.[`${key}plannedNrOfHours`],
          [`${key}plannedNrOfCrews`]: service?.[`${key}plannedNrOfCrews`],
          [`${key}plannedNrOfTrucks`]: service?.[`${key}plannedNrOfTrucks`],
          serviceOptions: [],
          ...rest,
        };
      } else {
        const existing = groupedServices[serviceId];
        existing[`${key}potentialStartDate`] =
          dayjsNY(service?.[`${key}potentialStartDate`]).valueOf() <
          dayjsNY(existing?.[`${key}potentialStartDate`]).valueOf()
            ? service?.[`${key}potentialStartDate`]
            : existing?.[`${key}potentialStartDate`];
        existing[`${key}plannedNrOfDays`] += service?.[`${key}plannedNrOfDays`];
        existing[`${key}plannedNrOfHours`] +=
          service?.[`${key}plannedNrOfHours`];
        existing[`${key}plannedNrOfCrews`] +=
          service?.[`${key}plannedNrOfCrews`];
        existing[`${key}plannedNrOfTrucks`] +=
          service?.[`${key}plannedNrOfTrucks`];
      }
      const groupedOptions = {};
      serviceOptions?.[0].forEach((option) => {
        const { items = [], elevationId, elevationLabel, ...rest } = option;

        if (!groupedOptions[`${option?.elevationId}`]) {
          groupedOptions[`${elevationLabel}${elevationId}`] = {
            items,
            elevationId,
            elevationLabel,
            [`${key}plannedNrOfDays`]: option?.[`${key}plannedNrOfDays`],
            [`${key}plannedNrOfHours`]: option?.[`${key}plannedNrOfHours`],
            [`${key}plannedNrOfCrews`]: option?.[`${key}plannedNrOfCrews`],
            [`${key}plannedNrOfTrucks`]: option?.[`${key}plannedNrOfTrucks`],
            ...rest,
          };
        } else {
          const existingOption =
            groupedOptions[`${elevationLabel}${elevationId}`];
          existingOption[`${key}plannedNrOfDays`] +=
            option?.[`${key}plannedNrOfDays`];
          existingOption[`${key}plannedNrOfHours`] +=
            option?.[`${key}plannedNrOfHours`];
          existingOption[`${key}plannedNrOfCrews`] +=
            option?.[`${key}plannedNrOfCrews`];
          existingOption[`${key}plannedNrOfCrews`] +=
            option?.[`${key}plannedNrOfCrews`];
        }

        const groupedItems = {};
        items.forEach((item) => {
          if (!groupedItems[item?.id]) {
            const { id, ...itemRest } = item;
            groupedItems[item?.id] = { id, ...itemRest };
          } else {
            const existingItem = groupedItems[id];
            existingItem[`${key}plannedNrOfDays`] +=
              item?.[`${key}plannedNrOfDays`];
            existingItem[`${key}plannedNrOfHours`] +=
              item?.[`${key}plannedNrOfHours`];
            existingItem[`${key}plannedNrOfCrews`] +=
              item?.[`${key}plannedNrOfCrews`];
            existingItem[`${key}plannedNrOfTrucks`] +=
              item?.[`${key}plannedNrOfTrucks`];
          }
          groupedOptions[`${elevationLabel}${elevationId}`].items =
            Object.values(groupedItems);
        });

        groupedOptions[`${elevationLabel}${elevationId}`].items =
          Object.values(groupedItems);
      });

      groupedServices[serviceId].serviceOptions[0] =
        Object.values(groupedOptions);
    });

    result[id] = Object.values(groupedServices);
  });

  return result;
};
