import { categoriesActions } from "../actions/categoriesActions";

export const categoriesReducer = (state, { type, payLoad }) => {
  const {
    SET_CATEGORIES,
    CREATE_REPORT,
    UPDATE_REPORT,
    DELETE_REPORT,
    SET_FAVORITE_REPORT,
    REMOVE_FAVORITE_REPORT,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    SET_FAVORITE_CATEGORY,
    REMOVE_FAVORITE_CATEGORY,
    UPDATE_CATEGORY_DATASETS,
  } = categoriesActions;

  switch (type) {
    case SET_CATEGORIES:
      return payLoad;

    case CREATE_CATEGORY:
      return [
        ...state,
        {
          categoryName: payLoad.categoryName,
          department: payLoad.department,
          reportsAvailable: [],
          datasets: [],
        },
      ];

    case DELETE_CATEGORY:
      const tmp = structuredClone(
        state.filter(({ categoryName }) => categoryName !== payLoad)
      );
      return tmp;

    case UPDATE_CATEGORY:
      const temp = structuredClone(
        state.map((category) => {
          const { categoryName } = category;
          return categoryName === payLoad.categoryName
            ? {
                ...category,
                ...payLoad.updatedValues,
              }
            : category;
        })
      );
      return temp;
    default:
      throw new Error();
  }
};
