export const citations = ({ tables, programFields }) => {
  const citationStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    citationStatus: () => ({
      statusData: tables?.Citations?.map((a) => {
        return {
          label: a?.citationName || "Save Draft",
          value: a?.citationStatus,
          color:
            citationStatus?.find(
              (b) =>
                b?.statusName?.toLowerCase() ===
                a?.citationStatus?.toLowerCase()
            )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.citationId,
        };
      }),
    }),

    citationName: () => ({
      statusData: tables?.Citations?.map((a) => {
        return {
          label: a?.citationName || "Save Draft",
          value: a?.citationStatus,
          color:
            citationStatus?.find(
              (b) =>
                b?.statusName?.toLowerCase() ===
                a?.citationStatus?.toLowerCase()
            )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.citationId,
        };
      }),
    }),

    employeeName: () => ({
      statusData: tables?.Citations?.map((a) => {
        return {
          label: a?.citationObject?.employeeName,
          value: a?.citationStatus,
          color:
            citationStatus?.find(
              (b) =>
                b?.statusName?.toLowerCase() ===
                a?.citationStatus?.toLowerCase()
            )?.statusColor || "#6D8299",
          date: a?.createdAt,
          id: a?.employeeId,
        };
      }),
    }),
  };
};
