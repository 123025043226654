import { message, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import ChooseDriveFolderView from "../choose-drive-folder/ChooseDriveFolderView";

import "./upload-pdf-to-drive-modal.scss";
import { InputComponent } from "../../../../Fleet/components";
import { getFileType } from "../../../utils/helpers";
import { XIcon } from "../../../../Communication/assets";

function UploadPdfToDriveModal({
  isOpen,
  setIsOpen,
  reportAsFile,
  // driveFolders,
  // driveFolderParentName,
  reportsFolderId,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const [folderId, setFolderId] = useState("");
  const [selectedFormat, setSelectedFormat] = useState(null);

  const handleCloseFolderModal = () => {
    setIsOpen(false);
    setFolderId("");
  };

  const handleFolderClick = (folderId) => {
    setFolderId((prevFolderId) => (prevFolderId === folderId ? "" : folderId));
  };

  const driveRequest = driveApi({ accessToken });

  const handleOk = async () => {
    if (!selectedFormat) {
      message.warning({
        content: "Please select a format file before proceeding",
      });
      return;
    } else if (!reportsFolderId) {
      message.warning({
        content:
          "Cannot upload report at this moment because the folder is missing. Please try again later.",
      });
      return;
    } else {
      message.loading({
        content: "Report is being uploaded to the Drive...",
      });

      if (reportAsFile?.data[selectedFormat]) {
        try {
          const blobFile = new Blob([reportAsFile?.data[selectedFormat]]);
          const uploadResponse = await driveRequest.uploadExcelFile(
            blobFile,
            getFileType(selectedFormat),
            {
              name: reportAsFile?.fileName,
              parents: [reportsFolderId],
            }
          );

          if (uploadResponse.status === 200) {
            message.success({ content: "Report uploaded successfully." });
            setIsOpen(false);
            setFolderId("");
          } else {
            throw new Error(uploadResponse);
          }
        } catch (error) {
          message.error({
            content: `Failed to upload pdf to drive error: ${error}`,
          });
        }
      } else {
        console.error("Unexpected export result format");
      }
    }
  };

  const handleOnSelect = (value) => {
    setSelectedFormat(value);
  };

  return (
    <Modal
      style={{ zIndex: 9999 }}
      open={isOpen}
      onCancel={handleCloseFolderModal}
      onClose={handleCloseFolderModal}
      onOk={handleOk}
      title={`Upload Report to Drive - ${reportAsFile?.fileName}`}
      // width="fit-content"
      centered
      className={`upload-report-modal ${
        isDarkMode && "upload-report-modal-dark"
      }`}
      closeIcon={<XIcon />}
    >
      <div className="upload-pdf-to-drive-modal-container">
        <InputComponent
          type="select"
          customOptions={[
            {
              id: "pdf",
              label: "PDF",
              value: "pdf",
            },
            {
              id: "excel",
              label: "Excel",
              value: "excel",
            },
          ]}
          placeholder="Choose Format"
          onChange={handleOnSelect}
          initialValue={selectedFormat}
          style={{ width: "300px" }}
          onClear={() => setSelectedFormat("")}
        />

        {/* <div className="choose-drive-folder-view-wrapper">
          {driveFolders.map(({ name, id }) => (
            <ChooseDriveFolderView
              key={id}
              folderName={name}
              isSelected={folderId === id ? true : false}
              onClick={() => handleFolderClick(id)}
            />
          ))}
        </div> */}
      </div>
    </Modal>
  );
}

export default UploadPdfToDriveModal;
