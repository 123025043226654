export const generateRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Convert the numbers to hexadecimal and ensure they are two digits
  const color = `#${red.toString(16).padStart(2, "0")}${green
    .toString(16)
    .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;

  return color;
};

// Convert Hex to HSL
const hexToHSL = (hex) => {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
};

// Convert HSL to Hex
const HSLToHex = (h, s, l) => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  // Convert RGB to Hex
  return (
    "#" +
    (
      (1 << 24) |
      (Math.round(r * 255) << 16) |
      (Math.round(g * 255) << 8) |
      Math.round(b * 255)
    )
      .toString(16)
      .slice(1)
      .toUpperCase()
  );
};

export const adjustColorTone = (hex, nuance) => {
  // Convert hex color to HSL
  const hsl = hexToHSL(hex);

  // Adjust the HSL values based on the nuance
  hsl[1] += (nuance - 3) * 0.1; // Slightly adjust saturation based on nuance (0.1 step)
  hsl[2] += (nuance - 3) * 0.05; // Slightly adjust lightness based on nuance (0.05 step)

  // Clamp the saturation and lightness to be between 0 and 1
  hsl[1] = Math.max(0, Math.min(1, hsl[1]));
  hsl[2] = Math.max(0, Math.min(1, hsl[2]));

  // Convert the adjusted HSL back to hex
  return HSLToHex(hsl[0], hsl[1], hsl[2]);
};
