import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as ApprovalIcon } from "../../../../icons/approvalsIcon.svg";
import { useSelector } from "react-redux";
import { Badge } from "antd";
import ApprovalsDrawer from "./components/approvals-drawer/ApprovalsDrawer";
import { fetchAllData, filterTables, getAccessRights } from "../../../../utils";
import { apiRoutes } from "../../../SidebarPages/Fleet/utils";
import { wsFormRequests } from "../../../../AppData";
import { useDispatch } from "react-redux";
import { WithTooltip } from "../../../commonComponents";
import { useSocket } from "src/hooks";
import { useCustomerWebsocketContext } from "../../../../contexts/WebsocketProvider/useCustomerWebsocket";
import { approvalsMsg } from "./utils/customerApprovals";

// const wsFRequests = new WebSocket(wsFormRequests.online);
function Approvals() {
  const [open, setOpen] = useState(false);
  const { approvals = [] } = useSelector((state) => state.approvals);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const dispatch = useDispatch();
  const { socket: wsFRequests } = useSocket({ path: wsFormRequests.online });

  const { websocketFunctions = {} } = useCustomerWebsocketContext();

  websocketFunctions.requests = (msg) => {
    approvalsMsg(msg, dispatch);
  };

  /**
   * Adds socket listeners for handling different types of messages.
   */
  function addSocketListeners() {
    wsFRequests.addEventListener("message", ({ data: msg }) => {
      const { request, body = {} } = JSON.parse(msg || {});
      const { requestId } = body;

      switch (request) {
        case "new": {
          dispatch({ type: "ADD_APPROVAL", payload: body });
          break;
        }
        case "request-response": {
          dispatch({ type: "UPDATE_APPROVAL", payload: body });
          break;
        }
        case "delete": {
          dispatch({
            type: "DELETE_APPROVAL_BY_ID",
            payload: { id: requestId },
          });
          break;
        }
        default:
          break;
      }
    });
  }

  // web-socket
  useEffect(() => {
    if (wsFRequests) {
      addSocketListeners();
    }
  }, [wsFRequests]);

  useEffect(() => {
    const { departmentName } = userConfiguration;

    (["Admin", "Executive"].includes(departmentName)
      ? fetchAllData({
          endpoint: apiRoutes.formRequests,
          resultId: "requestId",
        })
      : filterTables(
          apiRoutes.formRequests,
          "assignedTo",
          userConfiguration?.cognitoUserId
        )
    )
      .then((response) => {
        dispatch({ type: "ADD_APPROVALS", payload: response });
      })
      .catch((err) => {
        console.error("Error getting approvals: ", err);
      });
  }, [userConfiguration.departmentName]);

  const myRequests = useMemo(() => {
    return approvals.filter(
      ({
        assignedTo = "",
        createdBy,
        requestObject: { status },
        responseFrom,
      }) => {
        if (
          status === "Needs Review" &&
          createdBy === userConfiguration?.cognitoUserId
        ) {
          return true;
        }

        if (
          userConfiguration?.cognitoUserId !== assignedTo ||
          responseFrom === "approved"
        ) {
          return false;
        }

        if (["Draft", "Reviewed"].includes(status)) {
          return true;
        }
      }
    );
  }, [userConfiguration?.cognitoUserId, JSON.stringify(approvals)]);

  const accessRights = getAccessRights({
    userConfiguration,
    title: "approvals",
  })?.routeConfig.read;

  if (!accessRights) return null;

  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="approvals">
        <Badge className="ignore-onclickoutside" count={myRequests?.length}>
          <ApprovalIcon onClick={() => setOpen(true)} className="header-icon" />
        </Badge>
      </WithTooltip>

      {open && (
        <ApprovalsDrawer
          visible={open}
          setVisible={setOpen}
          notRespondedFormRequests={myRequests}
          from="header"
          wsFRequests={wsFRequests}
        />
      )}
    </div>
  );
}

export default Approvals;
