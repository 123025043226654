import { useDispatch, useSelector } from "react-redux";
import { Popover, message } from "antd";
import { useChartCardsContext } from "../../../../ChartCardsContext";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import {
  CardWidths,
  updateChartPreferences,
  updateProgramFields,
} from "../../../../utils";

const ChangeChartWidth = ({ cardConfigs }) => {
  const { id, width, isPrivate = false } = cardConfigs;

  const dispatch = useDispatch();

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const { chartCategory } = useChartCardsContext();

  const overviewsConfig = programFields?.find(
    ({ fieldName }) => fieldName === "Overviews Configuration"
  );

  const overviewCards =
    overviewsConfig.fieldOptions.overviewCards[chartCategory];
  const { preferences } = allPreferences;
  const myCardPrefs = preferences?.OverviewCards?.onlyMyCards?.[chartCategory];

  const onChangeWidth = async (width) => {
    message.loading("Changing width...");

    if (!!isPrivate) {
      const updatedPreferences = {
        ...preferences,
        OverviewCards: {
          ...(preferences?.OverviewCards || {}),
          onlyMyCards: {
            ...(preferences?.OverviewCards?.onlyMyCards || {}),
            [chartCategory]: myCardPrefs.map(({ isPrivate, ...card }) =>
              card.id === id ? { ...card, width } : card
            ),
          },
        },
      };

      await updateChartPreferences(
        updatedPreferences,
        allPreferences,
        dispatch
      );
    } else {
      const updatedFieldOptions = {
        ...overviewsConfig?.fieldOptions,
        overviewCards: {
          ...overviewsConfig?.fieldOptions?.overviewCards,
          [chartCategory]: overviewCards.map((card) =>
            card.id === id ? { ...card, width } : card
          ),
        },
      };

      await updateProgramFields(
        overviewsConfig?.fieldId,
        updatedFieldOptions,
        dispatch
      );
    }

    message.success("Chart Width Changed Successfully!");
  };

  return (
    <Popover
      overlayClassName={`chart-cards-popover ${
        isDarkMode ? "chart-cards-popover-content-dark" : ""
      }`}
      placement="right"
      content={
        <InputComponent
          type="select"
          label="Select new width!"
          placeholder="Select new width"
          defaultValue={width}
          onChange={onChangeWidth}
          customOptions={CardWidths}
        />
      }
    >
      <span className="ActionElementThreeDots">Change Width</span>
    </Popover>
  );
};

export default ChangeChartWidth;
