import { Modal, message } from "antd";
import React, { useCallback, useRef, useState } from "react";
import "./ShortcutForPliDays.scss";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import {
  columnDefsForTransferDays,
  swalToast,
} from "../SchedulingFirstPage/helperData";
import { AgGridReact } from "ag-grid-react";
import { MondayButton } from "../../../../commonComponents";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
const TransferDays = (props) => {
  const {
    transferDays = {},
    scheduleDays = [],
    closeTransferDays = () => {},
    handleDays = () => {},
    comesFromElevation = false,
  } = props;
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState();

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const saveData = () => {
    const selectedNodes = gridApi?.getSelectedNodes() || [];
    const selectedData =
      selectedNodes?.length > 0
        ? selectedNodes?.map?.((node) => node?.data?.id)
        : [];
    if (!!comesFromElevation) {
      handleDays(
        transferDays?.serviceId,
        transferDays?.elevationIndex,
        selectedData
      );
    } else {
      handleDays(selectedData);
    }
    closeTransferDays(false);
    swalToast({
      icon: "success",
      title: "Days updated successfully!",
      showConfirmButton: false,
      position: "bottom-end",
      timer: 4500,
    });
  };

  const onFirstDataRendered = (params) => {
    params?.api?.forEachNode((node) => {
      if (transferDays?.days?.includes(node?.data?.id)) {
        node?.setSelected(true);
      }
    });
  };

  return (
    <div>
      <Modal
        className="transfer-days-modal"
        title="Select Days"
        centered
        open={transferDays?.visible}
        onCancel={() => closeTransferDays(false)}
        width={1000}
        height="fit-content"
        closeIcon={<XIcon />}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <MondayButton
              onClick={() => closeTransferDays(false)}
              {...{
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              Close
            </MondayButton>
            <MondayButton
              onClick={saveData}
              {...{
                Icon: <TickIcon />,
              }}
            >
              Save Changes
            </MondayButton>
          </div>,
        ]}
      >
        <div className="daysInPLiGrid">
          <div
            className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
            style={{ width: "100%", height: 400 }}
          >
            <AgGridReact
              {...{
                ref: gridRef,
                columnDefs: columnDefsForTransferDays,
                rowData: scheduleDays,
                animateRows: true,
                onGridReady,
                suppressDragLeaveHidesColumns: true,
                suppressRowClickSelection: true,
                onFirstDataRendered,
                onComponentStateChanged: onFirstDataRendered,
                headerHeight: 32,
                rowHeight: 32,
                rowSelection: "multiple",
                defaultColDef: {
                  resizable: true,
                  enablePivot: true,
                  enableColResize: true,
                  enableRowGroup: true,
                  editable: false,
                  sortable: true,
                  flex: 1,
                  minWidth: 100,
                  filter: true,
                },
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransferDays;
