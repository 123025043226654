import { useContext } from "react";
import { CircleF, OverlayViewF, PolygonF } from "@react-google-maps/api";

import { Marker } from "src/components/commonComponents";
import PayrollLiveContext from "../../PayrollLiveContext";
import { ScheduleType, StatusColor } from "../../payrollLiveTypes";
import ScheduleInfoWindow from "../ScheduleInfoWindow/ScheduleInfoWindow";

interface Props {
  data: ScheduleType;
  onClick?: () => void;
  key?: string | number;
  statusColors: Array<StatusColor>;
}

const circleOptions = {
  strokeWeight: 2,
  fillOpacity: 0.2,
  fillColor: "#4e8afa",
  strokeColor: "#0059ff",
};

const polygonOptions = {
  strokeWeight: 2,
  fillOpacity: 0.4,
  fillColor: "#d99f00",
  strokeColor: "#d99f00",
};

function ScheduleMarker(props: Props) {
  const { key, data, onClick, statusColors } = props;
  const { schedulesInfo, setSchedulesInfo } = useContext(PayrollLiveContext);

  function onToggleInfo() {
    setSchedulesInfo((prev) => {
      if (prev === data?.scheduleId) {
        return undefined;
      } else {
        return data?.scheduleId;
      }
    });
    if (!!onClick) {
      onClick();
    }
  }

  const statusColor =
    statusColors.find((status) => status.statusName === data.scheduleStatus)
      ?.statusColor || "#ccc";

      return (
    <OverlayViewF
      zIndex={2}
      position={data.addressPosition}
      mapPaneName={"overlayMouseTarget"}
    >
      <Marker
        showIcon
        key={key}
        color={"#1264a3"}
        onClick={onToggleInfo}
        address={data.scheduleAddress}
        coordinates={data.addressPosition}
      />
      {!!data.radius && (
        <CircleF
          options={circleOptions}
          center={data.addressPosition}
          radius={
            typeof data?.radius === "string"
              ? parseFloat(data?.radius) * 0.3048
              : data?.radius * 0.3048
          }
        />
      )}
      {!!data?.geoFenceInfo?.length &&
        data?.geoFenceInfo?.map((geofence) => {
          return (
            <PolygonF
              options={{
                ...polygonOptions,
                editable: false,
                draggable: false,
              }}
              path={geofence?.geoFenceInfo}
            />
          );
        })}
      {schedulesInfo && schedulesInfo === data?.scheduleId ? (
        <ScheduleInfoWindow
          data={data}
          onClick={onToggleInfo}
          statusColor={statusColor}
        />
      ) : null}
    </OverlayViewF>
  );
}

export default ScheduleMarker;
