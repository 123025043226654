import { message, Tooltip } from "antd";
import { useRef } from "react";
import { DownloadIcon } from "src/components/SidebarPages/BasePage/src";
import { TrashIcon } from "src/components/SidebarPages/Communication/assets";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import dayjs from "dayjs";
import "./SingleFile.scss";

function SingleFile({
  driveRequest,
  index,
  isDarkMode,
  setCurrentFile,
  setShowModal,
  handleDelete,
  fileType,
  file,
  editCard = false,
}) {
  const {
    name = "",
    iconUrl,
    id = "",
    mimeType = "",
    url = "",
    downloadUrl = "",
    uploadedBy = "",
    lastEditedUtc = "",
  } = file;
  const divRef = useRef(null);

  function handleDownload(blob) {
    const aElement = document.createElement("a");
    aElement.setAttribute("href", blob);
    aElement.setAttribute("download", name);
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
  }

  async function onDownload(e) {
    e.stopPropagation();
    e.preventDefault();
    await driveRequest
      .getImageSrc(id)
      .then(({ blob }) => {
        handleDownload(blob);
      })
      .catch((err) => {
        console.log("Failed to download file", err);
        message.error("Failed to download file, please try again later");
      });
  }

  return (
    <div
      key={id}
      style={{
        borderRadius: 5,
        padding: 10,
      }}
      className={`documentContainer ${
        isDarkMode ? "documentContainerDark" : ""
      }`}
      onClick={() => {
        driveRequest.getImageSrc(id).then(({ src, blob }) => {
          setCurrentFile({ ...file, src, blob });
          setShowModal(true);
        });
      }}
      ref={divRef}
    >
      <div className="documentTitle">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            width: "100%",
          }}
        >
          {/* <img className="documentIcon" src={iconUrl} /> */}
          <span className="documentIcon">{fileIcons[fileType]}</span>
          <Tooltip title={name}>
            <span
              className="documentName"
              style={{
                color: isDarkMode ? "white" : "black",
              }}
            >
              {name}
            </span>
          </Tooltip>
        </div>
      </div>
      {!editCard && (
        <span
          style={{
            fontSize: 12,
            color: "#acacac",
            display: "flex",
            alignItems: "center",
            height: 30,
          }}
        >
          {uploadedBy && <span>Uploader: {uploadedBy}</span>}{" "}
          {uploadedBy && lastEditedUtc && <span> | </span>}
          {lastEditedUtc && (
            <span>
              Uploaded on: {dayjs(lastEditedUtc).format("DD MMM YYYY")}
            </span>
          )}
        </span>
      )}
      <div
        style={{
          display: "flex",
          gap: "20px",
          textAlign: "right",
          height: 18,
          alignSelf: "center",
        }}
      >
        <span
          onMouseOver={() => {
            divRef.current.classList.remove("documentContainerRed");
            divRef.current.classList.add("documentContainerBlue");
          }}
          onMouseLeave={() => {
            divRef.current.classList.remove("documentContainerBlue");
          }}
          onClick={onDownload}
        >
          <DownloadIcon
            fill="#1264A3"
            style={{
              cursor: "pointer",
            }}
          />
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleDelete(id);
          }}
          onMouseOver={() => {
            divRef.current.classList.remove("documentContainerBlue");
            divRef.current.classList.add("documentContainerRed");
          }}
          onMouseLeave={() => {
            divRef.current.classList.remove("documentContainerRed");
          }}
        >
          <TrashIcon
            fill="#FE4C4A"
            style={{
              cursor: "pointer",
            }}
          />
        </span>
      </div>
    </div>
  );
}

export default SingleFile;
