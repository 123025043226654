export const initialShortCut = {
  sortedSubCategories: {
    Fleets: [
      "Fleet",
      "Fleet Dispatch",
      "Fleet Violation",
      "Fleet Driver",
      "Fleet Activity",
      "Fleet Maintenance",
      "Fleet Inventory",
      "Work Orders",
      "Schedule Maintenance",
    ],
    Others: [
      "Task",
      "Notes",
      "Claims",
      "Hearings",
      // "Crew",
      "Geofence",
      "Communication",
      "Todo",
    ],
    Sales: [
      "Estimation",
      "Contact",
      "Client",
      "Subcontractor",
      "Lead",
      "Opportunity",
    ],
    Projects: [
      "Permit Drawing",
      "Project",
      "Documentation",
      "Scheduling",
      "Inspection",
    ],
    Approvals: [
      "Estimation Request",
      "Permit Drawing Request",
      "Scheduling Request",
      "Schedule Change Request",
      "Dispatch Request",
      "Safety Inspection Request",
      "Project Request",
      "Claim Request",
      "Incident Request",
      "Service Inspection Request",
      "Documentation Request",
      "Change Order Request",
      "Opportunity Request",
      "Work Order Request",
      "Fleet Inventory Request",
    ],
    Safety: [
      "Safety Reports",
      "Safety Inspection",
      "Incidents",
      "Safety Citations",
    ],
    Accountings: [
      "Payment",
      "Schedule Of Value",
      "Application",
      "Rental",
      "Charge",
      "Invoice",
    ],
    "Project Cost": ["DEG", "Jobsite"],
    Vendors: ["Vendor", "Bill"],
    HR: ["New Employee"],
  },
  sortedCategories: [
    "Safety",
    "Accountings",
    "Projects",
    "Approvals",
    "Sales",
    "Fleets",
    "Project Cost",
    "Vendors",
    "HR",
    "Others",
  ],
};
