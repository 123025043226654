import { Popover } from "antd";
import { useSelector } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useChartCardsContext } from "../../../ChartCardsContext";
import { SearchIcon } from "../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import MondayButton from "../../../../MondayButton/MondayButton";

const SearchCards = () => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { searchedCard, setSearchedCard } = useChartCardsContext();

  return (
    <div className={`chart-cards-lock${isDarkMode ? " dark-mode" : ""} `}>
      <Popover
        placement="left"
        overlayClassName={`chart-cards-popover ${
          isDarkMode ? "chart-cards-popover-content-dark" : ""
        }`}
        content={
          <InputComponent
            inputAllowClear={true}
            placeholder="Search any card"
            value={searchedCard}
            onChange={(e) => {
              setSearchedCard(e.target.value);
            }}
          />
        }
      >
        <MondayButton
          className={searchedCard ? "mondayButtonRed" : "mondayButtonBlue"}
          Icon={!!searchedCard ? <CloseCircleOutlined /> : <SearchIcon />}
          onClick={() => searchedCard && setSearchedCard("")}
        />
      </Popover>
    </div>
  );
};

export default SearchCards;
