import { Alert, Card, message, Modal, Spin } from "antd";
import React, { useState } from "react";
import Forecasting from "../../Forecasting";
import "./ForecastingRaport.scss";
import { XIcon } from "../../../../../Communication/assets";
import { useEffect } from "react";
import { fetchAllData } from "../../../../../../../utils";
import { LoadingOutlined } from "@ant-design/icons";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { API } from "aws-amplify";
import {
  getDaysForSpecificService,
  getDaysWithProgress,
  getElevationDays,
  getProgressDaysForService,
} from "../../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { MondayButton } from "../../../../../../commonComponents";
import {
  forecastingData,
  forecastingRaport,
  transformData,
} from "./forecastingData";
import { typeOfWorkColors } from "../../../../../../Header/forms/Scheduling/DataAgGrid/columnDefs";

const ForecastingRaport = (props) => {
  console.log("props", { props });

  const projectId = props?.rowData?.projectId;
  const isModalVisible = props?.forecastingRaport || false;
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [noSchedules, setNoSchedules] = useState(false);
  const [count, setCount] = useState(8);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const fetchSchedulesData = async () => {
      message.loading({
        content: "Loading schedules and sow items...",
        loading: 0,
        key: "loading-schedules",
      });
      try {
        setLoading(true);

        const schedules = await fetchAllData({
          endpoint: "scheduling",
          resultPosition: "scheduling",
          resultId: "scheduleId",
          otherStringParams: {
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "projectId",
                    value: projectId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        });

        if (schedules?.length === 0) {
          message.warning("No schedules found for this project", 5);
          setNoSchedules(true);
          return;
        }

        // const filterOnlyInstAndRemoval =
        //   schedules?.filter(
        //     (schedule) =>
        //       schedule?.typeOfWork === "Installation" ||
        //       schedule?.typeOfWork === "Removal"
        //   ) || [];

        if (Array.isArray(schedules) && schedules?.length > 0) {
          const schedulesWithToBeScheduled = [];
          for (const schedule of schedules) {
            const sowItems = await API.get("queryTable", "/queryTable", {
              queryStringParameters: {
                tableName: "toBeScheduled",
                indexName: "scheduleId-index",
                filterKey: "scheduleId",
                filterValue: schedule.scheduleId,
              },
            });

            schedule.toBeScheduled = _.groupBy(sowItems, "estimationId");
            schedulesWithToBeScheduled.push(schedule);
          }

          const groupSchedules =
            _.groupBy(schedulesWithToBeScheduled, "typeOfWork") || {};
          console.log("groupSchedules", { groupSchedules });

          let temp = {};

          Object.entries(groupSchedules)?.forEach(([key, value]) => {
            const sortedSchedules = value?.sort(
              (a, b) => dayjsNY(a?.createdAt) - dayjsNY(b?.createdAt)
            );
            const scheduleDays = value?.flatMap((sch) => sch?.scheduleDays);

            const allCrews = value?.flatMap((crews) => crews?.scheduleCrews);

            temp[key] = {};
            sortedSchedules?.forEach((schedule) => {
              const progressDays = getDaysWithProgress(
                schedule?.scheduleId,
                schedule?.scheduleDays,
                schedule?.toBeScheduled,
                schedule?.typeOfWork
              )?.sort((a, b) => a?.startDate - b?.startDate);

              Object.entries(schedule?.toBeScheduled)?.forEach(
                ([estimationId, services]) => {
                  if (!temp[key][estimationId]) {
                    temp[key][estimationId] = [];
                  }
                  temp[key][estimationId] = [
                    ...(temp[key][estimationId] || []),
                    ...(services || [])?.map((ser) => {
                      const serviceDays = getDaysForSpecificService(
                        ser?.serviceOptions
                      );
                      const getDates = scheduleDays
                        ?.filter((day) => serviceDays?.includes(day?.id))
                        ?.sort(
                          (a, b) =>
                            dayjsNY(a?.startDate) - dayjsNY(b?.startDate)
                        );

                      const getCrews = allCrews?.filter((crew) =>
                        crew?.days?.some((mem) =>
                          serviceDays?.includes(mem?.dayId)
                        )
                      );

                      let plannedNrOfHours = 0;
                      getDates?.forEach((date) => {
                        const hoursPerDay = dayjsNY(date?.endDate).diff(
                          dayjsNY(date?.startDate),
                          "hours",
                          true
                        );
                        plannedNrOfHours +=
                          hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                      });

                      const serviceDaysProgress =
                        progressDays?.filter(
                          (el) =>
                            el?.estimationId === estimationId &&
                            el?.serviceId === ser?.serviceId
                        ) || [];

                      let progressHours = 0;

                      if (serviceDaysProgress?.length > 0) {
                        serviceDaysProgress?.forEach((date) => {
                          const hoursPerDay = dayjsNY(date?.endDate).diff(
                            dayjsNY(date?.startDate),
                            "hours",
                            true
                          );
                          progressHours +=
                            hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                        });
                      }
                      ser["firstProgressDay"] =
                        serviceDaysProgress?.length > 0
                          ? dayjsNY(serviceDaysProgress?.[0]?.startDate).format(
                              "MM/DD/YYYY"
                            )
                          : "N/A";
                      ser["daysWithProgress"] = serviceDaysProgress?.length;
                      ser["progressHours"] = progressHours;
                      ser[`${key}potentialStartDate`] = dayjsNY(
                        getDates?.[0]?.startDate
                      ).format("MM/DD/YYYY");
                      ser[`${key}plannedNrOfDays`] = serviceDays?.length;
                      ser[`${key}plannedNrOfHours`] = plannedNrOfHours;
                      ser[`${key}plannedNrOfCrews`] = getCrews?.length;
                      ser[`${key}plannedNrOfTrucks`] = 0;
                      ser["scheduleCreated"] = schedule?.createdAt;
                      ser["serviceOptions"] = [
                        ser?.serviceOptions?.[0]?.map((elevation) => {
                          const eleDays = getElevationDays(elevation);

                          const getEleDates = getDates?.filter((date) =>
                            eleDays?.includes(date?.id)
                          );

                          const eleCrews = getCrews?.filter((crew) =>
                            crew?.days?.some((day) =>
                              eleDays?.includes(day?.dayId)
                            )
                          );

                          const elevationProgressDays =
                            serviceDaysProgress?.filter(
                              (b) =>
                                b?.elevationName ===
                                  elevation?.elevationLabel &&
                                b?.elevationId === elevation?.elevationId
                            ) || [];

                          let eleHours = 0;

                          getEleDates?.forEach((date) => {
                            const hoursPerDay = dayjsNY(date?.endDate).diff(
                              dayjsNY(date?.startDate),
                              "hours",
                              true
                            );
                            eleHours +=
                              hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                          });

                          // elevation["firstProgressDay"] =
                          //   elevationProgressDays?.length > 0
                          //     ? dayjsNY(
                          //         elevationProgressDays?.[0]?.startDate
                          //       ).format("MM/DD/YYYY")
                          //     : "N/A";
                          elevation["daysWithProgress"] =
                            elevationProgressDays?.length;
                          elevation[`${key}plannedNrOfDays`] =
                            getEleDates?.length;
                          elevation[`${key}plannedNrOfHours`] = eleHours;
                          elevation[`${key}plannedNrOfCrews`] =
                            eleCrews?.length;
                          elevation[`${key}plannedNrOfTrucks`] = 0;

                          elevation.items = elevation?.items?.map((pli) => {
                            let pliHours = 0;

                            const getPliDates = getEleDates?.filter((date) =>
                              pli?.days?.includes(date?.id)
                            );

                            const pliProgressDays =
                              elevationProgressDays?.filter(
                                (el) => el?.pli === pli?.id
                              ) || [];

                            const eleCrews = getCrews?.filter((crew) =>
                              crew?.days?.some((day) =>
                                pli?.days?.includes(day?.dayId)
                              )
                            );
                            getPliDates?.forEach((date) => {
                              const hoursPerDay = dayjsNY(date?.endDate).diff(
                                dayjsNY(date?.startDate),
                                "hours",
                                true
                              );
                              pliHours +=
                                hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                            });
                            // pli["firstProgressDay"] =
                            //   pliProgressDays?.length > 0
                            //     ? dayjsNY(
                            //         pliProgressDays?.[0]?.startDate
                            //       ).format("MM/DD/YYYY")
                            //     : "N/A";
                            pli["daysWithProgress"] = pliProgressDays?.length;
                            pli[`${key}plannedNrOfDays`] = pli?.days?.length;
                            pli[`${key}plannedNrOfHours`] = pliHours;
                            pli[`${key}plannedNrOfCrews`] = eleCrews?.length;
                            pli[`${key}plannedNrOfTrucks`] = 0;
                            return pli;
                          });

                          return elevation;
                        }),
                      ];

                      return ser;
                    }),
                  ];
                  temp[key] = transformData(temp[key], key);
                }
              );
            });
          });

          console.log("TEMPPPPP", { temp });
          props?.setSchedules(temp);

          setTypes(Object.keys(groupSchedules));

          console.log("formatData", { groupSchedules });

          // props.setSchedules(formatData);
        } else {
          props.setSchedules({});
        }
      } catch (error) {
        console.error("Error fetching scheduling data:", error);
      } finally {
        setLoading(false);
        message.destroy("loading-schedules");
      }
    };

    if (projectId && isModalVisible) {
      setTimeout(fetchSchedulesData, 0);
    }
  }, [isModalVisible, projectId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowMessage((prev) => !prev);
    }, 5500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!!noSchedules) {
      const timer = setInterval(() => {
        setCount((prev) => prev - 1);
      }, 1000);

      const timeout = setTimeout(() => {
        props.onClose();
      }, 8000);

      return () => {
        clearInterval(timer);
        clearTimeout(timeout);
      };
    }
  }, [props.onClose, noSchedules]);

  console.log({ props });

  return (
    <div>
      <Modal
        {...{
          title: null,
          open: props?.forecastingRaport,
          centered: true,
          className: "forecasting-raport",
          onCancel: () => {
            props.onClose();
          },
          closeIcon: <XIcon />,
          footer: null,
        }}
      >
        {types?.map((type, index) => (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                paddingTop: index === 1 ? 10 : "",
              }}
            >
              <h2>{props?.rowData?.projectAddress}</h2>
              <h1
                style={{
                  color: typeOfWorkColors[type],
                }}
              >
                ({type})
              </h1>
              {index === 0 && (
                <div
                  className={`message ${showMessage ? "fade-in" : "fade-out"}`}
                >
                  <Alert
                    message="Please note that this view is currently under development and
                  may not be fully complete yet. Thank you for your patience!"
                    showIcon
                    type="warning"
                  />
                </div>
              )}
            </div>
            <div className="forecastingRaportContent">
              {forecastingRaport({
                forecastEstimations: props?.projectServices || {},
                scheduleEstimations: props?.schedules?.[type],
              })?.map((data) => (
                <div className="content">
                  <div className="header">{data?.header}</div>
                  <div className="data">
                    <div className="estimation-container">
                      {data?.display?.map((estimation, index) => (
                        <div
                          className={`estimation-card`}
                          key={estimation.estimation}
                        >
                          <h2
                            className="estimation-title"
                            style={{
                              borderBottom: `3px solid ${data?.borderBottomColor}`,
                            }}
                          >
                            Estimation: {estimation.estimation}
                          </h2>
                          {estimation.services.map((service, serviceIndex) => (
                            <div className="service" key={serviceIndex}>
                              <h3 className="service-label">{service.label}</h3>
                              <div className="service-details">
                                <p>
                                  <strong>Start Date:</strong>{" "}
                                  {data?.progress
                                    ? service?.firstProgressDay
                                    : !!service?.[`${type}potentialStartDate`]
                                    ? dayjsNY(
                                        service?.[`${type}potentialStartDate`]
                                      ).format("MM/DD/YYYY")
                                    : "N/A"}
                                </p>
                                <p>
                                  <strong>Days:</strong>{" "}
                                  {data?.progress
                                    ? service?.daysWithProgress
                                    : service?.[`${type}plannedNrOfDays`]}
                                </p>
                                <p>
                                  <strong>Hours:</strong>{" "}
                                  {data?.progress
                                    ? service?.progressHours
                                    : service?.[`${type}plannedNrOfHours`]}
                                </p>
                                <p>
                                  <strong>Crews:</strong>{" "}
                                  {service[`${type}plannedNrOfCrews`]}
                                </p>
                                <p>
                                  <strong>Trucks:</strong>{" "}
                                  {service[`${type}plannedNrOfTrucks`]}
                                </p>
                              </div>
                              {service?.serviceOptions?.[0]?.map(
                                (elevation, elevationIndex) => (
                                  <div
                                    className="elevation"
                                    key={elevationIndex}
                                  >
                                    <h4 className="elevation-name">
                                      {elevation.elevationLabel +
                                        " " +
                                        elevation?.elevationId}
                                    </h4>
                                    <div className="elevation-details">
                                      <p>
                                        <strong>Days:</strong>{" "}
                                        {elevation?.[
                                          `${type}plannedNrOfDays`
                                        ] || "N/A"}
                                      </p>
                                      <p>
                                        <strong>Hours:</strong>{" "}
                                        {elevation?.[
                                          `${type}plannedNrOfHours`
                                        ] || "N/A"}
                                      </p>
                                      <p>
                                        <strong>Crews:</strong>{" "}
                                        {elevation?.[
                                          `${type}plannedNrOfCrews`
                                        ] || "N/A"}
                                      </p>
                                      <p>
                                        <strong>Trucks:</strong>{" "}
                                        {elevation?.[
                                          `${type}plannedNrOfTrucks`
                                        ] || "N/A"}
                                      </p>
                                    </div>
                                    <table className="items-table">
                                      <thead>
                                        <tr>
                                          <th>ID</th>
                                          <th>Length</th>
                                          <th>Width</th>
                                          <th>Height</th>
                                          <th>Days</th>
                                          <th>Hours</th>
                                          <th>Crews</th>
                                          <th>Trucks</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {elevation?.items?.map((item) => (
                                          <tr key={item?.id}>
                                            <td>{item?.id}</td>
                                            <td>{item?.["length"] || "-"}</td>
                                            <td>{item?.width || "-"}</td>
                                            <td>{item?.height || "-"}</td>
                                            <td>
                                              {item?.[
                                                `${type}plannedNrOfDays`
                                              ] || "N/A"}
                                            </td>
                                            <td>
                                              {item?.[
                                                `${type}plannedNrOfHours`
                                              ] || "N/A"}
                                            </td>
                                            <td>
                                              {item?.[
                                                `${type}plannedNrOfCrews`
                                              ] || "N/A"}
                                            </td>
                                            <td>
                                              {item?.[
                                                `${type}plannedNrOfTrucks`
                                              ] || "N/A"}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                )
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert
            key={currentText?.text}
            className="animateAlert"
            type={currentText?.key === 1 ? "info" : "warning"}
            message={currentText?.text}
            showIcon
          />
          <Card>
            <Forecasting
              {...{
                rowData: props?.rowData,
                comesFromRaport: true,
                header: "FORECASTING",
                additionalStyle: {
                  color: "#1A73E8",
                  borderLeft: "4px solid #1A73E8",
                  backgroundColor: "#F0F8FF",
                },
              }}
            />
          </Card>

          {!!noSchedules ? (
            <>
              <h1>NO SCHEDULES FOUND FOR {props?.rowData?.projectAddress}</h1>
              <span>
                This view will automatically close in {count} second
                {count !== 1 && "s"}
              </span>
            </>
          ) : (
            <>
              <Card>
                <Forecasting
                  {...{
                    rowData: { ...props?.rowData, services: props?.schedules },
                    comesFromRaport: true,
                    header: "SCHEDULING",
                    additionalStyle: {
                      color: "#D32F2F",
                      borderLeft: "4px solid #D32F2F",
                      backgroundColor: "#FFEBEE",
                    },
                    loadingRaport: loading,
                  }}
                />
              </Card>
              <Card>
                <Forecasting
                  {...{
                    rowData: props?.rowData,
                    comesFromRaport: true,
                    header: "ACTUAL",
                    additionalStyle: {
                      color: "rgb(40, 53, 51)",
                      borderLeft: "4px solid rgb(6, 129, 109)",
                      backgroundColor: "rgb(126, 192, 181)",
                    },
                  }}
                />
              </Card>
            </>
          )}
        </div> */}
      </Modal>
    </div>
  );
};

export default ForecastingRaport;
