import React, { useEffect, useState } from "react";

import { Bar, Line, Pie } from "react-chartjs-2";
import dayjs from "dayjs";

//region BarChart2
const getBar2 = (rowData, setChartData) => {
  if (!rowData || rowData.length === 0) {
    return;
  }

  const dataByMonth = {};

  rowData.forEach(({ createdAt }) => {
    const date = dayjs(createdAt); // Use dayjs to parse the date
    const month = date.format("MMM YYYY"); // Format month as "Jan 2023"
    console.log("month:", { month });
    const day = date.date(); // Get the day of the month
    console.log("day:", { day });

    if (!dataByMonth[month]) {
      dataByMonth[month] = {};
    }

    if (!dataByMonth[month][day]) {
      dataByMonth[month][day] = 0;
    }

    dataByMonth[month][day]++;
  });

  const labels = Array.from({ length: 31 }, (_, i) => i + 1); // Days of the month
  const datasets = Object.keys(dataByMonth).map((month) => {
    const data = labels.map((day) => dataByMonth[month][day] || 0);

    const twelveColors = {
      0: "rgb(255, 99, 132)",
      1: "rgb(75, 192, 192)",
      2: "rgb(53, 162, 235)",
      3: "rgb(255, 205, 86)",
      4: "rgb(75, 192, 192)",
      5: "rgb(53, 162, 235)",
      6: "rgb(255, 99, 132)",
      7: "rgb(75, 192, 192)",
      8: "rgb(53, 162, 235)",
      9: "rgb(255, 205, 86)",
      10: "rgb(75, 192, 192)",
      11: "rgb(53, 162, 235)",
    };
    const color = twelveColors[Object.keys(dataByMonth).indexOf(month)];

    return {
      label: month,
      data,
      backgroundColor: color,
      borderColor: color,
      borderWidth: 1,
    };
  });

  setChartData({
    labels, // Days of the month
    datasets,
  });
};

//region LineChart
const processLineChartData = (rowData, setLineChartData) => {
  const dataByDate = {};

  rowData.forEach(({ otherDetails }) => {
    const date = dayjs(otherDetails.createdAt).format("YYYY-MM-DD");

    if (!dataByDate[date]) {
      dataByDate[date] = 0;
    }

    dataByDate[date]++;
  });

  const labels = Object.keys(dataByDate).sort();
  const data = labels.map((date) => dataByDate[date]);

  setLineChartData({
    labels,
    datasets: [
      {
        label: "Number of Proposals",
        data,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
      },
    ],
  });
};

//region BarChart1
const processBarChartData = (rowData, setBarChartData) => {
  const dataByMonth = {};

  rowData.forEach(({ otherDetails }) => {
    const date = dayjs(otherDetails.createdAt);
    const month = date.format("MMM YYYY");

    if (!dataByMonth[month]) {
      dataByMonth[month] = 0;
    }

    dataByMonth[month]++;
  });

  const labels = Object.keys(dataByMonth);
  const data = labels.map((month) => dataByMonth[month]);

  setBarChartData({
    labels,
    datasets: [
      {
        label: "Number of Proposals",
        data,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });
};

//region PieChart
const processPieChartData = (rowData, setPieChartData) => {
  const statusCounts = {};

  rowData.forEach(({ proposalStatus }) => {
    if (!statusCounts[proposalStatus]) {
      statusCounts[proposalStatus] = 0;
    }

    statusCounts[proposalStatus]++;
  });

  const labels = Object.keys(statusCounts);
  const data = labels.map((status) => statusCounts[status]);

  setPieChartData({
    labels,
    datasets: [
      {
        label: "Proposal Statuses",
        data,
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });
};

const init = {
  labels: [],
  datasets: [],
};

function BodyStatistics({ rowData }) {
  const [barChartData, setBarChartData] = useState(init);
  const [pieChartData, setPieChartData] = useState(init);
  const [lineChartData, setLineChartData] = useState(init);
  const [barChart2, setBarChart2] = useState(init);

  useEffect(() => {
    processBarChartData(rowData, setBarChartData);
    processPieChartData(rowData, setPieChartData);
    processLineChartData(rowData, setLineChartData);
    getBar2(rowData, setBarChart2);
  }, [rowData]);

  return (
    <>
      <div className="chart-container">
        <h3>Proposals Created Per Month</h3>
        <div
          style={{
            height: "250px",
            width: "500px",
          }}
        >
          <Bar data={barChartData} options={{ responsive: true }} />
        </div>
      </div>
      <div className="chart-container">
        <h3>Proposal Status Distribution</h3>
        <div
          style={{
            height: "290px",
            width: "250px",
          }}
        >
          <Pie data={pieChartData} options={{ responsive: true }} />
        </div>
      </div>
      <div className="chart-container">
        <h3>Proposals Created Over Time</h3>
        <div
          style={{
            height: "250px",
            width: "500px",
          }}
        >
          <Line data={lineChartData} options={{ responsive: true }} />
        </div>
      </div>
      <div className="chart-container">
        <h3>Proposals Created Per Day</h3>
        <div
          style={{
            height: "250px",
            width: "500px",
          }}
        >
          <Bar data={barChart2} options={{ responsive: true }} />
        </div>
      </div>
    </>
  );
}

export default BodyStatistics;
