import { Menu } from "antd";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { SideBarSubsConfig } from "../Sidebars/SidebarData";
import { NewShortcutPopover } from "../Sidebars/components";
import Headline from "../commonComponents/Typography/Headline";

const Label = ({ label, href }) => {
  if (href) {
    return (
      <NavLink to={href}>
        <Headline level={5} whiteText>
          {label}
        </Headline>
      </NavLink>
    );
  }

  return (
    <Headline level={5} whiteText>
      {label}
    </Headline>
  );
};

function MobileHomeMenu({ pages = [], isLoaded, props }) {
  const [currentPage, setCurrentPage] = useState("");

  let location = useLocation();

  pages = pages.reduce((acc, currentValue) => {
    for (const [key, value] of Object.entries(SideBarSubsConfig)) {
      if (!acc[key]) {
        acc[key] = { children: [] };
      }
      if (key.toLowerCase() === currentValue.title.toLowerCase()) {
        acc[key].children.push(currentValue);
      }
      if (value.children.includes(currentValue.id)) {
        acc[key].children.push(currentValue);
      }
      if (value.src) {
        acc[key].src = value.src;
      }
    }
    return acc;
  }, {});

  const filteredPages = Object.entries(pages)
    ?.filter(([, value]) => !!value["children"].length)
    ?.map(([key, value]) => ({ ...value, page: key }));

  const items = filteredPages?.map((item) => {
    const IconSrc = item?.src;
    const ItemIcon = (
      <span className="li-icon">
        <IconSrc width="100%" />
      </span>
    );

    if (item["children"].length === 1) {
      return {
        key: item?.page?.toLowerCase(),
        icon: ItemIcon,
        label: (
          <Label
            label={item["children"][0].title}
            href={item["children"][0].to}
          />
        ),
      };
    }

    return {
      key: item?.page?.toLowerCase(),
      icon: ItemIcon,
      label: <Label label={item?.page} />,
      children: item?.children?.map((subItem) => {
        const SubItemIcon = subItem?.src;
        return {
          key: subItem?.title?.toLowerCase(),
          icon: (
            <span className="li-icon">
              <SubItemIcon width="100%" />
            </span>
          ),
          label: <Label label={subItem?.title} href={subItem?.to} />,
        };
      }),
    };
  });

  useEffect(() => {
    const currentPath = `${location.pathname.split("/")[1]}`;
    setCurrentPage(currentPath);
  }, [location]);

  return (
    <div className="menu-mobile">
      <NewShortcutPopover {...props} />

      <Menu
        mode="inline"
        className="mobile-menu"
        items={isLoaded ? items : []}
        selectedKeys={[currentPage]}
      />
    </div>
  );
}

export default MobileHomeMenu;
