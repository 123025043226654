import "./Topics.scss";
import { SearchOutlined } from "@ant-design/icons";
import { Radio, Tooltip } from "antd";
import { useState } from "react";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { useSelector } from "react-redux";
import { MondayButton } from "../../../../commonComponents";
import { DeleteIcon, PlusIcon } from "../../../../../assets";
import {
  CategoriesCollapseIcon,
  CategoriesExpandIcon,
} from "src/components/SidebarPages/Communication/assets";
import { EditIcon } from "../../../../../icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { camelCaseToNormalText } from "../../../../commonComponents/RowDataGridModal/components/utils";

const Topics = ({
  title,
  defaultOptionStyle = true,
  hasCollapse = true,
  topics,
  selectedTopic = null,
  setSelectedTopic,
  searchRef,
  topicRef,
  customStyle,
  onClickNew,
  onClickEdit,
  onClickDelete,
  isDragDisabled = true,
  onDragEnd = () => {},
  disabledTopics = [],
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [searchVal, setSearchVal] = useState("");
  const [collapseTopics, setCollapseTopics] = useState(false);

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <div
      className={`topicsContainer ${isDarkMode && "topicsContainerDark"}
      ${collapseTopics ? " collapsed" : ""}`}
      style={customStyle}
    >
      {!collapseTopics && (
        <>
          {title && (
            <div className="topicsHeader">
              <span className="topicTitle">{title}</span>
              {onClickNew && (
                <MondayButton
                  data-testid="new-topic-btn"
                  Icon={<PlusIcon />}
                  onClick={onClickNew}
                />
              )}
            </div>
          )}
          <div className="topicsContent">
            <div className="search-input" ref={searchRef}>
              <InputComponent
                {...{
                  onChange: handleSearch,
                  initialValue: searchVal,
                  prefix: <SearchOutlined style={{ color: "#323338" }} />,
                  placeholder: "Search here...",
                  inputAllowClear: true,
                }}
              />
            </div>
            <div
              className={`topic-options ${
                defaultOptionStyle ? "topic-elements" : "topic-elements-2"
              }`}
            >
              <div className="widget-content">
                <Radio.Group
                  {...{
                    name: "radiogroup",
                    ...(selectedTopic && { value: selectedTopic }),
                  }}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          {topics
                            ?.filter((topic) =>
                              topic
                                .toLowerCase()
                                .includes(searchVal.toLowerCase())
                            )
                            .map((topic, i) => (
                              <Draggable
                                key={topic + `${i}`}
                                draggableId={topic.toString()}
                                index={i}
                                isDragDisabled={isDragDisabled}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div
                                      className="topicOption"
                                      key={i}
                                      ref={i === 0 ? topicRef : null}
                                    >
                                      <Radio
                                        data-testid="selected-topic"
                                        onChange={() => setSelectedTopic(topic)}
                                        value={topic}
                                        disabled={disabledTopics?.includes(
                                          topic
                                        )}
                                        checked={
                                          selectedTopic
                                            ? selectedTopic === topic
                                            : false
                                        }
                                      >
                                        {camelCaseToNormalText(topic)}
                                      </Radio>
                                      {selectedTopic === topic && (
                                        <div className="actionButtons">
                                          {onClickEdit && (
                                            <EditIcon
                                              style={{
                                                cursor: "pointer",
                                                fill: "#1264A3",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                onClickEdit(topic);
                                              }}
                                            />
                                          )}
                                          {onClickDelete && (
                                            <DeleteIcon
                                              style={{
                                                cursor: "pointer",
                                                fill: "#FE4C4A",
                                              }}
                                              onClick={() => {
                                                onClickDelete(topic);
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Radio.Group>
              </div>
            </div>
          </div>
        </>
      )}
      {hasCollapse && (
        <div
          className="topicsCollapse"
          data-testid="topicsCollapse-testid"
          onClick={() => setCollapseTopics((prev) => !prev)}
        >
          <Tooltip
            trigger="hover"
            placement="right"
            title={`${collapseTopics ? "Expand" : "Collapse"} Topics`}
          >
            {collapseTopics ? (
              <CategoriesExpandIcon />
            ) : (
              <CategoriesCollapseIcon />
            )}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Topics;
