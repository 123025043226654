import {
  formatCurrency,
  formatNumberToPercentage,
} from "../../../../../../../../../../utils";

export const addonsColumnDefs = (service, addon, programFields) => {
  const splittedData = addon?.amounts
    ? addon?.amounts?.map((amount, amountIndex) => ({
        headerName: amount.event,
        marryChildren: true,
        headerClass:
          amountIndex % 2 === 0 ? "oddHeaderClassName" : "evenHeaderClassName",
        children: [
          {
            field: "charge percentage",
            valueGetter: (params) =>
              `${formatNumberToPercentage(
                params.data?.amounts?.find(
                  (event) => amount.event === event.event
                )?.rate
              )}`,
            columnGroupShow: "open",
            headerClass:
              amountIndex % 2 === 0
                ? "oddHeaderClassName"
                : "evenHeaderClassName",
          },
          {
            field: "amount",
            valueGetter: (params) =>
              `${formatCurrency(
                params.data?.amounts?.find(
                  (event) => amount.event === event.event
                )?.amount,
                "USD"
              )}`,
            headerClass:
              amountIndex % 2 === 0
                ? "oddHeaderClassName"
                : "evenHeaderClassName",
          },
          {
            field: "difference",
            valueGetter: (params) =>
              `${formatCurrency(
                params.data?.amounts?.find(
                  (event) => amount.event === event.event
                )?.difference,
                "USD"
              )}`,
            columnGroupShow: "open",
            headerClass:
              amountIndex % 2 === 0
                ? "oddHeaderClassName"
                : "evenHeaderClassName",
          },
        ],
      }))
    : [];

  const { dimensions } =
    programFields
      ?.find(({ fieldName }) => fieldName === "SOV Details Column Definitions")
      ?.addonsColumnDefs?.find(({ name }) => name === service?.label) || {};

  const originalElevationData = dimensions?.map(({ headerName, field }) => ({
    headerName,
    field,
    columnGroupShow: "open",
    editable: false,
    filter: "agTextColumnFilter",
    flex: 1,
    headerTooltip: `${headerName} from estimation`,
  }));

  return [
    {
      headerName: "Id",
      headerTooltip: "Id",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Price",
      headerTooltip: "Price",
      field: "totalPrice",
      cellRenderer: ({ value }) => formatCurrency(value),
      flex: 1,
    },
    ...(originalElevationData || []),
    ...(splittedData || []),
  ];
};
