import { createContext, useContext } from "react";

const ProposalContext = createContext({});

export const useProposalContext = () => {
  const context = useContext(ProposalContext);
  if (!context) {
    console.error("useProposalContext must be used within a ProposalProvider");
    return {};
  }
  return context;
};

export const ProposalProvider = ({ children, value }) => {
  return (
    <ProposalContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </ProposalContext.Provider>
  );
};
