import dayjs from "dayjs";
import { getWeeksDiff } from "../../../../RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalBody/AgGrid/columnDefs";
import moment from "moment";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
export const extractDataHandler = ({ params }) => {
  return params?.data?.services?.map(({ label, serviceOptions }) => {
    const weeks = serviceOptions
      ?.flatMap(({ items }) => items)
      ?.flatMap(({ rentalDetails }) => rentalDetails?.details)
      ?.filter(Boolean); // Ensure valid details

    // Extract start and end dates using Math.min/Math.max as timestamps
    const startDate = weeks?.length
      ? Math.min(...weeks.map(({ weekStart }) => weekStart).filter(Boolean))
      : undefined;

    const endDate = weeks?.length
      ? Math.max(...weeks.map(({ weekEnd }) => weekEnd).filter(Boolean))
      : undefined;
    // console.log("extractDataHandler", {
    //   label,
    //   startDate: dayjsNY(startDate).format("MM/DD/YYYY"),
    //   endDate: dayjsNY(endDate).format("MM/DD/YYYY"),
    //   endDate2: dayjs(endDate).format("MM/DD/YYYY"),
    //   weeks,
    // });
    return {
      label,
      startDate, // Returns as timestamp
      endDate, // Returns as timestamp
    };
  });
};

export const columnDefs = [
  {
    headerName: "No.",
    cellRendererFramework: (params) => params?.rowIndex + 1,
  },
  {
    headerName: "Label",
    flex: 2,
    field: "label",
  },
  {
    headerName: "Weeks",
    field: "weeksNo",
    valueGetter: (params) => {
      let endDate = !!params.data.endDate ? moment(params.data.endDate) : null;
      let startDate = !!params.data.startDate
        ? moment(params.data.startDate)
        : null;
      let numberOfWeeks = getWeeksDiff(endDate, startDate);
      return numberOfWeeks;
      // return <div className="rentalEditableCell">{numberOfWeeks.toString}</div>;
    },
  },
  {
    headerName: "Start Date",
    field: "startDate",
    flex: 2,
    cellRenderer: ({ value }) =>
      !!value ? dayjs(value)?.format("MM/DD/YYYY") : "--",
  },
  {
    headerName: "End Date",
    field: "endDate",
    flex: 2,
    cellRenderer: ({ value }) =>
      !!value ? dayjs(value)?.format("MM/DD/YYYY") : "--",
  },
];

export const defaultColDef = {
  flex: 1,
  sortable: true,
  resizable: true,
  enablePivot: true,
  filter: true,
  enableColResize: true,
  enableRowGroup: true,
  rowHeight: 40,
};
