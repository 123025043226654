// import { boroughs } from "../../../addWidgetsModalData";
import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";

export const claims = ({ tables, programFields }) => {
  const statusesOfClaims = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Claims;

  console.log("Tables : ", tables);
  // const isEmployee = { Yes: 0, No: 0 };

  // tables?.Claims?.forEach((e) => {
  //   isEmployee[e?.claimObject?.isClaimantAnEmployeeOfCore] += 1;
  // });

  return {
    claimStatus: () => ({
      labels: statusesOfClaims?.map(({ statusName }) => statusName),
      data: statusesOfClaims?.map(
        ({ statusName }) =>
          tables?.Claims?.filter((e) => e?.claimStatus === statusName)?.length
      ),
      backgroundColor: programFields
        ?.find((e) => e?.fieldName === "Status colors")
        ?.fieldOptions?.Claims?.claimStatus?.map((e) => e.statusColor),
    }),
    claimCategory: () => ({
      labels: uniq(tables?.Claims?.map((e) => e?.claimCategory)),
      data: tables?.Claims?.map(
        (a) =>
          tables?.Claims?.filter((e) => e?.claimCategory === a?.claimCategory)
            ?.length
      ),
      backgroundColor: uniq(tables?.Claims?.map(() => getRandomColor())),
    }),
    claimType: () => ({
      labels: uniq(tables?.Claims?.map((e) => e?.claimType)),
      data: tables?.Claims?.map(
        (a) =>
          tables?.Claims?.filter((e) => e?.claimType === a?.claimType)?.length
      ),
      backgroundColor: uniq(tables?.Claims?.map(() => getRandomColor())),
    }),

    // isClaimantAnEmployeeOfCore: () => ({
    //   labels: ["Yes", "No"],
    //   data: Object.values(isEmployee),
    //   backgroundColor: uniq(tables?.Claims?.map(() => getRandomColor())),
    // }),
  }; //Claims
};
