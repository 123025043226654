import { API } from "aws-amplify";

export const saveCitation = async (citation, status, userConfiguration) => {
  return API.post("citations", "/citations", {
    body: {
      citationName: citation.citationName,
      employeeId: citation.employeeId,
      citationObject: citation.citationObject,
      citationStatus: status,
      createdBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
      citationCategory: citation?.citationCategory || "General",
      teamsConfiguration: citation?.teamsConfiguration || [],
      googleDriveFolderIds: citation?.googleDriveFolderId || {},
      googleDriveUpload: citation?.googleDriveUpload || "",
      lastModifiedBy: {},
      createdAt: new Date(),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("There was a problem with the save operation:", error);
    });
};
