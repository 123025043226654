import "./UserInfos.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { HistoryOutlined, LockFilled, UnlockFilled } from "@ant-design/icons";
import {
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../../../commonComponents";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import OnBoardingProgress from "../../../../../../../OnBoarding/OnBoardingProgress/OnBoardingProgress";
import { LOG_CATEGORIES_PROFILE } from "../../../helpers";
import {
  ChangePasswordIcon,
  DepartmentIcon,
  KeyIcon,
  PhoneIcon,
  GreenCircleIcon,
  SuspendedIcon,
} from "../../../../../../../../assets";
import { EmailIcon } from "../../../../../../../SidebarPages/BasePage/src";
import { useEditLogs } from "../../../../../../../../hooks";
import TwoFactorAuthenticationChange from "./TwoFactorAuthenticationChange/TwoFactorAuthenticationChange";

const UserInfos = () => {
  const {
    userConfiguration: {
      userName,
      nameOfUser,
      cognitoUserId,
      phone_number,
      departmentName,
      groupName,
      isSuspended,
    } = {},
  } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { isLoggedIn } = useSelector((state) => state.googleAuth);

  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const { fetchedLogs, setFetchedLogs, handleFetchLogs, loading } =
    useEditLogs();

  const handleShowLogs = () => {
    const filters = [
      {
        operator: "AND",
        conditions: Object.values(LOG_CATEGORIES_PROFILE).map((value) => ({
          operator: "OR",
          column: "category",
          value,
          formula: "is",
        })),
      },
      {
        operator: "AND",
        conditions: [
          {
            operator: "AND",
            column: "cognitoUserId",
            value: cognitoUserId,
            formula: "is",
          },
        ],
      },
    ];
    handleFetchLogs({ filters });
  };

  return (
    <div className={`user-info ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="user-informations">
        <div className="nameOfUser">
          {isSuspended ? <SuspendedIcon /> : <GreenCircleIcon />}
          {nameOfUser}
        </div>
        <span style={{ marginBottom: "20px" }}>{groupName}</span>

        <span className="row-info" data-testid="phone-icon">
          <span className="icon">{<PhoneIcon />}</span>
          <span className="label">{phone_number}</span>
        </span>
        <span className="row-info" data-testid="email-icon">
          <span className="icon">{<EmailIcon />}</span>
          <span className="label">{userName}</span>
        </span>
        <span className="row-info" data-testid="department-icon">
          <span className="icon">{<DepartmentIcon />}</span>
          <span className="label">{departmentName}</span>
        </span>
        <span className="row-info" data-testid="key-icon">
          <span className="icon">{<KeyIcon />}</span>
          <span className="label">{groupName}</span>
        </span>
        <span className="row-info" data-testid="lock-icons">
          <span className="icon">
            {isLoggedIn ? (
              <UnlockFilled style={{ fontSize: "20px" }} />
            ) : (
              <LockFilled style={{ fontSize: "20px" }} />
            )}
          </span>
          <span className="label">
            {isLoggedIn ? "Authenticated" : "Not Authenticated"}
          </span>
        </span>

        {groupName !== "Foreman" && (
          <div className="progress-button">
            <OnBoardingProgress />
          </div>
        )}
      </div>
      <div className="account-buttons">
        <TwoFactorAuthenticationChange />
        <MondayButton
          {...{
            className: "mondayButtonBlue",
            Icon: <ChangePasswordIcon />,
            onClick: () => {
              setChangePasswordModal(true);
            },
          }}
        >
          Change Pass.
        </MondayButton>
        <MondayButton
          className="mondayButtonBlue"
          disabled={loading}
          Icon={
            loading ? (
              <Spin
                indicator={
                  <HistoryOutlined
                    data-testid="history-outlined"
                    style={{ color: "white" }}
                    spin
                  />
                }
              />
            ) : (
              <HistoryOutlined />
            )
          }
          onClick={handleShowLogs}
        >
          Show Logs
        </MondayButton>
      </div>

      {!!changePasswordModal && (
        <div data-testid="pass-modal">
          <ChangePasswordModal
            {...{
              updatePassword: changePasswordModal,
              setUpdatePassword: setChangePasswordModal,
            }}
          />
        </div>
      )}

      {Array.isArray(fetchedLogs) && (
        <MultiLevelTreeLogs
          visible={fetchedLogs}
          setVisible={setFetchedLogs}
          logsData={fetchedLogs.sort((a, b) => a.updatedAt - b.updatedAt)}
          title="Profile Logs"
        />
      )}
    </div>
  );
};

export default UserInfos;
