import React from "react";
import { Stepper } from "../../../commonComponents";
import "./ProposalBodyStepper.scss";
function ProposalBodyStepper({ proposalStatus, setProposalStatus }) {
  const items = [
    { title: "Drafts" },
    { title: "Action Required" },
    { title: "Waiting for Client" },
    { title: "Finalized" },
    { title: "To Be Approved" },
    { title: "Approved" },
  ];
  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      {" "}
      <Stepper
        currentStep={items.findIndex(
          (el) =>
            el?.title?.toLocaleLowerCase() ===
            proposalStatus?.toLocaleLowerCase()
        )}
        setCurrentStep={(curr) => {
          setProposalStatus(items[curr]?.title);
        }}
        items={items}
        stepRenderer={false}
        type="navigation"
        componentContainerClassName="proposalBuilderWrapper"
      />
    </div>
  );
}

export default ProposalBodyStepper;
