import moment from "moment-timezone";
import dayjs from "dayjs";

export const onFilter = ({
  gridApi,
  column,
  current,
  type = "equals",
  masterDetail,
}) => {
  const currentValues = Array.isArray(current)
    ? { values: [...current] }
    : { filter: current };

  const isInspectionList = window.location.pathname === "/inspections";
  const excludedColumn =
    column === "estimations" ||
    column === "services" ||
    column === "scheduleName";

  setTimeout(() => {
    if (!masterDetail) {
      if (isInspectionList && excludedColumn) {
        return;
      } else {
        type === "set"
          ? gridApi
              ?.getFilterInstance?.(column)
              ?.getChildFilterInstance?.(1)
              ?.setModel({
                type,
                ...currentValues,
              })
          : gridApi?.getFilterInstance?.(column)?.setModel?.({
              type,
              ...currentValues,
            });
        gridApi?.onFilterChanged?.();
      }
    } else {
      gridApi?.forEachDetailGridInfo?.((params) => {
        if (!params) return;
        type === "set"
          ? params.api?.getFilterInstance?.(column)?.setModel?.({
              type,
              ...currentValues,
            })
          : params.api?.getFilterInstance?.(column)?.setModel?.({
              type,
              ...currentValues,
            });
        params?.api?.onFilterChanged?.();
      });
    }
  }, 1000);
};

export const onDateFilter = ({ gridApi, column, dateArray, masterDetail }) => {
  let dateFrom, dateTo;

  try {
    dateFrom = !!dateArray ? dateArray[0]?.clone()?.format("YYYY-MM-DD") : null;
    dateTo = !!dateArray ? dateArray[1]?.clone()?.format("YYYY-MM-DD") : null;
  } catch (error) {
    dateFrom = !!dateArray
      ? dayjs(dateArray[0]?.$d || dateArray[0]).format("YYYY-MM-DD")
      : null;
    dateTo = !!dateArray
      ? dayjs(dateArray[1]?.$d || dateArray[1]).format("YYYY-MM-DD")
      : null;
  }

  if (!masterDetail) {
    if (typeof dateArray?.[0] === "number") {
      gridApi?.getFilterInstance(column)?.setModel({
        type: "inRange",
        filterType: "date",
        dateFrom,
        dateTo,
      });
      gridApi?.onFilterChanged();
    } else {
      gridApi?.getFilterInstance(column)?.setModel({
        type: "inRange",
        filterType: "date",
        dateFrom,
        dateTo,
      });
      gridApi?.onFilterChanged();
    }
  } else {
    gridApi?.forEachDetailGridInfo?.((params) => {
      if (typeof dateArray?.[0] === "number") {
        params?.api?.getFilterInstance(column)?.setModel({
          type: "inRange",
          filterType: "date",
          dateFrom,
          dateTo,
        });
        params?.api?.onFilterChanged();
      } else {
        params?.api?.getFilterInstance(column)?.setModel({
          type: "inRange",
          filterType: "date",
          dateFrom,
          dateTo,
        });
        params?.api?.onFilterChanged();
      }
    });
  }
};

export const onNumberRangeFilter = ({
  gridApi,
  column,
  numberArray,
  masterDetail,
}) => {
  let filter = !!numberArray?.[0] ? Number(numberArray?.[0]) - 1 || null : null;
  let filterTo = !!numberArray?.[1]
    ? Number(numberArray?.[1]) + 1 || null
    : null;

  if (!masterDetail) {
    gridApi.getFilterInstance(column).setModel({
      type: "inRange",
      filterType: "number",
      filter,
      filterTo,
    });
    gridApi?.onFilterChanged();
  } else {
    gridApi?.forEachDetailGridInfo?.((params) => {
      params?.api?.getFilterInstance(column)?.setModel({
        type: "inRange",
        filterType: "number",
        filter,
        filterTo,
      });
      params?.api?.onFilterChanged();
    });
  }
};

export const onTimeFilter = ({
  gridApi,
  column,
  activeFilters,
  newFiltersObject,
  filtersObject,
  dateArray,
}) => {
  // if (typeof dateArray[0] === "number") {
  //   gridApi.getFilterInstance(column).setModel({
  //     type: "inRange",
  //     filterType: "date",
  //     dateFrom: moment(dateArray[0]).format("YYYY-MM-DD"),
  //     dateTo: moment(dateArray[1]).format("YYYY-MM-DD"),
  //   });
  //   gridApi.onFilterChanged();
  // } else {
  //   gridApi.getFilterInstance(column).setModel({
  //     type: "inRange",
  //     filterType: "date",
  //     dateFrom: moment(dateArray[0]).format("YYYY-MM-DD"),
  //     dateTo: moment(dateArray[1]).format("YYYY-MM-DD"),
  //   });
  //   gridApi.onFilterChanged();
  // }
};
export const clearFilter = ({ gridApi, column }) => {
  gridApi.getFilterInstance(column).setModel(null);
  gridApi.onFilterChanged();
  gridApi?.forEachDetailGridInfo?.((params) => {
    params.api.getFilterInstance(column).setModel(null);
    params.api.onFilterChanged();
  });
};

export const clearAllFilters = ({ filters, gridApi }) => {
  filters.map((column) => clearFilter({ gridApi, column }));
};

const filterValues = (type) => {
  if (type === "Last 4 hours") {
    return [moment().subtract(4, "hours").valueOf(), moment().valueOf()];
  }
};
