import { useContext } from "react";
import { useSelector } from "react-redux";
import { Form, message } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { FleetsLiveContext } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { MondayButton } from "src/components/commonComponents";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { ClearFilterIcon } from "src/components/SidebarPages/DynamicView/src";

import "./TripsPicker.scss";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

function TripsPicker({ onConfirm = () => {} }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { selectedDate } = useContext(FleetsLiveContext);

  const [form] = Form.useForm();

  function calcStartEnd(filterValues) {
    let start = undefined;
    let end = undefined;
    let call = true;

    if (!filterValues?.["selected-date-range"]) {
      if (
        dayjsNY(filterValues["start-time"]).valueOf() >=
        dayjsNY(filterValues["end-time"]).valueOf()
      ) {
        message.warning({
          content: '"From Time" needs to be earlier than "To Time"',
          key: "smallTime",
        });
        call = false;
      } else {
        let timeStart = filterValues["start-time"];
        let timeEnd = filterValues["end-time"];
        let date = dayjsNY(selectedDate).format("DD/MM/YYYY");

        start = parseInTz(
          dayjs(`${date} ${timeStart.format("HH:mm")}`, "DD/MM/YYYY HH:mm")
        ).valueOf();

        end = parseInTz(
          dayjs(`${date} ${timeEnd.format("HH:mm")}`, "DD/MM/YYYY HH:mm")
        ).valueOf();
      }
    } else {
      let range = filterValues["selected-date-range"].map((e) =>
        dayjsNY(parseInTz(e)).startOf("day")
      );

      let timeStart = filterValues["start-time"];
      let timeEnd = filterValues["end-time"];

      if (range[0].valueOf() === range[1].valueOf()) {
        if (timeStart.valueOf() >= timeEnd.valueOf()) {
          message.warning({
            content: '"From Time" needs to be earlier than "To Time"',
            key: "smallTime",
          });
          call = false;
        }
      }

      start = parseInTz(
        dayjs(
          `${range[0].format("DD/MM/YYYY")} ${timeStart.format("HH:mm")}`,
          "DD/MM/YYYY HH:mm"
        )
      ).valueOf();

      end = parseInTz(
        dayjs(
          `${range[1].format("DD/MM/YYYY")} ${timeEnd.format("HH:mm")}`,
          "DD/MM/YYYY HH:mm"
        )
      ).valueOf();
    }

    if (call) {
      onConfirm({ start, end });
    }
  }

  return (
    <Form
      form={form}
      className={`trips-picker-form ${
        isDarkMode ? "trips-picker-form-dark" : ""
      }`}
    >
      <InputComponent
        {...{
          type: "rangePicker",
          formItemName: "selected-date-range",
          label: "Range",
          format: "MM/DD/YYYY",
          style: { width: "100%" },
          showTime: false,
          dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
        }}
      />
      <div className="time-select-section">
        <InputComponent
          {...{
            type: "timePicker",
            formItemName: "start-time",
            label: "From",
            dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
            required: true,
            allowClear: false,
          }}
        />
        <InputComponent
          {...{
            type: "timePicker",
            formItemName: "end-time",
            label: "To",
            dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
            required: true,
            allowClear: false,
          }}
        />
      </div>
      <div className="buttons-section">
        <MondayButton
          Icon={<ClearFilterIcon />}
          className="mondayButtonRed"
          onClick={() => {
            form.setFieldsValue({
              "start-time": null,
              "end-time": null,
              "selected-date-range": null,
            });
            onConfirm();
          }}
        >
          Clear Filer
        </MondayButton>
        <MondayButton
          Icon={<TickIcon />}
          onClick={() => {
            form.validateFields().then(calcStartEnd);
          }}
        >
          Confirm
        </MondayButton>
      </div>
    </Form>
  );
}

export default TripsPicker;
