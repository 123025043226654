import "./Styles.scss";

import { useState } from "react";
import { Button, Divider, Flex, Modal } from "antd";

import List from "./List";
import Headline from "../../../../commonComponents/Typography/Headline";

export default function GalleryView({ data = {} }) {
  const { inspectionReport, projectName, inspectionType } = data;
  const { questions } = inspectionReport || {};

  const [open, setOpen] = useState(false);

  const services = Object.keys(questions ?? {})?.reduce((acc, item) => {
    acc.push({
      serviceName: item,
      serviceQuestions: questions?.[item]?.map((category) => ({
        categoryId: category?.categoryId,
        categoryName: category?.categoryName,
        questions: category?.questions?.map((question) => ({
          questionId: question?.questionId,
          question: question?.question,
          fileIds: question?.fileIds ?? [],
        })),
      })),
    });
    return acc;
  }, []);

  const hasImages = services?.flatMap(({ serviceQuestions }) =>
    serviceQuestions?.flatMap(({ questions }) =>
      questions?.flatMap(({ fileIds }) => fileIds)
    )
  )?.length;

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      {!!hasImages && (
        <Flex justify="center" align="center" style={{ width: "100%" }}>
          <Button type="primary" size="small" onClick={() => setOpen(true)}>
            View
          </Button>
        </Flex>
      )}

      <Modal
        title={
          <Flex align="center" gap="small">
            <Headline span>{projectName}</Headline>
            <Divider type="vertical" />
            <Headline span>{inspectionType}</Headline>
          </Flex>
        }
        width={700}
        open={open}
        centered
        loading={!data}
        onCancel={() => setOpen(false)}
        footer={() => (
          <Button type="primary" onClick={handleOnClose}>
            Close
          </Button>
        )}
      >
        <div className="services-container">
          <List services={services} />
        </div>
      </Modal>
    </>
  );
}
