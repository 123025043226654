import { Form, Modal } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useCallback, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import {
  InfoIcon,
  PdfWhiteIcon,
  ExcelIconWhite,
} from "../../../../../../../assets";
import {
  FilterIcon,
  DownloadIcon,
} from "../../../../../../SidebarPages/BasePage/src";
import { filterFields } from "./exportDataModalUtils";
import { MondayButton } from "../../../../../../commonComponents";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../DEG/components/modalComponents/utils/cellFunctions";
import { compareIncluding } from "../../../../../../SidebarPages/utils";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import createPDF from "../../../../../../../integrations/AgGridToPdf/index";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";

import "./ExportDataModal.scss";

let externalFilter = {
  dateRange: [],
  employeeId: [],
  uploadName: [],
  companyName: [],
  jobsiteMatch: [],
  employeeFullName: [],
};

const excelShiftColumnsConfig = [
  "employeeId",
  "employeeFullName",
  "employeeRole",
  "crewTeamName",
  "company",
  "shiftType",
  "punchDate",
  "firstClockIn",
  "clockOut",
  "payrollType",
  "workHours",
  "breakHours",
  "overtimeHours",
  "employeeRate",
  "regAmount",
  "totalOvh",
  "otAmount",
  "total",
  "jobsiteMatch",
  "scheduleAddress",
  "sow",
  "reason",
];

function ExportDataModal({ open, onCancel, rowData, columnDefs }) {
  const [{ base64 }, { isDarkMode }] = useSelector((state) => [
    state.base64,
    state.darkMode,
  ]);

  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [hasFilters, setHasFilters] = useState(false);

  const [form] = Form.useForm();

  const location = useLocation();

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    setColumnApi(param.columnApi);
  }, []);

  const dropdownData = useMemo(() => {
    let uploadNames = [];
    let employeeIds = [];
    let companyNames = [];
    let jobsiteNames = [];
    let employeeNames = [];

    for (let i = 0; i <= rowData.length; i++) {
      const data = rowData[i];

      if (
        !!data?.uploadName &&
        !uploadNames.find((el) => el?.value === data?.uploadName)
      ) {
        const uploadItem = {
          key: i,
          label: data?.uploadName,
          value: data?.uploadName,
        };
        uploadNames.push(uploadItem);
      }

      if (
        !!data?.crewId &&
        !employeeNames.find((el) => el?.value === data?.crewId)
      ) {
        const employeeItem = {
          key: i,
          label: data?.employeeFullName,
          value: data?.crewId,
        };
        employeeNames.push(employeeItem);
      }

      if (
        !!data?.employeeId &&
        !employeeIds.find((el) => el?.value === data?.employeeId)
      ) {
        const employeeId = {
          key: i,
          label: data?.employeeId,
          value: data?.employeeId,
        };
        employeeIds.push(employeeId);
      }

      if (
        !!data?.companyName &&
        !companyNames.find((el) => el.value === data?.companyName)
      ) {
        const companyItem = {
          key: i,
          label: data?.companyName,
          value: data?.companyName,
        };
        companyNames.push(companyItem);
      }

      if (
        !!data?.jobsiteMatch?.jobsiteId &&
        !jobsiteNames.find((el) => el.value === data?.jobsiteMatch?.jobsiteId)
      ) {
        const jobsiteItem = {
          key: i,
          label: data?.jobsiteMatch?.jobName,
          value: data?.jobsiteMatch?.jobsiteId,
        };
        jobsiteNames.push(jobsiteItem);
      }
    }

    return {
      uploadNames,
      employeeIds,
      companyNames,
      jobsiteNames,
      employeeNames,
    };
  }, [rowData]);

  const formFilterFields = useMemo(() => {
    return filterFields({ dropdownData });
  }, [dropdownData]);

  const isExternalFilterPresent = useCallback(() => {
    return (
      !!externalFilter.uploadName?.length ||
      !!externalFilter.employeeId?.length ||
      !!externalFilter.companyName?.length ||
      !!externalFilter.jobsiteMatch?.length ||
      !!externalFilter.employeeFullName?.length ||
      !!externalFilter.dateRange
    );
  }, [JSON.stringify(externalFilter)]);

  const doesExternalFilterPass = useCallback(
    ({ data }) => {
      let timeCondition = true;
      let uploadCondition = true;
      let jobsiteCondition = true;
      let employeeIdCondition = true;
      let companyNameCondition = true;
      let employeeNameCondition = true;

      if (!!externalFilter.employeeFullName?.length) {
        employeeNameCondition = externalFilter.employeeFullName?.includes(
          data?.crewId
        );
      }

      if (!!externalFilter.employeeId?.length) {
        employeeIdCondition = externalFilter.employeeId?.includes(
          data?.employeeId
        );
      }

      if (!!externalFilter.jobsiteMatch?.length) {
        jobsiteCondition = externalFilter?.jobsiteMatch?.includes?.(
          data?.jobsiteMatch?.jobsiteId
        );
      }

      if (!!externalFilter.companyName?.length) {
        companyNameCondition = externalFilter.companyName.includes(
          data?.companyName
        );
      }

      if (!!externalFilter.uploadName?.length) {
        uploadCondition = externalFilter.uploadName.includes(data?.uploadName);
      }

      if (!!externalFilter.dateRange) {
        let start = dayjsNY(externalFilter.dateRange[0]).startOf("D").valueOf();
        let end = dayjsNY(externalFilter.dateRange[1]).startOf("D").valueOf();
        timeCondition = data?.punchDate
          ? dayjsNY(data?.punchDate).startOf("D").valueOf() >= start &&
            dayjsNY(data?.punchDate).startOf("D").valueOf() <= end
          : data?.firstClockIn >= start && data?.firstClockIn <= end;
      }

      return (
        timeCondition &&
        jobsiteCondition &&
        employeeIdCondition &&
        companyNameCondition &&
        employeeNameCondition &&
        uploadCondition
      );
    },
    [externalFilter]
  );

  function exportDataToPdf() {
    let rows = [];
    gridApi.forEachNode(({ data }) => {
      rows.push(data);
    });

    return createPDF({
      base64,
      rowData: rows,
      gridApi: gridApi,
      action: "download",
      masterDetail: true,
      gridColumnApi: columnApi,
      historyTab: window?.location?.pathname,
      history: location?.pathname?.split("/")?.[1],
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE: `Analytics Data`,
        PDF_LOGO: base64?.find(({ fileName }) =>
          compareIncluding(fileName, "Core Logo Black")
        )?.base64,
      },
    });
  }

  function exportToExcel() {
    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (headerName.includes("Punch Time")) {
          return dayjsNY(value).format(DEG_TIME_FORMAT);
        } else if (headerName === "Punch Date") {
          return dayjsNY(value).format(DEG_DATE_FORMAT);
        } else if (headerName === "Jobsite Match") {
          return value;
        } else if (headerName === "SOW") {
          return (value || []).join(", ");
        } else if (headerName === "Reg Amount") {
          return value || "";
        } else {
          return value === undefined || value === null ? "" : `${value}`;
        }
      },
    });
  }

  function getData() {
    externalFilter = form.getFieldsValue();
    gridApi.onFilterChanged();
    setHasFilters(true);
  }

  function clearFilters() {
    externalFilter = {
      dateRange: [],
      employeeId: [],
      uploadName: [],
      companyName: [],
      jobsiteMatch: [],
      employeeFullName: [],
    };
    form.resetFields();
    gridApi.onFilterChanged();
    setHasFilters(false);
  }

  return (
    <Modal
      centered
      open={open}
      closable={true}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      title="Export Data Modal"
      data-testid={"export-data-modal"}
      className={`exportDataModal ${isDarkMode && "exportDataModalDark"}`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          onClick={onCancel}
          className="mondayButtonRed"
        >
          Close
        </MondayButton>,
        <div className="exportButtons">
          <MondayButton
            disabled={!hasFilters}
            onClick={exportToExcel}
            Icon={<ExcelIconWhite />}
            className="mondayButtonExcel"
          >
            Export Excel
          </MondayButton>
          <MondayButton
            disabled={!hasFilters}
            Icon={<PdfWhiteIcon />}
            onClick={exportDataToPdf}
            className="mondayButtonPdf"
          >
            Export PDF
          </MondayButton>
        </div>,
      ]}
    >
      <div>
        <div className="infoContainer">
          <InfoIcon />
          <span>Set filtering parameters to export</span>
        </div>
        <Form form={form}>
          {RenderDynamicComponents(formFilterFields, form)}
        </Form>
        <div className="filterActions">
          <MondayButton
            Icon={<FilterIcon />}
            onClick={clearFilters}
            className="mondayButtonRed"
          >
            Clear Filters
          </MondayButton>
          <MondayButton
            onClick={getData}
            Icon={<DownloadIcon />}
            className="mondayButtonBlue"
          >
            Get Data
          </MondayButton>
        </div>
        <div
          className={`export-data-grid ${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }`}
        >
          <AgGridReact
            {...{
              gridApi,
              rowData,
              columnDefs,
              onGridReady,
              pagination: true,
              paginationPageSize: 8,
              doesExternalFilterPass,
              isExternalFilterPresent,
              paginationAutoPageSize: true,
              defaultExcelExportParams: {
                columnKeys: excelShiftColumnsConfig,
              },
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ExportDataModal;
