import { Typography } from "antd";
import { useSelector } from "react-redux";

const variants = {
  p: "p",
  large: "large",
  small: "small",
  muted: "muted",
};

const fontStylesMapping = {
  p: { fontSize: "14px", fontWeight: 400, lineHeight: 1 },
  large: { fontSize: "16px", fontWeight: 600 },
  small: { fontSize: "14px", fontWeight: 500 },
  muted: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1,
    color: "hsla(0, 0%, 67%, 1)",
  },
};

export default function Text({
  variant = variants.p,
  type,
  disabled,
  upperCase,
  span,
  strong,
  whiteText,
  style = {},
  dangerouslySetInnerHTML,
  children,
  ...typographyProps
}) {
  const { Text: AntText } = Typography;

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const fontStyles = fontStylesMapping[variant];

  const styles = {
    width: "100%",
    margin: "auto",
    color: whiteText ? "whitesmoke" : !!isDarkMode ? "#f2f2f2" : "#333238",
    textTransform: upperCase ? "uppercase" : undefined,
    ...fontStyles,
    ...style,
  };

  if (span) {
    return (
      <div style={{ width: "fit" }}>
        <AntText
          type={type}
          strong={strong}
          disabled={disabled}
          style={{ ...styles }}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          {...typographyProps}
        >
          {dangerouslySetInnerHTML ? (
            <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
          ) : (
            children
          )}
        </AntText>
      </div>
    );
  }

  return (
    <AntText
      type={type}
      strong={strong}
      disabled={disabled}
      style={styles}
      {...typographyProps}
    >
      {dangerouslySetInnerHTML ? (
        <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
      ) : (
        children
      )}
    </AntText>
  );
}
