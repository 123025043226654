import { getDailyTotalWorkingHours, isOffDay } from "../../helpers/calculators";
import { PopoverForDate } from "../PopoverForDate";
import { Button, Collapse, Input, Tooltip } from "antd";

import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { ScheduleDayInnerView } from "./ScheduleDayInnerView";
import { MissingDatesView } from "./MissingDatesView";
import { DayStatus } from "../DayStatusChangeModal/DayStatuses";
import { CanceledDayView } from "./CanceledDayView";
import { ComesFromDayView } from "./ComesFromDayView";
import { getScheduleDayLinks } from "../../helpers/scheduleDaysFunctions";
import dayjs from "dayjs";
import { useRedux } from "../../../../../SidebarPages/hooks/useRedux";
import {
  calculateMissingDays,
  getRemoveButton,
} from "../../SchedulingFirstPage/helperData";
dayjs.tz.setDefault("America/New_York");

export const ScheduleDayView = forwardRef(function ScheduleDayView(props, ref) {
  const {
    scheduleDay,
    scheduleDays,
    dayIndex,
    onAddDay,
    onRemoveDay,
    onRemoveTheShiftedDay,
    editMode,
    fromApprovals,
    changeScheduleDayStatus,
  } = props;

  const [isWritable] = useRedux("isWritableScheduling");
  if (scheduleDay?.status === DayStatus?.Postponed) return "";
  const { hours, minutes } = getDailyTotalWorkingHours(
    scheduleDays?.[dayIndex]
  );
  const totalDailyHours =
      `${parseFloat(hours)} hours` + (minutes !== 0 ? ` ${minutes} mins` : ``),
    onlyWorkingDailyHours =
      `${
        parseFloat(totalDailyHours) > 7
          ? parseFloat(hours) - 1
          : parseFloat(hours)
      } hours` + (minutes !== 0 ? ` ${minutes} mins` : ``);

  const { status } = scheduleDay;

  let removeDayView;

  if (scheduleDay.comesFromPostponed()) {
    removeDayView = getRemoveButton(
      "Delete this day and keep original.",
      () => onRemoveTheShiftedDay(scheduleDay?.id),
      fromApprovals ? false : editMode ? !isWritable : false
    );
  } else if (scheduleDay.isNotAvailable()) {
    removeDayView = getRemoveButton(
      `Can't remove ${status} day. Only confirmed days can be deleted like that.`,
      (e) => e.stopPropagation(),
      fromApprovals ? false : editMode ? !isWritable : false
    );
  }
  // else if (servicesForThisDay?.usageStatistics?.serviceId > 0) {
  //   removeDayView = PopoverForDate({
  //     statistics: servicesForThisDay?.usageDetails,
  //     renderer: getRemoveButton(
  //       "Can't remove this days because it is in usage!",
  //       (e) => e.stopPropagation(),
  //       fromApprovals ? false : editMode ? !isWritable : false
  //     ),
  //     title: "Can't remove this day because it is in usage!",
  //   });
  // }
  else if (scheduleDays?.length === 1) {
    removeDayView = getRemoveButton(
      "Can't remove last day",
      (e) => e.stopPropagation(),
      fromApprovals ? false : editMode ? !isWritable : false
    );
  } else {
    removeDayView = getRemoveButton(
      "Remove this day",
      () => onRemoveDay(dayIndex),
      fromApprovals ? false : editMode ? !isWritable : false
    );
  }

  const missingDaysDates = calculateMissingDays(scheduleDays, dayIndex) || [];

  const { ComesFrom } = getScheduleDayLinks(scheduleDays, scheduleDay);

  const postponedDays = ComesFrom?.filter(
    (day) => day?.status === DayStatus?.Postponed
  );

  return (
    <div>
      {scheduleDay?.status === DayStatus?.Canceled ? (
        <CanceledDayView
          {...{
            ...props,
            onlyWorkingDailyHours,
            hours,
          }}
        />
      ) : (
        <>
          {postponedDays?.length > 0 ? (
            <div className={"comes-from-days-container"}>
              {postponedDays
                ?.sort((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate))
                ?.map?.((origin_day) => {
                  return (
                    <ComesFromDayView
                      {...{
                        ...props,
                        onlyWorkingDailyHours,
                        hours,
                        scheduleDay: origin_day,
                      }}
                    />
                  );
                })}
            </div>
          ) : (
            ""
          )}
          <div>
            <ScheduleDayInnerView
              {...{
                ...props,
                onlyWorkingDailyHours,
                hours,
              }}
            />
          </div>
          {missingDaysDates?.length !== 0 ? (
            <MissingDatesView
              {...{
                missingDaysDates,
                dayIndex,
                onAddDay,
                scheduleDay,
                scheduleDays,
              }}
            />
          ) : null}
        </>
      )}
    </div>
  );
});
