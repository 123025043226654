import {
  dispatching,
  documentation,
  estimations,
  fleet,
  fleetInspections,
  leads,
  maintenance,
  opportunities,
  projects,
  scheduling,
  violations,
  applications,
  rentals,
  charges,
  invoices,
  claims,
  incidents,
  safety,
  todos,
  approvals,
  inspections,
  citations,
} from "./subCategory";

export const straightProgressBar = (
  IdHandler,
  programFields,
  updatedDynamicStates,
  projectsData,
  engData,
  archData,
  inspectionsData,
  fleetData,
  dispatchData,
  estimationsData,
  applicationsData,
  rentalsData,
  chargesData,
  invoicesData,
  incidentsData,
  reducedUsers
) => {
  return {
    Leads: () => leads(updatedDynamicStates, IdHandler, programFields),
    Claims: () => claims(updatedDynamicStates, IdHandler, programFields),
    Opportunities: () =>
      opportunities(updatedDynamicStates, IdHandler, programFields),
    Projects: () =>
      projects(updatedDynamicStates, IdHandler, programFields, projectsData),
    Documentation: () =>
      documentation(updatedDynamicStates, IdHandler, programFields),
    Scheduling: () =>
      scheduling(updatedDynamicStates, IdHandler, programFields),
    Dispatching: () =>
      dispatching(updatedDynamicStates, IdHandler, dispatchData),
    Fleet: () => fleet(updatedDynamicStates, IdHandler, fleetData),
    "Fleet Inspections": () =>
      fleetInspections(
        inspectionsData,
        updatedDynamicStates,
        IdHandler,
        programFields
      ),
    Maintenance: () => maintenance(updatedDynamicStates, IdHandler),
    Violations: () => violations(updatedDynamicStates, IdHandler),
    Incidents: () => incidents(incidentsData, updatedDynamicStates, IdHandler),
    Estimations: () =>
      estimations(
        IdHandler,
        estimationsData,
        updatedDynamicStates,
        archData,
        engData,
        programFields
      ),
    Applications: () =>
      applications(
        programFields,
        updatedDynamicStates,
        applicationsData,
        IdHandler
      ),
    Rentals: () =>
      rentals(programFields, updatedDynamicStates, rentalsData, IdHandler),
    Charges: () =>
      charges(programFields, updatedDynamicStates, chargesData, IdHandler),
    Invoices: () =>
      invoices(programFields, updatedDynamicStates, invoicesData, IdHandler),
    Safety: () => safety(IdHandler, programFields, updatedDynamicStates),
    "To Do": () => todos(updatedDynamicStates, IdHandler, programFields),
    Approvals: () => approvals(IdHandler, updatedDynamicStates, reducedUsers),
    Inspections: () => inspections(IdHandler, updatedDynamicStates),
    Citations: () => citations(IdHandler, programFields, updatedDynamicStates),
  }; //StraightProgressLine
};
