import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";

import {
  defaultColumnDefs,
  gridOptions,
  sideBar,
} from "./GridData/gridConstants";
import OpenBalanceGridHeader from "./OpenBalanceGridHeader";
import { StatusesPanel } from "../../../../commonComponents";
import { retrieveInvoices, onGeneratePDF } from "./utils/helpers";
import { openBalanceColumnDefs } from "./GridData/openBalanceColumnDefs";
import { openBalanceStatusPanel } from "./GridData/openBalanceStatusPanel";
import { autoSizeAllColumns } from "../../../../commonComponents/DynamicAgGridTable/utils/autoSizeColumns";
import FiltersBalanceTable from "../../../Accounting/Tabs/Payments/components/BalanceReport/BalanceTable/FiltersBalanceTable/FiltersBalanceTable";

import "./ClientOpenBalanceTab.scss";
import Swal from "sweetalert2";
import { message } from "antd";
import { LoadableComp } from "../../../XComponents";

function ClientOpenBalanceTab({ id, filterBy }) {
  const [rowData, setRowData] = useState(null);
  const [columnApi, setColumnApi] = useState();
  const [gridApi, setGridApi] = useState();
  const [payments, setPayments] = useState([]);
  const [isUnpaid, setIsUnpaid] = useState(false);
  const [totalities, setTotalities] = useState({
    appliedAmount: 0,
    totalInvoicedPaid: 0,
    totalEstimates: 0,
  });
  const [loading, setLoading] = useState(false);

  const [servicesRentalsPerEstimation, setServicesRentalsPerEstimation] =
    useState([]);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const timeoutRef = useRef();

  const filteredRowData = rowData?.filter((row) =>
    isUnpaid ? row.openBalance !== 0 : true
  );

  const activeInvoiceButtonText = isUnpaid ? "Unpaid Invoices" : "All Invoices";

  const onToggleFilter = () => {
    gridApi.showLoadingOverlay();
    setIsUnpaid((prev) => !prev);
  };

  useEffect(() => {
    message.loading("Loading data...", 0);
    setLoading(true);
    retrieveInvoices({
      id,
      filterBy,
      filterDateParam: null,
    })
      .then((result) => {
        console.log("result", result);
        setRowData(result?.processedInvoices);
        setPayments(result?.allPayments || []);
        setTotalities(result?.totalities);
        setServicesRentalsPerEstimation(result?.servicesRentalsPerEstimation);
        message.destroy();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const filterByDate = (dates) => {
    const { filterDateParam } = dates;
    gridApi.showLoadingOverlay();
    retrieveInvoices({
      id,
      filterBy,
      filterDateParam,
    }).then((result) => {
      setRowData(result?.processedInvoices || result?.updatedData || []);
      setPayments(result?.allPayments || []);
      setTotalities(result?.totalities);
    });
  };

  return (
    <LoadableComp loading={loading}>
      <div className="client-balance-container">
        <div className="client-balance-status-panel">
          <StatusesPanel
            data={openBalanceStatusPanel({
              rowData: filteredRowData,
              totalities,
              servicesRentalsPerEstimation,
              payments,
            })}
            onClick={() => {}}
          />
        </div>
        <div className="client-balance-filters-bar">
          <FiltersBalanceTable
            {...{
              activeInvoiceButtonText,
              onInvoiceToggle: onToggleFilter,
              filterAsOfDate: filterByDate,
              gridApi,
              columnApi,
              rowData: filteredRowData,
            }}
          />
        </div>
        <div>
          <OpenBalanceGridHeader rowData={rowData} />
          <div
            className={`client-balance-table mainGridContainer ${
              isDarkMode
                ? "dark-ag-theme ag-theme-alpine-dark"
                : "light-ag-theme ag-theme-alpine"
            }`}
          >
            <AgGridReact
              className="mainGrid"
              columnDefs={openBalanceColumnDefs({ isDarkMode })}
              rowData={filteredRowData}
              pagination={true}
              paginationPageSize={10}
              rowHeight={40}
              headerHeight={40}
              height="100%"
              defaultColDef={defaultColumnDefs}
              gridOptions={gridOptions}
              sideBar={sideBar}
              onGridReady={(params) => {
                setGridApi(params?.api);
                setColumnApi(params?.columnApi);
              }}
              suppressDragLeaveHidesColumns={true}
              onFirstDataRendered={(grid) => {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                  autoSizeAllColumns(rowData, grid, false);
                }, 300);
              }}
            />
          </div>
        </div>
      </div>
    </LoadableComp>
  );
}

export default ClientOpenBalanceTab;
