import {
  getEmailNotificationsFailure,
  getEmailNotificationsRequest,
  getEmailNotificationsRequestFinished,
  getEmailNotificationsSuccess,
  setFilters,
  updateNotifications,
} from "../../../../../actions/notificationsActions";
import { showInfoMsg } from "../../../../../utils";
import { isProduction } from "../../../../../utils/isProduction";
import { callGmailAPI } from "../../../../SidebarPages/Communication/functions";
import { getContent } from "../utils";
import { getFilterByValue } from "../utils/helpers";

export const getSearchValueFilteredNotifications = (
  notificationsData, // Array of notifications to be filtered
  searchValue // Search term provided by the user
) => {
  // Convert the search value to lowercase and trim whitespace
  const searchLower = searchValue.toLowerCase().trim();

  // Filter the notifications based on the search value
  return notificationsData.filter((obj) => {
    // Get content from the notification using a utility function
    const content = getContent(obj);

    // If content is not available or is not a string, skip this notification
    if (typeof content !== "string") return false;

    // Remove HTML tags from content and convert to lowercase for comparison
    const cleanedContent = content
      .replace(/<[^>]+>/g, "") // Remove HTML tags
      .replace(/'/g, "") // Remove single quotes
      .toLowerCase(); // Convert to lowercase

    // Check if the cleaned content includes the lowercased search term
    return cleanedContent.includes(searchLower);
  });
};

export const paginatedNotifications = (
  currentPage, // The current page number to display
  totalNotificationsPerPage, // The number of notifications to display per page
  allNotifications // An array of all notifications available
) => {
  // Calculate the starting index for slicing the notifications array
  const startIndex = (currentPage - 1) * totalNotificationsPerPage;

  // Calculate the ending index for slicing, ensuring it doesn't exceed the array length
  const endIndex = Math.min(
    startIndex + totalNotificationsPerPage, // Calculate the end based on current page and items per page
    allNotifications?.length || 0 // Use the length of all notifications or default to 0 if undefined
  );

  // Return a sliced array of notifications for the current page, or an empty array if none exist
  return allNotifications?.slice(startIndex, endIndex) || [];
};

export const handleMarkAllAsRead = async (
  sub, // The unique identifier for the user (e.g., cognitoUserId)
  dispatch, // The dispatch function to send actions to the Redux store
  allNotifications, // An array of all notifications available
  apiService // The API service instance to interact with the backend
) => {
  // Return early if no notifications are provided
  if (!allNotifications) return;

  // Check if there are more than 300 notifications
  if (allNotifications.length > 300) {
    // Display the showInfoMsg 
    showInfoMsg({
      content: "Too many notifications.",
    });
    return; // Exit the function to wait for user interaction with the modal
  }
  // Proceed if there are 300 or fewer notifications
  await markAllNotificationsAsRead();
  // Function to mark all notifications as read
  async function markAllNotificationsAsRead() {
  // Prepare the payload for the API request to modify notification status
  const payload = {
    action: "modify", // Action type to indicate what operation is being performed
    id: sub, // The identifier of the user whose notifications are being modified
    modValue: true, // The value to set for the specified modify key
    modifyKey: "hasBeenSeen", // The key that indicates the notification has been seen/read
  };

  // Show an informational message to the user indicating the action
  showInfoMsg({
    content: "All notifications marked as read",
  });

  // Dispatch an action to update the notifications in the Redux store
  dispatch(
    updateNotifications({
      notifications: allNotifications
        ?.filter((notification) => !notification.seen) // Filter notifications that have not been seen
        .map((notification) => notification.id), // Extract the IDs of the unread notifications
      action: "mark all as read", // Indicate the action taken
    })
  );

  // Call the API service to mark all notifications as read
  await apiService.modifyAll(payload);
}
};

export const getEmailsAsNotifications = async (
  dispatch,
  category,
  authData,
  userConfiguration // User-specific settings or configuration for the API
) => {
  // Dispatch an action to indicate the start of the email notifications request
  dispatch(getEmailNotificationsRequest());

  try {
    // Call the Gmail API to fetch emails as notifications
    const { data } = await callGmailAPI("getEmailsAsNotifications", {
      authData,
      userConfiguration, // Include user configuration in the API call
      category, // Specify the email category to fetch
      isProduction, // Indicate the environment (e.g., production or development)
    });

    // Destructure the response to get emails and unread length
    const { emails, unreadLength } = data;

    // Dispatch a success action with the fetched emails and unread count
    dispatch(getEmailNotificationsSuccess(emails, unreadLength));
  } catch (error) {
    // Extract error message from the response, if available
    const message = error?.response?.data?.message;

    // Dispatch a failure action with the caught error
    dispatch(getEmailNotificationsFailure(error));
    console.log("caught an error", {
      error, // Log the complete error object
      message: message || "Error while getting emails", // Log the error message or a default message
    });
  } finally {
    // Dispatch an action to indicate the completion of the email notifications request
    dispatch(getEmailNotificationsRequestFinished());
  }
};

export const updateFilters = (dispatch, tab, filterBy) => {
  dispatch(
    setFilters({
      tab,
      filterBy,
    })
  );
};

export const handlePaginationChange = (
  page, // The current page number selected by the user
  tab, // The current tab (used to determine pagination behavior)
  setCurrentPage, // Function to update the current page state
  totalNotificationsPerPage, // The number of notifications to display per page
  total, // The total number of notifications available
  fetchData // Function to fetch new data when the page exceeds available notifications
) => {
  // Check if the active tab is 'communication'
  if (tab?.toLowerCase() === "critical") {
    // Directly set the current page
    setCurrentPage(page);
  } else {
    // Check if the new page exceeds the total notifications
    if (page * totalNotificationsPerPage > total) {
      // If the new page is beyond the total notifications, update the page and fetch new data
      setCurrentPage(page);
      fetchData();
    } else {
      // Otherwise, just update the current page
      setCurrentPage(page);
    }
  }
};

export const handleTabChange = async (
  title, // The title of the tab that was selected
  handleUpdateFilters, // Function to update the filters based on the selected tab
  getTabValue, // Function to get the value associated with the selected tab
  filterBy, // Current filter value
  getFilterByValue, // Function to get the filter value associated with the selected tab
  setCurrentPage, // Function to set the current page
  setType, // Function to set the type for notifications
  type // Current type of notifications
) => {
  // Check if the selected tab is "communication"
  if (title?.toLowerCase() === "communication") {
    // Update filters based on the selected tab
    handleUpdateFilters(
      getTabValue(title),
      filterBy && filterBy === title?.toLowerCase()
        ? null // If the filter is the same as the tab, clear it
        : getFilterByValue(title) // Otherwise, set it to the new tab's filter value
    );
  } else {
    // For other tabs, reset to the first page
    setCurrentPage(1);

    // Update filters based on the selected tab
    handleUpdateFilters(
      getTabValue(title),
      filterBy && filterBy === title?.toLowerCase()
        ? null // Clear filter if it's the same as the tab
        : getFilterByValue(title) // Set to the new tab's filter value
    );

    // Toggle the type based on whether the selected tab is the same as the current type
    setType(type === title ? null : title);
  }
};

export const handleFilterClick = async (
  title, // The title of the filter that was clicked
  setType, // Function to set the current type of filter
  type, // Current type of filter applied
  tab, // Current tab selected
  handleUpdateFilters, // Function to handle updating filters
  filterBy, // Current filter value
  setCurrentPage // Function to set the current page number
) => {
  // Toggle the filter type based on the clicked title
  setType(type === title ? null : title);

  // Check if the selected tab is "communication"
  if (tab?.toLowerCase() === "communication") {
    // Update filters for the communication tab
    handleUpdateFilters(
      "communication", // Setting the tab as "communication"
      filterBy?.toLowerCase() === getFilterByValue(title).toLowerCase()
        ? null // Clear the filter if it's the same as the clicked filter
        : getFilterByValue(title) // Otherwise, set the filter to the clicked filter's value
    );
  } else {
    // For other tabs, reset to the first page
    setCurrentPage(1);

    // Update filters for the selected tab
    handleUpdateFilters(
      tab, // Current tab
      type === title ? null : getFilterByValue(title) // Clear the filter if it's the same as the clicked filter
    );
  }
};
