import "./ChangeChartType.scss";
import { useState } from "react";
import { Popover, Radio, Tooltip, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChartIcon,
  CircleProgressBarIcon,
  DoughnutCharIcon,
  PieChartIcon,
  StatusProgressIcon,
  AreaChartIcon,
} from "../../../../../../../assets";
import { useChartCardsContext } from "../../../../ChartCardsContext";
import { updateChartPreferences, updateProgramFields } from "../../../../utils";

const ChangeChartType = ({ cardConfigs }) => {
  const { id, type, chartTitle, isPrivate = false } = cardConfigs;

  const dispatch = useDispatch();

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const [chartTypesPopover, setChartTypesPopover] = useState(false);

  const { chartCategory } = useChartCardsContext();

  const overviewsConfig = programFields?.find(
    ({ fieldName }) => fieldName === "Overviews Configuration"
  );

  const overviewCards =
    overviewsConfig.fieldOptions.overviewCards[chartCategory];
  const { preferences } = allPreferences;
  const myCardPrefs = preferences?.OverviewCards?.onlyMyCards?.[chartCategory];

  const handleChangeType = async (type) => {
    message.loading("Changing chart type...");
    setChartTypesPopover(false);

    if (!!isPrivate) {
      const updatedPreferences = {
        ...preferences,
        OverviewCards: {
          ...(preferences?.OverviewCards || {}),
          onlyMyCards: {
            ...(preferences?.OverviewCards?.onlyMyCards || {}),
            [chartCategory]: myCardPrefs.map(({ isPrivate, ...card }) =>
              card.id === id ? { ...card, type } : card
            ),
          },
        },
      };

      await updateChartPreferences(
        updatedPreferences,
        allPreferences,
        dispatch
      );
    } else {
      const updatedFieldOptions = {
        ...overviewsConfig?.fieldOptions,
        overviewCards: {
          ...overviewsConfig?.fieldOptions?.overviewCards,
          [chartCategory]: overviewCards.map((card) =>
            card.id === id ? { ...card, type } : card
          ),
        },
      };

      await updateProgramFields(
        overviewsConfig?.fieldId,
        updatedFieldOptions,
        dispatch
      );
    }

    message.success("Chart Type Changed Successfully!");
  };

  return (
    <Popover
      overlayClassName={`chart-cards-popover ${
        isDarkMode ? "chart-cards-popover-content-dark" : ""
      }`}
      placement="right"
      trigger="click"
      open={chartTypesPopover}
      onOpenChange={(val) => {
        setChartTypesPopover(val);
      }}
      content={
        <span className="chart-types-wrapper">
          <label>Chart Type for {chartTitle}</label>

          <Radio.Group
            className="radio-charts"
            onChange={(e) => {
              handleChangeType(e.target.value);
            }}
            defaultValue={type}
          >
            <Tooltip title="Area Chart">
              <Radio value="area">
                <AreaChartIcon />
              </Radio>
            </Tooltip>
            <Tooltip title="Polar Chart">
              <Radio value="polar">
                <PieChartIcon />
              </Radio>
            </Tooltip>
            <Tooltip title="Bar Chart">
              <Radio value="bar">
                <BarChartIcon />
              </Radio>
            </Tooltip>
            <Tooltip title="Progress Chart">
              <Radio value="progress">
                <CircleProgressBarIcon />
              </Radio>
            </Tooltip>
            <Tooltip title="Doughnut Chart">
              <Radio value="doughnut">
                <DoughnutCharIcon />
              </Radio>
            </Tooltip>
            <Tooltip title="Horizontal Progress Chart">
              <Radio value="horizontal-progress">
                <StatusProgressIcon />
              </Radio>
            </Tooltip>
          </Radio.Group>
        </span>
      }
    >
      <span className="ActionElementThreeDots">Change Chart Type</span>
    </Popover>
  );
};

export default ChangeChartType;
