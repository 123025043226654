import { Fragment } from "react";
import { Tooltip, Checkbox } from "antd";

import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { JobTypeEditor, DriverEditor, NotesEditor } from "../components";
import { MondayButton } from "src/components/commonComponents";
import { OpenLinkIcon } from "src/assets";
import { TrashIcon } from "src/components/SidebarPages/Communication/assets";

const columnDefs = [
  {
    headerName: "Row #",
    cellRenderer({ node }) {
      if (node?.group) {
        return "";
      }
      return node.rowIndex;
    },
    pdfExportOptions: {
      skipColumn: true,
    },
  },
  {
    headerName: "Date",
    field: "date",
    filter: "agDateColumnFilter",
    cellRenderer({ value, node }) {
      if (node?.group && !value) {
        return "";
      }

      return dayjsNY(value).format("MM/DD/YYYY HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return dayjsNY(value).format("MM/DD/YYYY HH:mm");
      },
    },
  },
  {
    headerName: "Serial Number",
    field: "deviceSerialNumber",
  },
  {
    headerName: "Vehicle",
    field: "fleetName",
  },
  {
    headerName: "Event Type",
    field: "type",
  },
  {
    headerName: "Location",
    field: "location",
  },
  {
    headerName: "From Time",
    field: "fromTime",
    filter: "agDateColumnFilter",
    cellRenderer({ value, node }) {
      if (node?.group && !value) {
        return "";
      }

      if (!value) {
        return "--";
      }
      return dayjsNY(value).format("HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (!value) {
          return "--";
        }
        return dayjsNY(value).format("HH:mm");
      },
    },
  },
  {
    headerName: "From Location",
    field: "fromLocation",
  },
  {
    headerName: "To Time",
    field: "toTime",
    filter: "agDateColumnFilter",
    cellRenderer({ value, node }) {
      if (node?.group && !value) {
        return "";
      }

      if (!value) {
        return "--";
      }
      return dayjsNY(value).format("HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (!value) {
          return "--";
        }
        return dayjsNY(value).format("HH:mm");
      },
    },
  },
  {
    headerName: "To Location",
    field: "toLocation",
  },
  {
    headerName: "Route Type",
    field: "routeType",
    cellEditor: JobTypeEditor,
    minWidth: 200,
    editable({ context }) {
      return context?.isWritable;
    },
  },
  {
    headerName: "From Dispatch",
    field: "fromDispatch",
    cellClass: "checkbox-font",
    cellRenderer({ value, node, ...rest }) {
      if (node?.group) {
        if (value === "true") {
          return "From Dispatch";
        } else {
          if (value === undefined) {
            return null;
          }

          return "Not From Dispatch";
        }
      }

      return (
        <Checkbox
          defaultChecked={value}
          onClick={(e) => {
            e.preventDefault();
            e.target.checked = value;
          }}
        />
      );
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value || value === "true") {
          return "Yes";
        }
        return "No";
      },
    },
  },
  {
    headerName: "Driver",
    field: "driver",
    editable({ context }) {
      return context?.isWritable;
    },
    minWidth: 200,
    cellEditor: DriverEditor,
    cellRenderer({ data }) {
      return data?.driver?.name || "";
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        return value?.driver?.name || "";
      },
    },
  },
  {
    headerName: "Clock In",
    field: "clockIn",
    filter: "agDateColumnFilter",
    cellRenderer({ value, node }) {
      if (node?.group && !value) {
        return "";
      }

      if (!value) {
        return "--";
      }
      return dayjsNY(value).format("MM/DD/YYYY HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (!value) {
          return "--";
        }
        return dayjsNY(value).format("MM/DD/YYYY HH:mm");
      },
    },
  },
  {
    headerName: "Clock Out",
    field: "clockOut",
    filter: "agDateColumnFilter",
    cellRenderer({ value, node }) {
      if (node?.group && !value) {
        return "";
      }

      if (!value) {
        return "--";
      }
      return dayjsNY(value).format("MM/DD/YYYY HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (!value) {
          return "--";
        }
        return dayjsNY(value).format("MM/DD/YYYY HH:mm");
      },
    },
  },
  {
    headerName: "Notes",
    field: "notes",
    editable({ context }) {
      return context?.isWritable;
    },
    cellEditor: NotesEditor,
    minWidth: 200,
    cellRenderer: "leftAlignRenderer",
  },
  {
    headerName: "Actions",
    cellRenderer({ context, data, node }) {
      if (node?.group) {
        return "";
      }

      return (
        <Fragment>
          <Tooltip title="Go to audit">
            <MondayButton
              Icon={<OpenLinkIcon />}
              className="mondayButtonBlue"
              onClick={() => {
                if (context.onVehicleClick) {
                  context.onVehicleClick(data);
                }
              }}
            >
              {""}
            </MondayButton>
          </Tooltip>
          {context.isWritable && (
            <Tooltip title="Discard Activity">
              <MondayButton
                {...{
                  Icon: <TrashIcon />,
                  className: "mondayButtonRed",
                  onClick() {
                    if (context.onDiscard) {
                      context.onDiscard(data);
                    }
                  },
                }}
              >
                {""}
              </MondayButton>
            </Tooltip>
          )}
        </Fragment>
      );
    },
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
    pdfExportOptions: {
      skipColumn: true,
    },
  },
];

export const columnKeys = [
  "date",
  "deviceSerialNumber",
  "fleetName",
  "type",
  "location",
  "fromTime",
  "fromLocation",
  "toTime",
  "toLocation",
  "routeType",
  "fromDispatch",
  "driver",
  "clockIn",
  "clockOut",
  "notes",
];

export default columnDefs;
