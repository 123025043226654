import { Form, Tour } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  createCategory,
  deleteCategory,
  removeFavoriteCategory,
  setFavoriteCategory,
  updateCategory,
} from "../../actions/wrapperFunctions";
import { views } from "../../reportsConfigurationData";
import { useReportsConfigContext } from "../../ReportsConfigContext";
import { filterPeopleList } from "../../../../utils/filterPeopleList";
import { useModalOnceObject } from "../../../../../../hooks/useModalOnce";
import { NewCategoryPanel, NewReportModal } from "../../../components";
import { getChangedData } from "../../../../Accounting/components/utilities";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { findTutorialSteps } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import "./CategoriesView.scss";
import { useEditLogs } from "../../../../../../hooks";
import AgGridPageInput from "../../../../BasePage/components/AgGridPageInput/AgGridPageInput";
import { AgGridReact } from "ag-grid-react";
import { LoadableComp } from "../../../../XComponents";
import {
  ExportOrEmailDropdown,
  MondayButton,
} from "../../../../../commonComponents";
import {
  CloseOutlined,
  InfoCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { InputComponent } from "../../../../Fleet/components";
import useProgress from "../../../../../../hooks/useProgress";
import { getExcelColumnKeys } from "../../../../../../utils/getExcelColumnKeys";
import {
  columnDefs,
  excelColumnsConfig,
  formatCellValueHandler,
} from "../AgGridData";
import { Plus } from "../../../../DynamicView/components/FilterView/FilterIcons";
import CustomHeader from "../../../../Projects/Accounting/Applications/ApplicationsTab/ListOfApplications/GridList/CustomHeader";
import { FilterIcon } from "../../../../BasePage/src";
import FiltersModal from "../../../../../commonComponents/RowDataGridModal/components/FiltersModal";
import { keysForDates } from "../../../../../../helpers/constants";
import { camelCaseToNormalText } from "../../../../../commonComponents/RowDataGridModal/components/utils";
import ChartCards from "../../../../../commonComponents/ChartCards/ChartCards";
import { uniq } from "lodash";

/**
 *
 ** Default view when opening "Reports Configuration"
 *  -contains all created category
 *  -contains the category creation panel etc.
 *
 */

const CategoriesView = ({ onCloneExistingReport }) => {
  const {
    allCategories = [],
    reportsCategorization,
    curryDispatch,
    activeView,
    setActiveView,
    setSelectedReport,
    setSelectedCategoryName,
  } = useReportsConfigContext();

  const { userConfiguration = {} } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);

  //form of "New Category Panel"
  const [form] = Form.useForm();
  const [searchReport, setSearchReport] = useState([]);
  const [activeFilter, setActiveFilter] = useState(false);
  const [createdCategory, setCreatedCategory] = useState(null);
  const [tourOpen, setTourOpen] = useModalOnceObject("reportsSettingsTour");
  const [newCategoryPanel, setNewCategoryPanel] = useState(false);

  const [reportModalVisible, setReportModalVisible] = useState();

  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState();
  const [appliedFilters, setAppliedFilters] = useState();
  const [showFilterModal, setShowFilterModal] = useState(false);

  const gridRef = useRef();

  const hasFilters = appliedFilters && Object.keys(appliedFilters).length;

  const chartData = allCategories
    .map((category) =>
      category.reportsAvailable.map((cat) => ({
        createdAt: cat.createdAt,
        reportName: cat.reportName,
        categoryName: cat.categoryName,
      }))
    )
    .flatMap((item) => item);

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    setColumnApi(param.columnApi);
  });

  const getRowId = useCallback((param) => {
    return param.data.tableId;
  }, []);

  const defaultColumnDefs = useMemo(() => {
    return {
      resizable: true,
      enablePivot: true,
      enableColResize: true,
      enableRowGroup: false,
      editable: false,
      sortable: true,
      flex: 1,
      filter: true,
    };
  }, []);

  const statusBar = {
    statusPanels: [
      {
        statusPanel: AgGridPageInput,
        key: "paginationInput",
        align: "right",
      },
    ],
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");

  const { identityId } = userConfiguration;
  const [users, setUsers] = useState([]);

  const { saveAddedLogs } = useEditLogs();

  const { startProgress } = useProgress();

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  // new log object that will store the changes
  let newEditLog = {
    recordId: "",
    recordName: "",
    topic: "",
    actionType: "",
    category: "Reports Configuration",
    currentData: {},
    label: "",
    previousData: {},
    updatedKeys: [],
  };

  /**
   * Handles making a category favorite or removing it from favorites.
   * triggered when clicking the star icon on the category card
   *
   * @param {Boolean} favorite true - make favorite, false - remove from favorites
   * @param {Object} category Object of the category
   */
  //region onFavoriteClick
  const onFavoriteClick = (favorite, category) => {
    function callbackFn(updatedValues) {
      gridApi.applyTransaction({
        update: [{ ...category, ...updatedValues }],
      });
    }
    curryDispatch(
      favorite
        ? setFavoriteCategory(category, identityId, callbackFn)
        : removeFavoriteCategory(category, identityId, callbackFn)
    );
  };

  /**
   * Sets the selected category
   * Switches to the Category Configuration View
   *
   * @param {String} categoryName
   */
  //region onOpenConfig
  function onOpenConfiguration(categoryName) {
    setSelectedCategoryName(categoryName);
    setActiveView(views.CATEGORY_CONFIG);
  }

  //Creates a new category (validates fields too)
  //region onCreateConfig
  const onCreateConfiguration = () => {
    const { categoryName, department } = form.getFieldsValue();

    form.validateFields().then(() => {
      curryDispatch(
        createCategory({
          categoryName,
          department,
        })
      );
      form.resetFields();
      // get changes
      let result = getChangedData({ categoryName, department }, {});
      // update new log object with current changes
      newEditLog.recordId = uuidv4();
      newEditLog.recordName = categoryName;
      newEditLog.actionType = "create";
      newEditLog.currentData = { [categoryName]: result.curr };
      newEditLog.previousData = { [categoryName]: result.prev };
      newEditLog.updatedKeys.push({ categoryName, collapsable: true });

      // update DB with the new log
      saveAddedLogs(newEditLog);
    });
    setCreatedCategory(categoryName);
    broadcastNotification(
      "38",
      "onCreateReportCategory",
      [
        {
          common: user,
        },
        {
          userName: user,
          currentUser: authenticatedUser?.sub,
        },
      ],
      ""
    );
    setNewCategoryPanel(false);
  };

  /**
   * Deletes a category and all of its reports
   *
   * @param {String} categoryName Category name acts as an ID
   */
  //region onDeleteConfig
  const onDeleteConfiguration = (categoryName) => {
    // deletes category from DB
    curryDispatch(deleteCategory(categoryName));
    // find configuration to delete
    const configurationToDelete = allCategories.find(
      (category) => category.categoryName === categoryName
    );
    // get changes
    let result = getChangedData({}, configurationToDelete);
    // update new logs object with current changes
    newEditLog.recordId = uuidv4();
    newEditLog.recordName = categoryName;
    newEditLog.actionType = "delete";
    newEditLog.currentData = { [categoryName]: result.curr };
    newEditLog.previousData = { [categoryName]: result.prev };
    newEditLog.updatedKeys.push(categoryName);

    // update DB with the new log
    saveAddedLogs(newEditLog);
  };

  const onSearchReport = (e) => {
    let substring = e.target.value;
    let filteredData = allCategories?.filter((item) =>
      item?.categoryName.toLowerCase().includes(substring.toLowerCase())
    );
    setSearchReport(filteredData);
    substring !== "" ? setActiveFilter(true) : setActiveFilter(false);
  };

  useEffect(() => {
    if (activeView === "CategoriesView" && category)
      onOpenConfiguration(category);
  }, [activeView, category]);

  useEffect(() => {
    setUsers(filterPeopleList(userConfiguration.allUsers?.Items));
  }, [userConfiguration]);

  useEffect(() => {
    const loadReportFromLocalStorage = () => {
      try {
        const reportFromLS = localStorage.getItem("newReport");

        // check if there is a new report in local storage
        // if there is a new report, navigate to Report Configuration
        if (reportFromLS) {
          const report = JSON.parse(reportFromLS);

          onOpenConfiguration(report.categoryName);
          setSelectedReport(report);
          setActiveView(views.REPORT_CONFIG);
        }
      } catch (error) {
        console.error("Error parsing report from localStorage:", error);
      }
    };

    loadReportFromLocalStorage();
  }, []);

  function stepsMapHelper(title) {
    const refs = {
      Search: document.querySelector(".searchInput"),
      "Show Logs": document.querySelector("#logsButton"),
      "New Category": document.querySelector("#newServiceButton"),
      "Report Categories": document.querySelector("#firstNormalModeContainer"),
      "Clear Fields": document.querySelector(".clearBtn"),
    };
    return refs[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const dbSteps = mapRefs(findTutorialSteps("reportsSettings", programFields));

  const clearFilters = () => {
    gridApi?.setFilterModel(null);
    setAppliedFilters();
  };

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataASExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.categoryName;

        if (headerName === "Category") {
          return (
            value?.map(({ categoryName }) => categoryName).join("- ") || ""
          );
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
    // newEditLog("Download", {}, {}, [], "Downloaded Excel");
  };

  //region updateState
  function updateState(data) {
    const changedCategory = {
      ...data,
      reportState:
        typeof data?.reportState === "boolean" ? !data.reportState : false,
    };

    gridApi.applyTransaction({
      update: [changedCategory],
    });
    curryDispatch(
      updateCategory(data?.categoryName, {
        reportState: changedCategory.reportState,
      })
    );
  }

  //region colDefs
  const columnDefsMemo = useMemo(() => {
    return columnDefs({
      onOpenConfiguration,
      updateState,
      // onCloneExistingReport,
      setReportModalVisible,
      onDeleteConfiguration,
      isDarkMode,
      setSelectedCategoryName,
      onFavoriteClick,
      identityId: userConfiguration.identityId,
    });
  }, [
    onOpenConfiguration,
    updateState,
    setReportModalVisible,
    onDeleteConfiguration,
    isDarkMode,
    setSelectedCategoryName,
    onFavoriteClick,
    JSON.stringify(allCategories),
  ]);

  return (
    <div
      className={`reportsCategoriesBasePage ${
        isDarkMode && "reportsCategoriesBasePageDark"
      }`}
    >
      <LoadableComp loading={false}>
        <div className="reportsCategories-view-layout">
          <div className="reportsCategories-filters-header">
            <div className="reportsCategories-filter-section">
              <MondayButton
                className="mondayButtonBlue categories-filter"
                Icon={<FilterIcon />}
                onClick={() => {
                  setShowFilterModal(true);
                }}
                tooltipKey="openFilters"
              >
                Filters
              </MondayButton>
              {!!hasFilters && (
                <MondayButton
                  className="mondayButtonRed"
                  Icon={<CloseOutlined />}
                  onClick={clearFilters}
                  tooltipKey="clearFilters"
                >
                  Clear Filters
                </MondayButton>
              )}

              <InputComponent
                {...{
                  className: "searchReportCategories",
                  placeholder: "Search here...",
                  allowClear: true,
                  addonBefore: <SearchOutlined />,
                  onChange: (event) => {
                    gridApi.setQuickFilter(event.target.value);
                  },
                }}
              />
            </div>
            <div className="reportCategories-action-section">
              <ExportOrEmailDropdown
                key="export-email-dropdown"
                {...{
                  rowData: allCategories,
                  title: "Reports Configuration",
                  gridApi,
                  gridColumnApi: columnApi,
                  ...(formatCellValueHandler ? { formatCellValueHandler } : {}),
                  ...(exportGridToExcel ? { exportGridToExcel } : {}),
                }}
              />
              <MondayButton
                className="newReportConfig-button"
                Icon={<Plus />}
                onClick={() => {
                  setNewCategoryPanel(true);
                }}
              >
                Create Configuration
              </MondayButton>
            </div>
          </div>

          <div className="reportCategories-layout-container">
            <CustomHeader
              {...{
                title: "Reports",
              }}
            />

            <div
              className={`reportCategories-wrapper ${
                isDarkMode && "reportCategories-wrapperDark"
              }`}
            >
              <div className="reportCategories-info">
                <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
                <p className="reportCategories-text">
                  Here you can manage various types of reports.
                </p>
              </div>
              <div
                className={`reportConfig-grid ${
                  isDarkMode
                    ? "dark-ag-theme ag-theme-alpine-dark"
                    : "light-ag-theme ag-theme-alpine"
                }`}
              >
                <AgGridReact
                  {...{
                    gridApi: gridApi,
                    rowData: allCategories,
                    columnDefs: columnDefsMemo,
                    onGridReady: onGridReady,
                    defaultColDef: defaultColumnDefs,
                    ref: gridRef,
                    defaultExcelExportParams: {
                      columnKeys: excelColumnsConfig,
                      fileName: "Reports",
                    },
                    rowGroupPanelShow: "never",
                    rowSelection: "multiple",
                    pagination: true,
                    paginationPageSize: 10,
                    paginationAutoPageSize: true,
                    animateRows: true,
                    statusBar,
                    getRowId,
                    sideBar: {
                      toolPanels: [
                        {
                          id: "columns",
                          labelDefault: "Columns",
                          labelKey: "columns",
                          iconKey: "columns",
                          toolPanel: "agColumnsToolPanel",
                        },
                        {
                          id: "filters",
                          labelDefault: "Filters",
                          labelKey: "filters",
                          iconKey: "filter",
                          toolPanel: "agFiltersToolPanel",
                        },
                      ],
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <ChartCards
            {...{
              chartDatas: chartData,
              chartCategory: "Settings Reports",
              customKeys: ["categoryName"],
              addBtn: false,
              filterPeriod: false,
              filterDepartment: false,
            }}
          />
        </div>
        {newCategoryPanel && (
          <Form {...{ form }}>
            <NewCategoryPanel
              {...{
                form,
                allCategories,
                reportsCategorization,
                onCreateConfiguration,
                isDarkMode,
                newCategoryPanel,
                setNewCategoryPanel,
              }}
            />
          </Form>
        )}
        {reportModalVisible && (
          <NewReportModal
            {...{
              visible: !!reportModalVisible,
              setVisible: setReportModalVisible,
              cloning: true,
              newEditLog,
              isDarkMode,
              categoryName: reportModalVisible?.categoryName || [],
              selectedReportProp: reportModalVisible,
            }}
          />
        )}
        {tourOpen && (
          <Tour
            open={tourOpen}
            onClose={() => setTourOpen(false)}
            steps={dbSteps}
            mask={{ color: "#2a2b3a71" }}
          />
        )}
        {showFilterModal && (
          <FiltersModal
            {...{
              title: "Reports",
              showFilterModal,
              setShowFilterModal,
              appliedFilters,
              setAppliedFilters: (filters) => {
                filters === "clearFilters"
                  ? clearFilters()
                  : setAppliedFilters(filters);
              },
              gridApi,
              filtersObject: columnDefsMemo
                .sort((a, b) =>
                  keysForDates.includes(a.field)
                    ? -1
                    : keysForDates.includes(b.field)
                    ? 1
                    : 0
                )
                .filter((item) => item.hasOwnProperty("field"))
                .map(({ field }, index) => ({
                  globalKeyId: index,
                  createdAt: Date.now(),
                  createdBy: userConfiguration.nameOfUser,
                  dataType: keysForDates.includes(field) ? "date" : "string",
                  style: "",
                  label: camelCaseToNormalText(field),
                  type: keysForDates.includes(field)
                    ? "RangePicker"
                    : "Dropdown",
                  key: field,
                })),
              rowData: allCategories.map((res) => ({
                ...res,
              })),
            }}
          />
        )}
      </LoadableComp>
    </div>
  );
};

export default CategoriesView;
