import { Button, Modal, Descriptions, Popover } from "antd";
import React, { useMemo, useState } from "react";
import moment from "moment";
import { extractDataHandler } from "../../ListOfRentals/Componets/ListOfServices/listOfServicesFunctions";
import { appliedAmountGenerator } from "../../../RentalsView/components/Header/Components/ControlPanel/ControlPanelFunctions";
import { totalitiesTotalTaxGen } from "../../../RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/ServiceRentHeader/utils";
import AnimatedNumber from "react-animated-number";
import { formatCurrency } from "../../../../../../utils";
import { useSelector } from "react-redux";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import dayjs from "dayjs";

function RentalDescription({ params }) {
  const [active, setActive] = useState(false);
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );

  const closeModal = () => setActive(false);
  const openModal = async () => {
    setActive(true);
  };
  let data = extractDataHandler({ params }) || [];
  const structuredDataArr = data
    ?.filter((el) => !!el?.startDate && !!el?.endDate)
    ?.sort((a, b) => a?.startDate - b?.startDate);

  console.log("structuredDataArr", params);

  return (
    <div>
      <button className="linkButton" onClick={openModal}>
        <ProcessPreviewData {...{ structuredDataArr }} />
      </button>
      {/* <Modal width={700} visible={active} onCancel={closeModal} destroyOnClose>
				<RentalsDetails params={active ? params : null} />
			</Modal> */}
    </div>
  );
}

export default RentalDescription;

const RentalsDetails = ({ params }) => {
  let data = extractDataHandler({ params });

  return (
    <div>
      {params?.data?.services?.map((el) => {
        let particulate = data?.find((d) => d?.label === el?.label) || false;
        let fakeRentals = { ...params?.data };
        fakeRentals.services = fakeRentals?.services?.filter(
          (s) => s?.label === el?.label
        );
        const totalities = totalitiesTotalTaxGen({
          fakeRentals,
        });

        // const appliedToRows = appliedRentalGenerator({
        // 	fakeRentals: params?.data,
        // 	elevationData,
        // 	selectedService,
        // });
        // const totalitiesAndTax = totalitiesTaxGen({
        // 	fakeRentals: params?.data,
        // 	elevationData,
        // 	selectedService,
        // });
        return (
          <div>
            <Descriptions title={el?.label}>
              <Descriptions.Item label="Start Date">
                {moment(particulate?.startDate || null).format("DD/MM/YYYY") !==
                "Invalid date" ? (
                  moment(particulate?.startDate || null).format("DD/MM/YYYY")
                ) : (
                  <strong style={{ color: "red" }}>N/A</strong>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="End Date">
                {moment(particulate?.endDate || null).format("DD/MM/YYYY") !==
                "Invalid date" ? (
                  moment(particulate?.endDate || null).format("DD/MM/YYYY")
                ) : (
                  <strong style={{ color: "red" }}>N/A</strong>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Applied Rent">
                <AnimatedNumber
                  value={totalities?.appliedRent}
                  formatValue={(value) => formatCurrency(value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Retainage">
                {!!el?.retainage ? el?.retainage + "%" : 0}
              </Descriptions.Item>
              <Descriptions.Item label="Total Tax">
                <AnimatedNumber
                  value={totalities?.totalTax}
                  formatValue={(value) => formatCurrency(value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Total Price">
                <AnimatedNumber
                  value={totalities?.totalPrice}
                  formatValue={(value) => formatCurrency(value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        );
      })}
    </div>
  );
};

export const getMinStartAndMaxEnd = (
  structuredDataArr = [],
  proposedTypeOfWork = [],
  res = ""
) => {
  if (!structuredDataArr.length)
    return { minStart: undefined, maxEnd: undefined, res };

  let minStart = structuredDataArr[0]?.startDate;
  let maxEnd = structuredDataArr[0]?.endDate;

  const idCodes = structuredDataArr.map((el) => {
    const idCode =
      proposedTypeOfWork?.find((p) => p?.workName === el?.label)?.idCode || "";
    // Update minStart and maxEnd
    if (el?.startDate && el?.startDate < minStart) minStart = el.startDate;
    if (el?.endDate && el?.endDate > maxEnd) maxEnd = el.endDate;

    return idCode;
  });

  res = idCodes.filter(Boolean).join(", ");
  return { minStart, maxEnd, res };
};

export const ProcessPreviewData = ({
  structuredDataArr,
  showTooltip = true,
}) => {
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );
  console.log("ProcessPreviewData", structuredDataArr);
  const test = useMemo(() => {
    if (Array.isArray(structuredDataArr)) {
      let {
        minStart,
        maxEnd = 0,
        res,
      } = getMinStartAndMaxEnd(structuredDataArr, proposedTypeOfWork);
      if (!!res)
        res += ` (${dayjs(minStart)
          .add(8, "hours")
          .format("MM/DD/YYYY")} to ${dayjs(maxEnd)
          .add(8, "hours")
          .format("MM/DD/YYYY")})`;

      return res;
    }
    return false;
  }, [proposedTypeOfWork, structuredDataArr]);

  return showTooltip ? (
    <Popover content={test} title="Summary for this rental">
      {!!test ? test : "N/A"}
    </Popover>
  ) : !!test ? (
    test
  ) : (
    "N/A"
  );
};
