import { useContext } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { FleetsLiveContext } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { setHourMinute } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { MondayButton } from "src/components/commonComponents";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "../../../../../AuditController/components/TripsPicker/TripsPicker.scss";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

function ChangeActualEditor({ onConfirm = () => {}, alertDateTime }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { selectedDate } = useContext(FleetsLiveContext);

  const [form] = Form.useForm();

  function calcStartEnd(formValues) {
    const { selectedDate, selectedTime, reason } = formValues;
    onConfirm({
      date: setHourMinute(selectedDate, selectedTime).valueOf(),
      reason,
    });
  }

  return (
    <Form
      form={form}
      className={`trips-picker-form ${
        isDarkMode ? "trips-picker-form-dark" : ""
      }`}
    >
      <InputComponent
        {...{
          type: "datePicker",
          formItemName: "selectedDate",
          label: "Date",
          format: "MM/DD/YYYY",
          showTime: false,
          required: true,
          style: { width: "200px" },
          initialValue: dayjsNY(alertDateTime || selectedDate),
          dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
        }}
      />
      <InputComponent
        {...{
          type: "timePicker",
          formItemName: "selectedTime",
          label: "Time",
          dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
          required: true,
          allowClear: false,
        }}
      />
      <div className="time-select-section">
        <InputComponent
          {...{
            type: "textarea",
            formItemName: "reason",
            label: "Reason",
            required: true,
            style: { minHeight: "54px" },
          }}
        />
      </div>
      <div className="buttons-section">
        <MondayButton
          Icon={<TickIcon />}
          onClick={() => {
            form.validateFields().then(calcStartEnd);
          }}
        >
          Confirm
        </MondayButton>
      </div>
    </Form>
  );
}

export default ChangeActualEditor;
