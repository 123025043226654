import { Fragment } from "react";
import { TreeNode } from "../TreeNode";
import styles from "./TreeGraph.module.scss";
import { useSelector } from "react-redux";
import { hasHTMLTags } from "../../../../../utils/hasHTMLTags";

const TreeGraph = ({ logs, actionType }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const updatedLogs = logs.filter(
    (log) => !log?.key?.includes("Id") && log?.key?.toLowerCase() !== "id"
  );

  return (
    <div
      id="tree-graph-container"
      className={
        styles[
          isDarkMode ? "tree-graph-container-dark" : "tree-graph-container"
        ]
      }
      style={{ padding: "10px" }}
    >
      {updatedLogs.map((log, index) => (
        <Fragment key={index}>
          <TreeNode
            node={log}
            actionType={actionType}
            index={index}
            styledDescription={
              hasHTMLTags(log.previousValue) && hasHTMLTags(log.updatedValue)
            }
          />
          {log.children && (
            <TreeGraph logs={log.children} actionType={actionType} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default TreeGraph;
