import "./ChartCard.scss";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Result, Spin } from "antd";
import { FilterOutlined, LoadingOutlined } from "@ant-design/icons";
import { useChartCardsContext } from "../../ChartCardsContext";
import { FormCard } from "../../../../SidebarPages/Fleet/components";
import { filterDatas, hasValidValue } from "../../utils";
import { camelCaseToNormalText } from "../../../RowDataGridModal/components/utils";
import ChartTitle from "../ChartTitle/ChartTitle";
import DoughnutChart from "../ChartTypes/DoughnutChart/DoughnutChart";
import PolarChart from "../ChartTypes/PolarChart/PolarChart";
import AreaChart from "../ChartTypes/AreaChart/AreaChart";
import BarChart from "../ChartTypes/BarChart/BarChart";
import HorizontalProgressChart from "../ChartTypes/HorizontalProgressChart/HorizontalProgressChart";
import { useDataStatuses } from "../../../../../hooks";
import { formatCurrency } from "../../../../SidebarPages/utils";
import StackedBar from "../../../../pages/Settings/settingsComponents/MappingConfigurations/components/StackedBar/StackedBar";

const chartComponents = {
  doughnut: DoughnutChart,
  bar: BarChart,
  progress: AreaChart,
  polar: PolarChart,
  area: AreaChart,
  stackedBar: StackedBar,
  "horizontal-progress": HorizontalProgressChart,
};

const getRowFilter = (cardConfigs) => {
  return cardConfigs?.hasOwnProperty("isVideoTutorial") &&
    (cardConfigs.rowFilterKey === "favourites" ||
      cardConfigs.rowFilterKey === "partialWatched")
    ? "videoName"
    : cardConfigs.rowFilterKey;
};

const dataForStackedBar = (filteredData) => {
  if (Array.isArray(filteredData)) {
    const isValid = filteredData.every(
      (item) =>
        item &&
        typeof item.tableName === "string" &&
        Array.isArray(item.keys) &&
        item.keys.every((key) => typeof key === "string")
    );

    return isValid ? filteredData : [];
  }
  return [];
};

const ChartCard = ({ cardConfigs, statusColors = [] }) => {
  const { id, width = "small", permanentFilters = [] } = cardConfigs;

  const rowFilterKey = getRowFilter(cardConfigs);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [hasBlur, setHasBlur] = useState(false);

  const { allChartDatas, chartDatas, generalFilters, tutorialLogs } =
    useChartCardsContext();

  const cardFilters = [...permanentFilters, ...(generalFilters?.[id] || [])];

  const getFavouriteLogs = (type) => {
    const logs = tutorialLogs.filter(
      (log) =>
        log.actionType ===
        (type === "favourites" ? "Favourite" : "Partial Watched")
    );

    return filterDatas(logs, cardFilters).filter(({ [rowFilterKey]: value }) =>
      hasValidValue(value)
    );
  };

  const getFilteredData = () => {
    const datas = cardFilters?.length > 0 ? allChartDatas : chartDatas;

    if (!cardConfigs?.hasOwnProperty("isVideoTutorial")) {
      return filterDatas(datas, cardFilters).filter(
        ({ [rowFilterKey]: value }) => hasValidValue(value)
      );
    }

    if (["favourites", "partialWatched"].includes(cardConfigs.rowFilterKey)) {
      return getFavouriteLogs(cardConfigs.rowFilterKey);
    }

    return filterDatas(tutorialLogs, cardFilters).filter(
      ({ [rowFilterKey]: value }) => hasValidValue(value)
    );
  };

  const filteredData = getFilteredData();

  const uniqueLabels = [
    ...new Set(filteredData.flatMap(({ [rowFilterKey]: value }) => value)),
  ];

  const dataStatus = useDataStatuses(chartDatas);

  return (
    <div
      className={`chart-card-content ${width}-card${hasBlur ? " blurred" : ""}${
        isDarkMode ? " dark-mode" : ""
      }`}
    >
      <FormCard
        title={
          <ChartTitle
            {...{
              cardConfigs,
              hasBlur,
              setHasBlur,
              cardFilters,
              generalFilters,
            }}
          />
        }
      >
        {uniqueLabels.length > 100 ? (
          <Result
            {...{
              style: { margin: "auto" },
              icon: <FilterOutlined />,
              title: `Apply filters to display ${camelCaseToNormalText(
                rowFilterKey
              )}'s!`,
              subTitle:
                "You need to filter first because there are too many datas to display in chart!",
            }}
          />
        ) : uniqueLabels.length ? (
          <ChartContent {...{ cardConfigs, statusColors, filteredData }} />
        ) : (
          <Result
            {...{
              icon: dataStatus === "pending" && (
                <Spin
                  indicator={
                    <LoadingOutlined style={{ fontSize: "72px" }} spin />
                  }
                />
              ),
              style: { margin: "auto" },
              status: dataStatus === "pending" ? "" : "warning",
              title: (
                <span style={{ color: isDarkMode ? "#F2F2F2" : "#000" }}>
                  {dataStatus === "pending"
                    ? "Loading chart please wait..."
                    : "No records to display for this card!"}
                </span>
              ),
              ...(cardFilters.length && { subTitle: "Try clearing filters" }),
            }}
          />
        )}
      </FormCard>
    </div>
  );
};

export default ChartCard;

const ChartContent = ({ cardConfigs, statusColors = [], filteredData }) => {
  const { type } = cardConfigs;
  const rowFilterKey = getRowFilter(cardConfigs);

  const navigate = useNavigate();

  const { chartCategory, customOnClick } = useChartCardsContext();

  const ChartComponent = chartComponents[type];

  //is more faster than using uniqueLabels
  const { labels, data } = useMemo(() => {
    // Special case when we have charts for Projects Estimation Amount
    // Since the logic to show projectName and dhe price isn't supported by the dynamic charts we had to manually map the data
    if (cardConfigs?.chartTitle === "Projects Estimation Amount") {
      return filteredData.reduce(
        (acc, item) => {
          acc.labels.push(item?.projectName);
          acc.data.push(formatCurrency(item?.price || 0));
          return acc;
        },
        { labels: [], data: [] }
      );
    }

    if (type === "stackedBar") {
      return {
        labels: [],
        data: dataForStackedBar(filteredData),
      };
    }

    const dataCounted = filteredData.reduce(
      (acc, { [rowFilterKey]: label }) => {
        (Array.isArray(label) ? label : [label]).forEach((item) => {
          acc[item] = (acc[item] || 0) + 1;
        });
        return acc;
      },
      {}
    );

    const labels =
      chartCategory === "National Holidays"
        ? Object.keys(dataCounted)
        : Object.keys(dataCounted).sort();
    return { labels, data: labels.map((key) => dataCounted[key]) };
  }, [JSON.stringify(filteredData)]);

  const chartData = {
    label: chartCategory,
    labels,
    data,
    backgroundColor: statusColors.map(({ statusColor }) => statusColor),
  };

  const onClick = (_, clickedBarData) => {
    const clickedLabel = labels?.[clickedBarData?.[0]?.index];
    if (typeof customOnClick === "function") {
      customOnClick({ cardConfigs, clickedLabel });
    } else {
      navigate(null, {
        state: {
          tabPosition: chartCategory,
          chartFilter: { [rowFilterKey]: [clickedLabel] },
        },
      });
    }
  };

  return (
    <ChartComponent
      {...{ chartData, onClick, title: cardConfigs?.chartTitle }}
    />
  );
};
