import { groupBy } from "lodash";

import { getBulkCoordinates } from "src/utils";
import {
  JobsiteType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { ScheduleType, ExcelDataType } from "../payrollLiveTypes";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";

/**
 * Function that matches locations scheduled for employees in excel with schedules in program
 *
 * @param jobsites Jobsites registered in the program
 * @param schedules Today schedules from the program
 * @param excelData Data exported from excel sheet
 * @returns {Promise<{jobsitesIncluded: Array<JobsiteType>; schedulesIncluded: Array<ScheduleType>;}>}
 */
export async function matchSchedulesWithExcelData(
  jobsites: Array<JobsiteType>,
  schedules: Array<ScheduleType>,
  excelData: Array<ExcelDataType>
): Promise<{
  jobsitesIncluded: Array<JobsiteType>;
  schedulesIncluded: Array<ScheduleType>;
  updatedExcelData: Array<ExcelDataType & { Position?: CoordinateType }>;
}> {
  let schedulesIncluded: Array<ScheduleType> = [];
  const jobsitesIncluded: Array<JobsiteType> = [];

  let tmpExcelData = [];
  for (let i = 0; i < excelData.length; i++) {
    const data = excelData[i];
    if ((data?.Location || "")?.includes("++")) {
      const locationsInData = data.Location.split("++");
      for (let j = 0; j < locationsInData.length; j++) {
        const location = locationsInData[j];
        tmpExcelData.push({ ...data, Location: location });
      }
    } else {
      tmpExcelData.push(data);
    }
  }
  const totalCoordinates = {};

  const allLocations = groupBy(
    tmpExcelData.flatMap((el) =>
      !!el?.Location && el?.Location !== "undefined"
        ? {
            ...el,
            Location: el?.Location,
          }
        : []
    ),
    "Location"
  );

  let listOfLocations = [];
  const allAddresses = Object.keys(allLocations);

  for (let i = 0; i < allAddresses.length; i++) {
    const addressName = allAddresses[i];
    const addressData = allLocations[addressName];
    let index = addressData.findIndex((el) => el?.Position?.lat);
    if (index > -1) {
      Object.assign(totalCoordinates, {
        [addressName]: addressData[index].Position,
      });
    } else {
      listOfLocations.push(addressName);
    }
  }

  for (let i = 0; i < listOfLocations.length; i += 10) {
    let locations = listOfLocations.slice(i, i + 10);

    const coordinates = await getBulkCoordinates({
      addresses: locations,
    });
    for (let j = 0; j < coordinates.length; j++) {
      const addressCoordinates = coordinates[j];
      Object.assign(totalCoordinates, addressCoordinates);
    }
  }

  for (const address in totalCoordinates) {
    const position = totalCoordinates[address];

    let possibleMatch = { index: -1, distance: 9999 };
    for (let i = 0; i < schedules.length; i++) {
      const sch = schedules[i];
      const match = withinRadius(position, sch?.addressPosition, 600);

      const included = schedulesIncluded?.findIndex(
        (el) => el?.scheduleId === sch?.scheduleId
      );

      if (match?.withinRange && included === -1) {
        if (possibleMatch?.distance > match?.distanceInFeet) {
          possibleMatch = { index: i, distance: match?.distanceInFeet };
        }
      }
    }

    if (typeof possibleMatch?.index === "number" && possibleMatch?.index > -1) {
      schedulesIncluded.push({
        ...schedules[possibleMatch?.index],
        employeesAssigned: allLocations[address],
      });
    }

    if (possibleMatch?.index === -1) {
      let possibleJobMatch = { index: -1, distance: 9999 };
      for (let i = 0; i < jobsites.length; i++) {
        const job = jobsites[i];
        const match = withinRadius(position, job?.addressPosition, 600);
        const included = jobsitesIncluded.findIndex(
          (el) => el?.jobsiteId === job?.jobsiteId
        );
        if (match?.withinRange && included === -1) {
          if (possibleJobMatch?.distance > match?.distanceInFeet) {
            possibleJobMatch = { index: i, distance: match?.distanceInFeet };
          }
        }
      }

      if (
        typeof possibleJobMatch?.index === "number" &&
        possibleJobMatch?.index > -1
      ) {
        jobsitesIncluded.push({
          ...jobsites[possibleJobMatch?.index],
          employeesAssigned: allLocations[address],
        });
      }
    }
  }
  if (schedules?.length > schedulesIncluded?.length) {
    schedulesIncluded = schedulesIncluded.concat(
      schedules.filter(
        (el) =>
          schedulesIncluded.findIndex(
            (sch) => sch.scheduleId === el.scheduleId
          ) === -1
      )
    );
  }

  const includedJobsIds = jobsitesIncluded.map((el) => el?.jobsiteId);
  const notMatchedJobs = jobsites.filter(
    (el) => !includedJobsIds.includes(el?.jobsiteId)
  );

  return {
    schedulesIncluded,
    jobsitesIncluded: jobsitesIncluded.concat(notMatchedJobs),
    updatedExcelData: excelData.map((el) => ({
      ...el,
      Position: totalCoordinates?.[el?.Location] || null,
    })),
  };
}
