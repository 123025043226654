import React from "react";
import "../styles/ProposalCard.scss";
// import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
// import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import moment from "moment";
function ProposalCard({ item = {} }) {
  const navigate = useNavigate();
  return (
    <div
      className="proposal_card_draft_wrapper"
      onClick={() => {
        navigate(
          `/new-proposal-builder?tab=Take-Off&takeOffId=${item?.takeOffVersion}&proposalId=${item?.proposalId}`,
          {
            state: {
              // takeOffId: item?.takeOffVersion,
              // takeOff: item,
              // estimation: item?.estimation,
              // proposalId: item?.proposalId,
            },
          }
        );
        // Swal.fire({
        //   title: "<strong>MISSING ACTION</strong>",
        //   icon: "info",
        //   html: "You have <b>NO CLEARANCE</b> to view or edit this proposal! ",
        //   showCloseButton: true,
        //   showCancelButton: false,
        //   focusConfirm: false,
        //   confirmButtonText: '<i class="fa fa-thumbs-up"></i> CLOSE!',
        //   confirmButtonAriaLabel: "Thumbs up, great!",
        //   // cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
        //   cancelButtonAriaLabel: "Thumbs down",
        // });
      }}
    >
      <div className="cardHeader">{item?.otherDetails?.jobSiteAddress}</div>{" "}
      <div className="cardBody">
        <img
          style={{
            width: "200px",
            height: "260px",
            borderRadius: "10px",
            // borderRadius: "50%",
          }}
          src="https://i.ibb.co/2kM5gzZ/61bce4643a18373cd436962e8f50a521.png"
        />
      </div>{" "}
      <div className="cardFooter">
        <strong>{item?.otherDetails?.title}</strong>
      </div>{" "}
      <div className="cardFooterHover">
        <div>
          <span> Created by: </span>
          <strong>{`${item?.otherDetails?.createdBy}`} </strong>
        </div>
        <div>
          {`${moment(item?.otherDetails?.createdAt).format(
            "MM/DD/YYYY HH:mm A"
          )}`}{" "}
        </div>
      </div>{" "}
    </div>
  );
}

export default ProposalCard;
