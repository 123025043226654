import { message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useChartCardsContext } from "../../../../ChartCardsContext";
import { updateChartPreferences, updateProgramFields } from "../../../../utils";
import ConfirmationModal from "../../../../../ConfirmationModal/ConfirmationModal";
import broadcastNotification from "../../../../../../../helpers/controllers/broadcastNotification";
import { lazyFetch } from "../../../../../../../utils";
import { programFields as programFieldsDispatch } from "../../../../../../../actions";

const DeleteCardAction = ({ cardConfigs }) => {
  const dispatch = useDispatch();
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const [deleteModal, setDeleteModal] = useState(false);

  const { chartCategory } = useChartCardsContext();

  const { preferences } = allPreferences;

  const overviewsConfig = programFields?.find(
    ({ fieldName }) => fieldName === "Overviews Configuration"
  );
  const overviewCards =
    overviewsConfig.fieldOptions.overviewCards[chartCategory];

  const deleteCard = async () => {
    //delete cardConfigs func
    message.loading("Card is being deleted...!");

    const { id, chartTitle, isPrivate = false } = cardConfigs;
    const myCardPrefs =
      preferences?.OverviewCards?.onlyMyCards?.[chartCategory];

    if (!!isPrivate) {
      const updatedPreferences = {
        ...preferences,
        OverviewCards: {
          ...(preferences?.OverviewCards || {}),
          onlyMyCards: {
            ...(preferences?.OverviewCards?.onlyMyCards || {}),
            [chartCategory]: myCardPrefs?.filter((card) => card.id !== id),
          },
        },
      };
      updateChartPreferences(updatedPreferences, allPreferences, dispatch);
    } else {
      const programF = await lazyFetch({ tableName: "programFields" });

      dispatch(programFieldsDispatch(programF));

      const overviewsConfig =
        programF?.find(
          ({ fieldName }) => fieldName === "Overviews Configuration"
        ) || {};

      const updatedFieldOptions = {
        ...overviewsConfig?.fieldOptions,
        overviewCards: {
          ...overviewsConfig?.fieldOptions?.overviewCards,
          [chartCategory]: overviewCards.filter((card) => card.id !== id),
        },
      };

      updateProgramFields(
        overviewsConfig?.fieldId,
        updatedFieldOptions,
        dispatch
      ).then(() => {
        message.success("Card Deleted Successfuly!");
      });
    }

    !isPrivate &&
      broadcastNotification(
        "90",
        "onDeletion",
        [
          {
            common: userConfiguration.nameOfUser,
            commonName: chartTitle,
            commonOverview: chartCategory,
          },
          {
            userName: userConfiguration.nameOfUser,
            currentUser: userConfiguration.cognitoUserId,
          },
        ],
        null
      );

    setDeleteModal(false);
  };

  return (
    <>
      <span
        className="ActionElementThreeDots mondayButtonRed"
        onClick={() => {
          setDeleteModal(true);
        }}
      >
        Delete Card
      </span>
      <ConfirmationModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        onConfirm={deleteCard}
        onCancel={() => setDeleteModal(false)}
        text={`Do you want to delete this card?`}
        title="Warning!"
        confirmButtonText="Yes"
        cancelButtonText="No"
      />
    </>
  );
};

export default DeleteCardAction;
