import "./ChartCards.scss";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ChartCardsContext } from "./ChartCardsContext";
import { filterDatas, getStatusColors } from "./utils";
import ChartCard from "./components/ChartCard/ChartCard";
import SidebarActions from "./components/SidebarActions/SidebarActions";
import {
  ChartRefsProvider,
  useChartRefsContext,
} from "./providers/ChartRefsContext";

const initialFilter = {
  GENERAL_CHART_FILTERS: [
    {
      filterType: "period",
      value: "This Month",
      label: "This Month",
    },
  ],
};

const ChartCards = ({
  chartCategory,
  chartDatas,
  nestedStatusColorKey,
  handleReFetch,
  customOnClick,
  tutorialLogs,
  customKeys,
  addBtn = true,
  filterPeriod = true,
  filterDepartment = true,
  getChartRef = () => {},
}) => {
  const { chartRefs } = useChartRefsContext();

  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    preferences: {
      preferences: { OverviewCards: cardPrefs },
    },
  } = useSelector((state) => state.preferences);

  const [hiddenCardIds, setHiddenCardIds] = useState(
    cardPrefs?.hiddenCards?.[chartCategory] || []
  );
  const [showPrivateCards, setShowPrivateCards] = useState("public");
  const [generalFilters, setGeneralFilters] = useState(
    cardPrefs?.cardFilters?.[chartCategory] ||
      (filterPeriod ? initialFilter : { GENERAL_CHART_FILTERS: [] })
  );
  const [searchedCard, setSearchedCard] = useState("");
  const [chartType, setChartType] = useState("");
  const [updatedCards, setUpdatedCards] = useState({});
  const [newChanges, setNewChanges] = useState(false);

  const cardsConfigurations = programFields?.find(
    ({ fieldName }) => fieldName === "Overviews Configuration"
  ).fieldOptions;

  const pubCards =
    updatedCards?.false ||
    cardsConfigurations?.overviewCards?.[chartCategory] ||
    [];

  const privCards = (
    updatedCards?.true ||
    cardPrefs?.onlyMyCards?.[chartCategory] ||
    []
  ).map((card) => ({ ...card, isPrivate: true }));

  const showAddBtn = addBtn;

  const categoryCards =
    showPrivateCards === "public"
      ? pubCards
      : showPrivateCards === "private"
      ? privCards
      : [...pubCards, ...privCards];

  const statusColors = useMemo(() => {
    if (chartDatas?.length !== 0) {
      return getStatusColors(
        programFields,
        chartCategory,
        nestedStatusColorKey,
        chartDatas
      );
    }
  }, [programFields, nestedStatusColorKey, chartCategory, chartDatas?.length]);

  const filteredData = filterDatas(
    chartDatas,
    generalFilters?.GENERAL_CHART_FILTERS
  );

  useEffect(() => {
    getChartRef(chartRefs);
  }, [filteredData]);

  return (
    <div className={`chart-cards-wrapper${isDarkMode ? " dark-mode" : ""} `}>
      <ChartCardsContext.Provider
        value={{
          chartCategory,
          allChartDatas: chartDatas,
          chartDatas: filteredData,
          tutorialLogs,
          handleReFetch,
          customOnClick,
          hiddenCardIds,
          setHiddenCardIds,
          showPrivateCards,
          setShowPrivateCards,
          generalFilters,
          setGeneralFilters,
          searchedCard,
          setSearchedCard,
          categoryCards,
          setUpdatedCards,
          newChanges,
          setNewChanges,
          chartType,
          setChartType,
          customKeys,
          showAddBtn,
          filterPeriod,
          filterDepartment,
        }}
      >
        <ChartRefsProvider>
          <div className="chart-cards-content">
            <div className="chart-cards">
              {categoryCards
                .filter(({ id }) => !hiddenCardIds.includes(id))
                .filter(({ chartTitle }) =>
                  chartTitle?.toLowerCase().includes(searchedCard.toLowerCase())
                )
                .map((cardConfigs) => (
                  <ChartCard
                    key={cardConfigs.id}
                    {...{ cardConfigs, statusColors }}
                  />
                ))}
            </div>
            <SidebarActions
              {...{
                showAddBtn,
              }}
            />
          </div>
        </ChartRefsProvider>
      </ChartCardsContext.Provider>
    </div>
  );
};

export default ChartCards;
