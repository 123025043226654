import React, { useState, useEffect, useMemo } from "react";
import "./ProposalSidebar.scss";
import { PlusIcon } from "../../../../assets";
import { MondayButton } from "../../../commonComponents";
import {
  getServicesAsHtml,
  processWordHtml,
} from "../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/process/process";
import { useSelector } from "react-redux";
import GeneralTemplatesSidebar from "./components/GeneralTemplates/GeneralTemplatesSidebar";
import Sidebar from "./Sidebar";
import { SignatureFilled } from "@ant-design/icons";
import SignatureBuilder from "./components/SignatureBuilder/SignatureBuilder";
import { useProposalContext } from "../../WaitingRoomProposal/ProposalContext";
import { GreenCheckMarkIcon } from "../../../../containers/Signup/icons";
import fetchProposalTemplates from "./helpers/fetchProposalTemplates";
import ProposalAssistant from "../ProposalHeader/components/ProposalAssistant/ProposalAssitant";
import findTemplateIssues from "./helpers/findTemplateIssues";

function ProposalSidebar({ editorRef }) {
  const { proposalData } = useProposalContext();

  const [proposalTemplates, setProposalTemplates] = useState([]);

  const [chosenView, setChosenView] = useState(0);

  const issues = useMemo(() => {
    return findTemplateIssues(proposalData, proposalTemplates);
  }, [proposalData, proposalTemplates]);

  // console.log("proposalData", proposalData);

  const onSwitchView = (key) => {
    let index = proposalSidebarData.findIndex((item) => item.key === key);
    setChosenView(index);
  };
  const chosenComponentData = proposalSidebarData[chosenView];
  const { component: ChosenSidebarComponent, props } = chosenComponentData;

  useEffect(() => {
    fetchProposalTemplates().then((res) => {
      setProposalTemplates(res.proposalTemplates);
    });
  }, [proposalData?.takeOff?.services]);

  return (
    <div className="proposal-builder-sidebar_wrapper">
      <Sidebar
        onSwitchView={onSwitchView}
        proposalSidebarData={proposalSidebarData}
      />
      <div className="main-content">
        <ChosenSidebarComponent
          {...{
            editorRef,
            proposalTemplates,
            issues,
          }}
        />
      </div>
    </div>
  );
}

export default ProposalSidebar;

export const CustomVariableCard = ({
  name,
  id,
  description,
  html,
  onDragEnd,
  onDragStart,
  commandHandler,
  keyDetail,
}) => {
  return (
    <div
      className="proposal-builder_custom_variable_card"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "10px",
        fontSize: "14px",
        width: "150px",
        // height: "200px",
        // margin: "8px",
        // backgroundColor: "lightgray",
      }}
      draggable={true}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onClick={() => {
        commandHandler(keyDetail);
      }}
    >
      <div className="previewIcon">
        <MondayButton hasIcon={false}> test</MondayButton>
      </div>
      {/* Card content */}
      <span>
        <h4 style={{ color: "#fff" }}> {name}</h4>
      </span>
    </div>
  );
};

export const ServicesMapHtml = ({ editorRef }) => {
  const { proposalData } = useProposalContext();

  const [htmlTemp, setHtmlTemp] = useState("");
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);

  const onInitialize = () => {
    let temp = {};
    proposalData?.takeOff?.takeOffItem?.services?.forEach((service) => {
      if (service?.isScope !== false) return;
      let tempHtml = [];
      getServicesAsHtml([service], serviceDefinitions, tempHtml);
      temp[service.serviceId] = {
        data: service,
        html: tempHtml,
      };
    });
    setHtmlTemp(temp);
    console.log("onInitialize", temp);
  };
  useEffect(() => {
    const htmlTemp = getHtmlDataForAllServices(
      proposalData?.takeOff?.takeOffItem,
      proposalData?.estimation,
      serviceDefinitions,
      proposalData?.takeOff?.key
    );
    setHtmlTemp(htmlTemp);
    onInitialize();
  }, [proposalData, serviceDefinitions]);

  console.log("serviceDefinitions", serviceDefinitions);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        flexDirection: "row",
      }}
    >
      {!!htmlTemp &&
        Object.values(htmlTemp).map((item) => {
          console.log("item", item);
          return (
            <CustomVariableCard
              {...{
                name: item?.data?.label,
                html: item?.html?.[0],
                id: item?.data?.serviceId,
                onDragEnd: (event) => {
                  // const droppedHTML = item?.html?.[0];
                  event.preventDefault();
                  if (editorRef.current) {
                    // const editor = tinymce.get(editorRef.current.id);
                    // editor.execCommand("mceInsertContent", false, droppedHTML);
                  }
                  // return editorRef.current?.editor?.setContent(htmlTemp);
                },
              }}
            />
          );
        })}
    </div>
  );
};

const getHtmlDataForAllServices = (
  takeOff,
  estimation,
  serviceDefs,
  versionId
) => {
  let tempHTML = processWordHtml({
    gridData: takeOff?.services || [],
    projectAddress: "Test Test",
    serviceDefs,
    accountName: "Test",
    estimation,
    versionId: takeOff?.id,
  });
  console.log("tempHTML", tempHTML);

  return tempHTML;
};
export const proposalSidebarData = [
  {
    icon: <PlusIcon />,
    component: GeneralTemplatesSidebar,
    key: "GeneralTemplate",
    hoverText: "General Templates",
  },
  {
    icon: <GreenCheckMarkIcon width={18} height={18} />,
    component: ProposalAssistant,
    key: "ProposalAssistant",
    hoverText: "Proposal Assistant",
  },
  {
    icon: <SignatureFilled />,
    component: SignatureBuilder,
    key: "SignatureSidebar",
    hoverText: "Signature Templates",
  },
  // { icon: <UserIcon /> },
  // { icon: <ContainerFilled /> },
  // { icon: <LayoutFilled /> },
  // <RightCircleFilled />,
];

const Card = ({ title, description, imageUrl, buttonText }) => {
  return (
    <div className="card">
      <img src={imageUrl} alt={title} className="card-image" />
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{description}</p>
        {buttonText && <button className="card-button">{buttonText}</button>}
      </div>
    </div>
  );
};
