import React from "react";
import { useSelector } from "react-redux";

import { Popover } from "antd";
import PopoverLinksContent from "../../SidebarPages/DynamicView/Pages/Overview/components/PopoverLinksContent/PopoverLinksContent";
import { NewTabIcon } from "../../SidebarPages/DynamicView/src";
import MondayButton from "../MondayButton/MondayButton";

/**
 * Renders a Button with links as popover.
 * @param {Object} props The component props.
 * @param {string} props.title The button title.
 * @param {string} props.className Additional classnames for the button.
 * @param {Object} props.popoverProps Properties for the popover content.
 * @return {React.Element|null} The rendered component or null.
 */
export default function LinkButton({
  title = "",
  className = "",
  popoverProps,
  children,
}) {
  // Checks if popoverProps has values to render the button
  const renderLinkButton =
    Object.keys(popoverProps).filter((el) => popoverProps[el]).length > 0;

  if (!renderLinkButton) return null;

  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <Popover
      content={PopoverLinksContent({
        popoverProps,
        isDarkMode,
      })}
      placement="top"
      trigger="hover"
      overlayClassName={`popoverLinkOverview ${
        isDarkMode && "darkPopoverLinkOverview"
      }`}
      data-testid="link-button-popover"
    >
      {children ? (
        children
      ) : (
        <MondayButton
          className={`mondayButtonBlue ${className}`}
          Icon={<NewTabIcon />}
        >
          {title && title}
        </MondayButton>
      )}
    </Popover>
  );
}
