import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Badge, Tooltip } from "antd";
import "./HeaderTasks.scss";
import InspectionModal from "../AgGridComponents/InspectionModal";
import { TasksWhite } from "../../../../icons/index";
import { MondayButton } from "../../../commonComponents/index";
import { PlusIcon } from "../../../../assets/index";
import { XIcon } from "../../Communication/assets";
import TasksBasePage from "../../TaskManager/Tasks/TasksBasePage";

export const HeaderTasks = ({
  rowData,
  darkMode,
  taskProjectName,
  taskItemId,
  taskTopic,
  type = "icon",
}) => {
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);
  const [visible, setVisible] = useState(false);

  const title = "Tasks";
  const filteredTasks = loggedOnTasks.filter(
    (task) => task.taskTopic === taskTopic
  );
  const thisScheduleOfValueTasks = filteredTasks.filter(
    (task) => task.taskTopicId === taskItemId
  );

  const handleModalVisibility = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <>
      <Tooltip title="Tasks">
        <Badge count={thisScheduleOfValueTasks.length} offset={[-18, 0]}>
          {type === "icon" ? (
            <Button
              onClick={handleModalVisibility}
              className={
                darkMode ? "header-task-btn-dark" : "header-task-btn-light"
              }
              icon={
                <span
                  style={{
                    fill: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TasksWhite />
                </span>
              }
            />
          ) : (
            <MondayButton
              Icon={<PlusIcon />}
              onClick={handleModalVisibility}
              className="mondayButtonBlue"
            >
              {" "}
              Create Task
            </MondayButton>
          )}
        </Badge>
      </Tooltip>

      <InspectionModal
        {...{
          visible,
          onCancel: () => handleModalVisibility(),
          title,
          width: 1600,
          footer: false,
          closable: true,
          closeIcon: <XIcon fill="#333238" />,
          className: darkMode
            ? "header-tasks-modal-dark"
            : "header-tasks-modal-light",
        }}
      >
        <div style={{ height: "65dvh" }}>
          <TasksBasePage
            {...{
              customTaskProps: {
                rowData,
                taskTopic: taskTopic,
                taskTopicId: taskItemId,
                taskRelatedTo: taskProjectName,
              },
            }}
          />
        </div>
      </InspectionModal>
    </>
  );
};
