import { parseInTz } from "../../NewDispatchModal/utils/dateFunctions";
import { Button } from "antd";

/** @type {Partial<import("ag-grid-enterprise").IDetailCellRendererParams>} */
const matchGridDetails = {
  detailGridOptions: {
    defaultColDef: {
      flex: 1,
      resizable: true,
      enableColResize: true,
      sortable: true,
      filter: true,
      enableRowGroup: true,
      minWidth: 130,
      suppressDragLeaveHidesColumns: false,
    },
    animateRows: true,
    onFirstDataRendered(param) {
      param.columnApi.autoSizeAllColumns(false);
    },
    rowGroupPanelShow: "always",
    groupDisplayType: "groupRows",
    columnDefs: [
      {
        headerName: "Vehicle #",
        field: "fleetName",
        cellClass: "cell-left-aligned",
      },
      {
        headerName: "From Location",
        field: "pickUp",
        cellClass: "cell-left-aligned",
      },
      {
        headerName: "Planned Departure",
        field: "departAt",
        cellClass: "cell-left-aligned",
        cellRenderer({ value }) {
          if (!value) {
            return null;
          }

          return parseInTz(value).format("MM/DD/YYYY HH:mm");
        },
      },
      {
        headerName: "Actual Departure",
        field: "actualDepart",
        cellClass: "cell-left-aligned",
        cellRenderer({ value }) {
          if (!value) {
            return "N/A";
          }

          return parseInTz(value).format("MM/DD/YYYY HH:mm");
        },
      },
      {
        headerName: "To Location",
        field: "dropOff",
        cellClass: "cell-left-aligned",
      },
      {
        headerName: "Planned Arrival",
        field: "arriveBy",
        cellClass: "cell-left-aligned",
        cellRenderer({ value }) {
          if (!value) {
            return null;
          }

          return parseInTz(value).format("MM/DD/YYYY HH:mm");
        },
      },
      {
        headerName: "Actual Arrival",
        field: "actualArrive",
        cellClass: "cell-left-aligned",
        cellRenderer({ value }) {
          if (!value) {
            return "N/A";
          }

          return parseInTz(value).format("MM/DD/YYYY HH:mm");
        },
      },
      {
        headerName: "Driver",
        field: "driverName",
        cellClass: "cell-left-aligned",
      },
      {
        headerName: "Cargo",
        field: "cargo",
        cellClass: "cell-left-aligned",
        cellStyle({ value }) {
          return {
            color: value === "Loaded" ? "#00A464" : "#F04F4E",
            fontWeight: 600,
          };
        },
      },
      {
        headerName: "Show Dispatch",
        cellRenderer({ data, context }) {
          return (
            <Button
              type="primary"
              style={{ backgroundColor: "#1264a3" }}
              onClick={() => {
                context.showDispatchHandler(data);
              }}
            >
              Show Dispatch
            </Button>
          );
        },
      },
    ],
  },
  getDetailRowData({ data, successCallback }) {
    successCallback(data.details);
  },
};

export default matchGridDetails;
