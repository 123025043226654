import React from "react";
import { Popover } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import getWeatherIcon from "../../helpers/getWeatherIcon";
import "./WeatherFirstPage.scss";
import { MondayButton } from "../../../../../commonComponents";
import dayjs from "dayjs";

export const WeatherFirstPage = (props) => {
  const { fullWeatherArray, weatherNotFound, handleWeather } = props;

  return (
    <div className="weatherContainer">
      {fullWeatherArray?.map((day, index) => {
        return (
          <div className="weatherItem">
            <span className="dayName">{day?.name}</span>
            <img
              style={{ width: 25, height: 25 }}
              src={getWeatherIcon(day?.shortForecast)}
              alt="weather icon"
            />
          </div>
        );
      })}
    </div>
  );
};
