import {
  AccountingSideBarIcon,
  ProposalBuilderIcon,
  ClaimsIcon,
  IncidentsIcon,
  SafetyIcon,
  SidebarClaimsIcon,
  ToDoIcon,
} from "../../assets/index";
import {
  ClientsWhite,
  ContactsWhite,
  DashboardWhite,
  EstimationsWhite,
  FleetWhite,
  InspectionsWhite,
  LeadsWhite,
  OpportunitiesWhite,
  Documentation,
  ProjectsWhite,
  ReportIconNew,
  SchedulingWhite,
  SubcontractorsWhite,
  DobWhite,
  SalesWhite,
  ProjectManamengentWhite,
  ApprovalIconWhite,
  TasksIconNew,
  FleetManagement,
  MappingIcon,
  MaintenanceIcon,
  FleetsInventoryIcon,
  VendorsIcon,
} from "../../icons";
import { NotesIcon } from "../SidebarPages/DynamicView/src/index";
import { ChartIcon } from "../SidebarPages/BasePage/src";

export const SidebarData = [
  {
    to: "/dashboard",
    src: DashboardWhite,
    title: "Dashboard",
  },
  { to: "/leads", src: LeadsWhite, title: "Leads" },
  { to: "/dob", src: DobWhite, title: "DOB" },
  {
    to: "/opportunities",
    src: OpportunitiesWhite,
    title: "Opportunities",
  },
  {
    to: "/estimations",
    src: EstimationsWhite,
    title: "Estimations",
  },
  { to: "/projects", src: ProjectsWhite, title: "Projects" },
  { to: "projectsOverview", src: ProjectsWhite, title: "Projects Overview" },
  {
    to: "/scheduling",
    src: SchedulingWhite,
    title: "Scheduling",
  },
  {
    to: "/permitdrawings",
    src: Documentation,
    title: "Permit Drawings",
  },
  {
    to: "/subcontractors",
    src: SubcontractorsWhite,
    title: "Subcontractors",
  },
  {
    to: "/vendors",
    src: VendorsIcon,
    title: "Vendors",
  },
  { to: "/accounts", src: ClientsWhite, title: "Clients" },
  { to: "/contacts", src: ContactsWhite, title: "Contacts" },
  {
    to: "/inspections",
    src: InspectionsWhite,
    title: "Inspections",
  },
  { to: "/tasksManager", src: TasksIconNew, title: "Tasks" },
  { to: "/todos", src: ToDoIcon, title: "To Do" },
  { to: "/notes", src: NotesIcon, title: "Notes" },
  { to: "/fleets", src: FleetWhite, title: "Fleets" },
  { to: "/fleetInspections", src: FleetWhite, title: "Fleets Inspections" },
  { to: "/documentation", src: Documentation, title: "Documentation" },
  { to: "/approvals", src: ApprovalIconWhite, title: "Approvals" },
  { to: "/reports", src: ReportIconNew, title: "Reports" },
  { to: "/accounting/", src: ReportIconNew, title: "Accounting" },
  { to: "/mapping", src: MappingIcon, title: "Mapping" },
  { to: "/fleetMaintenance", src: MaintenanceIcon, title: "Fleet Maintenance" },
  {
    to: "/fleetsInventory",
    src: FleetsInventoryIcon,
    title: "Fleets Inventory",
  },
  // { to: "/proposalBuilder", src: ToolFilled, title: "Proposal Builder" },
  // { to: "/events", src: EventsIcon, title: "Events" },
  { to: "/claims", src: ClaimsIcon, title: "Claims" },
  { to: "/hearings", src: ClaimsIcon, title: "Hearings" },
  { to: "/safety", src: SafetyIcon, title: "Safety Reports" },
  { to: "/incidents", src: IncidentsIcon, title: "Incidents" },
  { to: "/safetyInspections", src: SafetyIcon, title: "Safety Inspections" },
  { to: "/safetyCitations", src: SafetyIcon, title: "Safety Citations" },
  { to: "/projectCost", src: ChartIcon, title: "Project Cost" },
  { to: "/hrManagement", src: DashboardWhite, title: "Hr Management" },
];

/**
 * Sidebar menu items config, item with empty children doesnt have submenu
 */
export const SideBarSubsConfig = {
  Dashboard: { children: [], src: DashboardWhite },
  Sales: { children: [1, 2, 4, 5], src: SalesWhite },
  Contacts: { children: [], src: ContactsWhite },
  "Project Management": {
    children: [6, 6.1, 7, 8, 28],
    src: ProjectManamengentWhite,
  },

  Clients: { children: [], src: ClientsWhite },
  Subcontractors: { children: [], src: SubcontractorsWhite },
  Vendors: { children: [], src: VendorsIcon },
  Inspections: { children: [], src: InspectionsWhite },
  Documentation: { children: [], src: Documentation },
  Reports: { children: [], src: ReportIconNew },
  Approvals: { children: [], src: ApprovalIconWhite },
  Tasks: { children: [], src: TasksIconNew },
  "To Do": { children: [], src: ToDoIcon },
  Notes: { children: [], src: NotesIcon },
  "Hr Management": { children: [], src: DashboardWhite },
  // Fleet: { children: [1, 3], src: FleetWhite },
  // "Fleets Inspections": { children: [], src: FleetWhite },
  "Fleet Management": { children: [14, 21, 23], src: FleetManagement },

  Accounting: { children: [], src: AccountingSideBarIcon },
  Mapping: { children: [], src: MappingIcon },
  "Proposal Builder": { children: [], src: ProposalBuilderIcon },
  // Incidents: { children: [], src: IncidentsIcon },
  // Claim: { children: [24, 25], src: SidebarClaimsIcon },
  Safety: { children: [24, 25, 25.1, 33, 26, 27], src: SafetyIcon },

  //  Events: { children: [], src: EventsIcon },
};
