import _ from "lodash";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import InvoiceGroup from "./InvoiceGroup";
import InvoiceBalance from "./InvoiceBalance";
import InvoiceDetails from "./InvoiceDetails";
import {
  categories,
  categoriesName,
  categoriesServiceMap,
} from "../../utils/constants";
import { useInitialInvoiceContext } from "../../context/InvoiceInitialContext";
import { useInvoiceFormDataContext } from "../../context/InvoiceFormDataContext";
import { setSelectedProjectsByAccountName } from "../../utils/setSelectedProjectsByAccountName";

import "./InvoiceForm.scss";
import { useRedux } from "../../../../../hooks";

export default function InvoiceForm({ servicesInRef }) {
  const [servicesGroups, setServicesGroups] = useState([]); //Service estimations used on the check boxes
  const [account, setAccount] = useState();

  const [_, setFormChanged] = useRedux("invoiceFormChanged");

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [selectedCategoryGroupNumber, setSelectedCategoryGroupNumber] =
    useState();

  const { formData, setFormData } = useInvoiceFormDataContext();

  const {
    categoryData,
    projectData,
    invoiceData,
    accountData,
    outerSelectedCategory,
  } = useInitialInvoiceContext();

  const [selectedCategory, setSelectedCategory] = useState(
    outerSelectedCategory || categories[0]
  );

  useEffect(() => {
    async function fetchFormData() {
      //If in Edit Mode then, fill inputs and populate form data and items in the Ag grid with the invoice data
      if (invoiceData && projectData && accountData && categoryData) {
        //Save data in the state that is used later to send the data in the DB
        setFormData((prev) => {
          return {
            ...prev,
            accountName: accountData.accountName,
            accountEmail: accountData.accountEmail,
            projectId: projectData.projectId,
            accountId: projectData.accountId,
            projectName: projectData.projectName,
            billingAddress: accountData.billingAddress,
            // invoiceNumber: invoiceData.invoiceNumber,
            invoiceDate: invoiceData.fromDate,
            dueDate: invoiceData.toDate,
            totalDue: invoiceData.subtotal,
            quickBooksNumber: invoiceData.quickBooksNumber,
            taxRate: projectData.taxRate,
            memos: invoiceData?.memos || [],
          };
        });
        //Sets data on state and in the html Inputs
        setAccount({ ...accountData });
        onInputChangeHandler(account?.billingAddress, "billingAddress");
        setSelectedProjectsByAccountName(accountData.accountName);

        onCategoryChange(selectedCategory, false, false, false);

        const checkGroupNumber =
          invoiceData.invoiceItems[
            invoiceData.invoiceItems.length - 1
          ].group.split(" ")[1];
        setSelectedCategoryGroupNumber(checkGroupNumber);
        setFormChanged(false);
      } else if (projectData) {
        onCategoryChange(
          selectedCategory,
          true,
          true,
          true,
          categoryData[selectedCategory]
        );

        const account = await API.get(
          "accounts",
          `/accounts/${projectData.accountId}`
        );

        setAccount({ ...account });
        setFormData((prev) => {
          return {
            ...prev,
            accountName: account.accountName,
            accountEmail: account.accountEmail,
            projectId: projectData.projectId,
            accountId: projectData.accountId,
            projectName: projectData.projectName,
            billingAddress: account.billingAddress,
            taxRate: projectData.taxRate,
            quickBooksNumber: account.quickBooksNumber,
            memos: [],
          };
        });
        setFormChanged(false);
      } else onCategoryChange(selectedCategory, categoryData, false);
    }
    fetchFormData();
  }, [invoiceData, projectData, categoryData]);

  //Change form data by key
  function onInputChangeHandler(value, key) {
    setFormData((prev) => {
      prev[key] = value;
      return { ...prev };
    });
  }

  function setNewServices(services) {
    let servicesGroups = [];
    for (let service in services) {
      servicesGroups.push({
        id: service,
        ...services[service],
      });
    }
    setServicesGroups([...servicesGroups]);
    return servicesGroups;
  }

  //Set Category Groups based on type and project id
  const onCategoryChange = (
    selectedCategory,
    resetRowData = true,
    removeSelectedEstimations = true,
    removeProductsList = true,
    selectedCategoryData
  ) => {
    //Checks if a project is selected
    const serviceGroups = (() => {
      const serviceGroups = {};
      if (formData.projectId || selectedCategoryData) {
        (
          selectedCategoryData ||
          categoryData[selectedCategory].filter(
            (el) => el.projectId === formData.projectId
          )
        ).forEach((el) => {
          const services = el[categoriesServiceMap[selectedCategory]];
          services &&
            (serviceGroups[el[`${categoriesName[selectedCategory]}Id`]] = {
              services,
              ...el,
            });
        });

        setNewServices(serviceGroups, selectedCategory);
      }
      return serviceGroups;
    })();
    setFormChanged(true);
    removeSelectedEstimations && setSelectedCategoryGroupNumber();
    // removeProductsList && setProductsList([...[]]);
    resetRowData && setSelectedCategory(structuredClone(selectedCategory));
    return serviceGroups;
  };

  return (
    <div
      className={
        isDarkMode ? "invoiceFormContainer-dark" : "invoiceFormContainer"
      }
    >
      <InvoiceDetails
        {...{
          setFormChanged,
          account,
          onCategoryChange,
          setSelectedProjectsByAccountName,
          setServicesGroups,
          selectedCategory,
        }}
      />
      <InvoiceGroup
        {...{
          selectedCategory,
          onCategoryChange,
          setFormChanged,
          servicesGroups,
          selectedCategoryGroupNumber,
          setSelectedCategoryGroupNumber,
          servicesInRef,
        }}
      />
      {invoiceData && <InvoiceBalance />}
    </div>
  );
}
