import React from "react";

import { formatCurrency } from "../../../utils";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import {  useSelector } from "react-redux";

import "./FooterAmounts.scss";

const FooterAmounts = () => {
  const { rowData } = useCreateBillContext();
  const { billData, form } = useInitialBillContext();
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const totalAmount = rowData.reduce((sum, row) => sum + row.total, 0);
  const subTotalAmount = rowData.reduce((sum, row) => sum + row.amount, 0);

  return (
    <div className="footer-bill-group">
      <h4 data-testid="total-amount"
      style={{ color: isDarkMode ? '#F2F2F2' : '#12131B' }}>Total: {formatCurrency(totalAmount)}</h4>
      <h4 data-testid="sub-total-amount"
       style={{ color: isDarkMode ? '#F2F2F2' : '#12131B' }}>
        Subtotal: {formatCurrency(subTotalAmount)}
      </h4>
      <h4 data-testid="sub-total-amount" 
      style={{ color: isDarkMode ? '#F2F2F2' : '#12131B' }}>
        Credit: {formatCurrency(billData?.creditAmount || 0)}
      </h4>
    </div>
  );
};

export default FooterAmounts;
