import { Circle, Polygon, Rectangle } from "@react-google-maps/api";
import { convertRectangleToSquare } from "../utils";
import { usePolygonFunctions } from "../usePolygonFunctions";
import { useEffect } from "react";

const ShapeController = ({
  type,
  options,
  setClickedShape,
  editShape,
  setShapeInfo,
  setMovingMouseAroundPolygon,
  clickedShape,
  showInfoWindow,
  getProgramFieldsField,
  setPolygonEditEnd,
  setEditShape,
  allowEditing,
}) => {
  const handleBoolForCl = editShape
    ? !editShape
    : clickedShape
    ? clickedShape
    : !clickedShape;

  const handleBoolForUN = clickedShape
    ? !clickedShape
    : editShape
    ? editShape
    : !editShape;

  const { pathPoints, onEdit, onLoad, onUnmount, discardChanges } =
    usePolygonFunctions();

  useEffect(() => {
    if (editShape) {
      setPolygonEditEnd(pathPoints);
    } else {
      setPolygonEditEnd([]);
    }
  }, [editShape, pathPoints]);

  function handleDiscardOnDoubleClick() {
    discardChanges();
    setEditShape(false);
  }

  return (
    <>
      <Polygon
        onDblClick={editShape ? handleDiscardOnDoubleClick : () => {}}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onMouseUp={onEdit}
        onDragEnd={onEdit}
        onMouseMove={() => {
          if (
            clickedShape ? handleBoolForUN : editShape ? handleBoolForCl : true
          ) {
            setMovingMouseAroundPolygon(type);
          }
        }}
        onMouseOut={() => {
          setMovingMouseAroundPolygon(false);
        }}
        options={{
          options: options.polygonOptions,
          editable: allowEditing && type.shapeId === editShape.shapeId,
          draggable: allowEditing && type.shapeId === editShape.shapeId,
        }}
        path={pathPoints.length ? pathPoints : type.geoFenceInfo}
        onClick={() => {
          if (
            getProgramFieldsField("Polygon")?.allowEditing
              ? showInfoWindow
              : getProgramFieldsField("Polygon")?.allowEditing
          ) {
            setClickedShape();

            setShapeInfo({
              title: type?.title,
              description: type?.description,
            });
            setMovingMouseAroundPolygon(false);
          }
        }}
      />
      <Circle
        onMouseMove={() => {
          if (
            clickedShape ? handleBoolForUN : editShape ? handleBoolForCl : true
          ) {
            setMovingMouseAroundPolygon(type);
          }
        }}
        onMouseOut={() => {
          setMovingMouseAroundPolygon(false);
        }}
        options={options.circleOptions}
        radius={type.geoFenceInfo?.[0]?.circleRadius}
        center={{
          lng: type.geoFenceInfo?.[0]?.lng,
          lat: type.geoFenceInfo?.[0]?.lat,
        }}
        onClick={() => {
          if (
            getProgramFieldsField("Circle")?.allowEditing
              ? showInfoWindow
              : getProgramFieldsField("Circle")?.allowEditing
          ) {
            setMovingMouseAroundPolygon(false);
            setClickedShape(type);
            setShapeInfo({
              title: type?.title,
              description: type?.description,
            });
          }
        }}
      />
      <Rectangle
        onMouseMove={() => {
          if (
            clickedShape ? handleBoolForUN : editShape ? handleBoolForCl : true
          ) {
            setMovingMouseAroundPolygon(type);
          }
        }}
        onMouseOut={() => {
          setMovingMouseAroundPolygon(false);
        }}
        options={options.squareOptions}
        bounds={convertRectangleToSquare(type?.geoFenceInfo || {})}
        onClick={() => {
          if (
            getProgramFieldsField("Square")?.allowEditing
              ? showInfoWindow
              : getProgramFieldsField("Square")?.allowEditing
          ) {
            setMovingMouseAroundPolygon(false);
            setClickedShape(type);
            setShapeInfo({
              title: type?.title,
              description: type?.description,
            });
          }
        }}
      />
    </>
  );
};

export default ShapeController;
