import moment from "moment";
import htmlParser from "../../../../utils/htmlParser";
import { hasHTMLTags } from "../../../../utils/hasHTMLTags";
import { highlightDifferences } from "../../../pages/Settings/settingsComponents/ServicesTemplate/utils/highlightDifferences";

export const parseValue = (value, key) => {
  const timestampKeys = ["date", "created"];

  if (typeof value === "string") {
    // Check if the value is HTML
    if (/^<.*>$/.test(value)) {
      const parsedValue = htmlParser(value);
      return parsedValue;
    }

    // Check if the value contains letters or special characters
    if (/[a-zA-Z!@#$%^&*(),.?":{}|<>]/.test(value)) {
      // If it does, treat it as a regular string and return it
      return value;
    }
  }

  if (
    typeof value === "number" &&
    timestampKeys.some((timestampKey) =>
      key.toLowerCase().includes(timestampKey)
    )
  ) {
    // Check if the value is a timestamp
    const timestamp = Date.parse(value);
    if (!isNaN(timestamp)) {
      const momentDate = moment(value);
      if (momentDate.isValid()) {
        return momentDate.format("MM/DD/YYYY hh:mm");
      }
    }
  }

  return value;
};

export const synchronizeKeys = (previousData, currentData) => {
  // Helper function to recursively synchronize keys
  const synchronizeKeysRecursive = (previousObj, currentObj, newCurrentObj) => {
    for (const key in previousObj) {
      if (typeof currentObj === "object") {
        // Check if the key exists in the current object
        if (key in currentObj) {
          newCurrentObj[key] = currentObj[key];
        } else {
          // If the key is not present in the current object, add it with an empty string value
          currentObj[key] = "";
          newCurrentObj[key] = "'Does not exist'";
        }
      }

      // Recursively synchronize nested objects or arrays
      if (
        typeof previousObj[key] === "object" &&
        typeof currentObj?.[key] === "object"
      ) {
        newCurrentObj[key] = {};
        synchronizeKeysRecursive(
          previousObj[key],
          currentObj[key],
          newCurrentObj[key]
        );
      }
    }
  };

  // Create copies of the objects to avoid modifying the original data
  const newPreviousData = { ...previousData };
  const newCurrentData = {};

  // Synchronize keys for the top-level objects
  synchronizeKeysRecursive(previousData, currentData, newCurrentData);

  return { newPreviousData, newCurrentData };
};

export const removeHtmlTagsAndCreateObject = (
  key,
  value,
  currentValueForKey
) => {
  const result = highlightDifferences(value, currentValueForKey);

  return {
    key,
    previousValue: result.prev,
    updatedValue: result.curr,
  };
};

export const transformData = (previousData, currentData) => {
  const { newPreviousData, newCurrentData } = synchronizeKeys(
    previousData,
    currentData
  );

  return Object.entries(newPreviousData).map(([key, value]) => {
    const currentValueForKey =
      newCurrentData[key] !== undefined ? newCurrentData[key] : "";

    if (typeof value === "object" && !Array.isArray(value)) {
      if (Object.keys(value).length === 0) {
        return {
          key,
          previousValue: newPreviousData[key],
          updatedValue: `${newCurrentData[key]}`,
        };
      }

      // Handle the case where the value is an object with numerical keys
      return {
        key: key || "", // Set key as an empty string if it doesn't exist
        children: transformData(value, currentValueForKey),
      };
    } else if (typeof value === "object" && Array.isArray(value)) {
      // Handle the case where the value is an array
      return {
        key: key || "", // Set key as an empty string if it doesn't exist
        children: value.map((item, index) =>
          transformData(item, currentValueForKey[index])
        ),
      };
    } else if (
      hasHTMLTags(value) ||
      hasHTMLTags(currentValueForKey) ||
      key.toLowerCase() === "description"
    ) {
      return removeHtmlTagsAndCreateObject(key, value, currentValueForKey);
    } else {
      // Handle the case where the value is a primitive type
      let parsedValue = parseValue(value, key);
      let parsedUpdatedValue = parseValue(newCurrentData[key], key);

      if (typeof parsedValue === "boolean") {
        parsedValue = `${parsedValue} `;
      }

      if (typeof parsedUpdatedValue === "boolean") {
        parsedUpdatedValue = `${parsedUpdatedValue}`;
      }

      return {
        key,
        previousValue: parsedValue,
        updatedValue: parsedUpdatedValue,
      };
    }
  });
};

export const modifyLogDataForComponents = (logData) => {
  if (!Array.isArray(logData)) {
    return [];
  }

  const result = logData
    ?.map((log) => ({
      ...log,
      actionType: log.actionType,
      logs:
        log?.actionType?.toLowerCase() !== "create" ||
        log?.updatedKeys[0]?.hasOwnProperty("collapsable")
          ? transformData(log.previousData, log.currentData)
          : [],
    }))
    .sort((a, b) => b?.updatedAt - a?.updatedAt);

  return result;
};
