import "./NewWidgetModal.scss";
import { Modal, Tooltip } from "antd";
import { updateDocumentTitle } from "../../../utils";
import { XIcon } from "../../SidebarPages/Communication/assets";
import { BackArrow } from "../../SidebarPages/DynamicView/src";
import { MondayButton, Stepper } from "..";
import { useState } from "react";
import { Tick } from "../../pages/Settings/settingsComponents/Roles/src";
import { useSelector } from "react-redux";

const NewWidgetModal = ({
  modalTitle = "Add Widget",
  newWidgetModal,
  closeModal,
  widgetSteps,
  filledWidgets,
  onFinish = () => {},
  disableNext,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [currentStep, setCurrentStep] = useState(0);

  const isLastStep = currentStep === widgetSteps.length - 1;
  //this determinates when to disable next button
  const isBtnDisabled = () => {
    const {
      title,
      widgetConfigs: { rulesToFill = 1 },
    } = widgetSteps[currentStep];

    const currTitle = filledWidgets?.[title];

    return typeof currTitle === "string"
      ? !currTitle
      : typeof currTitle === "object"
      ? Object.keys(currTitle).length !== rulesToFill
      : true;
  };

  return (
    <Modal
      title={modalTitle}
      afterOpenChange={(event) => {
        event && updateDocumentTitle({ newTitle: "Add Widget" });
      }}
      open={newWidgetModal}
      onCancel={closeModal}
      width={750}
      wrapClassName={`add-widget-modal${isDarkMode ? " dark-mode" : ""}`}
      closeIcon={<XIcon />}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={closeModal}
          >
            Cancel
          </MondayButton>
          <div style={{ display: "flex", gap: "5px" }}>
            {currentStep > 0 && (
              <MondayButton
                className="mondayButtonBlue"
                Icon={<BackArrow />}
                onClick={() => {
                  setCurrentStep((prev) => prev - 1);
                }}
              >
                Back
              </MondayButton>
            )}
            <Tooltip title={disableNext} placement="bottom">
              <MondayButton
                Icon={<Tick width={17} height={17} />}
                disabled={(!isLastStep && isBtnDisabled(0)) || disableNext}
                onClick={() => {
                  //if it's last step
                  if (isLastStep) {
                    onFinish();
                  } else {
                    setCurrentStep((prev) => prev + 1);
                  }
                }}
              >
                {isLastStep ? "Finish" : "Next"}
              </MondayButton>
            </Tooltip>
          </div>
        </div>
      }
    >
      <Stepper {...{ currentStep, setCurrentStep, steps: widgetSteps }} />
    </Modal>
  );
};

export default NewWidgetModal;
