import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "../../../../SidebarPages/Estimations/DataEntryGrid/dataEntryGrid";
import {
  Badge,
  Button,
  Collapse,
  message,
  Progress,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { assignServiceColor } from "../../../../../helpers/constants/forProject";
import AddonsList from "./componentsForPli/AddonsList";
import Breakdown from "./componentsForPli/Breakdown";
import CheckboxRenderer from "./componentsForPli/CheckboxRenderer";
import _, { get } from "lodash";
import "./scheduleGrid.scss";
import DaysInScheduleMaster from "./componentsForPli/DaysInScheduleMaster";
import AddonsForHoist from "./componentsForPli/AddonsList/AddonsForHoist";
import ScheduledDaysTime from "./componentsForPli/ScheduledDaysTime/ScheduledDaysTime";
import ModifyRenderer from "./componentsForPli/ModifyRenderer";
import { AddonType } from "../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import {
  filterNodesByDayNames,
  getElevationDays,
  getSurface,
} from "../helpers/creators";
import { SelectDaysElevation } from "./componentsForPli/ElevationPanelElements/SelectDaysElevation";
import { rtfEditorAddon } from "../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents";
import {
  get_item_Scheduled,
  get_ProgressDimensionsByServiceId,
} from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import {
  getCellRendererParams,
  getElevationColumns,
  getScheduleServiceAddonsColumns,
} from "./columnDefinition/columnsGenerators";
import { BREAKDOWN_RENDER_ORIGIN } from "./componentsForPli/Breakdown/breakdown";
import {
  getRowHeight,
  rowGroupOpenedHeight,
} from "../helpers/ag-grid-calculators";
import { setBreakDownsInElevationRows_agGrid } from "../helpers/PLI-setters-getters";
import {
  PliProgressModal,
  PROGRESS_ORIGIN,
} from "./componentsForPli/PliProgressModal";
import { get_new_progressByDay } from "../../../../SidebarPages/Scheduling/helpers/setters";
import UndoProgressSchedule from "./UndoProgressSchedule/UndoProgressSchedule";
import { ServiceMenu } from "./componentsForPli/ServiceMenu/ServiceMenu";
import { sortDayIds } from "./componentsForPli/DaysInScheduleMaster/daysInScheduleMaster";
import { PliHistoryPopover } from "./componentsForPli/PliHistoryPopover";
import { HoistJumpRenderer } from "./componentsForPli/HoistJumpRenderer";
import { AddonProgressModal } from "./componentsForPli/AddonProgressModal";
import { ProgressStatistics } from "../../../../SidebarPages/Scheduling/helpers/totals";
import { getAddonName } from "../../../../pages/Settings/settingsComponents/Pricing/utils/addonDataFunctions";
import { getItemDimensionsAsString } from "../helpers/parsers";
import { HoistElements } from "./componentsForPli/HoistElements/HoistElements";
import { getScheduleRowClass } from "../../DataEntryGrid/tools/columnDefinitions/Styles";
import { DayStatus } from "../Subcomponents/DayStatusChangeModal/DayStatuses";
import { setRowDataToAllGrids } from "./helper/ag-grid-actions";
import ClassRedux from "../../../../SidebarPages/utils/classRedux";
import rtfEditorSchedule from "../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rftEditorSchedule";
import {
  allDaysInElevation,
  availableDays,
  breakInspProgress,
  calcData,
  checkIfServiceIsOtherScope,
  daysScheduledInServices,
  getDaysForSpecificService,
  maskNumbers,
  newEstimationsAdded,
  newServicesAdded,
  pliEditedFromNextSchedules,
  prevPliCompleted,
  prevSubPliCompleted,
  progressByDayScheduling,
  serviceAddonsColumnDefs,
  swalToast,
  totalProgressForPliBreakdowns,
  totalProgressPli,
  unablePli,
  reset,
  cleanService,
  processPli,
  progressInPerc,
  elevationTotalAndProgress,
  serviceTotalAndProgress,
} from "../SchedulingFirstPage/helperData";
import { CollapseArrow, CollapseIcon } from "../../../../../icons";
import CustomProgressBar from "../../../../commonComponents/CustomProgress/CustomProgressBar";
import { MondayButton } from "../../../../commonComponents";
import { ServiceDocumentation } from "../Subcomponents/ServiceDocumentation/ServiceDocumentation";
import { driveFolderFunction } from "../Subcomponents/ServiceDocumentation/utils/utils";
import { checkIfServiceIsHoist } from "../../../../SidebarPages/Estimations/DataEntryGrid/models/Service";
import ShortcutForPliDays from "./ShortcutForPliDays";
import TransferDays from "./TransferDays";
import Swal from "sweetalert2";
import { typeOfWorkColors } from "../DataAgGrid/columnDefs";
import { removeHTMLTags } from "../../../../../utils/removeHTMLTags";
import { EyeIcon } from "../../../../SidebarPages/DynamicView/src";
import {
  OpenedEyeIcon,
  OpenedEyeIconBlue,
  StatusProgressIcon,
  WarningIcon,
} from "../../../../../assets";
import ProgressAddons from "./ProgressAddons/ProgressAddons";
import moment from "moment";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import LockPliInSchedule from "./LockPliInSchedule/LockPliInSchedule";

/**
 * Very important Component. This component represent a list of services from one estimations which are ready to be scheduled or to set progress on them.
 * */

export default class PLIPerService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: this?.props?.gridData, //services array
      daysInElevations: {}, //days that are in each elevation
      breakdownsInElevations: {}, //breakdowns that are in each elevation, these are applied to each PLI of that elevation
      UIFeaturesForEachElevation: {}, // this.UIFeaturesForEachElevation[serviceId][optionIndex][elevationIndex] = {data}
      userConfiguration: {},
      totalLength: {},
      trasnferDays: {},
      progressLogs: [],
      loadingService: {
        visible: false,
        service: "",
      },
      breakdownLogs: [],

      progressAddons: {},

      shortcutContent: {
        visible: false,
        content: {},
      },
    };
  }
  agGridApiForEachElevation = {}; //is accessed like this.agGridApiForEachElevation[serviceId][elevationIndex].api.setRowData()
  addonsAgGridInstances = {}; //similar to agGridApiForEachElevation
  frameworkComponents = {
    AddonsList,
    CheckboxRenderer,
    Breakdown,
    ModifyRenderer,
    rtfEditorAddon,
    DaysInScheduleMaster,
    AddonsForHoist,
    PliProgressModal,
    PliHistoryPopover,
    HoistJumpRenderer,
    AddonProgressModal,
    ScheduledDaysTime,
    rtfEditorSchedule,
    LockPliInSchedule,
  };

  // It is called when the component is mounted and when the gridData is updated. It will set the days and breakdowns in each elevation
  componentDidMount() {
    const { gridData } = this.props;

    const { daysInElevations, breakdownsInElevations } =
      this.get_Days_and_Breakdowns_in_elevations(
        gridData,
        this?.props?.editMode
      );
    this.setState({ gridData, daysInElevations, breakdownsInElevations });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this?.props?.gridData) !==
      JSON.stringify(
        prevProps?.gridData
      ) /*|| this.props.scheduleDays !== prevProps.scheduleDays*/
    ) {
      const { daysInElevations, breakdownsInElevations } =
        this.get_Days_and_Breakdowns_in_elevations(
          this?.props?.gridData,
          this?.props?.editMode
        );

      this.setState(
        {
          gridData: this?.props?.gridData,
          daysInElevations,
          breakdownsInElevations,
        },
        () => {
          const dayIdList = this?.props?.scheduleDays
            ?.filter(
              (d) =>
                d?.status !== DayStatus?.Postponed &&
                d?.status !== DayStatus?.Canceled &&
                d?.status !== DayStatus?.Skipped
            )
            ?.map((d) => d?.id);

          const { agGridApiForEachElevation, addonsAgGridInstances } = this;
          const { gridData } = this.state;
          setRowDataToAllGrids({
            agGridApiForEachElevation,
            addonsAgGridInstances,
            gridData,
          });

          //after services are updated, update rows with schedule days
          this?.redrawAllRows(dayIdList); //works well

          const { daysInElevations, breakdownsInElevations } =
            this.get_Days_and_Breakdowns_in_elevations(
              this?.state?.gridData,
              this?.props?.editMode
            );

          this.setState({ daysInElevations, breakdownsInElevations });
        }
      );
      // this?.props?.handlePlis(this?.state?.gridData, this?.props?.estimationId);
    }
    if (
      JSON.stringify(this?.props?.scheduleDays) !==
      JSON.stringify(prevProps?.scheduleDays)
    ) {
      const dayIdList = this?.props?.scheduleDays
        ?.filter(
          (d) =>
            d?.status !== DayStatus?.Postponed &&
            d?.status !== DayStatus?.Canceled &&
            d?.status !== DayStatus?.Skipped
        )
        ?.map((d) => d?.id);
      this?.redrawAllRows(dayIdList); //works well

      const { daysInElevations } = this.get_Days_and_Breakdowns_in_elevations(
        this?.props?.gridData,
        this?.props?.editMode
      );

      for (const inService of Object.values(daysInElevations || {})) {
        for (const elevationIndex of Object.keys(inService || {})) {
          inService[elevationIndex] = inService?.[elevationIndex]?.filter((d) =>
            dayIdList?.includes(d)
          );
        }
      }
      this.setState({ daysInElevations });
    }

    if (prevProps?.projectId !== this?.props?.projectId) {
      this.agGridApiForEachElevation = {};
      this.addonsAgGridInstances = {};
      const UIFeaturesForEachElevation = {};

      this.setState({ UIFeaturesForEachElevation });
    }
  }

  /**
   * Receives 2 objects daysInElevations, breakdownsInElevations. This will iterate into all services and sup up days for each elevation
   * Same thing for breakdownsInElevations, but if breakdowns of plis are not identical with each other, it means that they are set separately and
   * breakdownsInElevations will be empty as there is nothing to put.
   * */

  // set days and breakdowns in each elevation
  get_Days_and_Breakdowns_in_elevations = (gridData, editMode) => {
    const daysInElevations = {};
    const breakdownsInElevations = {};
    for (const service of gridData) {
      const serviceId = service?.serviceId;
      daysInElevations[serviceId] = {};
      breakdownsInElevations[serviceId] = {};

      for (
        let elevationIndex = 0;
        elevationIndex < service?.serviceOptions?.[0]?.length;
        elevationIndex++
      ) {
        let all_elevation_days = [];
        breakdownsInElevations[serviceId][elevationIndex] =
          service?.serviceOptions?.[0]?.[elevationIndex]?.breakdownValue || [];
        for (
          let i = 0;
          i < service?.serviceOptions?.[0]?.[elevationIndex]?.items?.length;
          i++
        ) {
          const pli =
            service?.serviceOptions?.[0]?.[elevationIndex]?.items?.[i];

          all_elevation_days = all_elevation_days?.concat(pli?.days);
        }
        daysInElevations[serviceId][elevationIndex] = [
          ...new Set(all_elevation_days),
        ];
      }
    }

    return { daysInElevations, breakdownsInElevations };
  };

  // redraw all rows in ag-grid when days are updated
  redrawAllRows = (dayIdList) => {
    const gridData = _.cloneDeep(this?.state?.gridData);
    const {
      pricing,
      units,
      serviceDefinitions,
      scheduleDays,
      dayColors,
      typeOfWorkObject,
    } = this.props;
    const {
      concatDays_From_PLI_ToElevation,
      unlockPliIfPrevScheduleDeleted,
      onSubPLIAddRemove,
      onBreakdownSetInPLI,
      updateDays_From_Addons_to_PLI,
      saveDataFromRTDEditor,
      setItemAfterProgress,
      updateDays_From_SubPLI_to_PLI,
      subPliCellValueChanged,
      updateDays_from_serviceAddons,
      frameworkComponents,
    } = this;

    for (const [serviceId, agGridApiForService] of Object.entries(
      this.agGridApiForEachElevation || {}
    )) {
      let service, serviceIndex;

      for (let i = 0; i < gridData?.length; i++) {
        const s = gridData[i];
        if (!s?.isSelected) continue;
        if (s?.serviceId?.toString() === serviceId?.toString()) {
          service = s;
          serviceIndex = i;
          break;
        }
      }

      if (agGridApiForService && service?.isSelected) {
        for (const [elevationIndex, agGridApiForElevation] of Object.entries(
          agGridApiForService || {}
        )) {
          const elevation = service?.serviceOptions?.[0]?.[elevationIndex];
          const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
          let elevationTotalSqft = 0;
          if (elevation?.items?.length > 0) {
            if (!d2) {
              elevationTotalSqft = elevation?.items?.reduce(
                (acc, item) => acc + item?.[d1],
                0
              );
            } else {
              elevationTotalSqft = elevation?.items?.reduce(
                (acc, item) => acc + item?.[d1] * item?.[d2],
                0
              );
            }
          }

          agGridApiForElevation?.api?.forEachDetailGridInfo((detail) => {
            let cols = getCellRendererParams(
              {
                service,
                serviceId,
                serviceIndex,
                elevationIndex,
                label: service?.label,
                elevationLabel: elevation?.elevationLabel,
                isWritable: true,
              },
              { scheduleDays, typeOfWorkObject, frameworkComponents },
              {
                setItemAfterProgress,
                updateDays_From_SubPLI_to_PLI,
                subPliCellValueChanged,
                editMode: this?.props?.editMode,
              }
            ).detailGridOptions.columnDefs;
            detail.api.setColumnDefs(cols);
            // detail.api?.forEachNode((n) => {
            //   filterNodesByDayNames(n, dayIdList);
            // });
            detail.api.redrawRows();
          });
          // do this only when the panel is open
          if (agGridApiForElevation?.api?.getDetailGridInfo) {
            agGridApiForElevation.api.setColumnDefs(
              getElevationColumns(
                {
                  service,
                  serviceId,
                  serviceIndex,
                  elevationIndex,
                  label: service?.label,
                  elevationLabel: elevation?.elevationLabel,
                  typeOfWork: this?.props?.typeOfWork,
                  updateAddonsForFloors: this.updateAddonsForFloors,
                  elevationTotalSqft,
                  isWritable: this?.props?.isWritable,
                  elevationId: elevation?.elevationId,
                  updateBreakdownLogs: this.updateBreakdownLogs,
                },
                {
                  pricing,
                  units,
                  serviceDefinitions,
                  scheduleDays,
                  dayColors,
                  typeOfWorkObject,
                  gridData,
                },
                {
                  concatDays_From_PLI_ToElevation,
                  unlockPliIfPrevScheduleDeleted,
                  onSubPLIAddRemove,
                  onBreakdownSetInPLI,
                  updateDays_From_Addons_to_PLI,
                  saveDataFromRTDEditor,
                  setItemAfterProgress,
                  scheduleName: this?.props?.scheduleName,
                  isDarkMode: this?.props?.isDarkMode,
                }
              )
            );
          }

          // agGridApiForElevation?.api?.forEachNode((n) => {
          //   filterNodesByDayNames(n, dayIdList);
          // });
          setTimeout(() => {
            agGridApiForElevation?.api?.refreshCells({
              force: true,
            });
          }, 1000);
        }
      }
    }
    for (const [serviceId, agGridApiForServiceAddons] of Object.entries(
      this.addonsAgGridInstances || {}
    )) {
      const service = gridData?.find(
        (s) => s?.serviceId?.toString() === serviceId?.toString()
      );
      if (!service?.isSelected) continue;
      agGridApiForServiceAddons.api.setColumnDefs(
        getScheduleServiceAddonsColumns(
          service,
          { scheduleDays, pricing, units, typeOfWorkObject },
          {
            concatDays_From_PLI_ToElevation,
            setItemAfterProgress,
            updateDays_from_serviceAddons,
          }
        )
      );
      agGridApiForServiceAddons.api.forEachNode((n) => {
        filterNodesByDayNames(n, dayIdList);
      });
      setTimeout(() => {
        agGridApiForServiceAddons?.api?.refreshCells({
          force: true,
        });
      }, 1000);
    }
  };

  /*ag-grid functions start*/
  onGridReady = (params, data, type, { serviceId, elevationIndex }) => {
    // this.gridApi = params.api;

    // i want this to be called only once when the agrig is opened
    // if (this?.agGridApiForEachElevation?.[serviceId]?.[elevationIndex]) return;
    for (const pli of data || []) {
      if (this?.props?.editMode === false) {
        pli.days = this?.state?.daysInElevations?.[serviceId]?.[elevationIndex];
        pli.breakdownValue =
          this?.state?.breakdownsInElevations?.[serviceId]?.[elevationIndex];

        if (pli?.subPli && pli?.subPli?.length > 1) {
          //set days in all subplis
          pli.subPli.days = pli.days;
        }
        if (pli.addons && pli.addons.length > 1) {
          //also in addons
          pli.addons.days = pli.days;
        }
      }
      // params.api.setRowData(data);
      // else {
      // if (
      //   Object.keys(pli?.editedFrom).length > 0 &&
      //   this?.props?.otherSchedules?.length > 0
      // ) {
      //   const scheduleIds = this?.props?.otherSchedules?.map((s) => s?.id);
      //   if (!scheduleIds.includes(pli?.editedFrom?.scheduleId)) {
      //     delete pli?.editedFrom;
      //   }
      // }
      // }

      // if (type === "elevation") {
      //   if (pli.addons)
      //     for (const addon of pli.addons) {
      //       addon.parentId = pli.id;
      //     }
      // }
    }

    // const UIFeaturesForEachElevation = _.cloneDeep(
    //   this.state.UIFeaturesForEachElevation
    // );

    // if (!UIFeaturesForEachElevation[serviceId])
    //   UIFeaturesForEachElevation[serviceId] = {};
    // if (!UIFeaturesForEachElevation[serviceId][elevationIndex])
    //   UIFeaturesForEachElevation[serviceId][elevationIndex] = {};
    // UIFeaturesForEachElevation[serviceId][elevationIndex].total_height =
    //   rowGroupOpenedHeight(params, { serviceId }); //this measures the div height so when panel is open, we will have enough space

    // params.api.addEventListener("rowGroupOpened", (params) => {
    //   UIFeaturesForEachElevation[serviceId][elevationIndex].total_height =
    //     rowGroupOpenedHeight(params, { serviceId });
    //   this.setState({ UIFeaturesForEachElevation });
    // });

    // params.api.addEventListener("firstDataRendered", (params) => {
    //   UIFeaturesForEachElevation[serviceId][elevationIndex].total_height =
    //     rowGroupOpenedHeight(params, { serviceId });
    //   this.setState({ UIFeaturesForEachElevation });
    // });

    if (type === "elevation") {
      if (this.agGridApiForEachElevation[serviceId] === undefined)
        this.agGridApiForEachElevation[serviceId] = {};
      this.agGridApiForEachElevation[serviceId][elevationIndex] = params;
    }
    params.api.setRowData(data);
    // this.setState({ UIFeaturesForEachElevation });
  };
  handleAddonsGridReady = (params, data) => {
    try {
      params.api.setRowData(data);
      // this.autoSizeAll(true)
    } catch (e) {
      console.error(e);
    }
  };
  //small helper functions
  resizeGridRows = (gridApi) => gridApi.resetRowHeights();
  getRowNodeId = (data) => data.id;
  isRowMaster = (dataItem) => (dataItem ? dataItem.subPli?.length > 0 : false);
  autoSizeAll(skipHeader) {
    try {
      var allColumnIds = [];
      this.gridApi.columnApi.getAllColumns().forEach(function () {
        allColumnIds.push(column.colId);
      });
      this.gridApi.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    } catch (e) {}
  }

  updateBreakdownLogs = (logs = []) => {
    this.setState({
      breakdownLogs: [...(this?.state?.breakdownLogs || []), ...logs],
    });
  };

  addAnewService = (service = {}) => {
    const { gridData } = this.state;
    let newServicesAdded = service;
    newServicesAdded = {
      ...newServicesAdded,
      serviceOptions: [
        newServicesAdded?.serviceOptions?.[0]?.map((elevation) => ({
          ...elevation,
          items: elevation?.items?.map((pli) =>
            processPli(
              pli,
              "hoist_dimension",
              100,
              newServicesAdded,
              newServicesAdded?.serviceId
            )
          ),
        })),
      ],
    };

    gridData.push(newServicesAdded);
    this.setState({
      gridData,
      daysInElevations: {
        ...this?.state?.daysInElevations,
        [newServicesAdded?.serviceId]: [],
      },
    });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  addAnewElevation = (serviceId, elevation = {}) => {
    const { gridData = [] } = this.state;
    const { agGridApiForEachElevation = {} } = this;
    const service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    const newElevationToAdd = {
      ...elevation,
      items: elevation?.items?.map((pli) =>
        processPli(pli, "hoist_dimension", 100, service, serviceId)
      ),
    };

    service?.serviceOptions?.[0]?.push(newElevationToAdd);
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  addAnewPli = (serviceId, elevationIndex, pli = {}) => {
    const { gridData } = this.state;
    const service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    service?.serviceOptions?.[0]?.[elevationIndex]?.items?.push(
      processPli(pli, "hoist_dimension", 100, service, serviceId)
    );

    // service.isSelected = false;

    this.setState({
      gridData,
    });

    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  dimensionMatch = (
    serviceId,
    elevationIndex,
    pliIndex,
    dimensionName,
    dimensionValue
  ) => {
    const { gridData } = this.state;
    const service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    service.serviceOptions[0][elevationIndex].items[pliIndex][dimensionName] =
      dimensionValue;
    service.serviceOptions[0][elevationIndex].items[pliIndex].originalPli[
      dimensionName
    ] = dimensionValue;
    this.setState({ gridData });
    this?.agGridApiForEachElevation?.[serviceId]?.[
      elevationIndex
    ]?.api?.setRowData(service?.serviceOptions?.[0]?.[elevationIndex]?.items);
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  removeServiceFromSchedule = (serviceId) => {
    const { gridData } = this.state;
    const newGridData = gridData?.filter(
      (service) => service?.serviceId?.toString() !== serviceId?.toString()
    );
    this.setState({ gridData: newGridData });
    this.props.handlePlis(newGridData, this?.props?.estimationId);
  };

  removeElevationFromSchedule = (serviceId, elevationId) => {
    const { gridData } = this.state;
    const service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    service.serviceOptions[0] = service?.serviceOptions?.[0]?.filter(
      (e) => e?.elevationId !== elevationId
    );
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  removePliFromSchedule = (serviceId, elevationIndex, id) => {
    const { gridData } = this.state;
    const service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    service.serviceOptions[0][elevationIndex].items =
      service?.serviceOptions?.[0]?.[elevationIndex]?.items?.filter(
        (pli) => pli?.id !== id
      );

    this.setState({ gridData });
    this?.agGridApiForEachElevation?.[serviceId]?.[
      elevationIndex
    ]?.api?.setRowData(service?.serviceOptions?.[0]?.[elevationIndex]?.items);
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  updateChanges = (func = "", parameters = []) => this[func](...parameters);

  //on sub-pli cell value changed
  subPliCellValueChanged = (params, serviceId, elevationIndex) => {
    try {
      const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

      if ([d1, d2]?.includes(params?.column?.colId)) {
        const parentPLIId = params?.node?.data?.parentId;
        const parentPLI =
          this.agGridApiForEachElevation?.[serviceId]?.[
            elevationIndex
          ]?.api?.getRowNode(parentPLIId)?.data;

        let dimension = params?.column?.colId;

        if (d2 === undefined) {
          //if we have a single progress dimension
          let total_length_of_subPLIs = 0;
          let subPLI_with_0_length = 0;

          params.api.forEachNode((n) => {
            const subPLILength = parseInt(n?.data?.[dimension]);
            total_length_of_subPLIs += subPLILength || 0;

            if (subPLILength === 0) subPLI_with_0_length++;
          });

          const difference =
            parseInt(parentPLI?.[dimension]) - total_length_of_subPLIs;

          if (difference < 0) {
            params.node.data[dimension] = params?.oldValue;

            params.api.applyTransaction({
              update: [params?.api?.getRowNode(params?.rowIndex)],
            });
            message.warning(
              `Total length of sub-PLI can't be higher than PLI's. Resetting ${dimension} to old value ${params?.oldValue}`
            );
          }
        } else {
          // progress depend on 2 dimensions
          let total_surface_of_subPLIs = 0;
          let subPLI_with_0_surface = 0;

          const inputValue = params?.node?.data?.[dimension];

          if (inputValue > parentPLI?.originalPli?.[dimension]) {
            //if sub-pli dimension is higher than original pli dimension

            params.node.data[dimension] = params?.oldValue;

            params.api.applyTransaction({
              update: [params.api.getRowNode(params?.rowIndex)],
            });
            message.warning(
              `Total surface of sub-PLI can't be higher than PLI's. Resetting ${dimension} to old value ${params?.oldValue}`
            );
            return;
          }

          params.api.forEachNode((n) => {
            const subPLI_d1 = parseInt(n?.data?.[d1]) || 0;
            const subPLI_d2 = parseInt(n?.data?.[d2]) || 0;

            total_surface_of_subPLIs += subPLI_d1 * subPLI_d2;

            if (total_surface_of_subPLIs === 0) subPLI_with_0_surface++;
          });

          const parentSurface = get_item_Scheduled(parentPLI, { d1, d2 });
          const difference = parentSurface - total_surface_of_subPLIs;

          if (difference < 0) {
            // message.warning(`Total surface of sub-PLI can't be higher than PLI's. Resetting ${dimension} to old value ${params.oldValue}`)
            params.node.data[dimension] = params?.oldValue;
            params.api.applyTransaction({
              update: [params?.api?.getRowNode(params?.rowIndex)],
            });
          } else if (difference > 0) {
            params.node.data["surface"] = getSurface(params?.node?.data, {
              d1,
              d2,
            }); //set scheduled surface
          } else {
            params.node.data["surface"] = getSurface(params?.node?.data, {
              d1,
              d2,
            }); //set scheduled surface
          }
        }

        params.api.redrawRows();
        this.agGridApiForEachElevation[serviceId][
          elevationIndex
        ].api.refreshCells();
      }
    } catch (e) {
      console.error(e);
    }
  };

  //cell render params
  getDetailGridInfo = () => {
    var detailGridInfo = this.gridApi.getDetailGridInfo("id");
    detailGridInfo.api.flashCells();
  };

  rowClassRules = () => {
    return {
      "even-row-color": (params) => {
        return params.rowIndex % 2 === 1;
      },
    };
  };

  // all days used in the plis of the elevation
  updateDaysInElevation = (
    serviceId,
    elevationIndex,
    totalDays = [],
    isFromElevation = false
  ) => {
    let daysInElevations = _.cloneDeep(this?.state?.daysInElevations);
    if (!!isFromElevation) {
      daysInElevations[serviceId][elevationIndex] = totalDays;
    } else {
      const findService = this?.state?.gridData?.find(
        (a) => a?.serviceId?.toString() === serviceId?.toString()
      );
      const findElevation =
        findService?.serviceOptions?.[0]?.[elevationIndex] || {};

      daysInElevations[serviceId][elevationIndex] =
        allDaysInElevation(findElevation);
    }

    this.setState({ daysInElevations });
    this?.props?.handlePlis(this?.state?.gridData, this?.props?.estimationId);
  };

  updateAddonsForFloors = (
    serviceId,
    elevationIndex,
    pliIndex,
    addons = "",
    type = "",
    addonsOptions = []
  ) => {
    const gridData = _.cloneDeep(this?.state?.gridData);
    const findService = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    let elevation = findService?.serviceOptions?.[0]?.[elevationIndex];
    const findAddon = addonsOptions?.find((a) => a?.id === addons);
    if (type === "add") {
      elevation.items[pliIndex].originalPli.addons = [
        ...elevation?.items?.[pliIndex]?.originalPli?.addons,
        findAddon,
      ];
      message.success(
        `${getAddonName(findAddon)} added to floor ${
          elevation?.items?.[pliIndex]?.floor
        }`
      );
    } else {
      elevation.items[pliIndex].originalPli.addons = [
        ...elevation?.items?.[pliIndex]?.originalPli?.addons?.filter(
          (x) => x?.id !== addons
        ),
      ];
      message.success(
        `${getAddonName(findAddon)} removed from floor ${
          elevation?.items?.[pliIndex]?.floor
        }`
      );
    }

    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  // update days in plis from elevation
  updateDaysFromElevationModalToRows = (serviceId, elevationIndex, days) => {
    const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
    const gridData = _.cloneDeep(this?.state?.gridData);
    const findService = gridData?.find((s) => s?.serviceId === serviceId);
    let elevation = findService?.serviceOptions?.[0]?.[elevationIndex];
    elevation.items = elevation?.items?.map((pli) => {
      if (unablePli(pli) || !!pli?.locked) return pli;
      pli.days = days;

      pli.usedScheduleDays = daysScheduledInServices(
        {
          ...pli,
          days: [
            ...new Set([
              ...(pli?.days || []),
              ...(pli?.isPliCompletedInThePreviousSchedule?.prevDays || []),
            ]),
          ],
        },
        this?.props?.scheduleDays,
        this?.props?.scheduleName,
        ""
      );

      pli.progressByDay = get_new_progressByDay(
        Object.keys(pli?.usedScheduleDays || {}),
        pli?.progressByDay,
        { d1, d2 }
      );

      pli.typeOfProgress = !days?.length ? "" : pli?.typeOfProgress;
      if (pli?.typeOfProgress === "breakdown") {
        pli?.breakdownValue?.forEach((breakdown) => {
          const progressByDayMap = new Map(
            (breakdown?.progressByDay || [])?.map((b) => [b?.day, b])
          );
          breakdown.days = days;
          breakdown.progressByDay = days?.map((day) => {
            if (progressByDayMap?.has(day)) {
              return progressByDayMap?.get(day);
            }
            return {
              day,
              ["breakdown_dimension"]: 0,
            };
          });
        });
      }
      if (pli?.subPli?.length || pli?.typeOfProgress === "subPli") {
        pli.subPli = pli?.subPli?.map((subPli) => {
          subPli.days = days;
          return subPli;
        });
      }
      pli.totalProgress =
        pli?.typeOfProgress === "breakdown"
          ? totalProgressForPliBreakdowns(
              pli,
              pli?.breakdownValue,
              this?.props?.typeOfWork,
              d1,
              d2
            )
          : totalProgressPli(pli, serviceId);
      return pli;
    });
    this.updateDaysInElevation(serviceId, elevationIndex, days);
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  updateProgressForServiceAddon = (
    serviceIndex,
    addonIndex,
    progressByDay = []
  ) => {
    const gridData = _.cloneDeep(this?.state?.gridData);
    const service = gridData?.[serviceIndex] || {};
    const addon = service?.serviceAddons?.[addonIndex] || {};
    addon.progressByDay = progressByDay || [];
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  updateDaysFromElevationToRows = (
    serviceId = "",
    elevationIndex,
    oldDays,
    day,
    action
  ) => {
    const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
    let updatedAtLeastOneRow = false;
    let newDays = [...oldDays];

    if (
      this.agGridApiForEachElevation[serviceId] &&
      this.agGridApiForEachElevation[serviceId][elevationIndex]
    ) {
      const { gridData } = this.state;

      //check if we have already ag-grid instances together

      if (action === "remove") {
        //first check if any sub-pli uses that day
        this.agGridApiForEachElevation[serviceId][
          elevationIndex
        ].api.forEachNode((n) => {
          let pli = n?.data || {};
          let progress, days;
          if (unablePli(pli) || !!pli?.locked) {
            return;
          } else if (pli?.typeOfProgress === "dimensions") {
            if (
              [
                new Set([
                  ...(pli?.days || []),
                  ...(pli?.isPliCompletedInThePreviousSchedule?.prevDays || []),
                ]),
              ]?.length === 0
            ) {
              progress = 0;
              days = [];
              pli.typeOfProgress = "";
              pli.progressByDay = [];
              pli.usedScheduleDays = {};
            } else {
              pli.usedScheduleDays = daysScheduledInServices(
                {
                  ...pli,
                  days: [
                    ...new Set([
                      ...pli?.days,
                      ...oldDays,
                      ...(pli?.isPliCompletedInThePreviousSchedule?.prevDays ||
                        []),
                    ]),
                  ]?.filter((el) => el !== day),
                },
                this?.props?.scheduleDays,
                this?.props?.scheduleName,
                ""
              );
              pli.progressByDay = pli?.progressByDay?.filter((el) =>
                Object.keys(pli?.usedScheduleDays)?.includes(el?.day)
              );
              days = [
                ...new Set([
                  ...pli?.days,
                  ...oldDays,
                  ...(pli?.isPliCompletedInThePreviousSchedule?.prevDays || []),
                ]),
              ]?.filter((el) => el !== day);
              pli.typeOfProgress = !pli?.progressByDay?.length
                ? ""
                : pli?.typeOfProgress;
              progress = totalProgressPli(pli, serviceId);
            }
          } else if (pli?.typeOfProgress === "breakdown") {
            n.setDataValue(
              "breakdownValue",
              pli?.breakdownValue?.map((el) => {
                el.days = el?.days?.filter((s) => s !== day);
                el.progressByDay = el?.progressByDay?.filter(
                  (s) => s?.day !== day
                );
                return el;
              })
            );
            days = [
              ...new Set(pli?.breakdownValue?.flatMap(({ days = [] }) => days)),
            ];
            pli.typeOfProgress = !pli?.breakdownValue?.some(
              (el) => el?.progressByDay?.length
            )
              ? ""
              : pli?.typeOfProgress;
            progress =
              pli?.breakdownValue
                ?.map?.(({ progressByDay = [], rate = 0 }) =>
                  breakInspProgress(
                    this?.props?.typeOfWork,
                    progressByDay,
                    rate
                  )
                )
                ?.reduce?.((a, b) => a + b, 0) *
              (d2 === undefined ? pli?.[d1] / 100 : pli?.["surface"] / 100);

            pli.progressByDay =
              pli?.progressByDay?.filter((el) => el?.day !== day) || [];

            pli.usedScheduleDays = Object.fromEntries(
              Object.entries(pli?.usedScheduleDays || {})?.filter(
                ([key, value]) => key !== day
              )
            );
            // return;
          } else if (pli?.typeOfProgress === "") {
            if (
              (pli?.isPliCompletedInThePreviousSchedule?.prevDays || [])
                ?.length === 0
            ) {
              progress = 0;
              days = [];
              pli.typeOfProgress = "";
              pli.progressByDay = [];
              pli.usedScheduleDays = pli?.usedScheduleDays || {};
            } else {
              pli.usedScheduleDays = daysScheduledInServices(
                {
                  ...pli,
                  days: [
                    ...new Set([
                      ...(pli?.days || []),
                      ...oldDays,
                      ...(pli?.isPliCompletedInThePreviousSchedule?.prevDays ||
                        []),
                    ]),
                  ]?.filter((el) => el !== day),
                },
                this?.props?.scheduleDays,
                this?.props?.scheduleName,
                ""
              );
              pli.progressByDay = pli?.progressByDay?.filter((el) =>
                Object.keys(pli?.usedScheduleDays)?.includes(el?.day)
              );
              days = [
                ...new Set([
                  ...pli?.days,
                  ...oldDays,
                  ...(pli?.isPliCompletedInThePreviousSchedule?.prevDays || []),
                ]),
              ]?.filter((el) => el !== day);
              progress = totalProgressPli(pli, serviceId);
            }
          } else if (pli?.subPli?.length) {
            if (!!this.isDayUsedInSubPLI_or_Addon(pli, day)) {
              let obj = {};
              n.setDataValue(
                "subPli",
                pli?.subPli?.map((el) => {
                  if (el?.days?.includes(day)) {
                    el.days = el?.days?.filter((s) => s !== day);
                    el.progressByDay = el?.progressByDay?.filter(
                      (s) => s?.day !== day
                    );
                    el.totalProgress = el?.progressByDay?.reduce?.(
                      (a, b) =>
                        a + (d2 === undefined ? b?.[d1] : b?.[d1] * b?.[d2]),
                      0
                    );
                    return el;
                  }

                  return el;
                })
              );
              for (const key in pli?.usedScheduleDays || {}) {
                if (key !== day) {
                  obj[key] = pli?.usedScheduleDays?.[key];
                }
              }
              pli.usedScheduleDays = obj;
              pli.typeOfProgress =
                [...new Set([...pli?.days, ...oldDays])]?.filter(
                  (el) => el !== day
                )?.length === 0
                  ? ""
                  : pli?.typeOfProgress;
              pli.progressByDay =
                pli?.progressByDay?.filter((el) => el?.day !== day) || [];
            }

            progress = pli?.subPli?.reduce?.((a, b) => a + b?.totalProgress, 0);
            days = [...new Set(pli?.subPli?.flatMap(({ days }) => days))];
          } else {
            progress = 0;
            days = [...new Set([...pli?.days, ...oldDays])]?.filter(
              (el) => el !== day
            );
          }

          n.setDataValue("totalProgress", progress);
          // n.setDataValue(
          //   "progressByDay",
          //   (pli.progressByDay = pli?.progressByDay?.filter((el) =>
          //     Object.keys(pli?.usedScheduleDays)?.includes(el?.day)
          //   ))
          // );
          n.setDataValue("days", days);
        });

        newDays = newDays?.filter((d) => d !== day);
      } else if (action === "add") {
        newDays.push(day);
        newDays = sortDayIds(this.props.scheduleDays, newDays);
      }
      this.agGridApiForEachElevation[serviceId][elevationIndex].api.forEachNode(
        (n) => {
          let pli = n.data;
          let updateResult = this.setDaysInPLI(
            n,
            pli,
            newDays,
            { serviceId },
            action,
            day
          );
          updatedAtLeastOneRow = updateResult.isPliUpdated;
        }
      );

      if (updatedAtLeastOneRow === false) {
        return;
      }

      this.agGridApiForEachElevation[serviceId][
        elevationIndex
      ].api.redrawRows();
      this.props.handlePlis(gridData, this?.props?.estimationId);
    } else {
      //update gridData manually (elevation panel have not been open)
      const gridData = _.cloneDeep(this?.state?.gridData);
      const elevation = gridData?.find((s) => s.serviceId === serviceId)
        ?.serviceOptions[0]?.[elevationIndex];
      if (elevation) {
        if (action === "remove") {
          //first check if any sub-pli uses that day
          let isDayUsedInSubPLI = false;
          // if(serviceId.toString() !== "3") {
          for (const pli of elevation?.items || []) {
            let emptyProgresses = [];
            let scheduledDaysInPli = {};
            for (const d of pli?.days) {
              emptyProgresses.push({ day: d, progress: 0 });
              scheduledDaysInPli = {
                ...scheduledDaysInPli,
                [d]: {
                  startTime: "",
                  endTime: "",
                },
              };
              // }
              pli["progressByDay"] = emptyProgresses;
              pli["totalProgress"] = 0;
              if (isDayUsedInSubPLI === false)
                isDayUsedInSubPLI = this.isDayUsedInSubPLI_or_Addon(pli, day); //if we are still in init phase
            }
          }

          newDays = newDays.filter((d) => d !== day);
        } else if (action === "add") {
          newDays.push(day);
          newDays = sortDayIds(this?.props?.scheduleDays, newDays);
        }
        for (const pli of elevation?.items) {
          let updateResult = this.setDaysInPLI(pli, newDays, { serviceId });

          updatedAtLeastOneRow = updateResult.isPliUpdated;
        }

        if (updatedAtLeastOneRow === false) {
          return;
        }
        this.setState({ gridData });
        this.props.handlePlis(gridData, this?.props?.estimationId);
      }
    }
    this.updateDaysInElevation(serviceId, elevationIndex, newDays, true); //finally set days in elevation days
  };

  setDaysInPLI = (
    params,
    pli,
    totalDays,
    { serviceId = "" },
    action = "",
    day
  ) => {
    const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
    let isPliUpdated = false;

    if (prevPliCompleted(pli)) {
      message.info(
        `Pli ${pli?.id} has no remaining progress, which means it is completed! Please proceed to the next pli!`
      );
    }
    if (pliEditedFromNextSchedules(pli)) {
      message.info(
        `Pli ${pli?.id} is edited in ${pli?.editedFrom?.scheduleName}, please proceed to the next pli!`
      );
    }
    if (pli?.typeOfProgress === "breakdown") {
      message.warning(
        `Pli ${pli?.id} has progress with breakdowns, please proceed giving progress with breakdowns!`
      );
    }
    if (pli?.subPli?.length) {
      message.warning(
        `Pli ${pli?.id} has Sub Pli(s) , please proceed adding days in Sub Pli(s)!`
      );
    }

    if (pli?.locked) {
      return pli;
    }

    if (pli?.subPli === undefined || pli?.subPli?.length === 0) {
      pli.days =
        pli?.typeOfProgress === "breakdown"
          ? [
              ...new Set([
                ...pli?.days,
                ...pli?.breakdownValue?.flatMap(({ days = [] }) => days),
              ]),
            ]
          : unablePli(pli)
          ? [...new Set([...pli?.days])]
          : [...new Set([...pli?.days, ...totalDays])];

      let usedScheduleDays = {};
      if (unablePli(pli) || pli?.typeOfProgress === "breakdown") {
        usedScheduleDays = pli?.usedScheduleDays;
      } else {
        usedScheduleDays = daysScheduledInServices(
          {
            ...pli,
            days: [
              ...(pli?.isPliCompletedInThePreviousSchedule?.prevDays || []),
              ...(totalDays || []),
            ],
          },
          this?.props?.scheduleDays,
          this?.props?.scheduleName,
          ""
        );
      }

      pli.usedScheduleDays = usedScheduleDays;

      pli.progressByDay =
        pli?.typeOfProgress === "breakdown"
          ? []
          : unablePli(pli)
          ? []
          : get_new_progressByDay(
              Object.keys(usedScheduleDays),
              pli?.progressByDay,
              {
                d1,
                d2,
              }
            );

      isPliUpdated = this.setDaysInPliAddons(pli, totalDays);
    } else {
      //update only in addons
      isPliUpdated = this.setDaysInPliAddons(pli, totalDays);
    }

    return { isPliUpdated };
  };

  /**
   * Comes from this.setDaysInPLI. It is a helper function.
   * @param pli {Object} - a normal PLI
   * @param totalDays {string[]} - a list with day IDs
   *
   * @return boolean - If there were addons to update, return true, else false is returned
   * */
  setDaysInPliAddons = (pli, totalDays) => {
    if (pli.addons && pli.addons.length > 0) {
      let addons_updated = [];
      for (const addon of pli.addons) {
        addon.days = totalDays;
        addons_updated.push(
          <p>
            {getAddonName(addon)} Dimensions: {getItemDimensionsAsString(addon)}
          </p>
        ); //add some description whcih addon was updated
      }
      return true;
    }
    return true;
  };

  /**
   * Helper function to check if a day is used in sub-pli or addon. Used in this.updateDaysFromElevationToRows
   * May be replaced with more modern function in filters file where there are days checkers.
   * */
  isDayUsedInSubPLI_or_Addon = (pli, day) => {
    let isDayUsedInSubPLI = false;
    if (pli.subPli) {
      for (const subPLI of pli.subPli) {
        if (subPLI.days?.includes(day)) {
          isDayUsedInSubPLI = { pli_id: getItemDimensionsAsString(pli) };
          break;
        }
      }
    }
    if (pli.addons) {
      for (const addon of pli.addons) {
        if (addon.days?.includes(day)) {
          isDayUsedInSubPLI = { pli_id: getItemDimensionsAsString(pli) };
          break;
        }
      }
    }
    return isDayUsedInSubPLI;
  };

  concatDays_From_PLI_ToElevation = (
    serviceId,
    elevationIndex,
    rowDays,
    pliData
  ) => {
    this.updateDaysInElevation(serviceId, elevationIndex, rowDays);
    const gridData = _.cloneDeep(this?.state?.gridData);
    const elevation = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    )?.serviceOptions[0]?.[elevationIndex];
    for (let i = 0; i < elevation?.items?.length; i++) {
      if (elevation?.items?.[i]?.id === pliData?.id) {
        if (
          [
            ...(pliData?.isPliCompletedInThePreviousSchedule?.prevDays || []),
            ...rowDays,
          ]?.length === 0
        ) {
          pliData.days = [];
          pliData.progressByDay = [];
          pliData.usedScheduleDays = {};
          pliData.totalProgress = 0;
          pliData.typeOfProgress = "";
          elevation.items[i] = pliData;
        } else {
          pliData.usedScheduleDays = daysScheduledInServices(
            {
              ...pliData,
              days: [
                ...(pliData?.isPliCompletedInThePreviousSchedule?.prevDays?.filter(
                  (b) => !rowDays?.some((a) => a === b)
                ) || []),
                ...rowDays,
              ],
            },
            this?.props?.scheduleDays,
            this?.props?.scheduleName,
            ""
          );
          pliData.progressByDay =
            pliData?.progressByDay?.filter((el) =>
              Object.keys(pliData?.usedScheduleDays || {})?.includes(el?.day)
            ) || [];
          pliData.totalProgress = totalProgressPli(pliData, serviceId);
        }
        elevation.items[i] = pliData;
        break;
      }
    }
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  unlockPliIfPrevScheduleDeleted = (
    serviceId = "",
    elevationIndex = "",
    pliData = {},
    unlockPli = false
  ) => {
    const gridData = _.cloneDeep(this?.state?.gridData);
    let pli = gridData
      ?.find((s) => s?.serviceId?.toString() === serviceId?.toString())
      ?.serviceOptions?.[0]?.[elevationIndex]?.items?.find(
        (p) => p?.id === pliData?.id
      );
    if (pliEditedFromNextSchedules(pli) && !unlockPli) {
      delete pli?.editedFrom;
    }
    if (!!unlockPli) {
      pli.locked =
        typeof pliData?.locked === "boolean" ? !pliData?.locked : true;
    }

    this.setState({ gridData });

    this.props.handlePlis(gridData, this?.props?.estimationId);

    this.agGridApiForEachElevation[serviceId][elevationIndex].api.setRowData(
      gridData?.find((s) => s?.serviceId?.toString() === serviceId?.toString())
        ?.serviceOptions?.[0]?.[elevationIndex]?.items
    );
  };

  /**
   * This function is called from daysInScheduleMaster, when we are working in sub-pli level.
   * @param serviceId {number} - id of service we are working
   * @param elevationIndex {number} - index of elevation we are working
   * @param pliData {Object} - sub-pli object
   * @param total_subPLI_days {string[]} - a merge of all days in all sub-plis of the PLi we are working under
   * */

  updateDays_From_SubPLI_to_PLI = (
    serviceId,
    elevationIndex,
    pliData,
    total_subPLI_days
  ) => {
    const { parentId, id } = pliData; //parentId is PLI it belong id. serviceId, elevationIndex and parentId are indexes we need to find where is PLI located
    if (
      this.agGridApiForEachElevation[serviceId] &&
      this.agGridApiForEachElevation[serviceId][elevationIndex]
    ) {
      let ElevationDays = [];
      const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
      // console.log(total_subPLI_days)

      let parentNode =
        this.agGridApiForEachElevation[serviceId][
          elevationIndex
        ].api.getRowNode(parentId);

      this.agGridApiForEachElevation[serviceId][elevationIndex].api.redrawRows({
        rowNodes: [parentNode],
      });

      const gridData = _.cloneDeep(this?.state?.gridData);
      const elevation = gridData?.find(
        (s) => s?.serviceId?.toString() === serviceId?.toString()
      )?.serviceOptions[0]?.[elevationIndex];
      if (elevation) {
        for (let i = 0; i < elevation?.items?.length; i++) {
          if (elevation?.items?.[i]?.id === parentId) {
            parentNode.detailNode.data.days = [
              ...new Set(
                parentNode?.detailNode?.data?.subPli?.flatMap(
                  ({ days }) => days
                )
              ),
            ];

            parentNode.detailNode.data.usedScheduleDays =
              daysScheduledInServices(
                {
                  ...parentNode?.detailNode?.data,
                  days: [
                    ...total_subPLI_days,
                    ...(parentNode?.detailNode?.data
                      ?.isPliCompletedInThePreviousSchedule?.prevDays || []),
                  ],
                },
                this?.props?.scheduleDays,
                this?.props?.scheduleName,
                ""
              );
            parentNode.detailNode.data.subPli =
              parentNode?.detailNode?.data?.subPli?.map((s) => {
                if (prevSubPliCompleted(s)) {
                  s = s;
                } else {
                  s.progressByDay = get_new_progressByDay(
                    [
                      ...new Set([
                        ...(s?.days || []),
                        ...(s?.isPliCompletedInThePreviousSchedule?.prevDays ||
                          []),
                      ]),
                    ],
                    s?.progressByDay,
                    {
                      d1,
                      d2,
                    }
                  );
                  s.totalProgress = totalProgressPli(s, serviceId);
                }
                return s;
              });
            parentNode.detailNode.data.progressByDay =
              progressByDayScheduling(
                parentNode?.detailNode?.data?.subPli?.flatMap(
                  ({ progressByDay = [] }) => progressByDay
                ),
                "day"
              )?.filter((el) =>
                Object.keys(
                  parentNode?.detailNode?.data?.usedScheduleDays || {}
                )?.includes(el?.day)
              ) || [];
            parentNode.detailNode.data.totalProgress = totalProgressPli(
              parentNode?.detailNode?.data,
              serviceId
            );

            elevation.items[i] = parentNode?.detailNode?.data; //replace PLi with new pli data
          }
          ElevationDays = ElevationDays.concat(elevation.items[i].days); //merge days
        }
      }

      //concat all pli rows
      ElevationDays = [...new Set(ElevationDays)];
      ElevationDays = sortDayIds(this.props.scheduleDays, ElevationDays);

      this.setState({ gridData });
      this.props.handlePlis(gridData, this?.props?.estimationId);
      this.updateDaysInElevation(serviceId, elevationIndex, ElevationDays);
    }
    this.props.setAbleToSave(true);
  };

  /**
   * This updates addon inside a PLI with new days it has taken. Also it updates days in elevation
   * */
  updateDays_From_Addons_to_PLI = (
    serviceId,
    elevationIndex,
    addonData,
    selectedDays
  ) => {
    const { parentId, id } = addonData;

    if (
      this.agGridApiForEachElevation[serviceId] &&
      this.agGridApiForEachElevation[serviceId][elevationIndex]
    ) {
      const gridData = _.cloneDeep(this.state.gridData);
      const elevation = gridData?.find(
        (s) => s.serviceId.toString() === serviceId.toString()
      )?.serviceOptions[0]?.[elevationIndex];
      if (elevation) {
        const correctPli = elevation?.items?.find(
          (pli) => pli?.id === parentId
        );

        for (let a = 0; a < correctPli.addons.length; a++) {
          if (correctPli.addons[a].id === id) {
            correctPli.addons[a] = addonData;
            break;
          }
        }
      }

      let elevationDays = getElevationDays(elevation, this.props.scheduleDays);

      this.setState({ gridData });
      this.props.handlePlis(gridData, this?.props?.estimationId);
      this.updateDaysInElevation(serviceId, elevationIndex, elevationDays);
    }
  };

  updateDays_from_serviceAddons = ({ serviceId, addon }) => {
    const gridData = _.cloneDeep(this.state.gridData);
    const service = gridData?.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );
    if (!service?.isSelected) return;

    for (let i = 0; i < service.serviceAddons.length; i++) {
      const serviceAddon = service.serviceAddons[i];
      if (serviceAddon.id === addon.id) {
        service.serviceAddons[i] = addon;
      }
    }
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  //progress
  /**
   * It may come from many components which set progress of an item.
   * @param {Object} item - pli/sub-pli/addon
   * @param {ProgressOriginType} item_origin - what kind of item is
   * @param serviceId {number} - id of service we are working
   * @param elevationIndex {number} - index of elevation we are working
   * */
  setItemAfterProgress = (
    item,
    item_origin,
    { serviceId, elevationIndex },
    d1,
    d2
  ) => {
    const gridData = _.cloneDeep(this.state.gridData);
    const service = gridData.find(
      (s) => s.serviceId.toString() === serviceId.toString()
    );

    const elevation = service?.serviceOptions[0]?.[elevationIndex];

    const { totalService = 0, serviceProgress = 0 } = serviceTotalAndProgress(
      service,
      item,
      d1,
      d2,
      elevation
    );

    const { totalElevation = 0, elevationProgress = 0 } =
      elevationTotalAndProgress(elevation, d1, d2, item, elevation);

    this.props.updateProgressLogs(
      item?.progressByDay
        ?.map((day, index) => {
          const { day: dayId, progress = 0 } = day || {};
          const usedScheduleDay = item?.usedScheduleDays?.[dayId] || {};
          const { startDate, endDate } = usedScheduleDay || {};

          if (!!day?.changed) {
            return {
              [d1]: day?.[d1],
              ...(d2 && { [d2]: day?.[d2] }),
              dayId,
              startDate: dayjsNY(startDate).valueOf(),
              endDate: dayjsNY(endDate).valueOf(),
              totalElevation,
              elevationProgress,
              totalService,
              serviceProgress,
              typeOfProgress: item?.typeOfProgress,
              dayProgress: !d2
                ? `${d1}: ${progress + " " + "lf"}`
                : `${d1}: ${day?.[d1] + " " + "lf"}, ${d2}: ${
                    day?.[d2] + " " + "lf"
                  }`,
              estimationId: this?.props?.estimationId,
              progressValues: !d2
                ? [{ [d1]: progress }]
                : [{ [d1]: day?.[d1] }, { [d2]: day?.[d2] }],
              estimation:
                service?.quickbooksNumber || service?.estimationNumber || "1",
              serviceId,
              elevationId: elevation?.elevationId,
              totalPli: !d2 ? item?.[d1] : item?.[d1] * item?.[d2],
              pliProgressPercentage: !d2
                ? (progress / item?.[d1]) * 100
                : (progress / (item?.[d1] * item?.[d2])) * 100,

              length: item?.length || item?.originalPli?.length,
              width: item?.width || item?.originalPli?.width,
              height: item?.height || item?.originalPli?.height,
              surface: item?.surface || item?.originalPli?.surface,
              pliProgress: item?.totalProgress,
              firstDateOnSchedule: dayjsNY(
                this?.props?.scheduleDays?.[0]?.startDate
              ).valueOf(),
              pliRemainingProgress: !d2
                ? item?.[d1] - item?.totalProgress
                : item?.[d1] * item?.[d2] - item?.totalProgress,
              serviceName: service?.label,
              elevation:
                elevation?.elevationLabel + " - " + elevation?.elevationId,
              elevationStatus: elevation?.status || "",
              pli: item?.id,
              user: this?.state?.userConfiguration?.nameOfUser,
              progressTimeGiven: Date.now(),
            };
          } else {
            return null;
          }
        })
        ?.filter(Boolean)
    );

    if (item_origin === PROGRESS_ORIGIN?.PLI) {
      //depending on origin where item came from, update it
      for (let i = 0; i < elevation?.items?.length; i++) {
        if (elevation?.items?.[i]?.id === item?.id) {
          if (Array.isArray(item?.progressByDay)) {
            item.progressByDay.forEach((el) => {
              delete el.changed;
            });
          }
          elevation.items[i] = item;
          break;
        }
      }
    } else if (item_origin === PROGRESS_ORIGIN?.SUB_PLI) {
      const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
      let pliNode = this.agGridApiForEachElevation?.[serviceId]?.[
        elevationIndex
      ]?.api?.getRowNode(item?.parentId);
      let pli = pliNode?.data;

      let tlSubPliProgress = 0;
      for (let i = 0; i < pli?.subPli?.length; i++) {
        //update subpli
        if (pli.subPli[i].id === item.id) {
          if (prevSubPliCompleted(pli?.subPli?.[i])) {
            pli.subPli[i] = pli?.subPli?.[i];
          }
          pli.subPli[i] = item;
        }
        tlSubPliProgress += pli.subPli[i]["totalProgress"];
      }
      pli["totalProgress"] = tlSubPliProgress;
      pli["progressByDay"] = progressByDayScheduling(
        pli?.subPli?.flatMap((el) => el?.progressByDay),
        "day"
      );
      pli["typeOfProgress"] = "subPli";
      for (let i = 0; i < elevation?.items?.length; i++) {
        if (elevation?.items?.[i]?.id === pli?.id) {
          elevation.items[i] = pli;
          break;
        }
      }
    } else if (item_origin === PROGRESS_ORIGIN.SERVICE_ADDON) {
      message.error("Service addon progress is not implemented yet");
      for (let i = 0; i < service.serviceAddons.length; i++) {
        if (service.serviceAddons[i].id === item.id) {
          service.serviceAddons[i] = item;
          break;
        }
      }
    } else if (item_origin === PROGRESS_ORIGIN.PLI_ADDON) {
      let pliNode = this.agGridApiForEachElevation[serviceId][
        elevationIndex
      ].api.getRowNode(item.parentId);
      let pli = pliNode.data;

      for (let i = 0; i < pli.addons.length; i++) {
        //update subpli
        if (pli?.addons[i]?.id === item.id) {
          pli.addons[i] = item;
        }
      }

      //this fixed duplication by changing item.id to pli.id
      for (let i = 0; i < elevation?.items?.length; i++) {
        if (elevation?.items?.[i]?.id === pli?.id) {
          elevation.items[i] = pli;
          break;
        }
      }
    } else {
      throw new Error(
        "PLIPerService: setItemAfterProgress: Please define origin pli, addon, serviceAddon or sub_pli"
      );
    }

    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
    this.agGridApiForEachElevation?.[serviceId]?.[
      elevationIndex
    ]?.api?.refreshCells();
  };

  /**
   * A special function for hoist. Hoist floors (treated as PLI) can come from some custom components such as jump modals.
   * We easily get the whole elevation as parameter which has been made up from these components and we just need to put it state,
   * ag-grid rows and update it in parent.
   * */

  setHoistPLis = (elevation, { serviceId, elevationIndex }) => {
    const gridData = _.cloneDeep(this?.state?.gridData);
    const service = gridData?.find(
      (s) => s.serviceId?.toString() === serviceId?.toString()
    );
    if (!service?.isSelected) return;
    service.serviceOptions[0][elevationIndex] = elevation;

    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);

    this.agGridApiForEachElevation?.[serviceId]?.[
      elevationIndex
    ]?.api?.setRowData(elevation?.items);
    this.agGridApiForEachElevation?.[serviceId]?.[
      elevationIndex
    ]?.api?.refreshCells();
  };
  //when using notes
  saveDataFromRTDEditor = (serviceIndex, optionIndex, elevationIndex, pli) => {
    const gridData = _.cloneDeep(this?.state?.gridData);
    gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items =
      gridData?.[serviceIndex]?.serviceOptions?.[optionIndex]?.[
        elevationIndex
      ]?.items?.map((a) => {
        return a.id === pli.id ? pli : a;
      });
    this.agGridApiForEachElevation?.[gridData?.[serviceIndex]?.serviceId]?.[
      elevationIndex
    ]?.api?.forEachNode((n) => {
      if (n?.data?.id === pli?.id) {
        n.data = pli;
      }
    });

    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  onAddonRichTextChange = ({
    service,
    addonType,
    addons,
    serviceIndex,
    optionIndex,
    elevationIndex,
    pliId,
  }) => {
    let gridData = _.cloneDeep(this.state.gridData);
    if (addonType === AddonType.serviceAddon || addonType === "documentation") {
      let correctService = gridData?.find(
        (s) => s?.serviceId === service?.serviceId
      );
      correctService.serviceAddons = service.serviceAddons;
    } else if (addonType === AddonType.pli) {
      let pli;
      // if(gridData[serviceIndex].serviceId === 3) {
      //   pli = gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].item.test.items.find(pli => pli.id === pliId)
      // } else {
      pli = gridData[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items.find((pli) => pli.id === pliId);
      // }
      pli.addons = addons;
    }

    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };
  //when clicking (-) in split
  onSubPLIAddRemove = (serviceId, elevationIndex, pli) => {
    const gridData = _.cloneDeep(this?.state?.gridData);
    const service = gridData?.find((sl) => sl?.serviceId === serviceId);

    let allElevationPLIs =
      service?.serviceOptions?.[0]?.[elevationIndex]?.items;

    for (let i = 0; i < allElevationPLIs?.length; i++) {
      if (allElevationPLIs?.[i]?.id === pli?.id) allElevationPLIs[i] = pli;
    }

    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  onSetBreakDownsInElevation = (serviceId, elevationIndex, breakdowns) => {
    const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
    let gridData = _.cloneDeep(this?.state?.gridData) || [];
    const service = gridData?.find((s) => s?.serviceId === serviceId);
    service.serviceOptions[0][elevationIndex].breakdownValue = breakdowns;
    service.serviceOptions[0][elevationIndex].items =
      setBreakDownsInElevationRows_agGrid(
        service?.serviceOptions?.[0]?.[elevationIndex]?.items,
        breakdowns,
        serviceId,
        this?.props?.scheduleDays,
        this?.props?.scheduleName,
        this?.props?.typeOfWork
      );

    this.agGridApiForEachElevation?.[serviceId]?.[
      elevationIndex
    ]?.api?.setRowData(service?.serviceOptions?.[0]?.[elevationIndex]?.items);

    // this.agGridApiForEachElevation?.[serviceId]?.[
    //   elevationIndex
    // ]?.api?.forEachNode((n) => {
    //   if (
    //     n?.data?.typeOfProgress === "breakdown" ||
    //     n?.data?.typeOfProgress === ""
    //   ) {
    //     n.data.breakdownValue = breakdowns;
    //     n.data.totalProgress =
    //       breakdowns?.length > 0
    //         ? breakdowns
    //             ?.map?.(({ progressByDay = [], rate }) =>
    //               breakInspProgress("Installation", progressByDay, rate)
    //             )
    //             ?.reduce?.((a, b) => a + b, 0) *
    //           (d2 === undefined
    //             ? (n?.[d1] || 0) / 100
    //             : (n?.[d1] * n?.[d2] || 0) / 100)
    //         : 0;
    //     n.setDataValue("breakdownValue", breakdowns);
    //   }
    // });

    this.updateDaysInElevation(serviceId, elevationIndex, [
      ...new Set(breakdowns?.flatMap?.(({ days = [] }) => days)),
    ]);
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  /**
   * Comes from Breakdown component that is placed in pli. This will set data for that individual pli. If this is used
   * breakdowns in elevation are reset to zero in everything as intended that user is using breakdowns for each pli and not in elevation level.
   * */
  onBreakdownSetInPLI = (
    items,
    serviceId,
    optionIndex,
    elevationIndex,
    breakdowns,
    pli,
    { d1, d2 }
  ) => {
    const gridData = _.cloneDeep(this?.state?.gridData);
    const service = gridData?.find((s) => s?.serviceId === serviceId);
    service.serviceOptions[optionIndex][elevationIndex].items = items;

    this.updateDaysInElevation(serviceId, elevationIndex, [
      ...new Set(breakdowns?.flatMap?.((b) => b?.days)),
    ]);
    this.props.updateProgressLogs(this?.state?.breakdownLogs);
    this.setState({
      gridData,
      breakdownLogs: [],
    });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  resetProgressElevation = (
    serviceId,
    elevationIndex,
    jumps = [],
    onlyProgress = false
  ) => {
    let gridData = _.cloneDeep(this?.state?.gridData);
    let service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    const { newItems = [], newJumps = [] } = reset(
      serviceId,
      service?.serviceOptions?.[0]?.[elevationIndex]?.items,
      "resetProgressElevation",
      onlyProgress,
      this?.props?.scheduleDays,
      jumps
    );

    service.serviceOptions[0][elevationIndex].items = newItems || [];

    if (Array.isArray(jumps) && jumps?.length > 0) {
      service.serviceOptions[0][elevationIndex].jumps = newJumps || [];
    }
    this.agGridApiForEachElevation?.[serviceId]?.[
      elevationIndex
    ].api.redrawRows();
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
    message.success("Reset successful!");
  };
  resetProgressService = (serviceId, onlyProgress = false) => {
    let gridData = _.cloneDeep(this?.state?.gridData);
    let service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    service = reset(
      serviceId,
      service,
      "resetProgressService",
      onlyProgress,
      this?.props?.scheduleDays
    );
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
    message.success("Reset successful!");
  };
  resetProgressEstimation = (onlyProgress = false) => {
    let gridData = _.cloneDeep(this?.state?.gridData);
    gridData = reset(
      undefined,
      gridData,
      "resetProgressEstimation",
      onlyProgress,
      this?.props?.scheduleDays
    );

    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
    message.success("Reset successful!");
  };

  removeAllBreakdowns = (serviceId, elevationIndex) => {
    let gridData = _.cloneDeep(this?.state?.gridData);
    let service = gridData?.find(
      (s) => s?.serviceId?.toString() === serviceId?.toString()
    );
    service.serviceOptions[0][elevationIndex].breakdownValue = [];
    service.serviceOptions[0][elevationIndex].items.forEach((pli) => {
      pli.breakdownValue = [];
    });

    this.agGridApiForEachElevation?.[serviceId]?.[
      elevationIndex
    ].api.redrawRows();
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  //other
  handleLayoutChange = (newLayout) => {
    //when we reorder services in ServiceMenu
    const gridData = _.cloneDeep(this?.state?.gridData);
    let sortedNewLayout = newLayout.sort((a, b) => a.y - b.y);
    let newGrid = sortedNewLayout.map((item) =>
      gridData.find((s) => s.label === item.i)
    );
    this.setState({ gridData: newGrid });
  };
  handleServiceSelected = (index, checked) => {
    let gridData = _.cloneDeep(this?.state?.gridData);
    this.setState({
      loadingService: { visible: true, service: gridData?.[index]?.label },
    });
    setTimeout(
      () => {
        gridData?.forEach((service) => {
          service.isSelected = false;
        });
        gridData[index].isSelected = checked;
        this.setState({
          gridData,
          loadingService: { visible: false, service: "" },
        });
      },

      checked ? 1000 : 500
    );
    this.setState({ gridData });
    this.props.handlePlis(gridData, this?.props?.estimationId);
  };

  newServicesFoldersCreated = [];

  render() {
    const {
      gridData,
      daysInElevations,
      breakdownsInElevations,
      userConfiguration,
    } = this.state;

    const {
      scheduleDays,
      serviceDefinitions,
      pricing,
      units,
      dayColors,
      estimationIndex,
      estimationId,
      typeOfWorkObject,
    } = this.props;
    const {
      concatDays_From_PLI_ToElevation,
      unlockPliIfPrevScheduleDeleted,
      onSubPLIAddRemove,
      onBreakdownSetInPLI,
      updateDays_From_Addons_to_PLI,
      saveDataFromRTDEditor,
      handleServiceSelected,
      handleLayoutChange,
      setItemAfterProgress,
      updateDays_From_SubPLI_to_PLI,
      subPliCellValueChanged,
      frameworkComponents,
      getRowNodeId,
      getDetailGridInfo,
      updateDays_from_serviceAddons,
      setHoistPLis,
    } = this;

    const { progressPercentage } = ProgressStatistics.estimationTotal(gridData);

    const isNew = (curr, prev) => newServicesAdded(curr || {}, prev || {});

    const updateGridDataFromShortcut = (gridData = []) => {
      this.setState({ gridData });
      this.props.handlePlis(gridData, this?.props?.estimationId);
    };

    return (
      <div className={"pli-per-service"}>
        <ClassRedux
          {...{
            varName: "userConfiguration",
            stateName: "userConfig",
            setState: (userConfiguration) =>
              this.setState({ userConfiguration }),
          }}
        />
        <div style={{ width: "100%", paddingRight: 10 }}>
          <Spin
            fullscreen
            tip={`Loading ${this?.state?.loadingService?.service}...`}
            spinning={this?.state?.loadingService?.visible}
          />

          {gridData?.length > 0 && (
            <div style={{ width: "100%" }}>
              <ServiceMenu
                {...{
                  estimationIndex,
                  estimationId,
                  gridData,
                  progressPercentage,
                  handleServiceSelected,
                  handleLayoutChange,
                  project: this?.props?.project,
                  unChangedRowData: this?.props?.unChangedRowData,
                  resetProgressEstimation: this.resetProgressEstimation,
                  typeOfWork: this?.props?.typeOfWork,
                  updateChanges: this.updateChanges,
                  serviceDefinitions,
                  thisProjectSchedules: this?.props?.thisProjectSchedules || [],
                  createdAt: this?.props?.rowData?.createdAt,
                  editMode: this?.props?.editMode,
                }}
              />
            </div>
          )}
          <div>
            <div>
              {gridData?.map((service, serviceIndex) => {
                if (!service?.isSelected) return;
                const { d1, d2 } = get_ProgressDimensionsByServiceId(
                  service?.serviceId
                );
                const {
                  serviceId,
                  isSelected,
                  label = "",
                  serviceOptions = [],
                  serviceAddons = [],
                  estimationNumber = "",
                  isNotAppliedForProgress = false,
                } = service;

                let documentationLabel = "";
                if (!estimationNumber) {
                  documentationLabel = `${label} -`;
                } else {
                  documentationLabel = `${label} - ${estimationNumber}`;
                }

                if (
                  this?.props?.editMode &&
                  !this.newServicesFoldersCreated.includes(
                    documentationLabel
                  ) &&
                  (isNew(
                    this?.props?.unChangedRowData?.unchanged?.toBeScheduled ||
                      {},
                    this?.props?.project?.services || {}
                  )?.[estimationId]?.includes(serviceId?.toString()) ||
                    newEstimationsAdded(
                      this?.props?.unChangedRowData?.unchanged?.toBeScheduled ||
                        {},
                      this?.props?.project?.services || {},
                      estimationId
                    ))
                ) {
                  this.newServicesFoldersCreated.push(documentationLabel);

                  driveFolderFunction({
                    day: documentationLabel,
                    addGoogleDriveFolderToQueue:
                      this.props?.addGoogleDriveFolderToQueue,
                    updateGoogleDriveFolders:
                      this.props?.updateGoogleDriveFolders,
                    driveRequest: this.props?.driveRequest,
                    action: "create-folder",
                    parentFolderId:
                      this.props?.googleDriveFolderIds?.["docObject"],
                  });
                }

                const { progressPercentage } =
                  ProgressStatistics.serviceTotal(service);

                const total = service?.serviceOptions?.[0]
                  ?.flatMap(({ items = [] }) => items)
                  ?.reduce(
                    (a, b) => a + (!d2 ? b?.[d1] : b?.[d1] * b?.[d2]),
                    0
                  );

                const elevationNr = service?.serviceOptions?.[0]?.length || 0;
                const serviceAddonNr = service?.serviceAddons?.length || 0;

                // const daysWithProgressInService = progressDaysForService(
                //   estimationId,
                //   service,
                //   this?.props?.typeOfWork,
                //   scheduleDays
                // );

                // const lastProgressDay =
                //   daysWithProgressInService?.[
                //     daysWithProgressInService?.length - 1
                //   ]?.startDate || "";

                // const lastProgressDayForElevation = (elId = "") => {
                //   const temp = daysWithProgressInService?.filter(
                //     ({ elevationId: id, estimationId: estId }) =>
                //       id === elId && estId === estimationId
                //   );
                //   return temp?.[temp?.length - 1]?.startDate || "";
                // };

                return (
                  <Badge.Ribbon
                    text={
                      <div>
                        <span>
                          {`${elevationNr} ${
                            elevationNr === 1 ? "elevation" : "elevations"
                          } and ${serviceAddonNr} ${
                            serviceAddonNr === 1 ? "addon" : "addons"
                          } in total for ${label}!`}
                        </span>
                      </div>
                    }
                    color={assignServiceColor?.[serviceId] || "black"}
                  >
                    <div
                      key={serviceId}
                      className="serviceContainerSch"
                      style={{
                        display: isSelected ? "block" : "none",
                        boxShadow: `0px 2px 2px ${
                          assignServiceColor?.[serviceId] ?? "red"
                        }`,
                        marginTop: 10,
                        padding: "10px 10px 0px 10px",
                      }}
                    >
                      <div>
                        <Badge
                          color={assignServiceColor[serviceId] || "black"}
                          text={
                            <span
                              style={{
                                width: "100%",
                                fontSize: 16,
                                color: "#323338",
                                fontWeight: 600,
                              }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  gap: "1rem",
                                  marginTop: -38,
                                  marginLeft: 10,
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    marginTop: 10,
                                    textDecoration: isNotAppliedForProgress
                                      ? "line-through"
                                      : "none",
                                    color: isNotAppliedForProgress
                                      ? "#fe4c4a"
                                      : "",
                                  }}
                                >
                                  {label}
                                </span>
                                <div style={{ position: "relative", top: 4 }}>
                                  <CustomProgressBar
                                    progressDone={progressPercentage || 0}
                                    breakDownProp={true}
                                  />
                                </div>

                                {!!this.props?.googleDriveFolderIds?.[
                                  documentationLabel
                                ] && (
                                  <ServiceDocumentation
                                    {...{
                                      label: documentationLabel,
                                      allFolders:
                                        this.props?.googleDriveFolderIds,
                                      scheduleDays,
                                      updateGoogleDriveFolders:
                                        this?.props?.updateGoogleDriveFolders,
                                      serviceDays:
                                        getDaysForSpecificService(
                                          serviceOptions
                                        ),
                                      key: serviceId,
                                      setAbleToSave: this.props.setAbleToSave,
                                      disabled: !this?.props?.isWritable,
                                      isDarkMode: this?.props?.isDarkMode,
                                    }}
                                  />
                                )}

                                {!checkIfServiceIsHoist(service) &&
                                  !checkIfServiceIsOtherScope(service) && (
                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: 7,
                                      }}
                                    >
                                      Total: {isNaN(total) ? 0 : total}
                                    </span>
                                  )}
                                {/* <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: 7,
                                  }}
                                >
                                  <UndoProgressSchedule
                                    {...{
                                      title: `Reset progress or all days for ${label}!`,
                                      reset: this.resetProgressService,
                                      needed: [serviceId],
                                    }}
                                  />
                                </span> */}

                                {service?.note?.trim()?.length > 0 && (
                                  <Tooltip
                                    title={maskNumbers(
                                      removeHTMLTags(service?.note?.trim())
                                    )}
                                  >
                                    <div
                                      style={{
                                        marginTop: 8,
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: 5,
                                        color: "#3A3C4E",
                                        backgroundColor: !this?.props
                                          ?.isDarkMode
                                          ? "#f0f0f0"
                                          : "#3A3C4E",
                                      }}
                                    >
                                      <span style={{ fontSize: 13 }}>
                                        {maskNumbers(
                                          removeHTMLTags(service?.note?.trim())
                                        )}
                                      </span>
                                    </div>
                                  </Tooltip>
                                )}
                                {/* {!!this?.props?.editMode && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      gap: 10,
                                      marginTop: 6,
                                    }}
                                    key={lastProgressDay}
                                    className="last-progress-day"
                                  >
                                    <span>Last progress day for {label}:</span>
                                    <b
                                      style={{
                                        color: !lastProgressDay
                                          ? "#fe4c4a"
                                          : typeOfWorkColors?.[
                                              this?.props?.typeOfWork
                                            ] || "black",
                                      }}
                                    >
                                      {lastProgressDay ||
                                        "All days of this schedule DO NOT have progress for this service!"}
                                    </b>
                                  </div>
                                )} */}
                              </span>
                            </span>
                          }
                        />
                        <Collapse
                          // accordion
                          destroyInactivePanel={true}
                          key={serviceId}
                          style={{
                            marginBottom: 5,
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            border: "none",
                            // justifyContent: "center",
                          }}
                          // collapsible={
                          //   this?.props?.editMode && !this?.props?.isWritable
                          //     ? "disabled"
                          //     : ""
                          // }
                          className="schedule-collapse-days"
                          expandIcon={({ isActive }) => (
                            <CollapseArrow
                              style={{
                                transform: isActive ? "rotate(90deg)" : "",
                              }}
                              height={12}
                              width={12}
                            />
                          )}
                        >
                          {serviceOptions?.[0]?.map?.(
                            (elevation, elevationIndex) => {
                              let elevationHeight =
                                this.state.UIFeaturesForEachElevation?.[
                                  serviceId
                                ]?.[elevationIndex]?.total_height || 250;
                              if (checkIfServiceIsHoist(service))
                                elevationHeight += 100;

                              const {
                                elevationLabel = "",
                                items = [],
                                elevationId = "",
                                elevationDescription = "",
                              } = elevation;

                              let elevationTotalSqft = 0;
                              if (!d2) {
                                elevationTotalSqft = items?.reduce(
                                  (a, b) => a + b?.[d1],
                                  0
                                );
                              } else {
                                elevationTotalSqft = items?.reduce(
                                  (a, b) => a + b?.[d1] * b?.[d2],
                                  0
                                );
                              }

                              const elevationTotalSqftProgress = items?.reduce(
                                (a, b) => a + b?.["totalProgress"],
                                0
                              );
                              const itemPercElev = isNaN(
                                elevationTotalSqftProgress / total
                              )
                                ? 0
                                : Math.round(
                                    (elevationTotalSqftProgress / total) * 100
                                  );

                              const { progressPercentage } =
                                ProgressStatistics.elevationTotal2(
                                  elevation,
                                  serviceId,
                                  this?.props?.typeOfWork
                                );

                              let columnDefs = getElevationColumns(
                                {
                                  service,
                                  serviceId,
                                  serviceIndex,
                                  elevationIndex,
                                  label,
                                  elevationLabel,
                                  typeOfWork: this?.props?.typeOfWork,
                                  editMode: this?.props?.editMode,
                                  setAbleToSave: this?.props?.setAbleToSave,
                                  updateAddonsForFloors:
                                    this.updateAddonsForFloors,
                                  elevationTotalSqft,
                                  isWritable: this?.props?.isWritable,
                                  updateProgressLogs:
                                    this.props.updateProgressLogs,
                                  estimationId: this?.props?.estimationId,
                                  elevationId,
                                  updateBreakdownLogs: this.updateBreakdownLogs,
                                },
                                {
                                  pricing,
                                  units,
                                  serviceDefinitions,
                                  scheduleDays,
                                  dayColors,
                                  typeOfWorkObject,
                                  gridData,
                                  userConfiguration,
                                },
                                {
                                  concatDays_From_PLI_ToElevation,
                                  unlockPliIfPrevScheduleDeleted,
                                  onSubPLIAddRemove,
                                  onBreakdownSetInPLI,
                                  updateDays_From_Addons_to_PLI,
                                  saveDataFromRTDEditor,
                                  setItemAfterProgress,
                                  scheduleName: this?.props?.scheduleName,
                                  isDarkMode: this?.props?.isDarkMode,
                                }
                              );

                              const detailCellRendererParams =
                                getCellRendererParams(
                                  {
                                    service,
                                    serviceId,
                                    serviceIndex,
                                    elevationIndex,
                                    label,
                                    elevationLabel,
                                    isWritable: true,
                                  },
                                  {
                                    scheduleDays,
                                    typeOfWorkObject,
                                    frameworkComponents,
                                  },
                                  {
                                    setItemAfterProgress,
                                    updateDays_From_SubPLI_to_PLI,
                                    subPliCellValueChanged,
                                    typeOfWork: this?.props?.typeOfWork,
                                    editMode: this?.props?.editMode,
                                    isDarkMode: this?.props?.isDarkMode,
                                  }
                                );

                              const onGridReady = (params) =>
                                this.onGridReady(params, items, "elevation", {
                                  serviceId,
                                  elevationIndex,
                                });
                              const getRowClass = (params) =>
                                getScheduleRowClass(params, serviceId);

                              const onRangeSelectionChanged = (event) => {
                                let cellRanges = event.api.getCellRanges();
                                if (cellRanges) {
                                  var sum = 0;
                                  cellRanges.forEach(function (range) {
                                    var startRow = Math.min(
                                      range.startRow.rowIndex,
                                      range.endRow.rowIndex
                                    );
                                    var endRow = Math.max(
                                      range.startRow.rowIndex,
                                      range.endRow.rowIndex
                                    );
                                    for (
                                      var rowIndex = startRow;
                                      rowIndex <= endRow;
                                      rowIndex++
                                    ) {
                                      range.columns.forEach(function (column) {
                                        var rowModel = event.api.getModel();
                                        var rowNode = rowModel.getRow(rowIndex);
                                        var value = event.api.getValue(
                                          column,
                                          rowNode
                                        );
                                        if (typeof value === "number") {
                                          sum += value;
                                        }
                                      });
                                    }
                                  });
                                  this.setState((prev) => ({
                                    ...prev,
                                    totalLength: !checkIfServiceIsHoist(service)
                                      ? {
                                          ...prev?.totalLength,
                                          [service?.serviceId +
                                          "_" +
                                          elevation?.elevationLabel +
                                          "_" +
                                          elevation?.elevationId]: sum || 0,
                                        }
                                      : {
                                          hoist: 0, //  Hoist does not have length so we set it to 0 to avoid errors
                                        },
                                  }));
                                }
                                return { sum: 0 };
                              };
                              const totalDimensions =
                                this?.state?.totalLength?.[
                                  service?.serviceId +
                                    "_" +
                                    elevation?.elevationLabel +
                                    "_" +
                                    elevation?.elevationId
                                ];

                              const selectedDays =
                                daysInElevations?.[serviceId]?.[
                                  elevationIndex
                                ]?.filter((sch) => {
                                  let day = scheduleDays?.find(
                                    (d) => d?.id === sch
                                  );
                                  if (!!day) {
                                    return (
                                      day?.status !== "Postponed" &&
                                      day?.status !== "Canceled" &&
                                      day?.status !== "Skipped"
                                    );
                                  } else return false;
                                }) || [];
                              return (
                                <Collapse.Panel
                                  key={elevationIndex}
                                  forceRender={true}
                                  className={"services-panel-header"}
                                  style={{
                                    border: "none !important",
                                  }}
                                  header={
                                    <div
                                      onClick={(e) => {
                                        if (
                                          !!this?.agGridApiForEachElevation?.[
                                            serviceId
                                          ]?.api
                                        ) {
                                          e.stopPropagation();
                                        }
                                      }}
                                      style={{
                                        height: "inherit",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        width: "100%",
                                        padding: 10,
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          maxWidth: 635,
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 10,
                                        }}
                                      >
                                        <Tooltip
                                          placement="topLeft"
                                          title={`${
                                            elevation?.elevationLabel
                                          } - ${elevation?.elevationId} ${
                                            elevation?.phase
                                              ? " - " + `(${elevation?.phase})`
                                              : ""
                                          }`}
                                        >
                                          <div
                                            style={{
                                              maxWidth: 590,
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              flex: "1 2 auto",
                                              marginRight: 10,
                                            }}
                                          >{`${elevation?.elevationLabel?.trim()} - ${elevation?.elevationId?.trim()} ${
                                            elevation?.phase
                                              ? " - " + `(${elevation?.phase})`
                                              : ""
                                          } `}</div>
                                        </Tooltip>
                                        <Select
                                          onClick={(e) => e.stopPropagation()}
                                          style={{
                                            width: 150,
                                            background: "#f4f5f6",
                                            border: "none",
                                          }}
                                          value={
                                            elevation?.elevationStatus ||
                                            "Not Started"
                                          }
                                          onChange={(e) => {
                                            const gridData = _.cloneDeep(
                                              this?.state?.gridData
                                            );
                                            const service = gridData?.find(
                                              (s) =>
                                                s.serviceId?.toString() ===
                                                serviceId?.toString()
                                            );
                                            service.serviceOptions[0][
                                              elevationIndex
                                            ].elevationStatus = e;
                                            this.setState({ gridData });
                                            this.props.handlePlis(
                                              gridData,
                                              this?.props?.estimationId
                                            );
                                          }}
                                        >
                                          <Option value="Not Started">
                                            Not Started
                                          </Option>
                                          <Option value="In Progress">
                                            In Progress
                                          </Option>
                                          <Option value="Completed">
                                            Completed
                                          </Option>
                                          <Option value="Not schedulable">
                                            Not schedulable
                                          </Option>
                                          <Option value="Cancelled">
                                            Cancelled
                                          </Option>
                                        </Select>
                                        {!!elevationDescription && (
                                          <Tooltip
                                            title={
                                              maskNumbers(
                                                removeHTMLTags(
                                                  elevationDescription?.trim()
                                                )
                                              ) || ""
                                            }
                                            placement="right"
                                          >
                                            <span
                                              style={{
                                                fontSize: 12,
                                                color: this?.props?.isDarkMode
                                                  ? "#fff"
                                                  : "#3A3C4E",
                                                maxWidth: 350,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                flex: "1 2 auto",
                                              }}
                                            >
                                              {maskNumbers(
                                                removeHTMLTags(
                                                  elevationDescription?.trim()
                                                )
                                              ) || ""}
                                            </span>
                                          </Tooltip>
                                        )}
                                      </div>

                                      <div
                                        style={{
                                          height: "inherit",
                                          display: "flex",
                                          alignItems: "center",
                                          // width: "inherit",
                                          justifyContent: "space-between",
                                          gap: 15,
                                        }}
                                      >
                                        {!checkIfServiceIsHoist(service) &&
                                          totalDimensions > 0 && (
                                            <div style={{ marginLeft: 10 }}>
                                              Sum:{" "}
                                              <strong>
                                                {isNaN(totalDimensions)
                                                  ? 0
                                                  : totalDimensions}
                                              </strong>
                                            </div>
                                          )}
                                        {/* <MondayButton
                                          onClick={() =>
                                            this.removeAllBreakdowns(
                                              serviceId,
                                              elevationIndex
                                            )
                                          }
                                        >
                                          Remove All Breakdowns
                                        </MondayButton> */}
                                        {!checkIfServiceIsHoist(service) && (
                                          <span
                                            onClick={(e) => {
                                              if (
                                                !!this
                                                  ?.agGridApiForEachElevation?.[
                                                  serviceId
                                                ]?.[elevationIndex]
                                              ) {
                                                e.stopPropagation();
                                              }
                                            }}
                                          >
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: !this?.props?.isDarkMode
                                                  ? "rgb(18, 100, 163)"
                                                  : "#3DC2EC",
                                                fontSize: 14,
                                                fontWeight: 600,
                                              }}
                                              disabled={
                                                !this?.props?.editMode
                                                  ? false
                                                  : !this?.props?.isWritable
                                              }
                                              onClick={() => {
                                                if (
                                                  !this?.props?.isWritable &&
                                                  !!this?.props?.editMode
                                                )
                                                  return;
                                                this.setState({
                                                  shortcutContent: {
                                                    visible: true,
                                                    content: {
                                                      serviceId,
                                                      elevation,
                                                      elevationIndex,
                                                      scheduleDays:
                                                        scheduleDays?.filter(
                                                          (el) =>
                                                            el?.status !==
                                                              "Postponed" &&
                                                            el?.status !==
                                                              "Canceled" &&
                                                            el?.status !==
                                                              "Skipped"
                                                        ),
                                                      service,
                                                      gridData,
                                                      scheduleName:
                                                        this?.props
                                                          ?.scheduleName,
                                                      typeOfWork:
                                                        this?.props?.typeOfWork,
                                                      editMode:
                                                        this?.props?.editMode,
                                                    },
                                                  },
                                                });
                                              }}
                                            >
                                              Shortcut for days
                                            </span>
                                          </span>
                                        )}
                                        {/* <span
                                        // onClick={(e) => {
                                        //   if (
                                        //     !!this
                                        //       ?.agGridApiForEachElevation?.[
                                        //       serviceId
                                        //     ]?.[elevationIndex]
                                        //   ) {
                                        //     e.stopPropagation();
                                        //   }
                                        // }}
                                        > */}
                                        {/* <span
                                          onClick={() => {
                                            this.setState({
                                              lockedPlis: {
                                                visible: true,
                                                title: `Locked PLIs for ${label} ${elevation?.elevationLabel} - ${elevation?.elevationId}`,
                                                items,
                                              },
                                            });
                                          }}
                                        >
                                          {<LockOutlined />}
                                        </span> */}
                                        {/* </span> */}
                                        {!checkIfServiceIsHoist(service) && (
                                          <span>
                                            Total Elevation:{" "}
                                            <b>{elevationTotalSqft || 0}</b>
                                          </span>
                                        )}
                                        {!checkIfServiceIsHoist(service) && (
                                          <span>
                                            Item: <b>{itemPercElev || 0}%</b>
                                          </span>
                                        )}

                                        <div>
                                          <CustomProgressBar
                                            progressDone={
                                              progressPercentage || 0
                                            }
                                            breakDownProp={true}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // height: "inherit",
                                            margin: "0 10px",
                                            gap: 10,
                                          }}
                                        >
                                          {!checkIfServiceIsHoist(service) && (
                                            <>
                                              {selectedDays?.length > 3 ? (
                                                <span
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    // if (
                                                    //   !!agGridApiForEachElevation?.[
                                                    //     serviceId
                                                    //   ]?.[elevationIndex]
                                                    // ) {
                                                    //   e.stopPropagation();
                                                    // }
                                                  }}
                                                >
                                                  <MondayButton
                                                    onClick={(e) => {
                                                      this.setState({
                                                        trasnferDays: {
                                                          visible: true,
                                                          days: sortDayIds(
                                                            scheduleDays,
                                                            selectedDays
                                                          ),
                                                          serviceId,
                                                          elevationIndex,
                                                        },
                                                      });
                                                    }}
                                                    {...{
                                                      hasIcon: false,
                                                      className:
                                                        "mondayButtonYellow",
                                                    }}
                                                  >
                                                    {selectedDays?.length +
                                                      " " +
                                                      "days" +
                                                      " " +
                                                      "used in this elevation"}
                                                  </MondayButton>
                                                </span>
                                              ) : (
                                                <SelectDaysElevation
                                                  {...{
                                                    elevation,
                                                    updateDaysFromElevationToRows:
                                                      this
                                                        .updateDaysFromElevationToRows,
                                                    serviceId,
                                                    elevationIndex,
                                                    scheduleDays,
                                                    editMode:
                                                      this?.props?.editMode,
                                                    isWritable:
                                                      this?.props?.isWritable,
                                                    items: elevation?.items,
                                                    agGridApiForEachElevation:
                                                      this
                                                        .agGridApiForEachElevation,
                                                    isDarkMode:
                                                      this?.props?.isDarkMode,
                                                    selectedDays,
                                                  }}
                                                />
                                              )}
                                            </>
                                          )}
                                          {!checkIfServiceIsHoist(service) && (
                                            <Breakdown
                                              {...{
                                                updateBreakdownLogs:
                                                  this.updateBreakdownLogs,
                                                editMode: this?.props?.editMode,
                                                scheduleName:
                                                  this?.props?.scheduleName,
                                                allItems: elevation?.items,
                                                typeOfWork:
                                                  this?.props?.typeOfWork,
                                                serviceDefinitions,
                                                serviceId,
                                                serviceIndex,
                                                optionIndex: 0,
                                                elevationIndex,
                                                dayColors,
                                                onBreakdownSetInPLI: () => {},
                                                breakdownsInElevations,
                                                daysInElevations,
                                                elevation,
                                                elevationId,
                                                scheduleDays,
                                                label,
                                                elevationLabel,
                                                origin:
                                                  BREAKDOWN_RENDER_ORIGIN.ELEVATION,
                                                onSetBreakDownsInElevation:
                                                  this
                                                    .onSetBreakDownsInElevation,

                                                typeOfWorkObject,
                                                editMode: this?.props?.editMode,
                                                isWritable:
                                                  this?.props?.isWritable,
                                                isDarkMode:
                                                  this?.props?.isDarkMode,
                                                updateProgressLogs:
                                                  this.props.updateProgressLogs,
                                              }}
                                            />
                                          )}
                                          {/* <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            <UndoProgressSchedule
                                              {...{
                                                title: `Reset progress or all days in ${elevation?.elevationLabel} - ${elevation?.elevationId}`,
                                                reset:
                                                  this.resetProgressElevation,
                                                needed: [
                                                  serviceId,
                                                  elevationIndex,
                                                  elevation?.jumps,
                                                ],
                                              }}
                                            />
                                          </span> */}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  extra={[]}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    className={`scheduleGrid ${
                                      this?.props?.isDarkMode
                                        ? "ag-theme-balham-dark ag-theme-alpine-dark"
                                        : "ag-theme-balham ag-theme-alpine"
                                    }`}
                                  >
                                    <>
                                      {checkIfServiceIsHoist(service) ? (
                                        <HoistElements
                                          {...{
                                            elevation,
                                            scheduleDays,
                                            serviceId,
                                            elevationIndex,
                                            setHoistPLis,
                                            typeOfWork: this?.props?.typeOfWork,
                                            editMode: this?.props?.editMode,
                                            typeOfRemoval:
                                              this?.props?.typeOfRemoval,
                                            serviceDefinitions,
                                            updateProgressLogs:
                                              this.props.updateProgressLogs,
                                            hoistDriveIds:
                                              this.props
                                                ?.googleDriveFolderIds?.[
                                                documentationLabel
                                              ],
                                            estimationId,
                                            elevationId,
                                            estimation:
                                              service?.quickbooksNumber ||
                                              service?.estimationNumber ||
                                              "1",
                                            serviceId,
                                            serviceName: service?.label,
                                            elevationStatus:
                                              elevation?.status || "",
                                            updateBreakdownLogs:
                                              this.updateBreakdownLogs,
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      >
                                        <AgGridReact
                                          {...{
                                            onGridReady,
                                            rowGroupPanelShow: "always",
                                            enableRowGroup: true,
                                            groupDisplayType: "groupRows",
                                            enableRangeSelection: true,
                                            onRangeSelectionChanged:
                                              onRangeSelectionChanged,
                                            columnDefs,
                                            detailCellRendererParams,
                                            getDetailGridInfo,
                                            frameworkComponents,
                                            defaultColDef,
                                            getRowHeight,
                                            pagination: true,
                                            context: {
                                              isDarkMode:
                                                this?.props?.isDarkMode,
                                              thisProjectSchedules:
                                                this?.props
                                                  ?.thisProjectSchedules || [],
                                              driveIdsByService:
                                                this.props
                                                  ?.googleDriveFolderIds?.[
                                                  documentationLabel
                                                ],
                                              service,
                                              elevation,
                                            },
                                            paginationPageSize: 9,
                                            // paginationAutoPageSize: true,
                                            alwaysShowHorizontalScroll: true,
                                            getRowNodeId,
                                            getRowClass,
                                            masterDetail: true,

                                            suppressDragLeaveHidesColumns: true,
                                            enableCellChangeFlash: true,

                                            headerHeight: 32,
                                            groupHeaderHeight: 32,
                                            floatingFiltersHeight: 20,
                                            pivotHeaderHeight: 32,
                                            pivotGroupHeaderHeight: 32,
                                            animateRows: true,
                                            defaultColDef: {
                                              flex:
                                                service?.isScope ||
                                                checkIfServiceIsHoist(service)
                                                  ? 1
                                                  : 0,
                                              resizable: true,
                                              animateRows: true,
                                            },
                                            immutableData: false,
                                          }}
                                          cellRendererParams={scheduleDays}
                                        />
                                      </div>
                                    </>
                                  </div>
                                </Collapse.Panel>
                              );
                            }
                          )}
                          {serviceAddons?.length > 0 &&
                            serviceAddons?.map(
                              (serviceAddon, serviceAddonIndex) => {
                                return (
                                  <Collapse.Panel
                                    key={serviceAddon?.id}
                                    forceRender={true}
                                    className={"services-panel-header"}
                                    collapsible="icon"
                                    showArrow={false}
                                    style={{
                                      border: "none !important",
                                    }}
                                    header={
                                      <div
                                        style={{
                                          height: "inherit",
                                          display: "flex",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          width: "100%",
                                          padding: 10,
                                          // justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          style={{
                                            maxWidth: 635,
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Tooltip
                                            title={`${serviceAddon?.name?.trim()} 
                                     `}
                                          >
                                            <div
                                              style={{
                                                maxWidth: 635,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                flex: "1 2 auto",
                                                marginRight: 10,
                                              }}
                                            >{`${serviceAddon?.name?.trim()} 
                                      `}</div>
                                          </Tooltip>
                                        </div>
                                        {serviceAddon?.description?.trim()
                                          ?.length > 0 && (
                                          <Tooltip
                                            title={maskNumbers(
                                              removeHTMLTags(
                                                serviceAddon?.description?.trim()
                                              )
                                            )}
                                          >
                                            <div
                                              style={{
                                                // marginTop: 8,
                                                display: "flex",
                                                // justifyContent: "center",
                                                alignItems: "center",
                                                gap: 5,
                                                color: !this?.props?.isDarkMode
                                                  ? "#3A3C4E"
                                                  : "#f0f0f0",
                                                backgroundColor: !this?.props
                                                  ?.isDarkMode
                                                  ? "#f0f0f0"
                                                  : "#3A3C4E",
                                              }}
                                            >
                                              {/* <b>NOTE:</b> */}
                                              <span
                                                style={{
                                                  fontSize: 13,
                                                }}
                                              >
                                                {maskNumbers(
                                                  removeHTMLTags(
                                                    serviceAddon?.description?.trim()
                                                  )
                                                )}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        )}
                                      </div>
                                    }
                                    extra={[
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: 15,
                                          alignItems: "center",
                                          paddingRight: 20,
                                        }}
                                      >
                                        <span
                                          style={{ width: 110 }}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              color: !this?.props?.isDarkMode
                                                ? "rgb(18, 100, 163)"
                                                : "#3DC2EC",
                                              fontSize: 14,
                                              fontWeight: 600,
                                            }}
                                            onClick={(e) => {
                                              if (
                                                !this?.props?.isWritable &&
                                                !!this?.props?.editMode
                                              ) {
                                                message.warning(
                                                  "Enable read/write mode to use this feature!"
                                                );
                                                return;
                                              } else if (
                                                Object.keys(
                                                  serviceAddon?.editedFrom || {}
                                                ).length > 0
                                              ) {
                                                message.warning(
                                                  `This addon is edited in ${serviceAddon?.editedFrom?.scheduleName}! You can't edit it here!`
                                                );
                                                return;
                                              } else {
                                                this.setState({
                                                  progressAddons: {
                                                    visible: true,
                                                    serviceId,
                                                    serviceIndex,
                                                    addonIndex:
                                                      serviceAddonIndex,
                                                    addonName:
                                                      serviceAddon?.name,
                                                    progressByDay:
                                                      serviceAddon?.progressByDay ||
                                                      [],
                                                  },
                                                });
                                              }
                                            }}
                                          >
                                            Progress Addon
                                          </span>
                                        </span>
                                        <CustomProgressBar
                                          progressDone={Math.round(
                                            calcData(
                                              serviceAddon?.progressByDay || [],
                                              "progress"
                                            ) /
                                              serviceAddon?.progressByDay
                                                ?.length || 0
                                          )}
                                          breakDownProp={true}
                                        />
                                      </div>,
                                    ]}
                                  >
                                    {/* <div
                                      style={{ width: "100%", height: "100%" }}
                                      className={`scheduleGrid ${
                                        this?.props?.isDarkMode
                                          ? "ag-theme-balham-dark ag-theme-alpine-dark"
                                          : "ag-theme-balham ag-theme-alpine"
                                      }`}
                                    >
                                      <>
                                        <AgGridReact
                                          {...{
                                            columnDefs: serviceAddonsColumnDefs(
                                              serviceAddon?.values || []
                                            ),
                                            rowData: serviceAddon?.values || [],
                                            headerHeight: 32,
                                            groupHeaderHeight: 32,
                                            floatingFiltersHeight: 20,
                                            pivotHeaderHeight: 32,
                                            pivotGroupHeaderHeight: 32,
                                            rowGroupPanelShow: "always",
                                            enableRowGroup: true,
                                            groupDisplayType: "groupRows",
                                            enableRangeSelection: true,
                                            pagination: true,
                                            paginationPageSize: 9,
                                            defaultColDef: {
                                              flex: 1,
                                              resizable: true,
                                            },
                                          }}
                                        />
                                      </>
                                    </div> */}
                                  </Collapse.Panel>
                                );
                              }
                            )}
                        </Collapse>
                      </div>
                    </div>
                  </Badge.Ribbon>
                );
              })}
            </div>
          </div>
        </div>

        {this?.state?.shortcutContent?.visible && (
          <ShortcutForPliDays
            {...{
              shortcutContent: this?.state?.shortcutContent,
              setShortcutContent: (obj) =>
                this?.setState({
                  shortcutContent: obj,
                }),
              updateGridDataFromShortcut,
              updateDaysInElevation: this?.updateDaysInElevation,
              agGridApiForEachElevation: this?.agGridApiForEachElevation,
              isDarkMode: this?.props?.isDarkMode,
            }}
          />
        )}

        {this?.state?.trasnferDays?.visible && (
          <TransferDays
            {...{
              transferDays: this?.state?.trasnferDays,
              scheduleDays: scheduleDays?.filter(
                (day) =>
                  day?.status !== "Postponed" &&
                  day?.status !== "Skipped" &&
                  day?.status !== "Canceled"
              ),
              closeTransferDays: (e) =>
                this.setState({
                  trasnferDays: { ...this?.state?.trasnferDays, visible: e },
                }),
              handleDays: this.updateDaysFromElevationModalToRows,
              comesFromElevation: true,
            }}
          />
        )}
        {this?.state?.progressAddons?.visible && (
          <ProgressAddons
            {...{
              progressAddons: this?.state?.progressAddons,
              scheduleDays,
              closeProgressAddonModal: (e) =>
                this.setState({
                  progressAddons: {
                    ...(this?.state?.progressAddons || {}),
                    visible: e,
                  },
                }),
              updateProgressForServiceAddon: this.updateProgressForServiceAddon,
              isDarkMode: this?.props?.isDarkMode,
              editMode: this?.props?.editMode,
            }}
          />
        )}
      </div>
    );
  }
}

const defaultColDef = {
  editable: false,
  enableCellChangeFlash: true,
  filter: true,
  sortable: true,
  resizable: true,
  autoHeight: true,
  enableRowGroup: true,
};
