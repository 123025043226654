export const getSortedArray = (data) => {
  let sortedArray = [];
  const sortedCategories = data.sortedCategories;

  sortedCategories.forEach((category) => {
    const subCategories = data.sortedSubCategories[category];
    if (subCategories) {
      sortedArray = [...sortedArray, ...subCategories];
    }
  });

  return sortedArray;
};
