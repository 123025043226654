import { Steps } from "antd";
import { StepRenderer } from "./components";
import "./Stepper.scss";
import { useResponsive } from "../../../hooks";

/**
 * Customized AntD stepper.
 *
 * A customized AntD stepper that accepts all the properties of the AntD stepper.
 *
 * @param {int} currentStep  Active step's index. Should be saved in the state.
 * @param {function} setCurrentStep  Function that sets the active step.
 * @param {Array} steps  Array containing an object list of the steps. Step's object should have:
 *                        title - The title that is gonna appear on the stepper.
 *                        Comp - Component to be rendered.
 *                        disabled - Whether the step is disabled or not.
 *                        Any other key is gonna be passed as a prop to the Comp.
 * @param {String} [stepperClassName] Stepper's Container class name.
 * @param {String} [componentContainerClassName] Rendered Component's class name.
 * @returns {Node} Component of the active step.
 */
const Stepper = ({
  setFormChanged = () => {},
  currentStep: current,
  setCurrentStep = () => {},
  size = "small",
  steps = [],
  stepperClassName = "",
  stepRenderer = true,
  componentContainerClassName = "",
  useKeys = false,
  onChange = () => {},
  statusesColors,
  requestStatus = () => {},
  geoFenceAccess,
  ...rest
}) => {
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  const { mobile } = useResponsive();

  return (
    <>
      <div
        className={`antCustomStepper ${stepperClassName} ${
          mobile && "antCustomStepper-mobile"
        }`.trim()}
      >
        <Steps
          {...{
            size,
            current,
            onChange: (curr) => {
              setFormChanged(true);
              onChange(curr);
              setCurrentStep(curr);
            },
            type: "navigation",
            items: steps?.map(
              (
                { title, disabled = false, status, setVisible, visible },
                key
              ) => {
                const filteredSteps =
                  geoFenceAccess && geoFenceAccess?.length === 0 && key === 3
                    ? "hide"
                    : "show";

                return {
                  title,
                  status,
                  disabled,
                  key,
                  ...rest,
                  style: statusesColors
                    ? {
                        backgroundColor: `${
                          current === key
                            ? statusesColors.find(
                                (el) => el?.statusName === title
                              )?.statusColor || ""
                            : hexToRgba(
                                statusesColors.find(
                                  (el) => el?.statusName === title
                                )?.statusColor || "",
                                0.4
                              )
                        }`,
                        "--color": `${
                          current === key
                            ? statusesColors.find(
                                (el) => el?.statusName === title
                              )?.statusColor || ""
                            : hexToRgba(
                                statusesColors.find(
                                  (el) => el?.statusName === title
                                )?.statusColor || "",
                                0.4
                              )
                        }`,
                        "--base-color": `${
                          current === key
                            ? statusesColors.find(
                                (el) => el?.statusName === title
                              )?.statusColor || ""
                            : hexToRgba(
                                statusesColors.find(
                                  (el) => el?.statusName === title
                                )?.statusColor || "",
                                0.4
                              )
                        }`,
                        "--process-color": `${
                          current === key
                            ? statusesColors.find(
                                (el) => el?.statusName === title
                              )?.statusColor || ""
                            : hexToRgba(
                                statusesColors.find(
                                  (el) => el?.statusName === title
                                )?.statusColor || "",
                                0.4
                              )
                        }`,
                        display: filteredSteps === "hide" ? "none" : "block",
                      }
                    : {
                        display: filteredSteps === "hide" ? "none" : "block",
                      },
                };
              }
            ),
            ...rest,
          }}
        />
      </div>
      {stepRenderer && (
        <div className={componentContainerClassName}>
          <StepRenderer
            {...{
              current,
              steps,
              useKeys,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Stepper;
