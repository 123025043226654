import { SimplePLI } from "./GeneralModels";

export class HeavyDutyRampsPLI extends SimplePLI {
  serviceId = 86;

  length;
  height;
  width;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateHeavyDutyRampsPLI extends SimplePLI {
  serviceId = 86;

  length;
  height;
  width;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
