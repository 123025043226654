import { useRef, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { message } from "antd";
import { columnDefs, defaultColumnDefs, onCheckBoxChange } from "./utils";
import "./PaymentTable.scss";
import {
  appliedAmountSumCalc,
  calculateInvoicePaidItem,
  clientBalanceCalculator,
  limitMaxAmount,
} from "./utils/calculators";
import { forceToNumber, validateThenSavePayment } from "../../utils/checkers";
import { useRedux } from "../../../../../../../hooks/useRedux";
import "./PaymentTable.scss";
import Swal from "sweetalert2";
import { useCallback } from "react";

const PaymentTable = ({ paymentData, isDarkMode }) => {
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [isWritable] = useRedux("paymentIsWritable");
  const [invoices] = useRedux("paymentInvoices");
  const [gridApi, setGridApi] = useState();
  const gridRef = useRef();

  const rowData = fakePayment ? [...fakePayment.invoices] : [];

  const getRowNodeId = useMemo(() => {
    return (params) => params.invoiceNumber;
  }, []);

  const onCellEditingStarted = (params) => {
    if (!isWritable && paymentData) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
  };

  const onCellEditingStopped = useCallback(
    (params) => {
      const { data, colDef, newValue } = params;
      let tempPaymentAmount = forceToNumber(fakePayment?.appliedAmount);
      if (colDef.field === "invoicePaidAmount") {
        const inputPayment = forceToNumber(newValue);
        let maxAmount =
          forceToNumber(params.data.invoiceAmountDue || 0).toFixed(2) || 0;
        let toPayInput = inputPayment;
        let currentApplied =
          fakePayment?.invoices?.reduce((a, b) => {
            console.log("a", a, b);
            if (b?.invoiceNumber !== data?.invoiceNumber) {
              return a + forceToNumber(b.invoicePaidAmount);
            } else {
              return a;
            }
          }, 0) + inputPayment;
        console.log("maxAmount", {
          maxAmount,
          applied: fakePayment?.appliedAmount,
          fakePayment,
          currentApplied,
          tempPaymentAmount,
        });
        if (
          currentApplied > tempPaymentAmount &&
          colDef.field === "invoicePaidAmount"
        ) {
          message.error("You can't apply more than the total payment amount.");
          // params.node.setDataValue(colDef.field, 0);
          // params.node.setDataValue("invoiceChecked", false);
          // focus on the cell
          //edit this cell with default value
          gridApi.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: colDef.field,
          }); //params.rowIndex, colDef.field);

          return;
        }

        // Check if the edited payment exceeds the maximum allowed payment
        if (isNaN(inputPayment)) {
          // Show an error message and reset the edited value
          //edit this cell with default value
          message.error("Please enter a valid amount.");
          gridApi.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: colDef.field,
          }); //params.rowIndex, colDef.field);

          return;
        } else {
          if (forceToNumber(inputPayment) > maxAmount) {
            Swal.fire(
              "Info",
              "You are trying to pay more than the invoice amount due. Please enter a valid amount.",
              "info"
            );
            toPayInput = 0;
            gridApi.startEditingCell({
              rowIndex: params.rowIndex,
              colKey: colDef.field,
            });
            return;
          }

          // return;
          // // Update the state with the edited payment
          // const newFakePayment = calculateInvoicePaidItem({
          //   fakePayment,
          //   invoicePaidNumber: data?.invoiceNumber,
          //   inputPayment: toPayInput,
          // });

          // setFakePayment(validateThenSavePayment(newFakePayment));
        }
        const newFakePayment = calculateInvoicePaidItem({
          fakePayment,
          invoicePaidNumber: data?.invoiceNumber,
          inputPayment: toPayInput,
        });

        setFakePayment(validateThenSavePayment(newFakePayment));
      }

      if (colDef.field === "invoicePaidPercentage") {
        let percentage = forceToNumber(newValue);
        const maxAmount = params.data.invoiceAmountDue || 0;
        const amount = (percentage / 100) * maxAmount;
        let tempApplied =
          fakePayment?.invoices?.reduce((a, b) => {
            if (b?.invoiceNumber !== data?.invoiceNumber) {
              return a + forceToNumber(b.invoicePaidAmount);
            } else {
              return a;
            }
          }, 0) + amount;

        if (tempApplied > tempPaymentAmount) {
          // Swal.fire(
          //   "Info",
          //   "You are trying to pay more than the payment amount. Please match values with header.",
          //   "info"
          // );
          message.error("You can't apply more than the total payment amount.");
          // params.node.setDataValue(colDef.field, 0);
          // params.node.setDataValue("invoiceChecked", false);
          // focus on the cell to edit
          gridApi.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: colDef.field,
          });
          // const newFakePayment = calculateInvoicePaidItem({
          //   fakePayment,
          //   invoicePaidNumber: data?.invoiceNumber,
          //   inputPayment: 0,
          // });

          // setFakePayment(validateThenSavePayment(newFakePayment));
          return;
        }
      }
      if (colDef.field === "invoicePaidPercentage") {
        const maxAmount = params.data.invoiceAmountDue || 0;
        if (maxAmount === 0) return;
        const percentage = forceToNumber(newValue);
        if (percentage > 100 || percentage < 0) {
          return message.error("Please enter valid percentage");
        } else {
          const amount = (percentage / 100) * maxAmount;
          const newFakePayment = calculateInvoicePaidItem({
            fakePayment,
            invoicePaidNumber: data?.invoiceNumber,
            inputPayment: amount,
          });

          setFakePayment(validateThenSavePayment(newFakePayment));
        }
      }
    },
    [fakePayment?.invoices, fakePayment?.appliedAmount]
  );

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <div
        className={`documentationsTable agGridContainer ag-grid-payments-table ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
        id="agGridId"
        style={{ width: "100%", height: "100%" }}
      >
        <AgGridReact
          onGridReady={({ api }) => setGridApi(api)}
          rowHeight={36}
          ref={gridRef} // Ref for accessing Grid's API
          headerHeight={50}
          getRowNodeId={getRowNodeId}
          rowData={rowData} // Row Data for Rows
          context={{ invoices }} // Context for Grid
          columnDefs={columnDefs({ paymentData })} // Column Defs for Columns
          defaultColDef={defaultColumnDefs} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple" // Options - allows click selection of rows\
          immutableData={false}
          pagination={true}
          paginationPageSize={10}
          paginationAutoPageSize={true}
          stopEditingWhenGridLosesFocus={true}
          rowBuffer={5}
          suppressContextMenu={true}
          suppressDragLeaveHidesColumns={true}
          onCellEditingStopped={onCellEditingStopped}
          onCellEditingStarted={onCellEditingStarted}
        />
      </div>
    </div>
  );
};

export default PaymentTable;
