import { Dayjs } from "dayjs";
import { utils, write } from "xlsx";
import { useSelector } from "react-redux";
import { Form, FormInstance, message, Tooltip } from "antd";
import { UIEvent, useContext, useMemo, useState } from "react";

import { ExcelIcon } from "src/assets";
import { EntryType } from "../../payrollLiveTypes";
import { convertSecondsToTime } from "../../utils";
import PayrollLiveContext from "../../PayrollLiveContext";
import { MondayButton } from "../../../../commonComponents";
import {
  StoreType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import CardComponent from "src/components/CardComponent/CardComponent";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./LateClockIn.scss";

interface Props {
  form: FormInstance<{ searchField: string; reportDate?: Array<Dayjs> }>;
}

const initialIndex = {
  end: 15,
  start: 0,
};

function LateClockIn(props: Props) {
  const { form } = props;
  const {
    mapRef,
    crewTeams,
    setFilters,
    onPageSelect,
    groupedEntries,
    employeesReport,
    setEmployeesInfo,
    controlPanelForm,
  } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [sliceIndex, setSliceIndex] = useState<{ start: number; end: number }>(
    initialIndex
  );

  const punchEnd = Form.useWatch("punchEnd", form);
  const punchStart = Form.useWatch("punchStart", form);
  const searchValue = Form.useWatch("searchField", form);
  const selectedDate = Form.useWatch("selectedDate", controlPanelForm);

  async function onEmpClick(
    employeeId: string,
    punchCoordinates: CoordinateType
  ) {
    const index = employeesReport.findIndex(
      (el) => el.employeeId === employeeId
    );
    const employeeNumber = employeesReport?.[index]?.employeeNumber;
    onPageSelect("map");
    setFilters((prev) => ({
      ...prev,
      schedules: [],
      liveStatus: [],
      employeeSearch: "",
      employeeNumber: [],
    }));
    setTimeout(() => {
      if (employeeNumber) {
        setEmployeesInfo(employeeNumber);
      }
      if (mapRef?.current?.fitBoundsToMarkers) {
        mapRef.current.fitBoundsToMarkers([punchCoordinates]);
      }
    }, 800);
  }

  function onScroll(e: UIEvent<HTMLElement>): void {
    const scrollStep = 10;
    const maxStartIndex = lateEntires.length - scrollStep;
    const maxEndIndex = lateEntires.length - 1;
    let indexPosition = e.currentTarget.scrollTop / 64;

    if (indexPosition >= 5 && sliceIndex.end === scrollStep) {
      setSliceIndex(() => ({
        start: 0,
        end: 15 + scrollStep,
      }));
    }

    if (indexPosition >= 18 && sliceIndex.end !== maxEndIndex) {
      setSliceIndex((prev) => {
        let endIndex = prev.end + scrollStep;
        let startIndex = prev.start + scrollStep;
        return {
          end: endIndex > maxEndIndex ? maxEndIndex : endIndex,
          start: startIndex > maxStartIndex ? maxStartIndex : startIndex,
        };
      });
    }

    if (indexPosition <= 0.4) {
      setSliceIndex((prev) => {
        return {
          start: prev.start >= scrollStep ? prev.start - scrollStep : 0,
          end: prev.end > scrollStep ? prev.end - scrollStep : scrollStep,
        };
      });
    }
  }

  const lateEntriesList = useMemo(() => {
    let entriesList = [];
    for (let i = 0; i < groupedEntries.length; i++) {
      const report = groupedEntries[i];
      const startTime = parseInTz(report.date, "YYYY-MM-DD")
        .set("hour", 7)
        .set("minute", 0)
        .startOf("m")
        .valueOf();

      if ((report?.ID?.[0]?.punchTimeStamp || 0) > startTime) {
        entriesList.push(report?.ID?.[0]);
      }
    }
    return entriesList;
  }, [groupedEntries]);

  function onExcelExport() {
    setExportDisabled(true);
    message.loading({ key: "excelExport", content: "Loading", duration: 0 });
    if (!lateEntriesList?.length) {
      message.warning({
        duration: 2,
        key: "excelExport",
        content: "There are no data to export.",
      });
      setExportDisabled(false);
      return;
    }

    try {
      const workbook = utils.book_new();
      const sheetData = (lateEntriesList || []).map((el) => {
        const teamIndex = crewTeams.findIndex(
          (team) => team?.crewTeamName === el?.crewTeamName
        );

        const employeeId = Number((el?.employeeId || "").split("-")?.[1]);
        return {
          "Employee Id": employeeId || "",
          Foreman: crewTeams?.[teamIndex]?.crewForeman?.crewName || "",
          "Employee Name": el?.employeeFullName || "",
          Crew: el?.crewTeamName || "",
          "Punch Time": el?.punchTime
            ? dayjsNY(el?.punchTime).format("hh:mm A")
            : "",
          "Punch Location": el?.punchLocation || "",
          "Job Assigned": el?.jobsiteMatch.jobAddress || "",
          Distance: el?.distanceFromJob
            ? `${Number(el?.distanceFromJob).toFixed(2)} ft`
            : "",
          Duration: Number(el?.duration)
            ? convertSecondsToTime(Math.round(el?.duration))
            : el?.duration || "",
          Notes: "",
        };
      });

      const workSheet = utils.json_to_sheet(sheetData);
      utils.book_append_sheet(workbook, workSheet);

      const fileTitle = `Late Clock In ${selectedDate?.format?.("MM-DD-YYYY")}`;

      const excelBufferNoJob = write(workbook, {
        type: "base64",
        bookType: "xls",
      });

      const file = {
        name: fileTitle + ".xls",
        type: "application/vnd.ms-excel",
        blob: `base64,${excelBufferNoJob}`,
      };

      const url = `data:${file.type};${file.blob}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setExportDisabled(false);
      message.success({
        duration: 2,
        key: "excelExport",
        content: "File exported successfully!",
      });
    } catch (error) {
      setExportDisabled(false);
      message.error({
        duration: 2,
        key: "excelExport",
        content: "Could not create excel file.",
      });
    }
  }

  const lateEntires = useMemo(() => {
    let filteredGroupedEntries: Array<EntryType> = [];

    for (let i = 0; i < lateEntriesList.length; i++) {
      let pass = true;

      const report = lateEntriesList[i];

      if (
        !!report.employeeFullName &&
        !report.employeeFullName
          .toLowerCase()
          .includes((searchValue || "").toLowerCase())
      ) {
        // search matches report name
        pass = false;
      }

      if (punchStart) {
        if (report?.punchTimeStamp <= punchStart?.startOf("m")?.valueOf?.()) {
          pass = false;
        }
      }

      if (punchEnd) {
        if (report?.punchTimeStamp >= punchEnd?.startOf("m")?.valueOf?.()) {
          pass = false;
        }
      }

      if (pass) {
        filteredGroupedEntries.push(report);
      }
    }

    return filteredGroupedEntries.map((rep, i) => {
      return (
        <div className={`report-card`} key={i}>
          <div className="report-header">
            <Tooltip title={`Go to location`}>
              <label
                onClick={() =>
                  onEmpClick(rep.employeeId, rep?.punchCoordinates)
                }
              >
                {rep?.employeeFullName}
              </label>
            </Tooltip>
          </div>
          <div className="report-body">
            <label>{dayjsNY(rep?.punchDate).format("MM/DD/YYYY")}</label>
            <label key={rep?.entryId}>
              {dayjsNY(rep?.punchTimeStamp).format("hh:mm A")}
            </label>
          </div>
        </div>
      );
    });
  }, [lateEntriesList, searchValue, punchEnd, punchStart]);

  return (
    <CardComponent
      key="late-clock-in"
      className="late-reports-card"
      title={
        <div className="custom-header">
          <label>Late Clock In</label>
          <Tooltip title="Export Late Clock In">
            <MondayButton
              onClick={onExcelExport}
              disabled={exportDisabled}
              Icon={<ExcelIcon height={23} width={23} />}
              className={darkMode ? "mondayButtonBlue" : "mondayButtonWhite"}
            />
          </Tooltip>
        </div>
      }
    >
      <>
        <div className="cards-wrapper" onScroll={onScroll}>
          {lateEntires?.length ? (
            lateEntires.slice(sliceIndex.start, sliceIndex.end)
          ) : (
            <div className="no-data">No Late Clock In</div>
          )}
        </div>
        <span className="reports-count">
          {lateEntires?.length} Total late clock in
        </span>
      </>
    </CardComponent>
  );
}

export default LateClockIn;
