import { cloneElement } from "react";
import { Radio, Select } from "antd";
import {
  GPickerButton,
  GPlacesAutocompleteInput,
  InputComponent,
  SimplePlacesInput,
} from "../../../SidebarPages/Fleet/components";
import { compareIncluding } from "../../../SidebarPages/utils";
import FormTextEditor from "./FormTextEditor/FormTextEditor";

/**
 * Renders dynamic components based on the provided JSON data.
 *
 * @param {Array} compJSON - The Array with object representing the components to render.
 *  Main properties:
 *  @property {String} compJSON[].type - The type of the component to render.
 *  Render a custom component:
 *  @property {JSX} compJSON[].customComponent - React component to be rendered.
 *  @property {Object} compJSON[].componentProps - Props to be passed to the custom component.
 *
 * @param {Object} optionalProps - Optional props to be passed to the components.
 *  @property {Object} optionalProps.form - The form instance object to be passed to the components.
 *  @property {String} optionalProps.accessToken - The access token to be passed to the components.
 *  @property {Boolean} optionalProps.disabled - Whether the components should be disabled or not.
 *
 * @returns {Array} An array of rendered components.
 */
const RenderDynamicComponents = (compJSON = [], optionalProps = {}) => {
  const isDarkMode = localStorage.getItem("darkmode") === "true";
  const { Option, OptGroup } = Select;
  const {
    form,
    accessToken,
    disabled = false,
    setUserTime = false,
  } = optionalProps;

  return compJSON?.map?.((input, i) => {
    // if you want to return a component instead
    if (input?.customComponent) {
      const { customComponent: Component, componentProps } = input;
      return <Component key={i} {...componentProps} />;
    } else {
      const {
        options,
        type = "",
        form: inputForm,
        accessToken: inputAccessToken,
        optionGroups,
        optionType,
        customOptions,
        myOptions,
        disabled: inputDisabled = false,
        testId,
      } = input;

      const InputOption = compareIncluding(type, "radio")
        ? compareIncluding(optionType, "Button")
          ? Radio.Button
          : Radio
        : Option;

      const WhichComponent = compareIncluding(type, "placesautocomplete")
        ? SimplePlacesInput
        : compareIncluding(type, "gPicker")
        ? GPickerButton
        : compareIncluding(type, "textEditor")
        ? FormTextEditor
        : InputComponent;

      const pickerType =
        type?.toLowerCase() === "datepicker" ||
        type?.toLowerCase() === "timepicker" ||
        type?.toLowerCase() === "rangepicker";

      return (
        <WhichComponent
          key={i}
          {...{
            ...input,
            form: inputForm || form,
            ...(type === "gPicker" && {
              accessToken: inputAccessToken || accessToken,
            }),
            ...(input.type?.toLowerCase() === "timeinput" && {
              setUserTime,
            }),
            popupClassName: isDarkMode
              ? pickerType
                ? "darkDateDropDown"
                : "darkDropDown"
              : pickerType
              ? "lightDateDropDown"
              : "lightDropDown",
            dropdownClassName: isDarkMode
              ? pickerType
                ? "darkDateDropDown"
                : "darkDropDown"
              : "",
            options:
              type === "radiobutton"
                ? options
                : !customOptions && !!myOptions
                ? //myOptions - If we want to get another look of options <Option></Option>
                  myOptions
                : Array.isArray(options) &&
                  options?.map((item, i) => (
                    <InputOption key={i} value={item}>
                      {item}
                    </InputOption>
                  )),
            optionGroups: optionGroups?.map(({ label, options }, index) => (
              <OptGroup key={label + index} label={label}>
                {options?.map((item, i) => (
                  <Option key={item + i + label} value={item}>
                    {item}
                  </Option>
                ))}
              </OptGroup>
            )),
            ...(!!disabled || !!inputDisabled ? { disabled: true } : {}),
            isDarkMode,
          }}
        />
      );
    }
  });
};

export default RenderDynamicComponents;
