import { API } from "aws-amplify";
import { defaultColors, generateDatePeriods } from "../../../utils";
import { updateProgramFieldByName } from "../../../actions/programFields";
import { message } from "antd";
import { preferences as preferencesDispatch } from "../../../actions";
import { getRandomColor } from "../../Header/forms/Scheduling/helpers/creators";

export const CardWidths = [
  { value: "small", label: "Small" },
  { value: "default", label: "Default" },
  { value: "large", label: "Large" },
];

export const mockedUseSelector = {
  userConfiguration: {
    nameOfUser: "Lulzim",
    cognitoUserId: "cognitoUserId",
    groupName: "admin",
    allUsers: {
      Items: [{ nameOfUser: "Lulzim", cognitoUserId: "cognitoUserId" }],
    },
  },
  programFields: [
    {
      fieldName: "Overviews Configuration",
      fieldOptions: {
        overviewCards: {
          "Edit Logs": [
            {
              chartTitle: "chartTitle",
              createdBy: {
                cognitoUserId: "cognitoUserId",
                nameOfUser: "Lulzim",
              },
              width: "default",
              id: "123",
              permanentCard: true,
              rowFilterKey: "label",
              type: "doughnut",
            },
          ],
        },
      },
    },
    {
      fieldName: "Departments",
      fieldOptions: [{ departmentName: "ADMIN" }],
    },
  ],
  preferences: {
    preferences: {
      OverviewCards: {
        hiddenCards: { "Edit Logs": [] },
        cardFilters: {},
        onlyMyCards: {
          "Edit Logs": [
            {
              chartTitle: "chartTitle",
              createdBy: {
                cognitoUserId: "cognitoUserId",
                nameOfUser: "Lulzim",
              },
              width: "default",
              id: "123",
              permanentCard: true,
              rowFilterKey: "label",
              type: "doughnut",
            },
          ],
        },
      },
    },
  },
  darkMode: false,
};

export const getStatusColors = (
  programFields,
  overviewName,
  nestedStatusColors,
  recordsAmount = [] // when no statuses with colors are found from "Status colors" this one generate random color for every record
) => {
  const fieldOptions = programFields?.find(
    ({ fieldName }) => fieldName === "Status colors"
  )?.fieldOptions;

  const findedColors = fieldOptions[overviewName];

  return !!findedColors
    ? (!!nestedStatusColors && !Array.isArray(findedColors)
        ? findedColors[nestedStatusColors]
        : findedColors) || []
    : recordsAmount.map((record, idx) => ({
        statusId: idx,
        statusName: "",
        statusColor: getRandomColor(),
      }));
};

export const filterDatas = (datas = [], filters = []) => {
  if (filters.length) {
    const datePeriods = generateDatePeriods();

    return datas.filter((record) => {
      return filters.every(({ filterType, value }) => {
        if (filterType === "period") {
          const keyForDate = record?.createdAt ? "createdAt" : "updatedAt";

          const { startDate, endDate } =
            datePeriods.find((date) => date.value === value) || {};

          // Convert both start and end dates to milliseconds
          const startTimestamp = startDate?.unix() * 1000;
          const endTimestamp = endDate?.unix() * 1000;

          // Normalize the record's date by converting it to a timestamp in milliseconds
          const recordDate = record[keyForDate];

          // Check if recordDate is a string and parse it, or use the timestamp as is
          const normalizedRecordDate = isNaN(recordDate)
            ? new Date(recordDate).getTime() // If it's a string, convert to timestamp
            : recordDate; // If it's already a timestamp, keep as is

          return (
            normalizedRecordDate >= startTimestamp &&
            normalizedRecordDate <= endTimestamp
          );
        } else {
          return Array.isArray(record[filterType])
            ? record[filterType]?.some((item) =>
                Array.isArray(value) ? value.includes(item) : item === value
              )
            : Array.isArray(value)
            ? value.includes(record[filterType])
            : value === record[filterType];
        }
      });
    });
  } else {
    return datas;
  }
};

const isStringOrNum = (val) => {
  return typeof val === "string" || typeof val === "number";
};

export const hasValidValue = (value) => {
  return Array.isArray(value)
    ? value.every((item) => isStringOrNum(item))
    : isStringOrNum(value);
};

export const getNecessaryKeys = (sampleObject = {}, videoTutorial = false) => {
  if (videoTutorial) {
    return [
      "nameOfUser",
      "videoName",
      "recordName",
      "favourites",
      "partialWatched",
    ];
  }

  const data = Object.keys(sampleObject).filter((key) => {
    return (
      !key.includes("updatedAt") &&
      !key.includes("createdAt") &&
      !key.includes("Id") &&
      !key.includes("Logs") &&
      hasValidValue(sampleObject[key])
    );
  });

  return data;
};

export const updateProgramFields = async (fieldId, fieldOptions, dispatch) => {
  return await API.patch("programFields", `/programFields/${fieldId}`, {
    body: { fieldOptions },
  })
    .then(() => {
      dispatch(
        updateProgramFieldByName({
          fieldName: "Overviews Configuration",
          fieldOptions,
        })
      );
      message.destroy();
    })
    .catch((error) => {
      console.log({ error });
      message.error("There was a problem!");
    });
};

export const updateChartPreferences = async (
  preferences,
  allPreferences,
  dispatch
) => {
  return await API.patch("preferences", "/preferences", {
    body: { preferences },
  })
    .then(() => {
      message.destroy();
      dispatch(preferencesDispatch({ ...allPreferences, preferences }));
    })
    .catch((error) => console.error("Error saving to preferences", error));
};
