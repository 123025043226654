import {
  useRef,
  useMemo,
  Fragment,
  useState,
  useContext,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import {
  Polygon,
  MarkerF,
  CircleF,
  GoogleMap,
  InfoWindowF,
  OverlayViewF,
  DrawingManagerF,
} from "@react-google-maps/api";
import { API } from "aws-amplify";
import { Modal, message, Popover } from "antd";

import {
  getFence,
  FleetsLiveContext,
  loadLivePreference,
  saveLocalPreferences,
  findGeofenceCenterCoordinate,
} from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { MAP_THEME } from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { getCoordinatesAndZip } from "src/components/SidebarPages/Fleet/utils";
import { pointsInsideAPolygon } from "../../../../../Map/utilities/pointsInsideAPolygon";
import { getRandomColor } from "src/components/Header/forms/Scheduling/helpers/creators";
import { withinRadius } from "src/components/pages/Payroll/Tabs/Activity/components/payrollActivityModalData";
import {
  Marker,
  WarningModal,
  MondayButton,
} from "src/components/commonComponents";
import {
  StopInfo,
  footerButtons,
  NearestFences,
  FenceSaveForm,
  StopController,
} from "./components";
import { FleetWhite } from "src/icons";
import { garagePin } from "src/components/SidebarPages/Fleet/assets";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./StopModal.scss";

/**
 * @typedef LatLng
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef Distance
 * @property {boolean} withingRange
 * @property {number} distanceInFeet
 * @property {number} distanceInMile
 */

/**
 * @typedef LinkInstance
 * @property {string} id
 * @property {string} matchId
 * @property {string} address
 * @property {LatLng} position
 * @property {LatLng[]} points
 * @property {number} [radius]
 * @property {Distance} distance
 * @property {"PROJECT"|"GEOFENCE"|"LOCATION"} type
 */

/**
 * @typedef ProjectUpdateParam
 * @property {string} address
 * @property {LatLng[]} points
 * @property {string} projectId
 * @property {string} geofenceUUID
 */

/**
 * @typedef {LinkInstance & {key:string; label: string; value: string}} SelectOption
 */

function LinkStopModal({
  open,
  onCancel,
  stopData,
  onSubmit = () => {},
  onDiscard = () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const {
    projects,
    jobsites,
    mapLoaded,
    geofences,
    setGeofences,
    projectsLoading,
    garageAddresses,
    updateProjectData,
  } = useContext(FleetsLiveContext);

  const [newFenceForm, setNewFenceForm] = useState(false);
  const [warningVisible, setWarningVisible] = useState(null);
  const [map, setMap] = useState(/** @type {google.maps.Map} */ (null));
  const [mode, setMode] = useState(/** @type {"VIEW"|"DRAW"} */ ("VIEW"));
  const [overlayFence, setOverlayFence] = useState(
    /** @type {LatLng[]} */ ([])
  );
  const [projectToLink, setProjectToLink] = useState(
    /** @type {LinkInstance|null} */ (null)
  );
  const [selectedFence, setSelectedFence] = useState(
    /** @type {LinkInstance|null} */ (null)
  );
  const [view, setView] = useState(
    /** @type {"PROJECTS"|"GEOFENCES"} */ ("PROJECTS")
  );
  const [dataVisible, setDataVisible] = useState(
    loadLivePreference("stopModalInfo") === "expanded"
  );
  const [canSavePolygon, setCanSavePolygon] = useState(
    /** @type {google.maps.Polygon} */ (null)
  );
  const [drawingManager, setDrawingManager] = useState(
    /** @type {google.maps.DrawingManager} */ (null)
  );

  const markerColor = useRef(getRandomColor());
  const pixelOffset = useRef(new google.maps.Size(0, -20));
  const pinAnchor = useRef(new google.maps.Point(20, 40));

  const onFenceClick = useCallback(
    /**
     * @param {LinkInstance} fence
     */
    (fence) => {
      if (!map) {
        return;
      }

      if (mode === "DRAW") {
        return;
      }

      if (!fence.points.length) {
        message.warning({
          content: `The selected ${
            selectedFence?.type === "LOCATION" ? "address" : "project"
          } doesn't have a geofence`,
          key: "noFenceForProject",
        });
      } else {
        message.destroy("noFenceForProject");
      }

      const bounds = new google.maps.LatLngBounds();

      if (selectedFence?.id !== fence.id) {
        for (const coord of fence.points.concat(fence.position)) {
          bounds.extend(coord);
        }

        setSelectedFence(fence);
      } else {
        setSelectedFence(null);
      }

      if (projectToLink) {
        bounds.extend(projectToLink.position);
      } else {
        bounds.extend(stopData.position);
      }

      if (fence.type !== "GEOFENCE") {
        markerColor.current = getRandomColor();
      }

      map.fitBounds(bounds);
    },
    [map, stopData, projectToLink, mode, selectedFence]
  );

  const sortedProjects = useMemo(
    /**
     * @returns {LinkInstance[]}
     */
    () => {
      /** @type {LinkInstance[]} */
      const projectMap = projects.map((project) => {
        const match = Object.values(geofences)?.find(
          ({ geofenceUUID }) =>
            geofenceUUID === project?.geoFenceInfo?.[0]?.["shapeId"]
        );

        return {
          type: "PROJECT",
          id: project.projectId,
          matchId: match?.geofenceUUID,
          address: project.projectName,
          distance: withinRadius(
            {
              lat: project?.projectLatitude,
              lng: project?.projectLongitude,
            },
            stopData.position
          ),
          position: {
            lat: project?.projectLatitude,
            lng: project?.projectLongitude,
          },
          points: (match?.points || [])?.map(
            ({ latitude: lat, longitude: lng }) => ({ lat, lng })
          ),
        };
      });

      projectMap.sort((a, b) => {
        if (isNaN(a.distance.distanceInFeet)) {
          return 1;
        }
        if (isNaN(b.distance.distanceInFeet)) {
          return -1;
        }

        return a.distance.distanceInFeet - b.distance.distanceInFeet;
      });

      return projectMap;
    },
    [projects, geofences, stopData]
  );

  const sortedFences = useMemo(
    /**
     * @returns {LinkInstance[]}
     */
    () => {
      /** @type {LinkInstance[]} */
      const fencesMap = Object.values(geofences).flatMap((geofence) => {
        const match = projects?.find(
          ({ geoFenceInfo }) =>
            geoFenceInfo?.[0]?.["shapeId"] === geofence.geofenceUUID
        );

        const position = findGeofenceCenterCoordinate(geofence.points);

        if (!position) {
          return [];
        }

        return {
          position,
          type: "GEOFENCE",
          address: geofence.name,
          id: geofence.geofenceUUID,
          matchId: match?.projectId,
          radius: (geofence?.radius ?? 0) * 1609,
          distance: withinRadius(
            position,
            (projectToLink || stopData).position
          ),
          points: (geofence?.points || [])?.map(
            ({ latitude: lat, longitude: lng }) => ({ lat, lng })
          ),
        };
      });

      fencesMap.sort((a, b) => {
        return a.distance.distanceInFeet - b.distance.distanceInFeet;
      });

      return fencesMap;
    },
    [projects, geofences, stopData, projectToLink]
  );

  /**
   * @param {google.maps.Polygon} e
   */
  function onPolygonComplete(e) {
    drawingManager.setMap(null);
    setCanSavePolygon(e);
  }

  function toggleInfo() {
    if (dataVisible) {
      saveLocalPreferences({ stopModalInfo: "collapsed" });
      setDataVisible(false);
    } else {
      saveLocalPreferences({ stopModalInfo: "expanded" });
      setDataVisible(true);
    }
  }

  function toggleView(linkProject = false) {
    if (view === "PROJECTS" || projectToLink) {
      if (linkProject) {
        if (map) {
          map.panTo(selectedFence.position);
          map.setZoom(19);
        }

        setProjectToLink(structuredClone(selectedFence));
        setSelectedFence(null);
      }

      if (projectToLink) {
        if (map) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(stopData.position);
          bounds.extend(projectToLink.position);

          map.fitBounds(bounds);
        }

        setSelectedFence(structuredClone(projectToLink));
        setProjectToLink(null);
        setView("PROJECTS");
      } else {
        setView("GEOFENCES");
      }
    } else {
      setView("PROJECTS");
    }

    if (selectedFence?.type !== "LOCATION" && !projectToLink && !linkProject) {
      if (map) {
        map.panTo(stopData.position);
        map.setZoom(19);
      }
      setSelectedFence(null);
    }
  }

  /**
   * @param {google.maps.Map} e
   */
  function onLoad(e) {
    e.panTo(stopData?.position);
    setMap(e);
  }

  /**
   *
   * @param {string} address
   * @param {SelectOption} option
   */
  async function onLocationSelect(address, option) {
    const { key, label, value, ...fenceInfo } = option;

    if (!label) {
      /**
       * when we don't have a defined label as an option,
       * it means that the address is a google option
       */
      message.loading({
        content: "Loading position...",
        key: "positionLoad",
        duration: 0,
      });

      try {
        const { coordinates } = await getCoordinatesAndZip(address);
        message.destroy("positionLoad");

        onFenceClick({
          id: "",
          address,
          points: [],
          matchId: "",
          type: "LOCATION",
          position: coordinates,
          distance: withinRadius(stopData.position, coordinates),
        });
      } catch (err) {
        message.error({
          content: "Something went wrong while loading address coordinates",
          key: "positionLoad",
        });
        console.log("Error loading position: ", err);
      }
    } else {
      onFenceClick(fenceInfo);
    }
  }

  function toggleMode() {
    if (!map || !drawingManager) {
      return;
    }

    if (mode === "VIEW") {
      if (selectedFence?.points?.length > 2) {
        setOverlayFence(selectedFence.points);
      }

      map.panTo(selectedFence?.position || stopData?.position);
      map.setZoom(19);

      drawingManager.setMap(map);
    } else {
      const bounds = new google.maps.LatLngBounds();
      if (selectedFence) {
        for (const coord of selectedFence.points.concat(
          selectedFence.position
        )) {
          bounds.extend(coord);
        }
      }

      if (projectToLink) {
        bounds.extend(projectToLink.position);
      } else {
        bounds.extend(stopData.position);
      }

      map.fitBounds(bounds);

      drawingManager.setMap(null);

      if (overlayFence?.length) {
        setOverlayFence([]);
      }
    }

    if (canSavePolygon) {
      canSavePolygon.setMap(null);
      canSavePolygon.setVisible(false);

      setCanSavePolygon(null);
    }

    if (newFenceForm) {
      setNewFenceForm(false);
    }

    setMode((prev) => (prev === "DRAW" ? "VIEW" : "DRAW"));
  }

  async function onFenceConfirm(confirmed = false) {
    const drawnPath = canSavePolygon
      .getPath()
      .getArray()
      .map(({ lat, lng }) => ({
        lat: lat(),
        lng: lng(),
      }));

    if (selectedFence) {
      if (
        !pointsInsideAPolygon(
          [selectedFence.position.lat, selectedFence.position.lng],
          drawnPath.map(({ lat, lng }) => [lat, lng])
        )
      ) {
        message.error({
          content: "The address is not included in the new geofence!",
          key: "pointOutside",
        });
        toggleMode();
        return;
      }
    }

    if (!confirmed) {
      setWarningVisible("NEW FENCE");
      return;
    }

    if (!selectedFence || selectedFence?.type === "LOCATION") {
      setNewFenceForm(true);
      return;
    }

    let newFence;

    if (selectedFence.type === "PROJECT") {
      if (selectedFence.matchId) {
        newFence = await onGeofenceUpdate({
          points: drawnPath,
          projectId: selectedFence.id,
          geofenceUUID: selectedFence.matchId,
        });
      } else {
        newFence = await onNewFenceCreate(selectedFence.address);
        let updatedProject;

        if (newFence) {
          updatedProject = await onProjectFenceUpdate({
            points: drawnPath,
            projectId: selectedFence.id,
            address: selectedFence.address,
            geofenceUUID: newFence.geofenceUUID,
          });

          if (updatedProject) {
            setSelectedFence({
              ...selectedFence,
              points: drawnPath,
              matchId: newFence.geofenceUUID,
            });
          }
        }
      }
    } else {
      newFence = await onGeofenceUpdate({
        points: drawnPath,
        geofenceUUID: selectedFence.id,
        projectId: selectedFence?.matchId,
      });

      setSelectedFence({
        ...selectedFence,
        points: drawnPath,
      });
    }

    toggleMode();
  }

  async function linkProjectHandler() {
    if (
      !pointsInsideAPolygon(
        [projectToLink.position.lat, projectToLink.position.lng],
        selectedFence.points.map(({ lat, lng }) => [lat, lng])
      )
    ) {
      message.error({
        content: "The address is not included in the geofence!",
        key: "pointOutside",
      });
      return;
    }

    const updatedProject = await onProjectFenceUpdate({
      address: selectedFence.address,
      geofenceUUID: selectedFence.id,
      points: selectedFence.points,
      projectId: projectToLink.id,
    });

    if (updatedProject) {
      setSelectedFence({
        ...projectToLink,
        matchId: updatedProject?.geoFenceInfo?.[0]?.["shapeId"],
        points: updatedProject?.geoFenceInfo?.[0]?.["points"] || [],
      });

      if (map) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(stopData?.position);

        for (const point of updatedProject?.geoFenceInfo?.[0]?.["points"] ||
          []) {
          bounds.extend(point);
        }

        map.fitBounds(bounds);
      }

      setProjectToLink(null);
      setView("PROJECTS");
    } else {
      toggleView();
    }
  }

  /**
   * @param {string} name
   * @param {LatLng[]} [customPoints]
   */
  async function onNewFenceCreate(name, customPoints) {
    message.loading({
      content: "Saving geofence...",
      key: "messagesChange",
      duration: 0,
    });

    let newFence;
    let points;

    if (!customPoints) {
      if (canSavePolygon) {
        points = canSavePolygon
          .getPath()
          .getArray()
          .map(({ lat, lng }) => ({ latitude: lat(), longitude: lng() }));
      }
    } else {
      points = [...customPoints];
    }

    try {
      newFence = await API.post("getGeofences", "/getGeofences", {
        body: {
          geofenceInfo: {
            name,
            points,
            fenceGroup: "Jobsites",
          },
        },
      });

      message.success({
        content: "Geofence created successfully!",
        key: "messagesChange",
      });

      setGeofences((prev) => ({
        ...prev,
        [getFence(name)]: newFence,
      }));
    } catch (err) {
      message.error({
        content: "Something went wrong while creating geofence",
        key: "messagesChange",
      });
      console.log("Error creating geofence: ", err);
    }

    return newFence;
  }

  /**
   * @param {ProjectUpdateParam} data
   */
  async function onProjectFenceUpdate(data) {
    const relatedProject = projects.find(
      ({ projectId }) => projectId === data.projectId
    );

    if (!relatedProject) {
      return;
    }

    const relatedJobsite = jobsites.find(
      ({ projectId }) => projectId === data.projectId
    );

    message.loading({
      content: "Saving project...",
      key: "messagesChange",
      duration: 0,
    });

    const body = {
      geoFenceInfo: [
        {
          type: "Polygon",
          description: "",
          title: data.address,
          createdAt: Date.now(),
          geoFenceInfo: data.points,
          shapeId: data.geofenceUUID,
          createdBy: userConfiguration.nameOfUser,
        },
      ],
    };

    return await Promise.all(
      [
        API.put("projects", `/projects/${relatedProject.projectId}`, { body }),
        relatedJobsite &&
          API.put("jobsites", `/jobsites/${relatedJobsite?.jobsiteId}`, {
            body,
          }),
      ].filter(Boolean)
    )
      .then(() => {
        message.success({
          content: "Project saved successfully!",
          key: "messagesChange",
        });

        updateProjectData({
          ...relatedProject,
          ...body,
        });

        return {
          ...relatedProject,
          ...body,
        };
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while updating project",
          key: "messagesChange",
        });
        console.log("Error updating data: ", err);

        return null;
      });
  }

  /**
   * @param {Object} data
   * @param {string} data.geofenceUUID
   * @param {LatLng[]} data.points
   * @param {string} [projectId]
   */
  async function onGeofenceUpdate({ geofenceUUID, points, projectId }) {
    const relatedGeofence = Object.values(geofences).find(
      ({ geofenceUUID: id }) => id === geofenceUUID
    );

    if (!relatedGeofence) {
      return;
    }

    const geofencePoints = points.map(({ lat: latitude, lng: longitude }) => ({
      latitude,
      longitude,
    }));

    message.loading({
      content: "Updating geofence data...",
      key: "messagesChange",
      duration: 0,
    });

    const { name, fenceGroup } = relatedGeofence;

    let updatedFence;

    try {
      updatedFence = await API.put(
        "getGeofences",
        `/getGeofences/${geofenceUUID}`,
        {
          body: {
            name,
            fenceGroup,
            status: "act",
            type: "polygon",
            radius: undefined,
            points: geofencePoints,
          },
        }
      );

      setGeofences((prev) => ({
        ...prev,
        [getFence(name)]: updatedFence,
      }));

      message.success({
        content: "Geofence updated successfully",
        key: "messagesChange",
      });
    } catch (err) {
      message.error({
        content: "Something went wrong while updating the geofence",
        key: "messagesChange",
      });
      console.log("Error updating geofence: ", err);
    }

    if (updatedFence && projectId) {
      await onProjectFenceUpdate({
        points,
        projectId,
        geofenceUUID,
        address: name,
      });
    }

    return updatedFence;
  }

  function onFinish() {
    if (!selectedFence) {
      return;
    }

    if (selectedFence.type === "PROJECT" && !selectedFence.matchId) {
      return;
    }

    if (selectedFence.type === "LOCATION") {
      return;
    }

    let geofence;
    if (selectedFence.type === "PROJECT") {
      geofence = Object.values(geofences).find(
        ({ geofenceUUID }) => geofenceUUID === selectedFence.matchId
      );
    } else {
      geofence = Object.values(geofences).find(
        ({ geofenceUUID }) => geofenceUUID === selectedFence.id
      );
    }

    if (!geofence) {
      return;
    }

    onSubmit(geofence);
  }

  async function handleCreateOnLocation(name) {
    const newFence = await onNewFenceCreate(name);

    if (newFence) {
      const prevSelectedFence = structuredClone(selectedFence);
      setTimeout(() => {
        const polygonPoints = newFence.points.map(
          ({ latitude: lat, longitude: lng }) => ({
            lat,
            lng,
          })
        );

        const position = findGeofenceCenterCoordinate(polygonPoints);

        const bounds = new google.maps.LatLngBounds();
        bounds.extend(stopData.position);

        for (const point of polygonPoints) {
          bounds.extend(point);
        }

        let tmpBody = {
          position,
          matchId: "",
          address: name,
          type: "GEOFENCE",
          points: polygonPoints,
          id: newFence?.["geofenceUUID"],
          distance: withinRadius(position, stopData.position),
        };

        if (prevSelectedFence?.type === "PROJECT") {
          tmpBody["type"] = "PROJECT";
          tmpBody["id"] = prevSelectedFence?.["id"];
          tmpBody["matchId"] = newFence?.["geofenceUUID"];
          tmpBody["position"] = prevSelectedFence?.["position"];
        }

        setSelectedFence(tmpBody);

        setView("GEOFENCES");
      }, 100);
    }

    toggleMode();
  }

  const markerInstance = projectToLink || selectedFence;
  const garage = garageAddresses?.[stopData?.fleetId];

  return (
    <Fragment>
      <Modal
        {...{
          open,
          onCancel() {
            setWarningVisible("CANCEL");
          },
          closeIcon: <XIcon />,
          className: `stop-modal-container${
            isDarkMode ? " stop-modal-dark" : ""
          }`,
          footer: footerButtons({
            onCancel() {
              setWarningVisible("CANCEL");
            },
            onDiscard() {
              setWarningVisible("DISCARD");
            },
            onSubmit() {
              setWarningVisible("SUBMIT");
            },
            selectedFence:
              mode === "DRAW" || canSavePolygon || projectToLink
                ? undefined
                : selectedFence,
          }),
          centered: true,
          destroyOnClose: true,
          maskClosable: false,
          title: projectToLink
            ? `Link Project: ${projectToLink.address}`
            : `Stop${stopData?.fleetName ? ` for ${stopData?.fleetName}` : ""}${
                stopData?.beginDate
                  ? ` on ${dayjsNY(stopData?.beginDate).format("MM/DD/YYYY")}`
                  : ""
              }`,
        }}
      >
        <StopInfo {...{ dataVisible, stopData }} />
        <NearestFences
          {...{
            mode,
            view,
            toggleInfo,
            toggleView,
            isDarkMode,
            dataVisible,
            onFenceClick,
            sortedFences,
            projectToLink,
            selectedFence,
            sortedProjects,
            projectsLoading,
          }}
        />
        <div className="stop-map-section">
          <Popover
            content={
              <FenceSaveForm
                {...{
                  stopData,
                  isDarkMode,
                  toggleMode,
                  selectedFence,
                  handleCreateOnLocation,
                }}
              />
            }
            open={newFenceForm}
            placement="bottomRight"
            overlayClassName={`popover-stop-save-fence stop-modal-popover-content ${
              isDarkMode ? " popover-dark" : ""
            }`}
          >
            <StopController
              {...{
                map,
                mode,
                stopData,
                toggleView,
                isDarkMode,
                toggleMode,
                newFenceForm,
                sortedFences,
                projectToLink,
                selectedFence,
                sortedProjects,
                onFenceConfirm,
                canSavePolygon,
                onLocationSelect,
                setSelectedFence,
                linkProjectHandler,
              }}
            />
          </Popover>
          {mapLoaded && (
            <GoogleMap
              {...{
                onLoad,
                zoom: 16,
                id: "stop-map",
                mapContainerClassName: "stop-map",
                options: {
                  disableDefaultUI: true,
                  styles: MAP_THEME[loadLivePreference("mapStyle")],
                  mapTypeId: loadLivePreference("mapType"),
                  fullscreenControl: true,
                  clickableIcons: false,
                  rotateControl: true,
                  fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  },
                  streetViewControl: true,
                  streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  },
                },
              }}
            >
              <OverlayViewF
                mapPaneName="overlayMouseTarget"
                position={stopData?.position}
              >
                <InfoWindowF position={stopData?.position}>
                  <b>{stopData?.address}</b>
                </InfoWindowF>
                <FleetWhite
                  fill={
                    (stopData?.stopType || "")
                      ?.toUpperCase()
                      ?.includes("IDLING")
                      ? "#f5a623"
                      : "#fe4c4a"
                  }
                  style={{
                    position: "absolute",
                    top: "-13px",
                    left: "-15px",
                  }}
                />
              </OverlayViewF>
              {garage?.position && (
                <Fragment>
                  <InfoWindowF
                    position={garage.position}
                    options={{
                      pixelOffset: pixelOffset.current,
                    }}
                  >
                    <b>{garage.address}</b>
                  </InfoWindowF>
                  <MarkerF
                    {...{
                      position: garage.position,
                      clickable: false,
                      icon: {
                        url: garagePin,
                        anchor: pinAnchor.current,
                      },
                    }}
                  />
                </Fragment>
              )}
              {markerInstance && (
                <OverlayViewF
                  mapPaneName="overlayMouseTarget"
                  position={markerInstance.position}
                >
                  <InfoWindowF position={markerInstance.position}>
                    <b>{markerInstance.address}</b>
                  </InfoWindowF>
                  <Marker
                    {...{
                      onClick() {},
                      ...markerInstance.position,
                      color: markerColor.current,
                      address: markerInstance.address,
                    }}
                  />
                </OverlayViewF>
              )}
              {projectToLink && selectedFence && (
                <InfoWindowF position={selectedFence.position}>
                  <b>{selectedFence.address}</b>
                </InfoWindowF>
              )}
              {selectedFence?.points?.length === 1 ? (
                <CircleF
                  visible={mode === "VIEW"}
                  center={selectedFence?.points.at(0)}
                  radius={selectedFence?.radius}
                  options={{
                    fillColor: "#1264a3",
                    fillOpacity: 0.35,
                    strokeWeight: 2,
                    strokeOpacity: 1,
                    strokeColor: "#1264a3",
                    clickable: false,
                    draggable: false,
                  }}
                />
              ) : (
                <Polygon
                  {...{
                    options: {
                      editable: false,
                      strokeWeight: 2,
                      draggable: false,
                      strokeOpacity: 1,
                      fillOpacity: 0.35,
                      fillColor: "#f6cb51",
                      strokeColor: "#f6cb51",
                    },
                    visible: mode === "VIEW",
                    paths: selectedFence?.points || [],
                  }}
                />
              )}
              <Polygon
                {...{
                  options: {
                    editable: false,
                    strokeWeight: 2,
                    draggable: false,
                    fillOpacity: 0.2,
                    strokeOpacity: 0.7,
                    fillColor: "#d99f00",
                    strokeColor: "#d99f00",
                  },
                  visible: !!overlayFence?.length,
                  paths: overlayFence,
                }}
              />
              <DrawingManagerF
                {...{
                  onLoad(e) {
                    setDrawingManager(e);
                    e.setMap(null);
                  },
                  onPolygonComplete,
                  options: {
                    polygonOptions: {
                      fillColor: "#52c41a",
                      strokeColor: "#52c41a",
                      fillOpacity: 0.4,
                      strokeWeight: 2,
                    },
                    drawingControlOptions: {
                      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                    },
                    drawingMode:
                      mode === "DRAW"
                        ? google.maps.drawing.OverlayType.POLYGON
                        : null,
                  },
                }}
              />
            </GoogleMap>
          )}
        </div>
      </Modal>
      {warningVisible && (
        <WarningModal
          visible={Boolean(warningVisible)}
          setVisible={setWarningVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              {warningVisible === "CANCEL"
                ? "Are you sure you want to cancel?"
                : warningVisible === "SUBMIT"
                ? `Are you sure you want to link this stop with ${selectedFence.address}?`
                : warningVisible === "NEW FENCE"
                ? "This fence will be saved to Linxup, are you sure you want to continue?"
                : "Are you sure you want to discard this stop?"}
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setWarningVisible(null)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (warningVisible === "CANCEL") {
                    onCancel();
                  } else if (warningVisible === "SUBMIT") {
                    onFinish();
                  } else if (warningVisible === "NEW FENCE") {
                    onFenceConfirm(true);
                  } else {
                    onDiscard(stopData);
                  }

                  setWarningVisible(null);
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Fragment>
  );
}

export default LinkStopModal;
