// ExportChartsAsPdf.jsx

import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ExportPdfIcon } from "../../../../../../assets";
import MondayButton from "../../../../MondayButton/MondayButton";
import { message } from "antd";
import { useChartRefsContext } from "../../../providers/ChartRefsContext";

function ExportChartsAsPdf() {
  const { chartRefs } = useChartRefsContext();

  const exportPDF = async () => {
    try {
      const images = [];
      const chunkSize = 5; // Adjust chunk size based on performance testing

      for (let i = 0; i < chartRefs.length; i += chunkSize) {
        const chunk = chartRefs.slice(i, i + chunkSize);
        const chunkImages = await Promise.all(
          chunk.map(async ({ title, ref }) => {
            const canvas = await html2canvas(ref);
            return { title, image: canvas.toDataURL("image/png") };
          })
        );
        images.push(...chunkImages);
      }

      const pdf = new jsPDF();
      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;
      const margin = 15;
      let xPosition = margin;
      let yPosition = margin;
      const imageWidth = (pageWidth - margin * 3) / 2;
      const imageHeight = imageWidth * 1;
      const borderWidth = 1;
      const borderColor = "#808080";
      const padding = 5;
      const cornerRadius = 3;

      images.forEach(({ title, image }, index) => {
        if (xPosition + imageWidth > pageWidth - margin) {
          xPosition = margin;
          yPosition += imageHeight + margin;
          if (yPosition + imageHeight > pageHeight - margin) {
            pdf.addPage();
            yPosition = margin;
          }
        }

        pdf.setDrawColor(borderColor);
        pdf.setLineWidth(borderWidth / pdf.internal.scaleFactor); // Scale border width according to internal scale factor
        pdf.roundedRect(
          xPosition,
          yPosition,
          imageWidth,
          imageHeight,
          cornerRadius,
          cornerRadius,
          "S"
        );

        pdf.text(title, xPosition, yPosition - 5);
        pdf.addImage(
          image,
          "PNG",
          xPosition + borderWidth + padding,
          yPosition + borderWidth + padding,
          imageWidth - (borderWidth + padding) * 2,
          imageHeight - (borderWidth + padding) * 2
        );

        xPosition += imageWidth + margin;
      });

      pdf.save("charts.pdf");
      message.success("Charts exported to PDF successfully!");
    } catch (error) {
      console.error("Error exporting charts to PDF:", error);
      message.error("Failed to export charts to PDF. Please try again.");
    } finally {
      message.destroy();
    }
  };

  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<ExportPdfIcon />}
        onClick={() => {
          message.info({ content: "Exporting charts to PDF. Please wait..." });
          setTimeout(() => {
            exportPDF();
          }, 500);
        }}
      />
    </>
  );
}

export default ExportChartsAsPdf;
