import "./DoughnutChart.scss";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useChartRefsContext } from "../../../providers/ChartRefsContext";
import { useEffect, useRef } from "react";

const DoughnutChart = ({
  chartData: { labels, data, label, backgroundColor },
  onClick = () => {},
  title,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { registerChartRef } = useChartRefsContext();
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      registerChartRef(chartRef.current, title);
    }
  }, [registerChartRef]);

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          padding: labels.length > 10 ? 5 : 10,
          boxWidth: labels.length > 10 ? 20 : 40,
          color: isDarkMode ? "#f2f2f2" : "#333238",
        },
      },
    },
    onHover: (event) => {
      event.native.target.style.cursor = "pointer";
    },
    onClick,
  };

  return (
    <div className="card-doughnut-chart" ref={chartRef}>
      <Doughnut
        data={{
          labels,
          datasets: [{ label, data, backgroundColor, hoverOffset: 4 }],
        }}
        options={options}
      />
    </div>
  );
};

export default DoughnutChart;
