import dayjs from "dayjs";

export const setInitialDynamicFieldValues = (citationObject, dynamicFields) => {
  const initialValues = {};
  dynamicFields.forEach((field) => {
    if (field.type === "datepicker" && citationObject[field.formItemName]) {
      initialValues[field.formItemName] = dayjs(
        citationObject[field.formItemName]
      );
    } else if (
      field.type === "timepicker" &&
      citationObject[field.formItemName]
    ) {
      const timeValue =
        typeof citationObject[field.formItemName] === "number"
          ? dayjs(citationObject[field.formItemName])
          : dayjs(citationObject[field.formItemName], "HH:mm");
      initialValues[field.formItemName] = timeValue.isValid()
        ? timeValue
        : null;
    } else {
      initialValues[field.formItemName] = citationObject[field.formItemName];
    }
  });
  return initialValues;
};
