export const filterVisibleSteps = ({
  useExistingData,
  title,
  existingEstimations,
  isEditProject,
}) => {
  if (!isEditProject && title === "Forecasting") {
    return false;
  }

  return !useExistingData || !existingEstimations.length
    ? !["estimations", "services", "documentation"].includes(
        title.toLowerCase()
      )
    : true;
};
