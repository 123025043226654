import _ from "lodash";
import { Form, message } from "antd";
import { useEffect, useState } from "react";
import "./Upload.scss";
import { GPickerButton } from "../../../Fleet/components";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { DeleteIcon } from "../../src";
import { driveApi } from "../../../../../integrations/DriveRequest";
import { XIcon } from "../../../Communication/assets";
import { WarningIcon } from "../../../../../icons";
import SingleFile from "../../cardComponent/Upload/SingleFile";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import { UploadDoc } from "../../../Documentation/View/assets";
import { FilePreviewModal } from "../../../Documentation/View/components";

const Upload = (prop) => {
  const {
    params: { content: data },
    getEditedValue,
    setEditMode,
    form,
    getFormChanged = () => {},
    setFormChanged = () => {},
  } = prop;
  let formList = {};
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [rowData, setRowData] = useState({});
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [currentFile, setCurrentFile] = useState();

  formList = { [rowData.formItemName]: rowData.value };
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const driveRequest = driveApi({ accessToken });
  const [prev, setPrev] = useState();
  const deleteFile = async (id) => {
    setFormChanged(true);
    setDeletedFiles((prev) => [
      ...prev,
      rowData?.value?.find?.((td) => td.id === id),
    ]);
    let tmpRowDataValue = rowData?.value?.filter?.((td) => td.id !== id);
    form.setFieldsValue({
      [rowData.formItemName]: tmpRowDataValue,
    });
    setRowData({
      formItemName: rowData.formItemName,
      value: tmpRowDataValue,
    });
  };

  const onCancel = () => {
    setFormChanged(false);
    setEditMode(false);
    setCancelModalVisible(false);
    if (deletedFiles.length > 0) {
      setRowData((prev) => ({
        ...prev,
        value: [...prev.value, ...deletedFiles],
      }));
      form.setFieldsValue({
        [rowData.formItemName]: [...rowData.value, ...deletedFiles],
      });
      setDeletedFiles([]);
    }
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  useEffect(() => {
    setPrev(() => _.cloneDeep(prop));
  }, [prop]);

  useEffect(() => {
    form.setFieldsValue({ [data.formItemName]: data.value });
    setRowData({
      formItemName: data.formItemName,
      value: form.getFieldsValue()[data.formItemName],
    });
  }, [form]);

  function onConfirmHandler() {
    if (deletedFiles.length > 0) {
      message.loading("Deleting files...");
      Promise.all(
        deletedFiles.map(({ id }) => {
          return driveRequest?.deleteDriveItem(id);
        })
      )
        .then(() => {
          setDeletedFiles([]);
          prop.params.content.value = form.getFieldsValue()[data.formItemName];
          getEditedValue(
            form.getFieldsValue(),
            undefined,
            formList,
            prev,
            prop
          );
          setEditMode(false);
          message.success("Files deleted successfully");
        })
        .catch((err) => {
          message.error("Error deleting files");
          console.log(err);
        });
    } else {
      prop.params.content.value = form.getFieldsValue()[data.formItemName];
      getEditedValue(form.getFieldsValue(), undefined, formList, prev, prop);
      setEditMode(false);
    }
  }
  return (
    <div className="UploadComponent">
      <Form
        form={form}
        onFieldsChange={() => {
          setFormChanged(true);
        }}
      >
        <Form.Item name={data.formItemName} noStyle />
      </Form>
      <div className="uploadButtonContainer">
        <UploadDoc className="uploaderIcon" />
        <span className="uploaderTitle">Upload Here.</span>
        <GPickerButton
          label=""
          style={{ alignSelf: "unset" }}
          accessToken={accessToken}
          parentId={data?.parentId}
          onPickerSuccess={(e) => {
            setFormChanged(true);
            form.setFieldsValue({
              [data.formItemName]: [
                ...rowData.value,
                ...e.uploadedFiles.map((item) => ({
                  ...item,
                  uploadedBy: userConfiguration?.nameOfUser,
                })),
              ],
            });
            setRowData({
              formItemName: data.formItemName,
              value: form.getFieldsValue()[data.formItemName],
            });
          }}
        >
          Upload
        </GPickerButton>
      </div>
      <div className="documentWrapper">
        {(Array.isArray(rowData?.value) ? rowData?.value : [])?.map(
          (file, index) => {
            const fileExtension = file?.name.split(".").pop();

            const fileType = Object.keys(fileIcons).includes(fileExtension)
              ? fileExtension
              : "image";
            return (
              <SingleFile
                {...{
                  driveRequest,
                  index,
                  file,
                  setCurrentFile,
                  setShowModal,
                  handleDelete: deleteFile,
                  editCard: true,
                  fileType,
                  isDarkMode,
                }}
                key={index}
              />
            );
          }
        )}
      </div>
      <div className="confirmContainer">
        <MondayButton
          onClick={(res) => {
            if (getFormChanged()) {
              setCancelModalVisible(true);
              setCurrentFile("");
            } else {
              onCancel();
            }
          }}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          onClick={onConfirmHandler}
          className="confirmButton"
          Icon={<Tick width={20} height={20} />}
        >
          Confirm
        </MondayButton>
      </div>

      {showModal && (
        <FilePreviewModal
          {...{
            visible: true,
            setVisible: () => {
              setShowModal(false);
              setCurrentFile("");
            },
            file: currentFile,
          }}
        />
      )}
      {cancelModalVisible && (
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible(false)}
                Icon={<CloseIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<Tick />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </div>
  );
};
export default Upload;
