import { createContext, useContext } from "react";

export const ChartCardsContext = createContext({});

export const useChartCardsContext = () => {
  const context = useContext(ChartCardsContext);
  if (context === undefined) {
    throw new Error(
      "useChartCardsContext must be used within a ChartCardsContext.Provider!"
    );
  }
  return context;
};
