import { Select } from "antd";
import { AutoRenew } from "../../../../../../assets";
import { MondayButtonIcon } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { compareIncluding } from "../../../../utils";
import moment from "moment";
import { GoTasks } from "../../../../../../icons";
import { GPickerButton } from "../../../components";
import {
  handlePopupScroll,
  searchFetchInput,
} from "src/utils/searchFetchForInput";
import debounce from "lodash.debounce";

export const footerButtons = ({
  onCancel,
  onSave,
  saving,
  onOptionClick,
  suffix,
}) => [
  {
    text: "Cancel",
    cancel: true,
    onClick: onCancel,
    className: "mondayButtonRed",
    tooltipCategory: "Fleets Violations",
    tooltipKey: "cancel",
  },

  {
    text: "Finish",
    primary: true,
    disabled: !!saving,
    onClick: onSave,
    options: ["Save & Close"],
    onOptionClick: onOptionClick,
    className: "mondayButtonBlue saveBtn",
    suffix: suffix,
    tooltipCategory: "Fleets Violations",
    tooltipKey: "finish",
  },
];
export const currencies = ["$", "€", "£", "¥"];
export const paymentStatus = ["Yes", "No"];
export const dateFormat = "MM/DD/YYYY";
export const timeFormat = "HH:mm";

/**
 * The point of this object is to translate the keys used in the violation object
 * into keys used in the violation form
 */
export const violationKeyTranslate = {
  "violation#": "summons_number",
  violation: "violation",
  interestAmount: "interest_amount",
  driver: "driverId",
  issuedDate: "issue_date",
  issuingAgency: "issuing_agency",
  liabilityAmount: "fine_amount",
  licenseType: "license_type",
  penaltyAmount: "penalty_amount",
  reductionAmount: "reduction_amount",
  vehicle: "fleetId",
  violationLocation: "NY, USA",
  violationStatus: "violation_status",
  amountPaid: "payment_amount",
  fleetViolationTeam: "teamsConfiguration",
};

export const dynamicInputFields = ({
  syncedSuccessfully,
  syncViolation,
  form,
  isDarkMode,
  projects,
  setIsViolationStatusPaid,
  isViolationStatusPaid,
  calculateTotalAmount,
  drivers,
  driverName,
  driverId,
  setDriverRequired,
  driverRequired,
  fleet,
  fleetName,
  fleetId,
  setPdfModal,
  fleetNameFromPath,
  violationStatusOptions,
  driverOptions,
  agencyOptions,
  fleetOptions,
  projectOptions,
  receivedByOptions,
  setSelectedTeam,
  dateFormat,
  timeFormat,
  verifyUrl,
  accessToken,
  onPickerSuccess,
  onFileDelete,
  driveFolderId,
  fileNames,
  routedDriverID,
  changePayment,
  projectSearchRef,
  currentPageKey,
  setCurrentPageKey,
  setLoadingRecords,
  setProjects,
  userConfiguration,
  plates,
}) => {
  return {
    "General Information": [
      {
        formItemName: "violation#",
        label: "Violation#",
        disabled: syncedSuccessfully,
        required: true,
        placeholder: "Violation#",
        style: { minWidth: 215 },
        type: "input",
      },
      {
        customComponent: MondayButtonIcon,
        componentProps: {
          Icon: <AutoRenew />,
          onClick: () => {
            syncViolation(true);
          },
          title: "Sync Violation",
          className: "syncViolationBtn",
          style: {},
        },
        formItemName: "syncViolation",
        label: "Sync Violation Button",
      },
      {
        required: true,
        label: "Violation",
        formItemName: "violation",
        placeholder: "Violation",
        style: { width: 215 },
      },
      {
        label: "Violation Location",
        popupClassName: isDarkMode && "darkDropDown",
        formItemName: "violationLocation",
        placeholder: "Click and Search...",
        onSelect: (value) => {
          const jobsite = projects?.find(({ projectName }) =>
            compareIncluding(projectName, value)
          )?.projectName;
          !!jobsite && form.setFieldsValue({ jobsite });
        },
        customOnSearch: debounce((e) => {
          projectSearchRef.current = e;
          return searchFetchInput({
            table: "projects",
            idKey: "projectId",
            searchKey: "projectName",
            searchValue: e,
            setLoading: setLoadingRecords,
            setRecords: setProjects,
            userConfiguration,
          });
        }, 500),

        onPopupScroll: (e) => {
          handlePopupScroll({
            e,
            table: "projects",
            idKey: "projectId",
            currentPageKey,
            setLoading: setLoadingRecords,
            setRecords: setProjects,
            userConfiguration,
            setCurrentPageKey,
          });
        },

        groupedOptions: [
          {
            label: "Projects",
            options: projects?.map(({ projectName }, i) => ({
              key: i,
              value: projectName,
            })),
          },
        ],
        keepOptions: true,
        required: true,
        style: { minWidth: 200, width: 350 },
        type: "placesautocomplete",
      },
      {
        required: true,
        label: "Violation Status",
        formItemName: "violationStatus",
        dropdownClassName: isDarkMode && "darkDropDown",
        placeholder: "Select...",
        type: "select",
        options: violationStatusOptions,
        onChange: (value) => {
          if (value === "Paid") {
            form.setFieldValue("amountPaid", calculateTotalAmount());
            setIsViolationStatusPaid(true);
          }
          if (value !== "Paid" && isViolationStatusPaid === true) {
            setIsViolationStatusPaid(false);
            form.setFieldValue("amountPaid", 0);
          }
        },
        showSearch: true,
        style: { width: 210 },
      },
      {
        required: driverRequired,
        label: "Driver",
        formItemName: "driver",
        placeholder: "Click and Search...",
        initialValue: driverName ? driverName : driverId,
        dropdownClassName: isDarkMode && "darkDropDown",
        disabled: driverName ? true : !!driverId,
        type: "select",
        showSearch: true,
        style: { width: 270 },
        onSelect: (selectedDriverId) => {
          setDriverRequired(true);
          form.setFieldsValue({
            licenseType: drivers?.find(({ driverId: arrDriverId }) =>
              compareIncluding(arrDriverId, selectedDriverId)
            )?.DlClass,
            driverIdentified: "Yes",
          });
        },
        customOptions: driverOptions,
      },
      {
        label: "Driver Identified?",
        type: "radio",
        buttonStyle: "solid",
        formItemName: "driverIdentified",
        defaultValue: driverRequired ? "Yes" : "No",
        disabled: !!routedDriverID,
        onChange: (e) => {
          if (e.target.value === "Yes") {
            setDriverRequired(true);
          } else {
            setDriverRequired(false);
            form.setFieldValue("driver", "");
          }
        },
        options: ["Yes", "No"],
      },
      {
        required: true,
        label: "Vehicle",
        formItemName: "vehicle",
        placeholder: "Click and Search...",
        dropdownClassName: isDarkMode && "darkDropDown",
        type: "select",
        initialValue: fleetName
          ? fleetName
          : !!fleetNameFromPath
          ? fleetNameFromPath
          : fleetId,
        disabled: fleetName
          ? true
          : !!fleetId
          ? true
          : !!fleetNameFromPath
          ? true
          : syncedSuccessfully
          ? true
          : false,
        showSearch: true,
        onChange: (e) => {
          let selectedTruck = fleet?.find((el) => el?.fleetId === e);
          setSelectedTeam(selectedTruck?.teamsConfiguration || []);
          form.setFieldValue("licensePlate", selectedTruck?.licensePlate);
          form.setFieldValue(
            "violationTeam",
            (selectedTruck?.teamsConfiguration || [])?.map(
              (team) => team?.value
            )
          );
        },
        customOptions: fleetOptions,
        style: { width: 260 },
      },
      {
        required: true,
        label: "License Plate",
        formItemName: "licensePlate",
        placeholder: "Plate",
        style: { width: 260 },
        dropdownClassName: isDarkMode && "darkDropDown",
        type: "select",
        disabled: fleetName
          ? true
          : !!fleetId
          ? true
          : !!fleetNameFromPath
          ? true
          : syncedSuccessfully
          ? true
          : false,
        showSearch: true,
        onChange(e) {
          let selectedTruck = fleet?.find((el) => el?.fleetId === e);
          setSelectedTeam(selectedTruck?.teamsConfiguration || []);
          form.setFieldValue("vehicle", selectedTruck?.fleetId);
          form.setFieldValue(
            "violationTeam",
            (selectedTruck?.teamsConfiguration || [])?.map(
              (team) => team?.value
            )
          );
        },
        customOptions: plates,
      },
      {
        required: true,
        label: "Issued Date and Time",
        formItemName: "issuedDate",
        dropdownClassName: isDarkMode && "darkDateDropDown",
        type: "datePicker",
        format: `${dateFormat} ${timeFormat}`,
        showTime: { format: timeFormat },
        disabledDate: (curr) => curr > moment(),
        style: { width: 280 },
        disabled: syncedSuccessfully,
      },
      {
        label: "Received Date",
        formItemName: "receivedDate",
        dropdownClassName: isDarkMode && "darkDateDropDown",
        type: "datePicker",
        disabledDate: (curr) => curr > moment(),
        rules: [
          {
            validator: (_, value) =>
              !!value && value < form.getFieldValue("issuedDate")
                ? Promise.reject(
                    new Error(
                      "Received Date cannot be earlier than issued date"
                    )
                  )
                : Promise.resolve(),
          },
        ],
        style: { width: 200 },
      },
    ],
    "Violation Amount": [
      {
        required: true,
        label: "Liability Amount",
        formItemName: "liabilityAmount",
        placeholder: "Liability Amount",
        typeNumber: true,
        onChange: () => {
          changePayment("liabilityAmount");
        },
        rules: [
          {
            validator: (_, value) =>
              !!value && parseInt(value) > 0
                ? Promise.resolve()
                : Promise.reject(new Error("Please enter a valid value!")),
          },
        ],
        addonAfter: "$",
        disabled: syncedSuccessfully,
      },
      {
        label: "Interest Amount",
        formItemName: "interestAmount",
        placeholder: "Interest Amount",
        typeNumber: true,
        onChange: () => {
          changePayment("interestAmount");
        },
        rules: [
          {
            validator: (_, value) =>
              !!value
                ? parseInt(value) >= 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Please enter a valid value!"))
                : Promise.resolve(),
          },
        ],
        addonAfter: "$",
        disabled: syncedSuccessfully,
      },
      {
        label: "Penalty Amount",
        formItemName: "penaltyAmount",
        placeholder: "Penalty Amount",
        typeNumber: true,
        onChange: () => {
          changePayment("penaltyAmount");
        },
        rules: [
          {
            validator: (_, value) =>
              !!value
                ? parseInt(value) >= 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Please enter a valid value!"))
                : Promise.resolve(),
          },
        ],
        addonAfter: "$",
        disabled: syncedSuccessfully,
      },
      {
        label: "Reduction Amount",
        formItemName: "reductionAmount",
        placeholder: "Reduction Amount",
        typeNumber: true,
        onChange: () => {
          changePayment("reductionAmount");
        },
        rules: [
          {
            validator: (_, value) =>
              !!value
                ? value >= 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Please enter a valid value!"))
                : Promise.resolve(),
          },
        ],
        addonAfter: "$",
        disabled: syncedSuccessfully,
      },
      {
        label: "Amount Paid",
        formItemName: "amountPaid",
        placeholder: "Amount Paid",
        typeNumber: true,
        disabled: isViolationStatusPaid,
        rules: [
          {
            validator: (_, value) => {
              if (form.getFieldValue("violationStatus") !== "Paid") {
                if (!!value) {
                  if (
                    parseInt(value) >= 0 &&
                    parseInt(value) <= calculateTotalAmount()
                  ) {
                    if (parseInt(value) === calculateTotalAmount()) {
                      form.setFieldValue("paymentStatus", "Yes");
                    } else {
                      form.setFieldValue("paymentStatus", "No");
                    }
                    return Promise.resolve();
                  } else {
                    form.setFieldValue("paymentStatus", "No");
                    return Promise.reject(
                      new Error(
                        "Value should be less than or equal to the total amount"
                      )
                    );
                  }
                } else {
                  form.setFieldValue("paymentStatus", "No");
                  return Promise.reject(
                    new Error("Please enter a valid value!")
                  );
                }
              }
            },
          },
        ],
        addonAfter: "$",
      },
      {
        disabled: true,
        label: "Paid?",
        formItemName: "paymentStatus",
        type: "radio",
        buttonStyle: "solid",
        options: ["Yes", "No"],
      },
    ],
    "Additional Information": [
      {
        label: "Jobsite",
        formItemName: "jobsite",
        placeholder: "Click and Search...",
        dropdownClassName: isDarkMode && "darkDropDown",
        type: "select",
        showSearch: true,
        customOptions: projectOptions,
        style: { minWidth: 315, width: "100%" },
      },
      {
        label: "Verify",
        formItemName: "verify",
        placeholder: "Link to the DMV website",
        rules: [{ type: "url" }],
      },
      {
        customComponent: MondayButtonIcon,
        componentProps: {
          Icon: <GoTasks />,
          onClick: () => {
            // let url = verifyUrl ?? form.getFieldValue("verify");
            // if (url) {
            //   url = url.replace(
            //     /(^http:\/\/)|(^https:\/\/)/,
            //     `${location.protocol}//`
            //   );
            // }

            window.open(verifyUrl ?? form.getFieldValue("verify"), "_blank");
            // setPdfModal(url);
          },
          title: "View Violation Documents",
          className: "syncViolationBtn",
          style: {},
        },
        formItemName: "goTo",
        label: "Go To Button",
      },
      {
        customComponent: GPickerButton,
        componentProps: {
          label: "Upload",
          accessToken,
          onPickerSuccess,
          onDelete: onFileDelete,
          parentId: driveFolderId,
          fileNames,
          uploaderId: "violation",
          required: true,
          onlyTypes: [
            "image/png",
            "image/jpeg",
            "image/svg+xml",
            "application/pdf",
          ],
        },
        formItemName: "uploadButton",
        label: "Upload Button",
      },
      {
        label: "License Type",
        formItemName: "licenseType",
        placeholder: "License Type",
        disabled: true,
      },
      {
        label: "Received by",
        formItemName: "receivedBy",
        placeholder: "Select...",
        dropdownClassName: isDarkMode && "darkDropDown",
        type: "select",
        showSearch: true,
        customOptions: receivedByOptions,
      },
      {
        label: "Issuing Agency",
        formItemName: "issuingAgency",
        placeholder: "Select...",
        dropdownClassName: isDarkMode && "darkDropDown",
        type: "select",
        style: { width: 200 },
        customOptions: agencyOptions,
        showSearch: true,
      },
    ],
    Details: [
      {
        required: true,
        label: "Details",
        formItemName: "details",
        placeholder: "Details",
        type: "textArea",
        rows: 3,
        className: "detailsContainer",
      },
    ],
  };
};
