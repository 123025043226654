import { GOOGLE_API_KEY } from "../../../../helpers/constants";
import { blobToBase64 } from "../../../SidebarPages/utils";
import {
  calculateZoomLevel,
  convertRectangleToLatLng,
  convertRectangleToSquare,
} from "./utils";

export const generateCircle = (radius, center) => {
  if (!radius || !center) return [];

  const circlePoints = [];
  const numPoints = 100;

  for (let i = 0; i <= numPoints; i++) {
    const angle = (i / numPoints) * 2 * Math.PI;
    const lat = center.lat + (radius / 111000) * Math.cos(angle);
    const lng =
      center.lng +
      (radius / (111000 * Math.cos((center.lat * Math.PI) / 180))) *
        Math.sin(angle);

    circlePoints.push(`${lat},${lng}`);
  }

  return circlePoints;
};

export const calculateCenter = (path = [], editShape) => {
  const numPoints = path.length;

  if (numPoints === 0) {
    return null;
  }

  let x = 0;
  let y = 0;
  let z = 0;

  for (const point of path) {
    const latitude = (point.lat * Math.PI) / 180;
    const longitude = (point.lng * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  x /= numPoints;
  y /= numPoints;
  z /= numPoints;

  const centerLongitude = Math.atan2(y, x);
  const hypotenuse = Math.sqrt(x * x + y * y);

  let centerLatitude = Math.atan2(z, hypotenuse);
  if (editShape) {
    centerLatitude += 0.0004;
  }

  return {
    lat: (centerLatitude * 180) / Math.PI || 40.712,
    lng: (centerLongitude * 180) / Math.PI || -74.006,
  };
};

export const generatePolygonImage = async ({
  polygonCoordinates = [],
  centerFromArgs = { lat: 40.743745480166666, lng: -73.97910847613525 },
  zoomOfMap = 16,
  shouldGenerateImageOnlyWithLink = false,
}) => {
  const isArray = Array.isArray(polygonCoordinates);
  const squareCoords = !isArray && convertRectangleToSquare(polygonCoordinates);

  const proceedPolygonCoordinates = isArray
    ? polygonCoordinates
    : convertRectangleToLatLng(squareCoords);

  const formattedCoordinates = proceedPolygonCoordinates.map((coord) => [
    coord.lat,
    coord.lng,
  ]);

  const findedCircle = proceedPolygonCoordinates.find(
    (coord) => coord.hasOwnProperty("circleRadius") && coord.circleRadius
  );

  const apiUrl = "https://maps.googleapis.com/maps/api/staticmap";

  const polygonPath = `fillcolor:0xeadf9e|color:0xC600FF|weight:2.7|${formattedCoordinates
    .map((coord) => coord.join(","))
    .join("|")}|${formattedCoordinates[0]?.join(",")}`;

  const center =
    proceedPolygonCoordinates.length !== 0
      ? calculateCenter(proceedPolygonCoordinates)
      : `${centerFromArgs.lat},${centerFromArgs.lng}`;

  let circlePath = "";
  if (findedCircle) {
    const circlePoints = generateCircle(findedCircle.circleRadius, {
      lat: findedCircle.lat,
      lng: findedCircle.lng,
    });

    if (circlePoints.length > 0) {
      circlePath = `fillcolor:0x669953|color:0xff8e00|weight:3|${circlePoints.join(
        "|"
      )}`;
    }
  }

  const params = new URLSearchParams({
    size: "600x400",
    path: polygonPath,
    key: GOOGLE_API_KEY,
    zoom: calculateZoomLevel(proceedPolygonCoordinates),
    center: typeof center === "string" ? center : `${center.lat},${center.lng}`,
    scale: 2,
  });

  if (circlePath) {
    params.append("path", circlePath);
  }

  const mapImageUrl = `${apiUrl}?${params.toString()}`;

  if (shouldGenerateImageOnlyWithLink) return mapImageUrl;

  try {
    const response = await fetch(mapImageUrl, { cache: "no-store" });
    const blob = await response.blob();
    const base64Image = await blobToBase64(blob);
    return base64Image;
  } catch (error) {
    console.error("Error creating base64: ", error);
  }
};
