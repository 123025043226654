import { useState } from "react";
import { Tooltip, Popover } from "antd";

import { formatDuration } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { formatNumber } from "src/components/SidebarPages/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import ChangeActualEditor from "../ChangeActualEditor/ChangeActualEditor";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { DragHandleIcon, ConnectedWhite } from "src/assets";
import { EditIcon } from "src/icons";
import { InfoIconBlue } from "src/components/Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";
import PopoverStats from "../PopoverStats/PopoverStats";

function getDurationDescription({ stop, isEnter, alertDateTime }) {
  //#region DURATION DESCRIPTION
  if (stop) {
    return `From ${dayjsNY(stop?.beginDate).format("HH:mm")} - To ${dayjsNY(
      stop?.endDate
    ).format("HH:mm")}`;
  } else {
    return `${isEnter.includes("enter") ? "Arrive:" : "Depart:"} ${dayjsNY(
      alertDateTime
    ).format("MM/DD/YYYY HH:mm")}`;
  }
}

function StopDurationTextSection({ stop }) {
  //#region STOP DURATION TEXT
  return (
    <div className="location-arrive-time">
      <span>{getDurationDescription({ stop })}</span>
      <span>
        {`Duration: ${formatDuration(stop?.duration, "minutes")?.text}`}
      </span>
    </div>
  );
}

function AlertTimeTextSection({
  id,
  isEnter,
  isDarkMode,
  timeChanges,
  durationText,
  alertDateTime,
  createdAlerts,
  distanceInMile,
}) {
  //#region ALTER TIME TEXT
  let dayStats;
  if (timeChanges?.[id]) {
    dayStats = {
      reason: timeChanges?.[id]?.["reason"] || "No reason found",
      originalDateTime: timeChanges?.[id]?.["originalDateTime"],
      updatedAt: timeChanges?.[id]?.["updatedAt"],
      updatedBy: timeChanges?.[id]?.["updatedBy"],
    };
  } else if (createdAlerts?.[id]) {
    dayStats = {
      createdBy: createdAlerts?.[id]?.["createdBy"],
      createdAt: createdAlerts?.[id]?.["createdAt"],
      creationReason: createdAlerts?.[id]?.["creationReason"],
    };
  }

  return (
    <div className="location-arrive-time">
      <span>
        {getDurationDescription({ isEnter, alertDateTime })}{" "}
        {dayStats ? (
          <Popover
            content={<PopoverStats dayStats={dayStats} />}
            trigger={"click"}
            placement="bottom"
            overlayClassName={`stop-modal-popover-content${
              isDarkMode ? " popover-dark" : ""
            }`}
          >
            <InfoIconBlue
              height={12}
              width={12}
              style={{ cursor: "pointer" }}
            />
          </Popover>
        ) : null}
      </span>
      <div>
        {durationText ? <span>{`Duration: ${durationText}`}</span> : <></>}
        {durationText ? (
          <span>
            {`, Distance: ${formatNumber(distanceInMile, {
              unit: "mile",
            })}`}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

//#region MAIN COMPONENT
function ActualAddressCard({
  id,
  type,
  isEnter,
  dragRef,
  cardKey,
  address,
  reasons,
  stop = {},
  addReason,
  isDarkMode,
  isWritable,
  goToLocation,
  durationText,
  dotClassName,
  onChangesMade,
  alertDateTime,
  distanceInMile,
  dragHandleHide,
  onReasonSelect,
  selectedReasons,
  onLocationHover,
  timeChanges = {},
  children = <></>,
  discardButtonHide,
  classNameAddition,
  createdAlerts = {},
  draggableProps = {},
  dragHandleProps = {},
  onInfoClick = () => {},
  dragHandleRender = true,
  discardHandle = () => {},
  actualChangeConfirm = () => {},
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  let reason = undefined;
  let predictDisabled = false;

  if (type === "STOP") {
    let reasonObj = selectedReasons.find(
      ({ beginDate, endDate }) =>
        beginDate === stop?.beginDate && endDate === stop?.endDate
    );
    if (reasonObj) {
      reason = reasonObj.reason;
      predictDisabled = reasonObj.isDefault;
    }
  }

  const isManualCreated = id in createdAlerts;

  //#region JSX
  return (
    <div
      className={`single-card-location ${classNameAddition} ${
        isManualCreated ? `manual-created-card${isDarkMode ? "-dark" : ""}` : ""
      }`}
      key={cardKey}
      id={id}
      ref={dragRef}
      onMouseEnter={
        goToLocation === "CLICK"
          ? undefined
          : () => {
              onLocationHover(stop?.position || address, {
                address,
                type:
                  type === "STOP"
                    ? stop.stopType.toLowerCase().includes("idling")
                      ? "IDLE"
                      : "STOP"
                    : "FENCE",
                durationInfo: getDurationDescription({
                  stop: type === "STOP" ? stop : null,
                  isEnter,
                  alertDateTime,
                }),
              });
            }
      }
      onClick={
        goToLocation === "HOVER"
          ? undefined
          : () => {
              onLocationHover(stop?.position || address, {
                address,
                type:
                  type === "STOP"
                    ? stop.stopType.toLowerCase().includes("idling")
                      ? "IDLE"
                      : "STOP"
                    : "FENCE",
                durationInfo: getDurationDescription({
                  stop: type === "STOP" ? stop : null,
                  isEnter,
                  alertDateTime,
                }),
              });
            }
      }
      {...draggableProps}
    >
      <div className="single-card-location-info">
        {/** 
          //#region DURATION TEXT
        */}
        <div className={`location-dot ${dotClassName}`} />
        <div className="single-location-info">
          {type === "STOP" ? (
            <StopDurationTextSection stop={stop} />
          ) : (
            <AlertTimeTextSection
              {...{
                id,
                isEnter,
                isDarkMode,
                timeChanges,
                durationText,
                createdAlerts,
                alertDateTime,
                distanceInMile,
              }}
            />
          )}
          <span className="location-arrive">{address}</span>
          {type === "STOP" && (isWritable || predictDisabled) && (
            <div className="reason-input">
              {/** 
                //#region REASON INPUT
              */}
              <InputComponent
                {...{
                  label: "",
                  type: "predictAutocomplete",
                  required: false,
                  noFormItem: true,
                  allowClear: true,
                  style: { width: "200px" },
                  value: predictDisabled ? reason : undefined,
                  defaultValue: reason,
                  disabled: predictDisabled,
                  onSelect(e) {
                    onReasonSelect({
                      reason: e,
                      beginDate: stop?.beginDate,
                      endDate: stop?.endDate,
                    });
                    onChangesMade();
                  },
                  addNewClick(e) {
                    addReason(e);
                  },
                  onClear() {
                    onReasonSelect({
                      reason: "",
                      beginDate: stop?.beginDate,
                      endDate: stop?.endDate,
                    });
                  },
                  customOptions: reasons.map((e) => ({
                    label: e,
                    value: e,
                  })),
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="location-info-control-buttons"
        style={
          discardButtonHide
            ? {
                visibility: "hidden",
                width: 0,
                overflow: "hidden",
              }
            : {}
        }
      >
        {type === "STOP" ? (
          <Tooltip title="Link stop">
            {/** 
              //#region LINK STOP
            */}
            <div
              className="location-handler stop-link-handler"
              onClick={onInfoClick}
              style={
                !isWritable
                  ? {
                      visibility: "hidden",
                    }
                  : {}
              }
            >
              <ConnectedWhite height={14} width={14} />
            </div>
          </Tooltip>
        ) : !isManualCreated ? (
          <Tooltip title="Regulate times">
            {/** 
              //#region CHANGE TIMES
            */}
            <Popover
              placement="bottom"
              content={
                <ChangeActualEditor
                  alertDateTime={alertDateTime}
                  onConfirm={(param) => {
                    actualChangeConfirm({
                      ...param,
                      alertUUID: id,
                      originalDateTime: alertDateTime,
                    });
                    setPopoverOpen(false);
                  }}
                />
              }
              open={popoverOpen}
              onOpenChange={(changed) => {
                setPopoverOpen(changed.valueOf());
              }}
              destroyTooltipOnHide
              trigger={"click"}
              overlayClassName={`stop-modal-popover-content${
                isDarkMode ? " popover-dark" : ""
              }`}
            >
              <div
                className="location-handler stop-link-handler"
                style={
                  !isWritable
                    ? {
                        visibility: "hidden",
                      }
                    : {
                        transform: dragHandleHide
                          ? "translateX(29px)"
                          : undefined,
                      }
                }
              >
                <EditIcon height={14} width={14} fill="#fff" />
              </div>
            </Popover>
          </Tooltip>
        ) : null}
        {dragHandleRender && (
          <div
            className="location-drag-handle"
            style={{
              ...(dragHandleHide ? { visibility: "hidden" } : {}),
            }}
            {...dragHandleProps}
          >
            {/** 
              //#region DRAG-DROP HANDLE
            */}
            <DragHandleIcon
              height={18}
              width={18}
              fill={isDarkMode ? "#fff" : "#000"}
            />
          </div>
        )}
        <Tooltip title={`Discard ${type?.toLowerCase()}`}>
          {/** 
            //#region DISCARD BUTTON
          */}
          <div
            className="location-handler location-discard-handle"
            style={
              !isWritable
                ? {
                    visibility: "hidden",
                  }
                : {}
            }
            onClick={() => {
              discardHandle();
            }}
          >
            <XIcon height={14} width={14} fill="#fff" />
          </div>
        </Tooltip>
      </div>
      {children}
    </div>
  );
}

export default ActualAddressCard;
