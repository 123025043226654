import { useState, useEffect, useContext, Fragment } from "react";
import { useSelector } from "react-redux";
import { Form, Tooltip, message } from "antd";

import { FleetsLiveContext, loadLivePreference } from "../../../../utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { ReportsPdfExport } from "./components";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { MondayButton } from "src/components/commonComponents";
import { ExcelIconWhite, PdfWhiteIcon } from "src/assets";
import { FilterIcon } from "src/components/SidebarPages/BasePage/src";

import "./ReportViewController.scss";

function ReportViewController({ collectReportsData, vehicleSelectHandler }) {
  const { locations, analytics } = useContext(FleetsLiveContext);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [pdfExportOpen, setPdfExportOpen] = useState(false);
  const [excelExportOpen, setExcelExportOpen] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    let reportController = document.getElementById("reports-view-controller");

    if (reportController) {
      let sideState = loadLivePreference("liveSidebar");
      if (sideState) {
        reportController.style.width = "calc(100dvw - 65px)";
      } else {
        reportController.style.width = "calc(100dvw - 200px)";
      }
    }
  }, []);

  async function getAnalyticsHandler() {
    await collectReportsData({
      dateRange: form.getFieldValue("dateRange"),
      selectedVehicle: form.getFieldValue("selectedVehicle"),
    }).then(() => {
      setTimeout(() => {
        form.setFieldsValue({
          dateRange: loadLivePreference("liveReportsTimeline").map((e) =>
            dayjsNY(e)
          ),
          selectedVehicle: loadLivePreference("liveReportsVehicleFilter"),
        });
      }, 0);
    });
  }

  function openExport(type) {
    if (!Object.keys(analytics).length) {
      message.warning({
        content: "You need to get the analytics data before exporting",
        key: "denyExport",
      });
      return;
    }

    if (type === "PDF") {
      setPdfExportOpen(true);
    } else if (type === "EXCEL") {
      setExcelExportOpen(true);
    }
  }

  return (
    <Fragment>
      <Form
        form={form}
        initialValues={{
          dateRange: loadLivePreference("liveReportsTimeline")
            ? loadLivePreference("liveReportsTimeline")?.map((e) => {
                return dayjsNY(e);
              })
            : null,
          selectedVehicle:
            loadLivePreference("liveReportsVehicleFilter") || null,
        }}
        className={`report-view-controller${
          isDarkMode ? " report-view-controller-dark" : ""
        }`}
        id="reports-view-controller"
      >
        <div className="controller-section-left">
          <InputComponent
            {...{
              label: "",
              disabledDate(val) {
                return dayjsNY(val)?.valueOf() > dayjsNY().endOf("D").valueOf();
              },
              type: "rangePicker",
              showTime: false,
              format: "MM/DD/YYYY",
              formItemName: "dateRange",
              dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
            }}
          />
          <InputComponent
            {...{
              label: "",
              type: "select",
              formItemName: "selectedVehicle",
              placeholder: "Select a Vehicle...",
              dropdownClassName: isDarkMode ? "darkDropDown" : undefined,
              onSelect: vehicleSelectHandler,
              onClear() {
                vehicleSelectHandler("");
              },
              showSearch: true,
              customOptions: locations?.map(
                ({ fleetName, deviceSerialNumber }) => ({
                  label: fleetName,
                  value: deviceSerialNumber,
                })
              ),
            }}
          />
        </div>
        <div className="controller-section-right">
          <Tooltip title="Export to Excel" placement="bottom">
            <MondayButton
              Icon={<ExcelIconWhite />}
              className="mondayButtonBlue"
              onClick={() => {
                openExport("EXCEL");
              }}
            >
              {""}
            </MondayButton>
          </Tooltip>
          <Tooltip title="Export to PDF" placement="bottom">
            <MondayButton
              Icon={<PdfWhiteIcon />}
              className="mondayButtonBlue"
              onClick={() => {
                openExport("PDF");
              }}
            >
              {""}
            </MondayButton>
          </Tooltip>
          <MondayButton
            Icon={<FilterIcon />}
            className="mondayButtonBlue"
            onClick={getAnalyticsHandler}
          >
            Get Analytics
          </MondayButton>
        </div>
      </Form>
      {(pdfExportOpen || excelExportOpen) && (
        <ReportsPdfExport
          open={pdfExportOpen || excelExportOpen}
          onCancel={() => {
            if (excelExportOpen) {
              setExcelExportOpen(false);
            } else {
              setPdfExportOpen(false);
            }
          }}
          onlyAllowTable={excelExportOpen}
        />
      )}
    </Fragment>
  );
}

export default ReportViewController;
