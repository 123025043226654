import { Modal, Select } from "antd";
// import { formatCategoryName } from "../../../../pages/Settings/settingsComponents/DocumentationConfiguration/View/utils";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { MondayButton } from "../../../../commonComponents";
import { useMemo, useState } from "react";
import { InputComponent } from "../../../Fleet/components";
import { InfoCircleFilled } from "@ant-design/icons";
import "./NewCategoryPanel.scss";
import { XIcon } from "../../../Communication/assets";

const optionMapping = (opt = "", format = false) => {
  let catName = format ? opt?.replace(" ", "-") : opt;
  return (
    <Select.Option key={opt} value={catName}>
      {catName}
    </Select.Option>
  );
};

const NewCategoryPanel = ({
  form,
  allCategories = [],
  reportsCategorization = {},
  onCreateConfiguration,
  isDarkMode,
  newCategoryPanel,
  setNewCategoryPanel,
}) => {
  const [selectedDepartment, setSelectedDepartment] = useState();

  const categoriesOptions = useMemo(() => {
    return Object.keys(reportsCategorization).map(optionMapping);
  }, [reportsCategorization]);

  const configurationOptions = useMemo(() => {
    if (!selectedDepartment) return [];
    return (reportsCategorization[selectedDepartment] || [])
      .filter(
        (category) =>
          //returns categories that are not created yet
          !allCategories
            ?.filter((c) => c?.department === selectedDepartment)
            ?.map(({ categoryName }) => categoryName)
            ?.includes(category?.statusName)
      )
      .map((category) => optionMapping(category.statusName, true));
  }, [selectedDepartment, reportsCategorization]);

  const onCancel = () => {
    form.resetFields();
    setNewCategoryPanel(false);
  };

  return (
    <>
      <Modal
        {...{
          className: `createCategoryPanel ${
            isDarkMode && "createCategoryPanelDark"
          }`,
          open: newCategoryPanel,
          title: "Create a New Category",
          closable: true,
          centered: true,
          destroyOnClose: true,
          closeIcon: <XIcon />,
          onCancel: () => {
            onCancel();
            setNewCategoryPanel(false);
          },
          footer: (
            <div className="createCategoryPanel-footer">
              <MondayButton
                className="mondayButtonRed"
                Icon={<XIcon />}
                onClick={() => {
                  onCancel();
                  setNewCategoryPanel(false);
                }}
              >
                Cancel
              </MondayButton>
              <MondayButton
                Icon={<TickIcon />}
                onClick={() => {
                  onCreateConfiguration();
                }}
              >
                Create
              </MondayButton>
            </div>
          ),
        }}
      >
        <div className="createCategoryPanel-container">
          <div className="createCategoryPanel-info">
            <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
            <p className="createCategoryPanel-text">
              Please select the configuration and enter the title of this new
              category below.
            </p>
          </div>
          <div className="categoryCreatorSection">
            <InputComponent
              required
              showSearch
              {...{
                label: "Category",
                formItemName: "department",
                placeholder: "Choose one...",
                tooltipKey: "category",
                type: "select",
                style: { minWidth: 250 },
                options: categoriesOptions,
                onSelect: (val) => setSelectedDepartment(val),
                className: "categoryReport-select",
                dropdownClassName: isDarkMode && "darkDropDown",
              }}
            />

            <InputComponent
              required
              showSearch
              {...{
                label: "Configuration Name",
                formItemName: "categoryName",
                tooltipKey: selectedDepartment ? "configName" : "noCatSelected",
                placeholder: "Choose one...",
                type: "select",
                style: { minWidth: 300 },
                disabled: !selectedDepartment,
                options: configurationOptions,
                className: "categoryReport-select",
                dropdownClassName: isDarkMode && "darkDropDown",
              }}
            />
          </div>
        </div>
      </Modal>

      {/* <div className="headerSearch" style={{ backgroundColor: "#f5f5f5" }}>
        <Input
          className="searchInput"
          placeholder="Search Reports"
          tooltipKey="search"
          allowClear
          prefix={<SearchIcon width={15} height={15} />}
          onChange={onSearchReport}
          style={{ width: 300, backgroundColor: "#f5f5f5" }}
        />
      </div> */}

      {/* <MondayButton
        {...{
          id: "newServiceButton",
          Icon: <Tick width={20} height={20} />,
          onClick: onCreateConfiguration,
          tooltipKey: "newConfig",
        }}
      >
        Create Configuration
      </MondayButton> */}
      {/* <MondayButton
        {...{
          id: "clearBtn",
          Icon: <Close width={17} height={17} />,
          className: "clearBtn",
          onClick: () => form.resetFields(),
          tooltipKey: "clear",
        }}
      >
        Clear
      </MondayButton> */}
    </>
  );
};

export default NewCategoryPanel;
