import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { SaveIcon } from "../../../../../SidebarPages/BasePage/src";
import { useChartCardsContext } from "../../../ChartCardsContext";
import MondayButton from "../../../../MondayButton/MondayButton";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { updateChartPreferences, updateProgramFields } from "../../../utils";

const SaveCardChanges = () => {
  const dispatch = useDispatch();

  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );
  const { programFields } = useSelector((state) => state.programFields);

  const {
    chartCategory,
    generalFilters,
    setGeneralFilters,
    hiddenCardIds,
    setHiddenCardIds,
    categoryCards,
    newChanges,
    setNewChanges,
    setUpdatedCards,
  } = useChartCardsContext();

  const { preferences } = allPreferences;

  const overviewsConfig = programFields?.find(
    ({ fieldName }) => fieldName === "Overviews Configuration"
  );

  const saveChangesHandler = async () => {
    message.loading("Saving changes please wait...");

    const privateCategoryCards = categoryCards.filter(
      ({ isPrivate }) => isPrivate
    );
    const publicCategoryCards = categoryCards.filter(
      ({ isPrivate }) => !isPrivate
    );

    console.log({ privateCategoryCards, publicCategoryCards });

    if (publicCategoryCards.length) {
      const updatedFieldOptions = {
        ...overviewsConfig?.fieldOptions,
        overviewCards: {
          ...(overviewsConfig?.fieldOptions?.overviewCards || {}),
          [chartCategory]: publicCategoryCards,
        },
      };
      updateProgramFields(
        overviewsConfig?.fieldId,
        updatedFieldOptions,
        dispatch
      );
    }

    const updatedPreferences = {
      ...preferences,
      OverviewCards: {
        ...(preferences?.OverviewCards || {}),
        cardFilters: {
          ...(preferences?.OverviewCards?.[chartCategory] || {}),
          [chartCategory]: generalFilters,
        },
        hiddenCards: {
          ...(preferences?.OverviewCards?.hiddenCards?.[chartCategory] || {}),
          [chartCategory]: hiddenCardIds,
        },
        ...(privateCategoryCards.length && {
          onlyMyCards: {
            ...(preferences?.OverviewCards?.onlyMyCards || {}),
            [chartCategory]: privateCategoryCards,
          },
        }),
      },
    };

    updateChartPreferences(updatedPreferences, allPreferences, dispatch).then(
      () => {
        message.success("Changes saved successfully!");
        setNewChanges(false);
      }
    );
  };

  return newChanges ? (
    <div className="chart-cards-save-changes">
      <MondayButton
        tooltipCategory="CHART CARDS"
        tooltipKey="saveChanges"
        Icon={<SaveIcon />}
        onClick={saveChangesHandler}
      />
      <MondayButton
        className="mondayButtonRed"
        tooltipCategory="CHART CARDS"
        tooltipKey="discardChanges"
        Icon={<XIcon />}
        onClick={() => {
          setNewChanges(false);
          setUpdatedCards();
          setGeneralFilters(
            preferences?.OverviewCards?.cardFilters?.[chartCategory] || {}
          );
          setHiddenCardIds(
            preferences?.OverviewCards?.hiddenCards?.[chartCategory] || []
          );
        }}
      />
    </div>
  ) : (
    <></>
  );
};

export default SaveCardChanges;
