import { MondayButton } from "../../../commonComponents";
import { ProjectIcon } from "../../../SidebarPages/Scheduling/GanttChart/assets/OtherIcons";
import EstimationStep from "./EstimationStep";
import ProjectsInfo from "./infoViews/ProjectsInfo/ProjectsInfo";
import TakeOffStep from "./TakeOffStep";

function stepsConfig({
  stepperData,
  onSelectDataSrc,
  onProgressControl,
  typeSource,
  onSelectEstimation,
  createProposalTakeOff,
}) {
  return [
    {
      //region Step 1
      title: stepperData?.sourceType || "Choose Source",
      content: (
        <div className="choose_source_wrapper">
          <div className="left-button">
            <MondayButton
              Icon={<ProjectIcon />}
              onClick={() => onProgressControl("sourceType", "Project")}
            >
              PROJECT
            </MondayButton>
          </div>
          <div className="right-button">
            <MondayButton
              className="mondayButtonYellow right-button"
              // disabled
              Icon={<ProjectIcon />}
              onClick={() => onProgressControl("sourceType", "Opportunity")}
            >
              OPPORTUNITY
            </MondayButton>
          </div>
        </div>
      ),
    },
    {
      //region Step 2
      title: stepperData?.selectLabel || "Select Source",
      content: (
        <div>
          <ProjectsInfo
            {...{
              onSelectDataSrc,
              typeView: typeSource,
            }}
          />
        </div>
      ),
    },
    {
      //region Step 3
      title: !!stepperData?.selectedEstimation
        ? "Pick Take Off"
        : "Pick Estimation",
      content: (
        <EstimationStep
          {...{
            stepperData,
            onSelectEstimation,
          }}
        />
      ),
    },
    {
      //region Step 4
      title: "Pick Take Off",
      content: (
        <TakeOffStep
          {...{
            stepperData,
            createProposalTakeOff,
          }}
        />
      ),
    },
  ];
}

export default stepsConfig;
