import { useEffect, useMemo, useState } from "react";
import { Button, Divider, Input, Modal, Tooltip, message } from "antd";
import _ from "lodash";
import "./JumpsConfigModal.scss";
import { MondayButton } from "../../../../../../../commonComponents";
import {
  Close,
  EditIcon,
} from "../../../../../../../SidebarPages/DynamicView/src";
import { Tick } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { PlusIcon } from "../../../../../../../../assets";
import {
  columnDefsForHoistJumps,
  swalToast,
} from "../../../../SchedulingFirstPage/helperData";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { InfoIconBlue } from "../../../../Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import SelfCheck from "../../../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/HoistForm/subcomponents/SelfCheck/SelfCheck";
import { forceToNumber } from "../../../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const JumpsConfigModal2 = ({
  elevation,
  serviceId,
  elevationIndex,
  setHoistPLis,
  typeOfWork,
  isFrom = "scheduling",
  customDarkMode = false,
  customTheme = "dark",
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [visible, set_visible] = useState(false);
  const [jumps, set_jumps] = useState();
  const [newJumpCreated, setNewJumpCreated] = useState({
    floorsSelected: [],
    name: "Initial Phase Jump",
  });

  const onCancel = () => {
    set_jumps(elevation?.jumps);
    set_visible(false);
    updateDocumentTitle(); // reset document title to "Lead Manager"
  };

  const onOk = () => {
    let modifiedElevation = _.cloneDeep(elevation);
    modifiedElevation.jumps = jumps;
    setHoistPLis(modifiedElevation, { serviceId, elevationIndex });
    set_visible(false);
    message.success("Jumps configured successfully!");
    updateDocumentTitle(); // reset document title to "Lead Manager"
  };

  const onChangeJumpCreatedName = (event) => {
    const checkIfJumpsAlreadyExist = jumps?.find(
      (el) => el?.name === event?.target?.value
    );
    if (checkIfJumpsAlreadyExist) {
      message.warning(`${event?.target?.value} already exists!`);
      return;
    }
    setNewJumpCreated({
      ...newJumpCreated,
      name: event?.target?.value,
    });
  };

  const floorClicked = (floor) => {
    const checkIfFloorAlreadySelected = newJumpCreated?.floorsSelected?.find(
      (el) => el === floor
    );
    if (checkIfFloorAlreadySelected) {
      setNewJumpCreated({
        ...newJumpCreated,
        floorsSelected: newJumpCreated?.floorsSelected?.filter(
          (el) => el !== floor
        ),
      });
      return;
    }
    setNewJumpCreated({
      ...newJumpCreated,
      floorsSelected: [...(newJumpCreated?.floorsSelected || []), floor],
    });
  };

  const onAddJump = () => {
    const jumps_copy = _.cloneDeep(jumps);
    if (!newJumpCreated?.name) {
      message.warning(`Please enter a name!`);
      return;
    }
    if (!newJumpCreated?.floorsSelected?.length) {
      message.warning(`Please select floors for the jump you want to create!`);
      return;
    }
    const allJumps = [
      ...jumps_copy,
      {
        name: newJumpCreated?.name || "",
        floors:
          newJumpCreated?.floorsSelected?.map((floor) => ({
            floor,
          })) || [],
        progressByDay: [],
      },
    ];
    set_jumps(allJumps);
    setNewJumpCreated({
      floorsSelected: [],
      name: `Jump ${allJumps?.length}`,
    });
  };

  const onRemoveJump = (jump = {}) => {
    const jumps_copy = _.cloneDeep(jumps);
    const newJumps = jumps_copy?.filter((el) => el?.name !== jump?.name);
    set_jumps(newJumps);
    message.success(`${jump?.name} removed successfully!`);
  };

  const onUIShow = () => {
    set_visible(true);
    set_jumps(elevation?.jumps || []);
  };

  const gridHeight = useMemo(() => {
    if ((jumps?.length || []).length < 3) {
      return 300;
    }
    if (jumps?.length > 10) {
      return 10 * 42 + 150;
    }
    return jumps?.length * 45 + 200;
  }, [jumps]);

  return (
    <>
      <div
        style={{
          padding: "0.5px 0px 0px 10px",
          display: "flex",
        }}
      >
        <MondayButton
          onClick={onUIShow}
          {...{
            className: "mondayButtonBlue",
            Icon: <EditIcon />,
          }}
        >
          Configure Jumps
        </MondayButton>
        {isFrom === "scheduling" && (
          <SelfCheck
            {...{
              elevation: {
                ...elevation,
                items: elevation?.items?.map((floor) => ({
                  ...floor,
                  addons: floor?.originalPli?.addons || [],
                  stop: floor?.originalPli?.stop || false,
                  dual: floor?.originalPli?.dual || false,
                })),
              },
              darkMode: isDarkMode,
              title: "Floor Addons",
            }}
          />
        )}
      </div>

      {visible && (
        <Modal
          title="Configure Floors on Jumps"
          afterOpenChange={(event) => {
            event &&
              updateDocumentTitle({ newTitle: "Configure Floors on Jumps" });
          }}
          open={visible}
          centered
          onCancel={onCancel}
          closeIcon={<XIcon />}
          destroyOnClose={true}
          className={`jumps-config-modal ${
            isDarkMode && "jumps-config-modal-dark"
          }`}
          width={993}
          footer={
            <div className="footer-content-jump-modal">
              <div className="flex-center-jump-icons"></div>
              <div style={{ display: "flex", gap: "1.5rem" }}>
                <MondayButton
                  onClick={onCancel}
                  {...{ Icon: <Close />, className: "mondayButtonRed" }}
                >
                  Cancel Changes
                </MondayButton>

                <MondayButton
                  // disabled={!editMode}
                  onClick={onOk}
                  {...{ Icon: <Tick /> }}
                >
                  Save Changes
                </MondayButton>
              </div>
            </div>
          }
        >
          <div>
            <div className="hoistConfigContent">
              <div className="floorsContent">
                <div className="infoIconFloor">
                  <span>{<InfoIconBlue />}</span>
                  <span>Available and taken floors!</span>
                </div>
                <div className="content-div-floors">
                  {elevation?.items?.map((floor) => {
                    const isFloorDisabledToSelect = jumps?.find((jump) =>
                      jump?.floors?.find((fl) => fl?.floor === floor?.floor)
                    );
                    const backgroundColor =
                      newJumpCreated?.floorsSelected?.find(
                        (el) => el === floor?.floor
                      )
                        ? "#D5F1C9"
                        : "#F5F5F7";
                    return (
                      <Tooltip
                        title={
                          isFloorDisabledToSelect
                            ? `Floor ${floor?.floor} is taken in ${isFloorDisabledToSelect?.name}!`
                            : `Floor ${floor?.floor}`
                        }
                      >
                        <Button
                          style={{ backgroundColor }}
                          onClick={() => floorClicked(floor?.floor)}
                          disabled={isFloorDisabledToSelect}
                          className="scheduleFloors-buttons"
                        >
                          {floor?.floor}
                        </Button>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
              <div className="floorsContent">
                <div className="infoIconFloor">
                  <span>{<InfoIconBlue />}</span>
                  <span>
                    Below you can name the next jump you want to create!
                  </span>
                </div>
                <div className="inputBtnDiv">
                  <Input
                    placeholder="Jump name..."
                    value={newJumpCreated?.name}
                    onChange={onChangeJumpCreatedName}
                    style={{
                      width: 250,
                      height: 32,
                      borderRadius: 5,
                      border: "none",
                      background: "#F5F5F7",
                    }}
                  />
                  <MondayButton
                    onClick={onAddJump}
                    {...{
                      Icon: <PlusIcon />,
                    }}
                  >
                    Add Jump
                  </MondayButton>
                </div>
              </div>
            </div>
            {jumps?.length > 0 && (
              <div
                className={`documentationsTable ${
                  isDarkMode
                    ? "dark-ag-theme ag-theme-alpine-dark"
                    : "light-ag-theme ag-theme-alpine"
                }`}
                style={{ width: "100%", height: gridHeight, marginTop: 22 }}
              >
                <AgGridReact
                  {...{
                    columnDefs: columnDefsForHoistJumps({
                      jumps,
                      onRemoveJump,
                      set_jumps,
                      availableFloors: elevation?.items?.filter((floor) => {
                        const isFloorDisabledToSelect = jumps?.find((jump) =>
                          jump?.floors?.find((fl) => fl?.floor === floor?.floor)
                        );
                        return !isFloorDisabledToSelect;
                      }),
                      typeOfWork,
                      isDarkMode,
                      elevation,
                    }),
                    rowData: jumps || [],
                    animateRows: true,
                    suppressDragLeaveHidesColumns: true,
                    suppressRowClickSelection: true,
                    rowSelection: "multiple",
                    defaultColDef: {
                      resizable: true,
                      enablePivot: true,
                      enableColResize: true,
                      enableRowGroup: true,
                      editable: false,
                      sortable: true,
                      flex: 1,
                      minWidth: 100,
                      filter: true,
                    },
                  }}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
