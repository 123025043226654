import { Button, Checkbox, Table } from "antd";

import Text from "src/components/commonComponents/Typography/Text";

import { editIcon } from "../../assets";
import { extractContent } from "../../../Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rtfEditor";

export default function PliTable({
  rowData,
  plisChecked,
  displayProgress,
  progressLabel,
  onShowModal,
  onElevationItemChange,
}) {
  const columns = [
    {
      title: <img src={editIcon} alt="pencil" />,
      dataIndex: "formItemName",
      key: "formItemName",
      render: (text, _record) => {
        return (
          <Checkbox
            data-testid={`${text}-checkbox-data-testid`}
            checked={
              plisChecked?.items?.find((pli) => pli.id === _record.id)
                ?.isChecked
            }
            disabled={
              displayProgress &&
              plisChecked?.items?.find((pli) => pli.id === _record.id)
                ?.totalProgress < 100
            }
            onChange={(e) => onElevationItemChange(e, text, _record?.id)}
          />
        );
      },
    },
    { title: "", dataIndex: "id", key: "id" },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
      render: (text, _record) => {
        const { originalPli = {} } = _record;

        return !!text ? text : originalPli?.height ? originalPli?.height : "";
      },
    },
    { title: "Length", dataIndex: "length", key: "length" },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, _record) => {
        const { originalPli = {} } = _record;
        const { description = {} } = originalPli;

        return (
          <Button
            type="primary"
            onClick={() =>
              onShowModal(
                !!text
                  ? extractContent(text?.note)
                  : !!description?.note
                  ? extractContent(description?.note)
                  : "This project line item has no description!"
              )
            }
          >
            Show
          </Button>
        );
      },
    },
    ...(displayProgress
      ? [
          {
            title: "",
            dataIndex: "totalProgress",
            key: "totalProgress",
            render: (text, _record) =>
              displayProgress && (
                <Text>{`${progressLabel}: ${
                  plisChecked?.items?.find((pli) => pli.id === _record.id)
                    ?.totalProgress ?? 0
                }%`}</Text>
              ),
          },
        ]
      : []),
  ];

  return (
    <Table
      rowKey="id"
      pagination={false}
      columns={columns}
      dataSource={rowData}
      rowClassName={(_record, index) => (index % 2 === 0 ? "" : "coloredRow")}
    />
  );
}
