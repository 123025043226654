import { RedWarningModal } from "../../../../../commonComponents";
import { DeleteIcon } from "../../../../DynamicView/src";

const EndInspectionModal = ({ visible, setVisible, onEnd }) => {
  return (
    <RedWarningModal
      {...{
        visible: visible,
        onCancel: () => setVisible(false),
        titleText: "Confirm Deletion",
        TitleIcon: DeleteIcon,
        footerProps: {
          onConfirm: () => onEnd(),
        },
        deleteModal: true,
      }}
    >
      <div className="deleteCrew-text">
        <p className="deleteCrew-name">
          Are you sure you want to terminate and lose this inspection?
        </p>
        <p>You can't undo this action.</p>
      </div>
    </RedWarningModal>
  );
};

export default EndInspectionModal;
