import { EstimationsWhite, PermitDrawingsWhite } from "../../../../../../icons";
import { MondayButton } from "../../../../../commonComponents";
import { Tick } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import {
  ActionsIcon,
  Close,
  CopyIcon,
} from "../../../../../SidebarPages/DynamicView/src";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "./RequestCard.scss";
import { Tag, Popover, message, Alert } from "antd";
import { openInNewTab } from "../../../../../../utils";
import { EmailIcon } from "../../assets";
import { TrashIcon } from "../../../../../SidebarPages/Communication/assets";
import { memo, useMemo } from "react";
import { REQUEST_FORM_TYPES } from "../../../../forms/RequestFormModal/helpers";
import { approveText, declineText, requestAction } from "../../approvalsData";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { useSelector } from "react-redux";

dayjs.extend(relativeTime);

const RequestCard = ({
  formRequest = {},
  onAddressClick,
  onApprove,
  onDecline,
  onView,
  onInstantDecline,
  createdId,
}) => {
  const {
    requestType,
    userRequesting,
    createdAt,
    requestObject = {},
    category,
    recordId,
    requestId,
    enableOnCustomerPortal = false,
  } = formRequest;

  // console.log("FORM REQUEST", formRequest);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { customerUsers } = useSelector((state) => state.customerUsers);

  const allReducedUsers = useMemo(() => {
    return [...userConfiguration?.allUsers?.Items, ...customerUsers]?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.cognitoUserId]: curr?.nameOfUser,
      })
    );
  }, [userConfiguration]);

  const { emailReference, status } = requestObject;
  const RecordIcon =
    requestType === REQUEST_FORM_TYPES.ESTIMATION
      ? EstimationsWhite
      : PermitDrawingsWhite;

  const onEmailReferenceClick = () => {
    openInNewTab(emailReference);
  };

  return (
    <div className="formRequestCard">
      <div className="requestContent">
        {enableOnCustomerPortal && <Alert message="Customer Portal Request" />}
        <div className="requestCardHeader">
          <div
            className="copyIcon"
            onClick={() => {
              navigator.clipboard.writeText(
                `${location.origin}/approvals/${requestId}`
              );
              message.info("Link copied to clipboard");
            }}
          >
            <CopyIcon />
          </div>
          <div className="recordIcon">
            <RecordIcon />
          </div>
          <Popover
            trigger={["click", "hover"]}
            placement="right"
            overlayClassName="approvalRequestMenuOption"
            content={
              <span
                className="requestMenuOption"
                onClick={() => {
                  onView(formRequest);
                }}
              >
                View Request
              </span>
            }
          >
            <div className="actionIcon">
              <ActionsIcon />
            </div>
          </Popover>
        </div>

        <div className="requestCardBody">
          <div className="requestInfo">
            {`${allReducedUsers?.[userRequesting]} has ${
              requestAction[status] ? requestAction[status] : ""
            }`}
            <Tag color="green" className="requestType">
              {`${requestType} Request`}
            </Tag>
            for{" "}
            <span
              className="jobsiteAddress"
              onClick={() => {
                onAddressClick(category, recordId);
              }}
            >
              {requestType === REQUEST_FORM_TYPES.DOCUMENTATION
                ? `${requestObject?.documentationCategory?.slice(0, -1)} - ${
                    requestObject?.recordName
                  }`
                : requestObject?.recordName}
            </span>
          </div>
          {!!formRequest?.requestObject?.cargoStatus && (
            <div className="cargoLine">
              <strong>Cargo:&nbsp;</strong>
              <span>{formRequest?.requestObject?.cargoStatus}</span>
              <strong style={{ marginLeft: "1rem" }}>Date:&nbsp;</strong>
              <span>
                {dayjsNY(formRequest?.requestObject?.dateOnSite).format(
                  "M/D/YY"
                )}
              </span>
            </div>
          )}
          <div className="requestMetaData">
            <div className="requestDate">{dayjs(createdAt).fromNow()}</div>
            {!!emailReference ? (
              <div className="requestReference" onClick={onEmailReferenceClick}>
                <EmailIcon className="emailIcon" /> <div>Email Reference</div>
              </div>
            ) : (
              <div className="requestDate">No Email Reference</div>
            )}
          </div>
        </div>
      </div>
      <div className="btnContainer">
        {(status === "Draft" || status === "Reviewed") && (
          <MondayButton
            className="declineBtn"
            Icon={<TrashIcon />}
            onClick={() => {
              onInstantDecline(formRequest);
            }}
          >
            Cancel
          </MondayButton>
        )}
        <MondayButton
          className="declineBtn"
          Icon={<Close />}
          onClick={() => onDecline(formRequest)}
        >
          {declineText[status]}
        </MondayButton>
        <MondayButton
          className="approveBtn"
          Icon={<Tick />}
          onClick={() => onApprove(formRequest)}
          disabled={createdId === requestId}
        >
          {approveText[status]}
        </MondayButton>
      </div>
    </div>
  );
};

export default memo(RequestCard);
