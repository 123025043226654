import { MondayButton } from "../../../../../../commonComponents";
import { EditIcon } from "../../../../../DynamicView/src";
import { TrashIcon } from "../../../../../Communication/assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

const excludesColumns = [
  {
    headerName: "Date",
    field: "excludeDate",
    cellRenderer({ value }) {
      if (!value) {
        return "";
      }

      return dayjsNY(value).format("MM/DD/YYYY");
    },
  },
  {
    headerName: "Driver",
    field: "driverName",
  },
  {
    headerName: "Comments",
    field: "excludeComments",
  },
  {
    headerName: "Actions",
    cellRenderer({ data, context }) {
      return (
        <div
          style={{
            display: "grid",
            placeItems: "center",
            gap: "10px",
            gridTemplateColumns: "auto auto",
          }}
        >
          <MondayButton
            Icon={<EditIcon />}
            className="mondayButtonBlue"
            onClick={() => {
              if (context?.onEdit && typeof context?.onEdit === "function") {
                context.onEdit(data);
              }
            }}
          >
            {null}
          </MondayButton>
          <MondayButton
            Icon={<TrashIcon />}
            className="mondayButtonRed"
            onClick={() => {
              if (
                context?.onDelete &&
                typeof context?.onDelete === "function"
              ) {
                context.onDelete(data);
              }
            }}
          >
            {null}
          </MondayButton>
        </div>
      );
    },
  },
];

export default excludesColumns;
