import { useSelector } from "react-redux";
import { Form, message, Popconfirm } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useContext, useMemo, useState } from "react";

import { useResponsive } from "src/hooks";
import EntryModal from "../modals/EntryModal";
import { EntryType } from "../payrollLiveTypes";
import { GroupedReportsCard } from "../components";
import PayrollLiveContext from "../PayrollLiveContext";
import { HoverButton } from "src/components/commonComponents";
import {
  StoreType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import LateClockIn from "../components/LateClockIn/LateClockIn";
import { useEntriesApi, payrollReportGenerator } from "../utils";
import { RightArrow } from "src/components/SidebarPages/BasePage/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import UnMatchedEntries from "../components/UnMatchedEntries/UnMatchedEntries";
import { RenderDynamicComponents } from "src/components/Header/forms/Components";
import UnregisteredEmployees from "../components/UnregisteredEmployees/UnregisteredEmployees";
import Report from "src/components/SidebarPages/DynamicView/Pages/Overview/components/Report/Report.jsx";
import { CrewsHeader } from "src/components/pages/Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";
import GetPayrollReportModal from "../../Payroll/Tabs/DEG/components/modalComponents/GetPayrollReportModal/GetPayrollReportModal";

import "./EmployeeReports.scss";

type NewEmpModalType = {
  employeeId?: string;
  accountName?: string;
  employeeNumber?: string;
  employeeFullName?: string;
};

type ReportVisibleType = { liveData: Array<any>; dataType: string };

const filterFields = [
  {
    type: "input",
    placeholder: "Search...",
    prefix: <SearchOutlined />,
    dataTestid: "employeeName",
    formItemName: "searchField",
  },
];

const reports = {
  NoPunch: "Live Punch",
  MatchedEntries: "Live Punch",
  LateClockIn: "Late Clock In",
  AllEntries: "Live Punch All",
  UnMatchedEntries: "Live Punch",
};

const reportOptions = [
  { key: 3, label: "No Punch", value: "NoPunch" },
  { key: 4, label: "All Entries", value: "AllEntries" },
  { key: 1, label: "Late Clock In", value: "LateClockIn" },
  { key: 0, label: "Matched Entries", value: "MatchedEntries" },
  { key: 2, label: "Unmatched Entries", value: "UnMatchedEntries" },
];

function EmployeeReports() {
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const {
    jobsites,
    crewTeams,
    degEntries,
    accessRight,
    setDegEntries,
    employeesReport,
    setProgramEmployees,
  } = useContext(PayrollLiveContext);

  const [payrollReport, setPayrollReport] = useState<boolean>(false);
  const [newEmpModal, setNewEmpModal] = useState<boolean | NewEmpModalType>(
    false
  );
  const [entryModal, setEntryModal] = useState<
    undefined | Partial<EntryType>
  >();
  const [reportVisible, setReportVisible] = useState<false | ReportVisibleType>(
    false
  );

  const [form] = Form.useForm();
  const { width } = useResponsive();
  const { updateEntries, postEntries, removeEntries } = useEntriesApi();

  function clearFilters() {
    form.resetFields();
  }

  async function massUpdateEntries(entriesToUpdate: Array<EntryType>) {
    message.loading({
      duration: 0,
      key: "massUpdate",
      content: "Updating Entries...",
    });
    updateEntries({
      entries: entriesToUpdate,
      onSuccessCallback: () => {
        message.success({
          duration: 3,
          key: "massUpdate",
          content: "Entries updated successfully",
        });
      },
      onErrorCallback: (err) => {
        console.log("Error updating entries: ", err);
        message.success({
          duration: 3,
          key: "massUpdate",
          content: "There was a problem updating entries",
        });
      },
    });
  }

  function onNewEmployeeCreation(newEmp: EmployeeType) {
    setProgramEmployees((prev) => prev.concat(newEmp));
    let entriesOfEmployee = degEntries.flatMap((el) =>
      el?.employeeId === newEmp?.employeeId
        ? {
            ...el,
            crewId: newEmp?.crewId,
            crewName: newEmp?.crewName,
            salaryType: newEmp?.salaryType,
            employeeRate: newEmp?.employeeRate,
            employeeRole: newEmp?.crewPosition,
            employeeFullName: newEmp?.crewName,
          }
        : []
    );
    setDegEntries((prev) =>
      prev.flatMap((el) => {
        if (el?.employeeId === newEmp?.employeeId) {
          const updatedEntryObj = {
            ...el,
            crewId: newEmp?.crewId,
            crewName: newEmp?.crewName,
            salaryType: newEmp?.salaryType,
            employeeRate: newEmp?.employeeRate,
            employeeRole: newEmp?.crewPosition,
            employeeFullName: newEmp?.crewName,
          };
          return updatedEntryObj;
        } else {
          return el;
        }
      })
    );

    massUpdateEntries(entriesOfEmployee);
  }

  function onSaveCallback(data: EntryType) {
    message.loading({
      duration: 0,
      key: "entryCrud",
      content: "Saving...",
    });
    if (data?.entryId) {
      updateEntries({
        entries: [data],
        onSuccessCallback: () => {
          setDegEntries((prev) =>
            prev.map((el) => (el?.entryId === data?.entryId ? data : el))
          );
          message.success({
            duration: 2,
            key: "entryCrud",
            content: "Entry edited successfully",
          });
        },
        onErrorCallback: (err) => {
          message.error({
            duration: 2,
            key: "entryCrud",
            content: "There was a problem editing this entry",
          });
          console.log("Error editing entry: ", err);
        },
      });
    } else {
      const { duration, ...body } = data as EntryType & {
        userId: string;
        lastFetch: number;
      };

      postEntries({
        entries: [body],
        onSuccessCallback: () => {
          setDegEntries((prev) => [data].concat(prev));
          message.success({
            duration: 3,
            key: "entryCrud",
            content: "Entry created successfully",
          });
        },
        onErrorCallback: (err) => {
          message.error({
            duration: 2,
            key: "entryCrud",
            content: "There was a problem creating this entry",
          });
          console.log("Error editing entry: ", err);
        },
      });
    }
  }

  function onDelete(data: EntryType) {
    message.loading({
      duration: 3,
      key: "entryCrud",
      content: "Removing Entries...",
    });
    removeEntries({
      entries: [data],
      onSuccessCallback: () => {
        setDegEntries((prev) =>
          prev.filter((el) => el.entryId !== data?.entryId)
        );

        message.success({
          duration: 3,
          key: "entryCrud",
          content: "Entry deleted successfully",
        });
      },
      onErrorCallback: (err) => {
        console.log("Error deleting Entry: ", err);
        message.error({
          duration: 3,
          key: "entryCrud",
          content: "There was a problem deleting this entry",
        });
      },
    });
  }

  function resetTimeFilter() {
    form.setFieldValue("punchEnd", null);
    form.setFieldValue("punchStart", null);
  }

  function handleReport(dataType) {
    const liveData = payrollReportGenerator({
      dataType,
      jobsites,
      crewTeams,
      employeesReport,
    });
    setReportVisible({ dataType, liveData });
  }

  const timeFilter = useMemo(() => {
    return (
      <Form form={form}>
        <div className="time-range">
          <InputComponent
            form={form}
            secondaryDarkMode
            type="customTimeInput"
            formItemName="punchTimeStart"
          />
          <RightArrow
            width={13}
            height={13}
            fill={darkMode ? "#fff" : "#323338"}
          />
          <InputComponent
            form={form}
            secondaryDarkMode
            type="customTimeInput"
            formItemName="punchTimeEnd"
          />
          <button
            type="button"
            onClick={resetTimeFilter}
            className="clear-time-btn"
          >
            <XIcon height={10} width={10} />
          </button>
        </div>
      </Form>
    );
  }, [form, darkMode]);

  return (
    <section
      className={`employee-reports-page ${
        darkMode ? "employee-reports-page-dark" : ""
      }`}
    >
      <section className="employee-reports-control-panel">
        <Form form={form} className="control-panel-form">
          {RenderDynamicComponents(filterFields, form)}
          {width > 1100 ? (
            <div className="time-range">
              <InputComponent
                form={form}
                secondaryDarkMode
                type="customTimeInput"
                formItemName="punchStart"
              />
              <RightArrow
                width={13}
                height={13}
                fill={darkMode ? "#fff" : "#323338"}
              />
              <InputComponent
                form={form}
                secondaryDarkMode
                type="customTimeInput"
                formItemName="punchEnd"
              />
              <button
                type="button"
                onClick={resetTimeFilter}
                className="clear-time-btn"
              >
                <XIcon height={10} width={10} />
              </button>
            </div>
          ) : (
            <Popconfirm
              title={null}
              placement="bottom"
              description={timeFilter}
              rootClassName={`popup-filter ${
                darkMode ? "popup-filter-dark" : ""
              }`}
            >
              <HoverButton
                type="action"
                hasIcon={false}
                onClick={() => {}}
                text={width > 1400 ? "Time Filter" : "Filter"}
              />
            </Popconfirm>
          )}
        </Form>
        <HoverButton
          type="decline"
          hasIcon={false}
          text="Clear Filters"
          onClick={clearFilters}
        />
        <InputComponent
          type="select"
          noFormItem={true}
          onSelect={handleReport}
          placeholder="Generate Report"
          customOptions={reportOptions}
        />
      </section>
      <div className="employee-reports-body">
        <GroupedReportsCard form={form} setEntryModal={setEntryModal} />
        <LateClockIn form={form} />
        <UnregisteredEmployees setNewEmpModal={setNewEmpModal} form={form} />
        <UnMatchedEntries form={form} />
      </div>
      {!!newEmpModal ? (
        <CrewsHeader
          isNextStep={false}
          visible={!!newEmpModal}
          defaultData={newEmpModal}
          setVisible={setNewEmpModal}
          defaultEmployeeType={"crews"}
          refreshTable={onNewEmployeeCreation}
        />
      ) : null}
      {entryModal ? (
        <EntryModal
          open={!!entryModal}
          onDeleteCallback={onDelete}
          onSaveCallback={onSaveCallback}
          editDisabled={!accessRight?.write}
          editData={entryModal as EntryType}
          onCancel={() => setEntryModal(undefined)}
        />
      ) : null}
      {payrollReport ? (
        <GetPayrollReportModal
          open={payrollReport}
          onCancel={() => setPayrollReport(false)}
        />
      ) : null}

      {!!reportVisible ? (
        <Report
          {...{
            setReportVisible,
            sendByEmail: true,
            manualPath: "/projectCost",
            isModalVisible: !!reportVisible,
            customData: reportVisible?.liveData || [],
            customCategoryName: reports[reportVisible?.dataType],
            onCancel: () => {
              setReportVisible(false);
            },
          }}
        />
      ) : null}
    </section>
  );
}

export default EmployeeReports;
