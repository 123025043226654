import "./HeaderAutomations.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { Badge, Drawer, message, Segmented } from "antd";
import { ExportOrEmailDropdown, WithTooltip } from "../../../commonComponents";
import { AutomationIcon } from "../../../../assets";
import {
  findFieldOptionByNameOrId,
  getRouteAccessRights,
} from "../../../../utils";
import ActiveAutomations from "./components/ActiveAutomations/ActiveAutomations";
import ExecutedAutomations from "./components/ExecutedAutomations/ExecutedAutomations";
import {
  exportToExcel,
  onGeneratePDF,
  uploadExcelToDrive,
} from "./exportUtils/exportPdfExcel";
import useExportHeadersModal from "../../../../hooks/useExportHeadersModal";
import PdfHeadersModal from "../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import {
  automationHeaders,
  responsesHeaders,
} from "./exportUtils/tableHeaders";
import { formatAutomationResponses } from "./utils";
import { useEditLogs } from "../../../../hooks";

export const automationResponseLogsFilters = [
  {
    operator: "AND",
    conditions: [
      {
        operator: "AND",
        column: "category",
        value: "Automations",
        formula: "is",
      },
      {
        operator: "AND",
        column: "actionType",
        value: "Automation Response",
        formula: "is",
      },
    ],
  },
];

const HeaderAutomations = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { base64 } = useSelector((state) => state.base64);

  const [open, setOpen] = useState(false);
  const [allAutomations, setAllAutomations] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Active Automations");

  const { fetchedLogs = [], handleFetchLogs } = useEditLogs();

  const accessRights = getRouteAccessRights({
    userConfiguration,
    title: ["Settings", "Automation"],
  })?.read;

  if (!accessRights) return null;

  useEffect(() => {
    if (open) {
      message.loading("Retrieving automation log responses!");
      handleFetchLogs({ filters: automationResponseLogsFilters }).then(() => {
        message.destroy();
      });
    }

    API.get("automations", "/automations").then((res) => {
      setAllAutomations(res);
    });
  }, [open]);

  const { userName, groupName } = userConfiguration;
  const isAdmin = groupName.toLowerCase() === "admin";

  const activeAutomations = allAutomations.filter(
    ({ automationStatus }) => automationStatus === "Active"
  );

  const postEventsAutomations = findFieldOptionByNameOrId({
    fieldName: "Post Events Automations Configurations",
  })?.filter(({ shouldPostExecute }) => shouldPostExecute);

  const tabs = {
    "Active Automations": {
      Component: ActiveAutomations,
      props: {
        fetchedLogs,
        setAllAutomations,
        responses: {
          Automation: activeAutomations,
          ["Post Events Automations"]: postEventsAutomations,
        },
      },
    },
    "Executed Automations": {
      Component: ExecutedAutomations,
      props: {
        responses: formatAutomationResponses(fetchedLogs).filter(
          ({ toUsers = [] }) => isAdmin || toUsers.includes(userName)
        ),
      },
    },
  };

  const responses = tabs[selectedTab].props.responses;
  const additionalData = {
    data: responses,
    base64,
    status: selectedTab,
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({ additionalData, onGeneratePDF, exportToExcel });

  const SelectedTabComp = tabs[selectedTab].Component;

  const allAutomationsLength =
    [...postEventsAutomations, ...activeAutomations].length || 0;

  return (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="activeAutomations">
        <Badge count={allAutomationsLength} overflowCount={99}>
          <AutomationIcon
            className="header-icon"
            onClick={() => setOpen(true)}
          />
        </Badge>
      </WithTooltip>

      <Drawer
        open={open}
        title={
          <>
            <span>Active Automations</span>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <ExportOrEmailDropdown
                {...{
                  rowData: responses,
                  title: selectedTab,
                  defaultDocDefinition: true,
                  onGeneratePDF: (action, getDocDefinition) =>
                    getDocDefinition
                      ? onGeneratePDF(action, additionalData, getDocDefinition)
                      : getHeadersModal(action, getDocDefinition),
                  exportGridToExcel: (getDocDefinition) =>
                    getDocDefinition
                      ? exportToExcel(additionalData, getDocDefinition)
                      : getHeadersModal(false, getDocDefinition, "excel"),
                  uploadExcelToDrive: (driveRequest, driveFolderId) =>
                    uploadExcelToDrive(
                      additionalData,
                      driveRequest,
                      driveFolderId
                    ),
                  position: "true",
                  noTooltip: true,
                }}
              />
              <Link to={{ pathname: "/settings/allAutomation" }}>
                Automations
              </Link>
            </div>
          </>
        }
        width={580}
        onClose={() => {
          setOpen(false);
        }}
        placement="right"
        rootClassName={`header-automations-drawer${
          isDarkMode ? " dark-mode" : ""
        }`}
      >
        <Segmented
          options={["Active Automations", "Executed Automations"]}
          onChange={(value) => {
            setSelectedTab(value);
          }}
        />

        <div className="selected-tab-content">
          <SelectedTabComp {...{ ...tabs[selectedTab].props }} />
        </div>
      </Drawer>

      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers:
              selectedTab === "Active Automations"
                ? automationHeaders
                : responsesHeaders,
            title: selectedTab,
          }}
        />
      )}
    </div>
  );
};

export default HeaderAutomations;
