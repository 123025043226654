import { Typography } from "antd";
import { useSelector } from "react-redux";

const fontStylesMapping = {
  1: { fontSize: "26px", fontWeight: 800 },
  2: { fontSize: "22px", fontWeight: 700 },
  3: { fontSize: "18px", fontWeight: 600 },
  4: { fontSize: "16px", fontWeight: 600 },
  5: { fontSize: "14px", fontWeight: 600 },
};

export default function Headline({
  level = 5,
  upperCase,
  span,
  whiteText,
  style = {},
  ...typographyProps
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const currentFontStyles = fontStylesMapping[level] || fontStylesMapping[5];

  const styles = {
    width: "100%",
    margin: "auto",
    color: whiteText
      ? "whitesmoke"
      : !!isDarkMode
      ? "#f2f2f2"
      : "hsla(345, 6%, 13%, 1)",
    textTransform: upperCase ? "uppercase" : undefined,
    ...currentFontStyles,
    ...style,
  };

  if (span) {
    return (
      <div style={{ width: "fit" }}>
        <Typography.Title
          level={level}
          style={{ ...styles }}
          {...typographyProps}
        />
      </div>
    );
  }

  return (
    <Typography.Title
      level={level}
      style={{ ...styles }}
      {...typographyProps}
    />
  );
}
