import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";

export const claims = (updatedDynamicStates, IdHandler, programFields) => {
  const claimStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Claims;

  return {
    claimStatus: () => ({
      title: "Claims",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Claims",
        field: "claimStatus",
        id: IdHandler,
        page: "claims",
        labels: claimStatus?.map(({ statusName }) => statusName),
        datasets: [
          {
            label: "Claims",
            data: claimStatus?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (e) => e?.claimStatus === status.statusName
                )?.length
            ),
            backgroundColor: programFields
              ?.find((e) => e?.fieldName === "Status colors")
              ?.fieldOptions?.Claims?.claimStatus?.map((e) => e.statusColor),

            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }),

    claimCategory: () => ({
      title: "Claim Category",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Claims",
        field: "claimCategory",
        id: IdHandler,
        page: "claims",
        labels: uniq(
          updatedDynamicStates[IdHandler]?.map((e) => e?.claimCategory)
        ),
        datasets: [
          {
            label: "Claims",
            data: uniq(
              updatedDynamicStates[IdHandler]?.map((e) => e?.claimCategory)
            )?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (e) => e?.claimCategory === status
                )?.length
            ),
            backgroundColor: uniq(
              updatedDynamicStates[IdHandler]?.map(() => getRandomColor())
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
    claimType: () => ({
      title: "Claim Type",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Claims",
        field: "claimType",
        id: IdHandler,
        page: "claims",
        labels: uniq(updatedDynamicStates[IdHandler]?.map((e) => e?.claimType)),
        datasets: [
          {
            label: "Claims",
            data: uniq(
              updatedDynamicStates[IdHandler]?.map((e) => e?.claimType)
            )?.map(
              (status) =>
                updatedDynamicStates[IdHandler]?.filter(
                  (e) => e?.claimType === status
                )?.length
            ),
            backgroundColor: uniq(
              updatedDynamicStates[IdHandler]?.map(() => getRandomColor())
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    // isClaimantAnEmployeeOfCore: () => ({
    //   title: "Core Employee Claimants",
    //   BodyComponent: "BarChart",
    //   type: "BarChart",
    //   BodyComponentParams: {
    //     table: "Claims",
    //     field: "isClaimantAnEmployeeOfCore",
    //     id: IdHandler,
    //     labels: ["Yes", "No"],
    //     datasets: [
    //       {
    //         label: "Claims",
    //         data: uniq(
    //           updatedDynamicStates[IdHandler]?.map(
    //             (e) => e?.claimObject?.isClaimantAnEmployeeOfCore
    //           )?.length
    //         ),
    //         backgroundColor: uniq(
    //           updatedDynamicStates[IdHandler]?.map(() => getRandomColor())
    //         ),
    //         borderWidth: 0,
    //       },
    //     ],
    //   },
    //   id: IdHandler,
    //   width: "790px",
    //   isFilter: false,
    // }),

    // claimAddress: () => ({
    //   title: "Claims Address",
    //   BodyComponent: "BarChart",
    //   type: "BarChart",
    //   BodyComponentParams: {
    //     table: "Claims",
    //     field: "claimAddress",
    //     id: IdHandler,
    //     page: "claims",
    //     labels: boroughs,
    //     datasets: [
    //       {
    //         label: "Claims",
    //         data: boroughs?.map(
    //           (status) =>
    //             updatedDynamicStates[IdHandler]?.filter(
    //               (e) => e.borough?.toLowerCase() === status?.toLowerCase()
    //             )?.length
    //         ),
    //         backgroundColor: programFields
    //           ?.find((e) => e?.fieldName === "NY Boroughs")
    //           ?.fieldOptions?.map((e) => e.boroughColor),
    //         borderWidth: 0,
    //       },
    //     ],
    //   },
    //   id: IdHandler,
    //   width: "790px",
    // }),
  };
};
