import { v4 as uuidv4 } from "uuid";
import {
  pieChart,
  barChart,
  statusProgress,
  straightProgressBar,
  circleProgressBar,
  longLineSteper,
  longBarChart,
  lineChartFull,
} from "../Dashboard/addWidgetsModalData/index";
import { setTablesFilterToStorage } from "./utils/dashboardSession";
import { tableKeyMapping, tableMapping } from "./constants";
import { toCamelCase } from "../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import {
  getCostDispersion,
  getEmployeeAnalytics,
} from "../../pages/Payroll/Tabs/DEG/components/modalComponents/utils";
import { getRandomColor } from "../utils";
import { getTotalServicesPrice } from "../Projects/Accounting/calculations/servicePrices";
import { API } from "aws-amplify";

//months array
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//borought array
export const boroughs = [
  "Bronx",
  "Brooklyn",
  "Manhattan",
  "Queens",
  "Staten Island",
];

//function that makes population for rowData
export const addWidgetHandler = ({
  IdHandler,
  programFields,
  updatedDynamicStates,
  projectsData,
  dispatchData,
  fleetData,
  engData,
  archData,
  inspectionsData,
  circleProgressPicker,
  estimationsData,
  setPreferences,
  applicationsData,
  preferences,
  rentalsData,
  chargesData,
  claimsData,
  safetyData,
  citationsData,
  incidentsData,
  defectsData,
  workOrdersData,
  jobsites,
  reducedUsers,
}) => {
  return {
    "Long Bar Chart": () => longBarChart(IdHandler, updatedDynamicStates),
    "Status Progress": () =>
      statusProgress(
        IdHandler,
        updatedDynamicStates,
        programFields,
        applicationsData,
        rentalsData,
        chargesData,
        claimsData,
        incidentsData,
        safetyData,
        citationsData,
        projectsData,
        jobsites
      ),
    "Circle Progress Bar": () =>
      circleProgressBar(
        IdHandler,
        updatedDynamicStates,
        circleProgressPicker,
        applicationsData,
        rentalsData,
        chargesData,
        claimsData,
        incidentsData,
        jobsites
      ),
    "Pie Chart": () =>
      pieChart(
        IdHandler,
        programFields,
        updatedDynamicStates,
        projectsData,
        engData,
        archData,
        inspectionsData,
        fleetData,
        dispatchData,
        rentalsData,
        applicationsData,
        chargesData,
        claimsData,
        incidentsData,
        defectsData,
        workOrdersData,
        safetyData,
        jobsites,
        reducedUsers
      ),
    "Bar Chart": () =>
      barChart(
        IdHandler,
        programFields,
        updatedDynamicStates,
        projectsData,
        engData,
        archData,
        inspectionsData,
        fleetData,
        dispatchData,
        applicationsData,
        rentalsData,
        chargesData,
        claimsData,
        incidentsData,
        defectsData,
        workOrdersData,
        safetyData,
        citationsData,
        jobsites,
        reducedUsers
      ),
    "Straight Progress Bar": () =>
      straightProgressBar(
        IdHandler,
        programFields,
        updatedDynamicStates,
        projectsData,
        engData,
        archData,
        inspectionsData,
        fleetData,
        dispatchData,
        estimationsData,
        applicationsData,
        rentalsData,
        chargesData,
        claimsData,
        incidentsData,
        safetyData,
        citationsData,
        jobsites,
        reducedUsers
      ),
    "Long Line Steper": () =>
      longLineSteper(
        IdHandler,
        programFields,
        updatedDynamicStates,
        engData,
        archData,
        inspectionsData,
        fleetData,
        dispatchData,
        applicationsData,
        rentalsData,
        chargesData,
        claimsData,
        incidentsData,
        defectsData,
        workOrdersData,
        safetyData,
        jobsites
      ),
    "Line Chart Full": () =>
      lineChartFull(
        IdHandler,
        programFields,
        updatedDynamicStates,
        applicationsData,
        rentalsData,
        chargesData,
        claimsData,
        incidentsData,
        safetyData,
        jobsites
      ),
  };
};

//width and height provider for cards
const widthHandler = {
  "Pie Chart": 1,
  "Bar Chart": 2,
  "Straight Progress Bar": 1,
  "Circle Progress Bar": 1,
  "Long Line Steper": 4,
  "Status Progress": 2,
  "Long Bar Chart": 4,
  "Line Chart Full": 2,
};
const heightHandler = {
  "Pie Chart": 1,
  "Bar Chart": 1,
  "Straight Progress Bar": 1,
  "Circle Progress Bar": 1,
  "Long Line Steper": 1,
  "Status Progress": 1,
  "Long Bar Chart": 2,
  "Line Chart Full": 1,
};

//function that makes population of coordinates of layout for adding
export const coordinatesAdder = ({ layout, widgetOptions, IdHandler }) => {
  return {
    lg: [
      ...(layout?.lg || []),
      {
        w: widthHandler[widgetOptions],
        h: heightHandler[widgetOptions],
        x: 0,
        y: layout?.lg?.length
          ? Math.max(...((layout?.lg || [])?.map((a) => a?.y) || 0)) + 1
          : 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    md: [
      ...(layout?.md || []),
      {
        w: widthHandler[widgetOptions],
        h: heightHandler[widgetOptions],
        x: 0,
        y: layout?.md?.length
          ? Math.max(...((layout?.md || [])?.map((a) => a?.y) || 0)) + 1
          : 0,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    sm: [
      ...(layout?.sm || []),
      {
        w: widthHandler[widgetOptions],
        h: heightHandler[widgetOptions],
        x: 0,
        y: layout?.sm?.length
          ? Math.max(...((layout?.sm || [])?.map((a) => a?.y) || 0)) + 1
          : 0,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    xs: [
      ...(layout?.xs || []),
      {
        w: widthHandler[widgetOptions],
        h: heightHandler[widgetOptions],
        x: 0,
        y: layout?.xs?.length
          ? Math.max(...((layout?.xs || [])?.map((a) => a?.y) || 0)) + 1
          : 0,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    xxs: [
      ...(layout?.xxs || []),
      {
        w: widthHandler[widgetOptions],
        h: heightHandler[widgetOptions],
        x: 0,
        y: layout?.xxs?.length
          ? Math.max(...((layout?.xxs || [])?.map((a) => a?.y) || 0)) + 1
          : 0,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    a: [
      ...(layout?.a || []),
      {
        w: widthHandler[widgetOptions],
        h: heightHandler[widgetOptions],
        x: 0,
        y: layout?.a?.length
          ? Math.max(...((layout?.a || [])?.map((a) => a?.y) || 0)) + 1
          : 0,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],

    b: [
      ...(layout?.b || []),
      {
        w: widthHandler[widgetOptions],
        h: heightHandler[widgetOptions],
        x: 0,
        y: layout?.b?.length
          ? Math.max(...((layout?.b || [])?.map((a) => a?.y) || 0)) + 1
          : 0,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
  };
};

//function that makes population of coordinates of layout for showing
export const widthHandlerShow = {
  Pie: 1,
  BarChart: 2,
  StreightProgressBar: 1,
  CircleProgresBar: 1,
  LongLineSteper: 4,
  Progress: 2,
  BarChartFull: 4,
  LineChartFull: 2,
};

export const heightHandlerShow = {
  Pie: 1,
  BarChart: 1,
  StreightProgressBar: 1,
  CircleProgresBar: 1,
  LongLineSteper: 1,
  Progress: 1,
  BarChartFull: 2,
  LineChartFull: 1,
};

//function that makes population of coordinates of layout for adding
export const coordinatesShower = ({ layout, widgetOptions, IdHandler }) => {
  return {
    lg: [
      ...layout.lg,
      {
        w: parseInt(widthHandlerShow[widgetOptions]),
        h: heightHandlerShow[widgetOptions],
        x: 0,
        y: Math.max(...layout.lg.map((a) => a.y)) + 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    md: [
      ...layout.md,
      {
        w: widthHandlerShow[widgetOptions],
        h: heightHandlerShow[widgetOptions],
        x: 0,
        y: Math.max(...layout.md.map((a) => a.y)) + 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    sm: [
      ...layout.sm,
      {
        w: widthHandlerShow[widgetOptions],
        h: heightHandlerShow[widgetOptions],
        x: 0,
        y: Math.max(...layout.sm.map((a) => a.y)) + 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    xs: [
      ...layout.xs,
      {
        w: widthHandlerShow[widgetOptions],
        h: heightHandlerShow[widgetOptions],
        x: 0,
        y: Math.max(...layout.xs.map((a) => a.y)) + 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    xs: [
      ...layout.xs,
      {
        w: widthHandlerShow[widgetOptions],
        h: heightHandlerShow[widgetOptions],
        x: 0,
        y: Math.max(...layout.xs.map((a) => a.y)) + 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    xxs: [
      ...layout.xxs,
      {
        w: widthHandlerShow[widgetOptions],
        h: heightHandlerShow[widgetOptions],
        x: 0,
        y: Math.max(...layout.xxs.map((a) => a.y)) + 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
    a: [
      ...layout.a,
      {
        w: widthHandlerShow[widgetOptions],
        h: heightHandlerShow[widgetOptions],
        x: 0,
        y: Math.max(...layout.a.map((a) => a.y)) + 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],

    b: [
      ...layout.b,
      {
        w: widthHandlerShow[widgetOptions],
        h: heightHandlerShow[widgetOptions],
        x: 0,
        y: Math.max(...layout.b.map((a) => a.y)) + 1,
        i: IdHandler,
        moved: false,
        static: false,
      },
    ],
  };
};

export const coordinatesReplacer = ({ layout, widgetOptions, IdHandler }) => {
  const layoutKeys = ["lg", "md", "sm", "xs", "xxs", "a", "b"];
  return layoutKeys?.reduce((acc, key) => {
    return {
      ...acc,
      [key]: layout?.[key]?.map((el) => {
        if (el.i === IdHandler) {
          return {
            ...el,
            w:
              key === "lg"
                ? parseInt(widthHandlerShow[widgetOptions])
                : widthHandlerShow[widgetOptions],
            h: heightHandlerShow[widgetOptions],
          };
        } else {
          return el;
        }
      }),
    };
  }, {});
};

//handles back button on every step of the stepper
export const BackHandler = ({
  currentStep,
  setSubCategoryFields,
  setWidgetOptions,
  setCircleProgressPicker,
  setCurrentStep,
  setNextFinishButtonContent,
  setUpdatedDynamicStates,
  IdHandler,
  setDynamicStates,
  setSubCategoryName,
  setWidgetCategory,
}) => {
  //third step deleted card from state and send you to the 2nd step
  if (currentStep === 2) {
    setSubCategoryFields("");
    setWidgetOptions("");
    setCircleProgressPicker("");
    setCurrentStep((a) => a - 1);
    setNextFinishButtonContent("Next");
    setUpdatedDynamicStates(({ [IdHandler]: keyToRemove, ...rest }) => rest);
    setDynamicStates(({ [IdHandler]: keyToRemove, ...rest }) => rest);
  }
  //2nd send to the first stop and clear the options selected
  else if (currentStep === 1) {
    setCurrentStep((a) => a - 1);
    setSubCategoryName("");
    setWidgetCategory("");
    setSubCategoryFields("");
    setWidgetOptions("");
    setCircleProgressPicker("");
  }
};

//modal next handler on every step
export const NextHandler = async ({
  currentStep,
  subCategoryName,
  widgetCategoryPicker,
  setCurrentStep,
  setIdHandler,
  widgetOptions,
  circleProgressPicker,
  IdHandler,
  setLayout,
  setStatesData,
  widgetRepetition,
  subCategoryFields,
  statesData,
  rowData,
  setIsModalVisible,
  setWidgetRepetition,
  updatedDynamicStates,
  initialData,
  setNextFinishButtonContent,
  setUpdatedDynamicStates,
  setDynamicStates,
  populatedWidgetHandler,
  layout,
  setRowData,
  fetchData,
  setPreferences,
  preferences,
  url,
  setPopulateDefault = () => {},
}) => {
  //the first step crates the id of the card also we chose the category and the table of the card
  if (currentStep === 0) {
    if (subCategoryName !== "" && widgetCategoryPicker !== "") {
      setCurrentStep((a) => a + 1);
      setIdHandler(uuidv4);
    }
  }
  //second step creates the card on state but it doesn't show it
  else if (currentStep === 1) {
    if (
      widgetOptions === "Circle Progress Bar"
        ? circleProgressPicker
        : subCategoryFields && widgetOptions
    ) {
      const updatedStates = {
        ...updatedDynamicStates,
        [IdHandler]: initialData[subCategoryName.toLocaleLowerCase()][0],
      };
      setCurrentStep((a) => a + 1);
      setNextFinishButtonContent("Finish");
      setUpdatedDynamicStates(updatedStates);
      setDynamicStates(updatedStates);
    }
  }

  //the third step saves the card information on db and show the card also disables the card from add widget
  else if (currentStep === 2) {
    const updatedRowData = [
      ...(rowData || []),
      populatedWidgetHandler[widgetOptions]()
        [subCategoryName]()
        [subCategoryFields](),
    ];

    const updatedLayout = coordinatesAdder({
      layout,
      widgetOptions,
      IdHandler,
      rowData,
    });
    //disable card from add widget
    const newWidgetRepetition = {
      ...widgetRepetition,
      [subCategoryName]: {
        ...widgetRepetition?.[subCategoryName],
        [subCategoryFields]: {
          ...widgetRepetition?.[subCategoryName]?.[subCategoryFields],
          [widgetOptions]: true,
        },
      },
    };

    const statesDataCreation = { [IdHandler]: subCategoryName };
    const statesMerge = { ...statesData, ...statesDataCreation };

    const apiTable =
      tableMapping[subCategoryName?.toLowerCase()] ||
      toCamelCase(subCategoryName);

    setTablesFilterToStorage("initial", apiTable);

    //disable cards handle in state
    setWidgetRepetition((prev) => ({ ...prev, [url]: newWidgetRepetition }));
    //disable cards handle in db
    setCurrentStep(0);
    // setRowData([...updatedRowData])
    // setLayout({...updatedLayout})
    setIsModalVisible(false);
    // setStatesData({...statesMerge})
    setPreferences((prev) => {
      // prev["defaultConfiguration"][url] = statesMerge;
      // prev["widgetConfiguration"][url] = updatedRowData;
      // prev["widgetLayout"][url] = updatedLayout;

      return {
        ...(prev || {}),
        defaultConfiguration: {
          ...(prev?.defaultConfiguration || []),
          [url]: statesMerge,
        },
        widgetConfiguration: {
          ...(prev?.widgetConfiguration || {}),
          [url]: updatedRowData,
        },
        widgetLayout: {
          ...(prev?.widgetLayout || {}),
          [url]: updatedLayout,
        },
      };
      // return { ...prev };
    });

    setPopulateDefault(false);
    setStatesData(statesMerge);

    await API.patch("preferences", "/preferences", {
      body: {
        defaultConfiguration: {
          ...preferences.defaultConfiguration,
          [url]: statesMerge,
        },
        widgetConfiguration: {
          ...preferences.widgetConfiguration,
          [url]: updatedRowData,
        },
        widgetLayout: { ...preferences.widgetLayout, [url]: updatedLayout },
        widgetRepetition: {
          ...preferences.widgetRepetition,
          [url]: newWidgetRepetition,
        },
      },
    })
      .then?.((res) => {
        fetchData();
      })
      .catch((err) => {
        let tmpStatesMerge = { ...statesMerge };
        delete tmpStatesMerge?.[IdHandler];
        setStatesData(tmpStatesMerge);
        console.error("Error updating preferences", err);
      });
    // await Promise.allSettled([
    //   await defConf,
    //   await widgetConf,
    //   await widgetLayout,
    // ]).then?.((res) => {
    //   fetchData();
    // });
  }
};

export function getProjectCostData({ data = [], jobsites = [] }) {
  let analytics = {};

  getEmployeeAnalytics({
    degRows: data,
    degGridApi: {},
    analyticsUpdate: (res) => {
      Object.assign(analytics, res);
    },
    externalFiltersPass: () => true,
  });

  return getCostDispersion({
    analytics,
    jobsites,
  });
}

export function getAmountPerService(data) {
  let allServices = {};

  data?.forEach((est) => {
    if (Array.isArray(est?.services) && !!est?.services?.length) {
      est?.services?.forEach((service) => {
        if (typeof service !== "object") return;
        if (!allServices[service?.label]) {
          allServices[service?.label] = parseFloat(
            getTotalServicesPrice([service])
          );
        } else {
          allServices[service?.label] += parseFloat(
            getTotalServicesPrice([service])
          );
        }
      });
    }
  });

  return allServices;
}

export function costChartData(
  costDispersion = [],
  labelKey = "",
  dataKey = "",
  formatter = (val) => val
) {
  const labels = [];
  const data = [];
  const backgroundColor = [];
  const statusData = [];

  costDispersion?.map((el) => {
    labels.push(el[labelKey]);
    data.push(formatter(el[dataKey]));
    backgroundColor.push(getRandomColor());
    statusData.push({
      label: el[labelKey],
      value: formatter(el[dataKey]),
      color: "#6D8299",
    });
  });

  return { labels, data, backgroundColor, statusData };
}
