import "./DocMembers.scss";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Form } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { InputComponent } from "../../../../../../Fleet/components";
import {
  AvatarImages,
  MaxTagTooltip,
  MondayButton,
} from "../../../../../../../commonComponents";
import { UserIconFilled } from "../../../../../../../../assets";
import DynamicTeamModal from "../../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { formatTeamFormField } from "../../helpers";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";
import { useSelector } from "react-redux";
import FolderAccessChanger from "../FolderAccessChanger/FolderAccessChanger";

const DocMembers = ({
  form,
  docConfig,
  docType,
  categoryName,
  thisDocNameComesFrom,
  teamsConfiguration = [],
  selectedTeam,
  setSelectedTeam,

  docId,
  folderId,
}) => {
  const [docTeam, setDocTeam] = useState([]);
  const [open, setOpen] = useState(false);
  const [allTeams, setAllTeams] = useState([]);
  const [refreshToken, setRefreshToken] = useState(1);
  const [accessVisible, setAccessVisible] = useState(false);
  const [permissionList, setPermissionList] = useState([]);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const driveRequest = driveApi({ accessToken });
  const allUsers = userConfiguration?.allUsers?.Items;

  const userObj = allUsers?.reduce((acc, el) => {
    acc[el.userName] = el;
    return acc;
  }, {});

  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data?.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onTeamDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== teamName));
    if (form.getFieldValue("documentationTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  useEffect(() => {
    API.get("teams", "/teams")
      .then((team) => {
        setAllTeams(team);
        setDocTeam(formatTeamFormField(team));
      })
      .catch((error) => console.error(error));
    if (teamsConfiguration?.length > 0) {
      setSelectedTeam(teamsConfiguration || []);
      form.setFieldValue("documentationTeam", teamsConfiguration || []);
    }
  }, []);

  async function getPermissionList() {
    let result = [];
    try {
      result = await driveRequest
        .permissionList(folderId)
        .then((res) => res.json())
        .then(({ permissions }) => {
          return permissions;
        });
    } catch (error) {
      console.error({ error });
    }

    return result;
  }

  const hasAccess = !!(userConfiguration?.routeConfig || [])
    .find((el) => el?.title === "Settings")
    ?.children?.find((el) => el?.title === "User Management")?.write;

  useEffect(() => {
    if (accessToken && docId && folderId) {
      getPermissionList().then((res) => {
        setPermissionList(res || []);
      });
    }
  }, [accessToken, docId, folderId, refreshToken]);

  const categories =
    docConfig?.find((el) => el?.categoryName === categoryName)
      ?.documentationsAvailable || [];

  const settingsTeamMembers =
    categories
      ?.find((cat) => cat?.docName === docType)
      ?.teamsConfiguration?.flatMap(({ members = [] }) => members) || [];

  return (
    <>
      <div className="doc-members">
        <div className="membersButtonDiv">
          <InputComponent
            {...{
              label: "Team",
              className: "doc-selectTeam",
              formItemName: "documentationTeam",
              tooltipCategory: "Documentations",
              tooltipKey: "documentationTeam",
              type: "select",
              mode: "multiple",
              placeholder: "Select team...",
              allowClear: false,
              customOptions: docTeam,
              onSelect: onTeamSelect,
              onDeselect: onTeamDeselect,
              showSearch: true,
              disabled: thisDocNameComesFrom !== "",
              maxTagPlaceholder: (items) => (
                <MaxTagTooltip array={items} hasDeselect={false} />
              ),
              maxTagCount: "responsive",
            }}
          />

          {!!selectedTeam?.length && (
            <MondayButton
              onClick={() => {
                setOpen(true);
              }}
              Icon={<UserIconFilled />}
              tooltipCategory="Documentations"
              tooltipKey="membersModal"
              className="mondayButtonBlue docMembersButton"
            />
          )}
        </div>

        <AvatarImages
          {...{
            cognitoUserIds: (selectedTeam || teamsConfiguration || [])
              ?.flatMap((el) => el?.members)
              ?.map((e) => e?.cognitoUserId),
          }}
        />
        {userConfiguration?.groupName === "Admin" &&
        hasAccess &&
        docId &&
        folderId ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
              width: "100%",
            }}
          >
            <MondayButton
              {...{
                onClick: () => {
                  setAccessVisible(true);
                },
                hasIcon: false,
                className: "mondayButtonBlue",
              }}
            >
              Change Folder Access
            </MondayButton>
            {permissionList?.length ? (
              <AvatarImages
                {...{
                  cognitoUserIds: permissionList?.map((el) => {
                    const user = userObj?.[el?.emailAddress] || {};
                    return user?.cognitoUserId;
                  }),
                }}
              />
            ) : null}
          </div>
        ) : null}

        {open && (
          <DynamicTeamModal
            {...{
              open,
              setOpen,
              selectedTeam,
              setSelectedTeam,
              ClearOptions: () => {
                setOpen(false);
              },
              proppedAllTeams: allTeams,
              onSave: (data) => {
                form.setFieldValue("documentationTeam", data || []);
              },
            }}
          />
        )}
      </div>
      {!!settingsTeamMembers.length && (
        <div className="docConfigTable">
          <span className="docCofingTableText">
            <InfoCircleFilled />
            <p>Here are the users that are selected in settings!</p>
          </span>

          <AvatarImages
            {...{
              cognitoUserIds: settingsTeamMembers.map(
                ({ cognitoUserId }) => cognitoUserId
              ),
            }}
          />
        </div>
      )}
      {accessVisible && (
        <FolderAccessChanger
          {...{
            visible: accessVisible,
            setVisible: setAccessVisible,
            permissionList,
            refreshPermissions: () => setRefreshToken((prev) => prev + 1),
            folderId,
          }}
        />
      )}
    </>
  );
};

export default DocMembers;
