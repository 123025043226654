import "./ServicesInspected.scss";
import { Flex, Tooltip } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

import { XIcon } from "../../../components/SidebarPages/Communication/assets";
import { InspectionModal } from "../../../components/SidebarPages/XComponents";
import { hexToRgba } from "../../../components/SidebarPages/utils";
import { MondayButton } from "../../../components/commonComponents";
import QuestionsImages from "./QuestionsImages";

import Headline from "src/components/commonComponents/Typography/Headline";

const ServicesInspected = ({ value = [] }) => {
  const [visible, setVisible] = useState(false);
  const disabledColor = hexToRgba("#1a3a6a", 0.4);
  const disabled = !value?.length;

  const uniqueServices = [
    ...new Set(
      value
        ?.map((obj) =>
          obj?.services?.flatMap((service) => service?.serviceType)
        )
        ?.flat()
    ),
  ];

  // const servicesInspected = value?.reduce(
  //   (total, obj) => total + obj?.services?.length,
  //   0
  // );

  return (
    <>
      <Tooltip
        title={disabled ? "there are no services inspected to show" : ""}
      >
        <button
          onClick={() => (!disabled ? setVisible(true) : {})}
          className="servicesInspectedCell"
          style={
            disabled
              ? {
                  backgroundColor: disabledColor,
                  borderColor: disabledColor,
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          {uniqueServices?.length}
        </button>
      </Tooltip>
      {visible && (
        <InspectionModal
          visible={visible}
          title="Services Inspected"
          setVisible={setVisible}
          basicModalFooter={[
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={() => setVisible(false)}
            >
              Close
            </MondayButton>,
          ]}
        >
          {typeof value !== "string"
            ? value?.map((estimation) => (
                <Flex gap="small" vertical>
                  {!!estimation?.estimationNumber ? (
                    <Link
                      target="_blank"
                      to={`/estimations/${estimation?.estimationId}`}
                    >
                      <Headline>
                        Estimation number {estimation?.estimationNumber}
                      </Headline>
                    </Link>
                  ) : (
                    <Headline>Estimation number #</Headline>
                  )}

                  <div style={{ marginLeft: 25 }}>
                    {estimation?.services
                      ?.filter(
                        (el) => !el?.serviceType?.includes("(Change Order)")
                      )
                      ?.map(
                        (
                          {
                            serviceType,
                            failed,
                            passed,
                            passedFilesIds,
                            failedFilesIds,
                            allFilesIds,
                            notIns,
                          },
                          i
                        ) => {
                          const filesIds = allFilesIds;
                          // const filesIds =
                          //   failedFilesIds && passedFilesIds
                          //     ? [...failedFilesIds, ...passedFilesIds]
                          //     : failedFilesIds || passedFilesIds;

                          return (
                            <div
                              key={i}
                              className="services-inspected-container"
                            >
                              <div className="services-inspected-details">
                                <div className="service-details-container">
                                  <p>{serviceType} </p>{" "}
                                  <p>({allFilesIds?.length || 0} photos)</p>
                                </div>
                                <ul className="questions-details-container">
                                  <li>
                                    {passed}{" "}
                                    {`${passed > 1 ? "questions" : "question"}`}{" "}
                                    - Passed
                                  </li>
                                  <li>
                                    {failed}{" "}
                                    {`${failed > 1 ? "questions" : "question"}`}{" "}
                                    - Failed
                                  </li>
                                  <li>
                                    {notIns}{" "}
                                    {`${notIns > 1 ? "questions" : "question"}`}{" "}
                                    - Not completed/decisive
                                  </li>
                                </ul>
                              </div>

                              <>
                                {filesIds && filesIds?.length > 0 ? (
                                  <QuestionsImages files={filesIds} />
                                ) : null}
                              </>
                            </div>
                          );
                        }
                      )}
                  </div>
                </Flex>
              ))
            : null}
        </InspectionModal>
      )}
    </>
  );
};

export default ServicesInspected;
