import { fetchData } from "../../../../SidebarPages/Fleet/utils";

const fetchProposalTemplates = async (proposalData) => {
  let toReturn = {
    proposalTemplates: [],
    servicesTemplates: [],
  };
  try {
    const data = await fetchData("proposalTemplates");
    // console.log("data", data);
    if (data) {
      let tempTemplates =
        data?.filter((el) => el?.templateCategory === "service") || [];
      //   setServicesTemplates(tempTemplates);
      toReturn.servicesTemplates = tempTemplates;
      let toShow = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element?.templateCategory?.toLowerCase() === "general") {
          toShow.push({
            ...element,
            templateCategory: element.templateCategory.toLowerCase(),
            serviceName: element?.templateTitle || "",
            templateContent: getTemplateContent(
              element || "",
              tempTemplates,
              proposalData
            ),
            parentTemplateContent: element?.templateContent || "",
            color: "",
            templateId: element?.templateId || "",
          });
        }
      }
      // console.log("toShow", toShow);
      //   setProposalTemplates(Array.isArray(toShow) ? toShow : []);
      toReturn.proposalTemplates = Array.isArray(toShow) ? toShow : [];
    }
    return toReturn;
  } catch (e) {
    console.log("fetchProposalTemplates ~ e", e);
    return toReturn;
  }
};

export default fetchProposalTemplates;

const getTemplateContent = (template, tempTemplates, proposalData) => {
  // console.log("getTemplateContent ~ template:", { template });
  let myServiceTemplates = tempTemplates?.filter((sTemplate) => {
    return !!proposalData?.takeOff?.services?.find(
      (service) =>
        service?.serviceId?.toString() ===
        sTemplate?.additionalDetails?.service?.workId?.toString()
    )
      ? true
      : false;
  });
  let htmlContent = template?.templateContent;
  let pattern = /\{\{([^_]+_[^}]+)\}\}/g;
  let result = htmlContent?.replace(pattern, function (match, id) {
    let templateIdService = id?.split("_")?.[1];
    let serviceName = id?.split("_")?.[0];

    let particulateService = tempTemplates?.find((el) => {
      return el?.templateId === templateIdService;
    });

    if (!!particulateService) {
      let droppedHTML = addStringToDoubleBracesTags(
        particulateService?.templateContent || "",
        particulateService?.additionalDetails?.service?.workName || ""
      );
      return droppedHTML || "";
    } else {
      return "";
    }
  });
  return result;
};

function addStringToDoubleBracesTags(html, keyToAdd) {
  const regex = /\{\{(\w+)\}\}/g;
  const updatedHtml = html.replace(
    regex,
    `<span class="proposalTag" contenteditable="false" style="cursor: not-allowed;">{{$1_${keyToAdd}}}</span>`
  );
  return updatedHtml;
}
