import { Flex } from "antd";
import Forecasting from "../../../../../SidebarPages/Projects/Tabs/Forecasting/Forecasting";
import "./ForecastingStep.scss";

const ForecastingStep = ({
  defaultData,
  handleSavedRecord,
  setVisible,
  currentStep,
  setCurrentStep,
  filteredSteps,
}) => {
  return (
    <Flex className="ForecastingStepContainer">
      <Forecasting
        rowData={defaultData}
        setRowData={(data) => {
          handleSavedRecord(data);
          currentStep === filteredSteps?.length - 1
            ? setVisible(false)
            : setCurrentStep((prev) => prev + 1);
        }}
      />
    </Flex>
  );
};

export default ForecastingStep;
