import { fetchData } from "../../components/SidebarPages/Fleet/utils";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

export const getAccountingComparisonPerProject = async () => {
  try {
    let [
      projectsRes,
      scheduleOfValuesRes,
      applicationsRes,
      rentalsRes,
      chargesRes,
      invoicesRes,
      billsRes,
      paymentsRes,
      includedRentalsRes,
    ] = await Promise.all([
      fetchAllData("projects", "projects", "projectId"),
      fetchAllData("scheduleOfValues", "schedules", "scheduleId"),
      fetchAllData("applications", "applications", "applicationId"),
      fetchAllData("rentals", "rentals", "rentalId"),
      fetchAllData("charges", "charges", "chargeId"),
      fetchAllData("invoices", "invoices", "invoiceId"),
      fetchAllData("bills", "bills", "billId"),
      fetchAllData("payments", "payments", "paymentId"),
      fetchAllData("includedRentals", "includedRentals", "rentalsReqId"),
    ]);

    const result = projectsRes.map((project) => {
      const allServices = [];

      const scheduleOfValues = scheduleOfValuesRes.filter(
        (schedule) => schedule.projectId === project.projectId
      );
      scheduleOfValues.forEach((schedule) =>
        schedule.services.map((service) => allServices.push(service.label))
      );

      const applications = applicationsRes
        .filter((application) => application.projectId === project.projectId)
        .map((app) => ({
          ...app,
          services: app.services,
          includedRentals: includedRentalsRes
            .filter(
              (rental) =>
                rental.applicationId === app.applicationId &&
                rental.projectId === app.projectId
            )
            .concat(app.includedRentals),
        }));

      applications.forEach((application) =>
        application.services.map((service) => allServices.push(service.label))
      );

      const rentals = rentalsRes.filter(
        (rentals) => rentals.projectId === project.projectId
      );

      rentals.forEach((rental) =>
        rental.services.map((service) => allServices.push(service.label))
      );

      const charges =
        chargesRes.filter((charge) => charge.projectId === project.projectId) ||
        [];
      // .map((app) => ({
      //   ...app,
      //   includedRentals: includedRentalsRes.filter(
      //     (rental) => rental.applicationId === app.recordId
      //   ),
      // }));

      charges.forEach((charge) =>
        charge.chargeItems?.map((service) => allServices.push(service.label))
      );

      const bills = billsRes.filter(
        (bill) => bill.projectId === project.projectId
      );

      bills.forEach((bill) =>
        bill.billItems.map((service) => allServices.push(service.itemService))
      );

      const invoices = invoicesRes.filter(
        (invoice) => invoice.projectId === project.projectId
      );

      const payments = paymentsRes.filter((payment) =>
        payment.invoices
          .filter((invoice) => invoice.projectId === project.projectId)
          .some((paymentDetail) =>
            invoices.some(
              (invoice) =>
                invoice.invoiceItems.length > 0 &&
                invoice.invoiceNumber === paymentDetail.invoiceNumber
            )
          )
      );

      const services = [...new Set(allServices.filter(Boolean))];

      return {
        scheduleOfValues,
        projectId: project.projectId,
        projectName: project.projectName,
        totalScheduleOfValues: scheduleOfValues.reduce(
          (sum, schedule) => sum + schedule.totalPrice,
          0
        ),
        totalApplications: applications.reduce(
          (sum, application) => sum + application.totalities.currentPaymentDue,
          0
        ),
        totalRentals: rentals.reduce(
          (sum, rental) => sum + rental.totalPrice,
          0
        ),
        totalCharges: charges.reduce(
          (sum, charge) => sum + charge.chargeAmount,
          0
        ),
        totalBills: bills.reduce((sum, bill) => sum + bill.billAmount, 0),
        totalInvoices: invoices.reduce(
          (sum, invoice) => sum + invoice.totalInvoiceAmount,
          0
        ),
        totalPayments: payments.reduce(
          (sum, payment) =>
            sum +
            payment.invoices.reduce(
              (itemSum, item) => itemSum + Number(item.invoicePaidAmount),
              0
            ),

          0
        ),
        services: services?.map((service) => ({
          projectId: project.projectId,
          projectName: project.projectName,
          serviceName: service,
          // totalScheduleOfValues: scheduleOfValues.reduce(
          //   (sum, schedule) =>
          //     sum +
          //     schedule.services
          //       .filter((item) => item.label === service)
          //       .reduce((itemSum, item) => itemSum + item.price, 0),
          //   0
          // ),

          totalScheduleOfValues: scheduleOfValues.reduce((sum, schedule) => {
            const serviceAmounts = schedule.services
              .filter((item) => item.label === service)
              .reduce((itemSum, item) => {
                if (
                  (item.isHoist || item.label === "Hoist") &&
                  item.serviceOptions?.[0]
                ) {
                  return (
                    itemSum +
                    item.serviceOptions[0].reduce(
                      (optionSum, option) =>
                        optionSum +
                        (option.amounts?.reduce(
                          (amountItemSum, amountItem) =>
                            amountItemSum + (amountItem.amount || 0),
                          0
                        ) || 0),
                      0
                    )
                  );
                }

                return (
                  itemSum +
                  item.amounts.reduce(
                    (amountItemSum, amountItem) =>
                      amountItemSum + (amountItem.amount || 0),
                    0
                  )
                );
              }, 0);

            return sum + serviceAmounts;
          }, 0),

          totalApplications: applications.reduce((sum, application) => {
            const serviceAmounts = application.services
              .filter((item) => item.label === service)
              .reduce((itemSum, item) => {
                if (
                  (item.isHoist || item.label === "Hoist") &&
                  item.serviceOptions?.[0]
                ) {
                  return (
                    itemSum +
                    item.serviceOptions[0].reduce(
                      (optionSum, option) =>
                        optionSum +
                        (option.amounts?.reduce(
                          (amountSum, amount) =>
                            amountSum + (amount.paymentDue || 0),
                          0
                        ) || 0),
                      0
                    )
                  );
                }

                return (
                  itemSum +
                  item.amounts.reduce(
                    (amountSum, amount) => amountSum + (amount.paymentDue || 0),
                    0
                  )
                );
              }, 0);

            const rentalAmounts = application.includedRentals.reduce(
              (sumRent, rental) =>
                sumRent +
                rental.services
                  .filter((item) => item.label === service)
                  .reduce((itemSum, item) => itemSum + item.currentPayment, 0),
              0
            );

            return sum + serviceAmounts + rentalAmounts;
          }, 0),

          totalRentals: rentals.reduce(
            (sum, rental) =>
              sum +
              rental.services
                .filter((item) => item.label === service)
                .reduce(
                  (itemSum, item) =>
                    itemSum +
                    item.serviceOptions.reduce(
                      (optionSum, option) =>
                        optionSum +
                        option.items.reduce(
                          (nItemSum, nItem) => nItemSum + nItem.appliedAmount,
                          0
                        ),
                      0
                    ),
                  0
                ),

            0
          ),

          totalCharges: charges?.reduce((sum, charge) => {
            const serviceAmounts =
              charge?.chargeItems
                ?.filter((item) => item.label === service)
                ?.reduce(
                  (itemSum, item) => itemSum + item.price + item.taxAmount,
                  0
                ) || 0;

            // const rentalAmounts = charge.includedRentals.reduce(
            //   (sumRent, rental) =>
            //     sumRent +
            //     rental.services
            //       .filter((item) => item.label === service)
            //       .reduce((itemSum, item) => itemSum + item.currentPayment, 0),
            //   0
            // );

            return sum + serviceAmounts; //+ rentalAmounts;
          }, 0),

          totalBills: bills.reduce(
            (sum, bill) =>
              sum +
              bill.billItems
                .filter((item) => item.itemService === service)
                .reduce((itemSum, item) => itemSum + item.total, 0),

            0
          ),
          totalInvoices: invoices.reduce(
            (sum, invoice) =>
              sum +
              invoice.invoiceItems
                .filter((item) => item.name === service)
                .reduce((itemSum, item) => itemSum + item.total, 0),
            0
          ),

          totalPayments: invoices.reduce((sum, invoice) => {
            const invoicePayments = payments
              .filter((payment) =>
                payment.invoices.some(
                  (paymentInvoice) =>
                    paymentInvoice.invoiceNumber === invoice.invoiceNumber
                )
              )
              .reduce(
                (sumPayment, payment) =>
                  sumPayment +
                  payment.invoices.reduce(
                    (sumInvoice, invoiceItem) =>
                      invoiceItem.invoiceNumber === invoice.invoiceNumber
                        ? sumInvoice + invoiceItem.invoicePaidAmount
                        : sumInvoice,
                    0
                  ),
                0
              );

            return (
              sum +
              invoice.invoiceItems
                .filter((item) => item.name === service)
                .reduce((itemSum, item) => {
                  const invoicePercentage =
                    item.total / invoice.totalInvoiceAmount;
                  return itemSum + invoicePayments * invoicePercentage;
                }, 0)
            );
          }, 0),
        })),
      };
    });
    return result;
  } catch (error) {
    console.error(
      "data source function getRentalComparisonWithAccounting error: ",
      error
    );
  }
};
