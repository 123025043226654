export const dataSourceMapper = ({ name, data }) => ({
  Name: name,
  ConnectionProperties: {
    DataProvider: "JSON",
    ConnectString: "jsondata=" + JSON.stringify(data ?? []),
  },
});

export const mapDataSources = (dataSources = []) =>
  dataSources.map(dataSourceMapper);

export const mapProppedDataSources = (dataSources = []) =>
  dataSources.map(({ name, data }) => ({
    id: name,
    title: name,
    canEdit: false,
    template: {
      Name: name,
      ConnectionProperties: {
        DataProvider: "JSON",
        ConnectString: "jsondata=" + JSON.stringify(data ?? []),
      },
    },
  }));
