import { message, Modal } from "antd";
import { useContext, useState } from "react";

import {
  MondayButton,
  WarningModal,
} from "../../../../../../../commonComponents";
import DegModalContext from "../DegModalContext";
import UploadStep from "../UploadStep/UploadStep";
import { PlusIcon } from "../../../../../../../../assets";
import { useEntriesApi } from "../../../../../../PayrollLive/utils";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import { WarningTriangle } from "../../../../../../../SidebarPages/DynamicView/src";

import "./UploadEntriesModal.scss";

function UploadEntriesModal({ open, onCancel }) {
  const {
    rowToEdit,
    uploading,
    isDarkMode,
    setRowData,
    setUploading,
    stopUploadingButton,
    addActionToRegister,
  } = useContext(DegModalContext);

  const [cancelVisible, setCancelVisible] = useState(false);
  const [uploadedEntries, setUploadedEntries] = useState([]);

  const { loading, postEntries } = useEntriesApi();

  function onAddRecords() {
    message.loading({
      duration: 0,
      key: "onAddRecords",
      content: "Adding entries...",
    });
    const entriesToAdd = uploadedEntries.map((el) => ({
      ...el,
      degId: rowToEdit?.degId,
    }));
    postEntries({
      entries: entriesToAdd,
      onSuccessCallback: () => {
        message.success({
          duration: 3,
          key: "onAddRecords",
          content: "Uploaded Entries added successfully!",
        });
        setRowData((prev) => [...entriesToAdd, ...prev]);
        addActionToRegister({
          type: "new",
          newActions: entriesToAdd,
        });
        onCancel();
      },
      onErrorCallback: (err) => {
        message.success({
          duration: 3,
          key: "onAddRecords",
          content: "There was a problem adding uploaded entries",
        });
        console.log("Error adding uploaded entries: ", err);
      },
    });
  }

  /**
   * @typedef param
   * @property {Array} data List of uploaded records
   * @property {string} type Type of action "add" | "remove"
   *
   * @param {param} param
   */
  function getUploadedData({ type, data }) {
    if (type === "add") {
      setUploadedEntries((prev) => [...prev, ...data]);
    } else if (type === "remove") {
      setUploadedEntries((prev) => prev.filter((el) => data !== el?.uploadId));
    }
  }

  return (
    <Modal
      {...{
        open,
        closable: true,
        destroyOnClose: true,
        closeIcon: <XIcon />,
        title: "Upload Entries",
        ["data-testid"]: "upload-entries-modal",
        onCancel: () => (uploading ? setCancelVisible(true) : onCancel()),
        setVisible: () => (uploading ? setCancelVisible(true) : onCancel()),
        className: `uploadEntriesModal ${
          isDarkMode && "uploadEntriesModalDark"
        }`,
        footer: [
          <MondayButton
            Icon={<XIcon />}
            disabled={loading}
            className="mondayButtonRed"
            data-testid="close-upload-modal-btn"
            onClick={() => (uploading ? setCancelVisible(true) : onCancel())}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            disabled={loading}
            Icon={<PlusIcon />}
            onClick={onAddRecords}
            className="mondayButtonBlue"
          >
            Add Records
          </MondayButton>,
        ],
      }}
    >
      <UploadStep getUploadedData={getUploadedData} />
      {cancelVisible && (
        <WarningModal
          closable={true}
          darkMode={isDarkMode}
          title="Warning Message"
          visible={cancelVisible}
          setVisible={setCancelVisible}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              Do you want to Stop this upload?
            </p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => setCancelVisible(false)}
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (uploading) {
                    stopUploadingButton?.current?.click();
                    setUploading(false);
                    setCancelVisible(false);
                  }
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Modal>
  );
}

export default UploadEntriesModal;
