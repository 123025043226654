import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import weekOfYear from "dayjs/plugin/weekOfYear";

import { COST_ROLES } from "./cellFunctions";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";

dayjs.extend(weekOfYear);

/**
 * @typedef jobsiteMatch
 * @property {string} jobName
 * @property {string} jobAddress
 * @property {string} jobsiteId
 * @property {string} googleSheetLink
 * @property {object | undefined} rates
 */

/**
 * @typedef shiftObjectType
 * @property {string[]} sow
 * @property {number} total
 * @property {string} crewId
 * @property {string} company
 * @property {string} shiftId
 * @property {number} duration
 * @property {boolean} hrShift
 * @property {number} otAmount
 * @property {number} totalOvh
 * @property {string} uploadId
 * @property {string[]} entries
 * @property {number} ovhAmount
 * @property {string} jobsiteId
 * @property {number} regAmount
 * @property {string} shiftType
 * @property {number} workHours
 * @property {number} breakHours
 * @property {number} lunchStart
 * @property {string} employeeId
 * @property {number} ovhRegular
 * @property {string} salaryType
 * @property {string} uploadName
 * @property {string} payrollType
 * @property {number} ovhOvertime
 * @property {string} shiftStatus
 * @property {number} employeeRate
 * @property {string} employeeRole
 * @property {number} firstClockIn
 * @property {number} overtimeHours
 * @property {dayjs.Dayjs} punchDate
 * @property {dayjs.Dayjs} punchTime
 * @property {string} jobsiteAddress
 * @property {number} distanceFromJob
 * @property {string} employeeFullName
 * @property {jobsiteMatch} jobsiteMatch
 * @property {string | undefined} reason
 * @property {string | undefined} geofence
 * @property {string | undefined} companyName
 * @property {object | undefined} employeeType
 * @property {number | undefined} reimbursement
 * @property {{lat: number, lng: number}} punchCoordinates
 */

function roundNumber(int) {
  if (typeof int !== "number") {
    return 0;
  }
  return Number(Number(int || 0).toFixed(2));
}

function groupEntriesInShifts({
  crews,
  jobsites,
  analytics,
  rowData = [],
  filterOvertime = false,
}) {
  let tmpOverheadRows = [];

  for (const entry of rowData) {
    if (["CASH", "1099"].includes(entry?.punchType)) {
      let newShiftEntry = {
        sow: [],
        total: 0,
        otAmount: 0,
        regAmount: 0,
        workHours: 0,
        breakHours: 0,
        ovhRegular: 0,
        jobsiteId: "",
        clockOut: null,
        hrShift: false,
        ovhOvertime: 0,
        payrollType: "",
        reimbursement: 0,
        jobsiteAddress: "",
        degId: entry?.degId,
        duration: undefined,
        geofence: undefined,
        reason: entry?.reason,
        lunchStart: undefined,
        crewId: entry?.crewId,
        shiftId: entry?.entryId,
        employeeType: undefined,
        entries: [entry?.entryId],
        uploadId: entry?.uploadId,
        distanceFromJob: undefined,
        punchCoordinates: undefined,
        punchDate: entry?.punchDate,
        punchTime: entry?.punchDate,
        companyName: entry?.company,
        employeeId: entry?.employeeId,
        salaryType: entry?.salaryType,
        uploadName: entry?.uploadName,
        employeeRate: entry?.employeeRate,
        shiftStatus: entry?.activityStatus,
        firstClockIn: entry?.punchTimeStamp,
        shiftType: `${entry?.punchType} Shift`,
        employeeFullName: entry?.employeeFullName,
        company: entry?.company || entry?.companyName,
        totalOvh: roundNumber(Number(entry?.totalOvh)),
        ovhAmount: roundNumber(Number(entry?.totalOvh)),
        jobsiteMatch: {
          jobName: "",
          jobsiteId: "",
          jobAddress: "",
          googleSheetLink: "",
        },
      };
      tmpOverheadRows.push(newShiftEntry);
    }
  }

  /**@type {shiftObjectType[]} */
  const result = Object.keys(analytics?.employeesHoursPerDay || {})
    .flatMap((employeeId) => {
      let entriesForEmployee = [];

      for (const date in analytics.employeesHoursPerDay[employeeId]) {
        let dayShift = analytics.employeesHoursPerDay[employeeId][date];
        const {
          reason,
          crewId,
          duration,
          geofence,
          uploadId,
          punchDate,
          punchTime,
          salaryType,
          uploadName,
          lunchStart,
          companyName,
          employeeType,
          distanceFromJob,
          scheduleId = null,
          crewTeamId = null,
          crewTeamName = null,
          scheduleDayId = null,
          scheduleAddress = null,
          company = dayShift?.company || dayShift?.companyName,
        } = dayShift;

        for (const shift of dayShift["jobsitesInfo"]) {
          let otToAdd = shift?.otAmount;
          let reimbursement = 0;

          let shiftStatus = (shift["activityStatusesArray"] || []).every(
            (status) => status === "Completed"
          )
            ? "Completed"
            : (shift["activityStatusesArray"] || []).every(
                (status) => status === "Draft"
              )
            ? "Draft"
            : "Pending";

          let tmpEmployee =
            crews?.find(({ employeeId: e }) => e === employeeId) ||
            rowData.find(({ employeeId: e }) => e === employeeId);

          let overheadCondition =
            shift?.hrShift ||
            (!shift?.inJobsiteId && !shift?.outJobsiteId) ||
            COST_ROLES.includes(shift?.employeeRole);

          //calculations for overtime
          if (tmpEmployee?.salaryType === "Salary") {
            otToAdd = 0;
          } else {
            if (
              shift?.payrollType === "Open Shop" ||
              shift?.payrollType === "Certified Payroll"
              // shift?.payrollType === "Prevailing Wage"
            ) {
              if (!overheadCondition) {
                let weekWorked = dayjs(shift?.firstClockIn).week();
                let weekTotal =
                  analytics?.employeeWeekTotals?.[employeeId]?.[weekWorked];
                let weeklyPercentage = 0;
                if (weekTotal?.weeksTypeOvertimeHours) {
                  weeklyPercentage =
                    (shift?.overtimeHours + shift?.workHours) /
                    (weekTotal.weeksTypeOvertimeHours + 40);
                }

                otToAdd = weeklyPercentage * weekTotal?.weekTypesOvertime;
              }
            }
          }

          /**
           * Check if employee have worked on 2 reimbursement jobsites
           * that are close to each other on the same day and get reimbursement
           * only from the first jobsite
           */
          if (shift?.reimbursement) {
            const reimbursementShiftJobs = dayShift?.["jobsitesInfo"].filter(
              ({ reimbursement }) => reimbursement
            );

            const earliestJobWork = reimbursementShiftJobs.sort(
              (a, b) => a?.firstClockIn - b?.firstClockIn
            )?.[0];

            if (earliestJobWork?.entries?.[0] === shift?.entries?.[0]) {
              reimbursement = shift?.employeeRate * 3;
            } else if (
              withinRadius(
                shift.punchCoordinates,
                earliestJobWork.punchCoordinates,
                5280
              ).withinRange
            ) {
              reimbursement = shift?.employeeRate * 3;
            } else {
              reimbursement = 0;
            }
          }
          const clockOutTime = dayjsNY(shift?.firstClockIn)
            .add(
              shift?.workHours + shift?.breakHours + shift?.overtimeHours,
              "hours"
            )
            .valueOf();

          let newEntry = {
            crewId,
            reason,
            geofence,
            duration,
            uploadId,
            punchDate,
            punchTime,
            salaryType,
            uploadName,
            employeeId,
            scheduleId,
            lunchStart,
            crewTeamId,
            shiftStatus,
            companyName,
            employeeType,
            crewTeamName,
            scheduleDayId,
            reimbursement,
            jobsiteId: "",
            scheduleAddress,
            distanceFromJob,
            jobsiteAddress: "",
            degId: shift?.degId,
            ovhOvertime: otToAdd,
            sow: shift?.sow || [],
            clockOut: clockOutTime,
            hrShift: shift?.hrShift,
            breakHours: shift?.breakHours,
            entries: shift?.entries || [],
            company: company || companyName,
            payrollType: shift?.payrollType,
            employeeRate: shift?.employeeRate,
            firstClockIn: shift?.firstClockIn,
            employeeRole: tmpEmployee?.crewPosition,
            shiftId: shift?.entries?.[0] || uuidv4(),
            workHours: roundNumber(shift?.workHours),
            ovhRegular: roundNumber(shift?.regAmount),
            punchCoordinates: shift?.punchCoordinates,
            overtimeHours: roundNumber(shift?.overtimeHours),
            otAmount: roundNumber(overheadCondition ? 0 : otToAdd),
            shiftType: shift?.hrShift ? "HR Shift" : "Regular Shift",
            regAmount: roundNumber(overheadCondition ? 0 : shift?.regAmount),
            totalOvh: roundNumber(
              overheadCondition ? shift?.regAmount + otToAdd : 0
            ),
            ovhAmount: roundNumber(
              overheadCondition ? shift?.regAmount + otToAdd : 0
            ),
            total: overheadCondition
              ? 0
              : shift?.regAmount + otToAdd + reimbursement,
            employeeFullName:
              tmpEmployee?.crewName || tmpEmployee?.employeeFullName || "",
            jobsiteMatch: {
              jobName: "",
              jobsiteId: "",
              jobAddress: "",
              googleSheetLink: "",
            },
          };

          if (shift?.inJobsiteId || shift?.outJobsiteId) {
            let selectedJobsite = jobsites?.find(({ jobsiteId }) =>
              shift?.inJobsiteId
                ? jobsiteId === shift?.inJobsiteId
                : jobsiteId === shift?.outJobsiteId
            );
            if (selectedJobsite) {
              newEntry["jobsiteId"] = selectedJobsite?.jobsiteId;
              newEntry["jobsiteAddress"] = selectedJobsite?.jobAddress;
              newEntry["jobsiteMatch"] = {
                rates: selectedJobsite?.rates,
                jobName: selectedJobsite?.jobName,
                jobsiteId: selectedJobsite?.jobsiteId,
                jobAddress: selectedJobsite?.jobAddress,
                googleSheetLink: selectedJobsite?.googleSheetLink,
              };
            }
          }

          if (filterOvertime) {
            if (newEntry?.otAmount > 0) {
              entriesForEmployee.push(newEntry);
            }
          } else {
            entriesForEmployee.push(newEntry);
          }
        }
      }

      return entriesForEmployee;
    })
    .concat(tmpOverheadRows);

  return result;
}

export default groupEntriesInShifts;
