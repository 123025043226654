import { DrawingManager } from "@react-google-maps/api";

import { Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";

import { useCallback } from "react";
import { useSelector } from "react-redux";

import FenceActions from "./FenceActions";

const DrawingManagerSection = ({
  drawingMode,
  setDrawingMode,
  showDrawingManager,
  options,
  setGeofenceStrucutre,
  geofenceStrucutre,
  setClickedShape,
  shapeInfo,
  setWidowsInfoOpener,
  setGeoFenceInfo,
  setShapeInfo,
  rowData,
  getProgramFieldsField,
  setUnsavedLogs,
  showNavigator,
  closeNavigator,
  show3DotActions,
  pathPoints,
  discardChanges,
  saveEditedValues,
  polygonEditEnd,
}) => {
  const {
    opportunityAddress = "",
    projectAddress = "",
    accountName = "",
  } = rowData || {};
  const checkAddress = opportunityAddress
    ? opportunityAddress
    : projectAddress
    ? projectAddress
    : null;

  const dynamicDefaultTitle = `${checkAddress} - ${accountName}`;
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const drawingManagerButtons = [
    {
      buttonName: "You are in view mode by default",
      type: "",
      isActive: false,
      img: (
        <img
          width="25"
          height="25"
          src="https://img.icons8.com/sf-black-filled/25/visible.png"
          alt="visible"
        />
      ),
      isVisible: true,
    },
    {
      buttonName: "Draw a polygon",
      type: "polygon",
      isActive: false,
      img: (
        <img
          width="25"
          height="25"
          src="https://img.icons8.com/ios-filled/25/polygon.png"
          alt="polygon"
        />
      ),
      isVisible:
        getProgramFieldsField("Polygon") !== undefined &&
        getProgramFieldsField("Polygon")?.allowCreating,
    },
    {
      buttonName: "Draw a circle",
      type: "circle",
      isActive: false,
      img: (
        <img
          width="25"
          height="25"
          src="https://img.icons8.com/ios-filled/25/circled.png"
          alt="circled"
        />
      ),
      isVisible:
        getProgramFieldsField("Circle") !== undefined &&
        getProgramFieldsField("Circle")?.allowCreating,
    },
    {
      buttonName: "Draw a square",
      type: "rectangle",
      isActive: false,
      img: (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="1"
            width="23"
            height="23"
            stroke="black"
            stroke-width="2"
          />
        </svg>
      ),
      isVisible:
        getProgramFieldsField("Square") !== undefined &&
        getProgramFieldsField("Square")?.allowCreating,
    },
  ];

  const onPolygonComplete = useCallback(
    function onPolygonComp(poly) {
      // const polyArray = poly.getPath().getArray();
      // let paths = [];
      // polyArray.forEach(function (path) {
      //   paths.push({ lat: path.lat(), lng: path.lng() });
      // });

      const newGeofence = {
        shapeId: uuidv4(),
        type: "Polygon",
        title: dynamicDefaultTitle,
        description: shapeInfo.description,
        createdAt: Date.now(),
        createdBy: userConfiguration.nameOfUser,
        geoFenceInfo: pathPoints,
      };
      !!setUnsavedLogs &&
        setUnsavedLogs((prev) => [
          ...prev,
          { ...newGeofence, actionType: "Create" },
        ]);
      !!setGeoFenceInfo && setGeoFenceInfo((prev) => [...prev, newGeofence]); // if the geofence is created from modal
      setGeofenceStrucutre((prev) => [...prev, newGeofence]);
      setClickedShape(newGeofence);
      setWidowsInfoOpener("creation");
      setDrawingMode("");
      // poly.setMap(null);
      setShapeInfo({
        title: newGeofence.title,
        description: shapeInfo.description,
      });
      discardChanges();
    },
    [
      shapeInfo,
      userConfiguration.nameOfUser,
      setGeofenceStrucutre,
      setClickedShape,
      setWidowsInfoOpener,
      setDrawingMode,
      rowData,
      pathPoints,
    ]
  );

  const onCircleComplete = useCallback(
    function onCircleComp(circle) {
      const circleCenter = circle.getCenter();
      const circleRadius = circle.getRadius();
      const newGeofence = {
        shapeId: uuidv4(),
        type: "Circle",
        title: dynamicDefaultTitle,
        description: shapeInfo.description,
        createdAt: Date.now(),
        createdBy: userConfiguration.nameOfUser,
        geoFenceInfo: [
          {
            lat: circleCenter.lat(),
            lng: circleCenter.lng(),
            circleRadius: circleRadius,
          },
        ],
      };
      !!setUnsavedLogs &&
        setUnsavedLogs((prev) => [
          ...prev,
          { ...newGeofence, actionType: "Create" },
        ]);
      !!setGeoFenceInfo && setGeoFenceInfo((prev) => [...prev, newGeofence]); // if the geofence is created from modal
      setGeofenceStrucutre((prev) => [...prev, newGeofence]);
      setClickedShape(newGeofence);
      setWidowsInfoOpener("creation");
      setDrawingMode("");
      setShapeInfo({
        title: newGeofence.title,
        description: shapeInfo.description,
      });
      circle.setMap(null);
    },
    [
      shapeInfo,
      userConfiguration.nameOfUser,
      setGeofenceStrucutre,
      setDrawingMode,
      setWidowsInfoOpener,
      setClickedShape,
      rowData,
    ]
  );

  const onRectangleComplete = useCallback(
    function onRectangleComp(rectangle) {
      const bounds = rectangle.getBounds();
      const ne = bounds.getNorthEast(); // North-East corner
      const sw = bounds.getSouthWest(); // South-West corner

      const newGeofence = {
        shapeId: uuidv4(),
        type: "Square",
        title: dynamicDefaultTitle,
        description: shapeInfo.description,
        createdAt: Date.now(),
        createdBy: userConfiguration.nameOfUser,
        geoFenceInfo: {
          bounds: {
            north: ne.lat(),
            south: sw.lat(),
            east: ne.lng(),
            west: sw.lng(),
          },
        },
      };

      !!setUnsavedLogs &&
        setUnsavedLogs((prev) => [
          ...prev,
          { ...newGeofence, actionType: "Create" },
        ]);
      !!setGeoFenceInfo && setGeoFenceInfo((prev) => [...prev, newGeofence]); // if the geofence is created from modal
      setGeofenceStrucutre((prev) => [...prev, newGeofence]);
      setClickedShape(newGeofence);
      setWidowsInfoOpener("creation");
      setDrawingMode("");
      setShapeInfo({
        title: newGeofence.title,
        description: shapeInfo.description,
      });
      rectangle.setMap(null);
    },
    [
      shapeInfo,
      userConfiguration.nameOfUser,
      setGeofenceStrucutre,
      setClickedShape,
      setWidowsInfoOpener,
      setDrawingMode,
      rowData,
    ]
  );

  // const { mobile, desktop } = useResponsive();

  return (
    <>
      <div
        className="custom-drawing-manager"
        data-testid="custom-drawing-manager"
      >
        {showDrawingManager &&
          drawingManagerButtons.map((el) => {
            const isActive = el.type === drawingMode;
            return (
              drawingMode === "" &&
              el.isVisible && (
                <Tooltip
                  key={el.buttonName}
                  title={el.buttonName}
                  onClick={() => {
                    setDrawingMode(el.type);
                    closeNavigator();
                  }}
                  style={
                    isActive ? { backgroundColor: "rgb(216, 216, 216)" } : {}
                  }
                >
                  <button>{el.img}</button>
                </Tooltip>
              )
            );
          })}
        {show3DotActions && geofenceStrucutre?.[0] && drawingMode === "" && (
          <FenceActions {...{ geofenceStrucutre, showNavigator }} />
        )}
        {showDrawingManager && drawingMode !== "" && (
          <button
            onClick={() => {
              discardChanges();
              setDrawingMode("");
            }}
          >
            You are in
            <span className="custom-drawing-manager-text">{drawingMode}</span>
            drawing mode. Click to here to return in view mode!
          </button>
        )}
        {pathPoints.length || polygonEditEnd.length ? (
          <>
            <button
              onClick={
                polygonEditEnd.length ? saveEditedValues : onPolygonComplete
              }
            >
              save
            </button>
            <button
              onClick={() => {
                discardChanges();
                setDrawingMode("");
              }}
            >
              {polygonEditEnd.length
                ? "Double Click the shape to discard"
                : "Discard"}
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
      {showDrawingManager && (
        <DrawingManager
          options={options}
          onPolygonComplete={onPolygonComplete}
          onCircleComplete={onCircleComplete}
          onRectangleComplete={onRectangleComplete}
          data-testid="drawing-manager"
        />
      )}
    </>
  );
};

export default DrawingManagerSection;
