import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Collapse,
  DatePicker,
  Divider,
  Flex,
  Input,
  Layout,
  Select,
  Skeleton,
  Spin,
  Tooltip,
  message,
} from "antd";
import {
  MondayButton,
  MondaySwitch,
  StatusesPanel,
} from "../../../../commonComponents";
import {
  ChangeOrderIcon,
  CloseIcon,
  TickIcon,
} from "../../../../pages/Settings/settingsComponents/Roles/src";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import "./Forecasting.scss";
import { CollapseArrow, NotesIcon, ScopeIcon } from "../../../../../icons";
import { get_ProgressDimensionsByServiceId } from "../../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import {
  calcData,
  checkIfServiceIsOtherScope,
  exportForecastXlSX,
  exportToExcelForecasting,
  firstServiceDay,
  labels,
  swalToast,
} from "../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { checkIfServiceIsHoist } from "../../../Estimations/DataEntryGrid/models/Service";
import { DriveIcon, XIcon } from "../../../Communication/assets";
import { removeHTMLTags } from "../../../../../utils/removeHTMLTags";
import { getServiceLegacyPrice } from "../../Accounting/calculations/servicePrices";
import { formatCurrency } from "../../../utils";
import useTakeOffAccessRights from "../../../Estimations/DataEntryGrid/tools/controllers/useTakeOffAccessRights";
import { BorderedTextCard, InputComponent } from "../../../Fleet/components";
import moment from "moment";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { FieldTimeOutlined, RetweetOutlined } from "@ant-design/icons";
import {
  CalendarDateIcon,
  CalendarDatesIcon,
  ExcelIconWhite,
  HoursIcon,
  MembersIcon,
  TruckIcon,
} from "../../../../../assets";
import { exportXLSX } from "../../../Estimations/DataEntryGrid/tools/exporters";
import { useSelector } from "react-redux";
import { Notes } from "../../../../commonComponents/Notes/Notes";
import DriveNavigate from "../../Accounting/Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/DriveNavigate/DriveNavigate";
import { typeOfWorkColors } from "../../../../Header/forms/Scheduling/DataAgGrid/columnDefs";
import { InfoText } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import ForecastingCards from "./ForecastingCards/ForecastingCards";
import { fetchAllData, filterTables } from "../../../../../utils";
import { CompareIcon } from "../../../Estimations/components/EstVersionsTab/assets";
import ForecastingRaport from "./ForecastingCards/ForecastingRaport/ForecastingRaport";

const WORKTYPES = ["Installation", "Removal"];

const Forecasting = (props) => {
  const { rowData = {}, setRowData = () => {} } = props;
  const { projectId = "", services = {}, projectAddress = "" } = rowData;
  const [schedules, setSchedules] = useState({});
  const [activeViewPanels, setActiveViewPanels] = useState([]);
  const [newServices, setNewServices] = useState(
    JSON.parse(JSON.stringify(services || {}))
  );
  const [forecastingRaport, setForecastingRaport] = useState(false);

  // console.log("newServices", {
  //   newServices,
  //   services,
  //   loading: props?.loading,
  // });

  const onChangeInputs = (
    key,
    value,
    type,
    estimationId,
    serviceIndex,
    elevationIndex,
    pliIndex,
    workType
  ) => {
    if (isNaN(value)) {
      message.warning("Please enter a number!");
      return;
    }

    if (!!props?.comesFromRaport) {
      message.warning("This view is read only!");
      return;
    }

    const newServicesCopy = { ...newServices };

    if (type === "service") {
      if (key === "plannedNrOfHours") {
        const plannedNrOfHours = Number(value);
        const plannedNrOfDays = plannedNrOfHours / 8;
        newServicesCopy[estimationId][serviceIndex][
          workType + "plannedNrOfDays"
        ] = plannedNrOfDays;
        newServicesCopy[estimationId][serviceIndex][
          workType + "plannedNrOfHours"
        ] = plannedNrOfHours;
        const totalElevations = newServicesCopy?.[estimationId]?.[
          serviceIndex
        ]?.["serviceOptions"]?.[0]?.reduce?.((acc, curr) => {
          return acc + (curr?.[workType + key] || 0);
        }, 0);
        if (plannedNrOfHours < totalElevations) {
          message.warning(
            `Not valid! Total elevations is ${totalElevations} and you entered ${value}`
          );
          return;
        }
        setNewServices(newServicesCopy);
        return;
      }
      if (key === "plannedNrOfDays") {
        const plannedNrOfDays = Number(value);
        const plannedNrOfHours = plannedNrOfDays * 8;

        const totalElevations = newServicesCopy?.[estimationId]?.[
          serviceIndex
        ]?.["serviceOptions"]?.[0]?.reduce?.((acc, curr) => {
          return acc + (curr?.[workType + "plannedNrOfDays"] || 0);
        }, 0);

        if (plannedNrOfDays < totalElevations) {
          message.warning(
            `Not valid! Total elevations is ${totalElevations} and you entered ${value}`
          );
          return;
        }

        newServicesCopy[estimationId][serviceIndex][
          workType + "plannedNrOfDays"
        ] = plannedNrOfDays;

        newServicesCopy[estimationId][serviceIndex][
          workType + "plannedNrOfHours"
        ] = plannedNrOfHours;
      }

      const totalElevations = newServicesCopy?.[estimationId]?.[serviceIndex]?.[
        "serviceOptions"
      ]?.[0]?.reduce?.((acc, curr) => {
        return acc + (curr?.[workType + key] || 0);
      }, 0);
      if (Number(value) < totalElevations) {
        message.warning(
          `Not valid! Total elevations is ${totalElevations} and you entered ${value}`
        );
        return;
      }
      newServicesCopy[estimationId][serviceIndex][workType + key] =
        Number(value);
    } else if (type === "elevation") {
      if (key === "plannedNrOfHours") {
        const plannedNrOfHours = Number(value);
        const plannedNrOfDays = plannedNrOfHours / 8;

        newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ][workType + "plannedNrOfDays"] = plannedNrOfDays;
        newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ][workType + "plannedNrOfHours"] = plannedNrOfHours;
        newServicesCopy[estimationId][serviceIndex][
          workType + "plannedNrOfDays"
        ] = newServicesCopy[estimationId][serviceIndex][
          "serviceOptions"
        ][0].reduce((acc, curr, index) => {
          return acc + (curr?.[workType + "plannedNrOfDays"] || 0);
        }, 0);
        newServicesCopy[estimationId][serviceIndex][workType + key] =
          newServicesCopy[estimationId][serviceIndex][
            "serviceOptions"
          ]?.[0]?.reduce((acc, curr) => {
            return acc + (curr?.[workType + key] || 0);
          }, 0);

        const totalPlis = newServicesCopy?.[estimationId]?.[serviceIndex]?.[
          "serviceOptions"
        ]?.[0]?.[elevationIndex]?.items?.reduce?.((acc, curr) => {
          return acc + (curr?.[workType + key] || 0);
        }, 0);
        if (totalPlis > plannedNrOfHours) {
          message.warning(
            `Not valid! Total plis is ${totalPlis} and you entered ${value}`
          );
          return;
        }

        setNewServices(newServicesCopy);
        return;
      }
      if (key === "plannedNrOfDays") {
        const plannedNrOfDays = Number(value);
        const plannedNrOfHours = plannedNrOfDays * 8;
        newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ][workType + "plannedNrOfDays"] = plannedNrOfDays;
        newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ][workType + "plannedNrOfHours"] = plannedNrOfHours;
        newServicesCopy[estimationId][serviceIndex][
          workType + "plannedNrOfHours"
        ] = newServicesCopy[estimationId][serviceIndex][
          "serviceOptions"
        ][0].reduce((acc, curr) => {
          return acc + (curr?.[workType + "plannedNrOfHours"] || 0);
        }, 0);
      }
      const totalElevations = newServicesCopy?.[estimationId]?.[serviceIndex]?.[
        "serviceOptions"
      ]?.[0]?.reduce?.((acc, curr, index) => {
        return (
          acc +
          (index === elevationIndex
            ? Number(value)
            : curr?.[workType + key] || 0)
        );
      }, 0);
      const totalPlis = newServicesCopy?.[estimationId]?.[serviceIndex]?.[
        "serviceOptions"
      ]?.[0]?.[elevationIndex]?.items?.reduce?.((acc, curr) => {
        return acc + (curr?.[workType + key] || 0);
      }, 0);

      newServicesCopy[estimationId][serviceIndex][workType + key] =
        totalElevations;

      if (totalElevations < totalPlis) {
        message.warning(
          `Not valid! Total plis is ${totalPlis} and you entered ${value}`
        );
        return;
      }

      newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
        elevationIndex
      ][workType + key] = Number(value);
    } else if (type === "pli") {
      if (key === "plannedNrOfHours") {
        const plannedNrOfHours = Number(value);
        const plannedNrOfDays = plannedNrOfHours / 8;
        newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ]["items"][pliIndex][workType + "plannedNrOfDays"] = plannedNrOfDays;
        newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ]["items"][pliIndex][workType + "plannedNrOfHours"] = plannedNrOfHours;
        newServices[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ][workType + "plannedNrOfDays"] = newServices[estimationId][
          serviceIndex
        ]["serviceOptions"][0][elevationIndex]["items"].reduce((acc, curr) => {
          return acc + (curr?.[workType + "plannedNrOfDays"] || 0);
        }, 0);
        newServices[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ][key] = newServices[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ]["items"].reduce((acc, curr) => {
          return acc + (curr?.[workType + key] || 0);
        }, 0);
        newServices[estimationId][serviceIndex][workType + "plannedNrOfDays"] =
          newServices[estimationId][serviceIndex]["serviceOptions"][0].reduce(
            (acc, curr) => {
              return acc + (curr?.[workType + "plannedNrOfDays"] || 0);
            },
            0
          );
        newServices[estimationId][serviceIndex][workType + key] = newServices[
          estimationId
        ][serviceIndex]["serviceOptions"][0].reduce((acc, curr) => {
          return acc + (curr?.[workType + key] || 0);
        }, 0);

        setNewServices(newServicesCopy);
        return;
      }
      if (key === "plannedNrOfDays") {
        const plannedNrOfDays = Number(value);
        const plannedNrOfHours = plannedNrOfDays * 8;
        newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ]["items"][pliIndex][workType + "plannedNrOfHours"] = plannedNrOfHours;
        newServices[estimationId][serviceIndex]["serviceOptions"][0][
          elevationIndex
        ][workType + "plannedNrOfHours"] = newServices?.[estimationId]?.[
          serviceIndex
        ]?.["serviceOptions"]?.[0]?.[elevationIndex]?.["items"]?.reduce?.(
          (acc, curr, index) => {
            return acc + (curr?.[workType + "plannedNrOfHours"] || 0);
          },
          0
        );
        newServices[estimationId][serviceIndex][workType + "plannedNrOfHours"] =
          newServices[estimationId][serviceIndex]["serviceOptions"][0].reduce(
            (acc, curr) => {
              return acc + (curr?.[workType + "plannedNrOfHours"] || 0);
            },
            0
          );
      }
      const totalPli =
        newServicesCopy?.[estimationId]?.[serviceIndex]?.[
          "serviceOptions"
        ]?.[0]?.[elevationIndex]?.["items"]?.reduce?.((acc, curr, index) => {
          return (
            acc +
            (index === pliIndex ? Number(value) : curr?.[workType + key] || 0)
          );
        }, 0) || 0;

      newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
        elevationIndex
      ][workType + key] = totalPli;
      const totalElevations = newServicesCopy?.[estimationId]?.[serviceIndex]?.[
        "serviceOptions"
      ]?.[0]?.reduce((acc, curr, index) => {
        return (
          acc +
          (index === elevationIndex ? totalPli : curr?.[workType + key] || 0)
        );
      }, 0);
      newServicesCopy[estimationId][serviceIndex][workType + key] =
        totalElevations;

      newServicesCopy[estimationId][serviceIndex]["serviceOptions"][0][
        elevationIndex
      ]["items"][pliIndex][workType + key] = Number(value);
    }

    setNewServices(newServicesCopy);
  };

  const saveChanges = async () => {
    const srv = Object.values(newServices).flat();

    try {
      message.loading("Saving changes...");
      const put = await API.put("projects", `/projects/${projectId}`, {
        body: { services: newServices },
      });

      setRowData({ ...rowData, services: newServices });

      message.destroy();

      swalToast({
        icon: "success",
        title: "Changes saved!",
        text: "All changes are saved!",
        position: "bottom-end",
        timer: 2500,
        showConfirmButton: false,
      });
    } catch (err) {
      console.error("err", err);
      message.error("Something went wrong!");
    }
  };

  const onClose = () => {
    setForecastingRaport(false);
    message.destroy();
  };

  const exportToExcel = () => {
    exportForecastXlSX(rowData, newServices);
  };

  useEffect(() => {
    if (!props?.loadingRaport) {
      setNewServices(JSON.parse(JSON.stringify(services || {})));
    }
  }, [props?.loadingRaport]);

  // console.log("newServices", { newServices });

  return (
    <div className="forcastingPage">
      {props?.header && (
        <span
          style={{
            fontSize: "1.5 em",
            marginBottom: "1rem",
            padding: "0.5rem",
            ...props?.additionalStyle,
          }}
        >
          {props?.header}
        </span>
      )}

      <div
        className="forecastingContent"
        style={{
          height: props?.comesFromRaport ? "" : 743,
        }}
      >
        <Skeleton
          active
          loading={props?.loadingRaport}
          style={{ width: "100%" }}
        >
          <Spin
            spinning={
              typeof props?.loadingRaport === "boolean"
                ? props?.loadingRaport
                : false
            }
            tip="Loading..."
          >
            <div className="workTypesCards">
              {WORKTYPES.map((workType) => {
                return <ForecastingCards {...{ workType, newServices }} />;
              })}
            </div>
            <div className="workTypesLabels">
              {WORKTYPES.map((workType) => {
                return <b>{workType}</b>;
              })}
            </div>
            <div
              className="forecastingCollapses"
              style={{
                height: props?.comesFromRaport ? "" : 585,
              }}
            >
              {Object.entries(newServices || {})?.map(
                ([key, value], estIndex) => {
                  return (
                    <Collapse
                      onChange={(e) => {
                        if (!e?.length) {
                          setActiveViewPanels([]);
                        } else {
                          return;
                        }
                      }}
                      key={estIndex}
                      bordered={false}
                      destroyInactivePanel={true}
                      forceRender={false}
                      defaultActiveKey={activeViewPanels}
                      expandIcon={({ isActive }) => (
                        <CollapseArrow
                          style={{
                            transform: isActive ? "rotate(90deg)" : "",
                          }}
                          height={12}
                          width={12}
                        />
                      )}
                    >
                      <Collapse.Panel
                        key={key}
                        header={
                          <div className="estimationForecastContent">
                            <div className="estimationForecast">
                              <b>
                                Estimation{" "}
                                {value?.[0]?.quickbooksNumber ||
                                  value?.[0]?.estimationNumber ||
                                  estIndex + 1}
                              </b>
                              <Divider type="vertical" />
                              <b
                                className="expandAll"
                                onClick={() => {
                                  setActiveViewPanels(key);
                                }}
                              >
                                Expand All
                              </b>
                            </div>
                            <div
                              className="forecastingEstimateExtra"
                              style={{
                                paddingRight: 420,
                              }}
                            >
                              {WORKTYPES.map((workType, index) => {
                                return (
                                  <div className="forecastingEstimateExtra__item">
                                    <div className="forecastingEstimateExtra__item">
                                      <CalendarDateIcon
                                        width={15}
                                        height={15}
                                      />
                                      <b>{firstServiceDay(value, workType)}</b>
                                    </div>
                                    <Divider type="vertical" />
                                    <div className="forecastingEstimateExtra__item">
                                      <CalendarDatesIcon
                                        width={15}
                                        height={15}
                                      />
                                      <b>
                                        {calcData(
                                          value,
                                          workType + "plannedNrOfDays"
                                        )}
                                      </b>
                                    </div>
                                    <Divider type="vertical" />
                                    <div className="forecastingEstimateExtra__item">
                                      <HoursIcon width={15} height={15} />
                                      <b>
                                        {calcData(
                                          value,
                                          workType + "plannedNrOfHours"
                                        )}
                                      </b>
                                    </div>
                                    <Divider type="vertical" />
                                    <div className="forecastingEstimateExtra__item">
                                      <MembersIcon width={15} height={15} />
                                      <b>
                                        {calcData(
                                          value,
                                          workType + "plannedNrOfCrews"
                                        )}
                                      </b>
                                    </div>
                                    <Divider type="vertical" />
                                    <div className="forecastingEstimateExtra__item">
                                      <TruckIcon width={15} height={15} />
                                      <b>
                                        {calcData(
                                          value,
                                          workType + "plannedNrOfTrucks"
                                        )}
                                      </b>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        }
                      >
                        {value?.map((item, serviceIndex) => {
                          return (
                            <Collapse
                              expandIcon={({ isActive }) => (
                                <CollapseArrow
                                  style={{
                                    transform: isActive ? "rotate(90deg)" : "",
                                  }}
                                  height={12}
                                  width={12}
                                />
                              )}
                              key={serviceIndex}
                              defaultActiveKey={activeViewPanels}
                              bordered={false}
                              destroyInactivePanel={true}
                              forceRender={false}
                            >
                              <Collapse.Panel
                                key={key}
                                header={
                                  <div className="estimationForecastContent">
                                    <div className="estimationForecast">
                                      <span>{item?.label}</span>
                                    </div>
                                    <div
                                      className="forecastingEstimateExtra"
                                      style={{ paddingRight: 194 }}
                                    >
                                      {WORKTYPES.map((workType, workIndex) => {
                                        return (
                                          <div
                                            className="forecastingEstimateExtra__item"
                                            style={{
                                              marginRight:
                                                workIndex === 1 ? 18 : "",
                                            }}
                                          >
                                            <div className="forecastingEstimateExtra__item">
                                              <CalendarDateIcon
                                                width={15}
                                                height={15}
                                              />
                                              <DatePicker
                                                suffixIcon={null}
                                                value={
                                                  item?.[
                                                    workType +
                                                      "potentialStartDate"
                                                  ]
                                                    ? dayjsNY(
                                                        item?.[
                                                          workType +
                                                            "potentialStartDate"
                                                        ]
                                                      )
                                                    : null
                                                }
                                                className="forecasting-container__extra__total__date-picker"
                                                format={"MM/DD/YYYY"}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                                allowClear={false}
                                                onChange={(date) => {
                                                  if (!date) return;
                                                  onChangeInputs(
                                                    "potentialStartDate",
                                                    dayjsNY(date).valueOf(),
                                                    "service",
                                                    key,
                                                    serviceIndex,
                                                    null,
                                                    null,
                                                    workType
                                                  );
                                                }}
                                              />
                                            </div>
                                            <Divider type="vertical" />
                                            <div className="forecastingEstimateExtra__item">
                                              <CalendarDatesIcon
                                                width={15}
                                                height={15}
                                              />
                                              <Input
                                                value={
                                                  item?.[
                                                    workType + "plannedNrOfDays"
                                                  ] || 0
                                                }
                                                onChange={(e) => {
                                                  onChangeInputs(
                                                    "plannedNrOfDays",
                                                    e.target.value,
                                                    "service",
                                                    key,
                                                    serviceIndex,
                                                    null,
                                                    null,
                                                    workType
                                                  );
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              />
                                            </div>
                                            <Divider type="vertical" />
                                            <div className="forecastingEstimateExtra__item">
                                              <HoursIcon
                                                width={15}
                                                height={15}
                                              />
                                              <Input
                                                value={
                                                  item?.[
                                                    workType +
                                                      "plannedNrOfHours"
                                                  ] || 0
                                                }
                                                placeholder="Hours..."
                                                onChange={(e) => {
                                                  onChangeInputs(
                                                    "plannedNrOfHours",
                                                    e.target.value,
                                                    "service",
                                                    key,
                                                    serviceIndex,
                                                    null,
                                                    null,
                                                    workType
                                                  );
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              />
                                            </div>
                                            <Divider type="vertical" />
                                            <div className="forecastingEstimateExtra__item">
                                              <MembersIcon
                                                width={15}
                                                height={15}
                                              />
                                              <Input
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                                value={
                                                  item?.[
                                                    workType +
                                                      "plannedNrOfCrews"
                                                  ] || 0
                                                }
                                                onChange={(e) => {
                                                  onChangeInputs(
                                                    "plannedNrOfCrews",
                                                    e.target.value,
                                                    "service",
                                                    key,
                                                    serviceIndex,
                                                    null,
                                                    null,
                                                    workType
                                                  );
                                                }}
                                              />
                                            </div>
                                            <Divider type="vertical" />
                                            <div className="forecastingEstimateExtra__item">
                                              <TruckIcon
                                                width={15}
                                                height={15}
                                              />
                                              <Input
                                                value={
                                                  item?.[
                                                    workType +
                                                      "plannedNrOfTrucks"
                                                  ] || 0
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                                onChange={(e) => {
                                                  onChangeInputs(
                                                    "plannedNrOfTrucks",
                                                    e.target.value,
                                                    "service",
                                                    key,
                                                    serviceIndex,
                                                    null,
                                                    null,
                                                    workType
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                }
                              >
                                {item?.serviceAddons?.length > 0 &&
                                  item?.serviceAddons?.map(
                                    (addon, addonIndex) => {
                                      return (
                                        <Collapse
                                          key={addonIndex}
                                          bordered={false}
                                          destroyInactivePanel={true}
                                          forceRender={false}
                                          defaultActiveKey={activeViewPanels}
                                        >
                                          <Collapse.Panel
                                            key={addonIndex}
                                            header={
                                              <div>
                                                <span>
                                                  Service Addon -{" "}
                                                  <b>{addon?.name}</b>
                                                </span>
                                              </div>
                                            }
                                            showArrow={false}
                                          />
                                        </Collapse>
                                      );
                                    }
                                  )}
                                {item?.serviceOptions?.[0]?.map(
                                  (option, eleIndex) => {
                                    return (
                                      <Collapse
                                        key={eleIndex}
                                        bordered={false}
                                        destroyInactivePanel={true}
                                        forceRender={false}
                                        defaultActiveKey={activeViewPanels}
                                        expandIcon={({ isActive }) => (
                                          <CollapseArrow
                                            style={{
                                              transform: isActive
                                                ? "rotate(90deg)"
                                                : "",
                                            }}
                                            height={12}
                                            width={12}
                                            fill="black"
                                          />
                                        )}
                                      >
                                        <Collapse.Panel
                                          key={key}
                                          header={
                                            <div className="estimationForecastContent">
                                              <div className="estimationForecast">
                                                <span className="elevationLabelForecasting">
                                                  {option?.elevationLabel +
                                                    " " +
                                                    option?.elevationId}
                                                </span>
                                              </div>
                                              <div
                                                className="forecastingEstimateExtra"
                                                style={{ paddingRight: 184 }}
                                              >
                                                {WORKTYPES.map(
                                                  (workType, index) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          paddingRight:
                                                            index === 0
                                                              ? 143
                                                              : 18,
                                                        }}
                                                        className="forecastingEstimateExtra__item"
                                                      >
                                                        <div className="forecastingEstimateExtra__item">
                                                          <CalendarDatesIcon
                                                            width={15}
                                                            height={15}
                                                          />
                                                          <Input
                                                            value={
                                                              option?.[
                                                                workType +
                                                                  "plannedNrOfDays"
                                                              ] || 0
                                                            }
                                                            onChange={(e) => {
                                                              onChangeInputs(
                                                                "plannedNrOfDays",
                                                                e.target.value,
                                                                "elevation",
                                                                key,
                                                                serviceIndex,
                                                                eleIndex,
                                                                null,
                                                                workType
                                                              );
                                                            }}
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                            }}
                                                          />
                                                        </div>
                                                        <Divider type="vertical" />
                                                        <div className="forecastingEstimateExtra__item">
                                                          <HoursIcon
                                                            width={15}
                                                            height={15}
                                                          />
                                                          <Input
                                                            value={
                                                              option?.[
                                                                workType +
                                                                  "plannedNrOfHours"
                                                              ] || 0
                                                            }
                                                            placeholder="Hours..."
                                                            onChange={(e) => {
                                                              onChangeInputs(
                                                                "plannedNrOfHours",
                                                                e.target.value,
                                                                "elevation",
                                                                key,
                                                                serviceIndex,
                                                                eleIndex,
                                                                null,
                                                                workType
                                                              );
                                                            }}
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                            }}
                                                          />
                                                        </div>
                                                        <Divider type="vertical" />
                                                        <div className="forecastingEstimateExtra__item">
                                                          <MembersIcon
                                                            width={15}
                                                            height={15}
                                                          />
                                                          <Input
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                            }}
                                                            value={
                                                              option?.[
                                                                workType +
                                                                  "plannedNrOfCrews"
                                                              ] || 0
                                                            }
                                                            onChange={(e) => {
                                                              onChangeInputs(
                                                                "plannedNrOfCrews",
                                                                e.target.value,
                                                                "elevation",
                                                                key,
                                                                serviceIndex,
                                                                eleIndex,
                                                                null,
                                                                workType
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                        <Divider type="vertical" />
                                                        <div className="forecastingEstimateExtra__item">
                                                          <TruckIcon
                                                            width={15}
                                                            height={15}
                                                          />
                                                          <Input
                                                            value={
                                                              option?.[
                                                                workType +
                                                                  "plannedNrOfTrucks"
                                                              ] || 0
                                                            }
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                            }}
                                                            onChange={(e) => {
                                                              onChangeInputs(
                                                                "plannedNrOfTrucks",
                                                                e.target.value,
                                                                "elevation",
                                                                key,
                                                                serviceIndex,
                                                                eleIndex,
                                                                null,
                                                                workType
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          }
                                        >
                                          {option?.items?.map(
                                            (pli, pliIndex) => {
                                              return (
                                                <Collapse
                                                  key={pliIndex}
                                                  bordered={false}
                                                  destroyInactivePanel={true}
                                                  forceRender={false}
                                                  defaultActiveKey={
                                                    activeViewPanels
                                                  }
                                                  expandIcon={({
                                                    isActive,
                                                  }) => (
                                                    <CollapseArrow
                                                      style={{
                                                        transform: isActive
                                                          ? "rotate(90deg)"
                                                          : "",
                                                      }}
                                                      height={12}
                                                      width={12}
                                                      fill="black"
                                                    />
                                                  )}
                                                >
                                                  <Collapse.Panel
                                                    key={key}
                                                    showArrow={false}
                                                    header={
                                                      <div className="estimationForecastContent">
                                                        <div
                                                          className="estimationForecast"
                                                          style={{
                                                            display: "flex",
                                                            gap: 6,
                                                          }}
                                                        >
                                                          <span>
                                                            {`${
                                                              checkIfServiceIsHoist(
                                                                item
                                                              )
                                                                ? "Floor"
                                                                : "Pli"
                                                            }` +
                                                              " " +
                                                              pli?.id}{" "}
                                                            {checkIfServiceIsHoist(
                                                              item
                                                            ) &&
                                                              option?.jumps
                                                                ?.length >
                                                                0 && (
                                                                <span
                                                                  style={{
                                                                    fontWeight: 600,
                                                                  }}
                                                                >
                                                                  {option?.jumps?.find(
                                                                    (floors) =>
                                                                      floors?.floors?.some(
                                                                        (
                                                                          floor
                                                                        ) =>
                                                                          floor?.floor ===
                                                                          pli?.floor
                                                                      )
                                                                  )?.name || ""}
                                                                </span>
                                                              )}
                                                          </span>
                                                          {pli?.length && (
                                                            <b>
                                                              {"L:" +
                                                                " " +
                                                                pli?.length}
                                                            </b>
                                                          )}
                                                          {pli?.height && (
                                                            <b>
                                                              {"H:" +
                                                                " " +
                                                                pli?.height}
                                                            </b>
                                                          )}
                                                          {pli?.width && (
                                                            <b>
                                                              {"W:" +
                                                                " " +
                                                                pli?.width}
                                                            </b>
                                                          )}
                                                        </div>
                                                        <div
                                                          className="forecastingEstimateExtra"
                                                          style={{
                                                            paddingRight: 175,
                                                          }}
                                                        >
                                                          {WORKTYPES.map(
                                                            (
                                                              workType,
                                                              index
                                                            ) => {
                                                              return (
                                                                <div
                                                                  style={{
                                                                    paddingRight:
                                                                      index ===
                                                                      0
                                                                        ? 143
                                                                        : 18,
                                                                  }}
                                                                  className="forecastingEstimateExtra__item"
                                                                >
                                                                  <div className="forecastingEstimateExtra__item">
                                                                    <CalendarDatesIcon
                                                                      width={15}
                                                                      height={
                                                                        15
                                                                      }
                                                                    />
                                                                    <Input
                                                                      value={
                                                                        pli?.[
                                                                          workType +
                                                                            "plannedNrOfDays"
                                                                        ] || 0
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        onChangeInputs(
                                                                          "plannedNrOfDays",
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          "pli",
                                                                          key,
                                                                          serviceIndex,
                                                                          eleIndex,
                                                                          pliIndex,
                                                                          workType
                                                                        );
                                                                      }}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <Divider type="vertical" />
                                                                  <div className="forecastingEstimateExtra__item">
                                                                    <HoursIcon
                                                                      width={15}
                                                                      height={
                                                                        15
                                                                      }
                                                                    />
                                                                    <Input
                                                                      value={
                                                                        pli?.[
                                                                          workType +
                                                                            "plannedNrOfHours"
                                                                        ] || 0
                                                                      }
                                                                      placeholder="Hours..."
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        onChangeInputs(
                                                                          "plannedNrOfHours",
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          "pli",
                                                                          key,
                                                                          serviceIndex,
                                                                          eleIndex,
                                                                          pliIndex,
                                                                          workType
                                                                        );
                                                                      }}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <Divider type="vertical" />
                                                                  <div className="forecastingEstimateExtra__item">
                                                                    <MembersIcon
                                                                      width={15}
                                                                      height={
                                                                        15
                                                                      }
                                                                    />
                                                                    <Input
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                      }}
                                                                      value={
                                                                        pli?.[
                                                                          workType +
                                                                            "plannedNrOfCrews"
                                                                        ] || 0
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        onChangeInputs(
                                                                          "plannedNrOfCrews",
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          "pli",
                                                                          key,
                                                                          serviceIndex,
                                                                          eleIndex,
                                                                          pliIndex,
                                                                          workType
                                                                        );
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <Divider type="vertical" />
                                                                  <div className="forecastingEstimateExtra__item">
                                                                    <TruckIcon
                                                                      width={15}
                                                                      height={
                                                                        15
                                                                      }
                                                                    />
                                                                    <Input
                                                                      value={
                                                                        pli?.[
                                                                          workType +
                                                                            "plannedNrOfTrucks"
                                                                        ] || 0
                                                                      }
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                      }}
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        onChangeInputs(
                                                                          "plannedNrOfTrucks",
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          "pli",
                                                                          key,
                                                                          serviceIndex,
                                                                          eleIndex,
                                                                          pliIndex,
                                                                          workType
                                                                        );
                                                                      }}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                    }
                                                  />
                                                </Collapse>
                                              );
                                            }
                                          )}
                                        </Collapse.Panel>
                                      </Collapse>
                                    );
                                  }
                                )}
                              </Collapse.Panel>
                            </Collapse>
                          );
                        })}
                      </Collapse.Panel>
                    </Collapse>
                  );
                }
              )}
            </div>
          </Spin>
        </Skeleton>
      </div>

      {!props?.comesFromRaport && (
        <div className="forecastingFooter">
          <MondayButton
            onClick={() => {
              setNewServices(JSON.parse(JSON.stringify(services || {})));
              swalToast({
                icon: "success",
                title: "Changes reverted!",
                text: "All changes are canceled!",
                position: "bottom-end",
                timer: 2500,
                showConfirmButton: false,
              });
            }}
            {...{
              className: "mondayButtonRed",
              Icon: <XIcon />,
            }}
          >
            Cancel Changes
          </MondayButton>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 7,
            }}
          >
            <MondayButton
              {...{
                Icon: <CompareIcon />,
                className: "mondayButtonPrismarine",
                onClick: () => setForecastingRaport(true),
              }}
            >
              Compare with Scheduling
            </MondayButton>
            <MondayButton
              onClick={exportToExcel}
              {...{
                className: "mondayButtonBlue",
                Icon: <ExcelIconWhite />,
              }}
            >
              Export to Excel
            </MondayButton>
            {/* <Select
            placeholder="Export to Excel"
            onSelect={(e) => {
              exportToExcel(e);
            }}
          >
            <Select.Option value="Installation">Installation</Select.Option>
            <Select.Option value="Removal">Removal</Select.Option>
          </Select> */}
            <MondayButton
              onClick={saveChanges}
              disabled={
                JSON.stringify(services) === JSON.stringify(newServices)
              }
              {...{
                className: "mondayButtonGreen",
                Icon: <TickIcon />,
              }}
            >
              Save Changes
            </MondayButton>
          </div>
        </div>
      )}
      {forecastingRaport && (
        <ForecastingRaport
          {...{
            rowData,
            forecastingRaport,
            setForecastingRaport,
            schedules,
            setSchedules,
            onClose,
            projectServices: newServices || {},
          }}
        />
      )}
    </div>
  );
};

export default Forecasting;
