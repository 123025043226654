import {
  useMemo,
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { message, Popover, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import {
  matchIdleData,
  formatDuration,
  FleetsLiveContext,
  loadLivePreference,
  findPaginationSize,
  saveLocalPreferences,
} from "../../utils";
import { filterTables } from "src/utils";
import { IdleDurationPicker } from "./components";
import { columnDefs, gridFilters, columnKeys } from "./gridData";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { WarningModal, MondayButton } from "src/components/commonComponents";
import PayrollLayout from "src/components/pages/Payroll/Layout/PayrollLayout";
import { ExcelIconWhite } from "src/assets";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "../LiveLocationsView/LiveLocationsView.scss";

/**
 * @typedef {"gt"|"lt"|"eq"} Equalizer
 */

/**
 * @typedef {"m"|"h"|"d"} DurationUnit
 */

/**
 * @typedef SelectedDuration
 * @property {number} duration - Duration = -1 means that we want all the events
 * @property {Equalizer} equalizer
 * @property {DurationUnit} unit
 */

/**
 * @typedef ChangeLog
 * @property {string} field
 * @property {string} value
 * @property {boolean} discarded
 * @property {number[]} eventRange
 */

function LiveIdleView() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    allStops,
    geofences,
    locations,
    isWritable,
    selectedDate,
    globalAudits,
    changeMenuKey,
    broadcastUpdates,
    plannedTripsForDate,
    externalUpdateAudit,
    plannedTripsForTruck,
  } = useContext(FleetsLiveContext);

  const [gridApi, setGridApi] = useState();
  const [reasons, setReasons] = useState([]);
  const [reasonField, setReasonField] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [discardWarning, setDiscardWarning] = useState();
  const [selectedDuration, setSelectedDuration] = useState(
    /** @type {SelectedDuration} */ ({
      duration: loadLivePreference("liveIdleDuration"),
      equalizer: loadLivePreference("liveIdleOperation"),
      unit: loadLivePreference("liveIdleDurationUnit"),
    })
  );

  const onCellValueChanged = useCallback(
    async (event) => {
      if (event.source !== "edit") {
        return;
      }

      const { data, node } = event;
      const value = event.newValue;
      const oldValue = event.oldValue;
      const field = event.column.colId;

      const { reason, fleetId, beginDate, endDate } = data;

      let auditForVehicle = globalAudits?.["alertChanges"]?.[fleetId];
      if (!auditForVehicle) {
        auditForVehicle = {
          changed: {},
          discarded: [],
          linkedStops: [],
          stopReasons: [],
          discardedStops: [],
          idleViewChanges: [],
          auditedActivities: [],
          afterHoursChanges: [],
          auditStatus: "PENDING",
        };
      }

      let tmpNewAlertChanges;

      /** @type {Array<ChangeLog>} */
      let existingChanges = auditForVehicle["idleViewChanges"] || [];

      if (field === "reason") {
        auditForVehicle["stopReasons"] = [
          ...auditForVehicle["stopReasons"],
          { beginDate, endDate, reason, isDefault: true },
        ];
      } else {
        const thisChangeLog = {
          field,
          value,
          discarded: false,
          eventRange: [beginDate, endDate],
        };

        let changeIndex = existingChanges.findIndex(
          ({ eventRange, field: f }) =>
            eventRange?.length &&
            eventRange?.[0] === thisChangeLog["eventRange"]?.[0] &&
            eventRange?.[1] === thisChangeLog["eventRange"]?.[1] &&
            f === field
        );

        if (changeIndex === -1) {
          existingChanges.splice(existingChanges.length, 0, thisChangeLog);
        } else {
          existingChanges.splice(changeIndex, 1, thisChangeLog);
        }

        auditForVehicle["idleViewChanges"] = [...existingChanges];
      }

      tmpNewAlertChanges = {
        alertChanges: {
          ...(globalAudits["alertChanges"] || {}),
          [fleetId]: auditForVehicle,
        },
      };

      message.loading({
        content: "Saving changes...",
        key: "reasonUpdate",
        duration: 0,
      });

      try {
        let audit = undefined;
        if (!globalAudits?.auditId) {
          await API.post("fleetAudits", "/fleetAudits", {
            body: {
              auditDate: dayjsNY(selectedDate).startOf("day").format(),
              ...tmpNewAlertChanges,
            },
          }).then((res) => {
            audit = res;
          });
        } else {
          await API.put(
            "fleetAudits",
            `/fleetAudits/${globalAudits?.auditId}`,
            {
              body: {
                ...tmpNewAlertChanges,
              },
            }
          ).then(() => {
            audit = {
              ...globalAudits,
              ...tmpNewAlertChanges,
            };
          });
        }

        message.success({
          content: "Changes saved successfully!",
          key: "reasonUpdate",
        });

        broadcastUpdates({ request: "audit-change", audit });
        externalUpdateAudit(audit);
      } catch (err) {
        message.error({
          content: "Something went wrong while saving changes",
          key: "reasonUpdate",
        });
        console.log("Error updating audit: ", err);
        node.updateData({ ...data, field: oldValue });
      }
    },
    [globalAudits, selectedDate]
  );

  const stopsData = useMemo(() => {
    return matchIdleData({
      allStops,
      geofences,
      locations,
      globalAudits,
      selectedDate,
      plannedTripsForDate,
      plannedTripsForTruck,
    });
  }, [
    allStops,
    geofences,
    locations,
    globalAudits,
    selectedDate,
    plannedTripsForDate,
    plannedTripsForTruck,
  ]);

  const rowData = useMemo(() => {
    let minutesDuration = selectedDuration.duration;

    switch (selectedDuration.unit) {
      case "h":
        minutesDuration = minutesDuration * 60;
        break;
      case "d":
        minutesDuration = minutesDuration * 1440;
        break;
    }

    switch (selectedDuration.equalizer) {
      case "gt":
        return stopsData.filter(({ duration }) => duration >= minutesDuration);
      case "lt":
        return stopsData.filter(({ duration }) => duration <= minutesDuration);
      default:
        return stopsData.filter(({ duration }) => duration === minutesDuration);
    }
  }, [stopsData, selectedDuration]);

  useEffect(() => {
    if (gridApi) {
      if (
        gridApi?.context?.contextParams?.providedBeanInstances?.gridOptions
          ?.context
      ) {
        gridApi.context.contextParams.providedBeanInstances.gridOptions.context.isWritable =
          isWritable;
        gridApi.refreshCells();
      }
    }
  }, [gridApi, isWritable]);

  useEffect(() => {
    filterTables("programFields", "fieldName", "Audit Reasons")
      .then((res) => {
        setReasonField(res[0]?.fieldId);
        setReasons(
          Array.isArray(res[0]?.fieldOptions) ? res[0]?.fieldOptions : []
        );
      })
      .catch((err) => {
        console.log("Error getting dynamic fields: ", err);
      });
  }, []);

  function onVehicleClick(data) {
    saveLocalPreferences({
      auditExternalRedirect: {
        fleetId: data.fleetId,
        fleetName: data.fleetName,
      },
    });
    changeMenuKey("AUDIT");
  }

  async function onLocationDiscard(data, isConfirmed = false) {
    if (!isConfirmed) {
      setDiscardWarning(data);
      return;
    }

    const { fleetId, beginDate, endDate } = data;

    let auditForVehicle = globalAudits?.["alertChanges"]?.[fleetId];
    if (!auditForVehicle) {
      auditForVehicle = {
        changed: {},
        discarded: [],
        linkedStops: [],
        stopReasons: [],
        discardedStops: [],
        idleViewChanges: [],
        auditedActivities: [],
        afterHoursChanges: [],
        auditStatus: "PENDING",
      };
    }

    const thisChangeLog = {
      field: null,
      value: null,
      discarded: true,
      eventRange: [beginDate, endDate],
    };

    const changes = (auditForVehicle?.["idleViewChanges"] || []).filter(
      ({ eventRange }) =>
        eventRange?.[0] === beginDate && eventRange?.[1] === endDate
    );

    changes.splice(changes?.length, 0, thisChangeLog);
    auditForVehicle["idleViewChanges"] = [...changes];

    const tmpNewAlertChanges = {
      alertChanges: {
        ...(globalAudits["alertChanges"] || {}),
        [fleetId]: auditForVehicle,
      },
    };

    message.loading({
      content: "Discarding Stop...",
      key: "stopDiscard",
      duration: 0,
    });

    try {
      let audit = undefined;
      if (!globalAudits?.auditId) {
        await API.post("fleetAudits", "/fleetAudits", {
          body: {
            auditDate: dayjsNY(selectedDate).startOf("day").format(),
            ...tmpNewAlertChanges,
          },
        }).then((res) => {
          audit = res;
        });
      } else {
        await API.put("fleetAudits", `/fleetAudits/${globalAudits?.auditId}`, {
          body: {
            ...tmpNewAlertChanges,
          },
        }).then(() => {
          audit = {
            ...globalAudits,
            ...tmpNewAlertChanges,
          };
        });
      }

      message.success({
        content: "Stop Discarded!",
        key: "stopDiscard",
      });

      broadcastUpdates({ request: "audit-change", audit });
      externalUpdateAudit(audit);
    } catch (err) {
      message.error({
        content: "Something went wrong while discard stop",
        key: "stopDiscard",
      });
      console.log("Error discarding stop: ", err);
    }
  }

  async function onReasonSelect(newReason) {
    message.loading({
      content: "Saving option...",
      key: "optionSave",
      duration: 0,
    });

    return API.patch("programFields", `/programFields/${reasonField}`, {
      body: {
        fieldOptions: [...reasons, newReason],
      },
    })
      .then(() => {
        message.destroy("optionSave");
        setReasons([...reasons, newReason]);
        return newReason;
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while saving option",
          key: "optionSave",
        });
        console.log("Error saving option: ", err);
        return "";
      });
  }

  /**
   * Handles the selection of the duration *IN MINUTES*
   * @param {number} duration The duration in minutes
   * @param {Equalizer} equalizer The operation on the duration
   * @param {DurationUnit} unit
   */
  function onDurationSelect(duration, equalizer, unit) {
    if (duration === -1) {
      setSelectedDuration({ ...selectedDuration, duration: +duration });
      saveLocalPreferences({ liveIdleDuration: +duration });
      return;
    }

    setSelectedDuration({ duration: +duration, equalizer, unit });
    saveLocalPreferences({
      liveIdleDuration: +duration,
      liveIdleOperation: equalizer,
      liveIdleDurationUnit: unit,
    });

    setPopoverOpen(false);
  }

  function excelExportHandler() {
    if (!gridApi) {
      return;
    }

    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (headerName.includes("Duration")) {
          return formatDuration(value, "minutes").text;
        } else if (headerName.includes("Date")) {
          return dayjsNY(value).format("MM/DD/YYYY HH:mm");
        }

        return value === undefined ? "" : `${value}`;
      },
    });
  }

  return (
    <Fragment>
      <div
        className={`live-locations-view ${
          isDarkMode ? "live-locations-dark" : ""
        }`}
        id="main-reasons-view"
      >
        <div
          className={`main-payroll-view ${
            isDarkMode && "main-payroll-view-dark"
          }`}
        >
          <PayrollLayout
            {...{
              rowData,
              title: "",
              columnDefs,
              gridFilters,
              hasNew: false,
              rowHeight: 38,
              headerHeight: 40,
              onCellValueChanged,
              getGridApi: setGridApi,
              suppressCellFocus: true,
              suppressDoubleClickEdit: true,
              enterNavigatesVertically: false,
              paginationPageSize: findPaginationSize("main-reasons-view", 38),
              context: {
                reasons,
                isDarkMode,
                isWritable,
                onReasonSelect,
                onVehicleClick,
                onLocationDiscard,
              },
              additionalGridProps: {
                defaultExcelExportParams: {
                  columnKeys,
                  sheetName: "Idle Events",
                  fileName: `Idle Events Report - ${dayjsNY(
                    selectedDate
                  ).format("MM/DD/YYYY")}`,
                },
              },
              additionalActionsButtons: [
                <Tooltip
                  title="Duration Filter"
                  placement="bottom"
                  key="live-idle-view-popover"
                >
                  <Popover
                    open={popoverOpen}
                    trigger={["click"]}
                    placement="bottom"
                    overlayClassName={`stop-modal-popover-content${
                      isDarkMode ? " popover-dark" : ""
                    }`}
                    content={
                      <IdleDurationPicker onDurationSelect={onDurationSelect} />
                    }
                  >
                    <MondayButton
                      Icon={<SearchOutlined />}
                      className="mondayButtonBlue"
                      onClick={() => {
                        setPopoverOpen(!popoverOpen);
                      }}
                    >
                      {null}
                    </MondayButton>
                  </Popover>
                </Tooltip>,
                <Tooltip
                  title="Excel Export"
                  placement="bottom"
                  key="excelExport"
                >
                  <MondayButton
                    Icon={<ExcelIconWhite />}
                    className="mondayButtonBlue"
                    onClick={() => {
                      excelExportHandler();
                    }}
                  >
                    {null}
                  </MondayButton>
                </Tooltip>,
              ],
            }}
          />
        </div>
      </div>
      {discardWarning && (
        <WarningModal
          visible={!!discardWarning}
          setVisible={setDiscardWarning}
          darkMode={isDarkMode}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body" style={{ textAlign: "center" }}>
            <span>
              <WarningTriangle />
            </span>
            <p>
              Are you sure you want to discard an idle event for{" "}
              {discardWarning?.fleetName} (from{" "}
              {dayjsNY(discardWarning.beginDate).format("MM/DD/YYYY HH:mm")} to{" "}
              {dayjsNY(discardWarning.endDate).format("MM/DD/YYYY HH:mm")})?
              This change cannot be reversed!
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => {
                  setDiscardWarning();
                }}
                className="mondayButtonRed"
                Icon={<XIcon />}
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  onLocationDiscard(discardWarning, true);
                  setDiscardWarning();
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Fragment>
  );
}

export default LiveIdleView;
