export const filtersOptions = ({ rowData, key, isInspectionsPage = false }) => {
  const uniqueItems = new Set();

  const options = [
    ...new Set(
      rowData
        ?.flatMap(({ [key]: keyDatas, ...rest } = {}) => {
          if (Array.isArray(keyDatas)) {
            return keyDatas?.map((obj) =>
              typeof obj === "object" && obj?.hasOwnProperty("nameOfUser")
                ? obj.nameOfUser
                : typeof obj === "string" || typeof obj === "number"
                ? obj
                : false
            );
          } else if (typeof keyDatas === "boolean") {
            return keyDatas ? "yes" : "no";
          } else if (typeof keyDatas === "object") {
            return keyDatas?.hasOwnProperty("name")
              ? keyDatas.name
              : keyDatas?.hasOwnProperty("nameOfUser")
              ? keyDatas.nameOfUser
              : typeof keyDatas === "string" || typeof keyDatas === "number"
              ? keyDatas
              : false;
          } else if (key === "estimations" && isInspectionsPage) {
            const { inspectionReport, inspectionAddress = "" } = rest;
            const { estimations = [] } = inspectionReport || {};

            const address = !!inspectionAddress ? `- ${inspectionAddress}` : "";

            return estimations
              ?.filter(({ estimationNumber }) => !!estimationNumber)
              ?.map(({ estimationNumber }) => ({
                label: `${estimationNumber} ${address}`,
                value: estimationNumber,
              }));
          } else if (key === "services" && isInspectionsPage) {
            const { inspectionReport } = rest;
            const { questions = {} } = inspectionReport || {};

            const services = Object.keys(questions || {});
            return services;
          } else {
            return keyDatas;
          }
        })
        .filter(Boolean)
    ),
  ]
    ?.filter((item) => {
      const key = typeof item === "string" ? item : JSON.stringify(item);

      if (uniqueItems.has(key)) {
        return false;
      }

      uniqueItems.add(key);
      return true;
    })
    .map((el) => {
      if (typeof el === "string") {
        return {
          value: el,
          label: el,
        };
      }

      return el;
    });

  return options;
};
