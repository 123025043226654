import { Modal, Form } from "antd";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useState, useEffect, useContext, useCallback, useMemo } from "react";

import DegModalContext from "../DegModalContext";
import { teamShiftColumns } from "../TeamShiftsModal/teamShiftModalData";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { getSelectionFooter, selectionFields } from "./teamSelectionModalData";
import { RenderDynamicComponents } from "../../../../../../../Header/forms/Components";
import { parseInTz } from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./TeamSelectionModal.scss";

const defaultColDef = {
  flex: 1,
  filter: true,
  minWidth: 130,
  sortable: true,
  resizable: true,
  enableColResize: true,
  enableRowGroup: false,
  suppressSizeToFit: true,
  suppressDragLeaveHidesColumns: true,
};

function TeamSelectionModal({ open, onCancel = () => {} }) {
  const {
    crews,
    jobsites,
    crewTeams,
    degGridApi,
    isDarkMode,
    currentStep,
    shiftsGridApi,
    setFiltersFromSelection,
    updateShiftExternalFilter,
  } = useContext(DegModalContext);

  const [gridApi, setGridApi] = useState();

  const [form] = Form.useForm();

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
  }, []);

  async function onSave() {
    let api = currentStep === 1 ? degGridApi : shiftsGridApi;
    if (!api) {
      return;
    }

    form
      .validateFields()
      .then(({ selectedEmployees = [], dateRange, selectedJobsite }) => {
        let range1 = null;
        let range2 = null;

        if (dateRange) {
          range1 = parseInTz(dateRange).startOf("D").valueOf();
          range2 = parseInTz(dateRange).startOf("D").add(1, "d").valueOf();
        }

        let filtersForDegGrid = {
          company: null,
          punchType: null,
          payrollType: null,
          jobsiteMatch: null,
          employeeRole: null,
          jobsiteMatch: null,
          employeeFullName: null,
          punchTimeStampweek: null,
          punchTimeStampend: range2,
          punchTimeStampstart: range1,
        };

        let filtersForShiftGrid = {
          selectedEmployees,
          selectedJobsites: [selectedJobsite].filter(Boolean),
          timeFrame: [
            parseInTz(dateRange).startOf("D").toISOString(),
            parseInTz(dateRange).endOf("D").add(1, "d").toISOString(),
          ],
        };

        if (selectedJobsite) {
          filtersForDegGrid["jobsiteMatch"] = [
            jobsites?.find(({ jobsiteId }) => jobsiteId === selectedJobsite)
              ?.jobAddress,
          ];
        }

        if (selectedEmployees?.length) {
          let tmp = [];
          filtersForDegGrid["employeeFullName"] = tmp;
          for (const employeeId of selectedEmployees) {
            tmp.push(
              crews?.find(({ employeeId: id }) => {
                let employeeIdSplit = id.split("-");
                let modifiedEmployeeId = `${employeeIdSplit[0]}-${parseFloat(
                  employeeIdSplit[1]
                )}`;
                const employeeMatch =
                  employeeId === id || employeeId === modifiedEmployeeId;

                return employeeMatch;
              })?.crewName
            );
          }
        }

        api.forEachNode((node) => {
          node.setSelected(false);
          let data = node["data"];
          let dateCondition = true;
          let employeeCondition = true;
          let jobsiteCondition = true;

          if (dateRange) {
            if (!data?.punchDate) {
              dateCondition = false;
            } else {
              try {
                let punchDate = dayjsNY(data?.punchDate).valueOf();
                if (punchDate < range1 || punchDate > range2) {
                  dateCondition = false;
                }
              } catch {
                dateCondition = false;
              }
            }
          }

          if (selectedEmployees?.length) {
            if (!selectedEmployees.includes(data?.["employeeId"])) {
              employeeCondition = false;
            }
          }

          if (selectedJobsite) {
            if (data?.["jobsiteMatch"]?.["jobsiteId"] !== selectedJobsite) {
              jobsiteCondition = false;
            }
          }

          if (dateCondition && employeeCondition && jobsiteCondition) {
            node.setSelected(true);
          }
        });

        setFiltersFromSelection(
          currentStep === 1
            ? filtersForDegGrid
            : Object.assign(filtersForShiftGrid, {
                selectedEmployees: crews.flatMap(({ employeeId, crewId }) =>
                  selectedEmployees.includes(employeeId) ? crewId : []
                ),

                timeFrame: [
                  parseInTz(dateRange).startOf("D").toISOString(),
                  parseInTz(dateRange).endOf("D").add(1, "d").toISOString(),
                ],
              })
        );
        if (currentStep === 1) {
          sessionStorage.setItem(
            "degStepFilters",
            JSON.stringify(filtersForDegGrid)
          );
        } else {
          updateShiftExternalFilter(filtersForShiftGrid);
        }
        onCancel();
      });
  }

  function onClearSelection() {
    let filtersForShiftGrid = {
      payrollType: [],
      employeeRole: [],
      timeFrame: undefined,
      selectedJobsites: [],
      selectedEmployees: [],
    };
    gridApi.forEachNode((node) => {
      node.setSelected(false);
    });
    form.resetFields();
    currentStep === 1
      ? degGridApi?.deselectAll()
      : shiftsGridApi?.deselectAll();
    setFiltersFromSelection({});
    updateShiftExternalFilter(filtersForShiftGrid);
  }

  useEffect(() => {
    function handleSelectionToggle(param) {
      if ((param?.source || "").includes("checkbox")) {
        let isSelected = param.node.isSelected();
        let selectedEmployees = form.getFieldValue("selectedEmployees") || [];

        let employeesToChange = [];
        if (param.data?.["crewForeman"]?.["crewId"]) {
          const foreman = crews.find(
            ({ crewId }) => crewId === param.data?.crewForeman?.crewId
          );
          if (!!foreman?.employeeId) {
            employeesToChange.push(foreman?.employeeId);
          }
        }
        for (const crew of param.data.crewMembers) {
          if (crew?.["employeeId"]) {
            employeesToChange.push(crew?.["employeeId"]);
          }
        }

        if (isSelected) {
          for (const employeeId of employeesToChange) {
            if (!selectedEmployees.includes(employeeId)) {
              selectedEmployees = [...selectedEmployees, employeeId];
            }
          }

          form.setFieldValue("selectedEmployees", selectedEmployees);
        } else {
          form.setFieldValue(
            "selectedEmployees",
            selectedEmployees.filter(
              (employeeId) => !employeesToChange.includes(employeeId)
            )
          );
        }
      }
    }

    if (gridApi) {
      gridApi.addEventListener("rowSelected", handleSelectionToggle);
    }

    return () => {
      if (gridApi) {
        gridApi.removeEventListener("rowSelected", handleSelectionToggle);
      }
    };
  }, [gridApi, form]);

  function handleChange(values) {
    if (gridApi) {
      gridApi.forEachNode((node) => {
        const { data } = node;
        let members = [data?.["crewForeman"]?.["employeeId"]].concat(
          data?.crewMembers?.map(({ employeeId }) => employeeId)
        );
        members = members.filter(Boolean);
        if (members.every((employeeId) => values.includes(employeeId))) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    }
  }

  const columnDefs = useMemo(() => {
    return teamShiftColumns.slice(0, 2).concat(teamShiftColumns.slice(3));
  }, [teamShiftColumns]);

  return (
    <Modal
      {...{
        open,
        onCancel,
        centered: true,
        closeIcon: <XIcon />,
        ["data-testid"]: "entries-selection-modal",
        title: `${currentStep === 1 ? "Entry" : "Shift"} Selection`,
        footer: getSelectionFooter({ onCancel, onSave, onClearSelection }),
        className: `teamSelectionModalContainer ${
          isDarkMode && "teamSelectionModalContainerDark"
        }`,
      }}
    >
      <Form form={form}>
        <section className="form-first-section">
          {RenderDynamicComponents(
            selectionFields({
              crews,
              jobsites,
              currentStep,
              handleChange,
            }).slice(0, 2),
            { form }
          )}
        </section>
        <section className="form-second-section">
          {RenderDynamicComponents(
            selectionFields({
              crews,
              jobsites,
              currentStep,
              handleChange,
            }).slice(2),
            { form }
          )}
        </section>
      </Form>
      <div
        className={`selection-grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            columnDefs,
            onGridReady,
            defaultColDef,
            pagination: false,
            rowData: crewTeams,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
          }}
        />
      </div>
    </Modal>
  );
}

export default TeamSelectionModal;
