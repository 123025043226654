export const title = "Driver's Daily Vehicle Inspection Report";
export const subtitle =
  "As required by the Federal Motor Carrier Safety Regulations for Commercial Drivers";
export const dateFormat = "MM/DD/YYYY";
export const timeFormat = "HH:mm";
export const checkboxCardsTitle = `Check the condition of the items below`;
export const inspectionTypes = ["Pre-trip", "Post trip"];
// export const generalItemsCardSubtitle = `(Car operators need only to inspect items with an asterisk "*")`
// export const truckItemsCardSubtitle = `(This section to be filled out by truck/trailer only.)`
export const questionAnswers = ["Yes", "No"];

export const footerButtons = ({
  onCancel,
  onSaveAndClose,
  // onOptionClick,
  exportToPdf,
  onLogClick,
  onDelete,
  suffix,
  saving,
  logsAccess = false,
  openToDo,
}) =>
  [
    {
      text: "Download Pdf",
      default: true,
      onClick: exportToPdf,
    },
    !suffix &&
      logsAccess && {
        text: "Show Logs",
        onClick: onLogClick,
      },
    !suffix &&
      logsAccess && {
        text: "To Do",
        onClick: openToDo,
      },
    !!onDelete && {
      text: "Delete",
      cancel: true,
      onClick: onDelete,
    },
    {
      text: "cancel",
      cancel: true,
      onClick: onCancel,
    },

    {
      text: "Save & Close",
      primary: true,
      disabled: !!saving,
      dropdownDisabled: !!saving,
      onClick: onSaveAndClose,
    },
  ].filter(Boolean);
