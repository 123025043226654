import { Button, Input, Space, Upload, message } from "antd";
import axios, { all } from "axios";
import { useEffect, useState } from "react";
import { blobToBase64 } from "../../../../SidebarPages/utils";
import { MondayButton } from "../../../../commonComponents";
import {
  CopyFilled,
  DoubleLeftOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  MergeCellsOutlined,
} from "@ant-design/icons";
import { NormalSizedModal } from "../../../../SidebarPages/Fleet/components";
import PageRangePicker from "./PageRangePicker/PageRangePicker";
import PdfsFinderFromDocuments from "./PdfsFinderFromDocuments/PdfsFinderFromDocuments";
import "./MergePdfs.scss";
import {
  DriveIcon,
  MergeIcon,
  PdfMergerIcon,
  StartOverIcon,
} from "../FirstProposalPage/assets";
import Swal from "sweetalert2";
import { PdfIcon } from "src/components/SidebarPages/Documentation/View/assets";
import { DeleteIcon } from "src/assets";
import { CopyIcon } from "src/components/Header/assets";
import { useSelector } from "react-redux";
import ActionsHeader from "./ActionsHeader/ActionsHeader";
import PDFCard from "./PDFCard/PDFCard";
import { useMemo } from "react";
import { useRedux } from "../../../../SidebarPages/hooks";
// import { downloadBlob } from "../../../../../../utils";
// import { FilePreviewModal } from "../../../../Documentation/View/components";
// // import "./TestingView.scss";
import FileSaver from "file-saver";
import { blobToBase64Sync } from "../../../../../utils";
import DocuSealForm from "../../ProposalHeader/components/DocuSeal/DocuSealForm/DocuSealForm";
import { useProposalContext } from "../../../WaitingRoomProposal/ProposalContext";

const MergePdfs = ({ editorRef }) => {
  const [allFilesList, setFileList] = useState([]);
  const [customConfigPages, setCustomConfigPages] = useState({});
  const [mergedPdf, setMergedPdf] = useState();
  const [previewPDF, setPreviewPDF] = useState(false);
  const [customFiles, setCustomFiles] = useState([]); // from google drive
  const [forceLoading, setForceLoading] = useState(false);
  const [tempCommands, setTempCommands] = useState({
    viewMode: "original", //else is iframeFull
    inputEnabler: null,
  });
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { proposalData } = useProposalContext();
  const [mergedBase64, setMergedBase64] = useState();
  const [enable, setEnable] = useState(false);
  const [externalId, setExternalId] = useState(false);
  const onUploadChange = ({ fileList, type = "" }) => {
    console.log("fileList", fileList);
    let allFiles = [...allFilesList];
    allFiles.push({ ...(fileList?.[0] || {}), type });
    allFiles = allFiles.map((file, idx) => ({
      ...file,
      thumbUrl: URL.createObjectURL(file.originFileObj),
    }));
    setFileList(allFiles);
    // setFileList(fileList);
  };

  // IMPORTANT, TO SEND TO BACKEND FOR MERGING
  //region onMergeAll
  const onMergeAll = async () => {
    // Swal.fire({
    //   title: "<strong>Merging PDFs</strong>",
    //   icon: "info",
    //   html: "Please wait while we merge your PDFs! ",
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    message.loading("Generating PDF, please wait...", 0);
    console.log("filelist:m", allFilesList);
    console.log("customConfigPages", customConfigPages);
    let FILES_TO_SEND = await Promise.all(
      allFilesList?.map((file) => {
        if (file?.type === "local") {
          return {
            ...file,
            fileSrc: blobToBase64(file?.originFileObj).then((res) => res),
          };
        } else {
          return {
            ...file,
            fileSrc: file.src,
          };
        }
      })
    );

    console.log("FILES_TO_SEND", FILES_TO_SEND);
    await axios
      .post(
        "https://leadmanager-express-backend.ue.r.appspot.com/api/mergePdfDocs",
        // "http://10.0.0.84:8080/api/mergePdfDocs",
        {
          // originalPdf: await blobToBase64(originalPdf.originFileObj),
          // pdfToAttach: await blobToBase64(pdfToAttach.originFileObj),
          originalPdf: [
            ...(await Promise.all(FILES_TO_SEND?.map((el) => el?.fileSrc))),
          ]?.filter((el) => typeof el === "string"),
          configuration: {
            fileList: FILES_TO_SEND,
            pagesConfig: customConfigPages,
            // ...(idxConfigs || {}),
          },
        }
        // { responseType: "blob" }
      )
      .then(async ({ data: mergedPdf }) => {
        setMergedBase64(mergedPdf);
        var blob = b64toBlob(mergedPdf, "application/pdf");
        const blobURL = URL.createObjectURL(blob);
        // const theWindow = window.open(blobURL);
        // const theDoc = theWindow.document;
        // const theScript = document.createElement("script");
        // function injectThis() {
        //   window.print();
        // }
        // theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
        // theDoc.body.appendChild(theScript);
        setPreviewPDF(true);
        setMergedPdf(blobURL);
        // window.open(fileURL);
      })
      .catch((e) => {
        console.log("error", e);
        Swal.fire({
          title: "<strong>Error</strong>",
          icon: "error",
          html: "There was an error merging your PDFs! ",
        });
      })
      .finally(() => {
        message.destroy();
      });
  };

  const onSendToDocuSeal = async () => {
    setEnable(true);
    return;
    let mergedFile = mergedPdf; //URL
    let base64Data = mergedBase64;
    console.log("base64Data", base64Data);
    const response = await axios
      .post(
        `http://10.0.0.85:8080/api/docusealPDFImporter`,
        // `https://leadmanager-express-backend.ue.r.appspot.com/api/${endPoint}`,
        {
          base64: base64Data,
        }
      )
      .then((res) => {
        console.log("restttt", res.data);
        setExternalId(res?.data?.external_id);
        setEnable(true);
      });
  };

  //region onAddCurrentProposal
  const onAddCurrentProposal = async () => {
    console.log("onAddCurrentProposal", proposalData);
    const response = await axios.post(
      // `http://10.0.0.84:8080/api/htmlDocusealConverter`,
      `https://leadmanager-express-backend.ue.r.appspot.com/api/htmlDocusealConverter`,
      {
        html: `${editorRef.current.value || "Testing Proposal"}`,
      }
    );
    let fileUrl = response?.data?.documents?.[0]?.url; //PDF
    if (!!fileUrl) {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileName = "engineers_work.pdf"; // Set the desired file name
      setFileList((prev) => [
        ...prev,
        {
          uid: Math.random(),
          name: fileName,
          type: "local",
          originFileObj: blob,
          thumbUrl: URL.createObjectURL(blob),
        },
      ]);
      // FileSaver.saveAs(blob, fileName);
    }

    console.log("response", response);
  };

  const printBob = (blobURL) => {
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement("script");
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
    theDoc.body.appendChild(theScript);
  };

  const customFilesChanger = (files) => {
    setFileList((prev) => [
      ...(prev || []),
      ...files?.map((file) => ({
        ...(file || {}),
        type: "drive",
        uid: file?.id,
      })),
    ]);
  };

  //region onStartOver
  const onStartOver = () => {
    Swal.fire({
      title: "<strong>Start Over</strong>",
      icon: "info",
      html: "Are you sure you want to start over? ",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        setFileList([]);
        setCustomConfigPages({});
        setMergedPdf();
        setPreviewPDF(false);
        setCustomFiles([]);
        setTempCommands((prev) => ({
          ...prev,
          viewMode: "original",
        }));
        Swal.close();
      }
    });
  };

  const onDeleteFile = (file) => {
    setFileList((prev) => prev?.filter((f) => f.uid !== file.uid));
    message.success("File removed successfully");
  };

  //region onMoveUp
  const onMoveUp = (file) => {
    const index = allFilesList.findIndex((f) => f?.uid === file?.uid);
    if (index === 0) return;
    const newFiles = structuredClone(allFilesList);
    const [movedFile] = newFiles.splice(index, 1);
    newFiles.splice(index - 1, 0, movedFile);
    console.log("newFIles", newFiles);
    setFileList(newFiles);
    setForceLoading(false);
  };

  //region onDelete
  const onDelete = (file) => {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
    let tempConfig = { ...(customConfigPages || {}) };
    if (tempConfig?.[file?.uid]) {
      delete tempConfig[file.uid];
      setCustomConfigPages(tempConfig);
    }
  };

  //region onMoveDown
  const onMoveDown = (file) => {
    const index = allFilesList.findIndex((f) => f?.uid === file?.uid);
    if (index === allFilesList.length - 1) return;
    const newFiles = structuredClone(allFilesList);
    const [movedFile] = newFiles.splice(index, 1);
    newFiles.splice(index + 1, 0, movedFile);
    setFileList(newFiles);
  };

  const getCustomPagesInfo = (customConfigPages, file) => {
    if (!customConfigPages?.[`${file?.uid}`]) return "";
    return "| " + customConfigPages?.[`${file?.uid}`]?.pagesInfo;
  };

  const onCategoryChanger = (e, file) => {
    setFileList((prev) => {
      const idx = prev.findIndex((f) => f?.uid === file?.uid);
      const newFile = { ...prev[idx] };
      newFile.category = e.target.value;
      const newPrev = [...prev];
      newPrev[idx] = newFile;
      return newPrev;
    });
  };

  console.log("logsssssss", {
    allFilesList,
    customConfigPages,
    mergedPdf,
    previewPDF,
    customFiles,
    tempCommands,
  });

  useEffect(() => {
    if (allFilesList.length !== 0) {
      onMergeAll();
    }
  }, [allFilesList, customConfigPages]);

  return (
    <div className="mergePdfsContainer">
      <ActionsHeader
        {...{ onStartOver, onMergeAll, onSendToDocuSeal, onAddCurrentProposal }}
      />
      <div className="mergePdfsBodyDragger">
        {allFilesList.length === 0 && (
          <Upload.Dragger
            onChange={({ fileList }) =>
              onUploadChange({ fileList, type: "local" })
            }
            fileList={[]}
            listType="picture"
            className="proposalDraggerDocuments"
            maxCount={15}
            accept="application/pdf"
            itemRender={(originNode, file, currFileList) => {
              console.log("file", file);
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  {originNode}{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <PickCustomPages
                      {...{
                        setCustomConfigPages,
                        file,
                        id: file.uid,
                        fileType: "local",
                      }}
                    />
                    {customConfigPages[file.uid] && (
                      <span
                        style={{
                          display: "flex",
                          fontWeight: "bold",
                          alignItems: "center",
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "18px",
                        }}
                      >
                        ({customConfigPages[file.uid].startPage} -{" "}
                        {customConfigPages[file.uid].endPage})
                      </span>
                    )}
                  </div>
                </div>
              );
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                mouseEvents: "none",
                height: "550px",
              }}
            >
              <span>
                <PdfMergerIcon />
              </span>
              <span
                style={{
                  color: isDarkMode ? "#fff" : "#000",
                }}
              >
                Drag and drop PDFs to merge into one file.
              </span>
              <div style={{ display: "flex", gap: "10px" }}>
                <MondayButton
                  className="mondayButtonBlue"
                  Icon={<FileSearchOutlined />}
                >
                  Browse
                </MondayButton>{" "}
                <PdfsFinderFromDocuments
                  {...{
                    customFilesChanger,
                    customFiles,
                  }}
                />
              </div>
            </div>
            {/* <Button>Original PDF</Button> */}

            {/* <strong>Upload Documents to Merge</strong> */}
          </Upload.Dragger>
        )}
      </div>{" "}
      {tempCommands.viewMode === "original" && (
        <div className="mergePdfsBodyContainer">
          {!!allFilesList && allFilesList.length > 0 && (
            <div className="customFilesContainer">
              <div className="pdf-list">
                {allFilesList?.map((file, idx) => (
                  <>
                    <PDFCard
                      fileName={file?.name}
                      key={file?.uid || idx}
                      file={file}
                      setCustomConfigPages={setCustomConfigPages}
                      configPages={customConfigPages}
                      onMoveDown={onMoveDown}
                      onMoveUp={onMoveUp}
                      onDelete={onDelete}
                    />
                  </>
                ))}
              </div>
              <div
                style={{
                  padding: 20,
                }}
              >
                <Upload.Dragger
                  onChange={({ fileList }) =>
                    onUploadChange({ fileList, type: "local" })
                  }
                  fileList={[]}
                  listType="picture"
                  // className="proposalDraggerDocuments"
                  maxCount={15}
                  accept="application/pdf"
                  // itemRender={(originNode, file, currFileList) => {
                  //   console.log("file", file);
                  //   return (
                  //     <div
                  //       style={{
                  //         display: "flex",
                  //         flexDirection: "column",
                  //         justifyContent: "flex-end",
                  //       }}
                  //     >
                  //       {originNode}{" "}
                  //       <div
                  //         style={{
                  //           display: "flex",
                  //           justifyContent: "flex-end",
                  //           marginTop: "10px",
                  //         }}
                  //       >
                  //         <PickCustomPages
                  //           {...{
                  //             setCustomConfigPages,
                  //             file,
                  //             id: file.uid,
                  //             fileType: "local",
                  //           }}
                  //         />
                  //         {customConfigPages[file.uid] && (
                  //           <span
                  //             style={{
                  //               display: "flex",
                  //               fontWeight: "bold",
                  //               alignItems: "center",
                  //               color: "red",
                  //               marginLeft: "10px",
                  //               fontSize: "18px",
                  //             }}
                  //           >
                  //             ({customConfigPages[file.uid].startPage} -{" "}
                  //             {customConfigPages[file.uid].endPage})
                  //           </span>
                  //         )}
                  //       </div>
                  //     </div>
                  //   );
                  // }}
                >
                  <strong style={{ color: "#fff" }}> Add More PDFs</strong>
                </Upload.Dragger>
              </div>
            </div>
          )}
          <div className="mergePdfsIframe">
            {mergedPdf && previewPDF && (
              <>
                <iframe
                  title="PDF Viewer"
                  style={{
                    width: "100%",
                    height: "650px",
                    // height: "900px",
                  }}
                  src={mergedPdf}
                />
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                    flexDirection: "row",
                    paddingBottom: "20px",
                    paddingTop: "20px",
                  }}
                >
                  <MondayButton
                    className="mondayButtonBlue"
                    hasIcon={false}
                    onClick={() => {
                      setTempCommands((prev) => ({
                        ...prev,
                        viewMode:
                          prev.viewMode === "original"
                            ? "iframeFull"
                            : "original",
                      }));
                    }}
                  >
                    Toggle View
                  </MondayButton>
                  <MondayButton
                    className="mondayButtonBlue"
                    hasIcon={false}
                    onClick={() => {
                      window.open(mergedPdf, "_blank");
                    }}
                  >
                    Open in new Tab
                  </MondayButton>{" "}
                  <MondayButton
                    className="mondayButtonBlue"
                    hasIcon={false}
                    onClick={() => {
                      window.open(mergedPdf, "_blank");
                    }}
                  >
                    Open in new Tab
                  </MondayButton>
                  <MondayButton
                    className="mondayButtonBlue"
                    hasIcon={false}
                    onClick={() => {
                      //download blob
                      printBob(mergedPdf, "merged-pdf.pdf");
                    }}
                  >
                    Print
                  </MondayButton>
                </div> */}
              </>
            )}
          </div>
          {/* <Button
        onClick={() =>
          downloadFile(
            "data:application/pdf;base64," + mergedPdf,
            "merged-pdf.pdf"
          )
        }
      >
        Download
      </Button> */}
          {/* 
      <FilePreviewModal
        visible={!!mergedPdf}
        setVisible={setMergedPdf}
        file={{
          src: "data:application/pdf;base64," + mergedPdf,
          mimeType: "application/pdf",
        }}
      /> */}
          {enable && (
            <DocuSealForm
              // externalId={externalId}
              enable={enable}
              setEnable={setEnable}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MergePdfs;

const PickCustomPages = ({
  setCustomConfigPages,
  file,
  id,
  fileType = "local",
}) => {
  const [active, setActive] = useState(false);
  const [toRender, setToRender] = useState();
  console.log("PickCustomPages", file);

  const onFirstOpen = () => {
    setActive(true);
    if (fileType === "local") {
      getFileAsBase64(file.originFileObj)
        .then((base64String) => {
          console.log("myFile", base64String);
          const toReturn = b64toBlob(base64String, "application/pdf");
          const blobURL = URL.createObjectURL(toReturn);
          setToRender(blobURL);
        })
        .catch((error) => {
          console.error("Error reading file:", error);
        });
    } else {
      const blobURL2 = file?.blob;
      setToRender(blobURL2);
    }
  };

  const onConfirm = ({ pagesArr, pagesInfo = "" }) => {
    console.log("logu", {
      customElements,
    });
    setCustomConfigPages((prev) => ({
      ...(prev || {}),
      [id]: {
        pagesArr,
        pagesInfo,
      },
    }));
    setActive(false);
  };

  return (
    <>
      {" "}
      <span
        style={{
          display: "flex",
          cursor: "pointer",
          color: "#1264A3",
          alignItems: "center",
        }}
        onClick={onFirstOpen}
      >
        Edit Pages
      </span>
      <NormalSizedModal
        {...{
          open: active,
          setOpen: setActive,
          onCancel: () => setActive(false),
          title: "Pick Custom Pages",
          closable: true,
          centered: true,
        }}
      >
        <div
          style={{
            width: "1200px",
            height: "800px",
            // height: "500px",
            // height: "800px",
          }}
        >
          <PageRangePicker onConfirm={onConfirm} />
          <br />
          {toRender && (
            <iframe
              title="PDF Viewer"
              style={{
                width: "100%",
                height: "700px",
              }}
              src={toRender}
            />
          )}
        </div>
      </NormalSizedModal>
    </>
  );
};
const getFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
export const b64toBlob = (content, contentType) => {
  contentType = contentType || "";
  const sliceSize = 512;
  // method which converts base64 to binary
  const byteCharacters = window.atob(content);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
    type: contentType,
  }); // statement which creates the blob
  return blob;
};

{
  /* <div
                    className="customFilesContainerItem"
                    key={file?.uid || idx}
                    style={{
                      display: "flex",
                      display: "none",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className="customFilesLeftSide">
                      {file?.type !== "local" && (
                        <div className="pdfFileIconContainer">
                          <DriveIcon />
                        </div>
                      )}
                      <div className="pdfFileIconContainer">
                        <PdfIcon />
                      </div>
                      <div
                        className="customFilesContainerItemLink"
                        onClick={() => {
                          window.open(file?.blob, "_blank");
                        }}
                      >
                        {file?.name}
                      </div>{" "}
                    </div>
                    <div className="customFilesRightSide">
                      <div className="pickPagesCont">
                        <span
                          className="titleinfopages"
                          onClick={() => {
                            setTempCommands((prev) => ({
                              ...prev,
                              inputEnabler: file?.uid,
                            }));
                          }}
                        >
                          {!!file?.category &&
                          tempCommands?.inputEnabler !== file?.uid ? (
                            file?.category
                          ) : (
                            <Input
                              value={file?.category}
                              onChange={(e) => onCategoryChanger(e, file)}
                              onBlur={() => {
                                setTempCommands((prev) => ({
                                  ...prev,
                                  inputEnabler: null,
                                }));
                              }}
                            />
                          )}
                          {getCustomPagesInfo(customConfigPages, file)}
                        </span>
                      </div>{" "}
                      <div className="deletIconHolder">
                        {" "}
                        <PickCustomPages
                          {...{
                            setCustomConfigPages,
                            file,
                            id: file?.uid || "1324",
                            fileType:
                              file?.type !== "local" ? "drive" : "local",
                          }}
                        />
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => onDeleteFile(file)}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </div> */
}
// </div> */
// }
