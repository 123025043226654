import { message } from "antd";
import { API } from "aws-amplify";

async function editCitation(citationId, citation, status, userConfiguration) {
  await API.put("citations", `/citations/${citationId}`, {
    body: {
      citationName: citation.citationName,
      employeeId: citation.employeeId,
      citationObject: citation.citationObject,
      citationStatus: status,
      lastModifiedBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
    },
  })
    ?.then(async () => {
      message.success({
        content: "Saved Successfully!",
        key: "safetyLoading",
        duration: 3,
      });
    })
    .catch((err) => {
      message.error({
        content: "Something Went Wrong While Saving",
        key: "safetyLoading",
      });
      console.error("Error editing safety", err);
    });
}

export default editCitation;
