export const fieldsJSON = ({
  form,
  statusTypes,
  address,
  vendorTypes,
  onAddressSelection,
}) => {
  const statusOptions = statusTypes.map((status) => ({
    label: status.statusName,
    value: status.statusName,
  }));

  const vendorOptions = vendorTypes;

  // const vendorTypes = ["type"];

  // const vendorOptions = vendorTypes.map((type) => ({
  //   label: type,
  //   value: type,
  // }));

  return [
    {
      label: "Vendor Name",
      formItemName: "vendorName",
      placeholder: "Vendor Name",
      type: "input",
      required: true,
    },
    {
      label: "Status",
      formItemName: "vendorStatus",
      placeholder: "Status",
      type: "select",
      customOptions: statusOptions,
      required: true,
    },
    {
      label: "Type",
      formItemName: "vendorType",
      placeholder: "Type",
      type: "select",
      customOptions: vendorOptions,
      required: true,
    },
    {
      form,
      label: "Address",
      formItemName: "vendorAddress",
      placeholder: "Click and Search...",
      type: "placesautocomplete",
      style: { paddingRight: 1, minWidth: 305 },
      onSelect: (value) => onAddressSelection(value),
      required: true,
    },
    {
      label: "Tax Rate",
      formItemName: "vendorTaxRate",
      placeholder: "Tax Rate",
      type: "input",
      disabled: true,
      required: true,
    },
  ];
};

export const bodyObject = ({ form }) => ({
  vendorName: form.getFieldValue("vendorName"),
  vendorStatus: form.getFieldValue("vendorStatus"),
  vendorType: form.getFieldValue("vendorType"),
  vendorAddress: form.getFieldValue("vendorAddress"),
  vendorTaxRate: form.getFieldValue("vendorTaxRate"),
});
