import dayjs from "dayjs";
import { FormGridStatusRenderer } from "../Components";
import { MondayButton } from "../../../commonComponents";
import { Plus } from "../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import { PlusIcon } from "../../../../assets";

export const ESTIMATION_INFORMATION = "Estimation Information";

const dropdownLabel = (label) => {
  return (
    <div>
      {`${label} - `}
      <span style={{ color: "red" }}>No Access</span>
    </div>
  );
};

export const footerButtons = ({
  onCancel,
  onSave,
  onOptionClick,
  suffix,
  saving,
}) => [
  {
    text: "Cancel",
    cancel: true,
    onClick: onCancel,
    className: "mondayButtonRed",
    tooltipCategory: "Estimations",
    tooltipKey: "cancel",
  },
  {
    text: "Finish",
    primary: true,
    onClick: onSave,
    disabled: !!saving,
    dropdownDisabled: !!saving,
    options: ["Save & New", "Save & Close"],
    onOptionClick: onOptionClick,
    className: "mondayButtonBlue saveButton",
    suffix: suffix,
    tooltipCategory: "Estimations",
    tooltipKey: "finish",
  },
];

export const fieldsJSON = ({
  form,
  filterGrid,
  coreFieldsDisabled,
  // opportunities,
  // projects,
  clients,
  leads,
  projectExecutives,
  formattedPExecutiveNames,
  statusByArch,
  statusByEng,
  architects,
  onDeselect,
  engineers,
  onSelect,
  accountManagers = [],
  priorityTypes,
  setSelectedCompanyName,
  setSelectedAccountManager,
  estimationTeam,
  setSelectedJobsite,
  engineerPopoverVisible,
  EngineerPopoverContent,
  archPopoverVisible,
  ArchPopoverContent,
  setSelectedEngineer,
  setSelectedArch,
  jobSiteAddress,
  clearGridFilter,
  setSelectedPrjExecutive,
  // selectedPrjExecutive,
  areAssigneesRequired,
  projectManagersOptions,
  setAddressTypeAndId,
  checkAccess,
  addressOptions,
  setClientModal,
  setNonUserModal,
}) => [
  {
    className: "estimationJobSiteAddress",
    label: "Jobsite Address",
    formItemName: "jobSiteAddress",
    placeholder: "Select...",
    type: "select",
    disabled: !!coreFieldsDisabled || !!jobSiteAddress,
    showSearch: true,
    required: true,
    allowClear: false,
    onSelect: (value, option) => {
      // filterGrid({ column: "jobSiteAddress", current: value });
      clearGridFilter("projectExecutive");
      setSelectedJobsite(value);
      setAddressTypeAndId({ type: option?.type, id: option?.key });
    },
    customOptions: addressOptions,
  },
  {
    label: "Company Name",
    formItemName: "accountName",
    placeholder: "Select...",
    type: "select",
    disabled: !!coreFieldsDisabled || !!jobSiteAddress,
    showSearch: true,
    required: true,
    onSelect: (value) => setSelectedCompanyName(value),
    dropdownRender: (menu) => {
      return (
        <>
          {menu}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MondayButton
              Icon={<PlusIcon />}
              onClick={() => setClientModal(true)}
            >
              New
            </MondayButton>
          </div>
        </>
      );
    },
    optionGroups: [
      !!checkAccess.clientAccess
        ? { label: "Clients", options: clients }
        : {
            label: dropdownLabel("Clients"),

            options: [],
          },
      !!checkAccess.leadAccess
        ? { label: "Leads", options: leads }
        : {
            label: dropdownLabel("Leads"),
            options: [],
          },
    ],
  },
  {
    label: "Project Executive",
    formItemName: "projectExecutive",
    placeholder: "Select...",
    type: "select",
    disabled: !!coreFieldsDisabled || !!jobSiteAddress,
    required: true,
    showSearch: true,
    onSelect: (value) => {
      const accountManager = projectExecutives?.find(
        ({ firstName, lastName }) => [firstName, lastName]?.join(" ") === value
      )?.accountManager;
      form.setFieldsValue({
        accountManager: accountManager[0],
        estimationsPExecutiveFilter: value,
      });
      setSelectedAccountManager(accountManager[0]);
      clearGridFilter("projectExecutive");
      filterGrid({ column: "projectExecutive", current: value });
      setSelectedPrjExecutive(value);
    },
    options: formattedPExecutiveNames,
  },
  {
    label: "Due Date",
    formItemName: "dueDate",
    type: "datepicker",
    disabledDate: (curr) => curr < dayjs(),
    style: { minWidth: "100%" },
  },

  {
    label: "Anticipated Start Date",
    formItemName: "anticipStartDate",
    type: "datepicker",
    disabledDate: (curr) =>
      curr > form.getFieldValue("anticipEndDate") || curr < dayjs(),
  },
  {
    label: "Anticipated End Date",
    formItemName: "anticipEndDate",
    type: "datepicker",
    disabledDate: (curr) =>
      curr < form.getFieldValue("anticipStartDate") || curr < dayjs(),
  },
  {
    label: "Eng. Assigned To",
    formItemName: "engAssignedTo",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    required: areAssigneesRequired,
    customOptions: engineers,
    onSelect: (value, option) => {
      clearGridFilter("archAssignedTo");
      filterGrid({ column: "engAssignedTo", current: value });
      form.setFieldsValue({ estimationsEngineerFilter: value });
      setSelectedEngineer({
        nameOfUser: option.nameOfUser,
        engineerId: option?.engineerId || "",
        userLink: option.userLink,
      });
    },
    dropdownRender: (menu) => {
      return (
        <>
          {menu}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MondayButton
              Icon={<PlusIcon />}
              onClick={() => setNonUserModal("engineers")}
            >
              New
            </MondayButton>
          </div>
        </>
      );
    },
    popoverVisible: engineerPopoverVisible,
    popoverContent: EngineerPopoverContent,
    popoverPlacement: "top",
  },
  {
    label: "Arch. Assigned To",
    formItemName: "archAssignedTo",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    required: areAssigneesRequired,
    customOptions: architects,
    onSelect: (value, option) => {
      clearGridFilter("engAssignedTo");
      filterGrid({ column: "archAssignedTo", current: value });
      form.setFieldsValue({ estimationsArchitectFilter: value });
      setSelectedArch({
        nameOfUser: option.nameOfUser,
        architectId: option?.architectId || "",
        userLink: option.userLink,
      });
    },
    dropdownRender: (menu) => {
      return (
        <>
          {menu}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MondayButton
              Icon={<PlusIcon />}
              onClick={() => setNonUserModal("architects")}
            >
              New
            </MondayButton>
          </div>
        </>
      );
    },
    popoverVisible: archPopoverVisible,
    popoverContent: ArchPopoverContent,
    popoverPlacement: "top",
  },
  {
    label: "Status By Eng",
    formItemName: "statusByEng",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    options: statusByEng,
  },
  {
    label: "Status By Arch",
    formItemName: "statusByArch",
    placeholder: "Select...",
    type: "select",
    showSearch: true,
    options: statusByArch,
  },

  {
    label: "Account Manager",
    formItemName: "accountManager",
    placeholder: "Select...",
    type: "select",
    required: true,
    showSearch: true,
    disabled: false,
    onSelect: (accountManage) => setSelectedAccountManager(accountManage),
    options: accountManagers,
  },
  {
    label: "Project Managers",
    formItemName: "projectManager",
    placeholder: "Select...",
    type: "select",
    mode: "multiple",
    disabled: !!coreFieldsDisabled || !!jobSiteAddress,
    showSearch: true,
    customOptions: projectManagersOptions,
    onChange: (val, option) => {
      form.setFieldValue(
        "projectManager",
        option.map((pM) => {
          const { label, value, cognitoUserId } = pM;
          return { label, value, cognitoUserId };
        })
      );
    },
  },
  {
    label: "Priority",
    formItemName: "estimPriority",
    placeholder: "Select...",
    type: "select",
    options: priorityTypes,
  },
  {
    label: "Team",
    formItemName: "estimationTeam",
    placeholder: "Select...",
    type: "select",
    customOptions: estimationTeam,
    mode: "multiple",
    onSelect: onSelect,
    onDeselect: onDeselect,
    required: true,
    allowClear: false,
  },
  {
    label: "Estimation Number",
    formItemName: "estimationNumber",
    placeholder: "Estimation Number...",
    type: "input",
    allowClear: false,
  },
  {
    label: "Quickbooks Number",
    formItemName: "quickbooksNumber",
    placeholder: "Quickbooks Number...",
    type: "input",
    allowClear: false,
    // required: true,
  },
  {
    label: "Building Identification",
    formItemName: "buildingIdentification",
    placeholder: "Building Identification...",
    type: "input",
    allowClear: false,
  },
];

export const body = ({
  form,
  existingEstStatus,
  alternativeAddresses,
  opportunityId,
  projectId,
  leadId,
  accountId,
  services,
  estimationLatitude,
  estimationLongitude,
  borough,
}) => ({
  services,
  alternativeAddresses,
  opportunityId,
  projectId,
  leadId,
  accountId,
  engProgress: 0,
  archProgress: 0,
  totalProgress: 0,
  estimationLatitude,
  estimationLongitude,
  borough,
  logo: "Core",
  estSTATUS: existingEstStatus || "Ready To Start",
  jobSiteAddress: form.getFieldValue("jobSiteAddress"),
  projectExecutive: form.getFieldValue("projectExecutive"),
  statusByEng: form.getFieldValue("statusByEng"),
  statusByArch: form.getFieldValue("statusByArch"),
  engAssignedTo: form.getFieldValue("engAssignedTo"),
  archAssignedTo: form.getFieldValue("archAssignedTo"),
  dueDate: !!form.getFieldValue("dueDate")
    ? dayjs(form.getFieldValue("dueDate")).valueOf()
    : undefined,
  anticipStartDate: !!form.getFieldValue("anticipStartDate")
    ? dayjs(form.getFieldValue("anticipStartDate")).valueOf()
    : undefined,
  anticipEndDate: !!form.getFieldValue("anticipEndDate")
    ? dayjs(form.getFieldValue("anticipEndDate")).valueOf()
    : undefined,
  accountManager: form.getFieldValue("accountManager"),
  estimPriority: form.getFieldValue("estimPriority"),
  accountName: form.getFieldValue("accountName"),
  assignNote: form.getFieldValue("assigneesToggle")
    ? form.getFieldValue("assigneesToggleReason")
    : undefined,
  projectManager: form.getFieldValue("projectManager"),
  estimationNumber: form.getFieldValue("estimationNumber"),
  quickbooksNumber: form.getFieldValue("quickbooksNumber"),
  buildingIdentification: form.getFieldValue("buildingIdentification")
});

export const projectStatuses = [
  {
    status: "Cancelled",
    color: "#787677",
  },
  {
    status: "Started",
    color: "#FF9C46",
  },
  {
    status: "On Hold",
    color: "#FF6292",
  },
  {
    status: "Paused",
    color: "#f0ad4e",
  },
  {
    status: "Ready To Start",
    color: "#009da1",
  },
];

export const gridSidebar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    },
  ],
  defaultToolPanel: "filters",
  hiddenByDefault: true,
};

export const dataGridColumns = [
  {
    headerName: "Jobsite Address",
    field: "jobSiteAddress",
    minWidth: 135,
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Status",
    controllerName: "status",
    field: "estSTATUS",
    filter: "agTextColumnFilter",
    minWidth: 150,
    resizable: true,
    sortable: true,
    cellRenderer: ({ value }) =>
      FormGridStatusRenderer({ value, statuses: projectStatuses }),
  },
  {
    headerName: "P. Executive",
    controllerName: "p. executive",
    filter: "agTextColumnFilter",
    field: "projectExecutive",
    resizable: true,
    sortable: true,
    minWidth: 126,
  },
  {
    headerName: "Due Date",
    controllerName: "due date",
    filter: "agTextColumnFilter",
    field: "dueDate",
    resizable: true,
    sortable: true,
    cellRenderer: (params) => {
      return dayjs(params.value).isValid()
        ? dayjs(params.value).format("MM/DD/YYYY")
        : "";
    },
  },
  {
    headerName: "Engineer",
    controllerName: "engineer",
    filter: "agTextColumnFilter",
    field: "engAssignedTo",
    minWidth: 108,
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Engineer Progress",
    field: "engProgress",
    cellRenderer: ({ value }) => `${value || 0}%`,
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Architect",
    controllerName: "architect",
    filter: "agTextColumnFilter",
    field: "archAssignedTo",
    minWidth: 116,
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Architect Progress",
    field: "archProgress",
    cellRenderer: ({ value }) => `${value || 0}%`,
    resizable: true,
    sortable: true,
  },
];

export const gridFilters = ({
  projectExecutives = [],
  engineers = [],
  architects = [],
}) => [
  {
    label: "Estimation Status",
    type: "select",
    column: "estSTATUS",
    formItemName: "estimationsStatusFilter",
    filterType: "equals",
    options: ["All", ...projectStatuses?.map(({ status }) => status)],
  },
  {
    label: "P. Executive",
    type: "select",
    column: "projectExecutive",
    formItemName: "estimationsPExecutiveFilter",
    filterType: "equals",
    options: ["All", ...projectExecutives],
  },
  {
    label: "Engineer",
    type: "select",
    column: "engAssignedTo",
    filterType: "equals",
    formItemName: "estimationsEngineerFilter",
    options: ["All", ...engineers.map(({ nameOfUser }) => nameOfUser)],
  },
  {
    label: "Architect",
    type: "select",
    column: "archAssignedTo",
    filterType: "equals",
    formItemName: "estimationsArchitectFilter",
    options: ["All", ...architects.map(({ nameOfUser }) => nameOfUser)],
  },
];
