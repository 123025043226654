import { DEG_DATE_FORMAT } from "../utils/cellFunctions";
import { MondayButton } from "src/components/commonComponents";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import {
  parseInTz,
  setHourMinute,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

export const shiftFields = ({
  form,
  index,
  shifts,
  launch,
  sowList,
  jobsites,
  endShiftDate,
  startShiftDate,
  onJobsiteSelect,
  getTimeConflict,
  updateFormValue,
  showAllServices,
}) => {
  const formList = form.getFieldValue("shifts");

  return [
    {
      type: "select",
      required: true,
      label: "Jobsite",
      showSearch: true,
      optionFilterProp: "label",
      getPopUpContainer: document.body,
      placeholder: "Select a Jobsite...",
      formItemName: [index, `selectedJobsite`],
      onSelect: (e) => onJobsiteSelect(e, index),
      customOptions: jobsites.map(
        ({ jobsiteId, jobName, accountName }, key) => ({
          key,
          value: jobsiteId,
          label: `${jobName}-${accountName?.accountName || ""}`,
        })
      ),
    },
    {
      label: "SOW",
      type: "select",
      required: false,
      mode: "multiple",
      getPopUpContainer: document.body,
      placeholder: "Select services...",
      formItemName: [index, `services${index}`],
      customOptions: (sowList?.[`services${index}`] || [])?.map(
        (opt, index) => ({
          key: index,
          value: opt,
          label: opt,
        })
      ),
      dropdownRender: (menu) => {
        return (
          <main>
            {menu}
            <section
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MondayButton
                hasIcon={false}
                className="mondayButtonBlue"
                onClick={() => showAllServices({ index })}
              >
                Show all Services
              </MondayButton>
            </section>
          </main>
        );
      },
    },
    {
      form,
      required: true,
      allowClear: false,
      formListName: "shifts",
      secondaryDarkMode: true,
      type: "customTimeInput",
      label: "Shift Start Time",
      selectedDate: startShiftDate,
      getPopUpContainer: document.body,
      placeholder: "Select a clock in time...",
      formItemName: [index, `selectedTime`],
      onChange(e) {
        const previousShift = formList?.[index - 1];
        if (
          previousShift?.selectedEndTime?.valueOf() > parseInTz(e).valueOf() &&
          shifts?.length === 1
        ) {
          updateFormValue(
            "selectedTime",
            index,
            setHourMinute(endShiftDate, parseInTz(e))
          );
          getTimeConflict({
            index,
            formData: form.getFieldValue("shifts"),
          });
        } else {
          updateFormValue(
            "selectedTime",
            index,
            setHourMinute(endShiftDate, parseInTz(e))
          );
          getTimeConflict({
            index,
            formData: form.getFieldValue("shifts"),
          });
        }
      },
    },
    {
      form,
      required: true,
      allowClear: false,
      formListName: "shifts",
      label: "Shift End Time",
      type: "customTimeInput",
      secondaryDarkMode: true,
      selectedDate: startShiftDate,
      getPopUpContainer: document.body,
      placeholder: "Select a clock in time...",
      formItemName: [index, `selectedEndTime`],
      onChange(e) {
        let time =
          shifts.length > 1
            ? setHourMinute(startShiftDate, parseInTz(e))
            : setHourMinute(startShiftDate, parseInTz(e));

        if (formList[index]?.selectedTime > time && shifts?.length === 1) {
          time = setHourMinute(endShiftDate, time);
        }

        updateFormValue("selectedEndTime", index, time);
        getTimeConflict({
          index,
          formData: form.getFieldValue("shifts"),
        });

        if (!!form?.getFieldValue("shifts")?.[index + 1]) {
          updateFormValue("selectedTime", index + 1, time.add(1, "minute"));
          getTimeConflict({
            index: index + 1,
            formData: form.getFieldValue("shifts"),
          });
        }
      },
    },
    !!launch?.includes(index) && {
      form,
      required: true,
      allowClear: false,
      formListName: "shifts",
      type: "customTimeInput",
      secondaryDarkMode: true,
      label: "Lunch Start Time",
      selectedDate: startShiftDate,
      getPopUpContainer: document.body,
      placeholder: "Select a launch in time...",
      formItemName: [index, "launchStartTime"],
      onChange(e) {
        const launchDate = dayjsNY(
          formList?.[index]?.selectedTime,
          DEG_DATE_FORMAT
        );

        const isLaunchNextDay =
          formList?.[index]?.selectedTime.valueOf() >
          setHourMinute(launchDate, parseInTz(e)).valueOf();

        updateFormValue(
          `launchStartTime`,
          index,
          setHourMinute(
            isLaunchNextDay && shifts?.length > 1 ? endShiftDate : launchDate,
            parseInTz(e)
          )
        );

        getTimeConflict({
          index,
          formData: form.getFieldValue("shifts"),
        });
      },
    },
    !!launch?.includes(index) && {
      form,
      required: true,
      allowClear: false,
      formListName: "shifts",
      label: "Lunch End Time",
      secondaryDarkMode: true,
      type: "customTimeInput",
      selectedDate: startShiftDate,
      getPopUpContainer: document.body,
      formItemName: [index, "launchEndTime"],
      placeholder: "Select a launch in time...",
      onChange(e) {
        const launchDate = dayjsNY(
          formList?.[index]?.selectedTime,
          DEG_DATE_FORMAT
        );

        const isLaunchNextDay =
          formList?.[index]?.selectedTime.valueOf() >
          setHourMinute(launchDate, parseInTz(e)).valueOf();

        updateFormValue(
          `launchEndTime`,
          index,
          setHourMinute(
            isLaunchNextDay ? endShiftDate : launchDate,
            parseInTz(e)
          )
        );
        getTimeConflict({
          index,
          formData: form.getFieldValue("shifts"),
        });
      },
    },
  ].filter(Boolean);
};
