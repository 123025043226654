import "./HorizontalProgressChart.scss";
import { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import ProgressLine from "./ProgressLine/ProgressLine";
import { useChartRefsContext } from "../../../providers/ChartRefsContext";

const HorizontalProgressChart = ({
  chartData: { labels, data, backgroundColor },
  onClick = () => {},
  title,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const { registerChartRef } = useChartRefsContext();
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      registerChartRef(chartRef.current, title);
    }
  }, [registerChartRef]);

  return (
    <div className="card-horizontal-progress-chart">
      <InputComponent
        value={searchValue}
        placeholder="Search here..."
        onChange={(event) => setSearchValue(event.target.value)}
        suffixIcon={<SearchOutlined />}
      />
      <div className="progress-items" ref={chartRef}>
        {labels
          .filter((label = "") =>
            label.toString().toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((label = "", index) => {
            return (
              <ProgressLine
                key={index}
                {...{
                  label: label.toString(),
                  value: data[index],
                  background: backgroundColor[index % backgroundColor.length],
                  onClick: () => {
                    onClick(_, [{ index }]);
                  },
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default HorizontalProgressChart;
