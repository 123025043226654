import { useContext, useRef, useState } from "react";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { Drawer, Modal, Tooltip, message } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import "./VideoPlayerModal.scss";
import VideoCard from "../VideoCard/VideoCard";
import { v4 } from "uuid";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import { EmailIcon } from "../../../Approvals/assets";
import EmailBox from "../../../../../SidebarPages/Communication/components/EmailBox/EmailBox";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import { useNavigate } from "react-router-dom";
import { useEditLogs, useResponsive } from "../../../../../../hooks";
import { updatePreferences } from "../../../../../../actions/preferences";
import { useDispatch } from "react-redux";
import { NotesIcon } from "../../../../../../icons";
import { convertMinutesToSeconds } from "../../utils/convertMinutesToSeconds";
import VideoPlayerComponent from "../VideoPlayerComponent/VideoPlayerComponent";

export const VideoPlayerModal = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    primaryArray,
    videoActive,
    setSubCategoryName,
    setActivePrimaryCat,
    setIdSubActive,
    setCustomeModal,
    customeModal,
    setVideoActive,
    playingVideo,
    favOrNewCategory,
    favourites,
    newVideos,
    setHasWatched,
    playedVideos,
  } = useContext(VideoTutorialContext);

  const [openModal, setOpenModal] = useState(false);
  const [videoEnd, setVideoEnd] = useState(false);
  const [emailBox, setEmailBox] = useState(false);
  const [videosToSend, setVideosToSend] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [showTranscript, setShowTranscript] = useState(false);
  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const videoPlayerRef = useRef();

  const { mobile, tablet } = useResponsive();
  const { googleAccessToken } = useGoogleSignIn();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { saveAddedLogs, handleFetchLogs } = useEditLogs();

  const addWatchedVideo = async () => {
    const totalVideoTime = convertMinutesToSeconds(
      playingVideo.lengthVideoInMin
    );

    if (
      videoPlayerRef?.current?.getCurrentTime() > 0 &&
      videoPlayerRef?.current?.getCurrentTime() < totalVideoTime
    ) {
      const doesExists = playedVideos?.find(
        (video) => video?.id === playingVideo?.id
      );

      const updatedPlayedVideos = doesExists
        ? playedVideos.map((video) =>
            video.id === playingVideo?.id
              ? {
                  id: video.id,
                  time: videoPlayerRef?.current?.getCurrentTime(),
                }
              : { id: video.id, time: video.time }
          )
        : [
            ...playedVideos.map((video) => ({
              id: video.id,
              time: video.time,
            })),
            {
              id: playingVideo.id,
              time: videoPlayerRef?.current?.getCurrentTime(),
            },
          ];

      const preferencesToSave = {
        ...allPreferences.preferences,
        videoPreferences: {
          ...allPreferences.preferences.videoPreferences,
          playedVideos: updatedPlayedVideos,
        },
      };

      try {
        await API.patch("preferences", `/preferences`, {
          body: {
            preferences: preferencesToSave,
          },
        });
        dispatch(updatePreferences(preferencesToSave));

        if (!doesExists) {
          saveAddedLogs({
            actionType: "Partial Watched",
            category: "Video Tutorials",
            recordId: playingVideo?.id,
            recordName: "Partial Watched",
            updatedKeys: [
              {
                type: v4(),
                key: "",
                label: playingVideo?.videoName,
                previousValue: "",
                updatedValue: "Partial Watched",
              },
            ],
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    setVideoEnd(false);
  };

  const handleCancel = async () => {
    navigate(location.pathname); //remove query parameters if there are
    await addWatchedVideo();

    setCustomeModal(true);
    setIdSubActive(null);
    setVideoActive();
    setHasWatched(false);
    setSubCategoryName("");
    setActivePrimaryCat();
  };

  const onEmailSave = ({ e, key, type, setLoading }) => {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  };

  const sendEmailNotification = () => {
    broadcastNotification(
      "37",
      "onVideoTutorial",
      [
        {
          common: userConfiguration?.nameOfUser,
          action: "sent email, tutorial video",
          commonNext: playingVideo?.videoName,
        },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: userConfiguration?.identityId,
        },
      ],
      []
    );
  };

  const checkSumVideo = () => {
    const videos = [];
    let totalSumVideos = 0;

    if (favOrNewCategory === "fav") {
      favourites.forEach(
        (video) => (totalSumVideos += Number(video.lengthVideoInMin))
      );
    } else if (favOrNewCategory === "new") {
      newVideos.forEach(
        (video) => (totalSumVideos += Number(video.lengthVideoInMin))
      );
    } else if (favOrNewCategory === "unfinished") {
      playedVideos.forEach(
        (video) => (totalSumVideos += Number(video.lengthVideoInMin))
      );
    } else {
      primaryArray.map((category) =>
        category.subCategories.map((subCat) =>
          subCat.videos.map(
            (video) =>
              video.videoLink === videoActive &&
              video.videoName === playingVideo?.videoName &&
              videos.push([...subCat.videos])
          )
        )
      );
      videos[0]?.map(
        (video) => (totalSumVideos += Number(video.lengthVideoInMin))
      );
    }

    return Math.round((Number(totalSumVideos) + Number.EPSILON) * 100) / 100;
  };

  const VideoCards = () => {
    const videos = [];

    if (favOrNewCategory === "fav") {
      videos.push(...favourites);
    } else if (favOrNewCategory === "new") {
      videos.push(...newVideos);
    } else if (favOrNewCategory === "unfinished") {
      videos.push(...playedVideos);
    } else {
      primaryArray.map((category) =>
        category.subCategories.map((subCat) =>
          subCat.videos.map((video) => {
            if (
              video.videoLink === videoActive &&
              video.videoName === playingVideo.videoName
            ) {
              videos.push(...subCat.videos);
            }
          })
        )
      );
    }

    return videos?.map((video) => (
      <VideoCard
        key={video.id}
        data={video}
        currentVideo={video.id === playingVideo.id}
        isFavourite={favOrNewCategory === "fav" ? true : false}
        isNewVideo={favOrNewCategory === "new" ? true : false}
        isUnfinishedVideo={favOrNewCategory === "unfinished" ? true : false}
        onOpen={() => addWatchedVideo()}
      />
    ));
  };

  return !mobile ? (
    <Modal
      title={
        <>
          <span className="title">{playingVideo?.videoName}</span>
          <InfoCircleFilled
            style={{
              fontSize: "20px",
              color: "#1264A3",
            }}
          />
          <span>
            The total airtime of the videos is :<b> {checkSumVideo()}</b> min
          </span>
        </>
      }
      open={!customeModal ? true : false}
      closeIcon={<XIcon />}
      footer={
        <>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={handleCancel}
          >
            Close
          </MondayButton>
          <div className="right-buttons">
            <Tooltip title="Notes">
              <MondayButton
                className="mondayButtonBlue"
                Icon={<NotesIcon style={{ width: "23px", height: "23px" }} />}
                onClick={() => setNotesModalVisible(true)}
              />
            </Tooltip>
            {!emailBox && (
              <MondayButton
                className="mondayButtonBlue"
                Icon={<EmailIcon />}
                onClick={() => setOpenModal(true)}
              >
                Send Email
              </MondayButton>
            )}
            {emailBox && playingVideo && (
              <div className="requestEmailBox">
                <EmailBox
                  {...{
                    onClose() {
                      setEmailBox(false);
                      setVideosToSend([]);
                    },
                    setEmails() {},
                    rowData: videosToSend,
                    attachments,
                    authToken: googleAccessToken,
                    onSave(e, type, setLoading) {
                      onEmailSave({ e, type, setLoading });
                    },
                    isFromCharge: true,
                    isRedirected: [],
                    sendEmailNotification,
                    proppedRecordId: playingVideo?.Id,
                    proppedTemplateCat: "Tutorial Videos",
                  }}
                />
              </div>
            )}
          </div>
        </>
      }
      onCancel={handleCancel}
      destroyOnClose={true}
      className={`playerModal ${isDarkMode && "playerModalDark"}`}
    >
      <div
        className={`videoModal-container ${
          tablet && "videoModal-container-tablet"
        }  ${mobile && "videoModal-container-mobile"}`}
      >
        <VideoPlayerComponent
          {...{
            openModal,
            setOpenModal,
            videoEnd,
            setVideoEnd,
            setEmailBox,
            videosToSend,
            setVideosToSend,
            setAttachments,
            showTranscript,
            setShowTranscript,
            videoPlayerRef,
            saveAddedLogs,
            handleFetchLogs,
            notesModalVisible,
            setNotesModalVisible,
          }}
        />
        {!showTranscript && (
          <div className="containerOpenModal">{VideoCards()}</div>
        )}
      </div>
    </Modal>
  ) : (
    <Drawer
      title={playingVideo?.videoName}
      open={!customeModal ? true : false}
      onClose={() => setCustomeModal(false)}
      closable={false}
      footer={
        <>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={handleCancel}
          >
            Close
          </MondayButton>
          <div className="right-buttons">
            <Tooltip title="Notes">
              <MondayButton
                className="mondayButtonBlue"
                Icon={<NotesIcon style={{ width: "23px", height: "23px" }} />}
                onClick={() => setNotesModalVisible(true)}
              />
            </Tooltip>
            {!emailBox && (
              <MondayButton
                className="mondayButtonBlue"
                Icon={<EmailIcon />}
                onClick={() => setOpenModal(true)}
              >
                Send Email
              </MondayButton>
            )}
            {emailBox && playingVideo && (
              <div className="requestEmailBox">
                <EmailBox
                  {...{
                    onClose() {
                      setEmailBox(false);
                      setVideosToSend([]);
                    },
                    setEmails() {},
                    rowData: videosToSend,
                    attachments,
                    authToken: googleAccessToken,
                    onSave(e, type, setLoading) {
                      onEmailSave({ e, type, setLoading });
                    },
                    isFromCharge: true,
                    isRedirected: [],
                    sendEmailNotification,
                    proppedRecordId: playingVideo?.Id,
                    proppedTemplateCat: "Tutorial Videos",
                  }}
                />
              </div>
            )}
          </div>
        </>
      }
      onCancel={handleCancel}
      destroyOnClose={true}
      className={`playerModal-drawer ${isDarkMode && "playerModal-drawerDark"}`}
    >
      <VideoPlayerComponent
        {...{
          openModal,
          setOpenModal,
          videoEnd,
          setVideoEnd,
          setEmailBox,
          videosToSend,
          setVideosToSend,
          setAttachments,
          showTranscript,
          setShowTranscript,
          videoPlayerRef,
          saveAddedLogs,
          handleFetchLogs,
          notesModalVisible,
          setNotesModalVisible,
        }}
      />
      {!showTranscript && (
        <div className="containerOpenModal">{VideoCards()}</div>
      )}
    </Drawer>
  );
};
