import { useSelector } from "react-redux";
import { StarFilled, StarOutlined } from "./assets";
import "./StarCheckbox.scss";

const StarCheckbox = ({ checked, onChange }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      id="favoriteToggle"
      className={`starCheckbox`}
      onClick={(e) => {
        e.preventDefault();
        onChange(!checked);
      }}
    >
      {!!checked ? (
        <StarFilled />
      ) : (
        <StarOutlined fill={isDarkMode ? "#fff" : "#000"} />
      )}
    </div>
  );
};

export default StarCheckbox;
