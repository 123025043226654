import {
  CheckOutlined,
  ClearOutlined,
  CloseOutlined,
  PlusOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useState } from "react";
import { API } from "aws-amplify";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Input, Tour, message } from "antd";

import {
  ConfirmationModal,
  MondayButton,
} from "../../../../../../../commonComponents";
import { CopyIcon } from "../../../../../../DynamicView/src";
import { LeftArrow } from "../../../../../../BasePage/src";
import { preferences as preferencesDispatch } from "../../../../../../../../actions/preferences";
import DynamicEditLogs from "../../../../../../../commonComponents/DynamicEditLogs/DynamicEditLogs";
import { findTutorialSteps } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { formatCategoryName } from "../../../../../../../pages/Settings/settingsComponents/DocumentationConfiguration/View/utils";
import { InputComponent } from "../../../../../../Fleet/components";

const ConfigurationPanel = ({
  CategoryIcon,
  selectedCategory = {},
  onBack,
  onCloneExistingReport,
  onCreateNewReport,
  searchKeyword,
  dragItems,
  playDrag,
  setPlayDrag,
  setDragItems,
  onSearchChange,
  retrieveFilter,
}) => {
  const { categoryName, department, reportsAvailable = [] } = selectedCategory;
  const [activeFilters, setActiveFilters] = useState({});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { createdAt } = activeFilters || {};
  const { programFields = [] } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [tourOpen, setTourOpen] = useState(false);
  programFields;

  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const setFilter = (filter, value) => {
    retrieveFilter({ ...activeFilters, [filter]: value });
    setActiveFilters((prev) => ({ ...prev, [filter]: value }));
  };

  const dispatch = useDispatch();

  function stepsMapHelper(title) {
    const refs = {
      "Filter By Date": document.querySelector(".filterDatepicker"),
      Search: document.querySelector(".searchField"),
      "Clone Report": document.querySelector(".cloneBtn"),
      "Create New Report": document.querySelector(".createBtn"),
      "Favorite Reports": document.querySelector("#favoriteToggle"),
      "Edit Report": document.querySelector("#firstReportPreviewCardContainer"),
      "Add Notes": document.querySelector(".notesIcon"),
      "Delete Report": document.querySelector("#deleteBtn"),
      "Preview Report": document.querySelector(
        "#firstReportPreviewCardContainer"
      ),
    };
    return refs[title] || null;
  }
  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    reportsAvailable.length < 1 && newSteps.splice(5, 5);
    return newSteps;
  }
  // const dbSteps = mapRefs(steps);
  const dbSteps = mapRefs(
    findTutorialSteps("reportsCategoryView", programFields)
  );

  const handleSaveReportsOrder = () => {
    const updatedPreferences = {
      ...(allPreferences?.preferences || {}),
      ReportsPageSettings: dragItems.map(({ reportId }) => reportId),
    };

    message.loading("Saving...");
    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        message.destroy();
        message.success("Changes Saved In Your Preferences!");
        setPlayDrag(false);
        dispatch(
          preferencesDispatch({
            ...allPreferences,
            preferences: updatedPreferences,
          })
        );
      })
      ?.catch((err) => {
        console.error("Error saving to preferences", err);
        setDragItems(reportsAvailable);
        setPlayDrag(false);
      });
  };

  const handleClearOrderPreference = async () => {
    if (!allPreferences?.preferences?.ReportsPageSettings) {
      message.info("There is no preference saved before...");
      return;
    }
    setOpenConfirmModal(true);
  };

  const handleOnConfirmModal = () => {
    message.loading("Saving...");
    const { ReportsPageSettings, ...rest } = allPreferences?.preferences;
    const updatedPreferences = rest;

    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        message.destroy();
        message.success("Order preference has been deleted!");
        dispatch(
          preferencesDispatch({
            ...allPreferences,
            preferences: updatedPreferences,
          })
        );
      })
      ?.catch((err) => console.error(err));
  };

  return (
    <div className="categoryConfigPanel">
      <div className="categoryConfigHeader-div">
        <div className="leftSection">
          <MondayButton
            className="mondayButtonBlue goBack-button"
            Icon={<LeftArrow />}
            onClick={() => onBack()}
          >
            Go Back
          </MondayButton>
        </div>

        <div className="rightSection">
          <InputComponent
            {...{
              allowClear: true,
              onChange: onSearchChange,
              placeholder: "Search...",
              className: "searchField",
              addonBefore: <SearchOutlined />,
            }}
          />
          <MondayButton
            {...{
              className: "mondayButtonBlue tourButton",
              Icon: <QuestionCircleFilled width={18} />,
              onClick: () => {
                dbSteps.length > 0
                  ? setTourOpen(true)
                  : message.info("No guide available for this form");
              },
              tooltipKey: "showGuide",
            }}
          />

          <MondayButton
            data-testid="clear-order"
            className="mondayButtonBlue"
            Icon={<ClearOutlined />}
            onClick={handleClearOrderPreference}
            tooltipKey="clearOrderPrefs"
          />
          <MondayButton
            {...{
              className: "mondayButtonBlue cloneBtn",
              Icon: <CopyIcon />,
              onClick: onCloneExistingReport,
              tooltipKey: "cloneExistingReport",
            }}
          >
            Clone
          </MondayButton>
          <MondayButton
            {...{
              className: "createBtn",
              Icon: <PlusOutlined />,
              onClick: onCreateNewReport,
              tooltipKey: "newReport",
            }}
          >
            Create New
          </MondayButton>
        </div>
      </div>

      {/* <div className="footerSection">
        <MondayButton
          {...{
            Icon: <CloseOutlined />,
            className: "mondayButtonRed",
            onClick: () => {
              setDragItems(reportsAvailable);
              setPlayDrag(false);
            },
          }}
        >
          Cancel Changes
        </MondayButton>
        <MondayButton
          data-testid="save-changes"
          {...{
            Icon: <CheckOutlined />,
            className: "mondayButtonGreen",
            onClick: handleSaveReportsOrder,
            tooltipKey: "saveCategoryChanges",
            disabled:
              !playDrag ||
              searchKeyword.length !== 0 ||
              !isNaN(activeFilters?.createdAt),
          }}
        >
          Save Changes
        </MondayButton>
      </div> */}

      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={dbSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}

      {openConfirmModal && (
        <ConfirmationModal
          onConfirm={handleOnConfirmModal}
          visible={openConfirmModal}
          setVisible={setOpenConfirmModal}
          title="Confirm"
          text="Are you sure you want to clear order preference"
        />
      )}
    </div>
  );
};

export default ConfigurationPanel;
