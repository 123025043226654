import { useEffect, useState } from "react";
import { useRedux } from "../../../../../hooks";
import { Alert, Button, Space, message } from "antd";
import {
  attachSpanPriceToPLI,
  defaultColDefPLIAddon,
  getColumnsPLIAddon,
} from "./gridHelpers";
import { getAddonsColumns } from "../../../tools/columnDefiners/addons/AddonsColumnDefiner";
import CheckboxRenderer from "../../../../../Projects/DataEntryGrid/subcomponents/CheckboxRenderer";
import AddonEditor from "../../cellRenderers/AddonEditor/addonEditor";
import rtfEditor from "../../cellRenderers/rtfEditor/rtfEditor";
import rtfEditorAddon from "../../cellRenderers/rtfEditorAddon/rtfEditorAddon";
import SidewalkShedSpansDetail from "../../SidewalkShed/SidewalkShedAddonsDetail/subcomponents/SidewalkShedSpansDetail/sidewalkShedSpansDetail";
import SpanCellRenderer from "../../SidewalkShed/SidewalkShedAddonsDetail/SpanCellRenderer";
import GeneralAddonEditor from "../../cellRenderers/AddonEditor/GeneralAddonEditor";
import SelectCellEditor from "../../cellRenderers/SelectEditor/SelectCellEditor";
import { AgGridReact } from "ag-grid-react";
import { AddonType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { darkModeRowStyleRules } from "../../../tools/formatters/GridStyles";
import {
  createPLIAddon,
  createSideWalkShedPLIAddon,
} from "../../../tools/polyfillers/AddonsCreator";
import GeneralAddonEditorFunc from "../../cellRenderers/AddonEditor/GeneralAddonEditorFunc";
import { getNewId } from "../../../tools/polyfillers";
import { SidewalkShedPLIAddonSpan } from "../../../models/SidewalkShedModels";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { calculateTotalPrice } from "../../../tools/formatters/pricing";
import { MondayButton } from "../../../../../../commonComponents";
import { PLIAddonSuggestor } from "../../../tools/columnDefiners/addons/PLIAddonSuggestor";
import { pliTaxAmountCalculate } from "../../../tools/controllers/CellEditingEvents";
import useTakeOffAccessRights from "../../../tools/controllers/useTakeOffAccessRights";

function PLIAddon(props) {
  const [state, setState] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [isWritable, setIsWritable] = useRedux("estimationsIsWritable");
  const [gridData, setGridData] = useState([]); // props?.data?.addons ?? [
  const rowId = props?.node?.id ?? null;
  const masterGridApi = props?.api ?? null;
  const [tableData, setTakeOffTableData] = useRedux("takeOffTableData");
  const {
    canChangeStatus = false,
    canAccessProposalDesigner = false,
    writeModeClearance = false,
    canViewPrice = false,
  } = useTakeOffAccessRights("Take-Off");
  const resize = () => {
    gridApi.sizeColumnsToFit();
  };
  const onGridReady = (params, cb = () => {}) => {
    const tempApi = params.api;
    const gridData = props?.data?.addons ?? [];
    if (!!masterGridApi) {
      masterGridApi.addDetailGridInfo(rowId, {
        id: rowId,
        api: tempApi,
        columnApi: params.columnApi,
        // handleUpdateDetailState: this.handleUpdateDetailState,
      });
    }
    tempApi.setRowData(gridData);
    setGridData(gridData);
    // tempApi.setDomLayout("autoHeight");
    // tempApi.sizeColumnsToFit();
    setGridApi(tempApi);
    applyPLIAddonFormulas(params);
    // cb();
  };

  const handleSave = () => {
    // props.saveSidewalkShedAddonsData(
    //   props.api,
    //   props.indexes.serviceIndex,
    //   props.indexes.optionIndex,
    //   props.indexes.elevationIndex,
    //   props.data.id,
    //   gridData
    // );
  };

  //   const Temp = <GeneralAddonEditor props={{ ...props }} />;

  const frameworkComponents = {
    CheckboxRenderer: CheckboxRenderer,
    AddonEditor: AddonEditor,
    rtfEditor: rtfEditor,
    rtfEditorAddon: rtfEditorAddon,
    SidewalkShedSpansDetail: SidewalkShedSpansDetail,
    SpanCellRenderer: SpanCellRenderer,
    GeneralAddonEditor: GeneralAddonEditor,
    // GeneralAddonEditor: GeneralAddonEditorFunc,
    SelectEditor: SelectCellEditor,
  };

  // TODO: addSpan
  const addSpan = (addonId) => {
    if (isWritable) {
      let tempData = structuredClone(gridData); //_.cloneDeep(this.gridData); //all addons from this row
      //modify addons by adding a new span in the correct addon
      for (const addon of tempData) {
        if (addon.id === addonId) {
          //find the correct addon
          //check if addon have enough data
          if (addon.sideWalkShedtype === undefined) {
            message.error(
              "Please, fill the addon with data then create a span."
            );
            return;
          }

          //add a new span
          const highestId = getNewId(addon.span); //find the highest id from spans. If there is no span the highest id will be 0
          //insert a new span into span array
          addon.span.push(new SidewalkShedPLIAddonSpan(highestId)); //use the new id which is higher than all other ids
          let currData = props.data.addons?.find((el) => el.id === addonId);
          if (!!currData) currData.span = addon.span;
          break;
        }
      }
      let temp = [];
      gridApi.forEachNode((node) => {
        temp.push(node.data);
      });
      // console.log("tempChanged", {
      //   temp,
      //   changedObj,
      //   valueFromNameChange,
      //   params,
      //   props,
      // });
      gridApi.setRowData(tempData);
      setGridData(tempData);
      handleSave();
      // this.gridApi.setRowData(gridData);
      // props.saveSidewalkShedAddonsData(
      //   props.api,
      //   props.indexes.serviceIndex,
      //   props.indexes.optionIndex,
      //   props.indexes.elevationIndex,
      //   props.data.id,
      //   tempData,
      //   { addonId, api: gridApi }
      // );
      props.api.getRowNode(addonId).setExpanded(true);
    } else {
      message.error("Please enable write mode.");
    }
  };

  // TODO: removeLastSpan
  const removeLastSpan = (addonId) => {
    if (!isWritable) {
      message.error("Please enable write mode.");
      return;
    }
    let tempData = structuredClone(gridData); //_.cloneDeep(this.gridData); //all addons from this row
    for (const addon of tempData) {
      if (addon.id === addonId) {
        //find the correct addon
        if (addon.span.pop()) {
          //if we removed the span
          // this.gridApi.setRowData(gridData); //save changed to database
          setGridData(tempData);
          props.saveSidewalkShedAddonsData(
            props.api,
            props.indexes.serviceIndex,
            props.indexes.optionIndex,
            props.indexes.elevationIndex,
            props.data.id,
            tempData
          );
          props.context.handleSave(false, true);
        } else {
          //if span array has been empty
          message.warning("There is no span to remove");
        }
        break;
      }
    }
  };

  const columnResized = (params) => {
    if (params?.api) {
      let paramsApi = params.api;
      paramsApi.resetRowHeights();
    }
  };

  const cellEditStarted = (params) => {
    if (!isWritable) {
      if (params?.api) {
        let paramsApi = params.api;
        paramsApi.stopEditing(true);
      }
      message.error("Please enable write mode");
    }
  };

  // TODO: cellValueChanged
  const cellValueChanged = (params, valueFromNameChange) => {
    console.log("cellValueChanged", { params, valueFromNameChange });
    const changedObj = params.data || {};

    if (params.colDef.field === "name") {
      //if name changed, change other data too
      for (const key of valueFromNameChange.values) {
        console.log("currKey", key);
        if (key.name.toLowerCase() !== "name") {
          // Check if formula
          changedObj[key.name.toLowerCase()] = key.value;
        } else {
          changedObj[key.name.toLowerCase()] = valueFromNameChange; //assign name object
        }
      }
    }
    // if (params.colDef.field === "spanPrice" && params.data.approved) {
    //   props.data.totalPrice +=
    //     Number(params.newValue || 0) - Number(params.oldValue || 0);
    // }
    if (params.colDef.field === "approved") {
      if (params.newValue) {
        props.data.totalPrice += Number(params.data.spanPrice || 0);
      } else {
        props.data.totalPrice -= Number(params.data.spanPrice || 0);
      }
    }
    applyPLIAddonFormulas(params);
    let temp = [];
    gridApi.forEachNode((node) => {
      temp.push(node.data);
    });
    // console.log("tempChanged", {
    //   temp,
    //   changedObj,
    //   valueFromNameChange,
    //   params,
    // });
    gridApi.setRowData(temp);
    setGridData(temp);
    attachPriceToPLI({ data: props.data, addons: temp, api: props.api });
    if (props?.service?.serviceId === 1) {
      attachSpanPriceToPLI({ data: props.data, addons: temp, api: props.api });
    }

    props.context.handleSave(false, true);
  };

  // TODO: onRichtextChange
  const onRichTextChange = (modifiedAddon) => {
    if (!isWritable) {
      message.error("Please enable write mode");
      return;
    }
    const temp = [...gridData];
    let index = temp.findIndex((item) => item.id === modifiedAddon.id);
    if (index !== -1) {
      temp[index] = modifiedAddon;
    }
    setGridData(temp);
    let { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    props.onAddonRichTextChange({
      addons: temp,
      addonType: AddonType.pli,
      serviceIndex,
      optionIndex,
      elevationIndex,
      pliId: props.data.id,
    });
  };

  // TODO: appendAddonRow
  const appendAddonRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (!isWritable) {
      message.error("Please enable write mode");
      return;
    }
    let addons = structuredClone(gridData);
    if (Array.isArray(addons)) {
      addons.forEach((item) => {
        if (item.id === itemId) {
          //when we go to the position that we want t append row
          let addon;
          if (this.props.service.serviceId.toString() === "1") {
            addon = createSideWalkShedPLIAddon(
              itemId + 1,
              item.length,
              item.height,
              item.width
            );
          } else addon = createPLIAddon(itemId + 1);

          addons.splice(i + 1, 0, addon);
          i++; //skip the nex position as it is new new added id
        } else if (item.id > itemId) {
          //all elements with id higher than the added element must be increased by 1
          item.id++;
        }
      });
      console.log("appendAddonRow", { addons, gridData });
      props.data.addons = addons;
      props.api?.applyTransaction({
        update: [props.data],
      });
      gridApi.setRowData(addons);
      setGridData(addons);
    }
  };

  // TODO: duplicateAddonRow
  const onDuplicate = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (!isWritable) {
      message.error("Please enable write mode");
      return;
    }
    let addons = structuredClone(gridData);
    console.log("onDuplicate", { addons, gridData });
    if (Array.isArray(addons) && addons.length > 0) {
      let lastAddon = addons[addons.length - 1];
      let newAddonCloned = structuredClone(lastAddon);
      newAddonCloned.id = lastAddon.id + 1;
      addons.push(newAddonCloned);
    }
    // for (let i = 0; i < addons.length; i++) {
    //   if (addons[i].id === itemId) {
    //     addons.splice(i + 1, 0, addons[i]);
    //     i++; //skip the nex position as it is new new added id
    //   }
    //   if (addons[i].id > itemId) {
    //     //all pli that have higher ID that then deleted pli must decrease their id by 1
    //     addons[i].id++;
    //   }
    // }
    props.data.addons = addons;
    props.api?.applyTransaction({
      update: [props.data],
    });
    gridApi.setRowData(addons);
    setGridData(addons);
    handleSave();
  };
  const onAppend = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    if (!isWritable) {
      message.error("Please enable write mode");
      return;
    }
    let addons = structuredClone(gridData);
    console.log("onDuplicate", { addons, gridData });
    if (Array.isArray(addons) && addons.length > 0) {
      let lastAddon = addons[addons.length - 1];
      let newAddonCloned = structuredClone(lastAddon);
      newAddonCloned.id = lastAddon.id + 1;
      addons.push(newAddonCloned);
    }
    // for (let i = 0; i < addons.length; i++) {
    //   if (addons[i].id === itemId) {
    //     addons.splice(i + 1, 0, addons[i]);
    //     i++; //skip the nex position as it is new new added id
    //   }
    //   if (addons[i].id > itemId) {
    //     //all pli that have higher ID that then deleted pli must decrease their id by 1
    //     addons[i].id++;
    //   }
    // }
    props.data.addons = addons;
    props.api?.applyTransaction({
      update: [props.data],
    });
    gridApi.setRowData(addons);
    setGridData(addons);
    handleSave();
  };
  // TODO: removeAddonRow
  const removeAddonRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    console.log("removeAddonRow", { props, gridApi });
    if (!isWritable) {
      message.error("Please enable write mode");
      return;
    }
    let parentAddons = props?.data?.addons ?? [];
    let newAddons = parentAddons.filter((item) => item.id !== itemId);
    props.data.addons = newAddons;
    applyPLIAddonFormulas2(props?.data);
    if (props?.service?.serviceId?.toString() === "1") {
      attachSpanPriceToPLI({
        data: props.data,
        api: props.api,
        addons: newAddons,
      });
    } else {
      attachPriceToPLI({
        data: props.data,
        api: props.api,
        addons: newAddons,
      });
    }

    props.api?.applyTransaction({
      update: [props.data],
    });
    props.api.refreshCells({
      rowNodes: [props.node],
      force: true,
    });
    props.handleSave(false, true);
    // let addons = structuredClone(gridData);
    // for (let i = 0; i < addons.length; i++) {
    //   const item = addons[i];
    //   if (item.id === itemId) {
    //     addons.splice(i, 1); //remove from this index
    //     i--; //come once more in this index
    //   }
    //   if (item.id > itemId) {
    //     //all pli that have higher ID that then deleted pli must decrease their id by 1
    //     item.id--;
    //   }
    // }
    // console.log("edited addons", addons);
    // props.data.addons = addons;
    // props.api?.applyTransaction({
    //   update: [props.data],

    // })
    // props?.api?.refreshCells({
    //   force: true,
    // })
    // setGridData(addons);
    // // this.gridApi.setRowData(this.gridData);
    // props?.handleSave(true, true);
  };

  const duplicateAddonRow = (
    gridApi,
    serviceIndex,
    optionIndex,
    elevationIndex,
    itemId
  ) => {
    console.log("removeAddonRow", { props, gridApi });
    if (!isWritable) {
      message.error("Please enable write mode");
      return;
    }
    let parentAddons = props?.data?.addons ?? [];
    let newAddons = parentAddons.filter((item) => item.id !== itemId);
    props.data.addons = newAddons;
    applyPLIAddonFormulas2(props?.data);
    if (props?.service?.serviceId?.toString() === "1") {
      attachSpanPriceToPLI({
        data: props.data,
        api: props.api,
        addons: newAddons,
      });
    } else {
      attachPriceToPLI({
        data: props.data,
        api: props.api,
        addons: newAddons,
      });
    }

    props.api?.applyTransaction({
      update: [props.data],
    });
    props.api.refreshCells({
      rowNodes: [props.node],
      force: true,
    });
    props.handleSave(false, true);
    // let addons = structuredClone(gridData);
    // for (let i = 0; i < addons.length; i++) {
    //   const item = addons[i];
    //   if (item.id === itemId) {
    //     addons.splice(i, 1); //remove from this index
    //     i--; //come once more in this index
    //   }
    //   if (item.id > itemId) {
    //     //all pli that have higher ID that then deleted pli must decrease their id by 1
    //     item.id--;
    //   }
    // }
    // console.log("edited addons", addons);
    // props.data.addons = addons;
    // props.api?.applyTransaction({
    //   update: [props.data],

    // })
    // props?.api?.refreshCells({
    //   force: true,
    // })
    // setGridData(addons);
    // // this.gridApi.setRowData(this.gridData);
    // props?.handleSave(true, true);
  };

  // TODO: saveSidewalkShedSpanData
  const saveSidewalkShedSpanData = (addonId, spanData) => {
    let tempData = structuredClone(gridData);
    for (let addon of tempData) {
      if (addon.id === addonId) {
        addon.span = spanData;
        break;
      }
    }
    setGridData(tempData);
    gridApi.setRowData(tempData);
    handleSave();
  };

  const createAddonColumns = () => {
    if (props.pricingData === undefined)
      console.warning("this.props.pricingData === undefined");

    // For SideWalkShed we have different logic because we have to add the span type -> and additional span dimensions
    if (props.service.serviceId.toString() === "1") {
      let columnDefs = getColumnsPLIAddon({
        isWritable,
        data: props.data,
        saveSidewalkShedSpanData,
        addSpan,
        removeAddonRow,
        removeLastSpan,
        appendAddonRow,
        onDuplicate,
        duplicateAddonRow,
        canViewPrice,
        handleSave,
      });
      for (const c of columnDefs) {
        if (c.cellRenderer === "rtfEditor")
          c.cellRendererParams = {
            service: props.service,
            isWritable: isWritable,
            onAddonRichTextChange: onRichTextChange,
            addonType: AddonType.pli,
            type: "addon",
            agGridTheme: props.agGridTheme,
          };
        c.width = 160;
        c.cellStyle = {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        };
      }

      columnDefs = columnDefs.filter(
        (c) =>
          !(
            c.field === "ppu" ||
            c.field === "rent" ||
            c.field === "price" ||
            c.field === "lock"
          )
      );

      let service = props?.service || {};
      if (!!service?.PLIAddonScheme) {
        let { x, y, z } = service?.PLIAddonScheme || {};
        if (Array.isArray(x) && Array.isArray(y) && Array.isArray(z)) {
          let tempColumn = columnDefs?.find((c) => c.field === "spanPrice");
          if (!!tempColumn) {
            tempColumn.cellRenderer = (params) => {
              return (
                <PLIAddonSuggestor
                  {...{
                    params,
                    service,
                    isSpan: true,
                    parentAPI: props?.api,
                    parentData: props?.data,
                    handleSave: () => props.handleSave(false, true),
                  }}
                />
              );
            };
          }
        }
      }

      console.log("columnDefs343", columnDefs);
      // columnDefs.splice(7, 0, tempColumn);

      return columnDefs;
    } else {
      // For all other services we derive the columns from the pricing data & Settings config
      const { priceView, priceEdit, agGridTheme } = props;

      return getAddonsColumns(
        props.pricingData,
        props.service,
        cellValueChanged,
        AddonType.pli,
        props.isWritable,
        props.selectOptions,
        onRichTextChange,
        { priceView, priceEdit, agGridTheme },
        props?.api,
        props?.data,
        null,
        props?.handleSave,
        canViewPrice
      );
    }
  };

  // TODO: evaluateData
  const evaluateData = (r) => {
    return r;
  };

  console.log("addon grid", gridData);

  useEffect(() => {
    return () => {
      masterGridApi.removeDetailGridInfo(rowId);
    };
  }, []);

  console.log("addon props", props);
  return (
    <div className="addonsContainer" style={{ height: "auto" }}>
      <div
        className="sidewalk-shed-addon-header"
        style={{ marginBottom: "10px" }}
      >
        {/* {this?.props?.service?.serviceId === 2 && <ScaffoldPlanks />} */}
        {!!props?.service?.PLIAddonScheme ? (
          <div>
            {" "}
            <MondayButton className="mondayButtonBlue" hasIcon={false}>
              For this Service, there is a PLI Addon Price scheme configured.
            </MondayButton>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        // key={this.state.key}
        style={{
          width: "auto",
        }}
        className="dataEntryGrid"
      >
        <AgGridReact
          id="detailGrid"
          width={"auto"}
          // autoHeight={true}
          rowHeight={40}
          animateRows={true}
          immutableData={true}
          enableFillHandle={true}
          embedFullWidthRows={true}
          enableRangeSelection={true}
          detailRowAutoHeight={true}
          enableCellChangeFlash={true}
          undoRedoCellEditingLimit={50}
          onGridReady={(params) => onGridReady(params, resize)}
          columnDefs={createAddonColumns(canViewPrice)}
          suppressDragLeaveHidesColumns={true}
          // getRowHeight={this.getRowHeight}
          // onColumnResized={this.columnResized}
          getRowNodeId={(data) => data.id}
          defaultColDef={defaultColDefPLIAddon}
          onCellValueChanged={cellValueChanged}
          onCellEditingStarted={cellEditStarted}
          // onCellEditingStopped={(params) => {
          //   params.api.resetRowHeights();
          // }}
          domLayout={"normal"}
          frameworkComponents={frameworkComponents}
          masterDetail={true}
          detailCellRenderer="SidewalkShedSpansDetail"
          detailCellRendererParams={{
            saveSidewalkShedSpanData: saveSidewalkShedSpanData,
            handleSave: handleSave,
          }}
          rowClassRules={darkModeRowStyleRules}
        />
      </div>
    </div>
  );
}

export default PLIAddon;

function evaluateFormula(formula, obj, key) {
  console.log("context", { obj, formula, key });
  // Extract the keys from the formula using a regular expression
  const keys = formula.match(/[a-zA-Z\s]+/g);

  // Replace each key in the formula with its corresponding value from the object
  keys.forEach((k) => {
    const regex = new RegExp(k, "g");
    formula = formula.replace(regex, obj[k.trim()]);
  });

  // Evaluate the formula using JavaScript's eval() function
  try {
    const result = eval(formula);
    obj[key] = result; // Update the value of the provided key with the result
    return result;
  } catch (error) {
    console.error("Error evaluating formula:", error);
    return null;
  }
}

const applyPLIAddonFormula = (formula, obj, key) => {};

export const applyPLIAddonFormulas = (gridParams) => {
  console.log("testparams", {
    gridParams,
  });

  // First, define the object and find PLI addon values
  const pliAddonData = gridParams?.data || {};
  if (
    pliAddonData.hasOwnProperty("name") &&
    typeof pliAddonData?.name === "object"
  ) {
    console.log("test1001", pliAddonData.name);
    let defaultValues = pliAddonData?.name?.values;
    if (Array.isArray(defaultValues) && defaultValues.length > 0) {
      for (const item of defaultValues) {
        let formulaToEval = "";

        let hasFormula =
          Array.isArray(item?.formula) && item?.formula?.length > 0
            ? item?.formula
            : false;
        if (!!hasFormula) {
          hasFormula.forEach((item) => {
            if (item !== "+" && item !== "-" && item !== "*" && item !== "/") {
              if (pliAddonData.hasOwnProperty(item.toLowerCase())) {
                const findItem = pliAddonData[item.toLowerCase()];
                if (!isNaN(forceToNumber(findItem))) {
                  formulaToEval += forceToNumber(findItem);
                }
              } else if (!isNaN(forceToNumber(item))) {
                formulaToEval += forceToNumber(item);
              }
            } else {
              formulaToEval += item;
            }
          });
          pliAddonData[item?.name?.toLowerCase()] = forceToNumber(
            eval(formulaToEval)
          );
        }
        console.log("test1002", formulaToEval);
      }
      console.log("pliAddonData", pliAddonData);

      //TODO: Consider first items first

      //TODO: Consider total price Last
    }
  }

  // let formulaToEval = "";
  // const objToExplore = structuredClone(objWithValues);
  // if (Array.isArray(arrFormula)) {
  //   arrFormula?.forEach((item) => {
  //     if (item !== "+" && item !== "-" && item !== "*" && item !== "/") {
  //       const findItem = objToExplore?.find(
  //         (obj) => obj?.name?.toLowerCase() === item?.toLowerCase()
  //       );
  //       if (!!findItem && forceToNumber(findItem?.defaultValue || 0) !== 0) {
  //         formulaToEval += forceToNumber(findItem?.defaultValue || 0);
  //       } else {
  //         if (typeof parseFloat(item) === "number") formulaToEval += item;
  //       }
  //     } else {
  //       formulaToEval += item;
  //     }
  //   });
  // }
  // if (formulaToEval.length > 0) {
  //   try {
  //     let res = forceToNumber(eval(formulaToEval));
  //     return res;
  //   } catch (e) {
  //     const temp =
  //       objWithValues?.find(
  //         (el) => el.name.toLowerCase() === keyToAssign?.toLowerCase()
  //       )?.defaultValue || 0;
  //     return temp;
  //   }
  //   return res;
  // } else {
  //   return 0;
  // }
};
// RETURNS THE DATA
export const applyPLIAddonFormulas2 = (data) => {
  const pliAddonData = data || {};

  try {
    if (
      pliAddonData.hasOwnProperty("name") &&
      typeof pliAddonData?.name === "object"
    ) {
      console.log("test1001", pliAddonData.name);
      let defaultValues = pliAddonData?.name?.values;
      if (Array.isArray(defaultValues) && defaultValues.length > 0) {
        for (const item of defaultValues) {
          let formulaToEval = "";

          let hasFormula =
            Array.isArray(item?.formula) && item?.formula?.length > 0
              ? item?.formula
              : false;
          if (!!hasFormula) {
            hasFormula.forEach((item) => {
              if (
                item !== "+" &&
                item !== "-" &&
                item !== "*" &&
                item !== "/"
              ) {
                if (pliAddonData.hasOwnProperty(item.toLowerCase())) {
                  const findItem = pliAddonData[item.toLowerCase()];
                  if (!isNaN(forceToNumber(findItem))) {
                    formulaToEval += forceToNumber(findItem);
                  }
                } else if (!isNaN(forceToNumber(item))) {
                  formulaToEval += forceToNumber(item);
                }
              } else {
                formulaToEval += item;
              }
            });
            pliAddonData[item?.name?.toLowerCase()] = forceToNumber(
              eval(formulaToEval)
            );
          }
          console.log("test1002", formulaToEval);
        }
        console.log("pliAddonData", pliAddonData);

        //TODO: Consider first items first

        //TODO: Consider total price Last
      }
    }
  } catch (err) {
  } finally {
    return pliAddonData;
  }
};

export const attachPriceToPLI = ({ data = {}, addons = [], api }) => {
  console.log("attachPriceToPLI", { data, addons });
  let tempPrice = 0;

  if (Array.isArray(addons) && addons?.length > 0) {
    addons.forEach((item) => {
      if (item.hasOwnProperty("total price") || item.hasOwnProperty("price")) {
        tempPrice += forceToNumber(
          item?.["total price"] || item?.["price"] || 0
        );
      }
    });
  }
  data.pliAddonTotal = tempPrice;
  data.taxAmount = pliTaxAmountCalculate(data);
  data.totalPrice = calculateTotalPrice(data);
  api?.applyTransaction({
    update: [data],
  });
};
