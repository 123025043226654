import { Modal, Table } from "antd";
import { useSelector } from "react-redux";
import { InfoCircleFilled } from "@ant-design/icons";
import { useContext, useState, useRef, useEffect } from "react";

import {
  MondayButton,
  WarningModal,
} from "../../../../../../../../commonComponents";
import { TickIcon } from "../../../../../Roles/src";
import { WarningIcon } from "../../../../../../../../../icons";
import EmployeeUploadContext from "../../EmployeeUploadContext";
import {
  XIcon,
  SearchIcon,
} from "../../../../../../../../SidebarPages/Communication/assets";
import { formatCurrency } from "../../../../../../../../SidebarPages/utils";
import { compareNewColumns, compareExistingColumns } from "./compareTableData";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";

import "./CompareTable.scss";

function CompareTable({ open, onCancel, employeeType = "crews" }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { gridApi, employeesConflicts, setEmployeesConflicts } = useContext(
    EmployeeUploadContext
  );

  const { newEmployees, existingEmployees } = employeesConflicts;
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [resolveWarning, setResolveWarning] = useState(false);
  const [filteredNewEmployees, setFilteredNewEmployees] = useState([]);
  const [filteredExistingEmployees, setFilteredExistingEmployees] = useState(
    []
  );

  const inputRef = useRef();
  const newEmployeesTable = useRef();
  const existingEmployeesTable = useRef();

  function getCellChange(value, key, index, table) {
    const tableToCompare = table === "new" ? existingEmployees : newEmployees;
    const differenceColor = table === "new" ? "#D5F1C9" : "#FDE5E4";
    const differenceColorDark = table === "new" ? "#acec908a" : "#ffb6b3c4";
    const style = {};
    if (value !== tableToCompare[index][key]) {
      Object.assign(style, {
        background: isDarkMode ? differenceColorDark : differenceColor,
      });
    }
    return key === "foreman"
      ? {
          props: {
            style,
          },
          children:
            typeof value === "boolean" && value ? "Foreman" : "Employee",
        }
      : {
          props: {
            style,
          },
          children: key === "employeeRate" ? formatCurrency(value) : value,
        };
  }

  function onSearch(e) {
    const value = (e || "").toLowerCase();

    let filteredNew = [];
    const filteredExisting = existingEmployees.filter((employee, index) => {
      const keys = Object.keys(employee);
      let filterPass = false;
      for (const key of keys) {
        const empValue = employee[key];
        if (
          typeof empValue === "string" &&
          empValue?.toLowerCase().includes(value)
        ) {
          filterPass = true;
          break;
        } else if (
          typeof empValue === "number" &&
          empValue == parseFloat(value)
        ) {
          filterPass = true;
          break;
        }
      }
      if (filterPass) {
        filteredNew.push(index);
      }
      return filterPass;
    });
    filteredNew = filteredNew.map((i) => newEmployees[i]);

    setFilteredExistingEmployees(filteredExisting);
    setFilteredNewEmployees(filteredNew);
  }

  function addNewEmployeeRow(record, rowIndex) {
    return {
      onClick: (event) => {
        const row = event.target.parentElement;

        const thisTableSelected = employeesToAdd.some(
          (emp) => emp?.newRowIndex === rowIndex
        );
        const otherTableSelected = employeesToAdd.some(
          (emp) => emp?.existingRowIndex === rowIndex
        );
        if (otherTableSelected) {
          const existingTable = document.getElementById(
            "existingEmployeesTable"
          );
          const exSelectedRow = existingTable
            .getElementsByClassName("ant-table-tbody")[0]
            .getElementsByClassName("ant-table-row")[rowIndex];
          exSelectedRow.classList.remove("selected");
          row.classList.add("selected");

          const tmpEmployeesToAdd = employeesToAdd.map((emp) => {
            if (emp?.existingRowIndex === rowIndex) {
              delete emp?.existingRowIndex;
              Object.assign(emp, { newRowIndex: rowIndex });
              return emp;
            } else {
              return emp;
            }
          });
          setEmployeesToAdd(tmpEmployeesToAdd);
        }
        if (thisTableSelected) {
          row.classList.remove("selected");
          const tmpEmployeesToAdd = employeesToAdd.filter(
            (emp) => emp.newRowIndex !== rowIndex
          );
          setEmployeesToAdd(tmpEmployeesToAdd);
        }

        if (!thisTableSelected && !otherTableSelected) {
          row.classList.add("selected");
          setEmployeesToAdd((prev) => [
            ...prev,
            { ...record, newRowIndex: rowIndex },
          ]);
        }
      },
    };
  }

  function addExistingEmployeeRow(record, rowIndex) {
    return {
      onClick: (event) => {
        const row = event.target.parentElement;

        const otherTableSelected = employeesToAdd.some(
          (emp) => emp?.newRowIndex === rowIndex
        );
        const thisTableSelected = employeesToAdd.some(
          (emp) => emp?.existingRowIndex === rowIndex
        );
        if (otherTableSelected) {
          const existingTable = document.getElementById("newEmployeesTable");
          const exSelectedRow = existingTable
            .getElementsByClassName("ant-table-tbody")[0]
            .getElementsByClassName("ant-table-row")[rowIndex];
          exSelectedRow.classList.remove("selected");
          row.classList.add("selected");

          const tmpEmployeesToAdd = employeesToAdd.map((emp) => {
            if (emp?.newRowIndex === rowIndex) {
              delete emp?.newRowIndex;
              Object.assign(emp, { existingRowIndex: rowIndex });
              return emp;
            } else {
              return emp;
            }
          });
          setEmployeesToAdd(tmpEmployeesToAdd);
        }
        if (thisTableSelected) {
          row.classList.remove("selected");
          const tmpEmployeesToAdd = employeesToAdd.filter(
            (emp) => emp.existingRowIndex !== rowIndex
          );
          setEmployeesToAdd(tmpEmployeesToAdd);
        }

        if (!thisTableSelected && !otherTableSelected) {
          row.classList.add("selected");
          const recordWithUpdatedValues = newEmployees[rowIndex];
          setEmployeesToAdd((prev) => [
            ...prev,
            {
              ...record,
              // crewId: recordWithUpdatedValues?.crewId,
              employeeId: recordWithUpdatedValues?.employeeId,
              accountName: recordWithUpdatedValues?.accountName,
              crewPosition: recordWithUpdatedValues?.crewPosition,
              employeeRate: recordWithUpdatedValues?.employeeRate,
              foreman: recordWithUpdatedValues?.foreman,
              teamGroup: recordWithUpdatedValues?.teamGroup,
              existingRowIndex: rowIndex,
            },
          ]);
        }
      },
    };
  }

  function selectAllNewEmployees() {
    const newEmpTable = document
      .getElementById("newEmployeesTable")
      .getElementsByClassName("ant-table-tbody")[0];

    const existingEmpTable = document
      .getElementById("existingEmployeesTable")
      .getElementsByClassName("ant-table-tbody")[0];

    const selectedNewEmployees = employeesToAdd.filter((emp) =>
      emp?.hasOwnProperty("newRowIndex")
    );

    const tmpEmployeesToAdd = [];

    for (let i = 0; i < newEmployees?.length; i++) {
      let emp = newEmployees[i];
      const newEmpTableRow =
        newEmpTable.getElementsByClassName("ant-table-row")[i];
      const existingEmpTableRow =
        existingEmpTable.getElementsByClassName("ant-table-row")[i];
      newEmpTableRow.classList.add("selected");

      const existingAddedRow = employeesToAdd.find(
        ({ existingRowIndex }) => existingRowIndex === i
      );
      if (existingAddedRow) {
        existingEmpTableRow.classList.remove("selected");
      }

      tmpEmployeesToAdd.push({ ...emp, newRowIndex: i });
    }
    if (!!selectedNewEmployees?.length) {
      setEmployeesToAdd([]);
      newEmployees.forEach((emp, index) => {
        newEmpTable
          .getElementsByClassName("ant-table-row")
          [index].classList.remove("selected");
      });
    } else {
      setEmployeesToAdd(tmpEmployeesToAdd);
    }
  }

  function updateAllExistingEmployees() {
    const newEmpTable = document
      .getElementById("newEmployeesTable")
      .getElementsByClassName("ant-table-tbody")[0];

    const existingEmpTable = document
      .getElementById("existingEmployeesTable")
      .getElementsByClassName("ant-table-tbody")[0];

    const selectedExistingEmployees = employeesToAdd.filter((emp) =>
      emp?.hasOwnProperty("existingRowIndex")
    );
    const tmpEmployeesToAdd = [];

    for (let i = 0; i < existingEmployees?.length; i++) {
      let emp = existingEmployees[i];
      const newEmpTableRow =
        newEmpTable.getElementsByClassName("ant-table-row")[i];
      const existingEmpTableRow =
        existingEmpTable.getElementsByClassName("ant-table-row")[i];
      existingEmpTableRow.classList.add("selected");

      const newAddedRow = employeesToAdd.find(
        ({ newRowIndex }) => newRowIndex === i
      );
      if (newAddedRow) {
        newEmpTableRow.classList.remove("selected");
      }
      const recordWithUpdatedValues = newEmployees[i];
      tmpEmployeesToAdd.push({
        ...emp,
        // crewId: recordWithUpdatedValues?.crewId,
        employeeId: recordWithUpdatedValues?.employeeId,
        accountName: recordWithUpdatedValues?.accountName,
        crewPosition: recordWithUpdatedValues?.crewPosition,
        employeeRate: recordWithUpdatedValues?.employeeRate,
        foreman: recordWithUpdatedValues?.foreman,
        teamGroup: recordWithUpdatedValues?.teamGroup,
        existingRowIndex: i,
      });
    }

    if (!!selectedExistingEmployees?.length) {
      existingEmployees.forEach((emp, index) => {
        existingEmpTable
          .getElementsByClassName("ant-table-row")
          [index].classList.remove("selected");
      });
      setEmployeesToAdd([]);
    } else {
      setEmployeesToAdd(tmpEmployeesToAdd);
    }
  }

  function resolveConflicts() {
    setEmployeesConflicts({
      newEmployees: [],
      existingEmployees: [],
    });
    gridApi.applyTransaction({ add: employeesToAdd });
    onCancel();
  }

  /**
   * This function synchronizes the scroll in Y axis of 2 elements
   * @param {HTMLDivElement} source
   * @param {HTMLDivElement} target
   */
  function synchTableScroll(source, target) {
    let isScrollSynced = false;
    if (!isScrollSynced) {
      isScrollSynced = true;
      target.scrollTop = source.scrollTop;
      isScrollSynced = false;
    }
  }

  useEffect(() => {
    setFilteredExistingEmployees(existingEmployees);
    setFilteredNewEmployees(newEmployees);
  }, [existingEmployees, newEmployees]);

  useEffect(() => {
    if (!!newEmployeesTable?.current && !!existingEmployeesTable?.current) {
      const newScrollContainer = document
        .getElementById("newEmployeesTable")
        .querySelector(".ant-table-body");
      const existingScrollContainer = document
        .getElementById("existingEmployeesTable")
        .querySelector(".ant-table-body");

      newScrollContainer.addEventListener("scroll", () =>
        synchTableScroll(newScrollContainer, existingScrollContainer)
      );
      existingScrollContainer.addEventListener("scroll", () =>
        synchTableScroll(existingScrollContainer, newScrollContainer)
      );
    }
  }, [newEmployeesTable?.current, existingEmployeesTable?.current]);
  console.log("employeesToAdd: ", employeesToAdd);
  return (
    <Modal
      open={open}
      centered={true}
      closable={true}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      wrapClassName="conflicts-wrap-table-modal"
      className={`conflicts-table-modal ${
        isDarkMode && "conflicts-table-modal-dark"
      }`}
      title={
        employeeType === "crews"
          ? `${newEmployees?.[0]?.accountName} Crew Members (Conflicts)`
          : `${employeeType.capitalize()} (Conflicts)`
      }
      footer={[
        <MondayButton
          Icon={<XIcon />}
          onClick={onCancel}
          className="mondayButtonRed"
        >
          Cancel
        </MondayButton>,
        <MondayButton
          Icon={<TickIcon />}
          onClick={() => setResolveWarning(true)}
          disabled={employeesToAdd?.length !== newEmployees?.length}
        >
          Confirm
        </MondayButton>,
      ]}
    >
      <main>
        <section className="control-panel">
          <div className="conflicts-info">
            <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
            <p className="uploadEmployeeText">
              Review highlighted differences between newly uploaded{" "}
              {employeeType} members from{" "}
              <b>{newEmployees?.[0]?.accountName}</b> and existing members.
              Select the right records and resolve the conflicts.
            </p>
          </div>
          <InputComponent
            type="input"
            inputRef={inputRef}
            placeholder="Search..."
            prefix={<SearchIcon />}
            onChange={_.debounce((e) => onSearch(e.target.value), 800)}
          />
        </section>
        <section className="tables-wrapper">
          <section className="table-container">
            <section className="control-header">
              {employeesToAdd.some(
                (el) => typeof el?.newRowIndex === "number"
              ) ? (
                <span className="de-select-all" onClick={selectAllNewEmployees}>
                  Deselect All{" "}
                  <span className="icon">
                    <XIcon />
                  </span>
                </span>
              ) : (
                <span className="select-all" onClick={selectAllNewEmployees}>
                  Select All{" "}
                  <span className="icon">
                    <TickIcon />
                  </span>
                </span>
              )}
              <span className="table-title">
                {newEmployees?.[0]?.accountName} {employeeType.capitalize()}{" "}
                members
              </span>
            </section>
            <Table
              id="newEmployeesTable"
              ref={newEmployeesTable}
              columns={compareNewColumns({ getCellChange, employeeType })}
              dataSource={filteredNewEmployees.map((data, key) =>
                Object.assign(data, { key })
              )}
              onRow={addNewEmployeeRow}
              pagination={false}
              scroll={{
                y: 11,
              }}
            />
          </section>

          <section className="table-container">
            <section className="control-header">
              {employeesToAdd.some(
                (el) => typeof el?.existingRowIndex === "number"
              ) ? (
                <span
                  className="de-select-all"
                  onClick={updateAllExistingEmployees}
                >
                  Deselect All{" "}
                  <span className="icon">
                    <XIcon />
                  </span>
                </span>
              ) : (
                <span
                  className="select-all"
                  onClick={updateAllExistingEmployees}
                >
                  Select All{" "}
                  <span className="icon">
                    <TickIcon />
                  </span>
                </span>
              )}
              <span className="table-title">
                Existing {employeeType.capitalize()} members
              </span>
            </section>
            <Table
              id="existingEmployeesTable"
              ref={existingEmployeesTable}
              columns={compareExistingColumns({ getCellChange, employeeType })}
              dataSource={filteredExistingEmployees}
              onRow={addExistingEmployeeRow}
              pagination={false}
              scroll={{
                y: 11,
              }}
            />
          </section>
        </section>
      </main>
      <WarningModal
        visible={resolveWarning}
        setVisible={setResolveWarning}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to Resolve conflicts in this way?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setResolveWarning(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={resolveConflicts}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </Modal>
  );
}

export default CompareTable;
