import { useEditLogs } from "../../../../../../hooks";
import { realTimePostEventsAutomations } from "../utils";
import { executeOneTimeAutomation } from "./executeOneTimeAutomation";

const usePostEventsAutomation = () => {
  // get the location of executed event
  const { href } = window.location;

  // this is used to dispatch program field we are working with

  const { saveAddedLogs } = useEditLogs();

  // this executes the post event automations if all requirements are completed
  const postEventsAutomationHandler = async ({
    automationName = "",
    dataToDisplayInAutomation,
    action = "",
    redirectId = "",
  }) => {
    const defaultFieldOptions = await realTimePostEventsAutomations();

    const findedAutomationToModify = defaultFieldOptions.find(
      (func) => automationName === func.automationName
    );

    if (!findedAutomationToModify) {
      console.error("No configuration found for the provided automation");
      return;
    }

    if (!action) {
      console.error("no action provided");
      return;
    }

    if (!findedAutomationToModify.shouldPostExecute) {
      console.log("Automation is stopped");
      return;
    }

    if (!automationName || !dataToDisplayInAutomation) {
      console.error("Missing props for executing event automation");
      return;
    }

    const hostOrigin = redirectId
      ? `${
          window.location.origin
        }/${findedAutomationToModify.categoryName.toLocaleLowerCase()}/${redirectId}`
      : href;

    const updatedAutomation = {
      ...findedAutomationToModify,
      automationBody: {
        ...findedAutomationToModify.automationBody,
        dataToShowInAutomationResponse: dataToDisplayInAutomation,
      },
      action,
      href: hostOrigin,
    };

    if (process.env.NODE_ENV !== "production") return;

    try {
      const responses = await executeOneTimeAutomation(updatedAutomation);
      const automationResponseLogs = responses.map((response) => ({
        // description: "Email sent successfully",
        // toUsers: [response.data.to],
        // date: parseInt(response.data.timestampDate),
        // response: automationName,
        // action: action || "",
        // linkTo: hostOrigin,

        category: "Automations",
        actionType: "Automation Response",
        recordId: automationName,
        recordName: automationName,
        topic: automationName,
        currentData: { toUsers: [response.data.to], status: "Success" },
        nameOfUser: "Automation",
        cognitoUserId: "Automation",
        label: "Email sent successfully",
        updatedAt: parseInt(response.data.timestampDate),
      }));

      saveAddedLogs(automationResponseLogs);
    } catch (error) {
      console.log({ error });
    }
  };

  return { postEventsAutomationHandler };
};

export default usePostEventsAutomation;
