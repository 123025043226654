import { getRandomColor } from "../../../../utils";
import uniq from "lodash/uniq";

export const citations = (updatedDynamicStates, IdHandler, programFields) => {
  const citationStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  const employeeNames = uniq(
    (updatedDynamicStates?.[IdHandler] || [])?.map(
      (e) => e?.citationObject?.employeeName
    )
  );

  return {
    citationStatus: () => ({
      title: "Safety Citation Status",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Citations",
        field: "citationStatus",
        id: IdHandler,
        page: "safetyCitations",
        labels: citationStatus?.map(({ statusName }) => statusName),
        datasets: [
          {
            label: "Citations",
            data: citationStatus?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) => e?.citationStatus === status?.statusName
                )?.length
            ),
            backgroundColor: programFields
              ?.find((e) => e?.fieldName === "Status colors")
              ?.fieldOptions?.Safety?.map((e) => e.statusColor),

            borderWidth: 0,
          },
        ],
      },

      id: IdHandler,
      width: "790px",
    }),

    citationName: () => ({
      title: "Safety Citation Name",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Citations",
        field: "citationName",
        id: IdHandler,
        page: "safetyCitations",
        labels: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((e) => e?.citationName)
        ),
        datasets: [
          {
            label: "Citations",
            data: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(
                (e) => e?.citationName
              )
            )?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) => e?.citationName === status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(() =>
                getRandomColor()
              )
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),

    employeeName: () => ({
      title: "Safety Citation - Employee",
      BodyComponent: "BarChart",
      type: "BarChart",
      BodyComponentParams: {
        table: "Citations",
        field: "employeeName",
        id: IdHandler,
        page: "crews",
        labels: employeeNames,
        datasets: [
          {
            label: "Citations",
            data: employeeNames?.map(
              (status) =>
                (updatedDynamicStates?.[IdHandler] || [])?.filter(
                  (e) => e?.citationObject?.employeeName === status
                )?.length
            ),
            backgroundColor: uniq(
              (updatedDynamicStates?.[IdHandler] || [])?.map(() =>
                getRandomColor()
              )
            ),
          },
        ],
      },
      id: IdHandler,
      width: "790px",
    }),
  };
};
