import {
  formatNumber,
  formatCurrency,
} from "../../../../../SidebarPages/utils";
import { MondayButton } from "../../../../../commonComponents";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../DEG/components/modalComponents/utils/cellFunctions";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { TrashIcon } from "../../../../../SidebarPages/Communication/assets";
import Services from "../../../../../SidebarPages/components/AgGridComponents/Services";

function columnDefs({
  dataIdKey,
  columnAccess,
  isSingleEmployee,
  setDeleteAnalyticsModal,
}) {
  let rateAccess = false;
  let deleteAccess = false;
  let companyAccess = false;
  let otAmountAccess = false;
  let regAmountAccess = false;
  let totalAmountAccess = false;

  for (let i = 0; i < columnAccess.length; i++) {
    const access = columnAccess[i];
    if (access?.title === "Rate") {
      rateAccess = true;
    }

    if (access?.title === "Delete") {
      deleteAccess = true;
    }

    if (access?.title === "Company") {
      companyAccess = true;
    }

    if (access?.title === "OT Amount") {
      otAmountAccess = true;
    }

    if (access?.title === "Reg Amount") {
      regAmountAccess = true;
    }

    if (access?.title === "Total Amount") {
      totalAmountAccess = true;
    }
  }

  return dataIdKey === "shiftId"
    ? // #region Shift columns
      [
        {
          headerName: "Upload Name",
          field: "uploadName",
          cellClass: "cell-left-aligned",
        },
        {
          headerName: "DEG",
          field: "degId",
          cellClass: "cell-left-aligned",
          valueGetter: ({ data, context }) => {
            const degIndex = context?.degPayrolls?.findIndex(
              (el) => el.degId === data?.degId
            );

            const deg = context?.degPayrolls?.[degIndex];

            return !!deg ? deg?.degName : "";
          },
        },
        !isSingleEmployee && {
          headerName: "Employee Id",
          field: "employeeId",
          cellClass: "cell-left-aligned",
        },
        !isSingleEmployee && {
          headerName: "Employee Full Name",
          field: "employeeFullName",
          cellClass: "cell-left-aligned",
        },
        !isSingleEmployee && {
          headerName: "Employee Role",
          field: "employeeRole",
          cellClass: "cell-left-aligned",
        },
        {
          headerName: "Team",
          field: "crewTeamName",
          cellClass: "cell-left-aligned",
          valueGetter: ({ data, context }) => {
            if (data?.crewTeamName) {
              return data?.crewTeamName;
            } else {
              const team = (context?.crewTeams || []).find((el) => {
                const isForeman = el?.crewForeman?.crewId === data?.crewId;
                const isMember =
                  el.crewMembers.findIndex(
                    (member) => member?.crewId === data?.crewId
                  ) > -1;
                return isForeman || isMember;
              });
              return team?.crewTeamName;
            }
          },
        },
        companyAccess && {
          headerName: "Company Name",
          field: "company",
          cellClass: "cell-left-aligned",
          cellRenderer: ({ value, data }) =>
            !!value ? value : data?.companyName,
        },
        {
          headerName: "Shift Type",
          field: "shiftType",
          cellClass: "cell-left-aligned",
        },
        {
          headerName: "Punch Date",
          field: "punchDate",
          filter: "agDateColumnFilter",
          cellClass: "cell-left-aligned",
          cellRenderer: ({ value }) => {
            return dayjsNY(value)?.format(DEG_DATE_FORMAT);
          },
          filterParams: {
            valueGetter: ({ data }) => {
              return dayjsNY(data?.punchDate).valueOf();
            },
          },
          pdfExportOptions: {
            modifyCellValue: (date) =>
              date
                ? new Date(date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "",
          },
          // comparator: (date1, date2) => sortDates(date1, date2, DEG_DATE_FORMAT),
        },
        {
          headerName: "Clock In",
          field: "firstClockIn",
          cellClass: "cell-left-aligned",
          valueGetter: ({ data }) => {
            const value = data?.firstClockIn;
            return dayjsNY(value)?.format(DEG_TIME_FORMAT);
          },
          pdfExportOptions: {
            modifyCellValue: (date) =>
              date ? dayjsNY(value)?.format(DEG_TIME_FORMAT) : "",
          },
        },
        {
          headerName: "Clock Out",
          field: "clockOut",
          cellClass: "cell-left-aligned",
          valueGetter: ({ data }) => {
            const value = data?.clockOut;
            return dayjsNY(value)?.format(DEG_TIME_FORMAT);
          },
          pdfExportOptions: {
            modifyCellValue: (date) => {
              return date;
              // return date ? dayjsNY(value)?.format(DEG_TIME_FORMAT) : "";
            },
          },
        },
        {
          headerName: "Payroll Type",
          field: "payrollType",
          cellClass: "cell-left-aligned",
        },
        {
          headerName: "Work Hours",
          field: "workHours",
          enableRowGroup: true,
          aggFunc: "sum",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
          pdfExportOptions: {
            modifyCellValue: (value) => {
              return formatNumber(value, { unit: "hour" });
            },
          },
        },
        {
          headerName: "Break Hours",
          field: "breakHours",
          enableRowGroup: true,
          aggFunc: "sum",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
          pdfExportOptions: {
            modifyCellValue: (value) => {
              return formatNumber(value, { unit: "hour" });
            },
          },
        },
        {
          headerName: "Overtime Hours",
          field: "overtimeHours",
          enableRowGroup: true,
          aggFunc: "sum",
          cellRenderer({ value }) {
            return formatNumber(value, { unit: "hour" });
          },
          pdfExportOptions: {
            modifyCellValue: (value) => {
              return formatNumber(value, { unit: "hour" });
            },
          },
        },
        rateAccess && {
          headerName: "Rate",
          field: "employeeRate",
          cellRenderer: ({ value }) => {
            return formatCurrency(value ?? 0);
          },
        },
        regAmountAccess && {
          headerName: "Reg Amount",
          field: "regAmount",
          valueGetter({ data }) {
            if (data?.shiftType === "HR Shift") {
              return 0;
            }
            let value = data?.regAmount;
            if (data?.ovhRegular) {
              value = data?.ovhRegular;
            }
            return formatCurrency(value || 0);
          },
          // cellRenderer: ({ value, data }) => {
          //   return formatCurrency(value ?? 0);
          // },
        },
        otAmountAccess && {
          headerName: "Overhead Amount",
          field: "totalOvh",
          valueGetter: ({ data }) => {
            return formatCurrency(data?.totalOvh ?? 0);
          },
        },
        otAmountAccess && {
          headerName: "OT Amount",
          field: "otAmount",
          cellRenderer: ({ value }) => {
            return formatCurrency(value ?? 0);
          },
        },
        totalAmountAccess && {
          headerName: "Total Amount",
          field: "total",
          cellRenderer: ({ data }) => {
            const total = (data?.total || 0) + (data?.totalOvh || 0);
            return formatCurrency(total ?? 0);
          },
        },
        // {
        //   headerName: "Punch Location",
        //   field: "punchLocation",
        //   cellClass: "cell-left-aligned",
        //   valueGetter: ({ data }) => {
        //     return data?.punchLocation || "";
        //   },
        // },
        {
          headerName: "Jobsite Match",
          field: "jobsiteMatch",
          cellClass: "cell-left-aligned",
          valueGetter: ({ data }) => {
            return data?.jobsiteMatch?.jobName || "";
          },
          getQuickFilterText: ({ data }) => {
            return data?.jobsiteMatch?.jobName;
          },
          pdfExportOptions: {
            modifyCellValue: (value) => {
              return value?.jobName;
            },
          },
        },
        {
          headerName: "Schedule Match",
          field: "scheduleAddress",
          cellClass: "cell-left-aligned",
        },
        {
          headerName: "SOW",
          field: "sow",
          cellClass: "cell-center-aligned",
          cellRendererFramework: ({ data }) => {
            return Services({
              value: {
                ["Services"]: {
                  services: Array.isArray(data?.sow) ? data?.sow : [],
                },
              },
              // data?.sow?.map?.((label) => label),
              title: "Employee SOW",
            });
          },
        },
        {
          headerName: "Reason",
          field: "reason",
          cellClass: "cell-center-aligned",
        },
        deleteAccess && {
          headerName: "Delete Entry",
          cellRenderer: ({ data }) => {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MondayButton
                  className="mondayButtonRed"
                  Icon={<TrashIcon fill="#fff" />}
                  onClick={() => setDeleteAnalyticsModal(data)}
                >
                  {""}
                </MondayButton>
              </div>
            );
          },
        },
      ].filter(Boolean)
    : // #region Entries columns
      [
        {
          headerName: "Upload Name",
          field: "uploadName",
          cellClass: "cell-left-aligned",
        },
        {
          headerName: "DEG",
          field: "degId",
          cellClass: "cell-left-aligned",
          valueGetter: ({ data, context }) => {
            const degIndex = context?.degPayrolls?.findIndex(
              (el) => el.degId === data?.degId
            );

            const deg = context?.degPayrolls?.[degIndex];

            return !!deg ? deg?.degName : "";
          },
        },
        !isSingleEmployee && {
          headerName: "Employee Id",
          field: "employeeId",
          cellClass: "cell-left-aligned",
        },
        !isSingleEmployee && {
          headerName: "Employee Full Name",
          field: "employeeFullName",
          cellClass: "cell-left-aligned",
        },
        !isSingleEmployee && {
          headerName: "Employee Role",
          field: "employeeRole",
          cellClass: "cell-left-aligned",
        },
        {
          headerName: "Team",
          field: "crewTeamName",
          cellClass: "cell-left-aligned",
          valueGetter: ({ data, context }) => {
            if (data?.crewTeamName) {
              return data?.crewTeamName;
            } else {
              const team = (context?.crewTeams || []).find((el) => {
                const isForeman = el?.crewForeman?.crewId === data?.crewId;
                const isMember =
                  el.crewMembers.findIndex(
                    (member) => member?.crewId === data?.crewId
                  ) > -1;
                return isForeman || isMember;
              });
              return team?.crewTeamName;
            }
          },
        },
        companyAccess && {
          headerName: "Company Name",
          field: "company",
          cellClass: "cell-left-aligned",
          cellRenderer: ({ value, data }) =>
            !!value ? value : data?.companyName,
        },
        {
          headerName: "Punch Time",
          field: "punchTime",
          cellClass: "cell-left-aligned",
          cellRenderer: ({ value }) => {
            return dayjsNY(value)?.format(DEG_TIME_FORMAT);
          },
          filterParams: {
            valueGetter: ({ data }) => {
              return dayjsNY(data?.punchTime).valueOf();
            },
          },
          pdfExportOptions: {
            modifyCellValue: (date) =>
              date
                ? new Date(date).toLocaleDateString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                : "",
          },
        },
        {
          headerName: "Punch Date",
          field: "punchDate",
          filter: "agDateColumnFilter",
          cellClass: "cell-left-aligned",
          cellRenderer: ({ value }) => {
            return dayjsNY(value)?.format(DEG_DATE_FORMAT);
          },
          filterParams: {
            valueGetter: ({ data }) => {
              return dayjsNY(data?.punchDate).valueOf();
            },
          },
          pdfExportOptions: {
            modifyCellValue: (date) =>
              date
                ? new Date(date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "",
          },
          // comparator: (date1, date2) => sortDates(date1, date2, DEG_DATE_FORMAT),
        },
        {
          headerName: "Jobsite Match",
          field: "jobsiteMatch",
          cellClass: "cell-left-aligned",
          valueGetter: ({ data }) => {
            return data?.jobsiteMatch?.jobName || "";
          },
          getQuickFilterText: ({ data }) => {
            return data?.jobsiteMatch?.jobName;
          },
          pdfExportOptions: {
            modifyCellValue: (value) => {
              return value?.jobName;
            },
          },
        },
        {
          headerName: "Schedule Match",
          field: "scheduleAddress",
          cellClass: "cell-left-aligned",
        },
        {
          headerName: "SOW",
          field: "sow",
          cellClass: "cell-center-aligned",
          cellRendererFramework: ({ data }) => {
            return Services({
              value: {
                ["Services"]: {
                  services: Array.isArray(data?.sow) ? data?.sow : [],
                },
              },
              // data?.sow?.map?.((label) => label),
              title: "Employee SOW",
            });
          },
        },
        {
          headerName: "Reason",
          field: "reason",
          cellClass: "cell-center-aligned",
        },
      ].filter(Boolean);
}

export default columnDefs;
