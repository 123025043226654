import "./HiddenCards.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Tooltip } from "antd";
import { useChartCardsContext } from "../../../ChartCardsContext";
import {
  ClosedEyeIcon,
  DrawerHiddenCardsIcon,
  OpenedEyeIconBlue,
} from "../../../../../../assets";
import { Close } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import MondayButton from "../../../../MondayButton/MondayButton";

const chartNames = {
  doughnut: "Doughnut Chart",
  bar: "Bar Chart",
  progress: "Area Chart",
  polar: "Polar Chart",
  area: "Area Chart",
  "horizontal-progress": "Horizontal Progress",
};

const HiddenCards = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [visible, setVisible] = useState(false);

  const { categoryCards, hiddenCardIds, setHiddenCardIds, setNewChanges } =
    useChartCardsContext();

  const hiddenCards = categoryCards?.filter(
    (el) => !!hiddenCardIds?.includes(el?.id)
  );

  const hiddenLength = hiddenCards?.length;

  return (
    <div className="chart-cards-hidden">
      <MondayButton
        className="mondayButtonBlue"
        tooltipCategory="CHART CARDS"
        tooltipKey="hiddenCards"
        Icon={<ClosedEyeIcon />}
        onClick={() => {
          setVisible(true);
        }}
      />
      <Drawer
        title={<div className="titleContainer">Hidden Cards</div>}
        placement="right"
        rootClassName={`hidden-cards-drawer${isDarkMode ? " dark" : ""}`}
        closeIcon={<Close />}
        onClose={() => {
          setVisible(false);
        }}
        open={visible}
      >
        {!!hiddenLength ? (
          hiddenCards.map((card, index) => {
            const { id, chartTitle, type } = card;
            return (
              <div key={index} className="hiddenCardsDashboard">
                <Tooltip
                  placement="bottom"
                  title={`${chartTitle}-${chartNames[type]}`}
                >
                  <div className="hiddenValue">{`${chartTitle} - ${chartNames[type]} `}</div>
                </Tooltip>
                <button
                  id={id}
                  className="showButton"
                  onClick={() => {
                    setNewChanges(true);
                    setHiddenCardIds((prev) =>
                      prev.filter((cardId) => cardId !== id)
                    );
                  }}
                >
                  <OpenedEyeIconBlue />
                </button>
                <div className="hiddenDecorativeDiv" />
              </div>
            );
          })
        ) : (
          <div className="drawerHiddenZeroCards">
            <DrawerHiddenCardsIcon />
            <span>
              Hello <strong>{userConfiguration?.nameOfUser}</strong>, you have{" "}
              <strong>0</strong> hidden cards.
            </span>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default HiddenCards;
