import { useSelector } from "react-redux";
import { Col, Flex, Row, Select } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";

import Text from "src/components/commonComponents/Typography/Text";
import { MondayButton } from "src/components/commonComponents";
import Button from "src/components/commonComponents/Buttons/Button";
import Headline from "src/components/commonComponents/Typography/Headline";
import { XIconWhite } from "src/components/SidebarPages/Communication/assets";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";

export default function Filters({
  inspections = [],
  filters = {},
  setFilters = () => {},
  showFilters = false,
  setShowFilters = () => {},
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const hasFilters = Object.keys(filters)?.length > 0;

  return (
    <Row style={{ width: "100%" }}>
      <Col span={24}>
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: 8,
            backgroundColor: isDarkMode ? "#12131B" : "#f8f8f8",
          }}
        >
          <Headline>Filters</Headline>
          <Button
            type="text"
            onClick={handleShowFilters}
            icon={showFilters ? <EyeInvisibleFilled /> : <EyeFilled />}
          >
            {showFilters ? "Hide Filters" : "Show Filters"}
          </Button>
          {hasFilters && (
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIconWhite />}
              onClick={() => setFilters({})}
            >
              Clear Filters
            </MondayButton>
          )}
        </Flex>
      </Col>

      <Col
        span={24}
        style={{
          ...(!showFilters && { display: "none" }),
          backgroundColor: isDarkMode ? "#20212D" : "#fff",
        }}
      >
        <Row justify="center">
          <Col xs={24}>
            <Flex
              dir="row"
              wrap="wrap"
              justify="space-evenly"
              align="center"
              gap={10}
              style={{ padding: "10px 20px" }}
            >
              <div>
                <Text>Inspections inspected:</Text>
                <InputComponent
                  type="rangepicker"
                  placeholder="Date"
                  value={filters?.inspected}
                  disabled={!!filters?.dates}
                  style={{ minWidth: 300 }}
                  dropdownClassName={isDarkMode && "darkDateDropDown"}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      inspected: !!e ? [e[0], e[1]] : undefined,
                    })
                  }
                />
              </div>
              <div>
                <Text>Scope of Work:</Text>
                <InputComponent
                  type="select"
                  placeholder="Scope of work"
                  style={{ minWidth: 300 }}
                  showSearch
                  initialValue={filters?.servicesInspected}
                  dropdownClassName={isDarkMode && "darkDropDown"}
                  onChange={(e) =>
                    setFilters({ ...filters, servicesInspected: e })
                  }
                  options={[
                    ...new Set(
                      inspections
                        ?.map((item) => item?.servicesInspected?.flat())
                        ?.flat()
                    ),
                  ].map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                />
              </div>
              <div>
                <Text>Dates:</Text>
                <InputComponent
                  type="rangepicker"
                  placeholder="Date"
                  value={filters?.dates}
                  disabled={!!filters?.inspected}
                  style={{ minWidth: 300 }}
                  dropdownClassName={isDarkMode && "darkDateDropDown"}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      dates: !!e ? [e[0], e[1]] : undefined,
                    })
                  }
                />
              </div>
              <div>
                <Text>Jobite:</Text>
                <InputComponent
                  type="select"
                  placeholder="Jobsite"
                  style={{ minWidth: 300 }}
                  showSearch
                  initialValue={filters?.projectName}
                  dropdownClassName={isDarkMode && "darkDropDown"}
                  onChange={(e) => setFilters({ ...filters, projectName: e })}
                  options={[
                    ...new Set(inspections?.map((item) => item?.projectName)),
                  ].map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                />
              </div>
              <div>
                <Text>Inspected By:</Text>
                <InputComponent
                  type="select"
                  placeholder="Inspected by"
                  style={{ minWidth: 300 }}
                  showSearch
                  initialValue={filters?.inspectedBy}
                  dropdownClassName={isDarkMode && "darkDropDown"}
                  onChange={(e) => setFilters({ ...filters, inspectedBy: e })}
                  options={[
                    ...new Set(inspections?.map((item) => item?.inspectedBy)),
                  ].map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                />
              </div>
              <div>
                <Text>Inspection Status:</Text>
                <InputComponent
                  type="select"
                  placeholder="Inspection status"
                  style={{ minWidth: 300 }}
                  showSearch
                  initialValue={filters?.inspectionStatus}
                  dropdownClassName={isDarkMode && "darkDropDown"}
                  onChange={(e) =>
                    setFilters({ ...filters, inspectionStatus: e })
                  }
                  options={[
                    ...new Set(
                      inspections?.map((item) => item?.inspectionStatus)
                    ),
                  ].map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                />
              </div>
            </Flex>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
