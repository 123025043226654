import uniq from "lodash/uniq";

export const claims = ({ tables, programFields }) => {
  const isEmployee = { Yes: 0, No: 0 };

  tables?.Claims?.forEach((e) => {
    isEmployee[e?.claimObject?.isClaimantAnEmployeeOfCore] += 1;
  });

  return {
    claimStatus: () => ({
      content: programFields
        ?.find((a) => a?.fieldName === "Statuses Of Categories")
        ?.fieldOptions?.Claims?.map((status, index) => ({
          key: index,
          title: status?.statusName,
          taskNumber: tables?.Claims?.length,
          taskDone: tables?.Claims?.filter(
            (a) => a?.claimStatus === status?.statusName
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status?.statusName} Claim`,
          contentsHandler: ` ${status?.statusName} Claims`,
        })),
    }),

    claimCategory: () => ({
      content: uniq(tables?.Claims?.map((e) => e?.claimCategory))?.map(
        (status, index) => ({
          key: index,
          title: status,
          taskNumber: tables?.Claims?.length,
          taskDone: tables?.Claims?.filter((a) => a?.claimCategory === status)
            ?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Claim`,
          contentsHandler: ` ${status} Claims`,
        })
      ),
    }),

    claimType: () => ({
      content: uniq(tables?.Claims?.map((e) => e?.claimType))?.map(
        (status, index) => ({
          key: index,
          title: status,
          taskNumber: tables?.Claims?.length,
          taskDone: tables?.Claims?.filter((a) => a?.claimType === status)
            ?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Claim`,
          contentsHandler: ` ${status} Claims`,
        })
      ),
    }),
    isClaimantAnEmployeeOfCore: () => ({
      content: [
        {
          key: 0,
          title: "Yes",
          taskNumber: tables?.Claims?.length,
          taskDone: isEmployee.Yes,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` Yes `,
          contentsHandler: ` Yes `,
        },
        {
          key: 1,
          title: "No",
          taskNumber: tables?.Claims?.length,
          taskDone: isEmployee.No,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` No `,
          contentsHandler: ` No `,
        },
      ],
    }),
  };
};
