const initialState = {
  approvals: [],
  requests: [],
};

function approvalsReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_APPROVALS": {
      return {
        ...state,
        approvals: action.payload,
      };
    }

    case "ADD_APPROVAL":
      const approvalExists = state?.approvals?.some(
        (item) => item?.requestId === action?.payload.requestId
      );

      if (!approvalExists) {
        return {
          ...state,
          approvals: [...state?.approvals, action.payload],
        };
      } else {
        return state;
      }

    case "UPDATE_APPROVAL": {
      const updatedApprovals = state.approvals.map((item) => {
        if (item.requestId === action.payload.requestId) {
          const { requestId, ...rest } = action.payload;

          return {
            ...item,
            ...rest,
            requestObject: {
              ...item.requestObject,
              ...rest.requestObject,
            },
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        approvals: updatedApprovals,
      };
    }

    case "DELETE_APPROVAL_BY_ID": {
      // Filter out the deleted item based on its id or some identifier
      const filteredApprovals = state.approvals.filter(
        (approval) => approval.requestId !== action.payload.id
      );

      return {
        ...state,
        approvals: filteredApprovals,
      };
    }

    default:
      return state;
  }
}

export default approvalsReducer;
