import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";

import { CustomLoadingOverlay } from "./customLoadingOverlay";
import { autoSizeAllColumns } from "./utils/autoSizeColumns";
import { useTableContext } from "./context/AgGridTableContext";

const DynamicAgGridTable = ({
  rowData,
  columnDefs,
  customClassName = "",
  customHeader = null,
  defaultColDef = {},
  autoResize = true,
  ...props
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { onGridReady, gridRef } = useTableContext();

  const defaultColumnDef = useMemo(
    () => ({
      resizable: true,
      enableColResize: true,
      enableRowGroup: true,
      sortable: true,
      flex: 1,
      filter: true,
      suppressSizeToFit: true,
      ...defaultColDef,
    }),
    []
  );

  const components = useMemo(
    () => ({
      agLoadingOverlay: CustomLoadingOverlay,
    }),
    []
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {customHeader}
      <div
        className={`documentationsTable agGridContainer ${customClassName} ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
        style={{ flex: 1 }}
        data-testid="ag-grid-container"
      >
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColumnDef}
          paginationAutoPageSize={true}
          onFirstDataRendered={(grid) => {
            if (autoResize)
              setTimeout(() => {
                autoSizeAllColumns(rowData, grid, false);
              }, 500);
          }}
          components={components}
          {...props}
        />
      </div>
    </div>
  );
};

export default DynamicAgGridTable;
