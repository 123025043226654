const gridFilters = [
  {
    globalKeyId: "8fdedbc3-0b8a-ewew352-6354cb0f22ec",
    label: "Upload Name",
    type: "Dropdown",
    key: "uploadName",
  },
  {
    globalKeyId: "8fewfwbc3-0b8a-400f-b352-6354cb0f22ec",
    label: "Employee Full Name",
    type: "Dropdown",
    key: "employeeFullName",
  },
  {
    globalKeyId: "8fdedbc3-xxxx-400f-b352-6354cb0f22ec",
    label: "Payroll Type",
    type: "Dropdown",
    key: "payrollType",
  },
  {
    globalKeyId: "9agksnc3-ytgh-f450-b52g-6354ci0f22mi",
    label: "Punch Location",
    type: "Dropdown",
    key: "punchLocation",
  },
  {
    globalKeyId: "6be58148-9152-447f-9a29-a7e2ac0f5158",
    label: "Jobsite Match",
    type: "Dropdown",
    key: ["jobsiteMatch", "jobAddress"],
  },
  {
    globalKeyId: "fd0e39dd-f0fe-4028-b39b-59f11a82c820",
    label: "SOW",
    type: "Dropdown",
    key: "sow",
  },
  {
    globalKeyId: "b4676ceb-00c8-420d-b6ec-cfee6d77fcd9",
    label: "Punch Date",
    type: "DateRange",
    key: "punchDate",
  },
];

export default gridFilters;
