import ToDoBasePage from "./ToDoBasePage";
import { XIcon } from "../Communication/assets";
import "./ToDoModal.scss";
import { Modal } from "antd";

/**
 *
 * @param {String} recordId - the id of the record we are viewing the to do's for
 * @param {Boolean} toDoVisible - whether the modal is visible or not
 * @param {Function} setToDoVisible - function to set the visibility of the modal
 * @param {String} recordName - the name of the record we are viewing the to do's for
 * @param {Array} rowData - the data of the to do's it is not necessary to pass this prop as the ToDoBasePage component will fetch the data if it is not passed based on the recordId
 * @param {Function} onDeleteToDo - call back function that is executed when we delete a to do
 * @param {Function} onAddTodo - call back function that is executed when we add a to do
 * @param {Function} onEditTodo - call back function that is executed when we edit a to do
 * @param {Boolean} isDarkMode - whether the dark mode is enabled or not used for the inspection modal
 * @returns
 */
const ToDoModal = ({
  recordId = "",
  toDoVisible = false,
  setToDoVisible = () => {},
  recordName = "",
  selectedCategory = "",
  rowData = [],
  onDeleteToDo = () => {},
  onAddTodo = () => {},
  onEditTodo = () => {},
  isDarkMode = false,
  hideCreateTaskModal = false,
}) => {
  const fromSettings = window.location.pathname.split("/")[1] === "settings";

  return (
    <Modal
      {...{
        open: toDoVisible,
        onCancel: () => setToDoVisible(false),
        title: `To Do for ${recordName}`,
        footer: null,
        closable: true,
        closeIcon: <XIcon fill="#333238" />,
        styles: { width: "1600px" },
        className: `todo-modal ${isDarkMode && "todo-modal-dark"}`,
      }}
    >
      <div
        style={
          fromSettings
            ? {
                position: "relative",
                height: "70dvh",
                width: "100%",
              }
            : { height: "70dvh" }
        }
      >
        <ToDoBasePage
          {...{
            recordId,
            isTab: false,
            propRowData: rowData,
            onDeleteToDo,
            onAddTodo,
            onEditTodo,
            recordName,
            hideCreateTaskModal,
            ...(selectedCategory && { selectedCategory }),
          }}
        />
      </div>
    </Modal>
  );
};

export default ToDoModal;
