import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TreeItem } from "../TreeItem";
import styles from "./TreeNode.module.scss";

const TreeNode = ({
  node,
  actionType,
  index,
  showVerticalLine,
  styledDescription = false,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [elHeight, setElHeight] = useState(0);

  useLayoutEffect(() => {
    const el = document.getElementById(`node-${index}`)?.offsetHeight;
    setElHeight(el);
  }, []);

  return (
    <div
      id={`node-${index}`}
      className={
        styles[isDarkMode ? "tree-node-container-dark" : "tree-node-container"]
      }
      data-testid="tree-node-container"
    >
      <div
        className={styles[isDarkMode ? "vertical-line-dark" : "vertical-line"]}
        style={{
          height: `${elHeight}px`,
        }}
        data-testid="vertical-line"
      />
      <div className={styles["tree-element"]}>
        <div className={styles["horizontal-line"]} />
        <div className={styles["tree-element-content"]}>
          <TreeItem
            item={node}
            actionType={actionType}
            styledDescription={styledDescription}
          />
        </div>
      </div>
    </div>
  );
};

export default TreeNode;
