import { message } from "antd";
import { batchUpdateItems } from "../../../../../utils";
import { apiRoutes } from "../../../../SidebarPages/Fleet/utils";
// import { updateUserPromise } from "../AllUsers/components/UserSessionModal/helpers";

export const checkIsValidIp = (ip) => {
  // Regex pattern for IPv4
  let ipv4Pattern =
    /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;

  // Regex pattern for IPv6
  let ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

  return ipv4Pattern.test(ip) || ipv6Pattern.test(ip);
};

export const checkIsValidSubnet = (subnet) => {
  const pattern =
    /^(255\.(255\.(255|254|252|248|240|224|192|128|0)\.0|255\.(255|254|252|248|240|224|192|128|0)|0)|255\.255\.0\.0|255\.0\.0\.0|0\.0\.0\.0)$/;

  return pattern.test(subnet);
};

const notificationActions = {
  create: "created a new",
  delete: "deleted a",
  edit: "edited a",
  toggle: "toggle",
};

export const buildedNotificationBody = (data) => {
  const proceedAction = notificationActions[data.actionType];
  const proceedRecordName = data?.recordName || "";

  const toReturn = `has ${
    proceedAction === "toggle"
      ? `${Object.values(data.currentData)[0] ? "enabled" : "disabled"} all`
      : proceedAction
  } Ipv4 ${proceedRecordName}`;

  return toReturn;
};

// export const toBinary = (subnet) => {
//   const binarySubnet = subnet
//     .split(".")
//     .map((octet) => parseInt(octet, 10).toString(2).padStart(8, "0"))
//     .join("");

//   return binarySubnet.split("1").length - 1;
// };

// export const getClass = (prefixLength) => {
//   if (prefixLength <= 8) return "A";
//   if (prefixLength <= 16) return "B";
//   if (prefixLength <= 32) return "C";
//   return "Custom";
// };

const sampleObjectOfIpAddress = {
  ipv4: "string",
  devices: [
    {
      deviceName: "string",
      deviceId: "string",
      deviceLabel: "string",
    },
  ],
  description: "string",
  addedAt: "number",
  addedBy: {
    name: "string",
    uid: "string",
  },
  isEnabled: "boolean",
  id: "string",
};

export async function updateIpAddressForAllUsers(
  usersToModifyIp,
  fields,
  toDelete
) {
  try {
    const updatedData = Object.entries(usersToModifyIp).reduce(
      (result, [id, { value, action }]) => {
        value
          .filter(({ isSelected }) => isSelected)
          .forEach(
            ({
              identityId,
              ipAddressWhitelist,
              action: actionInUserConfig,
            }) => {
              if (!identityId) return;

              result[identityId] = result[identityId] || {
                "ipAddressWhitelist.list": [],
              };

              const prevIps = ipAddressWhitelist?.list || [];
              const keyToFind = action === "delete" ? toDelete : fields;
              const matchingField = keyToFind.find((field) => field.id === id);
              if (!matchingField) return;

              const ipIndex = prevIps.findIndex(
                (prev) => prev.ipv4 === matchingField.ipv4
              );

              switch (action) {
                case "create":
                  if (ipIndex === -1) {
                    prevIps.push(matchingField);
                  }
                  break;

                case "edit":
                  if (ipIndex > -1) {
                    prevIps[ipIndex] = matchingField;
                  }
                  break;

                case "delete":
                  if (ipIndex > -1) {
                    prevIps.splice(ipIndex, 1);
                  }
                  break;

                case "mixed":
                  if (ipIndex > -1) {
                    if (actionInUserConfig === "edit") {
                      prevIps[ipIndex] = matchingField;
                    } else if (actionInUserConfig === "delete") {
                      prevIps.splice(ipIndex, 1);
                    }
                  }
                  break;
              }

              result[identityId]["ipAddressWhitelist.list"] = prevIps;
            }
          );

        return result;
      },
      {}
    );

    const entries = Object.entries(updatedData);

    const chunkSize = 10;
    const slicedUsers = entries.reduce((result, _, index) => {
      if (index % chunkSize === 0) {
        result.push(
          Object.fromEntries(entries.slice(index, index + chunkSize))
        );
      }
      return result;
    }, []);

    let promises = slicedUsers.map((updateData) => {
      return batchUpdateItems({
        tableName: apiRoutes.userConfiguration,
        conditionKey: "identityId",
        updateData,
      });
    });

    Promise.allSettled(promises)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        message.error(
          "failed to update user configuration, and to save the made changes"
        );
        console.error("Error in updateIpAddressForAllUsers:", error);
        reject(error);
      });

    // for (const updateData of slicedUsers) {
    //   console.log({ updateData });
    //   await batchUpdateItems({
    //     tableName: apiRoutes.userConfiguration,
    //     conditionKey: "identityId",
    //     updateData,
    //   });
    // }
  } catch (error) {
    console.error("Error in updateIpAddressForAllUsers:", error);
    throw error;
  }
}

// export async function updateAllUsers(userConfiguration, fieldOptions) {
//   const allUsers = userConfiguration?.allUsers?.Items || [];
//   let promises = [];
//   allUsers.forEach(async (user) => {
//     // const list = user.ipAddressWhitelist || {};
//     // const newKey = updateIpAddressKey(list, fieldOptions, deletedIps);
//     promises.push(
//       updateUserPromise(user.identityId, {
//         ipAddressWhitelist: {
//           list: fieldOptions,
//         },
//       })
//     );
//   });
//   await Promise.allSettled(promises);
// }
