import { CoordinateType } from "src/components/SidebarPages/FleetMaintenanceView/types";
/**
 * Check if a point is inside the geofence
 *
 * @param {Array<CoordinateType>} polygon - Array of coordinates that forms the polygon
 * @param {CoordinateType} point - Coordinates of the point that will be checked
 * @returns {boolean} - True if point is inside the polygon, False if it is outside
 */
export function isPointInsidePolygon(
  polygon: Array<CoordinateType>,
  point: CoordinateType
) {
  if (!point) {
    return false;
  }

  if (isNaN(Number(point?.lat)) || isNaN(Number(point?.lng))) {
    return false;
  }

  if (!polygon.length) {
    return false;
  }

  if (
    polygon.some(
      (coord) => isNaN(Number(coord?.lat)) || isNaN(Number(coord?.lng))
    )
  ) {
    return false;
  }

  const { lat: x, lng: y } = point;
  let isInside = false;

  for (let i = 0, j = polygon?.length - 1; i < polygon.length; j = i++) {
    const { lat: xi, lng: yi } = polygon[i];
    const { lat: xj, lng: yj } = polygon[j];

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) {
      isInside = !isInside;
    }
  }

  return isInside;
}
