import { useState } from "react";
import { Col, Divider, Row } from "antd";

import Text from "src/components/commonComponents/Typography/Text";
import Button from "src/components/commonComponents/Buttons/Button";

const transformKeyLabel = (inputString) => {
  return inputString
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Add space for consecutive uppercase letters
    .replace(/_/g, " ") // Replace underscores with space
    .toLowerCase() // Convert to lowercase
    .replace(/(^|\s)\S/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
};

const hasHTMLTags = (value) => {
  const htmlTagsRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlTagsRegex.test(value);
};

const Item = ({ value }) => {
  if (Array.isArray(value)) {
    return (
      <Text whiteText strong>
        {value.map(
          (item, index) => item + (index === value.length - 1 ? "" : ", ")
        )}
      </Text>
    );
  }
  if (hasHTMLTags(value)) {
    return (
      <Text whiteText strong dangerouslySetInnerHTML={{ __html: value }} />
    );
  }
  if (typeof value === "boolean") {
    return (
      <Text whiteText strong>
        {value === true ? "Yes" : "No"}
      </Text>
    );
  }
  if (typeof value === "object" && value !== null) {
    return (
      <Text whiteText strong>
        {value?.name || ""}
      </Text>
    );
  }

  return (
    <Text whiteText strong>
      {value}
    </Text>
  );
};

export default function ChartTooltip({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [recordsToShow, setRecordsToShow] = useState(15);

  const handleLoadMore = () => {
    setIsLoading(true);

    setTimeout(() => {
      setRecordsToShow((prev) => prev + 15);
      setIsLoading(false);
    }, 1000);
  };

  const displayedRecords = data?.slice(0, recordsToShow);

  const allRecordsDisplayed = displayedRecords?.length === data?.length;

  return (
    <div style={{ padding: 10, position: "relative" }}>
      <Row gutter={[0, 10]}>
        {displayedRecords?.map((item, index) => (
          <Col span={24} key={index}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Row>
                  {Object.entries(item).map((key) => (
                    <Col span={24} key={key}>
                      <Row gutter={[10]}>
                        <Col span={8}>
                          <Text whiteText>{transformKeyLabel(key[0])}:</Text>
                        </Col>
                        <Col span={16}>
                          <Item value={key[1]} />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
              {index !== data?.length - 1 && (
                <Col span={24}>
                  <Divider
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      background: "#858585",
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        ))}
      </Row>
      <Button
        fullWidth
        type="primary"
        isLoading={isLoading}
        disabled={isLoading || allRecordsDisplayed}
        onClick={handleLoadMore}
        style={{
          position: "sticky",
          bottom: 0,
          marginTop: 10,
        }}
      >
        Load more
      </Button>
    </div>
  );
}
