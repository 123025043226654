import React from "react";
import { Button, Flex } from "antd";
import { CheckOutlined, CloseOutlined, WarningFilled } from "@ant-design/icons";

import CustomModal from "../../../components/Modals/CustomModal";
import Headline from "../../../../../commonComponents/Typography/Headline";
import Text from "../../../../../commonComponents/Typography/Text";

const FailInspectionModal = ({ visible, setVisible, onFail }) => {
  return (
    <CustomModal
      title={
        <Flex align="center" gap="small">
          <WarningFilled style={{ fontSize: "15px", color: "#FDC700" }} />
          <Headline level={4} style={{ lineHeight: 1 }}>
            Fail Inspection
          </Headline>
        </Flex>
      }
      open={visible}
      onClose={() => setVisible(false)}
    >
      <Flex vertical gap={40}>
        <div>
          <Headline level={4}>Warning!</Headline>
          <Text>
            You're about to fail this inspection! Are you sure you want to
            proceed?
          </Text>
        </div>

        <Flex justify="end" align="center" gap="small">
          <Button
            color="default"
            variant="filled"
            icon={<CloseOutlined style={{ fontSize: "17px" }} />}
            iconPosition="end"
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            variant="solid"
            icon={<CheckOutlined style={{ fontSize: "17px" }} />}
            iconPosition="end"
            onClick={() => (onFail(), setVisible(false))}
          >
            OK
          </Button>
        </Flex>
      </Flex>
    </CustomModal>
  );
};

export default FailInspectionModal;
