import moment from "moment";
import { devices } from "../DeviceManagement/utils";

export const returnDetailsForWhiteListIps = (
  device,
  ip,
  userWhiteListIp,
  programFieldsWhiteListIp,
  nameOfUser,
  geoLocation
) => {
  const address = geoLocation?.formatted_address;

  const { ipExistenceCheck } = ipExistenceCheckFunc(
    userWhiteListIp,
    programFieldsWhiteListIp
  );

  const { ipEnablingCheck } = ipEnablingCheckFunc(
    userWhiteListIp,
    programFieldsWhiteListIp,
    device
  );

  const correctDeviceName = devices.find(
    ({ deviceLabel }) => deviceLabel === device
  ).deviceName;

  if (ipExistenceCheck.exists) {
    if (ipEnablingCheck.isEnabled) {
      if (ipEnablingCheck.deviceAllowance.deviceAllowance) {
        return {
          restrict: false,
        };
      } else {
        return {
          restrict: true,
          reason: `Access to this IP address (${ip}) from device: (${correctDeviceName})${
            address ? ` and from address (${address})` : ""
          } is restricted and can only be accessed from an authorized device.`,
          checkedFrom: `checked from ${ipEnablingCheck.deviceAllowance.location}`,
        };
      }
    } else {
      return {
        restrict: true,
        reason: `this ip (${ip}) is disabled in whitelist and can only be accessed from a enabled ip.`,
        checkedFrom: `checked from ${ipEnablingCheck.location}`,
      };
    }
  } else {
    return {
      restrict: true,
      reason: `On ${moment(Date.now()).format(
        "MM/DD/YYYY HH:mm A"
      )}, an access attempt was made to our website by (${nameOfUser}) from the IP address (${ip})${
        address ? ` and from address (${address})` : ""
      }. This IP address was not on our whitelist at the time of the request, and the user is not linked to any whitelisted IP`,
      checkedFrom: `checked from ${ipExistenceCheck.location}`,
    };
  }
};

function ipExistenceCheckFunc(userWhiteListIp, programFieldsWhiteListIp) {
  const ipExistenceCheck =
    !userWhiteListIp && !programFieldsWhiteListIp
      ? {
          location: "specific user white list and global white list",
          exists: false,
        }
      : userWhiteListIp && programFieldsWhiteListIp
      ? {
          location: "specific user white list and global white list",
          exists: true,
        }
      : !programFieldsWhiteListIp
      ? {
          location: userWhiteListIp
            ? "specific user white list"
            : "global white list",
          exists: userWhiteListIp ? true : false,
        }
      : {
          location: programFieldsWhiteListIp
            ? "global white list"
            : "specific user white list",
          exists: programFieldsWhiteListIp ? true : false,
        };

  return { ipExistenceCheck };
}

function ipEnablingCheckFunc(
  userWhiteListIp,
  programFieldsWhiteListIp,
  currentDevice
) {
  const isEnabledForUser = userWhiteListIp?.isEnabled;
  const isEnabledProgramFields = programFieldsWhiteListIp?.isEnabled;

  const ipEnablingCheck =
    !isEnabledForUser && !isEnabledProgramFields
      ? {
          location: "specific user white list and global white list",
          isEnabled: false,
          deviceAllowance: false,
        }
      : isEnabledForUser && isEnabledProgramFields
      ? {
          location: "specific user white list and global white list",
          isEnabled: true,
          deviceAllowance: checkDeviceAllowanceFunc(
            userWhiteListIp,
            programFieldsWhiteListIp,
            currentDevice
          ),
        }
      : !isEnabledProgramFields
      ? {
          location: isEnabledForUser
            ? "specific user white list"
            : "global white list",
          isEnabled: isEnabledForUser,
          deviceAllowance: checkDeviceAllowanceFunc(
            userWhiteListIp,
            programFieldsWhiteListIp,
            currentDevice
          ),
        }
      : {
          location: isEnabledProgramFields
            ? "global white list"
            : "specific user white list",
          isEnabled: isEnabledProgramFields,
          deviceAllowance: checkDeviceAllowanceFunc(
            userWhiteListIp,
            programFieldsWhiteListIp,
            currentDevice
          ),
        };
  return { ipEnablingCheck };
}

function checkDeviceAllowanceFunc(
  userWhiteListIp,
  programFieldsWhiteListIp,
  currentDevice
) {
  const userDevice = checkDeviceAllowance(userWhiteListIp, currentDevice);
  const pfDevice = checkDeviceAllowance(
    programFieldsWhiteListIp,
    currentDevice
  );

  const toReturn =
    !userDevice && !pfDevice
      ? {
          location: "specific user white list and global white list",
          deviceAllowance: false,
        }
      : userDevice && pfDevice
      ? {
          location: "specific user white list and global white list",
          deviceAllowance: true,
        }
      : !pfDevice
      ? {
          location: userDevice
            ? "specific user white list"
            : "global white list",
          deviceAllowance: userDevice ? true : false,
        }
      : {
          location: pfDevice ? "global white list" : "specific user white list",
          deviceAllowance: pfDevice ? true : false,
        };
  return toReturn;
}

function checkDeviceAllowance(rowData, currentDevice) {
  return rowData?.devices?.some(
    ({ deviceLabel }) => deviceLabel === currentDevice
  );
}
