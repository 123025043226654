import React from "react";
import { Link } from "react-router-dom";
import SecureValueDisplay from "../../../../../SidebarPages/DynamicView/cardComponent/Information/components/SecureValueDisplay/SecureValueDisplay";
import { EditIcon } from "../../../../../../icons";
import { TrashIcon } from "../../../../../SidebarPages/Communication/assets";

const statusStyle = {
  height: 30,
  width: "100%",
  color: "#fff",
  display: "flex",
  borderRadius: 5,
  alignItems: "center",
  justifyContent: "center",
  textTransform: "uppercase",
};

function columnDefs({
  writeAccess,
  salaryAccess,
  crewStatuses,
  setRowToDelete,
  isFromHrManagement,
  recordClicked = () => {},
}) {
  const statusMap = {};
  for (let i = 0; i < crewStatuses?.length; i++) {
    const statusField = crewStatuses[i];
    Object.assign(statusMap, {
      [statusField?.statusName]: statusField?.statusColor,
    });
  }

  return [
    {
      headerName: "Name",
      field: "crewName",
      cellClass: "cell-left-aligned",
      cellRenderer: ({ data }) => {
        const employeeId =
          data?.engineerId ||
          data?.architectId ||
          data?.driverId ||
          data?.crewId;

        const employeeType = data?.engineerId
          ? "engineers"
          : data?.architectId
          ? "architects"
          : data?.driverId
          ? "drivers"
          : data?.crewId
          ? "crews"
          : null;

        return (
          <Link
            style={{ color: "#1264a3" }}
            to={`/hrManagement/${employeeType}/${employeeId}`}
            state={{ employeeType: employeeType }}
          >
            {data?.crewName}
          </Link>
        );
      },
    },
    {
      headerName: "Employee ID",
      field: "employeeId",
      cellClass: "cell-left-aligned",
    },
    !isFromHrManagement && {
      headerName: "Foreman",
      field: "foreman",
      cellClass: "cell-left-aligned",
      getQuickFilterText({ value }) {
        return typeof value === "boolean" && value ? "Foreman" : "Employee";
      },
      valueGetter: ({ data }) => {
        const value = data?.foreman;
        if (typeof value === "boolean" && value) {
          return "Foreman";
        } else {
          return "Employee";
        }
      },
      // cellRenderer: ({ value }) => {
      //   console.log("value: ", value);
      //   if (typeof value === "boolean" && value) {
      //     return "Foreman";
      //   } else {
      //     return "Employee";
      //   }
      // },
      // cellRenderer: ({ value }) => {
      //   console.log("value: ", value);
      //   if (typeof value === "boolean" && value) {
      //     return "Foreman";
      //   } else {
      //     return "Employee";
      //   }
      // },
    },
    isFromHrManagement && {
      headerName: "Role",
      field: "role",
      cellClass: "cell-left-aligned",
      resizable: true,
      width: 350,
    },
    !isFromHrManagement && {
      headerName: "Crew Position",
      field: "crewPosition",
      cellClass: "cell-left-aligned",
      resizable: true,
    },
    !isFromHrManagement && {
      headerName: "Subcontractor",
      field: "accountName",
      cellClass: "cell-left-aligned",
      resizable: true,
    },
    salaryAccess?.read &&
      isFromHrManagement && {
        headerName: "Rate",
        field: "employeeRate",
        cellClass: "cell-left-aligned",
        resizable: true,
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
          inRangeInclusive: true,
        },
        maxWidth: 150,
        cellRenderer: ({ value }) => {
          if (!value) return null;
          return isFromHrManagement ? (
            <SecureValueDisplay value={value} />
          ) : (
            value
          );
        },
      },
    salaryAccess?.read &&
      isFromHrManagement && {
        headerName: "Salary Type",
        field: "salaryType",
        cellClass: "cell-left-aligned",
        resizable: true,
        maxWidth: 150,
      },
    !isFromHrManagement && {
      headerName: "Status",
      field: "crewStatus",
      cellClass: "cell-center-aligned",
      resizable: true,
      minWidth: 250,
      cellRenderer: ({ value }) => {
        const backgroundColor = statusMap?.[value] || "#787677";
        return <div style={{ ...statusStyle, backgroundColor }}>{value}</div>;
      },
    },
    isFromHrManagement && {
      headerName: "Status",
      field: "employeeStatus",
      cellClass: "cell-center-aligned",
      resizable: true,
      minWidth: 250,
      cellRenderer: ({ value }) => {
        const backgroundColor = statusMap?.[value] || "#787677";
        return <div style={{ ...statusStyle, backgroundColor }}>{value}</div>;
      },
    },
    !isFromHrManagement && {
      headerName: "Crew Team",
      resizable: true,
      minWidth: 170,
      field: "crewTeam",
      cellRenderer: ({ data, context }) => {
        const teams = context?.crewTeams;
        const team = teams.find(({ crewForeman, crewMembers }) => {
          const isForeman =
            (crewForeman?.crewId && data?.crewId === crewForeman?.crewId) ||
            (crewForeman?.employeeId &&
              data?.employeeId === crewForeman?.employeeId);

          const isMember = (crewMembers || []).find(
            (memb) =>
              (memb?.crewId && memb?.crewId === data?.crewId) ||
              (memb?.employeeId && memb?.employeeId === data?.employeeId)
          );
          return isForeman || isMember;
        });

        return team?.crewTeamName || "";
      },
      valueGetter: ({ data, context }) => {
        const teams = context?.crewTeams;
        const team = teams.find(({ crewForeman, crewMembers }) => {
          const isForeman =
            (crewForeman?.crewId && data?.crewId === crewForeman?.crewId) ||
            (crewForeman?.employeeId &&
              data?.employeeId === crewForeman?.employeeId);

          const isMember = (crewMembers || []).find(
            (memb) =>
              (memb?.crewId && memb?.crewId === data?.crewId) ||
              (memb?.employeeId && memb?.employeeId === data?.employeeId)
          );

          return isForeman || isMember;
        });
        return team?.crewTeamName || "";
      },
    },
    writeAccess && {
      headerName: "Edit",
      field: "edit",
      cellRenderer: (params) => {
        if (params?.data?.role?.includes("Total")) return null;
        return (
          <div
            data-testid="edit-icon"
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <EditIcon
              style={{
                cursor: "pointer",
                fill: "#1264A3",
              }}
              onClick={() => recordClicked(params)}
            />
          </div>
        );
      },
      onCellClicked: recordClicked,
      pdfExportOptions: {
        skipColumn: true,
      },
      maxWidth: 150,
    },
    writeAccess && {
      headerName: "Delete",
      field: "delete",
      cellRenderer: ({ data }) => {
        if (data?.role?.includes("Total")) return null;
        return (
          <div
            data-testid="delete-icon"
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TrashIcon
              onClick={() => setRowToDelete(data)}
              style={{ cursor: "pointer" }}
              fill="#FE4C4A"
            />
          </div>
        );
      },
      maxWidth: 160,
      pdfExportOptions: {
        skipColumn: true,
      },
    },
  ].filter(Boolean);
}

export default columnDefs;
