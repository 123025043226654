import { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { LoadingDots } from "../../../../../commonComponents/3LoadingDots/LoadingDots";
import { Notes, StarCheckbox } from "../../../../../commonComponents";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { message } from "antd";
import htmlParser from "../../../../../../utils/htmlParser";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Transcription from "../Transcription/Transcription";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { useDispatch } from "react-redux";
import { fetchAllData } from "../../../../../../utils";
import { getLastSeenUsers } from "../../utils/getLastSeenUsers";
import { API } from "aws-amplify";
import { updatePreferences } from "../../../../../../actions/preferences";
import { updateProgramFieldByName } from "../../../../../../actions/programFields";
import ResetModal from "../ResetModal/ResetModal";
import ViewsInfo from "../ViewsInfo/ViewsInfo";
import { v4 } from "uuid";
import TranscribeSwitch from "./TranscribeSwitch";
import "./VideoPlayerComponent.scss";
import { useResponsive } from "../../../../../../hooks";

const VideoPlayerComponent = ({
  openModal,
  setOpenModal,
  videoEnd,
  setVideoEnd,
  setEmailBox,
  videosToSend,
  setVideosToSend,
  setAttachments,
  showTranscript,
  setShowTranscript,
  videoPlayerRef,
  saveAddedLogs,
  handleFetchLogs,
  notesModalVisible,
  setNotesModalVisible,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const driveReq = driveApi({ accessToken });
  const [fileSrc, setFileSrc] = useState("");
  const [playing, setPlaying] = useState(true);
  const [loading, setLoading] = useState(false);

  const [favLoading, setFavLoading] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const [videViewDetails, setVideoViewDetails] = useState({
    lastSeenUsers: [],
    topUsers: [],
    totalViews: 0,
  });

  const navigate = useNavigate();
  const { mobile, tablet } = useResponsive();

  const {
    primaryArray,
    activePrimaryCat,
    videoActive,
    newVideos,
    playingVideo,
    hasWatched,
    setHasWatched,
    subCategoryName,
    favourites,
    playedVideos,
  } = useContext(VideoTutorialContext);

  const handleTextClick = (start) => {
    setCurrentTime(start < 0.01 ? start : start + 0.01);
    videoPlayerRef.current.seekTo(start < 0.01 ? start : start + 0.01);
  };

  const dispatch = useDispatch();

  const unseenVideo = newVideos.find((video) => video.id === playingVideo.id);

  const fetchData = async () => {
    setLoading(true);
    const logs = await fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: `VideoTutorials ${playingVideo.id}`,
                formula: "is",
              },
            ],
          },
        ]),
      },
    });

    const sortedLogs = logs.sort((a, b) => {
      return b.updatedAt - a.updatedAt;
    });

    const { lastSeenUsers, topUsers, totalViews } =
      getLastSeenUsers(sortedLogs);
    setVideoViewDetails({
      lastSeenUsers,
      topUsers,
      totalViews,
    });

    driveReq
      ?.getImageSrc(videoActive.split("=")[1])
      .then((res) => setFileSrc(res))
      .catch((err) => {
        console.log("ERR: ", err);
        message.error("Error loading video");
      });

    setLoading(false);
  };

  useEffect(() => {
    if (accessToken) {
      fetchData();
    }
  }, [videoActive, accessToken]);

  const onVideoEnd = async () => {
    if (!!videoPlayerRef?.current) {
      const updatedPlayedVideos = playedVideos
        ?.map((video) => ({ id: video.id, time: video.time }))
        ?.filter((item) => item?.id !== playingVideo?.id);

      const updatedVideoPreferences = primaryArray.map((category) => ({
        ...category,
        subCategories: category.subCategories.map((subCategory) => ({
          ...subCategory,
          videos: subCategory.videos.map((video) => ({ id: video.id })),
        })),
      }));

      const preferencesToSave = {
        ...allPreferences.preferences,
        videoPreferences: {
          ...allPreferences.preferences.videoPreferences,
          playedVideos: updatedPlayedVideos,
          order: updatedVideoPreferences,
        },
      };

      try {
        await API.patch("preferences", "/preferences", {
          body: {
            preferences: preferencesToSave,
          },
        });

        dispatch(updatePreferences(preferencesToSave));

        const filters = [
          {
            operator: "AND",
            conditions: [
              {
                operator: "AND",
                column: "actionType",
                value: "Partial Watched",
                formula: "is",
              },
            ],
          },
          {
            operator: "AND",
            conditions: [
              {
                operator: "AND",
                column: "recordId",
                value: playingVideo.id,
                formula: "is",
              },
            ],
          },
          {
            operator: "AND",
            conditions: [
              {
                operator: "AND",
                column: "nameOfUser",
                value: userConfiguration.nameOfUser,
                formula: "is",
              },
            ],
          },
        ];

        const logs = await handleFetchLogs({ filters });

        if (logs.length === 0) return;

        const logsToDelete = logs.map(({ logId }) => logId);

        try {
          await API.del("editLogs", "/editLogs/123", {
            body: {
              logs: logsToDelete,
            },
          });
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const userHasSeen = async () => {
    const videoTutorials =
      programFields.filter(
        (item) => item.fieldName === "Portal Video Tutorials"
      )[0]?.fieldOptions ?? [];

    if (!hasWatched) {
      setHasWatched(true);

      saveAddedLogs({
        actionType: "Watched",
        category: "Video Tutorials",
        recordId: `VideoTutorials ${playingVideo?.id}`,
        recordName: "Watched",
        updatedKeys: [
          {
            type: v4(),
            key: "",
            label: playingVideo?.videoName,
            previousValue: "",
            updatedValue: "Watched",
          },
        ],
      });
    }

    const userWatched = playingVideo.usersHaveSeen.find(
      (user) => user === userConfiguration.identityId
    );

    if (!userWatched) {
      const updatedArray = videoTutorials.map((category) => ({
        ...category,
        subCategories: category.subCategories.map((subCategory) => ({
          ...subCategory,
          videos: subCategory.videos.map((video) => {
            if (video.id === playingVideo.id) {
              return {
                ...video,
                usersHaveSeen: [
                  ...video.usersHaveSeen,
                  userConfiguration.identityId,
                ],
              };
            }

            return video;
          }),
        })),
      }));

      try {
        await API.patch(
          "programFields",
          `/programFields/7effeb2d-bf57-46e4-83ae-3e871826159d`,
          {
            body: {
              fieldOptions: updatedArray,
            },
          }
        ).then(() =>
          dispatch(
            updateProgramFieldByName({
              fieldName: "Portal Video Tutorials",
              fieldOptions: updatedArray,
            })
          )
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  function onPlay() {
    setPlaying(true);
  }

  const handleNearEnd = async () => {
    setVideoEnd(true);
    await userHasSeen();
  };

  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);

    const duration = state.loadedSeconds;
    const remainingTime = duration - state.playedSeconds;

    if (remainingTime <= 10 && remainingTime > 0 && !videoEnd) {
      handleNearEnd();
    }
  };

  const sendEmailClick = () => {
    if (!videosToSend || videosToSend.length === 0) {
      return setEmailBox(true);
    }

    const videoAttachments = videosToSend.map((video) => {
      const videoAttachment = {
        name: `${video.videoName.replace(/ /g, "")}.mp4`,
        blob: null,
        size: 0,
        type: "video/mp4",
        status: 400,
      };

      return videoAttachment;
    });

    setAttachments(videoAttachments);
    setEmailBox(true);
  };

  const onFavoriteUnclick = async () => {
    // EDIT PREFERENCES
    setFavLoading(true);
    message.loading({
      content: "Deleting from Favourites",
      duration: 0,
    });

    const updatedFavourites = favourites
      .filter((fav) => fav.id !== playingVideo.id)
      .map((item) => item.id);

    const preferencesToSave = {
      ...allPreferences.preferences,
      videoPreferences: {
        ...allPreferences.preferences.videoPreferences,
        favourites: updatedFavourites,
      },
    };

    try {
      await API.put("preferences", "/preferences", {
        body: {
          preferences: preferencesToSave,
        },
      });
      message.destroy();
      message.success("Changes saved successfuly!");
      setFavLoading(false);
      dispatch(updatePreferences(preferencesToSave));

      // DELETE FAVOURITE LOGS
      message.loading({
        content: "Deleting Logs",
        duration: 0,
      });

      const filters = [
        {
          operator: "AND",
          conditions: [
            {
              operator: "AND",
              column: "actionType",
              value: "Favourite",
              formula: "is",
            },
          ],
        },
        {
          operator: "AND",
          conditions: [
            {
              operator: "AND",
              column: "recordId",
              value: playingVideo.id,
              formula: "is",
            },
          ],
        },
        {
          operator: "AND",
          conditions: [
            {
              operator: "AND",
              column: "nameOfUser",
              value: userConfiguration.nameOfUser,
              formula: "is",
            },
          ],
        },
      ];

      const logs = await handleFetchLogs({ filters });

      if (logs.length === 0) return;

      const logsToDelete = logs.map(({ logId }) => logId);

      try {
        await API.del("editLogs", "/editLogs/123", {
          body: {
            logs: logsToDelete,
          },
        });
        message.destroy();
        message.success("Logs deleted successfuly!");
      } catch (error) {
        console.error(error);
        message.error("Something went wrong...");
      }
    } catch (error) {
      console.log(error);
      message.destroy();
      message.error("There was an error while saving this");
    }
  };

  const onFavoriteClick = async () => {
    setFavLoading(true);
    message.loading({
      content: "Adding to Favourites...",

      duration: 0,
    });

    const updatedFavourites = [
      ...favourites.map((fav) => fav.id),
      playingVideo.id,
    ];

    const preferencesToSave = {
      ...allPreferences.preferences,
      videoPreferences: {
        ...allPreferences.preferences.videoPreferences,
        favourites: updatedFavourites,
      },
    };

    try {
      await API.put("preferences", "/preferences", {
        body: {
          preferences: preferencesToSave,
        },
      });

      saveAddedLogs({
        actionType: "Favourite",
        category: "Video Tutorials",
        recordId: playingVideo?.id,
        recordName: "Favourite",
        updatedKeys: [
          {
            type: v4(),
            key: "",
            label: playingVideo?.videoName,
            previousValue: "",
            updatedValue: "Favourite",
          },
        ],
      });
      message.destroy();
      message.success("Changes saved successfuly!");
      setFavLoading(false);
      dispatch(updatePreferences(preferencesToSave));
    } catch (error) {
      console.log(error);
      message.destroy();
      message.error("There was an error while saving this");
    }
  };

  const isVideoFav = () => {
    return favourites?.find((fav) => fav.id === playingVideo.id);
  };

  const getVideoLink = () => {
    const subCatName = subCategoryName?.replaceAll(" ", "-");
    return `https://portal.corescaffold.com/dashboard?selectedVideoCat=${activePrimaryCat}&selectedVideoSubCat=${subCatName}&selectedVideoId=${playingVideo.id}`;
  };

  const getAllVideos = (checkLength = false) => {
    const videos = [];

    primaryArray.forEach((category) => {
      category.subCategories.forEach((subCategory) => {
        subCategory.videos.forEach((video) => {
          videos.push({
            id: video.id,
            category: category.categoryName,
            subCategoryName: subCategory.subCategoryName,
            videoId: video.videoLink.split("id=")[1],
            lengthVideoInMin: video.lengthVideoInMin,
            videoName: video.videoName,
            ...(video.hasOwnProperty("description") &&
              video.description !== "" && {
                description: video.description,
              }),
          });
        });
      });
    });

    if (!!checkLength) {
      return videos.length === videosToSend.length;
    }

    return videos;
  };

  const handleSendEmail = () => {
    sendEmailClick();
  };

  return (
    <>
      <div
        className={`videoAndDescription ${
          tablet && "videoAndDescription-tablet"
        }
        ${mobile && "videoAndDescription-mobile"}
        ${isDarkMode && "videoAndDescription-dark"}
      `}
      >
        {!loading && !!fileSrc ? (
          <div className="reactPlayerDiv">
            <ReactPlayer
              playbackRate={1}
              playsInline
              height="100%"
              width="100%"
              controls={true}
              url={[
                {
                  src: fileSrc.src,
                },
              ]}
              playing={playing}
              onStart={(e) => {
                let time = playedVideos?.find(
                  (el) => el?.id === playingVideo?.id
                )?.time;

                if (!!time && !!videoPlayerRef?.current) {
                  videoPlayerRef?.current?.seekTo(time, "seconds");
                }
              }}
              onProgress={handleProgress}
              onPlay={onPlay}
              onPause={() => setPlaying(false)}
              light={true}
              onEnded={() => onVideoEnd()}
              ref={(player) => {
                if (!!player) {
                  videoPlayerRef.current = player;
                }
              }}
              stopOnUnmount={true}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80%",
            }}
          >
            <LoadingDots style={{ fontSize: "50px" }} />
          </div>
        )}

        <div className="description-container">
          <div className="header">
            {!loading && (
              <>
                <div className="fav-link">
                  {!unseenVideo && (
                    <StarCheckbox
                      {...{
                        checked: isVideoFav(),
                        onChange: (favorite) =>
                          !favLoading
                            ? favorite
                              ? onFavoriteClick()
                              : onFavoriteUnclick()
                            : message.error("Cant change"),
                      }}
                    />
                  )}
                  <DynamicAvatars
                    {...{
                      title: "Seen By",
                      isDarkMode: isDarkMode,
                      members: videViewDetails.lastSeenUsers.map(
                        (user) => user
                      ),
                      nameKey: "name",
                      idKey: "id",
                      customTooltip: true,
                    }}
                  />
                  {playingVideo.link && (
                    <button onClick={() => navigate(playingVideo.link)}>
                      {playingVideo.videoName}
                    </button>
                  )}
                </div>
                <div className="fav-link">
                  <ViewsInfo
                    {...{
                      topUsers: videViewDetails.topUsers,
                      totalViews: videViewDetails.totalViews,
                    }}
                  />
                  {(playingVideo.hasOwnProperty("transcribedText") &&
                    playingVideo.transcribedText.trimStart().length < 1) ||
                  !playingVideo.hasOwnProperty("transcribedText") ? null : (
                    <TranscribeSwitch
                      checked={showTranscript}
                      setChecked={setShowTranscript}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="description-container">
            <h2 className="title">Description</h2>
            <div className="description-box">
              {playingVideo.hasOwnProperty("description") &&
              playingVideo?.description?.length > 0
                ? htmlParser(playingVideo?.description)
                : "No Description to show"}
            </div>
          </div>
        </div>
      </div>
      {!!showTranscript && (
        <div className="transcript-container">
          <Transcription
            {...{
              video: playingVideo,
              handleTextClick,
              currentTime,
            }}
          />
        </div>
      )}
      {openModal && (
        <ResetModal
          {...{
            isModalOpen: openModal,
            setIsModalOpen: setOpenModal,
            videosToRemove: videosToSend,
            setVideosToRemove: setVideosToSend,
            areAllSelected: getAllVideos(true),
            modalTitle: "Video Tutorials",
            sendEmail: true,
            array: getAllVideos(),
            handleSendEmail,
          }}
        />
      )}
      {notesModalVisible && (
        <Notes
          notesVisibility={notesModalVisible}
          setNotesVisibility={setNotesModalVisible}
          noteModalName={playingVideo.videoName}
          rowId={playingVideo.id}
          topicCategory="Video Tutorials"
        />
      )}
    </>
  );
};

export default VideoPlayerComponent;
