import { XIcon } from "../../../../../Communication/assets";
import { Modal } from "antd";
import { InputComponent } from "../../../../../Fleet/components";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const ScheduleViewModal = ({
  open,
  children,
  onCancel = () => {},
  showSearch = false,
  gridApi,
  title,
  detailsCell = false,
  dataTestId = "",
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [value, setValue] = useState("");

  const searchFunc = (e) => {
    if (!!detailsCell && !!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setValue(e);
  };

  return (
    <Modal
      wrapClassName="schedule-modal-wrapper"
      title={
        showSearch ? (
          <div className="customTitle">
            <span>{title}</span>
            <InputComponent
              type="input"
              prefix={<SearchOutlined className="SearchLogoIcon" />}
              value={value}
              onChange={(e) => searchFunc(e.target.value)}
            />
          </div>
        ) : (
          title
        )
      }
      open={open}
      className={`schedulingOverviewModal ${
        isDarkMode && "schedulingOverviewModalDark"
      }`}
      closeIcon={<XIcon data-testid="fullScreenClose" />}
      onCancel={(e) => onCancel(e)}
      data-testid={dataTestId}
    >
      {children}
    </Modal>
  );
};

export default ScheduleViewModal;
