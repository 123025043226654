import { sortBy } from "lodash";
import { filterTables } from "../../../../../../../../../../../utils";
import { appliedAmountGenerator } from "../../../../Header/Components/ControlPanel/ControlPanelFunctions";
import { totalitiesTotalTaxGen } from "../RentalHeader/Components/ServiceRentHeader/utils";
import { message } from "antd";

export const getApplicableRentals = async ({ projectId = "" }) => {
    try {
        message.loading("Fetching applicable records...", 2);
        const rentals = await filterTables("rentals", "projectId", projectId);
        
        // Additional processing
        const modifiedRentals = rentals
        // .map((item) => {
        //     const appliedAmount = appliedAmountGenerator({ fakeRentals: item });
        //     const { totalTax = 0, totalPrice = 0, retainageAmount = 0 } = totalitiesTotalTaxGen({ fakeRentals: item });
            
        //     return {
        //         ...item,
        //         totalPrice,
        //         totalTax,
        //         retainageAmount,
        //         totalAppliedRent: appliedAmount,
        //     };
        // });
        
        console.log("sortedRentals", modifiedRentals);
        return modifiedRentals;
    } catch (error) {
        console.error("Error fetching rentals:", error);
        return [];
    }
};
