import {
  useContext,
  useState,
  useEffect,
  forwardRef,
  useMemo,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import _ from "lodash";

import { ProjectMatchContext } from "../../data";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { LeftArrow } from "src/components/SidebarPages/BasePage/src";
import { SmallLoadableComp } from "src/components/Sidebars/components";
import { filterTables } from "src/utils";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { parseInTz } from "../../../NewDispatchModal/utils/dateFunctions";

import "./MatchScheduleInfo.scss";

/**
 * @typedef ScheduleDay
 * @property {string} scheduleName
 * @property {string} typeOfWork
 * @property {number} day
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} status
 * @property {string} scheduleId
 */

//#region COMPONENT
const MatchScheduleInfo = forwardRef(({ getSelectedRoutes }, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    selectedViewData,
    filters,
    matchedData,
    selectedId,
    onFilterChange,
    updateMatchedData,
    dispatches,
    changeConfirmDisabled,
    changeConfirmText,
  } = useContext(ProjectMatchContext);

  const [loading, setLoading] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState();
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [scheduleDays, setScheduleDays] = useState(
    /** @type {ScheduleDay[]} */ ([])
  );
  const [selectedDay, setSelectedDay] = useState(
    /** @type {ScheduleDay} */ (undefined)
  );

  const dispatchesCount = useMemo(() => {
    //#region DISPATCHES COUNT
    return scheduleDays.reduce((acc, day) => {
      let counter = 0;
      for (const dispatch of dispatches) {
        if (
          dayjsNY(day.startDate).startOf("day").format() !==
          dispatch.dispatchDate
        ) {
          continue;
        }

        if (
          dispatch.routes.some(({ scheduleId: id }) => id === day.scheduleId)
        ) {
          ++counter;
        }
      }

      return {
        ...acc,
        [day.scheduleId]: {
          ...(acc?.[day.scheduleId] || {}),
          [day.day]: counter,
        },
      };
    }, {});
  }, [dispatches, scheduleDays]);

  const dispatchesDays = useMemo(() => {
    //#region DISPATCHES DAYS
    return scheduleDays.reduce((acc, day) => {
      const dsp = [];
      for (const dispatch of dispatches) {
        if (
          dayjsNY(day.startDate).startOf("day").format() !==
          dispatch.dispatchDate
        ) {
          continue;
        }

        if (
          dispatch.routes.some(({ scheduleId: id }) => id === day.scheduleId)
        ) {
          dsp.push(dispatch);
        }
      }

      return {
        ...acc,
        [day.scheduleId]: {
          ...(acc?.[day?.scheduleId] || {}),
          [day.day]: dsp,
        },
      };
    }, {});
  }, [dispatches, scheduleDays]);

  useEffect(() => {
    //#region GET SCHEDULES
    const itemData = matchedData?.[selectedViewData?.["address"]]?.[selectedId];
    if (!itemData) {
      return;
    }

    if (itemData?.subitems) {
      return;
    }

    setLoading(true);

    filterTables("scheduling", "projectId", itemData.item.id)
      .then((r) => {
        setLoading(false);
        updateMatchedData(selectedViewData["address"], selectedId, {
          subitems: r,
        });
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while trying to get the schedules",
          key: "addressInfo",
        });

        setLoading(false);
        console.log("Error getting schedules: ", err);
        return null;
      });
  }, [selectedViewData, matchedData, selectedId]);

  useEffect(() => {
    //#region ALL SCHEDULE DAYS
    const tmpSubitems =
      matchedData?.[selectedViewData?.["address"]]?.[selectedId]?.["subitems"];
    if (!tmpSubitems) {
      setScheduleDays([]);
      return;
    }

    const tmpDays = [];
    for (const schedule of tmpSubitems) {
      const { scheduleName, scheduleDays, typeOfWork, scheduleId } = schedule;

      for (const day of scheduleDays) {
        tmpDays.push({
          scheduleName,
          typeOfWork,
          day: day.day,
          startDate: day.startDate,
          endDate: day.endDate,
          status: day.status,
          scheduleId,
        });
      }
    }

    let tmpOptions = [];

    if (filters["date"]) {
      tmpOptions = tmpSubitems.flatMap(
        ({ scheduleId, scheduleName, scheduleDays }) => {
          if (
            scheduleDays.every((day) => {
              return (
                dayjsNY(day?.startDate).startOf("D").format() !==
                filters["date"]
              );
            })
          ) {
            return [];
          }

          return {
            label: scheduleName,
            value: scheduleId,
          };
        }
      );
    } else {
      tmpOptions = tmpSubitems.flatMap(({ scheduleId, scheduleName }) => ({
        label: scheduleName,
        value: scheduleId,
      }));
    }

    setScheduleDays(tmpDays);
    setScheduleOptions(tmpOptions);

    if (tmpOptions.length) {
      setSelectedScheduleId((prev) => {
        if (tmpOptions.find(({ value }) => value === prev)) {
          return prev;
        }

        return tmpOptions?.[0]?.["value"];
      });
    }
  }, [selectedViewData, matchedData, selectedId, filters]);

  //#region REF HANDLER
  useImperativeHandle(
    ref,
    () => {
      return {
        getSelectedDayInfo() {
          return selectedDay;
        },
      };
    },
    [selectedDay]
  );

  function dayClickHandler(data) {
    //#region DAY CLICK
    setSelectedDay(data);
    if (
      !filters["date"] ||
      (filters["date"] && filters["dateChangeReason"] === "AUTOMATIC")
    ) {
      onFilterChange({
        date: dayjsNY(data.startDate).startOf("day").format(),
        dateChangeReason: "AUTOMATIC",
      });
    }

    const selectedRows = getSelectedRoutes();
    if (!selectedRows.length) {
      changeConfirmText();
      changeConfirmDisabled(true);
    } else {
      changeConfirmDisabled(false);
      changeConfirmText(
        `Match routes with schedule day - ${dayjsNY(data.startDate).format(
          "MMM DD"
        )}`
      );
    }
  }

  function backHandler() {
    //#region BACK HANDLER
    setSelectedDay(undefined);
    if (filters["date"] && filters["dateChangeReason"] === "AUTOMATIC") {
      onFilterChange({ date: null });
    }

    const selectedRows = getSelectedRoutes();
    if (!selectedRows.length) {
      changeConfirmText();
      changeConfirmDisabled(true);
    } else {
      changeConfirmDisabled(false);
      changeConfirmText("Match routes with selected project");
    }
  }

  //#region JSX
  return (
    <div
      className={`project-match-card project-match-schedule-card ${
        isDarkMode ? "project-match-schedule-dark" : ""
      }`}
    >
      <div className="project-match-card-header">
        {/** 
          //#region CARD HEADER
        */}
        <span className="card-header-title">
          {!selectedDay
            ? "Schedule Info"
            : `Schedule Info - ${dayjsNY(selectedDay.startDate).format(
                "MMM DD"
              )}`}
        </span>
        {scheduleOptions?.length > 1 ? (
          <InputComponent
            {...{
              label: null,
              noFormItem: true,
              placeholder: "Select a schedule...",
              allowClear: false,
              className: "header-schedule-select",
              type: "select",
              initialValue: selectedScheduleId,
              customOptions: scheduleOptions,
              onSelect(id) {
                setSelectedScheduleId(id);
                backHandler();
              },
              style: { maxWidth: "50%" },
            }}
          />
        ) : null}
      </div>
      <div className="project-match-body">
        <SmallLoadableComp loading={loading} darkMode={isDarkMode}>
          {!scheduleDays?.length ? (
            <div className="no-schedule-days-info">
              No schedules were found for this project
            </div>
          ) : !scheduleOptions?.length ? (
            <div className="no-schedule-days-info">
              No schedule days were found for the selected date
            </div>
          ) : !selectedDay ? (
            scheduleDays.flatMap((day, index) => {
              //#region MAPS SCHEDULE DAYS
              if (filters["date"]) {
                if (
                  filters["date"] !==
                  dayjsNY(day.startDate).startOf("day").format()
                ) {
                  return [];
                }
              }

              if (selectedScheduleId) {
                if (day["scheduleId"] !== selectedScheduleId) {
                  return [];
                }
              }

              return (
                <div
                  className="match-inner-schedule-info-container"
                  key={`${index}`}
                  onClick={() => {
                    dayClickHandler(day);
                  }}
                >
                  <div className="schedule-info-data">
                    <b className="schedule-info-title">Day</b>
                    <span className="schedule-info-value">{day.day}</span>
                  </div>
                  <div className="schedule-info-data">
                    <b className="schedule-info-title">Start Date</b>
                    <span className="schedule-info-value">
                      {dayjsNY(day.startDate).format("MM/DD/YYYY")}
                    </span>
                  </div>
                  <div className="schedule-info-data">
                    <b className="schedule-info-title">End Date</b>
                    <span className="schedule-info-value">
                      {dayjsNY(day.endDate).format("MM/DD/YYYY")}
                    </span>
                  </div>
                  <div className="schedule-info-data">
                    <b className="schedule-info-title">Dispatches</b>
                    <b
                      className="schedule-info-value"
                      style={{ color: "#1264A3" }}
                    >
                      {dispatchesCount?.[day.scheduleId]?.[day?.day] || 0}{" "}
                      Dispatches
                    </b>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="single-day-selected-container">
              {/** 
                //#region SINGLE DAY SELECTED
              */}
              <div
                className="back-button"
                onClick={() => {
                  backHandler();
                }}
              >
                <LeftArrow fill="#1264A3" />
                <b style={{ color: "#1264A3" }}>Back to all schedule days</b>
              </div>
              <div className="day-dispatches-list">
                {dispatchesDays?.[selectedDay?.scheduleId]?.[selectedDay?.day]
                  ?.length ? (
                  dispatchesDays[selectedDay.scheduleId][selectedDay.day].map(
                    (dispatch, index) => {
                      return (
                        <div
                          className="single-day-dispatch-container"
                          key={`dispatch-${index}`}
                        >
                          <div className="single-dispatch-title">
                            Dispatch {index + 1} - {dispatch.fleetName}
                          </div>
                          {dispatch["routes"].map((route, i) => {
                            return (
                              <div
                                className={`single-dispatch-details-container ${
                                  i % 2 > 0 ? "route-odd" : "route-even"
                                }`}
                                key={`route-${i}`}
                              >
                                <div className="route-info">
                                  <div className="location-dot leave"></div>
                                  <span className="location-name">
                                    {route.pickUpLocation}
                                  </span>
                                </div>
                                <div className="route-info">
                                  <div className="location-dot enter"></div>
                                  <span className="location-name">
                                    {route.dropOffLocation}
                                  </span>
                                </div>
                                <div className="separated-info">
                                  <div className="separated-info-section">
                                    {parseInTz(route.arriveBy).format(
                                      "MM/DD/YYYY - HH:mm"
                                    )}
                                  </div>
                                  <div className="separated-info-separator"></div>
                                  <div
                                    className={`separated-info-section cargo-${route.cargo}`}
                                  >
                                    {route.cargo}
                                  </div>
                                </div>
                                <div className="separated-info">
                                  <div className="separated-info-section">
                                    {route.driverName}
                                  </div>
                                  <div className="separated-info-separator"></div>
                                  <div className="separated-info-section">
                                    {dispatch.fleetName}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="no-schedule-days-info">
                    No dispatches are linked to this schedule day
                  </div>
                )}
              </div>
            </div>
          )}
        </SmallLoadableComp>
      </div>
    </div>
  );
});

export default MatchScheduleInfo;
