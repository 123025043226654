import React, { useMemo, useState } from "react";
import "../styles/HeaderFilter.scss";
import { MondayButton } from "../../../commonComponents";
import { DeleteIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { Select } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { uniqBy } from "lodash";
import { PlusIcon } from "../../../../assets";
import { NormalSizedModal } from "../../../SidebarPages/Fleet/components";
import ProposalStepper from "../../NewProposal/ProposalStepper/ProposalStepper";
function HeaderFilter({ handleFilterChange, filters, rowData }) {
  const [newProposal, setNewProposal] = useState(false);

  const onClearFilters = () => {
    handleFilterChange("reset");
  };

  const reportProjects = useMemo(() => {
    return uniqBy(
      rowData?.map((item) => ({
        label: item?.otherDetails?.jobSiteAddress,
        value: item?.projectId,
      })),
      "value"
    );
  }, [rowData]);

  const statusesOptions = useMemo(() => {
    return uniqBy(
      rowData?.map((item) => ({
        label: item?.proposalStatus,
        value: item?.proposalStatus,
      })),
      "value"
    );
  }, [rowData]);

  return (
    <div className="proposal-header-filter-wrapper">
      {" "}
      <div className="proposal-header-left">
        <MondayButton
          className="mondayButtonBlue"
          Icon={<RollbackOutlined />}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go Back
        </MondayButton>
        {/* <DebounceSelect
          className="proposal-header-filter-select"
          value={proposalData?.filter?.project ?? null}
          placeholder="Search a project"
          fetchOptions={fetchOptions}
          onSelect={onSelectDataSrc}
        /> */}

        <Select
          {...{
            className: "proposal-header-filter-select",
            style: { width: "300px" },
            placeholder: "Filter by project",
            options: reportProjects,
            value: filters?.jobSiteAddress,
            onSelect: (value) => handleFilterChange("jobSiteAddress", value),
          }}
        />
        <Select
          className="proposal-header-filter-select"
          placeholder="Filter by Proposal Status"
          // disabled
          style={{ width: "300px" }}
          options={statusesOptions}
          value={filters?.proposalStatus}
          onSelect={(value) => handleFilterChange("proposalStatus", value)}
        />
        <MondayButton
          className="mondayButtonRed"
          Icon={<DeleteIcon />}
          onClick={onClearFilters}
          disabled={!filters.jobSiteAddress && !filters.proposalStatus}
        >
          Clear Filters
        </MondayButton>
      </div>
      <div className="proposal-header-right">
        <MondayButton
          {...{
            Icon: <PlusIcon />,
            onClick: () => {
              setNewProposal(true);
            },
          }}
        >
          Create New Proposal
        </MondayButton>
      </div>
      {newProposal && (
        <NormalSizedModal
          title={"New Proposal"}
          open={newProposal}
          maskClosable={false}
          onCancel={() => setNewProposal(false)}
          className="new-proposal-modal"
          width={1800}
          destroyOnClose
          centered
          closable={true}
          customFooter={<div></div>}
        >
          <div className="new-proposal-modal-headerStepper">
            <ProposalStepper />
          </div>
        </NormalSizedModal>
      )}
    </div>
  );
}

export default HeaderFilter;
