import React, { useEffect, useRef, useState } from "react";
import ProposalHeader from "./components/ProposalHeader/ProposalHeader";
import ProposalSidebar from "./components/ProposalSidebar/ProposalSidebar";
import "./ProposalBuilder.scss";
import ProposalBodyStepper from "./components/ProposalBodyStepper/ProposalBodyStepper";
import { API } from "aws-amplify";
import JoditEditorCustom from "./components/JoditEditorCustom/JoditEditorCustom";
import { LoadableComp } from "../SidebarPages/XComponents";
import { ProposalProvider } from "./WaitingRoomProposal/ProposalContext";
import Swal from "sweetalert2";
import { mapTagToData } from "./components/ProposalHeader/components/RealTimeTakeOffPreview/mappers/dataMapper";
import axios from "axios";
// location state needs to have this structure state {estimation: {…}, takeOff: {…}, takeOffId: ...}
function ProposalBuilder() {
  const editorRef = useRef(null);
  const [proposalData, setProposalData] = useState({});
  const [loading, setLoading] = useState(false);
  // const [proposalData, setProposalData] = useRedux(" proposalData", {}, false);
  const [proposalStatus, setProposalStatus] = useState("Drafts");
  const [visbCheckers, setVisbCheckers] = useState({
    realTimePreview: false,
    templateBuilder: true,
    sidebar: true,
  });

  const getDataFromQueryParams = async () => {
    // new url params
    const urlParams = new URLSearchParams(window.location.search);
    const proposalId = urlParams.get("proposalId");
    const takeOffId = urlParams.get("takeOffId");
    let result = {};
    setLoading(true);
    await API.get("proposalBuilds", `/proposalBuilds/${proposalId}`)
      .then(async (currentProposal) => {
        result.currentProposal = currentProposal;
        await API.get(
          "estimations",
          `/estimations/${currentProposal?.estimationId}`
        )
          .then((estimation) => {
            setLoading(false);
            result.estimation = estimation;
          })
          .catch((error) => {
            console.log("error fetching estimation data", error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log("error fetching proposal data", error);
        setLoading(false);
      });
    result.proposalId = proposalId;
    result.takeOffId = takeOffId;
    return result;
  };

  const onDownload = async (openNewTab) => {
    try {
      const myMadeSenseData = mapTagToData({
        proposalData: proposalData,
        html: editorRef.current.value,
      });
      const response = await axios.post(
        `https://leadmanager-express-backend.ue.r.appspot.com/api/htmlDocusealConverter`,
        // `http://10.0.0.84:8080/api/htmlDocusealConverter`,
        // `https://leadmanager-express-backend.ue.r.appspot.com/api/${endPoint}`,
        {
          html: myMadeSenseData,
        }
      );
      if (openNewTab) {
        window.open(response.data?.documents?.[0]?.url);
      }
    } catch (error) {
      console.error("Error converting HTML to document:", error.message);
    }
  };

  const onPreview = async (newTab) => {
    Swal.fire({
      title: "<strong>Finalizing...</strong>",
      icon: "info",
      html: "Please wait! ",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await onDownload(newTab);
    } catch (err) {
      console.log("err", err);
      Swal.fire({
        title: "<strong>Finalize Proposal Error</strong>",
        icon: "error",
        html: "Your proposal could not be finalized! ",
      });
    } finally {
      Swal.close();
    }
  };

  const onViewChange = (activeView) => {
    if (visbCheckers?.realTimePreview) {
      setVisbCheckers({
        realTimePreview: false,
        templateBuilder: true,
        sidebar: true,
      });
    } else {
      setVisbCheckers({
        realTimePreview: true,
        templateBuilder: true,
        sidebar: false,
      });
    }
  };

  useEffect(() => {
    getDataFromQueryParams().then((result) => {
      console.log("getDataFromQueryParams", result);
      const tmpBody = {
        takeOff:
          result?.estimation?.versionServices?.[`${result?.takeOffId}`] || {},
        estimation: result?.estimation || {},
        realTimePreview: false,
        realTimePreviewData: {},
        proposalId: result?.proposalId,
        takeOffId: result?.takeOffId,
        wrappedHtml: result?.currentProposal?.wrappedHtml || "",
        templateData: {
          ...(result?.currentProposal?.templateData || {}),
        },
        savedContent: result?.currentProposal?.savedContent || "",
      };
      setProposalData(tmpBody);
    });
  }, []);

  return (
    <ProposalProvider
      value={{
        proposalData,
        setProposalData,
      }}
    >
      <LoadableComp loading={loading}>
        <div className="new_proposal_builder">
          <header className="new_proposal_builder__header-wrapper">
            <ProposalHeader
              {...{
                editorRef,
                onViewChange,
                onPreview,
              }}
            />
          </header>{" "}
          <ProposalBodyStepper
            {...{
              proposalStatus,
              setProposalStatus,
            }}
          />
          <div className="new_proposal_builder__body">
            {visbCheckers?.templateBuilder && (
              <div
                className="new_proposal_builder__body__editor-wrapper"
                onDragOver={(event) => {
                  event.preventDefault();
                }}
              >
                {/* <ProposalTinyEditor {...{ editorRef }} /> */}
                <JoditEditorCustom ref={editorRef} />
              </div>
            )}

            {/* {visbCheckers?.realTimePreview && (
              <div className="new_proposal_builder__body__realtime-preview">
                <RealTimeTakeOffPreview {...{ editorRef }} />{" "}
              </div>
            )} */}

            {visbCheckers?.sidebar && (
              <div className="new_proposal_builder__body__sidebar-wrapper">
                <ProposalSidebar {...{ editorRef }} />
              </div>
            )}
          </div>
        </div>
      </LoadableComp>
    </ProposalProvider>
  );
}

export default ProposalBuilder;
