import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Input, Modal, Select } from "antd";
import { MondayButton } from "../../../../commonComponents";
import { ClearFilterIcon } from "../../../DynamicView/src";
import { DropdownIconReport } from "../PageControlPanel/PageControlPanel";
import { useMemo, useState } from "react";
import { DatepickerIcon } from "../../../Scheduling/ScheduleChangesModal/assets";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { XIcon, XIconWhite } from "../../../Communication/assets";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";

import "./ReportModals.scss";

dayjs.extend(timezone);

const FilterModal = ({
  filterModal,
  setFilterModal,
  setActiveFilters,
  configDatas,
  activeFilters,
  userList,
  isDarkMode,
}) => {
  const [filters, setFilters] = useState(activeFilters || {});
  const { reportName, createdBy, categoryName, department, createdAt } =
    filters || {};

  const categoriesMemo = useMemo(
    () => configDatas.map((con) => con.categoryName),
    [configDatas]
  );

  const departmentsMemo = useMemo(
    () => configDatas.map((con) => con.department),
    [configDatas]
  );

  const optMapper = (opt) => ({ label: opt, value: opt });
  const setTempFilters = (filter, value) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
  };

  return (
    <Modal
      {...{
        open: filterModal,
        centered: true,
        onCancel: () => setFilterModal(false),
        title: "Filter Reports",
        className: `reportsPageModals ${isDarkMode && "reportsPageModalsDark"}`,
        footer: (
          <>
            <MondayButton
              {...{
                className: "mondayButtonRed",
                onClick: () => {
                  setFilterModal(false);
                  // setActiveFilters({});
                  setFilters({});
                },
                Icon: <XIconWhite />,
              }}
            >
              Cancel
            </MondayButton>
            {Object.keys(filters).length > 0 && (
              <MondayButton
                {...{
                  Icon: <ClearFilterIcon />,
                  onClick: () => {
                    setActiveFilters({}), setFilters({});
                  },
                  className: "mondayButtonRed",
                  style: { marginLeft: "20px" },
                }}
              >
                Clear Filters
              </MondayButton>
            )}
            <MondayButton
              {...{
                className: "mondayButtonBlue",
                onClick: () => {
                  setFilterModal(false);
                  setActiveFilters(filters);
                  setFilters({});
                },
                Icon: <TickIcon width={17} height={17} />,
              }}
            >
              Apply
            </MondayButton>
          </>
        ),
        closeIcon: <XIcon />,
      }}
    >
      <div className="filtersCard">
        <div className="filtersContainer">
          <div className="searchBarContainer">
            {/* <Input
              {...{
                value: reportName,
                placeholder: "Search for reports",
                onChange: (e) => setTempFilters("reportName", e.target.value),
                prefix: <SearchOutlined />,
              }}
            /> */}
          </div>

          <div className="filterSelectors">
            <div className="filterInput">
              <div className="filterInputLabel">Category</div>
              <Select
                allowClear
                {...{
                  value: categoryName,
                  placeholder: "Category",
                  className: "dynamicSelect",
                  popupClassName: isDarkMode
                    ? "darkDropDown"
                    : `dynamicSelectDropdown`,
                  onSelect: (value) => setTempFilters("categoryName", value),
                  options: categoriesMemo.map(optMapper),
                  onClear: () => setTempFilters("categoryName", undefined),
                  suffixIcon: <DropDownArrow />,
                }}
              />
            </div>

            <div className="filterInput">
              <div className="filterInputLabel">Department</div>
              <Select
                allowClear
                {...{
                  value: department,
                  placeholder: "Department",
                  className: "dynamicSelect",
                  popupClassName: isDarkMode
                    ? "darkDropDown"
                    : `dynamicSelectDropdown`,
                  onSelect: (value) => setTempFilters("department", value),
                  options: departmentsMemo.map(optMapper),
                  onClear: () => setTempFilters("department", undefined),
                  suffixIcon: <DropDownArrow />,
                }}
              />
            </div>

            <div className="filterInput">
              <div className="filterInputLabel">Created By</div>
              <Select
                allowClear
                {...{
                  value: createdBy,
                  placeholder: "Created By",
                  className: "dynamicSelect",
                  popupClassName: isDarkMode
                    ? "darkDropDown"
                    : `dynamicSelectDropdown`,
                  onSelect: (value) => setTempFilters("createdBy", value),
                  options: userList.map(optMapper),
                  onClear: () => setTempFilters("createdBy", undefined),
                  suffixIcon: <DropDownArrow />,
                }}
              />
            </div>

            <div className="filterInput">
              <div className="filterInputLabel">Created At</div>
              <DatePicker
                {...{
                  value: createdAt ? dayjs.tz(createdAt) : undefined,
                  placeholder: "Created At",
                  format: "MM/DD/YYYY",
                  popupClassName: isDarkMode && "darkDateDropDown",
                  onChange: (value) =>
                    setTempFilters("createdAt", dayjs.tz(value).valueOf()),
                  suffixIcon: <DatepickerIcon />,
                  allowClear: false,
                  onClear: () => setTempFilters("createdAt", undefined),
                }}
              />
            </div>

            <div className="filterInput">
              <div className="filterInputLabel">Dates</div>
              <InputComponent
                {...{
                  type: "rangepicker",
                  format: "MM/DD/YYYY",
                  style: { width: "100%" },
                  onChange: (dates, dateStrings) => {
                    setTempFilters(
                      "rangeCreatedAt",
                      dates && [dayjs(dateStrings[0]), dayjs(dateStrings[1])]
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
