import moment from "moment";
import createPDF, { PREVIEW } from "../../../../integrations/createPDF";
import { compareIncluding, groupArray } from "../../utils";
import { fetchData } from ".";

const dateFormat = "MM/DD/YYYY";
const timeFormat = "HH:mm";
export const vehicleInspectionToPDF = async ({ companyLogos, rowObj }) => {
  const {
    fleetName,
    inspectionType,
    inspectionReport,
    inspectedBy,
    mechanic,
    driver,
    inspectionDate,
    inspectionNotes,
    inspectionSignature,
  } = rowObj;

  const fillColor = (rowIndex) => {
    return rowIndex % 2 > 0 ? "#f4f4f4" : "#ffffff";
  };
  const borders = { hLineWidth: () => 0, vLineWidth: () => 0 };

  let companyDetails;
  await fetchData("companies").then((r) => {
    companyDetails = r.find((item) =>
      compareIncluding(item.companyName, "Core Scaffold Systems")
    );
  });

  const formattedInputs = (data = [], title, noColor = false) => {
    const formattedData = data.map(({ label, value, type }) => [
      { text: label },
      {
        text: compareIncluding(type, "datepicker")
          ? moment.unix(value / 1000).format(dateFormat)
          : compareIncluding(type, "timepicker")
          ? moment.unix(value / 1000).format(timeFormat)
          : value,
        alignment: "right",
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [
          [
            {
              stack: [
                { text: title, style: ["strong"], margin: [10, 10, 10, 0] },
                {
                  table: {
                    widths: ["*", "auto"],
                    body: formattedData,
                  },
                  layout: { ...borders, fillColor },
                  style: "innerTable",
                },
              ],
            },
          ],
        ],
      },
      style: "outterTable",
      layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
    };
  };

  const formattedBooleans = (data = [], title) => {
    const formattedData = data.map(({ label, value }) => [
      { text: label },
      {
        width: "auto",
        columns: ["YES", "NO"].map((bool, i) => ({
          alignment: "right",
          width: 15,
          style: !i ? "firstCheck" : "",
          svg: compareIncluding(bool, value)
            ? `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 54 54" style="enable-background:new 0 0 54 54;" xml:space="preserve">
<style type="text/css">
.st0{fill:none;}
.st1{fill:none;stroke:#707070;stroke-width:3;}
.st2{fill:none;stroke:#333333;stroke-width:7;}
</style>
<g id="Rectangle_1196">
<rect class="st0" width="54" height="54"/>
<rect x="1.5" y="1.5" class="st1" width="51" height="51"/>
</g>
<path id="Path_6983" class="st2" d="M9,29.9L19.1,40L45,14"/>
</svg>
`
            : `<svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
<g id="Rectangle_1195" data-name="Rectangle 1195" fill="none" stroke="#707070" stroke-width="3">
  <rect width="54" height="54" stroke="none"/>
  <rect x="1.5" y="1.5" width="51" height="51" fill="none"/>
</g>
</svg>`,
        })),
      },
    ]);

    return {
      table: {
        widths: ["*"],
        body: [
          [
            {
              stack: [
                {
                  columns: [
                    { text: title, style: ["strong", "content"], width: "*" },
                    {
                      text: "Yes",
                      style: ["strong", "content"],
                      alignment: "right",
                      width: "auto",
                    },
                    {
                      text: "No",
                      style: ["strong", "content"],
                      alignment: "right",
                      width: "auto",
                    },
                  ],
                },
                {
                  table: {
                    widths: ["*", "auto"],
                    body: formattedData,
                  },
                  layout: { ...borders, fillColor },
                  style: "innerTable",
                },
              ],
            },
          ],
        ],
      },
      style: "outterTable",
      layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
    };
  };

  const getItemsByValue = (items = [], filterValue) =>
    groupArray(
      items
        .filter(({ value }) => compareIncluding(value, filterValue))
        .map(({ label }) => label),
      4
    ).map((arr) => ({ columns: arr }));

  const itemsTable = ({
    title,
    titleColor,
    data = [],
    type,
    noMargin = false,
  }) => ({
    table: {
      widths: ["*"],
      body: [
        [
          {
            stack: [
              {
                text: title,
                style: ["strong"],
                color: titleColor,
                margin: [0, 0, 0, 5],
              },
              ...getItemsByValue(data, type),
            ],
          },
        ],
      ],
    },
    style: !noMargin ? "outterTable" : "",
  });

  const formattedDefectiveItems = (data = []) => {
    return {
      table: {
        widths: ["*"],
        body: [
          [
            {
              stack: [
                {
                  text: `Total Items (${data.length})`,
                  style: ["strong"],
                  margin: [0, 0, 0, 10],
                },

                itemsTable({
                  title: "Acceptable",
                  titleColor: "#9EDE73",
                  data,
                  type: "acceptable",
                }),
                itemsTable({
                  title: "Poor",
                  titleColor: "#E48900",
                  data,
                  type: "Poor",
                }),
                itemsTable({
                  title: "Defective",
                  titleColor: "#BE0000",
                  data,
                  type: "Defective",
                  noMargin: true,
                }),
              ],
              margin: 10,
            },
          ],
        ],
      },
      style: "outterTable",
      layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
    };
  };

  const additionalComments = {
    pageBreak: "after",
    table: {
      widths: ["*"],
      body: [
        [
          {
            margin: 10,
            stack: [
              { text: "Additional Comments", style: ["strong"], width: "*" },
              inspectionNotes,
            ],
          },
        ],
      ],
    },
    style: "outterTable",
    layout: { hLineColor: () => "#707070", vLineColor: () => "#707070" },
  };

  const signatures = {
    width: "*",

    margin: [0, 20, 0, 0],
    columns: [
      {
        startPosition: { pageNumber: 2 },
        stack: [
          {
            text: `${driver}`,
            alignment: "center",
            style: "strong",
          },
          {
            text: `DRIVER`,
            alignment: "center",
            style: "faded",
          },
          {
            svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
        <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#000000" stroke-width="1"/>
      </svg>`,
            width: 150,
            height: 2,
            style: "subheader",
            alignment: "center",
          },
          {
            image: inspectionSignature?.driver,
            fit: [80, 80],
            alignment: "center",
            style: "sign",
          },
        ],
        width: "*",
        alignment: "left",
      },
      {
        stack: [
          {
            text: `${mechanic}`,
            alignment: "center",
            style: "strong",
          },
          {
            text: `MECHANIC`,
            alignment: "center",
            style: "faded",
          },
          {
            svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
      <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#000000" stroke-width="1"/>
    </svg>`,
            width: 150,
            height: 2,
            style: "subheader",
            alignment: "center",
          },
          {
            image: inspectionSignature?.mechanic,
            fit: [80, 80],
            alignment: "center",
            style: "sign",
          },
        ],
        width: "*",
        alignment: "center",
      },
      {
        stack: [
          {
            text: inspectedBy,
            alignment: "center",
            style: "strong",
          },
          {
            text: `DRIVER REVIEWING REPAIRS`,
            alignment: "center",
            style: "faded",
          },
          {
            svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
    <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#000000" stroke-width="1"/>
  </svg>`,
            width: 150,
            height: 2,
            style: "subheader",
            alignment: "center",
          },

          {
            image: inspectionSignature?.inspectedBy,
            fit: [80, 80],
            alignment: "center",
            style: "sign",
          },
        ],
        width: "*",
        alignment: "right",
      },
    ],
  };

  const dd = {
    pageOrientation: "portrait",
    pageMargins: [20, 20, 20, 20],
    footer: (currentPage, pageCount) => {
      return {
        text: `${currentPage} of ${pageCount} | ${companyDetails.companyName?.toUpperCase()}`,
        alignment: "center",
      };
    },
    content: [
      {
        columns: [
          {
            image: "logo",
            width: 100,
          },

          {
            stack: [
              {
                text: companyDetails.address,
                style: ["strong", "projectName"],
              },
              companyDetails.phoneNumber,
            ],
            width: "*",
            alignment: "right",
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
          <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
        </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [
          {
            columns: [
              {
                text: `Date: `,
                style: ["strong", "date"],
                width: "auto",
              },
              `  ${moment.unix(inspectionDate / 1000).format("MM/DD/YYYY")}`,
            ],
          },
          {
            text: `${inspectionType} Inspection`,
            style: "strong",
            alignment: "center",
          },
          {
            text: fleetName,
            style: "strong",
            alignment: "right",
          },
        ],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      formattedInputs(
        inspectionReport.find(({ category }) =>
          compareIncluding(category, "details")
        ).values,
        "Details"
      ),
      formattedDefectiveItems(
        inspectionReport.find(({ category }) =>
          compareIncluding(category, "defective items")
        ).values
      ),
      formattedBooleans(
        inspectionReport.find(({ category }) =>
          compareIncluding(category, "booleans")
        ).values,
        "Questions"
      ),
      additionalComments,
      signatures,
    ],
    images: {
      logo: companyLogos?.find(
        ({ fileName }) =>
          compareIncluding(fileName, "black") &&
          compareIncluding(companyDetails.companyName, fileName.split(" ")[0])
      )?.base64,
    },
    styles: {
      date: { margin: [0, 0, 5, 0] },
      firstCheck: { margin: [0, 0, 10, 0] },
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      projectName: {
        margin: [0, 10, 0, 5],
      },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10] },
      innerTable: { margin: 10 },
      outterTable: { margin: [0, 0, 0, 10] },
      content: { margin: [10, 10, 12, 0] },
      coloredRow: { background: "#fafafa" },
      lastColumn: { color: "#4A4A4A" },
      faded: { color: "#CACACA", margin: [0, 10, 0, 10] },
      image: { margin: 1 },
      bool: { margin: [0, 5] },
      sign: { margin: [0, 10, 0, 20] },
    },
  };
  return createPDF({
    action: PREVIEW,
    docDefinition: dd,
  });
};
