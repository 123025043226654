import "./AreaChart.scss";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useChartRefsContext } from "../../../providers/ChartRefsContext";
import { useEffect, useRef } from "react";

const AreaChart = ({
  chartData: { labels, data, label, backgroundColor },
  onClick = () => {},
  title,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { registerChartRef } = useChartRefsContext();
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      registerChartRef(chartRef.current, title);
    }
  }, [registerChartRef]);

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "right",
        labels: {
          padding: labels.length > 10 ? 5 : 10,
          boxWidth: labels.length > 10 ? 20 : 40,
          color: isDarkMode ? "#f2f2f2" : "#333238",
        },
      },
    },
    onHover: (event) => {
      event.native.target.style.cursor = "pointer";
    },
    onClick,
  };

  return (
    <div className="card-area-chart" ref={chartRef}>
      <Line
        data={{
          labels,
          datasets: [
            {
              label,
              data,
              fill: true, // Fill the area under the line
              backgroundColor,
              hoverOffset: 4,
              borderColor: "rgba(75,192,192,1)", // Border color of the line
              borderWidth: 4, // Border width of the line
            },
          ],
        }}
        options={options}
      />
    </div>
  );
};

export default AreaChart;
