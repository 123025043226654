import "./Styles.scss";
import { Divider, Flex } from "antd";
import { useSelector } from "react-redux";

import Text from "../../../../commonComponents/Typography/Text";

const FooterFilterInfo = (props) => {
  const { api } = props;

  if (!api) return null;

  const { programFields } = useSelector((state) => state.programFields);

  const inspectionTypes = programFields
    ?.find((item) => item?.fieldName === "Inspection Types")
    ?.fieldOptions?.map((item) => item?.statusName ?? "");

  let rowData = [];
  api.forEachNodeAfterFilterAndSort((node) => {
    rowData.push(node.data);
  });

  const panelData = inspectionTypes?.map((type) => {
    return {
      statusName: type,
      statusLength:
        rowData?.filter((item) => item?.inspectionType === type)?.length || 0,
    };
  });

  return (
    <div className="custom-grid-status-panel">
      {panelData.map((item, i) => (
        <>
          <Flex key={i} justify="center" align="center" gap={3}>
            <Text span style={{ textWrap: "nowrap" }}>
              {item.statusName}:
            </Text>
            <Text strong span>
              {item.statusLength}
            </Text>
          </Flex>

          {i !== panelData.length - 1 && <Divider type="vertical" />}
        </>
      ))}
    </div>
  );
};

export default FooterFilterInfo;
