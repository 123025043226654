import { useState } from "react";
import { Col, Input, Modal, Row, Select, message } from "antd";
import { SketchPicker } from "react-color";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import "./EditModal.scss";
import {
  CloseOutlined,
  InfoCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import {
  LogsButton,
  MondayButton,
  MondaySwitch,
  RedWarningModal,
} from "../../../../../../commonComponents";
import { Tick, TickIcon } from "../../../Roles/src";
import { makeFirstLetterLowerCase } from "../../utils/makeFirstLetterLowerCase";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { CloseIconModal } from "../../../../../../../assets";
import { useResponsive } from "../../../../../../../hooks";
import { getChangedData } from "../../../../../../SidebarPages/Accounting/components/utilities";

const EditModal = ({
  data,
  setData,
  createLog,
  editModal,
  fieldName,
  categories,
  manualSave,
  hasKeyColor,
  setEditModal,
  selectedData,
  getPrevOrCurr,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields = [] } = useSelector((state) => state.programFields);

  const defaultValues = {
    statusId: "",
    statusName: "",
    status: true,
    description: "",
    ...(hasKeyColor ? { statusColor: "#ccc" } : {}),
    ...(categories.length > 0 ? { category: "" } : {}),
    ...(fieldName === "Inspection Types" ? { affectedByService: false } : {}),
    ...(fieldName === "Inspection Types"
      ? { isAffectedBySchedule: false }
      : {}),
    ...(fieldName === "Inspection Types" ? { relatedScheduleType: "" } : {}),
  };

  const [editData, setEditData] = useState(selectedData || defaultValues);
  const [isWarningVisible, setIsWarningVisible] = useState(false);

  const newId = v4();

  const { mobile } = useResponsive();

  const scheduleOptions =
    programFields
      ?.find(({ fieldName }) => fieldName === "Scheduling Types")
      ?.fieldOptions?.map(({ key, statusName }) => ({
        value: key,
        label: statusName,
      })) ?? [];

  const handleModalClose = () => {
    setEditModal(false);
  };

  const handleEdit = () => {
    setData((prev) =>
      prev.map((item) =>
        item.statusId !== editData.statusId
          ? item
          : {
              ...item,
              ...editData,
              key: makeFirstLetterLowerCase(editData.statusName),
            }
      )
    );

    const prev1 = getPrevOrCurr(selectedData, hasKeyColor, {
      status: selectedData.status ? "Enabled" : "Disabled",
    });

    const curr1 = getPrevOrCurr(editData, hasKeyColor, {
      status: editData.status ? "Enabled" : "Disabled",
    });

    const { curr, prev } = getChangedData(curr1, prev1);

    const newLogInfo = {
      recordId: selectedData.statusId,
      actionType: "Edit",
      ...(selectedData.hasOwnProperty("category")
        ? { recordName: selectedData.category }
        : {}),
      previousData: prev,
      currentData: curr,
    };

    createLog(newLogInfo);
  };

  const handleCreate = () => {
    const newData = {
      ...editData,
      statusId: newId,
      createdBy: userConfiguration.nameOfUser,
      createdAt: Date.now(),
      key: makeFirstLetterLowerCase(editData.statusName),
    };
    setData((prev) => [...prev, newData]);

    const newLogInfo = {
      recordId: newId,
      actionType: "Create",
      ...(editData.hasOwnProperty("category")
        ? { recordName: editData.category }
        : {}),
      previousData: getPrevOrCurr("", hasKeyColor),
      currentData: getPrevOrCurr(editData, hasKeyColor, {
        status: editData.status ? "Enabled" : "Disabled",
      }),
    };

    createLog(newLogInfo);

    if (manualSave) {
      manualSave(newData);
    }
  };

  const handleModalSave = () => {
    let allNames = data
      .filter((item) => {
        if (editData.hasOwnProperty("category")) {
          return (
            item.statusId !== editData.statusId &&
            item.category === editData.category
          );
        } else {
          return item.statusId !== editData.statusId;
        }
      })
      .map((row) => row?.statusName?.toLowerCase());

    if (editData.statusName.trimStart().length === 0) {
      return message.error("Please fill the Name");
    }

    if (allNames.includes(editData.statusName.trimStart().toLowerCase())) {
      return message.error(
        `${editData.statusName.toLowerCase()} already exists!`
      );
    }

    if (editData.hasOwnProperty("category") && !editData.category) {
      return message.error("Please select a Category");
    }

    editData.statusId ? handleEdit() : handleCreate();

    handleModalClose();
  };

  const handleEditItemChange = (key, value) => {
    setEditData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Modal
      title={`${editData.statusId ? "Edit" : "Create"} Status`}
      open={editModal}
      onCancel={handleModalClose}
      closeIcon={<XIcon />}
      className={`configTable-editModal ${
        isDarkMode && "configTable-editModal-dark"
      } ${!!mobile && "configTable-editModal-mobile"}`}
      footer={null}
      centered
    >
      <div className="info">
        <InfoCircleFilled
          style={{
            color: "#1264A3",
            fontSize: "20px",
          }}
        />
        <p>Make the needed changes below.</p>
      </div>
      <div className="body">
        <div className="left">
          {categories.length > 0 && (
            <div className="label-input">
              <label>Category</label>
              <InputComponent
                allowClear={false}
                type="select"
                placeholder="Select category..."
                onChange={(value) => handleEditItemChange("category", value)}
                defaultValue={editData.category}
                options={categories?.map((category) => (
                  <Select.Option value={category}>{category}</Select.Option>
                ))}
                disabled={editData.statusId}
              />
            </div>
          )}
          <div className="name-status">
            <div className="label-input">
              <label>Name</label>
              <Input
                placeholder="Enter name..."
                value={editData.statusName}
                onChange={(e) =>
                  handleEditItemChange("statusName", e.target.value)
                }
              />
            </div>
            <MondaySwitch
              {...{
                checked: editData.status,
                onToggleSwitch: (value) =>
                  handleEditItemChange("status", value),
                onText: "Enabled",
                offText: "Disabled",
                removeIcon: false,
              }}
            />
          </div>
          {fieldName === "Inspection Types" && (
            <Row gutter={[30, 0]}>
              <Col xs={12} md={7}>
                <div className="label-input" style={{ flex: 0 }}>
                  <label>Affected By Service</label>
                  <MondaySwitch
                    {...{
                      checked: editData.affectedByService,
                      onToggleSwitch: (value) => {
                        handleEditItemChange("affectedByService", value);
                        value === false &&
                          handleEditItemChange("relatedScheduleType", "");
                      },
                      onText: "True",
                      offText: "False",
                      removeIcon: false,
                      style: { width: "100%" },
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={7}>
                <div className="label-input" style={{ flex: 0 }}>
                  <label>Affected By Schedule</label>
                  <MondaySwitch
                    {...{
                      checked: editData.isAffectedBySchedule,
                      onToggleSwitch: (value) => {
                        handleEditItemChange("isAffectedBySchedule", value);
                      },
                      onText: "True",
                      offText: "False",
                      removeIcon: false,
                      style: { width: "100%" },
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={10}>
                <div className="label-input">
                  <InputComponent
                    style={{ textAlign: "center" }}
                    type="select"
                    label="Schedule Type"
                    dropdownClassName={isDarkMode && "darkDropDown"}
                    placeholder={`Select Scheme...`}
                    customOptions={scheduleOptions}
                    onChange={(value) => {
                      handleEditItemChange("relatedScheduleType", value);
                    }}
                    disabled={!!!editData.isAffectedBySchedule}
                    initialValue={editData.relatedScheduleType}
                  />
                </div>
              </Col>
            </Row>
          )}
          <div className="label-input">
            <label>Description</label>
            <ReactQuill
              placeholder="Write description here..."
              value={editData.description}
              onChange={(value) => handleEditItemChange("description", value)}
            />
          </div>
        </div>
        {!!hasKeyColor && (
          <div className="right">
            <div className="sketchPicker">
              <SketchPicker
                presetColors={[]}
                color={editData.statusColor}
                onChange={(updatedColor) =>
                  handleEditItemChange("statusColor", updatedColor.hex)
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <MondayButton
          className={"mondayButtonRed"}
          onClick={() => setIsWarningVisible(true)}
          Icon={<CloseOutlined />}
        >
          Cancel
        </MondayButton>
        {editData.statusId && (
          <LogsButton
            {...{
              buttonLabel: "Logs",
              logsTitle: `${fieldName} Logs`,
              filtersObj: { recordId: editData.statusId },
            }}
          />
        )}
        <MondayButton onClick={handleModalSave} Icon={<Tick />}>
          {editData.statusId ? "Edit" : "Create"}
        </MondayButton>
      </div>

      {isWarningVisible && (
        <RedWarningModal
          {...{
            visible: isWarningVisible,
            onCancel: () => setIsWarningVisible(false),
            footerProps: {
              onConfirm: () => {
                setIsWarningVisible(false);
                handleModalClose();
              },
            },
            confirmModal: true,
          }}
        >
          <div className="warning-content">
            <p>
              Are you sure you want to cancel changes? It will result in the
              <span> loss of any changes</span> you have made that have not been
              saved. Please confirm if you wish to proceed.
            </p>
          </div>
        </RedWarningModal>
      )}
    </Modal>
  );
};

export default EditModal;
