import { LockOutlined, UpOutlined } from "@ant-design/icons";
import { GlobeIcon } from "../../../../../../assets";
import { useChartCardsContext } from "../../../ChartCardsContext";
import MondayButton from "../../../../MondayButton/MondayButton";

const privacyValues = {
  public: { next: "private" },
  private: { next: "all" },
  all: { next: "public" },
};

const PublicPrivateCards = () => {
  const { showPrivateCards, setShowPrivateCards } = useChartCardsContext();

  return (
    <div className="chart-cards-pubic-private">
      <MondayButton
        className="mondayButtonBlue"
        tooltipCategory="CHART CARDS"
        tooltipKey="cardsPrivacy"
        Icon={
          showPrivateCards === "public" ? (
            <GlobeIcon />
          ) : showPrivateCards === "private" ? (
            <LockOutlined />
          ) : (
            <div style={{ fontSize: "15px" }}>All</div>
          )
        }
        onClick={() => {
          setShowPrivateCards((prev) => privacyValues[prev]?.next);
        }}
      />
    </div>
  );
};

export default PublicPrivateCards;
