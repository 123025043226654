import React from "react";
import ErrorComponent from "../assets/ErrorComponent";
import { LeftArrow } from "../components/SidebarPages/BasePage/src";
import { message } from "antd";
import "../index.scss";
import { OpenedEyeIcon } from "../assets";
import ErrorInfoModal from "../assets/ErrorInfoModal";
import { callGmailAPI } from "../components/SidebarPages/Communication/functions";
import { Auth, API } from "aws-amplify";
import {
  generateTemplateSubject,
  generateEmailTemplate,
  fetchEmailTemp,
} from "../components/pages/Settings/settingsComponents/PostEventsAutomationsConfig/functions/executeOneTimeAutomation.js";
import {
  osName,
  osVersion,
  browserName,
  fullBrowserVersion,
  deviceType,
} from "react-device-detect";
import { apiRoutes, fetchData } from "../components/SidebarPages/Fleet/utils";
import { getGeoLocation } from "../hooks/useEditLogs";

type Props = {
  children: React.ReactNode;
};

type ErrorInfo = {
  error?: Error;
  errorInfo?: React.ErrorInfo;
};

type UserInfo = {
  full_name: string;
  email: string;
  location: string;
  cognitoUserId: string;
};

type EnvironmentInfo = {
  deviceType: string;
  browserName: string;
  fullBrowserVersion: string;
  ip: string;
  osName: string;
  osVersion: string;
};

type State = {
  hasError: boolean;
  infoModal: boolean;
  errorInfo: ErrorInfo;
  userInfo: UserInfo;
  envInfo: EnvironmentInfo;
  explanation: string;
};

const reloadPaths = () => {
  if ("referrer" in document) window.location.replace(document.referrer);
  else window.location.reload();
};

export const sendErrorEmail = async (state: State, otherFunc = () => {}) => {
  const hideLoading = message.loading("Sending Email...", 0);
  const pathLocation =
    window.location.pathname + (window.location.search || "");

  const isProd = process.env.NODE_ENV === "production";

  if (isProd) {
    const emailTemplate = await fetchEmailTemp("Error Template");

    await callGmailAPI("sendEmail", {
      to: emailTemplate.templateRoles.to,
      subject: generateTemplateSubject(emailTemplate, {
        location: pathLocation,
      }),
      body: generateEmailTemplate(emailTemplate, {
        userName: state.userInfo.full_name,
        userEmail: state.userInfo.email,
        userExplanation:
          state.explanation || "No explanation provided by the user.",
        error: state.errorInfo.error?.message,
        errorDescription: state.errorInfo.errorInfo?.componentStack,
        pathLocation,
        ip: state.envInfo.ip,
        device: state.envInfo.deviceType,
        browser: state.envInfo.browserName,
        browserVersion: state.envInfo.fullBrowserVersion,
        osName: state.envInfo.osName,
        osVersion: state.envInfo.osVersion,
        location: state.userInfo.location,
      }),
      attachments: [],
      category: "error",
      authData: false,
      companyName: "Core Scaffold Systems Inc",
    })
      .then(() => {
        hideLoading();
        API.post("editLogs", "/editLogs", {
          body: {
            recordId: pathLocation,
            recordName: pathLocation,
            actionType: "Error Report",
            category: "Error",
            topic: "Error",
            label: "Error",
            currentData: {},
            previousData: {},
            nameOfUser: state.userInfo.full_name,
            cognitoUserId: state.userInfo.cognitoUserId,
            updatedAt: Date.now(),
            updatedKeys: [],
          },
        });
        reloadPaths();
      })
      .catch((e) => {
        reloadPaths();
        hideLoading();
        console.log("Error sending email", e);
      });
  } else {
    hideLoading();
    otherFunc();
    reloadPaths();
  }
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      infoModal: false,
      errorInfo: {},
      userInfo: {
        cognitoUserId: "",
        email: "Email info missing",
        full_name: "Couldn't get full name",
        location: "Location not allowed",
      },
      explanation: null,
      envInfo: {
        deviceType: "",
        browserName: "",
        fullBrowserVersion: "",
        ip: "",
        osName: "",
        osVersion: "",
      },
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
      infoModal: false,
    };
  }

  async componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("ERROR BOUNDARY", error, errorInfo);

    const user = await Auth.currentAuthenticatedUser();
    const { given_name, family_name, email, sub } = user.attributes;
    const ip = (await fetchData(apiRoutes.getIp))?.split(",")[0];
    const geoLocation = await getGeoLocation()
      .then((res) => res.formatted_address)
      .catch((err) => err.message);

    this.setState({
      errorInfo: { error, errorInfo },
      userInfo: {
        full_name: given_name + " " + family_name,
        email: email || "Couldn't get email",
        cognitoUserId: sub,
        location: geoLocation,
      },
      envInfo: {
        ip,
        fullBrowserVersion,
        browserName,
        deviceType,
        osName,
        osVersion,
      },
    });
  }

  infoModalHandler = (boolean: boolean) => {
    this.setState({ infoModal: boolean });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary">
          <ErrorComponent
            {...{
              errorMessage: "Something Went Wrong",
              errorDescription: "Contact the Software Development Department",
              buttonProps: {
                buttonText: "Go Back",
                buttonAction: async () =>
                  await sendErrorEmail(this.state, reloadPaths),
                suffixIcon: <LeftArrow />,
                isDisabled: false,
              },
              feedBackProps: {
                feedBackAction: () =>
                  this.infoModalHandler(!this.state.infoModal),
                feedBackText: <OpenedEyeIcon />,
              },
            }}
          />
          {this.state.infoModal && (
            <ErrorInfoModal
              {...{
                visible: this.state.infoModal,
                setVisible: this.infoModalHandler,
                errorInfoProps: {
                  error: this.state.errorInfo.error,
                  errorInfo: this.state.errorInfo.errorInfo,
                },
                explanation: this.state.explanation,
                setExplanation: (explanation: string) =>
                  this.setState({ explanation }),
                buttonAction: async () => {
                  await sendErrorEmail(this.state);
                  this.infoModalHandler(false);
                },
              }}
            />
          )}
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
