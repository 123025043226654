import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useMemo } from "react";

import {
  columnDefs,
  gridFilters,
  chartingOptions,
  excelColumnsConfig,
} from "./AgGridData";
import { useQuery } from "../../../../../hooks";
import PayrollContext from "../../PayrollContext";
import PayrollLayout from "../../Layout/PayrollLayout";

function JobsiteTab({ accessRight }) {
  const queryParam = useQuery();
  const { jobsites: rowData } = useContext(PayrollContext);
  const selectedJobsite = queryParam.get("selectedJobsite");

  const [rowToEdit, setRowToEdit] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

  function onEditClick(data) {
    setRowToEdit(data);
    setModalVisible(true);
  }

  const memoColumns = useMemo(() => {
    return columnDefs({
      onEditClick,
      writeAccess: accessRight?.write,
    });
  }, [accessRight]);

  useEffect(() => {
    if (selectedJobsite && rowData?.length) {
      onEditClick(
        rowData.find(({ jobsiteId }) => jobsiteId === selectedJobsite)
      );
    }
  }, [selectedJobsite, rowData?.length]);

  return (
    <PayrollLayout
      {...{
        rowData,
        rowToEdit,
        gridFilters,
        modalVisible,
        setRowToEdit,
        chartingOptions,
        setModalVisible,
        title: "Jobsites",
        excelColumnsConfig,
        paginationPageSize,
        dataIdKey: "jobsiteId",
        columnDefs: memoColumns,
        hasNew: accessRight?.write,
        suppressDoubleClickEdit: true,
      }}
    />
  );
}

JobsiteTab.propTypes = {
  accessRight: {
    write: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.array,
  },
};

export default JobsiteTab;
