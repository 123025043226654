import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LabeledShortcutIcon from "../LabeledShortcutIcon/LabeledShortcutIcon";
import { getAccessRights } from "src/utils";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "src/hooks";
import { cloneDeep } from "lodash";
import { useProgramFields } from "../../../../hooks";

const paths = {
  //Sales
  "/leads": "Lead",
  "/opportunities": "Opportunity",
  "/estimations": "Estimation",

  "/contacts": "Contact",

  //Projects
  "/projects": "Project",
  "/scheduling": "Scheduling",
  "/permitdrawings": "Permit Drawing",

  "/accounts": "Client",
  "/subcontractors": "Subcontractor",
  "/inspections": "Inspection",
  "/approvals": "Estimation Request",
  "/tasksManager": "Task",
  "/notes": "Notes",
  "/todos": "Todo",

  //for fleets
  Fleets: "Fleets",
  Violations: "Fleet Violation",
  Dispatch: "Fleet Dispatch",
  Drivers: "Fleet Driver",
  Activities: "Fleet Activity",
  Maintenances: "Fleet Maintenance",
  Incidents: "Fleet Incident",
  Documentation: "Fleet Documentation",
  "Work Order": "Work Order",
  "Schedule Maintenance": "Schedule Maintenance",

  //for accounting tab
  "Schedule Of Values": "Schedule Of Value",
  Rentals: "Rental",
  Requisition: "Application",
  Charges: "Charge",
  Invoice: "Invoice",
  "Receive Payments": "Payment",
  // these accounting in projects has diff name
  Payments: "Payment", //accountingTab
  Applications: "Application", //accountingTab
  Credit: "Charge", //accountingTab
};

export const ShortCuts = ({
  setVisible,
  searchInput = "",
  shortcutButtons,
  setShortcutButtons,
  category,
  modifiedShortcuts,
  setActionHappend,
  showFavourites = false,
  favourites,
  onFavouriteClick,
  categoryRef,
}) => {
  let query = useQuery();
  const queryTab = query.get("tab");
  const querySubTab = query.get("subTab");

  const { state: locationState } = useLocation();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const findedElements = shortcutButtons?.sortedSubCategories?.[category]
    .map((el) =>
      modifiedShortcuts?.[category]?.find(({ title }) => title === el)
    )
    .filter(Boolean);

  const { "Approvals Role Access": approvalsRoleAccess } = useProgramFields();

  function getAccessValue(pathArray, accessArray) {
    let currentLevel = accessArray;

    for (let i = 0; i < pathArray.length; i++) {
      const title = pathArray[i];
      if (Array.isArray(title)) {
        return title.some((t) => getAccessValue(t, accessArray));
      } else {
        const item = currentLevel.find((obj) => obj.title === title);

        if (!item || !item.read) {
          return false;
        }

        if (i === pathArray.length - 1) {
          return item.read;
        }
        if (!item.children) {
          return false;
        }
        currentLevel = item.children;
      }
    }

    return false;
  }

  //check access rights for each topic
  const checkAccessForCategory = (arr) => {
    return arr?.filter(({ accessRightsTitle, ...props }) => {
      // accessRightsTitle  // returns string or array of strings
      // props returns object values

      if (category === "Approvals") {
        const { routeConfig } = getAccessRights({
          userConfiguration,
          title: "Approvals",
        });

        if (routeConfig?.write) {
          return (
            approvalsRoleAccess?.[userConfiguration?.departmentName]?.[
              userConfiguration?.groupName
            ]?.includes(props?.title?.split("Request")?.[0]?.trim()) || false
          );
        }

        return false;
      }

      // routeConfig  in line :85  //object with key:value
      // !!routeConfig?.write usage  //returns boolean
      // titles const in line :81 make a check and modify of data wich is used in accesAllowed const
      const titles = Array.isArray(accessRightsTitle)
        ? accessRightsTitle
        : [accessRightsTitle];

      const hasAccess = getAccessValue(
        titles,
        userConfiguration.routeConfig,
        props?.title === "Geofence"
      );
      if (!hasAccess) {
        return false;
      } else {
        if (
          window.location.pathname === "/accounting" ||
          window.location.pathname === "/fleets/overview" ||
          locationState?.tabPosition === "Accounting" ||
          queryTab === "Accounting"
        ) {
          return (
            paths[
              locationState?.tabPosition !== "Accounting" ||
              queryTab !== "Accounting"
                ? querySubTab
                : queryTab
            ] !== props.title
          );
        }
        return paths[window.location.pathname] !== props.title;
      }
    });
  };
  // const clone = cloneDeep(findedElements ?? []); // deep copy
  const favouriteShortcuts = findedElements?.filter((el) =>
    (favourites || [])?.includes(el?.title)
  );

  // clone?.sort((a, b) => {
  //   return favourites?.indexOf(b.title) - favourites?.indexOf(a.title);
  // });

  const filteredShortcuts = checkAccessForCategory(
    showFavourites ? favouriteShortcuts : findedElements
  )?.filter((shortcut) => {
    return searchInput?.length
      ? shortcut.title.toLowerCase().includes(searchInput.toLowerCase())
      : true;
  });

  if (filteredShortcuts.length === 0) {
    setShortcutButtons((prev) => ({
      ...prev,
      sortedCategories: prev.sortedCategories.filter((el) => el !== category),
    }));
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      setActionHappend(false);
      return;
    }
    setActionHappend(true);
    const items = Array.from(filteredShortcuts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setShortcutButtons((prev) => ({
      ...prev,
      sortedSubCategories: {
        ...prev.sortedSubCategories,
        [category]: items.map(({ title }) => title),
      },
    }));
  };

  const checkRef = (shortcut) => {
    const foundedRef = categoryRef?.find(
      (item) => item.label === shortcut.title
    );
    return foundedRef?.value || null;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-child">
        {(provided) => (
          <div
            className="categoryItems"
            {...provided?.droppableProps}
            ref={provided?.innerRef}
          >
            {filteredShortcuts?.map((shortcut, key) => {
              const favouriteShortcut = favourites?.find(
                (favourite) => favourite === shortcut?.title
              );

              return (
                <Draggable
                  draggableId={`droppable-${key}`}
                  index={key}
                  key={key}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <div ref={checkRef(shortcut)}>
                        <LabeledShortcutIcon
                          key={key}
                          {...{
                            ...shortcut,
                            favouriteShortcut,
                            onFavouriteClick,
                            setPopoverVisible: setVisible,
                            style: { minWidth: 200 },
                            propTestId: shortcut?.["data-testid"],
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
