import { Checkbox } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useStateContext } from "../../../../../../Context/Context";
import { Avatar, List, message } from "antd";

import "./PickRequisition.scss";
import { formatCurrency } from "../../../../../../../../../../utils";
function PickRequisition({ creditedRequisition, setCreditedRequisition }) {
  const { fakeApplication, darkMode } = useStateContext();
  const { applications } = useSelector((state) => state.applications);

  const handlePickRequisition = (e, appId) => {
    setCreditedRequisition(appId);
  };

  return (
    <div className="pick-req-wrapper">
      {" "}
      <List>
        {applications?.map((app) => {
          if (app?.applicationNo < fakeApplication?.applicationNo)
            return (
              <List.Item key={app?.applicationNo}>
                <div className="req-checkable-el">
                  {" "}
                  <Checkbox
                    // disabled={foremanSelected ? false : isUsedInAnotherDay}
                    className="credit-modal-checkbox"
                    checked={creditedRequisition === app?.applicationId}
                    onChange={(e) => {
                      handlePickRequisition(e, app?.applicationId);
                    }}
                  >
                    <h5 style={{ color: "white" }}>{`Requisition ${
                      app?.applicationNo
                    } (${app.applicationStatus}) | Amount: ${formatCurrency(
                      getTotalAmount(app) || 0
                    )}`}</h5>
                  </Checkbox>
                </div>
              </List.Item>
            );
        })}{" "}
      </List>
    </div>
  );
}

export default PickRequisition;

const getTotalAmount = (app) => {
  var result = 0;
  if (Array.isArray(app?.includedRentals) && app?.includedRentals?.length > 0) {
    app?.includedRentals?.forEach((rental) => {
      rental?.services?.forEach((service) => {
        let retainage = service?.retainage || 0;
        let amount = service?.appliedAmount || 0;
        let taxRate = service?.taxRate || 0;
        let taxAmount = (amount * taxRate) / 100;
        let serviceRentalCredit = service?.serviceRentalCredit || 0;
        let totalAmount = amount + taxAmount + retainage + serviceRentalCredit;
        result += totalAmount;
      });
    });
    return result;
  } else return result;
};
