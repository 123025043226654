import "./CrewsModalSchedule.scss";
import React, { useEffect, useState, useCallback } from "react";
import {
  Tooltip,
  Card,
  Modal,
  Checkbox,
  Alert,
  Input,
  message,
  TimePicker,
  Skeleton,
} from "antd";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { MondayButton } from "../../../../../../../commonComponents";
import { TickIcon } from "../../../../../../../InternalChat/assets/icons";
import { API } from "aws-amplify";
import {
  AutoCompleteIcon,
  UndoIcon,
} from "../../../../../../../pages/ScheduleProgress/assets/images";
import { filterTables } from "../../../../../../../../utils";

const CrewsModal = ({
  visibleCrews = false,
  setVisibleCrews = () => {},
  scheduleDay = {},
  crewsForThisDay = [],
  updateScheduleCrews = () => {},
  dayIndex,
  scheduleDays,
  comesFromUnassigned = false,
}) => {
  const [listOfTeams, setListOfTeams] = useState([]);
  const [checked, setChecked] = useState({});
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [desiredCrewNr, setDesiredNr] = useState(
    Number(scheduleDay?.desiredCrewNr) || 0
  );
  const [loading, setLoading] = useState(false);
  const [applyToAll, setApplyTAll] = useState(false);

  const filterTeams = useCallback(
    (value) => {
      const query = value?.toLowerCase() || "";
      if (!query) {
        setFilteredTeams(listOfTeams);
        return;
      }

      const dataFiltered = listOfTeams.filter((el) => {
        const crewTeamName = el?.crewTeamName?.toLowerCase() || "";
        const crewForemanName = el?.crewForeman?.crewName?.toLowerCase() || "";
        const hasMatchingMember = el?.crewMembers?.some((member) =>
          (member?.crewName?.toLowerCase() || "").includes(query)
        );

        return (
          crewTeamName.includes(query) ||
          crewForemanName.includes(query) ||
          hasMatchingMember
        );
      });

      setFilteredTeams(dataFiltered);
    },
    [listOfTeams]
  );

  const timepickerValue = (value) => {
    return !value ? null : dayjsNY(value);
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const onChangeCheckbox = (type = "", value = false, data = undefined) => {
    if (comesFromUnassigned) {
      message.error(
        "This view is for read-only purposes, and no actions can be performed."
      );

      return;
    }

    let dataChecked = { ...checked } || {};

    if (type === "crewTeam") {
      if (value) {
        if (Object.keys(data?.crewForeman)?.length > 0) {
          dataChecked[data?.crewForeman?.crewId + " " + data?.crewTeamName] = {
            crewName: data?.crewForeman?.crewName,
            crewId: data?.crewForeman?.crewId,
            crewTeamName: data?.crewTeamName,
            foreman: true,
          };
        }
        if (data?.crewMembers?.length > 0) {
          data?.crewMembers?.forEach((member) => {
            dataChecked[member?.crewId + " " + data?.crewTeamName] = {
              crewName: member?.crewName,
              crewId: member?.crewId,
              crewTeamName: data?.crewTeamName,
            };
          });
        }
      } else {
        delete dataChecked[
          data?.crewForeman?.crewId + " " + data?.crewTeamName
        ];
        data?.crewMembers?.forEach((member) => {
          delete dataChecked[member?.crewId + " " + data?.crewTeamName];
        });
      }
    } else if (type === "crewForeman") {
      if (!!value) {
        dataChecked[data?.crewId + " " + data?.crewTeamName] = {
          crewName: data?.crewName,
          crewId: data?.crewId,
          crewTeamName: data?.crewTeamName,
          foreman: true,
        };
      } else {
        delete dataChecked[data?.crewId + " " + data?.crewTeamName];
      }
    } else {
      if (!!value) {
        dataChecked[data?.crewId + " " + data?.crewTeamName] = {
          crewName: data?.crewName,
          crewId: data?.crewId,
          crewTeamName: data?.crewTeamName,
        };
      } else {
        delete dataChecked[data?.crewId + " " + data?.crewTeamName];
      }
    }

    setChecked(dataChecked);
  };

  const onChangeInput = useCallback(
    debounce((e) => {
      const value = e.target.value;
      filterTeams(value);
    }, 300),
    [filterTeams]
  );

  const updateDesiredCrewNr = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      message.error("Please enter a valid number!");
      return;
    } else {
      setDesiredNr(Number(value));
    }
  };

  const onSaveTime = (type, time, crewId) => {
    let selectedCrews = { ...checked } || {};
    selectedCrews[crewId][type] = dayjsNY(time).valueOf();

    setChecked(selectedCrews);
  };

  useEffect(() => {
    let obj = {};
    Array.isArray(crewsForThisDay) &&
      crewsForThisDay?.length > 0 &&
      crewsForThisDay.forEach((crew) => {
        const { startTime, endTime } = crew?.days?.find(
          (day) =>
            dayjsNY(day?.startDate).format("MM/DD/YYYY") ===
            dayjsNY(scheduleDay?.startDate).format("MM/DD/YYYY")
        );
        obj[`${crew.crewId} ${crew?.crewTeamName}`] = {
          crewName: crew?.crewName,
          crewId: crew?.crewId,
          crewTeamName: crew?.crewTeamName,
          startTime: startTime || null,
          endTime: endTime || null,
        };
      });

    const fetchTeams = async () => {
      try {
        message.loading("Please wait...", 0);
        setLoading(true);

        const res = await filterTables("crewTeams", "crewTeamStatus", "Active");
        const data = Array.isArray(res) ? res : [];

        if (Object.keys(obj).length > 0 && data?.length > 0) {
          const crewsForThisDayNames =
            crewsForThisDay?.map((crew) => crew?.crewTeamName) || [];

          const { selectedCrews, unselectedCrews } = data?.reduce(
            (prev, crewTeam) => {
              if (crewsForThisDayNames?.includes(crewTeam?.crewTeamName)) {
                prev.selectedCrews.push(crewTeam);
              } else {
                prev.unselectedCrews.push(crewTeam);
              }
              return prev;
            },
            { selectedCrews: [], unselectedCrews: [] }
          );

          setFilteredTeams([...selectedCrews, ...unselectedCrews]);
          setListOfTeams([...selectedCrews, ...unselectedCrews]);
        } else {
          setFilteredTeams(data);
          setListOfTeams(data);
        }

        setLoading(false);
        message.destroy();
      } catch (error) {
        message.error(`Crew Teams could not be loaded.`);
        console.error("Error fetching crew teams:", error);
      }
    };
    fetchTeams();

    setChecked(obj);
  }, []);

  return (
    <>
      <Modal
        open={visibleCrews}
        title={
          comesFromUnassigned
            ? "Unassigned Crews for" +
              " " +
              dayjsNY(scheduleDay?.startDate).format("MM/DD/YYYY")
            : "Crews" +
              " - " +
              dayjsNY(scheduleDay?.startDate).format("MM/DD/YYYY h:mm a") +
              " - " +
              dayjsNY(scheduleDay?.endDate).format("h:mm a")
        }
        className="crewsModalSchedule"
        width={1900}
        centered={true}
        closeIcon={<XIcon />}
        onCancel={() => setVisibleCrews(false)}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <MondayButton
            onClick={() => setVisibleCrews(false)}
            {...{
              className: "mondayButtonRed",
              Icon: <XIcon />,
            }}
          >
            Close Modal
          </MondayButton>,

          <MondayButton
            disabled={comesFromUnassigned}
            onClick={() => {
              updateScheduleCrews(scheduleDay, Object.values(checked), {
                applyToAll,
                desiredNr: desiredCrewNr,
                dayIndex,
                days: scheduleDays,
              });
              setVisibleCrews(false);
              message.success("Saved!");
            }}
            {...{
              Icon: <TickIcon />,
            }}
          >
            Save Changes
          </MondayButton>,
        ]}
      >
        {loading ? (
          <>
            <Skeleton active={loading} />
            <Skeleton active={loading} />
            <Skeleton active={loading} />
            <Skeleton active={loading} />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              height: 700,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <Input
                  placeholder="Search crew team, foreman, member..."
                  variant="filled"
                  onChange={onChangeInput}
                  style={{ width: 300 }}
                />
                {!comesFromUnassigned && (
                  <MondayButton
                    disabled={
                      comesFromUnassigned || !Object.keys(checked).length
                    }
                    onClick={() => setChecked({})}
                    {...{
                      className: "mondayButtonRed",
                      hasIcon: false,
                    }}
                  >
                    Unselect All
                  </MondayButton>
                )}
              </div>
              {!comesFromUnassigned && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <span>Enter desired crew number:</span>
                  <Input
                    placeholder="Desired crew number..."
                    variant="filled"
                    value={desiredCrewNr}
                    onChange={updateDesiredCrewNr}
                    style={{ width: 200 }}
                    disabled={comesFromUnassigned}
                  />
                  <Tooltip
                    title={
                      applyToAll
                        ? "The crew number you entered will be applied to all the subsequent days."
                        : "Click to apply the crew number you entered to all the subsequent days."
                    }
                  >
                    <AutoCompleteIcon
                      onClick={() => setApplyTAll(!applyToAll)}
                      cursor={"pointer"}
                      fill={applyToAll ? "#71cf48" : "#ACACAC"}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
            <Alert
              type={
                comesFromUnassigned
                  ? "warning"
                  : Object.keys(checked || {}).length > 0
                  ? "info"
                  : "warning"
              }
              message={
                comesFromUnassigned ? (
                  <>
                    <span>
                      This view is for read-only purposes, and no actions can be
                      performed.
                    </span>{" "}
                    <b>
                      ({Object.keys(checked || {}).length} selected in total)
                    </b>
                  </>
                ) : Object.keys(checked || {}).length > 0 ? (
                  <>
                    <b>
                      {Object.values(checked)
                        .map((crew) => crew?.crewName)
                        .join(",")}
                    </b>{" "}
                    {Object.keys(checked).length > 1 ? "are" : "is"} selected
                    for {dayjsNY(scheduleDay?.startDate).format("MM/DD/YYYY")}.
                  </>
                ) : (
                  `There are no crews selected for ${dayjsNY(
                    scheduleDay?.startDate
                  ).format("MM/DD/YYYY")}.`
                )
              }
              showIcon
            />
            <div className="crewsModalContent">
              {filteredTeams?.map((team) => {
                const allIds = [
                  Object.keys(team?.crewForeman)?.length > 0 &&
                    team?.crewForeman?.crewId,
                  ...team?.crewMembers?.map((member) => member?.crewId),
                ].filter(Boolean);

                return (
                  <div className="crewTeamCard">
                    <Card
                      title={
                        <>
                          <Checkbox
                            onChange={(e) =>
                              onChangeCheckbox(
                                "crewTeam",
                                e.target.checked,
                                team
                              )
                            }
                            checked={allIds?.every(
                              (id) => !!checked[id + " " + team?.crewTeamName]
                            )}
                          >
                            {team?.crewTeamName} ({team.company})
                          </Checkbox>
                        </>
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {!Object.keys(team?.crewForeman).length ? (
                          <span
                            style={{
                              opacity: 0.5,
                            }}
                          >
                            No FOREMAN for this team.
                          </span>
                        ) : (
                          <Checkbox
                            // disabled={
                            // comesFromUnassigned &&
                            // !!checked[
                            //   team?.crewForeman?.crewId +
                            //     " " +
                            //     team?.crewTeamName
                            // ]
                            // }
                            onChange={(e) =>
                              onChangeCheckbox(
                                "crewForeman",
                                e.target.checked,
                                {
                                  ...team?.crewForeman,
                                  crewTeamName: team?.crewTeamName,
                                }
                              )
                            }
                            checked={
                              !!checked[
                                team?.crewForeman?.crewId +
                                  " " +
                                  team?.crewTeamName
                              ]
                            }
                          >
                            <b>{team?.crewForeman?.crewName}</b>
                          </Checkbox>
                        )}
                        {!!Object.keys(team?.crewForeman).length && (
                          <div>
                            {comesFromUnassigned ? (
                              <Alert
                                message={
                                  !!checked[
                                    team?.crewForeman?.crewId +
                                      " " +
                                      team?.crewTeamName
                                  ]
                                    ? "Selected"
                                    : "Not Selected"
                                }
                                type={
                                  !!checked[
                                    team?.crewForeman?.crewId +
                                      " " +
                                      team?.crewTeamName
                                  ]
                                    ? "success"
                                    : "error"
                                }
                                showIcon
                                banner
                              />
                            ) : (
                              <>
                                <TimePicker
                                  placeholder="Start Time"
                                  format={"h:mm a"}
                                  style={{ height: 20 }}
                                  onChange={(e) =>
                                    onSaveTime(
                                      "startTime",
                                      e,
                                      team?.crewForeman?.crewId +
                                        " " +
                                        team?.crewTeamName
                                    )
                                  }
                                  disabled={
                                    // comesFromUnassigned ||
                                    !checked[
                                      team?.crewForeman?.crewId +
                                        " " +
                                        team?.crewTeamName
                                    ]
                                  }
                                  value={timepickerValue(
                                    checked[
                                      team?.crewForeman?.crewId +
                                        " " +
                                        team?.crewTeamName
                                    ]?.startTime
                                  )}
                                />
                                <TimePicker
                                  placeholder="End Time"
                                  format={"h:mm a"}
                                  style={{ height: 20 }}
                                  onChange={(e) =>
                                    onSaveTime(
                                      "endTime",
                                      e,
                                      team?.crewForeman?.crewId +
                                        " " +
                                        team?.crewTeamName
                                    )
                                  }
                                  disabled={
                                    // comesFromUnassigned ||
                                    !checked[
                                      team?.crewForeman?.crewId +
                                        " " +
                                        team?.crewTeamName
                                    ]
                                  }
                                  value={timepickerValue(
                                    checked[
                                      team?.crewForeman?.crewId +
                                        " " +
                                        team?.crewTeamName
                                    ]?.endTime
                                  )}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      <div>
                        {team?.crewMembers?.map((member) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {" "}
                              <Checkbox
                                // disabled={comesFromUnassigned}
                                onChange={(e) =>
                                  onChangeCheckbox("", e.target.checked, {
                                    ...member,
                                    crewTeamName: team?.crewTeamName,
                                  })
                                }
                                checked={
                                  !!checked[
                                    member?.crewId + " " + team?.crewTeamName
                                  ]
                                }
                              >
                                {member?.crewName}
                              </Checkbox>
                              <div>
                                {comesFromUnassigned ? (
                                  <Alert
                                    message={
                                      !!checked[
                                        member?.crewId +
                                          " " +
                                          team?.crewTeamName
                                      ]
                                        ? "Selected"
                                        : "Not Selected"
                                    }
                                    type={
                                      !!checked[
                                        member?.crewId +
                                          " " +
                                          team?.crewTeamName
                                      ]
                                        ? "success"
                                        : "error"
                                    }
                                    showIcon
                                    banner
                                  />
                                ) : (
                                  <>
                                    {" "}
                                    <TimePicker
                                      placeholder="Start Time"
                                      format={"h:mm a"}
                                      style={{ height: 20 }}
                                      disabled={
                                        // comesFromUnassigned ||
                                        !checked[
                                          member?.crewId +
                                            " " +
                                            team?.crewTeamName
                                        ]
                                      }
                                      onChange={(e) =>
                                        onSaveTime(
                                          "startTime",
                                          e,
                                          member?.crewId +
                                            " " +
                                            team?.crewTeamName
                                        )
                                      }
                                      value={timepickerValue(
                                        checked[
                                          member?.crewId +
                                            " " +
                                            team?.crewTeamName
                                        ]?.startTime
                                      )}
                                    />{" "}
                                    <TimePicker
                                      placeholder="End Time"
                                      disabled={
                                        // comesFromUnassigned ||
                                        !checked[
                                          member?.crewId +
                                            " " +
                                            team?.crewTeamName
                                        ]
                                      }
                                      format={"h:mm a"}
                                      onChange={(e) =>
                                        onSaveTime(
                                          "endTime",
                                          e,
                                          member?.crewId +
                                            " " +
                                            team?.crewTeamName
                                        )
                                      }
                                      value={timepickerValue(
                                        checked[
                                          member?.crewId +
                                            " " +
                                            team?.crewTeamName
                                        ]?.endTime
                                      )}
                                      style={{ height: 20 }}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CrewsModal;
