import React from "react";
import { MondayButton } from "../../../commonComponents";
import "./ProposalSidebar.scss";
// import { DeleteIcon, PlusIcon } from "../../../../assets";
// import { TemplateIcon } from "../../../../icons";
// import {
//   CaretRightFilled,
//   ContainerFilled,
//   RightCircleFilled,
//   RightOutlined,
//   LayoutFilled,
// } from "@ant-design/icons";
// import { UserAccessIcon } from "../../../../icons/Settings";
// import { UserIcon } from "../../../SidebarPages/Communication/assets";
// import GeneralTemplatesSidebar from "./components/GeneralTemplates/GeneralTemplatesSidebar";
// import { proposalSidebarData } from "./ProposalSidebar";
const Sidebar = ({ onSwitchView, proposalSidebarData = [] }) => {
  return (
    <div className="sidebar-content">
      {/* <div className="sidebar-header">Sidebar Header</div> */}
      {proposalSidebarData.map((view, index) => (
        <MondayButton
          className="mondayButtonBlue"
          key={index + view.key}
          hoverText={view.hoverText}
          onClick={() => onSwitchView(view.key)}
          icon={view.icon}
          noTooltip={false}
          tooltipKey={view.key}
        />
      ))}{" "}
      {/* <MondayButton
          className="mondayButtonGreen"
          key={3545477}
          icon={<RightCircleFilled />}
        /> */}
    </div>
  );
};

export default Sidebar;
