import React from "react";

import { MondayButton } from "../../../../../commonComponents";
import Services from "../../../../../SidebarPages/components/AgGridComponents/Services";

function columnDefs({ writeAccess, onEditClick }) {
  return [
    writeAccess && {
      width: 100,
      headerName: "Edit",
      pdfExportOptions: {
        skipColumn: true,
      },
      cellClass: "cell-left-aligned",
      cellRenderer({ data }) {
        return (
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick() {
                onEditClick(data);
              },
              hasIcon: false,
            }}
          >
            Edit
          </MondayButton>
        );
      },
    },
    {
      field: "jobAddress",
      headerName: "Jobsite Address",
      cellClass: "cell-left-aligned",
    },
    {
      field: "jobName",
      headerName: "Job Name",
      cellClass: "cell-left-aligned",
    },
    {
      headerName: "Client",
      field: "accountName",
      cellClass: "cell-left-aligned",
      valueGetter: ({ data }) => {
        return data?.accountName?.accountName;
      },
      getQuickFilterText: ({ value }) => {
        return value?.accountName;
      },
    },
    {
      field: "payrollType",
      headerName: "Payroll Category",
      cellClass: "cell-left-aligned",
    },
    {
      width: 100,
      headerName: "Sow",
      field: "services",
      cellClass: "cell-center-aligned",
      pdfExportOptions: {
        modifyCellValue: (value) => {
          return value;
        },
      },
      valueGetter({ data }) {
        let result = (Array.isArray(data?.services) ? data.services : []).join(
          ", "
        );
        return result;
      },
      cellRendererFramework: ({ data }) => {
        return Services({
          title: "Jobsite Services",
          value: {
            ["Services"]: {
              services: Array.isArray(data?.services) ? data?.services : [],
            },
          },
        });
      },
    },
  ].filter(Boolean);
}

export default columnDefs;
