import { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import {
  adjustColorTone,
  generateRandomColor,
} from "../../../../../../../utils/generateRandomColor";

const StackedBar = ({ chartData }) => {
  const data = chartData?.data || [];

  const chartRef = useRef(null); // Create a reference for the canvas
  const chartInstanceRef = useRef(null); // Store chart instance to handle cleanup

  const getDatasets = () => {
    const finalResult = [];
    const length = data.length;
    const colors = [];

    for (let i = 0; i < length; i++) {
      colors.push(generateRandomColor());
    }

    data.forEach((column, index) => {
      column.keys.forEach((item, keyIndex) => {
        const dataArray = new Array(length).fill(0);
        dataArray[index] = 1;
        finalResult.push({
          label: item,
          backgroundColor: adjustColorTone(colors[index], keyIndex),
          data: dataArray,
        });
      });
    });

    return finalResult;
  };

  useEffect(() => {
    if (chartRef.current) {
      // If a chart instance already exists, destroy it before creating a new one
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");

      const chartData = {
        labels: data.map((item) => item.tableName),
        datasets: getDatasets(),
      };

      const chartOptions = {
        maintainAspectRatio: false,

        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };

      // Create the new chart instance and store it in the ref
      chartInstanceRef.current = new Chart(ctx, {
        type: "bar",
        data: chartData,
        options: chartOptions,
      });
    }

    // Cleanup chart instance on component unmount or when data changes
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default StackedBar;
