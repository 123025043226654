import {
  useState,
  Fragment,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Timeline, Tooltip, message } from "antd";

import {
  FleetsLiveContext,
  matchTruckActivities,
} from "../../../../../../utils";
import LiveAuditContext from "../../../../LiveAuditContext";
import { dayjsNY } from "../../../../../../../../../DateComponents/contants/DayjsNY";
import {
  ClosedEyeIcon,
  FilledEyeIcon,
} from "../../../../../../../../../../assets";
import { TickIcon } from "../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../../../../Communication/assets";
import { WarningTriangle } from "../../../../../../../../DynamicView/src";

import "./UpdatedPlanCard.scss";

const UpdatedPlanCard = forwardRef(({ trips, auditTripsHandler }, ref) => {
  //#region HOOKS
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { plannedTripsForDate, isWritable, geofences } =
    useContext(FleetsLiveContext);
  const { selectedVehicle: vehicle, auditStatus } =
    useContext(LiveAuditContext);

  const [forceHighlighted, setForceHighlighted] = useState([]);
  const [audited, setAudited] = useState([]);

  //#region ELIGIBLE FOR AUDIT
  let eligibleForAudit =
    matchTruckActivities({
      trips,
      geofences,
      planForTruck: _.cloneDeep(plannedTripsForDate?.[vehicle] || []),
    }) || [];

  let items = [];
  for (const eligible of eligibleForAudit) {
    //#region UI ITEMS
    items.push([
      {
        color: "green",
        ids: [eligible?.activityId, ...(eligible?.alertIds || [])],
        children: (
          <div className="single-card-location">
            <div className="location-arrive-time">
              Depart:&nbsp;
              <span className="old-time">
                {dayjsNY(eligible?.["plannedDepart"]).format("MMM DD, HH:mm")}
                &nbsp;
              </span>
              <span className="new-time">
                {dayjsNY(eligible?.["actualDepart"]).format("MMM DD, HH:mm")}
              </span>
            </div>
            <div className="location-arrive">
              {eligible?.["pickUpLocation"]}
            </div>
          </div>
        ),
      },
      {
        color: "red",
        ids: [eligible?.activityId, ...(eligible?.alertIds || [])],
        children: (
          <div className="single-card-location">
            <div className="location-arrive-time">
              Arrive:&nbsp;
              <span className="old-time">
                {dayjsNY(eligible?.["plannedArrive"]).format("MMM DD, HH:mm")}
                &nbsp;
              </span>
              <span className="new-time">
                {dayjsNY(eligible?.["actualArrive"]).format("MMM DD, HH:mm")}
              </span>
            </div>
            <div className="location-arrive">
              {eligible?.["dropOffLocation"]}
            </div>
          </div>
        ),
      },
    ]);
  }

  function getAllMatchedStatus() {
    //#region ARE ALL MATCHED
    return (plannedTripsForDate?.[vehicle] || [])?.every(({ activityId }) =>
      items?.find((e) => e?.[0]?.ids[0] === activityId)
    );
  }

  useEffect(() => {
    //#region VALIDATE ALL MATCHED
    if (getAllMatchedStatus() && items?.length && auditStatus !== "UPLOADED") {
      message.success({
        content: "All the activities have matched the plan!",
        key: "allMatch",
      });
    }
  }, [items?.length, plannedTripsForDate, auditStatus]);

  useEffect(() => {
    //#region UPLOADED MESSAGE
    if (auditStatus === "UPLOADED") {
      message.info({
        content:
          "This audit has been uploaded, no further changes can be made!",
        key: "allMatch",
        duration: 5,
      });
    }
  }, [auditStatus]);

  useEffect(() => {
    //#region HIGHLIGHT HANDLE
    if (forceHighlighted?.length) {
      let tmpForced = [];
      items?.forEach(({ ids }) => {
        if (hasHighlight(ids)) {
          tmpForced.push(ids);
          timelineHover(ids, "enter");
        } else {
          timelineHover(ids, "exit");
        }
      });
      setForceHighlighted(tmpForced);
    }
  }, [plannedTripsForDate]);

  /**
   * Function used for highlight management, FORCES a highlighted
   * state on the initial and actual plan cards
   */
  function timelineHover(ids = [], type) {
    //#region TIMELINE HOVER
    let activityEls = document.getElementsByClassName(ids[0]);
    for (let i = 0; i < activityEls?.length; i++) {
      if (type === "exit") {
        activityEls.item(i).classList.remove("update-enter");
      } else {
        activityEls.item(i).classList.add("update-enter");
      }
    }

    let startActual = document.getElementById(ids[1]);
    let endActual = document.getElementById(ids[2]);
    if (startActual) {
      if (type === "exit") {
        startActual.classList.remove("update-enter");
      } else {
        startActual.classList.add("update-enter");
      }
    }

    if (endActual) {
      if (type === "exit") {
        endActual.classList.remove("update-enter");
      } else {
        endActual.classList.add("update-enter");
      }
    }
  }

  /**
   * Function used for mouse out events. Cases
   * are separated to prevent highlight bugs
   */
  function mouseOutHandler(ids = []) {
    //#region MOUSE OUT HANDLER
    if (!isPlanHighlighted(ids?.[0])) {
      let activityEls = document.getElementsByClassName(ids[0]);
      for (let i = 0; i < activityEls?.length; i++) {
        activityEls.item(i).classList.remove("update-enter");
      }
    }

    if (!areActivitiesHighlighted(ids?.slice(1))) {
      let startActual = document.getElementById(ids[1]);
      let endActual = document.getElementById(ids[2]);
      if (startActual) {
        startActual.classList.remove("update-enter");
      }

      if (endActual) {
        endActual.classList.remove("update-enter");
      }
    }
  }

  function removeHighlights(idArray) {
    //#region REMOVE HIGHLIGHTS
    setForceHighlighted((prev) => {
      let index = prev?.findIndex((e) =>
        e?.every((id) => idArray.includes(id))
      );
      if (index > -1) {
        prev.splice(index, 1);
        return [...prev];
      }
      return prev;
    });
  }

  function isPlanHighlighted(id) {
    //#region IS PLAN HIGHLIGHTED
    return Boolean(forceHighlighted?.find((e) => e?.[0] === id));
  }

  function areActivitiesHighlighted(ids) {
    //#region ARE ACTIVITIES HIGHLIGHTED
    return Boolean(
      forceHighlighted?.find((e) => e.slice(1).every((id) => ids.includes(id)))
    );
  }

  function hasHighlight(idArray = [], defaultHighlights) {
    //#region HAS HIGHLIGHT
    return (
      (defaultHighlights || forceHighlighted)?.findIndex((e) =>
        e?.every((id) => idArray.includes(id))
      ) > -1
    );
  }

  function isAudited(idArray) {
    //#region IS AUDITED
    return (
      audited?.findIndex((e) => e?.every((id) => idArray.includes(id))) > -1
    );
  }

  /**
   * Used to look up the "audited" state. When an eligible
   * activity is selected without saving the changes, the
   * other eligible requests that have the same alerts
   * need to be filtered
   */
  function areEligibleAlertsAudited(ids = []) {
    //#region ARE ELIGIBLE ALERTS AUDITED
    let arrSlice = ids.slice(1);
    let matchingAlertsAudit = audited.find((arr) =>
      arr.slice(1).every((id) => arrSlice.includes(id))
    );
    if (!matchingAlertsAudit) {
      return false;
    }
    return ids[0] !== matchingAlertsAudit[0];
  }

  function removeAudit(idArray) {
    //#region REMOVE AUDITED
    setAudited((prev) => {
      let index = prev?.findIndex((e) =>
        e?.every((id) => idArray.includes(id))
      );
      if (index > -1) {
        prev.splice(index, 1);
        return [...prev];
      }
      return prev;
    });
  }

  function timelineClickHandler(timeline, hasHighLight) {
    //#region TIMELINE CLICK HANDLER
    let actual = document.getElementById(timeline[0]?.ids[1]);
    let planCollection = document.getElementsByClassName(timeline[0]?.ids[0]);

    if (actual) {
      actual.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }

    if (planCollection?.length) {
      planCollection.item(0).scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }

    if (!hasHighLight) {
      setForceHighlighted([...forceHighlighted, timeline[0]?.ids]);
    }
  }

  function isDefaultAudited(ids = []) {
    //#region IS DEFAULT AUDITED
    return Boolean(
      ids
        .slice(1)
        ?.every((id) =>
          trips?.find(
            ({ alertUUID, defaultAudited, activityId }) =>
              alertUUID === id && defaultAudited && activityId === ids[0]
          )
        )
    );
  }

  /**
   * Function that checks if the activity has been audited
   * in order to prevent matching the same plan with multiple alerts
   */
  function isEligibleActivityAudited(activityId) {
    //#region IS ELIGIBLE AUDITED
    const defaultAudited = trips?.find(
      ({ activityId: id }) => id === activityId
    );
    if (defaultAudited) {
      return defaultAudited;
    }

    return audited.find((e) => e?.[0] === activityId);
  }

  //#region REF HANDLES
  useImperativeHandle(
    ref,
    () => {
      return {
        clearHighlights() {
          setForceHighlighted([]);
          for (const item of items) {
            timelineHover(item[0]?.["ids"] || [], "exit");
          }
        },
        clearAudited() {
          setAudited([]);
        },
        updateHighlights({ exclude }) {
          setTimeout(() => {
            for (let i = 0; i < items?.length; i++) {
              let ids = items[i]?.[0]?.["ids"] || [];
              if (hasHighlight(ids)) {
                if (exclude && ids.includes(exclude)) {
                  timelineHover(ids, "exit");
                  removeHighlights(ids);
                } else {
                  timelineHover(ids, "enter");
                }
              }
            }
          }, 0);
        },
        getActivitiesAudited() {
          return audited?.reduce(
            (acc, val) => [...acc, { id: val?.[0], alerts: val?.slice(1) }],
            []
          );
        },
      };
    },
    [items, forceHighlighted, audited]
  );

  //#region JSX
  return (
    <div
      className={`audit-locations-card audit-updated-plan-card ${
        isDarkMode ? "updated-plan-dark" : ""
      }`}
      data-testid="audit-updated-plan"
    >
      <div className="locations-card-title">
        {/**
         //#region TITLE
         */}
        PREVIEW OF UPDATED PLAN
        {getAllMatchedStatus() && Boolean(items?.length) && (
          <span style={{ color: "#69C043", fontSize: "14px" }}>
            &nbsp;(ALL MATCH)
          </span>
        )}
      </div>
      <div className="locations-card-info-container">
        {!items?.length ? (
          <div className="no-match-warning">
            {/**
             //#region NO MATCH
             */}
            <div className="no-match-icon">
              <WarningTriangle height={50} width={50} />
            </div>
            <span className="no-match-text">
              The initial plan doesn't match the routes taken by the vehicle.
              Change the original plan or the actual activities to proceed with
              the audit
            </span>
          </div>
        ) : (
          <Fragment>
            {items?.flatMap((e, index) => {
              //#region ITEMS MAP
              let a = isAudited(e[0]?.ids);
              let d = isDefaultAudited(e[0]?.ids);

              if (!d && !a) {
                if (areEligibleAlertsAudited(e[0]?.ids)) {
                  return [];
                }
                if (isEligibleActivityAudited(e[0]?.ids[0])) {
                  return [];
                }
              }

              let h = hasHighlight(e[0]?.ids);
              return (
                <div
                  className={`audit-update-timeline ${
                    d ? "default-audited" : ""
                  }`}
                  key={`${index}`}
                  onMouseEnter={() => {
                    timelineHover(e[0]?.ids, "enter");
                  }}
                  onMouseLeave={() => {
                    mouseOutHandler(e[0]?.ids);
                  }}
                >
                  <Tooltip title="Scroll to actual activity">
                    <Timeline
                      {...{
                        mode: "left",
                        items: e,
                        style: {
                          cursor: "pointer",
                        },
                        onClick() {
                          timelineClickHandler(e, h);
                        },
                      }}
                    />
                  </Tooltip>
                  <div className="update-action-buttons">
                    <Tooltip
                      title={h ? "Remove Highlight" : "Highlight Activities"}
                    >
                      {/**
                       //#region REMOVE HIGHLIGHT
                       */}
                      <div
                        className={`update-action-button ${h ? "red" : "blue"}`}
                        onClick={() => {
                          if (h) {
                            removeHighlights(e[0]?.ids);
                          } else {
                            setForceHighlighted([
                              ...forceHighlighted,
                              e[0]?.ids,
                            ]);
                          }
                        }}
                      >
                        {h ? (
                          <ClosedEyeIcon height={14} width={14} />
                        ) : (
                          <FilledEyeIcon height={14} width={14} />
                        )}
                      </div>
                    </Tooltip>
                    {!d && (
                      <Tooltip title={a ? "Remove Audit" : "Audit activity"}>
                        {/**
                         //#region AUDIT BUTTON
                         */}
                        <div
                          className={`update-action-button ${
                            a ? "red" : "green"
                          }`}
                          style={
                            !isWritable || auditStatus === "UPLOADED"
                              ? { display: "none" }
                              : {}
                          }
                          onClick={() => {
                            if (a) {
                              auditTripsHandler(e[0]?.ids);
                              removeAudit(e[0]?.ids);
                            } else {
                              auditTripsHandler(e[0]?.ids);
                              setAudited([...audited, e[0]?.ids]);
                              if (!hasHighlight(e[0]?.ids)) {
                                setForceHighlighted([
                                  ...forceHighlighted,
                                  e[0]?.ids,
                                ]);
                                timelineHover(e[0]?.ids, "enter");
                              }
                            }
                          }}
                        >
                          {a ? (
                            <XIcon height={14} width={14} fill="#fff" />
                          ) : (
                            <TickIcon height={14} width={14} />
                          )}
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    </div>
  );
});

export default UpdatedPlanCard;
