import { Card } from "antd";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";

function TakeOffStep({ stepperData, createProposalTakeOff }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gap: "10px",
        width: "100%",
        padding: "10px",
      }}
    >
      {Object.keys(stepperData?.versionServices ?? {})
        ?.sort((a, b) => {
          return a.createdAt - b.createdAt;
        })
        ?.map((el, i) => {
          let takeOff = stepperData?.versionServices;
          const newEl = {
            title: `${takeOff[el]?.versionName || ""} - ${
              takeOff[el]?.status || ""
            }`,
            status: takeOff[el]?.status,
            createdAt: takeOff[el]?.createdAt,
            createdBy: takeOff[el]?.createdBy?.name,
            key: el,
            takeOffItem: takeOff[el],
            estimation: stepperData?.estimationTakeOff,
          };

          return (
            <Card
              key={i}
              title={`${takeOff[el]?.versionName || ""} - ${
                takeOff[el]?.status || ""
              }`}
              style={{
                width: 300,
                cursor: "pointer",
                background:
                  newEl?.status === "Approved" ? "#00A464" : "#E9C466",
                color: "white",
              }}
              onClick={() => createProposalTakeOff(newEl)}
            >
              <p>Created By: {takeOff[el]?.createdBy?.name || "N/A"}</p>
              <p>
                Created on:{" "}
                {dayjsNY(takeOff[el]?.createdAt).format("MM/DD/YYYY HH:mm")}
              </p>
            </Card>
          );
        })}
    </div>
  );
}

export default TakeOffStep;
