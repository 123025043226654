import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import MondayButton from "../MondayButton/MondayButton";
import "./RichTextEditor.scss";
const RichTextEditor = ({
  value,
  onChange,
  disabled,
  initialValue,
  placeholder,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div className="richTextComp">
      <ReactQuill
        {...{
          value: value || initialValue,
          disabled,
          onChange: onChange,
          placeholder: placeholder || "",
        }}
      />
    </div>
  );
};

export default RichTextEditor;
