import moment from 'moment'

export const addZero = min => min < 10 ? '0' + min : min

export const getTodayModifiedDate = () => {
  const date = new Date()

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hour = date.getHours()
  const min = addZero(date.getMinutes())
  const fullDate = `${month}/${day}/${year}  ${hour}:${min}`

  return fullDate
}

export const getToday = () => {
  const today = new Date()

  const dd = today.getDate()
  const mm = today.getMonth() + 1 //January is 0!
  const yyyy = today.getFullYear()
  const hour = today.getHours()
  const min = today.getMinutes()
  const date = mm + '/' + dd + '/' + yyyy + '  ' + hour + ':' + min
  
  return date
}

export const calculateDurationForInterval = (first, last) => {
  const startDate = moment(first)
  const timeEnd = moment(last)
  const diff2 = timeEnd.diff(startDate)
  
  return diff2
}

export const formatDate = string => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
  return new Date(string).toLocaleDateString([], options)
}