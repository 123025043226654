export const APPROVALS_REQUESTS = {
  new: "new-request",
  update: "update-request",
  delete: "delete-request",
};

export const approvalsMsg = (req, dispatch) => {
  const { body, request } = req;

  switch (request) {
    case APPROVALS_REQUESTS.update + "-res": {
      dispatch({ type: "UPDATE_APPROVAL", payload: body });
      break;
    }
    case APPROVALS_REQUESTS.new + "-res": {
      dispatch({ type: "ADD_APPROVAL", payload: body });
      break;
    }
    case APPROVALS_REQUESTS.delete + "-res": {
      const { requestId } = body;
      dispatch({ type: "DELETE_APPROVAL_BY_ID", payload: { id: requestId } });
      break;
    }
    default:
      break;
  }
};

export const sendRequestWsMessageCP = ({
  sendWsMessage,
  action = "CP_RequestUpdated",
  recordId,
  nameOfUser,
  recordName,
  createdBy,
  updatedReq = {},
}) => {
  sendWsMessage({
    notification: {
      templateCategory: "Request",
      action,
      keysConfig: {
        category: "Requests",
        recordId,
        nameOfUser,
        recordName,
      },
      cognitoUserIds: [createdBy],
    },
  });

  sendWsMessage({
    type: "requests",
    request: APPROVALS_REQUESTS.update,
    body: {
      requestId: recordId,
      ...updatedReq,
    },
  });
};
