import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import { Component } from "react";
import { message } from "antd";
import React from "react";

import { updateScheduleOfValue } from "../../../../../../../../../../actions/scheduleOfValues";
import { defaultColDef, columnDefs, frameworkComponents } from "./GridData";
import { withContext } from "../../../../Context/Context";
import "./service-content.scss";
import {
  getRowNodeId,
  onCellKeyPress,
  getContextMenuItems,
  appendRow,
  removeRow,
  cellValueChanged,
} from "./GridData/GridUtils";
import AgGridMathful from "../../../../../../Applications/ApplicationView/components/ServiceList/Service/ServiceContent/AgGridSum/AgGridMathful";
import { toggleTaxEvent } from "./GridData/GridUtils/toggleTaxEvent";
import { companyDetails } from "../../../../../../../../../../helpers/constants";
import { removeRetainageEvent } from "./GridData/GridUtils/removeRetainageEvent";

class ServiceContent extends Component {
  state = {
    selected: false,
    width: "auto",
    height: "auto",
  };

  setSOV = (newSOV) => {
    this.props.dispatchContext({ type: "SET_FAKE_SOV", payload: newSOV });
  };

  setErrors = (errors) => {
    this.props.dispatchContext({ type: "SET_ERRORS", payload: errors });
  };

  // Resizes grid row heights
  resizeGridRows = (gridApi) => {
    gridApi.resetRowHeights();
  };
  // Called when a cell editing has started.
  cellEditingStarted = (params) => {
    if (!this.props.stateContext.isWritable) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
    if (this.props.isLoading) {
      params.api.stopEditing(true);
      message.error("Please wait until it finishes updating");
    }
  };

  // Called when a cell editing has stopped. Used to resize rows when not in focus
  cellEditingStopped = (params) => {
    this.resizeGridRows(params.api);
  };

  onRangeSelectionChanged = (event) => {
    let cellRanges = event.api.getCellRanges();
    let hasNotColumns = cellRanges[0]?.columns?.find(
      (el) => el?.colId === "id_1" || el?.colId === "note"
    );
    if (!!hasNotColumns) {
      // message.error("You can't select this column");
      // event.api.clearRangeSelection();
    } else {
      if (
        cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
        cellRanges.length > 1
      ) {
        this.setState({ selected: Object.assign(event, {}) });
      } else {
        this.setState({ selected: false });
      }
    }
  };

  // Appends a row below another row
  appendRowInGrid = (eventId) => {
    appendRow(
      eventId,
      this.props.stateContext.isWritable,
      this.props.isLoading,
      this.props.service,
      this.props.stateContext.fakeSOV,
      this.setSOV,
      this.setErrors
    );
  };

  // Removes a row
  removeRowInGrid = (eventId) => {
    removeRow(
      eventId,
      this.props.stateContext.isWritable,
      this.props.isLoading,
      this.props.service,
      this.props.stateContext.fakeSOV,
      this.setSOV,
      this.setErrors
    );
  };

  // Makes a row taxable from project tax
  makeRowTaxable = (eventId, data) => {
    toggleTaxEvent(
      eventId,
      this.props.stateContext.isWritable,
      this.props.isLoading,
      this.props.service,
      this.props.stateContext.fakeSOV,
      this.setSOV,
      this.setErrors,
      data,
      this.props.project
    );
    console.log("makeRowTaxable", eventId, data);
  };

  removeRetainage = (eventId, data) => {
    return removeRetainageEvent(
      eventId,
      this.props.stateContext.isWritable,
      this.props.isLoading,
      this.props.service,
      this.props.stateContext.fakeSOV,
      this.setSOV,
      this.setErrors,
      data
    );
  };
  onResize = (event, { node, size, handle }) => {
    this.setState({ width: size.width, height: size.height });
  };
  autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    this.gridRef.current.api.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.gridRef.current.api.autoSizeColumns(allColumnIds, skipHeader);
  };

  constructor(props) {
    super(props);
    this.currentFakeSov = React.createRef();
    this.currentServices = React.createRef();
    this.gridRef = React.createRef();
  }

  render() {
    this.currentFakeSov.current = this.props.stateContext.fakeSOV;
    this.currentServices.current = this.props.service;
    let darkMode = this.props.stateContext?.darkMode;

    return (
      <div className="sov-service-content">
        {/* <ResizableBox
          // className="sov-resizable"
          // minConstraints={[0, 100]}
          // maxConstraints={[0, 1500]}
          width={this.state.width}
          height={this.state.height}
          // height={350}
          axis="y"
          handle={
            <div className="applicationBodyResizableGripContainer">
              <div
                className="leftSize"
                style={{ background: this.props.borderColor }}
              />
              <div
                className="grabberContainer"
                style={{ background: this.props.borderColor }}
              >
                <div className="gripLines" />
                <div className="gripLines" />
                <div className="gripLines" />
              </div>
              <div
                className="rightSize"
                style={{ background: this.props.borderColor }}
              />
            </div>
          }
          onResize={this.onResize}
        > */}
        <div
          className={
            darkMode
              ? "schedule-of-values-service-content-grid-dark agGridRentalsDetailsStyleDark ag-theme-alpine-dark"
              : "schedule-of-values-service-content-grid agGridRentalsDetailsStyleLight ag-theme-alpine"
          }
          style={{ height: "100%", width: "100%" }}
        >
          <AgGridMathful
            darkMode={darkMode}
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
            event={this.state.selected}
          />

          <AgGridReact
            immutableData={true}
            ref={this.gridRef}
            domLayout="autoHeight"
            rowDragManaged={true}
            suppressMoveWhenRowDragging={true}
            undoRedoCellEditing={true}
            enableRangeSelection={true}
            rowSelection="single"
            enableCellChangeFlash={true}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            // params.api.setRowData(this.props.service?.amounts)
            undoRedoCellEditingLimit={50}
            rowClassRules={{
              "ag-grid-taxed-sov-row": (params) => params.data.taxAmount > 0,
            }}
            rowHeight={40}
            gridOptions={{
              rowClassRules: {
                "ag-grid-taxed-sov-row": (params) => params.data.taxAmount > 0,
              },
            }}
            defaultColDef={defaultColDef}
            rowData={this.props.service?.amounts}
            columnDefs={columnDefs({
              serviceLabel: this.props.service.label,
              estimationId: this.props.service.estimationId,
              events: this.props.serviceEvents?.listOfEvents,
              writable: this.props.stateContext.isWritable,
              service: this.props.service,
              fakeSOV: this.currentFakeSov,
              setSOV: this.setSOV,
              setErrors: this.setErrors,
              appendRowInGrid: this.appendRowInGrid,
              removeRowInGrid: this.removeRowInGrid,
              makeRowTaxable: this.makeRowTaxable,
              removeRetainage: this.removeRetainage,
            })}
            onRowDragEnd={(params) => {
              let rows = [];
              this.gridRef.current.api.forEachNode((node) =>
                rows.push(node.data)
              );
              rows = rows?.map((row, index) => {
                row.id = index;
                return row;
              });
              // change rowdata indexes
              // this.gridRef.current.api.setRowData(
              //   rows?.map((row, index) => {
              //     row.id = index;
              //     return row;
              //   })
              // );
              let NEW_SOV = structuredClone(this.props.stateContext.fakeSOV);
              NEW_SOV?.services?.forEach((service) => {
                if (service?.serviceId === this.props.service?.serviceId) {
                  let TOTAL_PRICE = service?.totalPrice;
                  service.amounts = rows.map((row, index) => {
                    TOTAL_PRICE -= row?.amount;
                    return { ...row, difference: TOTAL_PRICE };
                  });
                }
              });
              this.setSOV(NEW_SOV);
              console.log("rowDragEnd", {
                params,
                amounts: this.props.service?.amounts,
                fakeSov: this.props.stateContext.fakeSOV,
                rowdatas: test,
              });
            }}
            getContextMenuItems={(params) =>
              getContextMenuItems(
                params,
                this.appendRowInGrid,
                this.removeRowInGrid,
                this.props.service,
                this.props.serviceEvents?.listOfEvents
              )
            }
            onCellEditingStarted={(params) => this.cellEditingStarted(params)}
            onCellEditingStopped={(params) => this.cellEditingStopped(params)}
            onCellValueChanged={(params) =>
              cellValueChanged(
                params,
                this.props.service,
                this.props.stateContext.fakeSOV,
                this.setSOV,
                this.setErrors
              )
            }
            autoSizeStrategy={{
              type: "fitCellContents",
            }}
            onFirstDataRendered={(params) => {
              let columnApi = params.columnApi;
              const allColumnIds = [];

              columnApi.getColumns()?.forEach((column) => {
                const colId = column?.getId();
                allColumnIds.push(colId);
              });
              try {
                columnApi.autoSizeColumns(allColumnIds, false);
              } catch (e) {
                console.log(e);
              }
            }}
            onComponentStateChanged={(params) => {
              let columnApi = params.columnApi;
              const allColumnIds = [];

              columnApi.getColumns()?.forEach((column) => {
                const colId = column?.getId();
                allColumnIds.push(colId);
              });
              try {
                columnApi.autoSizeColumns(allColumnIds, false);
              } catch (e) {
                console.log(e);
              }
            }}
            // to be updated
            onRangeSelectionChanged={(event) =>
              this.onRangeSelectionChanged(event)
            }
            frameworkComponents={frameworkComponents}
            getRowNodeId={getRowNodeId}
            onCellKeyPress={onCellKeyPress}
            stopEditingWhenGridLosesFocus={true}
            suppressDragLeaveHidesColumns={true}
            allowDragFromColumnsToolPanel={true}
          />
        </div>{" "}
        {/* </ResizableBox>{" "} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyName: companyDetails.companyName,
  isLoading: state?.scheduleOfValues?.isLoading,
});

export default connect(mapStateToProps, { updateScheduleOfValue })(
  withContext(ServiceContent)
);
