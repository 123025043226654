import React from "react";

import { useRedux } from "../../../hooks";
import { PlusIcon } from "../../../../../assets";
import { defaultGridRow } from "../utils/constants";
import { MondayButton } from "../../../../commonComponents";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";
import { useSelector } from "react-redux";

import "./TableTitle.scss";

const TableTitle = () => {
  const [isWritable] = useRedux("billIsWritable");

  const { form, billData, vendor, project } = useInitialBillContext();
  const { rowData, setRowData } = useCreateBillContext();
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const addNewRow = () => {
    const formAmount = form.getFieldValue("billAmount") || 0;
    const totalAmount = rowData.reduce((sum, row) => sum + row.total, 0);

    const taxReverse = vendor?.vendorTaxRate ? 1 + vendor?.vendorTaxRate : 0;
    const totalValue = formAmount - totalAmount;
    const amount = taxReverse > 0 ? totalValue / taxReverse : totalValue;
    const taxAmount = amount * (vendor?.vendorTaxRate || 0);

    setRowData((prev) => [
      ...prev,
      {
        ...structuredClone(defaultGridRow),
        amount: amount,
        projectName: project?.projectName,
        taxAmount: taxAmount,
        total: amount + taxAmount,
        itemId: prev.length + 1,
        tax: taxAmount > 0,
        billable: taxAmount > 0,
      },
    ]);
  };

  return (
    <div className="table-title-container">
      <span style={{ color: isDarkMode ? '#F2F2F2' : '#12131B' }}
      >Bill Items</span>
      <MondayButton
        onClick={addNewRow}
        Icon={<PlusIcon />}
        disabled={!!billData && !isWritable}
      >
        Add Row
      </MondayButton>
    </div>
  );
};

export default TableTitle;
