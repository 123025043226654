export const statusStyle = {
  Draft: "#FCC94A",
  Confirmed: "#71CF48",
  Cancelled: "#FE4C4A",
  "In Process": "#ECDE7C",
  "Pre-Schedule": "#ECDE7C",
  "In Progress": "#ECDE7C",
  Completed: "#7AC14D",
};

export const colorsForPreviewSchedule = {
  Installation: "#5573B8",
  Removal: "#F04F4E",
  Inspection: "#0096FF",
};

export const typeOfWorkColors = {
  Installation: "rgb(122, 193, 77)",
  Repair: "#B3D99B",
  Removal: "#F04F4E",
  "Pick up material": "#00A464",
  "Deliver material": "#00A464",
  Maintenance: "#7AC14D",
  Inspection: "#7AC14D",
  Yard: "#99183e",
};
