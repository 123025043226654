import { API } from "aws-amplify";
import moment from "moment";

export const makeDataOfPostAutomationReadable = (structure) => {
  if (Array.isArray(structure)) {
    if (structure.every((item) => typeof item === "string")) {
      return structure.join(", "); // Join if all are strings
    } else if (
      structure.every(
        (item) =>
          typeof item === "object" && item !== null && !Array.isArray(item)
      )
    ) {
      return structure
        .map((obj) => {
          return Object.entries(obj)
            .filter(([key]) => !key.toLowerCase().includes("id")) // Exclude keys containing 'id'
            .map(
              ([key, value]) =>
                `${key}: ${makeDataOfPostAutomationReadable(value)}`
            )
            .join(", "); // Join key-value pairs
        })
        .join("; "); // Join objects with '; '
    } else {
      return structure
        .map((item) => makeDataOfPostAutomationReadable(item))
        .join("; "); // Join items with '; '
    }
  }

  if (typeof structure === "string" && structure.includes("https:")) {
    return `<img src="${structure}" style="max-width: 100%; height: 100%; border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);" />`;
  }

  if (typeof structure === "number" && structure.toString().length === 13) {
    return moment(structure).format("MM/DD/YYYY");
  }

  return structure.toString();
};

export const realTimePostEventsAutomations = async () => {
  try {
    const res = await API.get("programFields", "/programFields");
    const proceedRes = res?.find(
      ({ fieldName }) => fieldName === "Post Events Automations Configurations"
    )?.fieldOptions;
    return proceedRes;
  } catch (err) {
    console.log({ err });
    return null;
  }
};
