import { useState } from "react";
import { FilterIcon } from "../../../../../SidebarPages/BasePage/src";
import { useChartCardsContext } from "../../../ChartCardsContext";
import { ClearFilterIcon } from "../../../../../SidebarPages/DynamicView/src";
import MondayButton from "../../../../MondayButton/MondayButton";
import ChartFilters from "../../ChartFilters/ChartFilters";

const FilterCards = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const { generalFilters, setGeneralFilters, setNewChanges } =
    useChartCardsContext();

  return (
    <div className="chart-cards-filters">
      <MondayButton
        className="mondayButtonBlue"
        tooltipCategory="CHART CARDS"
        tooltipKey="chartFilters"
        Icon={
          generalFilters?.GENERAL_CHART_FILTERS?.length ? (
            <ClearFilterIcon />
          ) : (
            <FilterIcon />
          )
        }
        onClick={() => {
          setShowFilterModal(true);
        }}
      />
      {showFilterModal && (
        <ChartFilters
          {...{
            showFilterModal,
            setShowFilterModal,
            defaultFilters: generalFilters?.GENERAL_CHART_FILTERS,
            setFilters: (filters) => {
              setNewChanges(true);
              setGeneralFilters((prev) => ({
                ...prev,
                GENERAL_CHART_FILTERS: filters,
              }));
            },
          }}
        />
      )}
    </div>
  );
};

export default FilterCards;
