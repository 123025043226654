import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../commonComponents";
import { WarningTriangle } from "../../../SidebarPages/DynamicView/src";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { Tick } from "../../../pages/Settings/settingsComponents/Roles/src";
import { useEffect } from "react";

export const ConfirmationModal = ({ showModal, title, setShowModal, onOK, footer,titleInfo }) => {
  const handleEsc = (event) => {
    if (event.key === "Escape") {
      setShowModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [handleEsc]);

  return (
    <WarningModal
      wrapClassName="confirmModalOnRouteChange"
      title={"Warning"}
      visible={showModal}
      setShowModal={() => { }}
      closable={false}
      className="logout-warning-modal"
      //   darkMode={isDarkMode}
    >
      <div className="logout-modal-body">
        <span>
          <WarningTriangle />
        </span>
        {titleInfo ?
          <p>{titleInfo}</p>
          : <p>{`Are you sure you want to ${title}?`}</p>
        }
        {footer ?
          <div className="buttons">{footer}</div>
          :
        <div className="buttons">
          <MondayButton
            Icon={<XIcon />}
            data-testid="decline-button"
            onClick={() => setShowModal(false)}
            className="decline-logout mondayButtonRed"
          >
            No
          </MondayButton>
          <MondayButton
            data-testid="accept-button"
            onClick={() => {
              onOK();
              setShowModal(false);
            }}
            Icon={<Tick width={17} height={17} />}
          >
            Yes
          </MondayButton>
        </div>
        }
      </div>
    </WarningModal>
  );
};
