import { CloseOutlined } from "@ant-design/icons";
import { Badge, Drawer, Pagination, Tabs, Empty } from "antd";
import { Buffer } from "buffer";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleSignIn } from "../../../../hooks/useGoogleSignIn";
import { showInfoMsg } from "../../../../utils";
import { callGmailAPI } from "../../../SidebarPages/Communication/functions";
import ActionsComponent from "../Notifications/NotificationData/ActionsComponent";
import { ConfirmationModal } from "./ConfirmationModal";
import { FilterTypes, TabData } from "./NotificationData";
import { Filters } from "./components";
import GoogleUserUi from "./components/google-user-ui/GoogleUserUi";
import NotificationsLayout from "./components/notifications-layout/NotificationsLayout";
import { getFilterByValue, getTabValue } from "./utils/helpers";
import { XIcon } from "../../../SidebarPages/Communication/assets"

import SelectRecordCategory from "./components/SelectRecordCategory/SelectRecordCategory";
import apiService from "./utils/notifications-api";

import "./Notifications.scss";
import {
  getEmailsAsNotifications,
  getSearchValueFilteredNotifications,
  handleFilterClick,
  handleMarkAllAsRead,
  handlePaginationChange,
  handleTabChange,
  paginatedNotifications,
  updateFilters,
} from "./helpers/notifications-drawer-helpers";
import { useNotificationsDrawer } from "./providers/NotificationsDrawerProvider";
import { useMultipleRefs } from "../../../../hooks/useMultipleRefs";
import { MondayButton } from "../../../commonComponents";
const totalNotificationsPerPage = 24;

const NotificationsDrawer = ({
  open,
  // setOpen,
  fetchData,
  notificationsData,
  lastEvaluatedKey,
}) => {
  const dispatch = useDispatch();
  const { googleAccessToken, onGoogleSignIn, onGoogleSignOut, userLoggedIn } =
    useGoogleSignIn();
  const { tab, filterBy, notifications, communication } = useSelector(
    (state) => state.notifications
  );
  const { toggleDrawer } = useNotificationsDrawer();
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [type, setType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRecordCategory, setSelectedRecordCategory] =
    useState("projects");
  const [filteredEmails, setFilteredEmails] = useState([]);
  const tabRefs = useMultipleRefs(4);  
  const [allNotificationsCount, setAllNotificationsCount] = useState(0);
  const [clickedFilter, setClickedFilter] = useState(null);
  const [allRef, hiddenRef, mentionedRef, assignedRef] = tabRefs;
  // search notifications input filtering data when triggered
  const allNotifications = useMemo(() => {
    if (!!searchValue) {
      return getSearchValueFilteredNotifications(notificationsData, searchValue);
    }
    return notificationsData;
  }, [searchValue, notificationsData]);

  let authData =
    userLoggedIn && googleAccessToken
      ? Buffer.from(JSON.stringify(googleAccessToken)).toString("base64")
      : null;

  useEffect(() => {
    getEmailsAsNotifications(
      dispatch,
      selectedRecordCategory,
      authData,
      userConfiguration
    );
  }, [googleAccessToken]);

  const handleUpdateFilters = (tab, filterBy) => {
    updateFilters(dispatch, tab, filterBy);
  };

  useEffect(() => {
    setAllNotificationsCount(Array.isArray(notificationsData) ? notificationsData.length : 0); 
  }, [notificationsData]);

  
  const onFilterClick = async (title) => {
    if (clickedFilter === title) {
      setClickedFilter(null); // deselect the filter
    } else {
      setClickedFilter(title); // set filter
    }
  if(title.toLowerCase() !== 'all') {
    setFilteredEmails([]);
    setCurrentPage(1);
    handleFilterClick(
      title,
      setType,
      type,
      tab,
      handleUpdateFilters,
      filterBy,
      setCurrentPage
    );
   }
};

  const onTabChange = async (title) => {
    setCurrentPage(1);
    setFilteredEmails([]);
    handleTabChange(
      title,
      handleUpdateFilters,
      getTabValue,
      filterBy,
      getFilterByValue,
      setCurrentPage,
      setType,
      type
    );
  };

  const TabItems = TabData({
    notifications: notifications,
    userConfiguration,
    communication: communication,
  }).map(({ title, length }, i) => {
    const displayCount = title.toLowerCase() === 'all'
    ? allNotificationsCount 
    : notificationsData?.filter((notification) => 
      notification?.priority === title)?.length || 0;

    const filteredNotifications = notificationsData?.filter((notification) => 
      notification?.priority === title
    );
   return {
      key: title,
      label: (
        <div className={`badge-notification-count`}>
          <Badge
            ref={tabRefs[i]}
            style={{ top: -3 }}
            className="ignore-onclickoutside"
            count={displayCount}
            size="small"
            onClick={() => {
              onTabChange(title);
            }}
          >
            <span className="tab-label">{title}</span>
          </Badge>
        </div>
      ),
      children: (
        <div className="notifications-body">
          <div className="notifications-filters">
            <div className="filters">
              <span className="filter-text">Filter By:</span>
              {FilterTypes?.filter(({ title }) => {
                if (
                  tab.toLowerCase() === "communication" &&
                  (title.toLowerCase() === "critical" ||
                    title.toLowerCase() === "info")
                ) {
                  return false;
                }
                return true;
              }).map(({ title, Icon, color }, i) => {
                const filterNotifications = notificationsData?.filter(
                  notification => {
                    return notification?.priority === title
                  }
                )?.length || 0

                return (
                  <div key={i} className="filter-item">
                    <div className="filter-title" onClick={() => onFilterClick(title)}>
                <Filters
                  key={i}
                  {...{
                    title,
                    Icon,
                    i,
                    color,
                    type,
                    setType: (e) => {
                      handleUpdateFilters(tab, getFilterByValue(e));
                      setType(e);
                      setFilteredEmails([]);
                      setCurrentPage(1);
                    },
                    isDarkMode,
                    onClick: () => {
                      onFilterClick(title);
                    },
                  }}
                />
           </div> 
                    {clickedFilter === title && filterNotifications > 0 && (
                      <Badge
                        count={filterNotifications}
                        style={{
                          position: 'absolute',
                          bottom: '11px',
                          fontSize: '12px',
                          lineHeight: '18px',
                          padding: ' 2px 1px',
                          marginRight: '-6px'
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>

            {tab?.toLowerCase() !== "communication" && (
              <div style={{ display: "flex", gap: "5px" }}>
                <div
                  onClick={() => {
                    const newArray = allNotifications.filter(
                      (notification) => notification.seen === false
                    );

                    if (!!newArray?.length) {
                      setShowModal(true);
                      // setIconClicked("Mark All As Read");
                      if (newArray?.length > 300) {
                        // Set warning modal for 300 notifications
                        setShowModalInfo(true)
                      }
                    } else {
                      showInfoMsg({
                        content: "Notifications are already read.",
                      });
                    }
                  }}
                  className="mark-read-btn"
                >
                  Mark All As Read
                </div>
              </div>
            )}

            {tab?.toLowerCase() === "communication" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <SelectRecordCategory
                  fetchData={(selectedValue) =>
                    getEmailsAsNotifications(
                      dispatch,
                      selectedValue,
                      authData,
                      userConfiguration
                    )
                  }
                  selectedRecordCategory={selectedRecordCategory}
                  setSelectedRecordCategory={setSelectedRecordCategory}
                />

                <GoogleUserUi
                  onGoogleSignOut={onGoogleSignOut}
                  onGoogleSignIn={onGoogleSignIn}
                  getEmailsAsNotifications={() =>
                    getEmailsAsNotifications(
                      dispatch,
                      selectedRecordCategory,
                      authData,
                      userConfiguration
                    )
                  }
                  setSelectedRecordCategory={setSelectedRecordCategory}
                />
              </div>
            )}
          </div>
          {allNotifications?.length > 0 ? (
            <NotificationsLayout
              paginatedNotifications={paginatedNotifications(
                currentPage,
                totalNotificationsPerPage,
                allNotifications
              )}
              allNotifications={allNotifications}
              // setOpen={setOpen}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              tabTitle={title}
              activeTab={tab?.toLowerCase()}
              currentPage={currentPage}
              searchValue={searchValue}
              filteredEmails={filteredEmails}
              setFilteredEmails={setFilteredEmails}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      ),
    };
  });

  const total = allNotifications?.length;

  return (
    <div className="notifications-container">
      <Drawer
        title="Notifications"
        extra={
          <ActionsComponent
            {...{
              filteredNotifications: allNotifications,
              activeNotificationsTab: tab,
              setCurrentPage,
              setSearchValue,
              searchValue,
              selectedItems,
              tabRefs,
            }}
          />
        }
        width={900}
        onClose={() => toggleDrawer()}
        open={open}
        placement="right"
        closeIcon={<CloseOutlined />}
        rootClassName={`notifications-drawer ${isDarkMode && "notifications-drawer-dark"
          }`}
      >
        <div className="notification-tabs">
          <Tabs
            defaultActiveKey="1"
            onChange={(e) => {
              setCurrentPage(1);
              handleUpdateFilters(getTabValue(e), filterBy);
            }}
            items={TabItems}
          />
        </div>
        <div className="notifications-footer">
          <Pagination
            current={currentPage}
            pageSize={totalNotificationsPerPage}
            total={
              tab?.toLowerCase() === "communication"
                ? filteredEmails?.length
                : lastEvaluatedKey !== null
                ? total + 24
                : total
            }
            onChange={(page) =>
              handlePaginationChange(
                page,
                tab,
                setCurrentPage,
                //totalNotificationsPerPage,
                total,
                fetchData
              )
            }
            showSizeChanger={false}
          />
        </div>
      </Drawer>

      {showModal ? (
        <ConfirmationModal
          showModal={showModal}
          setShowModal={setShowModal}
          title="Mark All As Read"
          onOK={() =>
            handleMarkAllAsRead(
              authenticatedUser.sub,
              dispatch,
              allNotifications,
              apiService
            )
          }
        />
      ) : null}
      {!showModal && showModalInfo ? (
        <ConfirmationModal
          titleInfo={[<p>You have too many notifications.</p>]}
          footer={[
            <MondayButton
              Icon={<XIcon />}
              data-testid="decline-button"
              onClick={() => setShowModalInfo(false)}
              className="decline-logout mondayButtonRed"
            >
              Close
            </MondayButton>]}
          showModal={showModalInfo}
          setShowModal={setShowModalInfo}
        />
      ) : null}
    </div>
  );
};

export default NotificationsDrawer;
