import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

const columnDefs = [
  {
    headerName: "Vehicle",
    field: "fleetName",
  },
  {
    headerName: "Driver",
    field: "driverName",
  },
  {
    headerName: "Cargo Status",
    field: "cargo",
  },
  {
    headerName: "Planned Start",
    field: "startingTime",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      if (!value) {
        return null;
      }
      return dayjsNY(value).format("HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value) {
          return dayjsNY(value).format("MM/DD/YYYY HH:mm");
        }
        return "";
      },
    },
  },
  {
    headerName: "From Location",
    field: "pickUpLocation",
  },
  {
    headerName: "Planned Arrive",
    field: "timeAtLocation",
    filter: "agDateColumnFilter",
    cellRenderer({ value }) {
      if (!value) {
        return null;
      }
      return dayjsNY(value).format("HH:mm");
    },
    pdfExportOptions: {
      modifyCellValue(value) {
        if (value) {
          return dayjsNY(value).format("MM/DD/YYYY HH:mm");
        }
        return "";
      },
    },
  },
  {
    headerName: "To Location",
    field: "dropOffLocation",
  },
  {
    headerName: "Paperwork Type",
    field: "paperworkType",
  },
  {
    headerName: "Cargo Weight",
    field: "cargoWeight",
  },
  {
    headerName: "Returning Weight",
    field: "returningWeight",
  },
  {
    headerName: "Activity Notes",
    field: "notes",
  },
  {
    headerName: "Dispatch Notes",
    field: "dispatchNotes",
  },
];

export const columnKeys = [
  "fleetName",
  "driverName",
  "cargo",
  "startingTime",
  "pickUpLocation",
  "timeAtLocation",
  "dropOffLocation",
  "paperworkType",
  "cargoWeight",
  "returningWeight",
  "notes",
  "dispatchNotes",
];

export default columnDefs;
