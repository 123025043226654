import { Card } from "antd";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";

function EstimationStep({ stepperData, onSelectEstimation }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gap: "10px",
        width: "100%",
        padding: "10px",
      }}
    >
      {stepperData?.applicableEstimations
        .sort((a, b) => b?.createdAt - a?.createdAt)
        .map((el) => {
          const newEl = {
            title: `Estimation #${el?.estimationNumber || 0}`,
            status: el?.estSTATUS || "N/A",
            createdAt: el?.createdAt,
            createdBy: el?.createdBy || "N/A",
            key: el?.estimationId,
            isChangeOrder: el?.isChangeOrder,
            value: el?.estimationId,
            versionServices: el?.versionServices,
            estimationTakeOff: el,
          };
          return (
            <Card
              key={el?.estimationId}
              title={`Estimation #${el?.estimationNumber || 0}`}
              style={{
                width: 300,

                cursor: "pointer",
                background:
                  newEl?.status === "Approved" && !el?.isChangeOrder
                    ? "#00A464"
                    : "#E9C466",
                color: "white",
              }}
              onClick={() => onSelectEstimation(newEl)}
            >
              <p>Created By: {el?.createdBy || "N/A"}</p>
              <p>
                Created on: {dayjsNY(el?.createdAt).format("MM/DD/YYYY HH:mm")}
              </p>
            </Card>
          );
        })}
    </div>
  );
}

export default EstimationStep;
