import "./TasksBasePage.scss";
import { useEffect, useMemo, useState } from "react";
import { LoadableComp } from "../../XComponents";
import BasePage from "../../BasePage";
import { useSelector } from "react-redux";
import {
  filterTables,
  getFiltersObject,
  getPanelObject,
} from "../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  columnDefs,
  chartingOptions,
  excelColumnsConfig,
  formatCellValueHandler,
} from "./AgGridData/index";
import NewTaskModal from "./TasksModals/NewTask/NewTaskModal";
import EditTaskModal from "./TasksModals/EditTask/EditTaskModal";
import { API } from "aws-amplify";
import { Checkbox, message } from "antd";
import { getOnlyMyTasks } from "../utils";
import { fetchData } from "../../Fleet/utils";
import { getExcelColumnKeys } from "../../../../utils/getExcelColumnKeys";
import { getChartingOptions } from "./utils/getChartingOptions";
import { hasSettingsAccess } from "./utils/hasSettingsAccess";

////// NOTE
//
//
////// DO NOT CHANGE THIS COMP. WITHOUT CONSULTING BECAUSE IT'S A REUSABLE COMP.
//
//
////// NOTE

// in tasks used in dynamic views we get customTaskProps
// from Task Manager taskRelatedTo click we get selectedId key
const TasksBasePage = (props) => {
  const {
    rowData: rows, // from MainDynamicTasksView
    setRowData: setRows, // from MainDynamicTasksView
    customTaskProps, // from other dynamic views
    selectedId: taskId, // from Task Related To redirect
    reFetchData = () => {},
  } = props;

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  const keysToUpdate = locationState?.chartFilter || {};
  const panelObject = getPanelObject(programFields, "Tasks");
  const filtersObject = getFiltersObject(programFields, "Tasks", keysToUpdate);

  const [rowData, setRowData] = useState();
  const [myTasks, setMyTasks] = useState();
  const [showOnlyMyTasks, setShowOnlyMyTasks] = useState(false);
  const [panelFilter, setPanelFilter] = useState({
    status: panelObject?.Status?.label,
    checked: false,
    visible: true,
  });
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);
  const [clonedTask, setClonedTask] = useState(); //save current task on edit to clone it
  const [gridApi, setGridApi] = useState(null);

  const isAdmin = userConfiguration.groupName.toLowerCase().includes("admin");
  const fromSettings = window.location.pathname.split("/")[1] === "settings";

  const taskStatuses = programFields
    ?.find((item) => item.fieldName === "Task Statuses")
    .fieldOptions.filter(({ status }) => status)
    .map((item) => ({
      title: item.statusName,
      statusColor: item.statusColor,
    }));

  useEffect(() => {
    getRowData();
  }, [rows]);

  //when we click task related to in TasksBasePage or from kanban
  useEffect(() => {
    const sessionTaskId = sessionStorage.getItem("taskId");
    if (!!taskId || sessionTaskId) {
      API.get(
        "tasksManagement",
        `/tasksManagement/${!!taskId ? taskId : sessionTaskId}`
      )
        .then((res) => {
          setSelectedTask(res);
          sessionStorage.removeItem("taskId");
        })
        .catch(() => setSelectedTask(404));
    }
  }, [taskId, locationState]);

  useEffect(() => {
    if (locationState?.props) {
      API.get("tasksManagement", `/tasksManagement/${locationState?.props}`)
        .then((res) => {
          setSelectedTask(res);
        })
        .catch(() => setSelectedTask(404));
      navigate(location.pathname, { replace: true, state: undefined });
    }
  }, [locationState?.props]);

  const filterSearchData = async (filters) => {
    gridApi?.showLoadingOverlay();
    const queryStringParameters =
      Array.isArray(filters) && filters.length > 0
        ? { filters: JSON.stringify(filters) }
        : undefined;

    await fetchData("tasksManagement", "tasksManagement", {
      queryStringParameters,
    })
      .then((result) => setRowData(result))
      .catch((error) => console.log({ error }));
  };

  useEffect(() => {
    !!clonedTask && setNewTaskModal(true);
  }, [clonedTask]);

  const getRowData = () => {
    // from MainDynamicTasksView
    if (!!rows) {
      setRowData(rows);
    } else if (customTaskProps?.fromSettings) {
      filterTables("tasksManagement", "taskTopic", customTaskProps.taskTopic)
        .then((res) => {
          const tasks = customTaskProps?.taskRelatedTo
            ? res.filter(
                (item) => item.taskRelatedTo === customTaskProps?.taskRelatedTo
              )
            : res; //Only admin can see all task.Each user can see tasks he created, tasks that he has been assigned to

          setRowData(
            !!isAdmin ? tasks : getOnlyMyTasks(tasks, userConfiguration)
          );
        })
        .catch((err) => {
          console.log({ err });
          setRowData([]);
        });
    } else if (customTaskProps?.taskTopicId) {
      filterTables(
        "tasksManagement",
        "taskTopicId",
        customTaskProps.taskTopicId
      )
        .then((res) => {
          //Only admin can see all task.Each user can see tasks he created, tasks that he has been assigned to
          setRowData(!!isAdmin ? res : getOnlyMyTasks(res, userConfiguration));
        })
        .catch((err) => {
          console.log({ err });
          setRowData([]);
        });
    }
  };

  const reloadGrid = (gridApi) => {
    if (!!rows) {
      gridApi.showLoadingOverlay();
      reFetchData();
    } else {
      gridApi.showLoadingOverlay();
      getRowData();
    }
  };

  const changePanels = (event) => {
    event
      ? setPanelFilter({
          status: panelObject?.Topic.label,
          checked: true,
          visible: true,
        })
      : setPanelFilter({
          status: panelObject?.Status.label,
          checked: false,
          visible: true,
        });
  };

  const updateRowData = (updatedRowData) => {
    !!rows ? setRows(updatedRowData) : setRowData(updatedRowData);
  };

  const filterMyTasks = (
    <div className="filter__wrapper centeredFlex">
      <span>My Tasks</span>
      <Checkbox
        checked={showOnlyMyTasks}
        onChange={(e) => {
          setShowOnlyMyTasks(e.target.checked);

          e.target.checked &&
            setMyTasks(getOnlyMyTasks(rowData, userConfiguration));
        }}
        className="trucks-modal-checkbox"
      />
    </div>
  );

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (headerName === "Assigned to") {
          return value?.length
            ? value.map(({ nameOfUser }) => nameOfUser).join(", ")
            : "";
        } else if (headerName === "SubTopic Related To") {
          if (typeof value === "string" && value !== "") {
            return value;
          } else if (
            typeof value === "object" &&
            Object.keys(value).length > 0
          ) {
            return value?.recordName || "-|-";
          } else return "-|-";
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  };

  const handleTaskClick = (data) => {
    if (data.hasOwnProperty("fromSettings") && !!data.fromSettings) {
      const result = programFields
        .find(({ fieldName }) => fieldName === "Settings Tasks")
        .fieldOptions.filter((item) => item.included.includes("task"))
        .find((topic) => topic.categoryName === data.taskTopic);

      const hasAccess = hasSettingsAccess(userConfiguration, result.access);

      if (!hasAccess) {
        return message.error({
          content: "You don't have access to this record or it doesn't exist.",
          key: "taskAccess",
          duration: 2,
        });
      }
    }

    setSelectedTask(data);
  };

  const columnDefsMemo = useMemo(() => {
    return columnDefs({ handleTaskClick, clickableRecord: !customTaskProps });
  }, [customTaskProps]);

  const getTableData = () => {
    const isSettingsPage = window.location.pathname === "/settings/allTasks";

    if (isSettingsPage) {
      return (showOnlyMyTasks ? myTasks : rowData)?.filter(
        (item) => item.hasOwnProperty("fromSettings") && !!item.fromSettings
      );
    } else if (!customTaskProps) {
      return (showOnlyMyTasks ? myTasks : rowData)?.filter(
        (item) =>
          (item.hasOwnProperty("fromSettings") && !item.fromSettings) ||
          !item.hasOwnProperty("fromSettings")
      );
    } else {
      return showOnlyMyTasks ? myTasks : rowData;
    }
  };

  const tableData = useMemo(() => getTableData(), [rowData, myTasks]);

  return (
    <LoadableComp
      loading={!!!Array.isArray(rowData)}
      style={
        fromSettings
          ? {
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              width: "100%",
              height: "100%",
            }
          : {}
      }
    >
      <div
        className={`tasks-base-page ${isDarkMode && "tasks-base-page-dark"}`}
      >
        <BasePage
          {...{
            title: "Tasks",
            rowData: tableData,
            filtersObject,
            panelObject: panelFilter.checked // used in switch status panel of base page
              ? panelObject?.Topic
              : panelObject?.Status,
            columnDefs: columnDefsMemo,
            exportGridToExcel,
            defaultExcelExportParams: {
              columnKeys: excelColumnsConfig,
              fileName: "Tasks",
            },
            formatCellValueHandler,
            chartingOptions,
            paginationPageSize: 10,
            paginationAutoPageSize: true,
            videoLinks: true,
            panelFilter: !!customTaskProps ? { visibleOne: true } : panelFilter, // used in switch status panel of base page // no switch inside a DynamicView
            setPanelFilter: changePanels, // used in switch status panel of base page
            customNew: () => setNewTaskModal(true),
            getGridApi: (e) => setGridApi(e),
            getChartingOptions: (data) => getChartingOptions(data),
            optionButton: !!isAdmin && filterMyTasks,
            isTab: !!customTaskProps,
            reloadGrid,
            filterSearchData,
            setRowData: setRows,
          }}
        />
      </div>
      {newTaskModal && (
        <NewTaskModal
          {...{
            rowData,
            updateRowData,
            newTaskModal,
            setNewTaskModal,
            clonedTask,
            setClonedTask,
            customTaskProps,
            taskStatuses,
            fromSettings: customTaskProps?.fromSettings || false,
          }}
        />
      )}

      {!!selectedTask && (
        <EditTaskModal
          {...{
            selectedTask,
            setSelectedTask,
            rowData,
            updateRowData,
            setClonedTask,
            taskStatuses,
          }}
        />
      )}
    </LoadableComp>
  );
};

export default TasksBasePage;
