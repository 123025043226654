import { InputComponent } from "../../../SidebarPages/Fleet/components";

const WidgetTitle = ({ title, filledWidgets, setFilledWidgets }) => {
  return (
    <div>
      <InputComponent
        {...{
          type: "input",
          label: "Enter title",
          required: true,
          placeholder: "Provide a title for widget...",
          defaultValue: filledWidgets?.[title],
          onChange: (event) => {
            setFilledWidgets((prev) => ({
              ...prev,
              [title]: event.target.value,
            }));
          },
        }}
      />
    </div>
  );
};

export default WidgetTitle;
