import { useSelector } from "react-redux";
import { InputComponent } from "../../SidebarPages/Fleet/components";

const getChildren = (childrens, keyTitle) => {
  return childrens.map(({ title, children = [] }, i) => ({
    key: keyTitle + i,
    value: title,
    label: title,
    children: getChildren(children, title),
  }));
};

const RouteTitleAccessInput = ({ formItemName = "routeTitleAccess" }) => {
  const { userGroups } = useSelector((state) => state.userGroups);

  const allRouteConfigs = userGroups.find(
    ({ groupId }) => groupId === "14292f99-a8aa-407f-8683-c4326cff7b49"
  ).groupConfigs.routeConfig;

  return (
    <InputComponent
      required
      label="Route Access Title"
      type="cascader"
      formItemName={formItemName}
      placeholder="Select path to check access rights!"
      options={getChildren(allRouteConfigs)}
    />
  );
};

export default RouteTitleAccessInput;
