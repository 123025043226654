import { ExcelIcon } from "../../../../../../../assets";
import { forceToNumber } from "../../../../../../../utils/ActiveReportJsUtils/helpers";
import { MondayButton } from "../../../../../../commonComponents";
import * as XLSX from "xlsx";

const ExportElevationPlanks = ({  dataSrc, priceSheetDataSrc }) => {
    const onExport = () => {
        console.log("Exporting data to excel");
        console.log("ExportElevationPlanks", {
            dataSrc,
            priceSheetDataSrc
        });
        let SUMMARY_DATA = priceSheetDataSrc.map((item, index) => {
            return {
                Entity: item.dataKey || "",
                Value: forceToNumber(item.dataValue || ""),
                PPU: forceToNumber(item.dataPPU || ""),
                // Price: item.dataPrice || "",
                "Total Price" : forceToNumber(item.dataTotalPrice || ""),
            }
        });
        let SUMMARY_SHEET = XLSX.utils.json_to_sheet(SUMMARY_DATA);
        let ELEVATION_DATA = dataSrc.map((item, index) => {
            return {
                L: item?.length || "",
                H: item?.height || "",
                Sqft: item?.sqft || "",
                "BaysLong": item?.baysLong || "",
                "Frames High": item?.framesHigh || "",
                "# of Full Decks": item?.fullDecksNo || "",
                "Full Deck": item?.fullDeck || "",
                "Bicycle Type": item?.bicType || "",
                "# Bic Levels": item?.bicLevelNo || "",
                "Bic Level": item?.bicLevel || "",
                "Full Deck Every Level": item?.fullDeckEveryLevel || "",
                "Bic Every Level": item?.bicEveryLevel || "",
                "Frames High W/O Safety and FD": item?.framesH || "",


            }
        });

        let ELEVATION_SHEET = XLSX.utils.json_to_sheet(ELEVATION_DATA);
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, SUMMARY_SHEET, "Summary");
        XLSX.utils.book_append_sheet(workbook, ELEVATION_SHEET, "Elevation Data");
        // XLSX.utils.book_append_sheet(workbook, otherWorksheet, "Other Data");
      
        // Write the workbook and trigger a download
        XLSX.writeFile(workbook, "ScaffoldPlanks.xlsx");
    }


    return <MondayButton className="mondayButtonBlue" Icon={<ExcelIcon />} onClick={onExport}>Export Elevation Planks</MondayButton>
}

export default ExportElevationPlanks    