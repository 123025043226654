import { useEffect, useState, useMemo } from "react";

export const useDataStatuses = (rowData = []) => {
  const [status, setStatus] = useState("pending");

  const computeStatus = useMemo(() => {
    if (rowData.length === 0) {
      return "ok-noDataFound";
    } else {
      return "ok-dataFound";
    }
  }, [rowData]);

  useEffect(() => {
    let timeout;

    if (computeStatus === "ok-noDataFound") {
      timeout = setTimeout(() => {
        setStatus("ok-noDataFound");
      }, 3000);
    } else {
      setStatus("ok-dataFound");
    }

    return () => clearTimeout(timeout);
  }, [computeStatus]);

  return status;
};
