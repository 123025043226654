import { useSelector } from "react-redux";
import { Form, message } from "antd";
import { InputComponent } from "../../Fleet/components";
import { RenderDynamicComponents } from "../../../Header/forms/Components";
import { useState, useEffect, useMemo } from "react";
import { MondayButton } from "../../../commonComponents";
import { API } from "aws-amplify";
import { XIconWhite } from "../../Communication/assets";
import { PlusOutlined } from "@ant-design/icons";
import { saveCitation } from "./helpers/saveCitationFunction";
import SafetyStatus from "../SafetyModal/components/SafetyStatus";
import { LoadableComp } from "../../XComponents";
import { DeleteIcon } from "../../DynamicView/src";
import editCitation from "./helpers/editCitationFunction";
import ScheduleViewModal from "../../Projects/Tabs/ScheduleView/components/ScheduleViewModal/ScheduleViewModal";
import "./CitationModal.scss";
import { setInitialDynamicFieldValues } from "./helpers/setInitialDynamicFieldValues";

const CitationModal = ({ visible, setVisible, crewId, data, refreshTable }) => {
  const [
    { userConfiguration },
    { programFields },
    { isDarkMode },
    { sampleObjects },
  ] = useSelector((state) => [
    state.userConfig,
    state.programFields,
    state.darkMode,
    state.sampleObjects,
  ]);

  const objectsWithPk = useMemo(
    () => sampleObjects.filter((item) => item.apiName && item.primaryKey),
    [sampleObjects]
  );

  const [crews, setCrews] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const [form] = Form.useForm();
  const formFields = Form.useWatch([], { preserve: true, form });

  const dynamicFields = programFields?.find(
    (el) => el?.fieldName === "Safety Dynamic Fields"
  ).fieldOptions["Safety Citations"];

  useEffect(() => {
    message.loading("Loading...");
    if (!crews?.length) {
      API.get("crews", "/crews")
        .then((res) => {
          setCrews(res);
        })
        .catch((err) => console.log("Error getting crews: ", err))
        .finally(() => {
          message.destroy();
        });
    }
  }, [crews]);

  const onCitationSave = async () => {
    let newValues = {};

    const selectedRecordIds = dynamicFields.reduce((acc, input) => {
      const primaryKey = objectsWithPk.find(
        (obj) => obj.apiName === input.table
      )?.primaryKey;

      if (!primaryKey) {
        newValues = {
          ...newValues,
          [input.formItemName]: formFields[input.formItemName],
        };
        return acc;
      }

      return {
        ...acc,
        ...(!acc?.[primaryKey] && {
          [primaryKey]: formFields?.[input?.formItemName],
        }),
      };
    }, {});

    const employeeId = formFields.employee.value ?? formFields.employee;
    const crewObject = crews.find(
      (crew) => crew.crewId === (crewId || employeeId)
    );
    const employeeName = `${crewObject.crewName} - ${crewObject.crewPosition}`;

    const updatedValues = {
      ...newValues,
      citationName: formFields.citationName,
      employeeId: crewId || employeeId,
      citationObject: {
        ...selectedRecordIds,
        ...newValues,
        employeeName,
      },
    };

    dynamicFields.forEach((input) => {
      const field = formFields[input.formItemName];

      if (field?.$isDayjsObject) {
        updatedValues.citationObject[input.formItemName] = field.valueOf();
      }
    });

    if (!data) {
      await saveCitation(
        updatedValues,
        statuses[currentStep].statusName || "Draft",
        userConfiguration
      );
      setVisible(false);
      form.resetFields();
      refreshTable();
    } else {
      await editCitation(
        data.citationId,
        updatedValues,
        statuses[currentStep].statusName || "Draft",
        userConfiguration
      );
      setVisible(false);
    }
  };

  const onCitationDelete = async () => {
    if (!data) return;
    await API.del("citations", `/citations/${data.citationId}`);
    refreshTable();
  };

  useEffect(() => {
    const fieldObj =
      programFields?.find((item) => item.fieldName === "Status colors")
        .fieldOptions.Safety || [];
    setStatuses(fieldObj);
  }, [programFields]);

  const statusesColors = useMemo(() => {
    return (
      programFields?.find((el) => el?.fieldName === "Statuses Of Categories")
        ?.fieldOptions?.Safety || []
    );
  }, [programFields]);

  useEffect(() => {
    if (!data) {
      form.resetFields();
      if (crewId) {
        const employee = crews.find((crew) => crew.crewId == crewId);
        form.setFieldValue("employee", {
          label: `${employee?.crewName} - ${employee?.crewPosition}`,
          value: crewId,
        });
      }
      setCurrentStep(0);
      return;
    }

    const newDataToAdd = dynamicFields.reduce((acc, input) => {
      const primaryKey = objectsWithPk.find(
        (obj) => obj.apiName === input.table
      )?.primaryKey;

      if (!primaryKey) {
        return acc;
      }

      return {
        ...acc,
        ...(!acc?.[input.formItemName] && {
          [input.formItemName]: data?.citationObject?.[primaryKey],
        }),
      };
    }, {});
    const formValues = {
      citationName: data.citationName,
      ...setInitialDynamicFieldValues(data.citationObject, dynamicFields),
      ...newDataToAdd,
      employee: {
        value: data.employeeId,
        label: data.citationObject.employeeName,
      },
      citationStatus: data.citationStatus,
    };
    form.setFieldsValue(formValues);
    const statusIndex = statuses.findIndex(
      (status) => status.statusName === data.citationStatus
    );
    setCurrentStep(statusIndex !== -1 ? statusIndex : 0);
  }, [data, form, statuses, crews]);

  return (
    <LoadableComp loading={false}>
      <ScheduleViewModal
        open={visible}
        onCancel={() => {
          setCurrentStep(0);
          setVisible(false);
        }}
        title="Safety Citation"
      >
        <div className="citationModalContent">
          <SafetyStatus
            {...{
              form,
              currentStep,
              safetyStatus: data?.citationStatus || "Draft",
              isDarkMode,
              statusesColors,
              setCurrentStep,
            }}
          />
          <Form form={form} layout="vertical" onFinish={onCitationSave}>
            <div className="formElements">
              <InputComponent
                {...{
                  label: "Citation Name",
                  formItemName: "citationName",
                  placeholder: "Citation Name...",
                  type: "text",
                  required: true,
                  value: form.getFieldValue("citationName")?.value,
                }}
              />
              <InputComponent
                {...{
                  label: "Employee",
                  formItemName: "employee",
                  placeholder: "Select Employee...",
                  type: "select",
                  required: true,
                  disabled: crewId,
                  customOptions: crews
                    .filter(
                      (crew) => crew.crewStatus?.toLowerCase() === "active"
                    )
                    .map((crew) => ({
                      label: `${crew.crewName} - ${crew.crewPosition}`,
                      value: crew.crewId,
                    })),
                  value: form.getFieldValue("employee")?.value,
                }}
              />
              {RenderDynamicComponents(
                dynamicFields.map((field, i) => {
                  const keysToUse = [
                    ...new Set(
                      dynamicFields
                        .filter((input) => input.table == field.table)
                        .map((input) => input.formItemName)
                    ),
                  ];

                  if (!field.table) {
                    return {
                      formItemName: field.formItemName,
                      label: field.label,
                      placeholder: field.placeholder,
                      type: field.type,
                      options: field.options,
                      index: i,
                      required: field.required,
                      ...(field.type === "timepicker"
                        ? {
                            format: "HH:mm",
                          }
                        : {}),
                      ...(field.type === "datepicker"
                        ? {
                            format: "MM/DD/YYYY",
                          }
                        : {}),
                    };
                  } else {
                    const primaryKey = objectsWithPk.find(
                      (obj) => obj.apiName === field.table
                    )?.primaryKey;

                    return {
                      selectedTopic: {
                        apiName: field.table,
                        primaryKey: primaryKey,
                        rowName: field.key,
                      },
                      formItemName: field.formItemName,
                      label: field.label,
                      index: i,
                      modifyValue: true,
                      placeholder: field.placeholder,
                      type: "fetchOptions",
                      required: field.required,
                      onChange: (_, input) => {
                        keysToUse.forEach((key) => {
                          form.setFieldValue(key, input.value);
                        });
                      },
                    };
                  }
                }),
                { form }
              )}
            </div>
            <div className="formButtons">
              {data && (
                <MondayButton
                  htmlType="reset"
                  className="mondayButtonRed"
                  Icon={<DeleteIcon />}
                  onClick={() => {
                    onCitationDelete();
                    setVisible(false);
                  }}
                >
                  Delete
                </MondayButton>
              )}
              <MondayButton
                htmlType="reset"
                className="mondayButtonRed"
                Icon={<XIconWhite />}
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </MondayButton>
              <MondayButton
                htmlType="submit"
                className="mondayButtonGreen"
                Icon={<PlusOutlined />}
              >
                Save
              </MondayButton>
            </div>
          </Form>
        </div>
      </ScheduleViewModal>
    </LoadableComp>
  );
};

export default CitationModal;
