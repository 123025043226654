import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { streetAbbreviations, ABBREVIATIONS_RG } from "../data";
import { appStore } from "src/store";

/**
 * Regex used to parse addresses in order to match
 * geofence locations and projects
 */
const ADDRESS_RGX = /^([\w\ \&\-\.\(\)]+,*)([\w\d\ \&\-\.\(\)]+){1}/g;

const OMIT_KEYWORDS = [
  "IDLE_START",
  "IGNITION_ON",
  "IGNITION_OFF",
  "BREAK",
  "UNAUTHORIZED",
  "BATTERY",
  "MODEM",
];

/**
 * Function that returns the street and the city of an address
 * @param {String} address The address to split
 */
export function getFence(address) {
  if (!address || typeof address !== "string") {
    return "";
  }

  let tmpNewAddress = (address.match(ADDRESS_RGX)?.[0] || "")?.trim();

  tmpNewAddress = tmpNewAddress.replace(ADDRESS_RGX, function (_, g1, g2) {
    return `${g1} ${g2.replace(/\d/g, "").trim()}`;
  });

  let match = tmpNewAddress.match(ABBREVIATIONS_RG);

  if (!match) {
    return tmpNewAddress;
  }

  const matchedSubstring = match[0];
  const lng = matchedSubstring.length;
  const matchedIndex = match.index;
  const matchedSubArr = tmpNewAddress.split("");

  matchedSubArr.splice(
    matchedIndex,
    lng,
    ` ${
      streetAbbreviations[
        matchedSubstring.replaceAll(/[,\s]/g, "").toLowerCase()
      ]
    }${matchedSubstring.charAt(lng - 1)}`
  );

  return matchedSubArr.join("");
}

export function isYard(address) {
  return (appStore.getState()?.yards?.yards || [])
    .map((e) => getFence(e?.address))
    .includes(getFence(address));
}

export function compareFences(fence1, fence2) {
  if (isYard(fence1)) {
    return isYard(fence2);
  }

  if (isYard(fence2)) {
    return isYard(fence1);
  }

  return getFence(fence1) === getFence(fence2);
}

export function isGeofenceAlert(alertCode) {
  if (!alertCode || typeof alertCode !== "string") {
    return false;
  }

  return alertCode?.toLowerCase().includes("geofence");
}

export function isEnteredAlert(alertCode) {
  if (!alertCode || typeof alertCode !== "string") {
    return false;
  }
  return alertCode?.toLowerCase().includes("entered");
}

/**
 * Function that creates a dictionary of the registered geofences
 * These will be used in order to find the activity locations that have a
 * registered geofence
 * @param {Array} geofences The list of registered geofences on LINXUP
 * @param {String} nameKey Accepts a key for the geofence name
 */
export function createGeofenceDictionary(geofences = [], nameKey = "name") {
  return geofences?.reduce((acc, val) => {
    let add = getFence(val?.[nameKey]);
    if (add) {
      return { ...acc, [add?.trim()]: { ...val } };
    } else {
      return acc;
    }
  }, {});
}

/**
 * Function that slices the actual dispatches into actual activities
 * @param {Array} alertsForTruck
 */

export function getActualActivities(alertsForTruck = []) {
  /**
   * The point of the function is to retrieve all the alerts
   * from the exit to the enter of a geofence. There may be some
   * conflicts when the geofences overlap. This is for the user to solve.
   * The correct activities have the "EXITED" alert as the first index
   * and the "ENTERED" alert as the last index. Every activity that breaks
   * this pattern is incorrect and the user needs to solve it manually.
   */
  let alerts = structuredClone(alertsForTruck);

  let truckActivities = [];
  let conflictingActivities = [];
  let lastActivity = [];
  let lastFenceEnter = null;

  for (let i = 0; i < alerts?.length; i++) {
    const alert = alerts[i];
    const { fenceName, alertCode } = alert;

    if (!isGeofenceAlert(alertCode)) {
      continue;
    }

    if (isEnteredAlert(alertCode) && lastFenceEnter === null) {
      continue;
    }

    let breakpoint = i;

    for (let j = i + 1; j < alerts?.length; j++) {
      let innerAlert = alerts[j];
      const {
        alertCode: innerCode,
        fenceName: innerFence,
        alertDesc = "",
      } = innerAlert;
      if (!isGeofenceAlert(innerCode)) {
        if (
          alertDesc?.startsWith("Entered geofence") ||
          alertDesc?.startsWith("Exited geofence")
        ) {
        }
        continue;
      }

      if (isEnteredAlert(alertCode) || !isEnteredAlert(innerCode)) {
        conflictingActivities.push(alerts.slice(i, j + 1));
        i = j;
        break;
      }

      if (fenceName === innerFence) {
        conflictingActivities.push(alerts.slice(i, j + 1));
        i = j;
        break;
      }

      if (fenceName !== lastFenceEnter && lastFenceEnter !== null) {
        conflictingActivities.push(alerts.slice(i, j + 1));
        i = j;
        break;
      }

      truckActivities.push(alerts.slice(i, j + 1));
      lastFenceEnter = innerFence;
      i = j;
      break;
    }

    /**
     * If the loop hasn't changed the main loop index, it means that
     * the vehicle is either in motion or these are the last alerts for the day.
     * It's up to the user to validate/invalidate those
     */
    if (breakpoint === i) {
      lastActivity.push(alerts.slice(i));
    }
  }

  return { truckActivities, conflictingActivities, lastActivity };
}

/**
 * Function that filters our the repeated and unnecessary alerts
 * @param {Array} alertsForTruck
 */

export function keepRelevantAlerts(alertsForTruck = []) {
  let alerts = structuredClone(alertsForTruck).filter(
    ({ alertCode }) =>
      !OMIT_KEYWORDS.find((keyword) => alertCode?.includes(keyword))
  );

  if (alerts?.length === 2) {
    return alerts;
  }

  let filteredAlerts = [];

  for (let i = 0; i < alerts?.length; i++) {
    let alert = alerts[i];
    const { alertCode } = alert;

    if (alertCode?.includes("SPEEDING")) {
      for (let j = i + 1; j < alerts?.length; j++) {
        if (alerts[j]?.["alertCode"]?.includes("SPEEDING")) {
          i = j;
          continue;
        } else {
          break;
        }
      }
    }
    filteredAlerts.push(alerts[i]);
  }

  return filteredAlerts;
}

export function getLocationStatus(defaultStatus = "", speed) {
  let status = defaultStatus;

  if (!speed) {
    status = "Stopped";
  } else {
    if (status === "Stopped") {
      status = "In Motion";
    }
  }
  return status;
}

/**
 * @param {Object} data - Data object
 * @param {Array} data.alerts - The "ENTER/EXIT" alerts for all vehicles
 * @param {Array} data.activities - All the activities for the date
 * @param {Array} data.fleet - The list of all registered vehicles
 * @param {Array} data.locations - The list of the actual truck locations
 * @param {Object} data.geofences - All the fetched geofences
 */

export function sortLiveActivities({
  alerts = [],
  activities = [],
  fleet = [],
  locations = [],
}) {
  let parsedLocations = [];
  let actualTrips = {};
  let plannedTrips = {};

  let dataToLoop = locations;
  if (!locations?.length && fleet?.length) {
    dataToLoop = fleet?.map((e) => ({ ...e, vin: e?.vinNumber }));
  }

  for (const location of dataToLoop) {
    const vehicle = fleet?.find(
      ({ deviceSerialNumber, fleetName }) =>
        deviceSerialNumber === location?.deviceSerialNumber ||
        fleetName === location?.personName
    );

    if (!vehicle) {
      continue;
    }

    let VEHICLE_ACTIVITIES = activities
      .flatMap((activity) => {
        const { fleetId, dropOffLocation = "" } = activity;
        let locationMatch = getFence(dropOffLocation);

        if (fleetId !== vehicle?.fleetId) {
          return [];
        }

        return {
          ...activity,
          fenceName: locationMatch,
        };
      })
      .sort(
        (a, b) =>
          dayjsNY(a?.startingTime).valueOf() -
          dayjsNY(b?.startingTime).valueOf()
      );

    let VEHICLE_ALERTS = alerts
      ?.filter(
        ({ deviceSerialNumber }) =>
          deviceSerialNumber === location?.deviceSerialNumber
      )
      .sort((a, b) => a?.alertDateTime - b?.alertDateTime);

    let ACTUAL_TRIPS = getActualActivities(keepRelevantAlerts(VEHICLE_ALERTS));

    if (!!locations?.length) {
      let locationData = {
        fleetId: vehicle?.["fleetId"],
        vinNumber: location?.["vin"],
        fleetName:
          vehicle?.["fleetName"] ??
          `${location?.["firstName"]} ${location?.["lastName"]}`,
        odometerReading: location?.["trueOdo"] ?? location?.["virtualOdo"],
        position: { lat: location?.["latitude"], lng: location?.["longitude"] },
        heading: location?.["heading"],
        direction: location?.["direction"],
        speed: location?.["speed"],
        speeding: location?.["speeding"],
        estSpeedLimit: location?.["estSpeedLimit"],
        fuelLevel: location?.["fuelLevel"],
        imei: location?.["imei"],
        deviceSerialNumber: location["deviceSerialNumber"],
        linxupFleetId: +location?.["fleetId"],
        linxupDriverId: +location?.["driverId"],
        deviceUUID: location?.["deviceUUID"],
        locationStatus: getLocationStatus(
          location?.["status"],
          location?.["speed"]
        ),
      };

      parsedLocations.push(locationData);
    }

    actualTrips[vehicle?.["fleetId"]] = ACTUAL_TRIPS;
    plannedTrips[vehicle?.["fleetId"]] = VEHICLE_ACTIVITIES;
  }

  return {
    parsedLocations,
    actualTrips,
    plannedTrips,
  };
}

/**
 * Function that validates actual trips taken by the truck
 * @param {Array} trips The alerts for the trips of a vehicle
 */
export function validateActualTrips(trips) {
  /**
   * For a single trip to be valid, it need to have two things
   * 1. It needs to have a different code from the previous and the next
   * 2. It needs to have a different location from the previous (if enter alert)
   * and a different location from the next (if exit alert)
   */
  let validation = [];

  for (let i = 0; i < trips?.length; i++) {
    let trip = trips[i];
    let isConflicting = false;
    let prevTrip = trips[i - 1];
    let nextTrip = trips[i + 1];
    let tripAlert = trip["alertCode"].replace("GEOFENCE_", "");
    let tripFence = getFence(trip["fenceName"]);
    if (isYard(trip["fenceName"])) {
      tripFence = "YARD";
    }

    if (prevTrip) {
      let prevAlert = prevTrip["alertCode"].replace("GEOFENCE_", "");
      if (prevAlert === tripAlert) {
        isConflicting = true;
      }

      let prevFence = getFence(prevTrip["fenceName"]);
      if (isYard(prevTrip["fenceName"])) {
        prevFence = "YARD";
      }
      if (isEnteredAlert(tripAlert)) {
        if (prevFence === tripFence) {
          isConflicting = true;
        }
      } else {
        if (prevFence !== tripFence) {
          isConflicting = true;
        }
      }
    }

    if (nextTrip && !isConflicting) {
      let nextAlert = nextTrip["alertCode"]?.replace("GEOFENCE_", "");
      if (nextAlert === tripAlert) {
        isConflicting = true;
      }

      let nextFence = getFence(nextTrip["fenceName"]);
      if (isYard(nextTrip["fenceName"])) {
        nextFence = "YARD";
      }
      if (isEnteredAlert(tripAlert)) {
        if (nextFence !== tripFence) {
          isConflicting = true;
        }
      } else {
        if (nextFence === tripFence) {
          isConflicting = true;
        }
      }
    }

    validation.push({
      alertId: trip["alertUUID"],
      isConflicting,
    });
  }

  return validation;
}
