import uniq from "lodash/uniq";

export const claims = (programFields, updatedDynamicStates, IdHandler) => {
  const statusesOfClaims = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Claims;

  const isEmployee = { Yes: 0, No: 0 };

  (updatedDynamicStates?.[IdHandler] || [])?.forEach((e) => {
    isEmployee[e?.claimObject?.isClaimantAnEmployeeOfCore] += 1;
  });
  return {
    claimStatus: () => ({
      title: "Claim Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Claims",
        field: "claimStatus",
        content: statusesOfClaims?.map((status, index) => ({
          key: index,
          title: status?.statusName,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.claimStatus === status?.statusName
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status?.statusName} Claim`,
          contentsHandler: ` ${status?.statusName} Claims`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),

    claimCategory: () => ({
      title: "Claim Category",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Claims",
        field: "claimCategory",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (e) => e?.claimCategory
          )
        )?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.claimCategory === status
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Claim`,
          contentsHandler: ` ${status} Claims`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
    claimType: () => ({
      title: "Claim Type",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Claims",
        field: "claimType",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map((e) => e?.claimType)
        )?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
          taskDone: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (a) => a?.claimType === status
          )?.length,
          color: "#71CF48",
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Claim`,
          contentsHandler: ` ${status} Claims`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),

    isClaimantAnEmployeeOfCore: () => ({
      title: "Core Employee Claimants",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Claims",
        field: "isClaimantAnEmployeeOfCore",
        content: [
          {
            key: 0,
            title: "Yes",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: isEmployee.Yes,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` Yes `,
            contentsHandler: ` Yes `,
          },
          {
            key: 1,
            title: "No",
            taskNumber: (updatedDynamicStates?.[IdHandler] || [])?.length,
            taskDone: isEmployee.No,
            color: "#71CF48",
            backgroundColor: "#B2DD9B",
            contentHandler: ` No `,
            contentsHandler: ` No `,
          },
        ],
      },
      id: IdHandler,
      width: "1600px",
      isFilter: false,
    }),
  };
};
