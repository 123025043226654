import {
  progressStatuses,
  TypeOfWorkDefinitions,
} from "../../../../SidebarPages/Scheduling/models/TypeOfWorkDefinitions";
import {
  get_ProgressDimensionsByServiceId,
  JUMP_MAXIMAL_PROGRESS,
} from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { breakdown_dimension } from "../PLIPerService/componentsForPli/Breakdown/breakdown";
import _ from "lodash";
import {
  getScheduleDayLinkChain,
  ScheduleDayLinksDirection,
} from "./scheduleDaysFunctions";
import moment from "moment-timezone";
import { estimatedBreakdownsProgress } from "./PliInfoGetters";
import {
  breakdownDays,
  breakInspProgress,
  daysScheduledInServices,
  pliEditedFromNextSchedules,
  preventDuplicateStrings,
  prevPliCompleted,
} from "../SchedulingFirstPage/helperData";
import { message } from "antd";

/**
 * This file contains functions which will set or get variables into items. There are other function around program which may belong
 * to this file but at the time of writing hvae not been moved here.
 * */

export const setBreakDownsInElevationRows_agGrid = (
  data,
  breakdowns,
  serviceId,
  scheduleDays,
  scheduleName,
  typeOfWork
) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
  let dataToReturn = [];
  dataToReturn = data?.map((n) => {
    const isPliCompleted =
      prevPliCompleted(n) ||
      pliEditedFromNextSchedules(n) ||
      (n?.typeOfProgress !== "breakdown" && n?.typeOfProgress !== "");

    if (prevPliCompleted(n)) {
      message.warning(
        ` PLI ${n?.id} is completed. You can't edit it anymore.`,
        5
      );
    } else if (pliEditedFromNextSchedules(n)) {
      message.warning(
        ` PLI ${n?.id} is edited in ${n?.editedFrom?.scheduleName}. You can't edit it!`,
        5
      );
    } else if (n?.typeOfProgress !== "breakdown" && n?.typeOfProgress !== "") {
      message.warning(
        ` PLI ${n?.id} is not in breakdown mode. This action will not be executed and will be ignored by the system!`,
        5
      );
    }

    n.breakdownValue = isPliCompleted ? n?.breakdownValue : breakdowns;

    n.totalProgress = isPliCompleted
      ? n?.totalProgress
      : n?.breakdownValue?.every(
          ({ progressByDay = [] }) => progressByDay?.length === 0
        )
      ? 0
      : n?.breakdownValue
          ?.map?.(({ progressByDay = [], rate }) =>
            breakInspProgress("Installation", progressByDay, rate)
          )
          ?.reduce?.((a, b) => a + b, 0) *
        (d2 === undefined ? n?.[d1] / 100 : (n?.[d1] * n?.[d2]) / 100);
    n.days = isPliCompleted ? n?.days : breakdownDays(n?.breakdownValue);
    // preventDuplicateStrings([
    //     // ...(n?.days || []),
    //     ...breakdownDays(n?.breakdownValue),
    //   ]);
    n.usedScheduleDays = isPliCompleted
      ? n?.usedScheduleDays
      : daysScheduledInServices(n, scheduleDays, scheduleName, "");
    n.typeOfProgress = isPliCompleted
      ? n?.typeOfProgress
      : n?.breakdownValue?.every(
          ({ progressByDay }) => progressByDay?.length === 0
        )
      ? ""
      : "breakdown";
    return n;
  });

  return dataToReturn;
};

/**
 * Crucial function. This will set dimensions and totalProgress in pli or service addon. It is a action function used by
 * iterateServicesInPLILevel as actionFunction. Read there more about function parameters
 * */
export const setForSchedulePli = ({ pli, serviceAddon, variablesNeeded }) => {
  const { typeOfWork, indexes } = variablesNeeded;
  let { d1, d2 } = get_ProgressDimensionsByServiceId(indexes.serviceId);

  if (serviceAddon) {
    //if we are in using serviceAddon instead of pli make serviceAddon look like pli
    pli = serviceAddon;
    d1 = "addon_progress_dimension"; //addon have only one "dimension" for progress which is from 0 to 100. It is referred as percentage
    d2 = undefined;
  }

  if (pli.addons) {
    //execute setForScheduleItem for all addons inside it
    // console.log(pli.addons.map(a => a.addon_progress_dimension + "---" + a.totalProgress))
    for (let a = 0; a < pli?.addons?.length; a++) {
      setForScheduleItem({
        pli: pli.addons[a],
        variablesNeeded,
        d1: "addon_progress_dimension",
      });
    }
    // console.log(pli.addons.map(a => a.addon_progress_dimension + "---" + a.totalProgress))
  }
  if (pli.breakdownValue) {
    //also for breakdowns
    // console.log(pli.addons.map(a => a.addon_progress_dimension + "---" + a.totalProgress))
    for (let b = 0; b < pli.breakdownValue.length; b++) {
      if (isNaN(pli.breakdownValue[b][breakdown_dimension]))
        pli.breakdownValue[b][breakdown_dimension] = 100; //for breakdown as progress dimension we count from 0 to 100%
      setForScheduleItem({
        pli: pli.breakdownValue[b],
        variablesNeeded,
        d1: breakdown_dimension,
      });
    }
    // console.log(pli)
    // console.log(pli.breakdownValue.map(b => b[breakdown_dimension] + "---" + b.totalProgress))
  }

  setForScheduleItem({ pli, serviceAddon, variablesNeeded, d1, d2 });
};

/**
 * This function will set dimensions for schedule depending on type of work. Also it will calculate a new field for 2D
 * items which is the surface.
 * */
const setForScheduleItem = ({ pli, variablesNeeded, d1, d2 }) => {
  const { typeOfWork, indexes } = variablesNeeded;
  !!d1 && !!d2;

  const { history = [], originalPli, surface } = pli || {};

  if (d2 !== undefined) {
    //calculate surface if we are in 2D
    if (pli["surface"] === undefined)
      //if schedule is happening for the first time, which means services are taken from project.services not from project. project.scheduleRemaining
      pli["surface"] = parseInt(pli[d1]) * parseInt(pli[d2]);
  }

  if (typeOfWork === TypeOfWorkDefinitions?.INSTALLATION?.label) {
    //set dimensions that can be scheduled
    setForSchedule({ pli, d1, d2, action: "minus" });
  } else if (typeOfWork === TypeOfWorkDefinitions?.REMOVAL?.label) {
    setForSchedule({ pli, d1, d2, action: "minus" });
  } else if (typeOfWork === TypeOfWorkDefinitions?.REPAIR?.label) {
    setForSchedule({ pli, d1, d2, action: "equal" });
  } else if (typeOfWork === TypeOfWorkDefinitions.INSPECTION.label) {
    setForSchedule({ pli, d1, d2, action: "minus" });
  } else if (typeOfWork === TypeOfWorkDefinitions["PICK UP MATERIAL"].label) {
    setForSchedule({ pli, d1, d2, action: "minus" });
  } else if (typeOfWork === TypeOfWorkDefinitions["DELIVER MATERIAL"].label) {
    setForSchedule({ pli, d1, d2, action: "minus" });
  } else if (typeOfWork === TypeOfWorkDefinitions.MAINTENANCE.label) {
    setForSchedule({ pli, d1, d2, action: "minus" });
  }

  pli["totalProgress"] = 0; //set progress to 0

  // if (
  //   d2 &&
  //   pli["surface"] !==
  //     parseInt(pli.originalPli[d1]) * parseInt(pli.originalPli[d2])
  // ) {
  //   //if we are not for the first time scheduling this project and there have some changes in dimensions from progress.
  //   //if so make dimensions undefined as we really don't know how much is done
  //   //   const hasConstantValues = (previous=[], current=[]) =>
  //   //     !previous.length ||
  //   //     !current.length ||
  //   //     current.some(
  //   //       (val, idx) => val === previous[idx] && !isNaN(parseInt(val))
  //   //     )

  //   //   const getRemainingOfDimension = (previous=[] , current=[] ) => {
  //   //     return !!hasConstantValues(previous, current)
  //   //       ? current.map((curr, idx) =>
  //   //           curr === previous[idx] ? +curr : +previous[idx] - +curr
  //   //         )
  //   //       : ["-", "-"]
  //   //   }

  //   //   const formatHistoryPliRemains = (pliObj={}) => {
  //   //     const { progressByDay = [] } = pliObj
  //   //     const isEven = (nr) => !(nr % 2)

  //   //     const [odds = [], evens = []] = progressByDay.reduce(
  //   //       (acc, curr, idx) =>{
  //   //         acc[+isEven(idx)].push([curr[d1], curr[d2]])
  //   //         return acc
  //   //       },
  //   //       [[] , []]
  //   //     )

  //   //     const hasPliConstantValues = odds.every((dimensions, idx) => {
  //   //       const nextDimensions = evens[idx]
  //   //       const previousDimensions = evens[idx - 1]
  //   //       const mergedDimensions = [nextDimensions, previousDimensions]
  //   //       const isBetween = !!nextDimensions && !!previousDimensions
  //   //       const whichToCompare = nextDimensions || previousDimensions

  //   //       return isBetween
  //   //         ? mergedDimensions.every((arrDimensions) =>
  //   //             hasConstantValues(dimensions, arrDimensions)
  //   //           )
  //   //         : hasConstantValues(dimensions, whichToCompare)
  //   //     })

  //   //     const latestProgressObj= progressByDay.at(-1)

  //   //     const latestDimensions = [latestProgressObj?.[d1], latestProgressObj?.[d2]]

  //   //     return !!hasPliConstantValues
  //   //       ?  latestDimensions
  //   //       : ["-", "-"]
  //   //   }

  //   // const getRemains = (historyArr = [], originalDimensions) =>
  //   //   historyArr.reduce((acc, curr) => {
  //   //     if (acc.every((dimension) => !isNaN(+dimension)) && !!curr) {
  //   //       const { progressByDay = [] } = curr;

  //   //       const groupedProgress = progressByDay.reduce(
  //   //         (progressAcc, item) => {
  //   //           progressAcc[0].push(item[d1]);
  //   //           progressAcc[1].push(item[d2]);

  //   //           return progressAcc;
  //   //         },
  //   //         [[], []]
  //   //       );

  //   //       const [d1Progress, d2Progress] = groupedProgress;

  //   //       const constantDimension = [
  //   //         _.uniq(d1Progress),
  //   //         _.uniq(d2Progress),
  //   //       ].find((ds, idx) => ds.length === 1 && ds?.[0] === acc?.[idx])?.[0];

  //   //       const constantDimensionIdx = acc.indexOf(constantDimension);
  //   //       const mutatedDimensionsIdx = +!constantDimensionIdx;

  //   //       const mutatedDimensionsSum = groupedProgress[
  //   //         mutatedDimensionsIdx
  //   //       ].reduce((sumAcc, value) => (sumAcc += value), 0);

  //   //       if (!!constantDimension)
  //   //         [acc[constantDimensionIdx], acc[mutatedDimensionsIdx]] = [
  //   //           constantDimension,
  //   //           acc[mutatedDimensionsIdx] - mutatedDimensionsSum,
  //   //         ];
  //   //       else acc = ["-", "-"];
  //   //     }
  //   //     return acc;
  //   //   }, originalDimensions);

  //   // [pli[d1], pli[d2]] = !surface
  //   //   ? [0, 0]
  //   //   : getRemains(history, [originalPli[d1], originalPli[d2]]);

  //   //   ;[pli[d1], pli[d2]] = getRemainingOfDimension(
  //   //     formatHistoryPliRemains(secondToLastPli || {progressByDay: [{...originalPli}]}),
  //   //     formatHistoryPliRemains(latestPli)
  //   //   )
  // }
};

/**
 * This function will set dimensions and surface fot 2D items by adding or subtracting the existing progress
 * @param pli {Object} - a pli, addon or breakdown
 * @param d1 {length|height|width} - a dimension which progress is measured
 * @param d2 {length|height|width|undefined} - the second dimension by which progress is measured (if applicable depending on service type)
 * @param action {'minus'|'equal'} - what we want to do
 * */
export const setForSchedule = ({ pli, d1, d2, action }) => {
  const pliTotalProgress = parseInt(pli["totalProgress"]) || 0;
  // console.log(pli)
  if (action === "minus") {
    if (d2 !== undefined) {
      pli["surface"] -= pliTotalProgress;
    } else {
      pli[d1] -= pliTotalProgress;
    }
  } else {
    if (d2 !== undefined) {
      pli["surface"] = pli["totalProgress"];
      pli["totalProgress"] = 0; // ( parseInt(pli.originalPli[d1]) * parseInt(pli.originalPli[d2]) ) - pli["surface"]
    } else {
      pli[d1] = pli["totalProgress"] || 100;
      pli["totalProgress"] = 0;
    }
  }
  // console.log(pli)
};

/**
 * This function is used when postponing days and we want to replace old days with new days.
 * */
// export const replaceDaysInItem = ({ pli, serviceAddon, variablesNeeded }) => {
//   let { scheduleDays, replacementType, scheduleDaysToBeReplaced } =
//     variablesNeeded;

//   if (serviceAddon) {
//     pli = serviceAddon;
//   }

//   if (pli.addons) {
//     //if this item has addons replace days in them too
//     // console.log(pli.addons.map(a => a.addon_progress_dimension + "---" + a.totalProgress))
//     for (let a = 0; a < pli.addons.length; a++) {
//       replaceDaysInItem({ pli: pli.addons[a], variablesNeeded });
//     }
//     // console.log(pli.addons.map(a => a.addon_progress_dimension + "---" + a.totalProgress))
//   }
//   if (pli.breakdownValue) {
//     // console.log(pli.addons.map(a => a.addon_progress_dimension + "---" + a.totalProgress))
//     for (let b = 0; b < pli.breakdownValue.length; b++) {
//       replaceDaysInItem({ pli: pli.breakdownValue[b], variablesNeeded });
//     }
//     // console.log(pli)
//     // console.log(pli.breakdownValue.map(b => b[breakdown_dimension] + "---" + b.totalProgress))
//   }
//   const PLIDAYS = pli?.days?.filter((el) =>
//     scheduleDays?.some((s) => s?.id === el)
//   );

//   if (PLIDAYS) {
//     for (let i = 0; i < PLIDAYS?.length; i++) {
//       //if we have an array of specified days we want to replace, check if this day belongs to days we want to replace. if not skip it
//       if (
//         scheduleDaysToBeReplaced !== undefined &&
//         !scheduleDaysToBeReplaced.map((sch) => sch.id).includes(PLIDAYS[i])
//       )
//         continue;

//       //find which is this day in Schedule Days list
//       const scheduleDayInPLi = scheduleDays?.find(
//         (sch) => sch?.id === PLIDAYS[i]
//       );

//       if (scheduleDayInPLi === undefined)
//         throw new Error(
//           `Day with id ${PLIDAYS[i]} found in pli ${JSON.stringify(
//             variablesNeeded.indexes
//           )} was not found in schedule days given: ${scheduleDays.toString()}`
//         );

//       if (replacementType === undefined)
//         replacementType = DayReplacementType.LastPostponedDay;

//       let dayToReplaceTheOtherDay;

//       if (replacementType === DayReplacementType.LastPostponedDay) {
//         //get the most recent postponed day that it has
//         const postponedDaysArray = getScheduleDayLinkChain(
//           scheduleDays,
//           [scheduleDayInPLi],
//           ScheduleDayLinksDirection.PostponedTo
//         );
//         // console.log(postponedDaysArray)
//         dayToReplaceTheOtherDay =
//           postponedDaysArray?.[postponedDaysArray?.length - 1];
//       } else if (replacementType === DayReplacementType?.LastComesFromDay) {
//         dayToReplaceTheOtherDay =
//           scheduleDayInPLi.findWhereComesFrom(scheduleDays);
//       }

//       if (dayToReplaceTheOtherDay) {
//         //if we have a day to replace, do it
//         // console.log("replaceing day " + dayToReplaceTheOtherDay.day, scheduleDayInPLi.day)
//         const dayProgressData = pli?.progressByDay?.find(
//           (pData) => pData?.day === PLIDAYS[i]
//         );

//         if (dayProgressData) {
//           dayProgressData.day = dayToReplaceTheOtherDay?.id;
//           return dayProgressData;
//         }
//         PLIDAYS[i] = dayToReplaceTheOtherDay.id;
//         return PLIDAYS;
//       } else {
//         console.log("Day to replace was not found in list.", {
//           scheduleDays,
//           scheduleDayInPLi,
//           replacementType,
//         });
//       }
//     }

//     // if(pli.days.length > 0)
//     // console.log(pli.days)
//   }
// };

export const DayReplacementType = {
  LastPostponedDay: "LastPostponedDay",
  LastComesFromDay: "LastComesFromDay",
};

//functions for hoist start

export const defaultHoistInitialPhaseFloorsCount = 4;
export const defaultHoistFloorsForJumpCount = 2;

/**
 * Function which generate jumps by a given logic. In program use is able to change floor config in jumps.
 * */
export const defaultHoistJumpSplit = (items) => {
  const jumps = [];
  let currentJumpNumber = 1;
  let currentFloorJump = 1; //it will max go as defaultHoistFloorsForJumpCount

  for (const item of items) {
    const { floor, stop } = item;

    if (parseInt(floor) <= defaultHoistInitialPhaseFloorsCount) {
      //floor should be in initial phase

      const existingJump = jumps.find((j) => j.name === "Initial Phase");

      if (existingJump) {
        //if this jump have been created
        existingJump.floors.push({ floor, stop });
      } else {
        //if it the first time
        jumps.push({ name: "Initial Phase", floors: [{ floor, stop }] });
      }
    } else {
      //we are in jumps phase

      if (currentFloorJump === defaultHoistFloorsForJumpCount) {
        //if we have reached the maximum stack increase jump number
        currentJumpNumber++;
        currentFloorJump = 1;
      }

      const existingJump = jumps.find(
        (j) => j.name === "Jump " + currentJumpNumber
      );

      if (existingJump) {
        //if this jump have been created
        existingJump.floors.push({ floor, stop });
        currentFloorJump++;
      } else {
        //if it the first time
        jumps.push({
          name: "Jump " + currentJumpNumber,
          floors: [{ floor, stop }],
        });
      }
    }
  }

  return jumps;
};

export const setBreakdownsToFloors = (items, breakdowns) => {
  const initialBreakdowns = [];
  const jumpsBreakdowns = [];

  for (let i = 0; i < breakdowns.length; i++) {
    if (i <= 2) {
      initialBreakdowns.push(breakdowns[i]);
    } else {
      jumpsBreakdowns.push(breakdowns[i]);
    }
  }

  for (const item of items) {
    const { floor } = item;

    if (parseInt(floor) <= defaultHoistInitialPhaseFloorsCount) {
      //floor should be in initial phase
      item.breakdownValue = initialBreakdowns;
    } else {
      //we are in jumps phase
      item.breakdownValue = jumpsBreakdowns;
    }
  }
};

//set progress of all days to 0 and total progress to 0
export const setItemEmptyProgress = (item, { d1, d2 }) => {
  for (let i = 0; i < item.progressByDay.length; i++) {
    item.progressByDay[i][d1] = 0;
    if (d2) {
      item.progressByDay[i][d2] = 0;
    }
  }
  item.totalProgress = 0;
};

export const getItemSumPrevToThisDayIndex = (
  progressByDay,
  dayIndex,
  { d1, d2 }
) => {
  let sum = 0;
  for (let i = 0; i < dayIndex; i++) {
    if (d2) {
      sum += parseInt(progressByDay[i][d1]) * parseInt(progressByDay[i][d2]);
    } else {
      sum += progressByDay[i][d1];
    }
  }
  return sum;
};

/**
 * After getting progress that is done previews to the given day. It is used when clicking "complete progress in this day"
 * button. So for example if the previews progress has been 20%, after clicking the button of that day, progress of that day will be 80%
 * */
export const setProgress100ToItem = (item, dayIndex, d1) => {
  let prevDaysProgress = getItemSumPrevToThisDayIndex(
    item.progressByDay,
    dayIndex,
    { d1 }
  );

  item.progressByDay[dayIndex][d1] = item[d1] - prevDaysProgress;

  for (let i = dayIndex + 1; i < item.progressByDay.length; i++) {
    item.progressByDay[i][d1] = 0;
  }
  item.totalProgress = item[d1];
};

export const getJumpStatus = (items) => {
  let completed = 0;

  for (const item of items) {
    if (item.totalProgress === JUMP_MAXIMAL_PROGRESS) {
      //100 is maximal progress, which means completed
      completed++;
    }
  }

  if (completed === 0) return progressStatuses[0];
  if (completed > 0 && completed < items.length) return progressStatuses[1];
  return progressStatuses[2];
};

export const getJumpAverage = (items, key, typeOfWork) => {
  for (const item of items) {
    completed += Math.abs(item[key]);
  }
  return Math.abs(Math.round(completed / items?.length));
};

export const setInItems = (items, variablesToSetObject) => {
  for (const item of items) {
    for (const [key, value] of Object.entries(variablesToSetObject)) {
      item[key] = _.cloneDeep(value);
    }
  }
};

export const findJump = (jumps, floor) => {
  return jumps?.find((j) => j?.floors?.map((f) => f?.floor)?.includes(floor));
};
export const hasItemYetToSchedule = (item, serviceId) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
  if (d2 === undefined) {
    return item?.totalProgress !== item[d1];
  } else {
    return item?.totalProgress !== item?.surface;
  }
};
