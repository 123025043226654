import uniq from "lodash/uniq";

export const citations = ({ tables, programFields }) => {
  const citationStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    citationStatus: () => ({
      content: citationStatus?.map((status) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Safety?.filter(
            (e) => e?.citationStatus === status?.statusName
          ).length /
            tables?.Citations?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: status?.statusName,
        done: tables?.Citations?.filter(
          (e) => e?.citationStatus === status?.statusName
        )?.length,
      })),
    }),

    citationName: () => ({
      content: uniq(tables?.Citations?.map((safety) => safety?.citationName))
        ?.filter(Boolean)
        ?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Citations?.filter((e) => e?.citationName === status)
              ?.length /
              tables?.Citations?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.Citations?.filter((e) => e?.citationName === status)
            ?.length,
        })),
    }),

    employeeName: () => ({
      content: uniq(
        tables?.Citations?.map(
          (citation) => citation?.citationObject?.employeeName
        )
      )?.map((a) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: (
          (tables?.Citations?.filter(
            (e) => e?.citationObject?.employeeName === a
          )?.length /
            tables?.Citations?.length) *
          100
        )?.toFixed(1),
        showInfo: true,
        status: a,
        done: tables?.Citations?.filter(
          (e) => e?.citationObject?.employeeName === a
        )?.length,
      })),
    }),
  };
};
