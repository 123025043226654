import { exportToXLSX } from "../../../../../../../../Applications/ApplicationView/components/exports/exportToXLSX";
import { useStateContext } from "../../../../../../Context/Context";
import { Excel } from "../../../../../../../../../../BasePage/src";
import { exportToExcelData } from "./exportToExcelFunctions";
import "./ExportToExcel.scss";
import { message, Tooltip } from "antd";
import { API } from "aws-amplify";
import * as FileSaver from "file-saver";
import { b64toBlob } from "../../../../../../../../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";

const ExportToExcel = () => {
  // Context state & dispatch
  const { fakeSOV } = useStateContext();

  const workSheetDataExcel = exportToExcelData({ fakeSOV });

  const handleExport = async () => {
    let fileName = `${fakeSOV?.projectName || "AIA_Application"}__SOV${
      fakeSOV?.SOVNo || ""
    }`;
    const hideLoad = message.loading("Exporting to Excel...", 0);
    console.log("workSheetDataExcel", workSheetDataExcel);
    await API.post("exportSoV", "/exportSoV", {
      body: {
        data: { workSheetDataExcel },
        name: fileName,
      },
    }).then((response) => {
      console.log("response", response);
      let blob = b64toBlob(
        response.body,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      FileSaver.saveAs(blob, fileName);
      hideLoad();
      message.success("Export successful.");
    });
    // exportToXLSX(fileName, "", {
    //   sovData: { workSheetDataExcel },
    // });
  };

  return (
    <>
      <Tooltip title="Export to Excel">
        <button className="excelButtonSOV" onClick={handleExport}>
          <Excel />
        </button>
      </Tooltip>
    </>
  );
};

export default ExportToExcel;
