import uniq from "lodash/uniq";
import { getRandomColor } from "../../../../utils";

export const citations = ({ tables, programFields }) => {
  const citationStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  const employeeNames = uniq(
    tables?.Citations?.map((e) => e?.citationObject?.employeeName)
  );

  return {
    citationStatus: () => ({
      labels: citationStatus?.map(({ statusName }) => statusName),
      data: citationStatus?.map(
        ({ statusName }) =>
          tables?.Citations?.filter((e) => e?.citationStatus === statusName)
            ?.length
      ),
      backgroundColor: programFields
        ?.find((e) => e?.fieldName === "Status colors")
        ?.fieldOptions?.Citations?.map((e) => e.statusColor),
    }),

    citationName: () => ({
      labels: uniq(tables?.Citations?.map((e) => e?.Citations)),
      data: tables?.Citations?.map(
        (a) =>
          tables?.Citations?.filter((e) => e?.Citations === a?.citationName)
            ?.length
      ),
      backgroundColor: uniq(tables?.Citations?.map(() => getRandomColor())),
    }),

    employeeName: () => ({
      labels: employeeNames,
      data: employeeNames?.map(
        (a) =>
          tables?.Citations?.filter(
            (e) => e?.citationObject?.employeeName === a
          )?.length
      ),
      backgroundColor: uniq(tables?.Safety?.map(() => getRandomColor())),
    }),
  };
};
