import React, { useEffect, useMemo, useState } from "react";
import HeaderStatistics from "./HeaderStatistics/HeaderStatistics";
import "./styles/WaitingRoomProposal.scss";
import BodyStatistics from "./BodyStatistics/BodyStatistics";
import HeaderFilter from "./HeaderFilter/HeaderFilter";
import { fetchData } from "../../SidebarPages/Fleet/utils";
import { SmallLoadableComp } from "../../Sidebars/components";
import ScrollableContainer from "../../pages/ScheduleProgress/Components/ForemanProject/ScrollableContainer/ScrollableContainer";
import ProposalCard from "./ProposalCards/ProposalCard";
function WaitingRoomProposal() {
  const [rowData, setRowData] = useState([]);
  console.log("WaitingRoomProposal ~ rowData:", { rowData });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    jobSiteAddress: undefined,
    proposalStatus: undefined,
  });

  function handleFilterChange(key, value) {
    if (key === "reset") {
      setFilters({
        jobSiteAddress: undefined,
        proposalStatus: undefined,
      });
    } else {
      setFilters({
        ...filters,
        [key]: value,
      });
    }
  }

  const filteredCards = useMemo(() => {
    if (!filters?.jobSiteAddress && !filters?.proposalStatus) {
      return rowData;
    }
    return (
      rowData?.filter((item) => {
        return (
          item?.projectId?.includes(filters?.jobSiteAddress || "") &&
          item?.proposalStatus.includes(filters?.proposalStatus || "")
        );
      }) || []
    );
  }, [rowData, filters]);

  useEffect(() => {
    setLoading(true);
    fetchData("proposalBuilds")
      .then((data) => {
        setRowData(data?.sort((a, b) => b?.createdAt - a?.createdAt));
        setLoading(false);
      })
      .catch((error) => {
        console.error("WaitingRoomProposal ~ fetchData ~ error", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="waiting-room-proposal">
      <div className="waiting-room-proposal_header-statistics">
        <HeaderStatistics />
      </div>
      <div className="waiting-room-proposal_filter-header">
        <HeaderFilter
          {...{
            handleFilterChange,
            filters,
            rowData,
          }}
        />
      </div>
      <SmallLoadableComp loading={loading}>
        <div className="waiting-room-proposal_body-statistics">
          <BodyStatistics
            {...{
              rowData: filteredCards,
            }}
          />
        </div>
        <ScrollableContainer
          {...{
            bodyClassName: "proposal-slider-body",
            transparentArrows: true,
            scrollValue: 300,
            wheelScrollSpeed: 200,
          }}
        >
          {filteredCards.map((item, index) => {
            return (
              <ProposalCard
                key={index}
                title={item?.proposalStatus ?? item?.otherDetails?.title}
                item={item}
              />
            );
          })}
        </ScrollableContainer>
      </SmallLoadableComp>
    </div>
  );
}

export default WaitingRoomProposal;
