export const REDUCER_INIT = {
  employeesHoursPerDay: {},
  employeeIncorrectShifts: {},
  employeeOver24HShift: {},
  employeeOverheadShifts: {},
  employeeWeekTotals: {},
  employeeNotFoundLocations: [],
  dateRange: [],
  shiftsWithMultipleJobsites: [],
  missingLunchEntries: [],
  generalOverheadTotal: 0,
};

function ANALYTICS_REDUCER(state, request) {
  let tmpNewObj = {
    ...state,
    generalOverheadTotal: 0,
  };

  let { employeeList, ...action } = request;
  if (request?.reset) {
    const defaultState = {
      employeesHoursPerDay: {},
      employeeIncorrectShifts: {},
      employeeOver24HShift: {},
      employeeOverheadShifts: {},
      employeeWeekTotals: {},
      employeeNotFoundLocations: [],
      dateRange: [],
      shiftsWithMultipleJobsites: [],
      missingLunchEntries: [],
      generalOverheadTotal: 0,
    };
    Object.assign(state, defaultState);
    Object.assign(REDUCER_INIT, defaultState);
    return defaultState;
  }
  for (const analytic in action) {
    switch (analytic) {
      case "employeeNotFoundLocations":
        let locationSet = new Set([
          ...(employeeList ? state[analytic] : []),
          ...action[analytic],
        ]);
        let tmp = [];
        locationSet.forEach((location) => {
          tmp.push(location);
        });
        tmpNewObj[analytic] = tmp;
        break;
      case "employeeIncorrectShifts":
        for (const employeeId in action[analytic]) {
          tmpNewObj[analytic][employeeId] = [...action[analytic][employeeId]];
        }
        break;
      case "dateRange":
        tmpNewObj[analytic] = action[analytic];
        break;
      case "shiftsWithMultipleJobsites":
        tmpNewObj[analytic] = action[analytic];
        break;
      case "missingLunchEntries":
        tmpNewObj[analytic] = action[analytic];
        break;
      case "employeeOverheadShifts":
        tmpNewObj[analytic] = action[analytic];
        break;
      case "generalOverheadTotal":
        tmpNewObj[analytic] = action[analytic];
        break;
      default:
        tmpNewObj[analytic] = {
          ...(employeeList ? state[analytic] || {} : {}),
          ...action[analytic],
        };
    }
  }
  return tmpNewObj;
}

export default ANALYTICS_REDUCER;
