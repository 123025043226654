import "./NotesIconContent.scss";
import { useSelector } from "react-redux";
import { CheckAccessRights } from "../../../../../utils";
import WithTooltip from "../../../WithTooltip/WithTooltip";
import { Badge, message } from "antd";
import IconButton from "../../../Buttons/IconButton";

const NotesIconContent = ({
  rowNotes,
  topicCategory,
  tooltipCategory,
  openModal = () => {},
  customIcon,
  showBadge,
  disableNotes,
  isGrayButton = false,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const noteAccesses = userConfiguration?.routeConfig?.find(
    ({ title }) => title === "Notes/View"
  )?.children;

  const iconContent = (
    <div
      className={`icon-content ${isGrayButton ? "grey-color" : ""}`}
      onClick={(e) => {
        if (disableNotes) {
          message.info("Notes are disabled!");
        } else {
          e.stopPropagation();

          CheckAccessRights(noteAccesses, topicCategory)
            ? openModal(true)
            : message.error("You don't have access to view notes");
        }
      }}
    >
      {customIcon ? (
        <span className="custom-icon">{customIcon}</span>
      ) : (
        <span style={{ color: "#1264A3", fontWeight: "600" }}>Notes</span>
      )}
    </div>
  );

  return (
    <div className="notes-icon-content">
      <WithTooltip {...{ tooltipCategory, tooltipKey: "showNotes" }}>
        {showBadge ? (
          <Badge count={rowNotes.length || 0}>{iconContent}</Badge>
        ) : (
          iconContent
        )}
      </WithTooltip>
    </div>
  );
};

export default NotesIconContent;
