import { useState } from "react";
import { HistoryOutlined } from "@ant-design/icons";
import { MondayButton } from "../../../../../../commonComponents";
import "./Logs.scss";
import Modal from "./Modal";

const Logs = ({ category, videoName, name, title, noTextButton = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        onClick={() => setIsOpen(true)}
        Icon={<HistoryOutlined />}
      >
        {noTextButton ? "" : "Logs"}
      </MondayButton>
      {isOpen && (
        <Modal
          visible={isOpen}
          setVisible={setIsOpen}
          title={title}
          videoName={videoName}
          logsCategory={category}
          logsName={name}
        />
      )}
    </>
  );
};

export default Logs;
