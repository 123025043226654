import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteFilled,
  DownSquareFilled,
  EditOutlined,
  RetweetOutlined,
  UpSquareFilled,
} from "@ant-design/icons";
import { Input, List, Avatar } from "antd";
import React from "react";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import { MondayButton } from "../../../../../commonComponents";
import { NormalSizedModal } from "../../../../../SidebarPages/Fleet/components";
import PageRangePicker from "../PageRangePicker/PageRangePicker";
import "./PDFCard.scss";

const PDFCard = ({
  fileName,
  onMoveUp,
  onMoveDown,
  onDelete,
  onReplace,
  file,
  key,
  configPages,
  setCustomConfigPages,
}) => {
  const truncateFileName = (name) => {
    return name.length > 15 ? name.substring(0, 15) + "..." : name;
  };

  const [showNameInput, setShowNameInput] = useState(false);
  const [tempName, setTempName] = useState(
    configPages?.[file?.uid]?.customName || fileName
  );

  const onChangeName = (e) => {
    if (!e.target.value || fileName === e.target.value) {
      setShowNameInput(false);
      return;
    }
    console.log("new name", e.target.value);
    let tempConfig = configPages;
    if (!tempConfig[file.uid])
      tempConfig[file.uid] = {
        pagesArr: [],
        pagesInfo: "",
        customName: "",
      };
    tempConfig[file.uid].customName = e.target.value;
    setCustomConfigPages(tempConfig);
    setShowNameInput(false);
  };

  console.log("PDFCard", { file, configPages });
  return (
    <div className="pdf-card" key={key}>
      <Document className={"pdfCardCanvas"} file={file.thumbUrl}>
        <Page pageNumber={1} />
      </Document>
      <div style={{ zIndex: 100 }} className="pdf-card__thumbnail">
        <div className="pdf-card__buttons">
          <button
            title="Move Up"
            className="pdf-card__button--up"
            onClick={() => onMoveUp(file)}
          >
            <ArrowUpOutlined />
          </button>
          <button
            title="Move Down"
            className="pdf-card__button--down"
            onClick={() => onMoveDown(file)}
          >
            <ArrowDownOutlined />
          </button>
          <button
            title="Delete"
            className="pdf-card__button--delete"
            onClick={() => onDelete(file)}
          >
            <DeleteFilled />
          </button>
          <button className="pdf-card__button--replace">
            {/* <EditOutlined /> */}
            <PickCustomPages
              {...{
                setCustomConfigPages,
                configPages,
                file,
                id: file?.uid || "1324",
                fileType: file?.type !== "local" ? "drive" : "local",
              }}
            />
          </button>
        </div>
      </div>
      <div
        className="pdf-card__filename"
        onClick={() => setShowNameInput(true)}
      >
        {" "}
        {showNameInput ? (
          <Input
            type="text"
            value={tempName}
            onChange={(e) => setTempName(e.target.value)}
            onBlur={onChangeName}
          />
        ) : (
          truncateFileName(tempName)
        )}
      </div>
    </div>
  );
};

export default PDFCard;
const PickCustomPages = ({
  setCustomConfigPages,
  file,
  id,
  configPages,
  fileType = "local",
}) => {
  const [active, setActive] = useState(false);
  const [toRender, setToRender] = useState();
  console.log("PickCustomPages", file);

  const onFirstOpen = () => {
    setActive(true);
    if (fileType === "local") {
      getFileAsBase64(file.originFileObj)
        .then((base64String) => {
          console.log("myFile", base64String);
          const toReturn = b64toBlob(base64String, "application/pdf");
          const blobURL = URL.createObjectURL(toReturn);
          setToRender(blobURL);
        })
        .catch((error) => {
          console.error("Error reading file:", error);
        });
    } else {
      const blobURL2 = file?.blob;
      setToRender(blobURL2);
    }
  };

  const onConfirm = ({ pagesArr, pagesInfo = "" }) => {
    console.log("logu", {
      customElements,
    });
    setCustomConfigPages((prev) => ({
      ...(prev || {}),
      [id]: {
        pagesArr,
        pagesInfo,
      },
    }));
    setActive(false);
  };

  return (
    <>
      {" "}
      <span
        title="Pick Custom Pages"
        style={{
          display: "flex",
          cursor: "pointer",
          //   color: "#1264A3",
          alignItems: "center",
        }}
        onClick={onFirstOpen}
      >
        <EditOutlined />
      </span>
      <NormalSizedModal
        {...{
          open: active,
          setOpen: setActive,
          onCancel: () => setActive(false),
          title: "Pick Custom Pages",
          closable: true,
          centered: true,
        }}
      >
        <div
          style={{
            width: "1200px",
            height: "800px",
            // height: "500px",
            // height: "800px",
          }}
        >
          <PageRangePicker
            onConfirm={onConfirm}
            configPages={configPages}
            file={file}
          />
          <br />
          {toRender && (
            <iframe
              title="PDF Viewer"
              style={{
                width: "100%",
                height: "700px",
              }}
              src={toRender}
            />
          )}
        </div>
      </NormalSizedModal>
    </>
  );
};

const getFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
export const b64toBlob = (content, contentType) => {
  contentType = contentType || "";
  const sliceSize = 512;
  // method which converts base64 to binary
  const byteCharacters = window.atob(content);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
    type: contentType,
  }); // statement which creates the blob
  return blob;
};
