import dayjs from "dayjs";
import { neededDataForService } from "../../components/Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import { quickStatistics_Schedule_and_Project } from "../../components/SidebarPages/Scheduling/helpers/quickStatistics";
import { forceToNumber } from "../../components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const resetTimeToMidnightUTC = (date) => {
  date.setUTCHours(0, 0, 0, 0);
  return date.getTime();
};

const calculateTotalRent = (estimationsFound, removalProgress) => {
  const rentalsPerService = (estimationsFound?.services || []).map(
    (service) => {
      const { progress = 0 } =
        removalProgress?.find(
          (removal) => removal.serviceId === service.serviceId
        ) || {};

      const rentPerService = service.serviceOptions[0].reduce(
        (optionAcc, option) => {
          const itemsRent = (option.items || []).reduce(
            (itemAcc, item) => itemAcc + (forceToNumber(item.rent) || 0),
            0
          );

          const remainingRent = ((100 - progress) / 100) * itemsRent;

          return optionAcc + remainingRent;
        },
        0
      );

      return {
        service: service.label,
        rent: rentPerService,
      };
    }
  );

  return rentalsPerService;
};

const calculateDailyRentals = (rental, rentalStartDate) => {
  const dailyRentals = [];

  for (let day = 0; day < 28; day++) {
    const dailyRentAmount = rental.rent / 28;
    const currentDate = new Date(rentalStartDate);
    currentDate.setUTCDate(currentDate.getUTCDate() + day);

    dailyRentals.push({
      dayNumber: day + 1,
      dailyRentAmount,
      date: resetTimeToMidnightUTC(currentDate),
      ...rental,
    });
  }
  return dailyRentals;
};

const generateForecastedRentals = (
  schedule,
  rentalsPerService,
  installedDate
) => {
  const forecastedRentalsArray = [];
  const installedDateObj = new Date(installedDate);
  const rentalInstalledDate = new Date(installedDate);

  for (let i = 0; i < 12; i++) {
    const rentalStartDate = resetTimeToMidnightUTC(installedDateObj);
    installedDateObj.setUTCDate(installedDateObj.getUTCDate() + 28);
    const rentalEndDate = resetTimeToMidnightUTC(installedDateObj);

    rentalsPerService.forEach((rental) => {
      const serviceRental = {
        ...rental,
        rentalNumber: i + 1,
        installedDate: rentalInstalledDate.getTime(),
        rentalAmount: rental.rent,
        rentalStartDate,
        rentalEndDate,
        rentalDays: 28,
        projectExecutive: schedule.projectExecutive,
        projectName: schedule.scheduleAddress,
        scheduleId: schedule.scheduleId,
      };

      const dailyRentals = calculateDailyRentals(
        serviceRental,
        resetTimeToMidnightUTC(installedDateObj)
      );

      const forecastedRental = {
        ...serviceRental,
        dailyRentals,
      };

      forecastedRentalsArray.push(forecastedRental);
    });
  }

  return forecastedRentalsArray;
};

const generateRentalsForEstimation = (
  estimations,
  schedule,
  installedDate,
  removalProgress
) => {
  const foundEstimation = estimations.find(
    (estimation) => estimation.projectId === schedule.projectId
  );

  if (!foundEstimation) return [];

  const takeOffId = Object.keys(foundEstimation.versionServices || {}).find(
    (key) => foundEstimation.versionServices[key].status === "Approved"
  );

  const estimationsFound = foundEstimation?.versionServices?.[takeOffId];

  const rentalsPerService = calculateTotalRent(
    estimationsFound,
    removalProgress
  );
  const forecastedRentals = generateForecastedRentals(
    schedule,
    rentalsPerService,
    installedDate
  );

  return forecastedRentals;
};

function getNewestByAddress(scheduling, typeOfWork) {
  const newestByAddress = scheduling
    .filter((schedule) => schedule.typeOfWork === typeOfWork)
    .reduce((acc, currentSchedule) => {
      const { scheduleAddress, createdAt } = currentSchedule;

      // If the address is not in the accumulator, or the current entry has a newer timestamp, update the entry
      if (!acc[scheduleAddress] || createdAt > acc[scheduleAddress].createdAt) {
        acc[scheduleAddress] = { ...currentSchedule };
      }

      return acc;
    }, {});

  // Convert the object values (newest entries) back to an array
  const newestSchedules = Object.values(newestByAddress);
  return newestSchedules;
}

export const getForecastingRentalsPerService = async () => {
  const [estimationsRes, schedulingRes] = await Promise.all([
    fetchAllData("estimations", "estimations", "estimationId"),
    fetchAllData("scheduling", "scheduling", "scheduleId"),
  ]);

  const estimations = estimationsRes;
  const scheduling = getNewestByAddress(schedulingRes, "Installation");

  const removedScheduling = getNewestByAddress(schedulingRes, "Removal");

  const forecastedRentals = scheduling
    .map((schedule) => {
      const foundRemoval = removedScheduling.find(
        (removalSchedule) =>
          removalSchedule.scheduleAddress === schedule.scheduleAddress
      );

      const removalProgress = Object.keys(foundRemoval?.toBeScheduled || {})
        .map((key) => neededDataForService(foundRemoval.toBeScheduled[key]))
        .flat();

      const periods = schedule?.scheduleDays?.sort(
        (a, b) => dayjs(a?.startDate).valueOf() - dayjs(b?.startDate).valueOf()
      );

      const installedDate = periods?.[0]?.endDate;

      const forecastRentals = generateRentalsForEstimation(
        estimations,
        schedule,
        installedDate,
        removalProgress
      );

      if (schedule?.totalScheduleProgress === 100)
        return forecastRentals.filter(
          (rental) =>
            rental.rentalAmount > 0 &&
            new Date(rental.rentalEndDate) >= new Date()
        );
    })
    .filter(Boolean);

  return forecastedRentals.flat();
};
