import { GlobalOutlined } from "@ant-design/icons";
import { CircleLockIcon } from "../../../../../assets";
import { DobWhite, ThreeStarsIcon } from "../../../../../../../../../../icons";
import {
  LogoutIcon,
  SuspendedUserIcon,
} from "../../../../../../../../../../assets";

function sessionButtons({
  setAllowedIpAddressVisible,
  isSuspended,
  setModalType,
  setDepartmentVisible,
  setPinVisible,
  disable2Fa,
  is2FaEnabled,
  showIpAddressButton,
}) {
  return [
    {
      id: "logoutButton",
      className: "mondayButtonBlue",
      Icon: <LogoutIcon style={{ height: 17, width: 17 }} />,
      onClick: () => setModalType("Logout"),
      text: "Logout All",
      isVisible: true,
    },
    {
      id: "resetPasswordButton",
      className: "mondayButtonBlue",
      Icon: <CircleLockIcon />,
      onClick: () => setModalType("Force Reset Password"),
      text: "Force Reset Password",
      isVisible: true,
    },
    {
      id: "allowedIpButton",
      className: "mondayButtonBlue",
      Icon: <GlobalOutlined />,
      onClick: () => setAllowedIpAddressVisible(true),
      text: "Allowed IP Addresses",
      isVisible: showIpAddressButton,
    },
    {
      id: "changeDepartmentButton",
      onClick: () => {
        setDepartmentVisible(true);
      },
      Icon: <DobWhite fill={"#fff"} />,
      className: "mondayButtonBlue",
      text: "Change Department",
      isVisible: true,
    },
    {
      id: "changePinButton",
      onClick: () => {
        setPinVisible(true);
      },
      className: "mondayButtonBlue",
      Icon: <ThreeStarsIcon width={18} height={18} />,
      text: "Change Pin & Idle Time",
      isVisible: true,
    },
    is2FaEnabled && {
      id: "resetTwoFactorButton",
      onClick: disable2Fa,
      className: "mondayButtonBlue",
      Icon: <ThreeStarsIcon width={18} height={18} />,
      text: "Disable 2FA",
      isVisible: true,
    },
    {
      id: !isSuspended ? "SuspendButton" : "ReactivateButton",
      className: !isSuspended ? "mondayButtonRed" : "mondayButtonGreen",
      Icon: <SuspendedUserIcon />,
      onClick: () => setModalType(!isSuspended ? "Suspend" : "Reactivate"),
      text: !isSuspended ? "Suspend" : "Reactivate",
      isVisible: true,
    },
  ].filter(Boolean);
}

export default sessionButtons;
