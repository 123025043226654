const filterReport = ({ identityId, reportsConfiguration }) => {
  // const favouriteReports = allPreferences?.preferences?.reportPreferences;

  const filteredReports = reportsConfiguration.filter((report) => {
    return report?.reportsAvailable.some(({ hasSelectedFavorite }) =>
      hasSelectedFavorite?.includes(identityId)
    );
  });

  return filteredReports ?? [];
};

export default filterReport;
