import { forceToNumber } from "src/components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import ElevationGridComp from "./ElevationGridComp/ElevationGridComp";
import { useLayoutEffect, useMemo, useState } from "react";
import { MondayButton } from "src/components/commonComponents";
import { getElevationAddonsPrice } from "src/components/SidebarPages/Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/ServicePrices";
import AdditRentalsDetails from "./ElevationGridComp/AdditRentalsDetails/AdditRentalsDetails";
import AttachElevationLabel from "./ElevationGridComp/AttachElevationLabel/AttachElevationLabel";
import { message } from "antd";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../../../Context/Context";
import { useDispatch, useSelector } from "react-redux";
import { totalPriceOfSOVHandler } from "../../../../../../../Header/Components/ControlPanel/controlPanelFunctions";
import { useRedux } from "../../../../../../../../../../../../hooks";
import { getRowData } from "./ElevationGridComp/rowData";
import Swal from "sweetalert2";

const EventsCalculatorBody = ({ eventProps }) => {
  const { service = {} } = eventProps;
  // here i am going to divide for all elevations and get applicable amounts!
  const {
    isWritable = false,
    isAutoSaving = false,
    fakeSOV = {},
    errors = {},
    darkMode = true,
    retracted = false,
  } = useStateContext();
  const dispatchContext = useDispatchContext();
  const dispatch = useDispatch();
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );
  const [attachName, setAttachName] = useState(false);
  const [gridApis, setGridApis] = useRedux("eventsCalculatorModalData");
  const [rowDatas, setRowDatas] = useRedux("rowDatasEventsSov");
  const [distributedElevationData, setDistributedElevationData] = useRedux(
    "distributedElevationData"
  );

  // const totalPriceOfSOV = totalPriceOfSOVHandler({ fakeSOV });
  // const elevationData = useMemo(() => {
  //   const { serviceOptions = [] } = service || {};

  //   if (!serviceOptions?.length) return [];
  //   const elevationData = serviceOptions?.[0]?.map?.((serviceOption, index) => {
  //     const { price = 0, taxAmount = 0 } = getElevationPrice(
  //       service,
  //       serviceOption,
  //       index
  //     );
  //     let { elevationAddonPrice = 0, elevationAddonTax = 0 } =
  //       getElevationAddonsPrice(serviceOption);
  //     return {
  //       ...serviceOption,
  //       price,
  //       taxAmount,
  //       elevationAddonPrice,
  //       elevationAddonTax,
  //       maxPrice: price + elevationAddonPrice,
  //     };
  //   });
  //   return elevationData;
  // }, [service, attachName]);

  const onToggleAttachServiceAddons = () => {
    // console.log("onToggleAttachServiceAddons", { distributedElevationData });
    console.log("onToggleAttachServiceAddons", { rowDatas, service });
    let myRowData = [...rowDatas];
    let elevationsData = [...distributedElevationData];
    // rowDatas = myRowData?.filter((rowData) => rowData?.type !== "serviceAddon");
    setDistributedElevationData(
      elevationsData?.filter(
        (elevationData) => elevationData?.type !== "serviceAddon"
      )
    );
    setRowDatas(
      myRowData?.map((data) => {
        return data?.filter((rowData) => rowData?.type !== "serviceAddon");
      })
    );
    Swal.fire({
      title: "INFO!",
      text: "SERVICE ADDON HAS BEEN REMOVED BUT PRICE REMAINS TO BE DISTRIBUTED!",
      icon: "info",
    });
  };

  const onAttachName = () => {
    console.log("onAttachName", {
      gridApis,
      rowDatas,
    });

    let myRowData = [...rowDatas];
    if (!myRowData?.length) {
      message.error("Please add at least one elevation!");
      return;
    } else {
      myRowData.forEach((rowData) => {
        if (!rowData?.length) {
          message.error("Please add at least one elevation!");
          return;
        } else {
          rowData.forEach((row) => {
            if (!row?.name.includes(row?.elevation)) {
              row.name += ` - ${row?.elevation}`;
            }
          });
        }

        // let rowData = [];
        // api.forEachNode((node) => {
        //   let data = node.data;
        //   if (!data?.name?.includes(data?.elevation)) {
        //     data.name += ` - ${data?.elevation}`;
        //   }
        //   rowData.push(data);
        // });
        // console.log("rowData", rowData);
        // api.setRowData(rowData);
        // api.refreshCells();
      });

      //   let rowData = [];
      //   params.api.forEachNode((node) => rowData.push(node.data));

      //   let valid = true;
      //   console.log("rowData", rowData);
      //   rowData?.reduce((acc, curr) => {
      //     acc += forceToNumber(curr?.rate);
      //     if (acc > 100) {
      //       valid = false;
      //     }
      //     return acc;
      //   }, 0);
      //   console.log("valid", valid);
      //   return valid;
    }
    setRowDatas(myRowData);
    Swal.fire({
      title: "Success!",
      text: "Elevation names have been attached!",
      icon: "success",
    });
  };

  const changeElevationPrice = (newPrice, serviceOption) => {
    const newAmount = forceToNumber(newPrice);
    let myData = structuredClone(distributedElevationData);
    for (const data of myData) {
      if (data?.elevationId === serviceOption?.elevationId) {
        data.maxPrice = newAmount;
      }
    }
    console.log("onConfirmnewAmount", { myData, newAmount });
    if (Array.isArray(service?.expandedAmounts)) {
      setRowDatas(service?.expandedAmounts);
    } else {
      let myRows = [];

      if (Array.isArray(myData) && myData.length > 0) {
        myData.forEach((item) => {
          myRows.push(getRowData({ service, serviceOption: item }));
        });
      }
      console.log("myRows", myRows);
      setRowDatas(myRows);
    }
    setDistributedElevationData(myData);
  };

  const onDistributeAdditionalRentals = () => {
    console.log("onDistributeAdditionalRentals", {
      rowDatas,
      service,
      distributedElevationData,
    });
    let balance = forceToNumber(service?.additionalRentalTerms?.newPrice);
    let totalServicePrice = forceToNumber(service?.totalPrice) - balance; // Price w tax!
    let addonsPrice = service?.serviceAddons?.reduce(
      (acc, curr) => acc + forceToNumber(curr?.totalPrice),
      0
    );
    totalServicePrice -= forceToNumber(addonsPrice);
    let myRowData = [...rowDatas];
    let elevationsData = [...distributedElevationData];
    if (balance > 0) {
      elevationsData?.forEach((elevationData, index) => {
        if (elevationData?.type === "serviceAddon") return;
        let {
          elevationAddonPrice = 0,
          price = 0,
          additionalRentals = 0,
        } = elevationData;
        let maxPrice = price + elevationAddonPrice; //Elevation price including addons
        let currRate = maxPrice / totalServicePrice; // Percentage of elevation price including addons for total service price
        console.log("curr rate", {
          currRate,
          balance,
        });
        let newPrice = forceToNumber(currRate * balance); // New price for elevation including addons
        // balance -= newPrice;
        elevationData.additionalRentals = newPrice;
        elevationData.maxPrice = maxPrice + newPrice;
        // elevationData.legacyPrice = newPrice + maxPrice;
      });
      // now with the row data part
      let myRows = [];

      if (Array.isArray(elevationsData) && elevationsData.length > 0) {
        elevationsData.forEach((item) => {
          myRows.push(getRowData({ service, serviceOption: item }));
        });
      }
      console.log("myRows", myRows);
      setRowDatas(myRows);
      setDistributedElevationData(elevationsData);
    }

    return;
    // let myRowData = [...rowDatas];
    // let elevationsData = [...distributedElevationData];
    // rowDatas = myRowData?.filter((rowData) => rowData?.type !== "serviceAddon");
    setDistributedElevationData(
      elevationsData?.filter(
        (elevationData) => elevationData?.type !== "serviceAddon"
      )
    );
    setRowDatas(
      myRowData?.map((data) => {
        return data?.filter((rowData) => rowData?.type !== "serviceAddon");
      })
    );
    Swal.fire({
      title: "INFO!",
      text: "SERVICE ADDON HAS BEEN REMOVED BUT PRICE REMAINS TO BE DISTRIBUTED!",
      icon: "info",
    });
  };
  const getGridApis = (currAPI) => {
    setGridApis([...(gridApis || []), currAPI]);
  };

  console.log("distributedElevationData", distributedElevationData);

  return (
    <div>
      <AttachElevationLabel
        {...{
          onAttachName,
          onToggleAttachServiceAddons,
          onDistributeAdditionalRentals,
        }}
      />
      <br />
      <AdditRentalsDetails {...eventProps} />
      {Array.isArray(distributedElevationData) &&
        distributedElevationData?.map((serviceOption, index) => {
          return (
            <ElevationGridComp
              key={index}
              rowData={rowDatas?.[index]}
              serviceOption={serviceOption}
              index={index}
              getGridApis={getGridApis}
              {...eventProps}
            />
          );
        })}
    </div>
  );
};

export default EventsCalculatorBody;
const totalPriceMapper = (el) =>
  !isNaN(+el?.totalPrice) ? +el?.totalPrice : 0;
const totalTaxMapper = (el) => (!isNaN(+el?.taxAmount) ? +el?.taxAmount : 0);

const hoistTotalPriceMapper = ({ totalPrice }) => totalPrice;
const totalHoistTaxMapper = ({ taxAmount }) => taxAmount;
export const getElevationPrice = (service, elevation, optionIndex) => {
  console.log("getElevationPrice", service, elevation, optionIndex);
  let TO_RETURN = { price: 0, taxAmount: 0 };
  if (!elevation) return TO_RETURN;

  if (!checkIfServiceIsHoist(service)) {
    elevation?.items?.forEach?.((item) => {
      if (item?.approved) {
        TO_RETURN.price += forceToNumber(totalPriceMapper(item));
        TO_RETURN.taxAmount += forceToNumber(totalTaxMapper(item));
      }
    });
  }
  //TODO: HOIST maybe in the future if structure changes
  return TO_RETURN;
};

const checkIfServiceIsHoist = (service) => {
  return service?.serviceId?.toString() === "3" || service?.isHoist === true;
};
