import AutomaticSchedulingPicker from "./Components/AutomaticSchedulingPicker/AutomaticSchedulingPicker";
import SchedulingPicker from "./Components/SchedulingPicker/SchedulingPicker";
import EstimationPicker from "./Components/EstimationPicker/EstimationPicker";
import { apiRoutes, fetchData } from "../../../../../Fleet/utils";
import { calculatedScheduleHandler } from "./newRentalFunctions";
import { Cancel } from "../../../../../DynamicView/src";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import "./NewRentals.scss";
import WarningModal from "../../../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as WarningIcon } from "../../../../../../../icons/warningTriangle.svg";
import { ReactComponent as CloseIcon } from "../../../../../DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { MondayButton } from "../../../../../../commonComponents/index";

import { useEffect, useState } from "react";
import { message, Modal, Result, Skeleton, Spin } from "antd";
import { requestAllPages } from "../../../../../../../utils/paginationFetchData";
import { API } from "aws-amplify";
import { filterTables } from "../../../../../../../utils/filterTables";
import { fetchAllData, updateDocumentTitle } from "../../../../../../../utils";
import { useSelector } from "react-redux";
import RentalsView from "../../RentalsView/RentalsView";
import RentalTermsProject from "./Components/RentalTermsProject/RentalTermsProject";

const NewRentals = ({
  projectId,
  visible,
  handleCloseForm,
  getBodyToSaveInAutomation, //this is a function used in Automations to get body for api, to save it later automatically
  isNextStep,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [estimations, setEstimations] = useState([]);
  const [schedulingData, setSchedulingData] = useState([]);
  const [specificProject, setSpecificProject] = useState(null);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [isOpenRentalForStep, setIsOpenRentalForStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chosenData, setChosenData] = useState({
    selectedId: undefined,
    from: "estimation",
    service: [],
  });

  //here we fetch data from db
  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      await Promise.allSettled([
        filterTables("scheduling", "projectId", projectId),
        fetchAllData({
          endpoint: "estimations",
          resultPosition: "estimations",
          resultId: "estimationId",
          otherStringParams: {
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "projectId",
                    value: projectId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        }),
      ])
        ?.then(async ([{ value: schedulingRes }, { value: estimationRes }]) => {
          const filteredScheduling = schedulingRes
            ?.filter((data) => data?.projectId === projectId)
            ?.sort((a, b) => a.createdAt - b.createdAt);

          await API.get("projects", `/projects/${projectId}`).then(
            setSpecificProject
          );
          setSchedulingData(
            calculatedScheduleHandler({
              allSchedules: filteredScheduling,
            })
          );

          setEstimations(estimationRes);

          //setChosenData((initialData) => ({
          // 	...initialData,
          // 	from: filteredScheduling?.length > 1 ? "schedulingAutomatically" : "schedulingManually",
          // }));
        })
        .catch((err) => console.log("err", err));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const onCancel = () => {
    if (isNextStep) return;
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setFormChanged(false);
    setCancelModalVisible(false);
    handleCloseForm();
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      updateDocumentTitle(); // reset document title to "Lead Manager";

      handleCloseForm();
    }
  };

  return (
    <>
      {visible && !!!isOpenRentalForStep && (
        <Modal
          title="New Rental"
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: "New Rental" });
          }}
          className={`addNewRental ${isDarkMode && "addNewRentalDark"}`}
          open={visible && !!!isOpenRentalForStep}
          destroyOnClose
          onCancel={() => {
            setCancelModalVisible(true);
          }}
          closeIcon={<Cancel />}
          footer={
            <div
              style={{
                // width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Footer
                {...{
                  handleCloseForm: () => {
                    onCancel();
                    // setCancelModalVisible(true);
                  },
                  setCancelModalVisible,
                  schedulingData,
                  setChosenData,
                  estimations,
                  chosenData,
                  projectId,
                  specificProject,
                  getBodyToSaveInAutomation,
                  isNextStep,
                  getRental: (rental) => {
                    setIsOpenRentalForStep(rental.id);
                  },
                }}
              />
            </div>
          }
        >
          {loading ? (
            <>
              <Result
                status={"info"}
                title="Hold tight! We are gathering all the information for you."
                extra={<Spin />}
              />

              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <div className="container">
              <Header {...{ setChosenData, chosenData, schedulingData }} />
              {chosenData?.from === "schedulingAutomatically" && (
                <AutomaticSchedulingPicker
                  {...{ chosenData, setChosenData, schedulingData }}
                />
              )}
              {chosenData?.from === "schedulingManually" && (
                <SchedulingPicker
                  {...{
                    chosenData,
                    setChosenData,
                    schedulingData,
                    setFormChanged,
                    setFormChanged,
                    isDarkMode,
                  }}
                />
              )}
              {chosenData?.from === "estimation" && (
                <EstimationPicker
                  {...{
                    estimations,
                    chosenData,
                    setChosenData,
                    setFormChanged,
                  }}
                />
              )}{" "}
              <RentalTermsProject
                {...{ project: specificProject, chosenData }}
              />
            </div>
          )}
        </Modal>
      )}
      {cancelModalVisible && (
        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          onKeyPress={(e) => onEnterPress(e)}
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible(false)}
                Icon={<CloseIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<Tick />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}

      {isOpenRentalForStep && (
        <RentalsView
          {...{
            isNextStep,
            rentId: isOpenRentalForStep,
            handleCloseForm,
          }}
        />
      )}
    </>
  );
};

export default NewRentals;
