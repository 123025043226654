import "./FiltersModal.scss";
import { Alert, Divider, Modal } from "antd";
import { useState } from "react";
// import _ from "lodash";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import MondayButton from "src/components/commonComponents/MondayButton/MondayButton";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { FilterIcon } from "src/components/SidebarPages/BasePage/src";
import { useSelector } from "react-redux";
import { generateDatePeriods } from "src/utils";
import { onDateFilter, onFilter } from "src/components/SidebarPages/utils";
import dayjs from "dayjs";
import { filtersOptions } from "./helpers";
import { useLocation } from "react-router-dom";

////
// NOTE: To ensure filters function correctly, the 'filter' key must be added to the columnDefs with the value "agMultiColumnFilter".
////

const FiltersModal = ({
  title = "",
  showFilterModal,
  setShowFilterModal,
  appliedFilters,
  setAppliedFilters,
  gridApi,
  filtersObject = [],
  additionalItemsToExclude = [],
  rowData,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const location = useLocation();

  const [temporaryFilters, setTemporaryFilters] = useState(appliedFilters); //here we save updated filters until we click filter button

  const dateOptions = generateDatePeriods();

  const itemsToExclude = [
    ...additionalItemsToExclude,
    "Id",
    "description",
    "actions",
    "edit",
    "delete",
    "notes",
    "Drag",
  ];

  const isInspectionsPage = location.pathname === "/inspections";

  const handleFilter = () => {
    setShowFilterModal(false);

    const updatedFilters = Object.entries(temporaryFilters).reduce(
      (acc, [key, value]) => {
        const hasValue = Array.isArray(value) ? value.length > 0 : !!value;

        const { type } = filtersObject?.find(
          (filterObj) => filterObj.key === key
        );

        if (type === "Dropdown") {
          onFilter({
            gridApi,
            column: key,
            current: value,
            type: "set",
          });
        } else if (type === "RangePicker") {
          if (key.includes("Range")) {
            const columnKey = key.split("Range")[0];
            onDateFilter({
              gridApi,
              column: columnKey,
              dateArray: [value[0], value[1]],
            });
          } else {
            onDateFilter({
              gridApi,
              column: key,
              dateArray: value,
            });
          }
        } else if (type === "DateRange") {
          const { startDate, endDate } = dateOptions.find(
            ({ label }) => label === value
          );
          onDateFilter({
            gridApi,
            column: key,
            dateArray: [startDate, endDate.add(1, "day")],
          });
        }

        return { ...acc, ...(hasValue && { [key]: value }) };
      },
      {}
    );

    setAppliedFilters(updatedFilters);
  };

  return (
    <Modal
      className={`filters-modal ${isDarkMode && "filters-modal-dark"}`}
      title={`${title} Filters`}
      centered
      open={showFilterModal}
      onCancel={() => setShowFilterModal(false)}
      closeIcon={<XIcon />}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={() => setShowFilterModal(false)}
          >
            Cancel
          </MondayButton>
          <div style={{ display: "flex", gap: "10px" }}>
            {!!temporaryFilters && (
              <MondayButton
                className="mondayButtonYellow"
                Icon={<XIcon />}
                onClick={() => {
                  setShowFilterModal(false);
                  setAppliedFilters("clearFilters");
                }}
              >
                Clear Filters
              </MondayButton>
            )}
            <MondayButton
              className="mondayButtonBlue"
              Icon={<FilterIcon />}
              onClick={handleFilter}
            >
              Filter
            </MondayButton>
          </div>
        </div>
      }
      data-testid="filters-modal"
    >
      <div className="filters-modal-inputs">
        {filtersObject
          .filter(
            ({ key }) => !itemsToExclude.some((item) => key?.includes(item))
          )
          ?.map((filterObj, index) => {
            const { key, label, type } = filterObj;

            return (
              <div className="filters-modal-element" key={index}>
                {index > 0 && <Divider />}
                <span className="filters-modal-text">
                  {`Filter by ${label}`}
                </span>
                <div className="filters-modal-input">
                  {type === "Dropdown" ? (
                    <InputComponent
                      style={{ textAlign: "center" }}
                      type="select"
                      mode="multiple"
                      placeholder={`Select ${label}...`}
                      defaultValue={temporaryFilters?.[key]}
                      customOptions={filtersOptions({
                        rowData,
                        key,
                        isInspectionsPage,
                      })}
                      onChange={(option) => {
                        setTemporaryFilters((prev) => ({
                          ...prev,
                          [key]: option,
                        }));
                      }}
                    />
                  ) : type === "DateRange" ? (
                    <InputComponent
                      style={{ textAlign: "center" }}
                      type="select"
                      placeholder={`Select ${label}...`}
                      defaultValue={temporaryFilters?.[key]}
                      customOptions={dateOptions}
                      onChange={(value) => {
                        setTemporaryFilters((prev) => ({
                          ...prev,
                          [key]: value,
                        }));
                      }}
                    />
                  ) : type === "RangePicker" ? (
                    <InputComponent
                      {...{
                        type: "rangepicker",
                        format: "MM/DD/YYYY",
                        style: { width: "100%" },

                        ...(temporaryFilters?.[key] && {
                          defaultValue: [
                            dayjs(temporaryFilters[key]?.[0]),
                            dayjs(temporaryFilters[key]?.[1]),
                          ],
                        }),
                        onChange: (dates, dateStrings) => {
                          setTemporaryFilters((prev) => ({
                            ...prev,
                            [key]: dates && [
                              dayjs(dateStrings[0]),
                              dayjs(dateStrings[1]),
                            ],
                          }));
                        },
                      }}
                    />
                  ) : (
                    <Alert
                      message="Filters Object should have one of these types: Dropdown, RangePicker or DateRange!"
                      type="warning"
                      showIcon
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default FiltersModal;
