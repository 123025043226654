import { Input, Radio } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import CustomCheckBox from "../../CustomCheckBox/CustomCheckBox";
import { SearchOutlined } from "@ant-design/icons";

import "./WidgetCategory.scss";

const WidgetCategory = ({
  title,
  widgetConfigs,
  filledWidgets,
  setFilledWidgets,
  className,
}) => {
  const [searchValue, setSearchValue] = useState();

  const { widgetTitle, widgetEntities, subWidgetTitle, subWidgetEntities } =
    widgetConfigs;

  const [filteredWidgetEntities, setFilteredWidgetEntities] =
    useState(widgetEntities);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [selectedCategory, setSelectedCategory] = useState(
    filledWidgets?.[title]?.[widgetTitle]?.entityElements || subWidgetEntities
  );

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchValue(query);

    const filtered = widgetEntities.filter((item) =>
      item?.label?.toLowerCase().includes(query)
    );

    setFilteredWidgetEntities(filtered);
  };

  return (
    <>
      {widgetEntities?.length > 10 && (
        <div className="widget-search-input-container">
          <Input
            value={searchValue}
            allowClear
            className="search-comp"
            placeholder="Search Categories"
            prefix={<SearchOutlined className="SearchLogoIcon" />}
            onChange={handleSearch}
          />
        </div>
      )}

      <div
        className={`widget-category-container ${className || ""}${
          isDarkMode ? " dark-mode" : ""
        }`}
      >
        <div className="widget-category">
          <div className="widget-text">{widgetTitle}</div>
          <div className="widget-content">
            {filteredWidgetEntities?.map((cat, index) => (
              <CustomCheckBox
                key={index}
                checked={cat?.key === filledWidgets?.[title]?.[widgetTitle]}
                name={cat?.key}
                onChange={(e) => {
                  setSelectedCategory(cat?.entityElements || subWidgetEntities);
                  setFilledWidgets((prev) => ({
                    ...prev,
                    [title]: { ...prev[title], [widgetTitle]: cat?.key },
                  }));
                }}
              />
            ))}
          </div>
        </div>

        {subWidgetTitle && selectedCategory && (
          <div className="widget-category">
            <div className="widget-text">{subWidgetTitle}</div>
            <div className="widget-content">
              {selectedCategory?.map((entity, index) => (
                <CustomCheckBox
                  key={index}
                  checked={
                    entity?.key === filledWidgets?.[title]?.[subWidgetTitle]
                  }
                  name={entity?.key}
                  onChange={(e) => {
                    setFilledWidgets((prev) => ({
                      ...prev,
                      [title]: {
                        ...prev[title],
                        [subWidgetTitle]: entity?.key,
                      },
                    }));
                  }}
                />
              )) || (
                <Radio.Group
                  key="widgetEntity"
                  name="radiogroup"
                  defaultValue={filledWidgets?.[title]?.[subWidgetTitle]}
                >
                  {selectedCategory?.map((entity, index) => (
                    <div key={index} className="widgetCategoryContainerStyle">
                      <Radio
                        key={`radio${index}`}
                        value={entity?.key}
                        onClick={() =>
                          setFilledWidgets((prev) => ({
                            ...prev,
                            [title]: {
                              ...prev[title],
                              [subWidgetTitle]: entity?.key,
                            },
                          }))
                        }
                      >
                        {entity?.label}
                      </Radio>
                    </div>
                  ))}
                </Radio.Group>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetCategory;
