import { updateApplication } from "../../../../../../../../../../actions/applications";
import IncludeRentals from "./ControlPanelComponents/IncludeRentals/IncludeRentals";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../Context/Context";
import ExportToExcel from "./ControlPanelComponents/ExportToExcel/ExportToExcel";
import {
  classNameAdapter,
  datesChangeHandler,
  statusChangeHandler,
  totalCalculator,
} from "./controlPanelFunctions";
import { applicationExtractor } from "../../../../applicationViewFunctions";
import { MondayButton } from "../../../../../../../../../commonComponents";
import { Tasks } from "../../../../Tasks/Tasks";
import "./ControlPanel.scss";
import {
  DropdownIcon,
  RightArrow,
  LeftArrow,
  SaveIcon,
  WordIcon,
  Pdf,
} from "../../../../../../../../BasePage/src";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Switch,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import importedDayjs from "dayjs";
import { useState, useMemo } from "react";
import AnimatedNumber from "react-animated-number";
import { formatCurrency } from "../../../../../../../../utils";
import EditDates from "./ControlPanelComponents/EditDates/EditDates";
import { getNYDate } from "../../../../utils/dateFunctions";
import ApplicationDateAdjust from "./ControlPanelComponents/CreatedAt/ApplicationDateAdjust";
import Preview from "./ControlPanelComponents/Preview/Preview";
import { UndoRedoButtons } from "../../../../../../../../../commonComponents/UndoRedoButtons/UndoRedoButtons";
import { thisRetainageCalculator } from "./utils";
import {
  LogsIcon,
  NotesIcon,
} from "../../../../../../../../DynamicView/src/index";
import { Notes } from "../../../../../../../../../commonComponents/Notes/Notes";
import { getMonthOfTimestamp } from "../../../../../../../../utils/getMonthOfTimestamp";
import { CheckAccessRights } from "../../../../../../../../../../utils/CheckAccessRights";
import { checkIfServiceIsHoist } from "../../../../../../../../Estimations/DataEntryGrid/models/Service";
import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { calculateTotalsReqs } from "./totalities";
import { SettingsWhiteIcon } from "../../../../../../../../../../assets";
import { fetchAllData } from "../../../../../../../../Fleet/utils/fetchAllData";
import moment from "moment";
import { HistoryOutlined } from "@ant-design/icons";
import ChainRentals from "./ControlPanelComponents/ChainRentals/ChainRentals";
import NotesIconContent from "../../../../../../../../../commonComponents/Notes/components/NotesIconContent/NotesIconContent";
const timezone = require("dayjs/plugin/timezone");
importedDayjs.extend(timezone);
const dayjs = importedDayjs.tz;
const ControlPanel = ({
  undoClickHandler,
  redoClickHandler,
  canUndo,
  canRedo,
  setLogsVisible,
}) => {
  const [{ programFields }] = useSelector((state) => [state.programFields]);
  const applicationStatuses = programFields?.find(
    ({ fieldName }) => fieldName === "Statuses Of Categories"
  )?.fieldOptions?.["Requisition"];

  let isSaveButtonClicked = false;
  const { Item } = Menu;
  const { isWritable, isAutoSaving, fakeApplication, darkMode, appId } =
    useStateContext();
  const { isLoading, application, applications } = useSelector(
    (state) => state.applications
  );
  const dispatchContext = useDispatchContext();
  const { applicationId: currentApplicationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const applicationId = currentApplicationId || appId;

  //its called when the user pick a status
  const changeStatus = (data) =>
    statusChangeHandler({
      dispatchContext,
      fakeApplication,
      status: data,
    });
  const updateApplicationDates = (dates) => {
    datesChangeHandler({ fakeApplication, dispatchContext, dates });
  };
  const nextApplication = applicationExtractor({
    application,
    applications,
    addedIndex: 1,
  });
  const previousApplication = applicationExtractor({
    application,
    applications,
    addedIndex: -1,
  });
  // const totalities = useMemo(() => {
  //   if (!fakeApplication || applications?.length === 0) return {};
  //   else return totalCalculator(fakeApplication, applications);
  // }, [fakeApplication, application, applications]);
  // const thisRetainage = useMemo(() => {
  //   if (!fakeApplication || applications?.length === 0) return 0;
  //   else {
  //     const applicationWithTotalPrice = {
  //       ...fakeApplication,
  //       services: fakeApplication?.services?.map((el) => {
  //         if (checkIfServiceIsHoist(el)) {
  //           return {
  //             ...el,
  //             serviceOptions: el?.serviceOptions?.map((sOptions) => {
  //               return sOptions.map((sOption) => {
  //                 return {
  //                   ...sOption,
  //                   amounts: sOption?.amounts?.map((amount) => {
  //                     const {
  //                       thisPeriod = 0,
  //                       taxAmount = 0,
  //                       retainage = 0,
  //                       reducedAmount = 0,
  //                     } = amount || {};
  //                     const paymentDue =
  //                       Number(thisPeriod) -
  //                       Number(retainage) +
  //                       Number(reducedAmount);
  //                     return {
  //                       ...amount,
  //                       paymentDue: paymentDue > 0 ? paymentDue : 0,
  //                       applicableRetainage:
  //                         (thisPeriod * el?.retainage) / 100 || 0,
  //                     };
  //                   }),
  //                 };
  //               });
  //             }),
  //           };
  //         } else {
  //           return {
  //             ...el,
  //             amounts: el?.amounts?.map((amount) => {
  //               const {
  //                 thisPeriod = 0,
  //                 taxAmount = 0,
  //                 retainage = 0,
  //                 reducedAmount = 0,
  //               } = amount || {};
  //               const paymentDue =
  //                 Number(thisPeriod) -
  //                 // Number(taxAmount) -
  //                 Number(retainage) +
  //                 Number(reducedAmount);
  //               return {
  //                 ...amount,
  //                 paymentDue: paymentDue > 0 ? paymentDue : 0,
  //                 applicableRetainage:
  //                   (thisPeriod * amount?.retainagePercentage) / 100 || 0,
  //               };
  //             }),
  //           };
  //         }
  //       }),
  //       totalities: {
  //         ...(fakeApplication?.totalities || {}),
  //         ...totalities,
  //       },
  //     };
  //     return thisRetainageCalculator({
  //       fakeApplication: applicationWithTotalPrice,
  //       applications,
  //     });
  //   }
  // }, [fakeApplication, application, applications, totalities]);

  const totalAmounts = useMemo(() => {
    if (!fakeApplication || applications?.length === 0) return {};
    else {
      return calculateTotalsReqs(fakeApplication, applications);
    }
  }, [fakeApplication, application, applications]);

  const onFix = async () => {
    let RESULT = {};
    let minTimeframe = moment("01/11/2023", "DD/MM/YYYY").valueOf();
    await fetchAllData("applications", "applications", "applicationId").then(
      (data) => {
        let filtered = data.filter((el) => {
          return el.createdAt < minTimeframe;
        });
        let grouped = filtered.reduce((acc, el) => {
          let key = el?.projectName;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(el);
          return acc;
        }, {});
        console.log("data", { filtered, grouped });
      }
    );
  };

  return (
    <div
      className={
        darkMode
          ? "application-control-panel-dark"
          : "application-control-panel-light"
      }
    >
      <div style={{ display: "flex", width: "100%" }}>
        <div className="rightSideContainer">
          <div className="detailsContainer">
            <div className="ContainerLabel">
              <div className="label">Requisition NO:</div>
              <div className="label">Req. Due Date:</div>
            </div>
            <div className="ContainerDate">
              <div>{application?.applicationNo}</div>
              <div>{dayjs(fakeApplication?.dueDate).format("MM/DD/YYYY")}</div>
            </div>
            <div className="ContainerLabel" style={{ marginLeft: "5px" }}>
              <div className="label">Period:</div>
              <div className="label">Application Date:</div>
            </div>
            <div className="ContainerDate">
              <div style={{ color: "#87BCDE", marginLeft: "-10px" }}>
                {/* {dayjs(fakeApplication?.periodTo?.end).format("MM/DD/YYYY")} */}
                {getNYDate(fakeApplication?.periodTo?.start).formatted} -{" "}
                {getNYDate(fakeApplication?.periodTo?.end).formatted}
              </div>
              <div style={{ color: "#87BCDE" }}>
                <ApplicationDateAdjust />
              </div>
            </div>
          </div>
          <div className="applicationSwitcherContainer">
            <EditDates
              {...{ application, updateApplicationDates, darkMode, isWritable }}
            />
          </div>

          <UndoRedoButtons
            canUndo={canUndo}
            canRedo={canRedo}
            undoClickHandler={undoClickHandler}
            redoClickHandler={redoClickHandler}
          />
          <div className="applicationSwitcherContainer" id="prevNextContainer">
            {/* <MondayButton
                        disabled={!previousApplication}
                        className="previousApplicationButton"
                        onClick={() => navigate(`/applications/${previousApplication?.applicationId}`)}
                        Icon={<LeftArrow />}
                    > */}
            <span
              disabled={!previousApplication}
              className="previousApplicationSpan"
              onClick={() => {
                if (!!previousApplication)
                  navigate(
                    `/applications/${previousApplication?.applicationId}`
                  );
                else message.error("No previous application");
              }}
            >
              <LeftArrow />
            </span>
            {/* </MondayButton> */}
            {/* <MondayButton
                        disabled={!nextApplication}
                        className="nextApplicationButton"
                        onClick={() => navigate(`/applications/${nextApplication?.applicationId}`)}
                        Icon={<RightArrow />}
                    >
                        Next Requisition
                    </MondayButton> */}
            <span
              disabled={!nextApplication}
              className="previousApplicationSpan"
              onClick={() => {
                if (!!nextApplication)
                  navigate(`/applications/${nextApplication?.applicationId}`);
                else message.error("No next application");
              }}
            >
              <RightArrow />
            </span>
          </div>
        </div>
        <div className="leftSideContainer">
          <div className="statuses">
            {/* <IncludeRentals
              disabled={!fakeApplication?.projectId || isLoading || !isWritable}
            /> */}
            <ChainRentals
              disabled={!fakeApplication?.projectId || isLoading || !isWritable}
            />
            <Dropdown
              className="exportTo"
              overlayClassName="dropdownExportToClassName"
              // disabled={!fakeApplication?.projectId || isLoading}
              trigger="click"
              arrow={true}
              overlay={() => (
                <Menu>
                  {applicationStatuses
                    ?.filter(({ status }) => status)
                    ?.map(({ statusName }) => (
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => changeStatus(statusName)}
                      >
                        <Item>{statusName}</Item>
                      </Popconfirm>
                    ))}
                </Menu>
              )}
            >
              {/*approvedStatus sentStatus draftStatus paidStatus*/}
              <MondayButton
                className={classNameAdapter[fakeApplication?.applicationStatus]}
                Icon={<DropdownIcon />}
                disabled={isLoading}
              >
                <div className="selectedCategoryHolder">
                  {fakeApplication?.applicationStatus}
                </div>
              </MondayButton>
            </Dropdown>
            {/* <Tooltip title="Turn on/off auto save">
              <Switch
                className="autoSaveToggle"
                checked={isAutoSaving}
                onChange={(checked) =>
                  dispatchContext({
                    type: "SET_AUTOSAVING",
                    payload: checked,
                  })
                }
                checkedChildren="Auto Save On"
                unCheckedChildren="Auto Save Off"
                style={
                  isAutoSaving
                    ? { backgroundColor: "#71CF48" }
                    : { backgroundColor: "#FE4C4A" }
                }
              />
            </Tooltip> */}
          </div>
          <div className="application-header-options">
            <Tasks {...{ darkMode }} />
            {CheckAccessRights(
              userConfiguration?.routeConfig?.find(
                ({ title }) => title === "Notes/View"
              )?.children,
              "Applications"
            ) && (
              <Notes
                noteModalName={`${fakeApplication?.projectName} | Application ${
                  fakeApplication?.applicationNo
                } - ${getMonthOfTimestamp(fakeApplication?.periodTo?.start)}`}
                rowId={application?.applicationId}
                topicCategory="Applications"
                customIcon={<NotesIcon />}
              />
            )}
            {/* <Notes {...{ darkMode }} /> */}
            {/* <Tasks {...{ darkMode }} /> */}
            <Tooltip title={"Show Logs"}>
              <span
                className="logsButtonContainer"
                onClick={() => setLogsVisible(true)}
              >
                <LogsIcon style={{ fill: "#fff", borderRadius: "5px" }} />
              </span>
              {/* <Button
              type="primary"
              className="float-right clearfix-primary-btn editLogs"
              style={{ marginRight: 24 }}
              onClick={() => setLogsVisible(true)}
            >
              <LogsIcon style={{ fill: "#fff", borderRadius: "5px" }} />
            </Button> */}
            </Tooltip>
            <div className="dropdownExportToClassName">
              <ExportToExcel />
              {/* <Preview /> */}
              {/* <Tooltip title="Export to Pdf">
                            <button className="exportToButton">
                                <Pdf />
                            </button>
                        </Tooltip> */}
              {/* <Tooltip title="Export to Word">
                            <button className="exportToButton">
                                <WordIcon />
                            </button>
                        </Tooltip> */}
            </div>
            <Tooltip
              title={
                isWritable ? "Save your work" : "Enable WRITE mode to save work"
              }
            >
              {/* <span
              onClick={() =>
                dispatch(
                  updateApplication(
                    applicationId,
                    {
                      ...fakeApplication,
                      totalPrice: totalCalculator({ fakeApplication }),
                    },
                    applications
                  )
                )
              }
              style={{
                width: "32px",
                height: "32px",
                background: "#71cF48",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <SaveIcon />
            </span> */}
              {/* <Button></Button> */}
              <MondayButton
                className="quickSaveApplicationHeader"
                width="32px"
                onClick={() => {
                  if (!isSaveButtonClicked) {
                    isSaveButtonClicked = true;
                    let PRICES = calculateTotalsReqs(fakeApplication);
                    console.log("PRICES", PRICES);
                    dispatch(
                      updateApplication(
                        applicationId,
                        {
                          ...fakeApplication,
                          totalities: {
                            totalAmount: PRICES?.CURRENT_PAYMENT,
                            totalRetainage: PRICES?.ACCUMULATED_RETAINAGE,
                            currentPeriod: PRICES?.THIS_PERIOD,
                            currentRetainage: PRICES?.CURRENT_RETAINAGE,
                            totalRentalWithTax: 0, //TODO: add total rental with tax
                            thisRentalsRetaiange: 0, //TODO: add this rentals retainage
                            currentPaymentDue: PRICES?.CURRENT_PAYMENT,
                            thisPeriodRetainage: PRICES?.CURRENT_RETAINAGE,
                            thisRetainage: PRICES?.CURRENT_RETAINAGE,
                          },
                        },
                        applications
                      )
                    ).then(() => {
                      isSaveButtonClicked == false;
                    });
                  }
                }}
                Icon={<SaveIcon />}
                disabled={!isWritable || isLoading || isSaveButtonClicked}
                id="quickSaveApplicationHeader"
              >
                Quick Save
              </MondayButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div
        className="amounts-indic"
        style={{ display: "flex", fontSize: "14px", padding: "0 10px" }}
      >
        {" "}
        <div className="total-amount-price">
          <strong
            style={{
              backgroundColor: "#347928",
              borderRadius: "5px",
              padding: "0 5px",
            }}
          >
            CURRENT PAYMENT:
          </strong>
          <AnimatedNumber
            style={{ color: "#71CF48" }}
            value={
              forceToNumber(totalAmounts?.CURRENT_PAYMENT?.toFixed(2)) ?? 0
            }
            formatValue={(value) => formatCurrency(value)}
          />
        </div>{" "}
        <div className="total-amount-price">
          <strong
            style={{
              backgroundColor: "#347928",
              borderRadius: "5px",
              padding: "0 5px",
            }}
          >
            THIS PERIOD:
          </strong>
          <AnimatedNumber
            style={{ color: "#71CF48" }}
            value={totalAmounts?.THIS_PERIOD}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
        <div className="total-amount-price">
          <strong
            style={{
              backgroundColor: "#347928",
              borderRadius: "5px",
              padding: "0 5px",
            }}
          >
            RETAINAGE (THIS PERIOD):
          </strong>
          <AnimatedNumber
            style={{ color: "#71CF48" }}
            value={forceToNumber(totalAmounts?.CURRENT_RETAINAGE) ?? 0}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
        <div className="total-amount-price">
          <HistoryOutlined />{" "}
          <strong
            style={{
              backgroundColor: "#347928",
              borderRadius: "5px",
              padding: "0 5px",
            }}
          >
            RETAINAGE:{" "}
          </strong>
          <AnimatedNumber
            style={{ color: "#71CF48" }}
            value={totalAmounts?.ACCUMULATED_RETAINAGE}
            formatValue={(value) => formatCurrency(value)}
          />
        </div>
        {/* <MondayButton Icon={<SettingsWhiteIcon />} onClick={onFix}>
          Fix ME!
        </MondayButton> */}
      </div>
    </div>
  );
};
export default ControlPanel;
