import { useSelector } from "react-redux";
import { InfoCircleFilled } from "@ant-design/icons";
import { useCallback, useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import EmployeeUploadContext from "../../EmployeeUploadContext";
import { crewsColumnDefs, engineersColumnDefs } from "../../AgGridData";
import CompareTable from "../../components/CompareConflictTable/CompareTable";
import { SearchIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { WarningTriangle } from "../../../../../../../../SidebarPages/DynamicView/src";

import "./EmployeesTable.scss";

const defaultColDef = {
  flex: 1,
  filter: true,
  sortable: true,
  resizable: true,
  editable: false,
  enablePivot: true,
  enableRowGroup: true,
  enableColResize: true,
};

const sideBar = {
  toolPanels: [
    {
      id: "columns",
      iconKey: "columns",
      labelKey: "columns",
      labelDefault: "Columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      iconKey: "filter",
      labelKey: "filters",
      labelDefault: "Filters",
      toolPanel: "agFiltersToolPanel",
    },
  ],
};

function EmployeesTable() {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    gridApi,
    setGridApi,
    employeeType,
    uploadedEmployees,
    employeesConflicts,
  } = useContext(EmployeeUploadContext);

  const [conflictsTableVisible, setConflictsTableVisible] = useState(false);
  const [searchInput, setSearchInput] = useState(null);

  const onGridReady = useCallback(({ api }) => {
    setGridApi(api);
  }, []);

  const getRowId = useCallback((param) => {
    if (employeeType === "crews") {
      return param?.data?.crewId;
    } else if (employeeType === "engineers" || employeeType === "architects") {
      return param?.data?.email;
    }
  }, []);

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setSearchInput(e);
  }

  const columnDefs =
    employeeType === "crews"
      ? crewsColumnDefs
      : employeeType === "engineers" || employeeType === "architects"
      ? engineersColumnDefs
      : null;

  return (
    <main
      className={`employees-table-container ${
        isDarkMode && "employees-table-container-dark"
      }`}
    >
      <section className="table-control-panel">
        <div className="uploadEmployeeInfo">
          <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
          <p className="uploadEmployeeText">
            Manage the list of uploaded crew members. You can remove users,
            double-click on records to edit details, and resolve the conflicts
            if there is any.
          </p>
          {!!employeesConflicts?.newEmployees?.length && (
            <div
              className="conflicts"
              onClick={() => setConflictsTableVisible(true)}
            >
              <WarningTriangle />
              <span className="conflictsCount">
                {employeesConflicts?.newEmployees?.length}
              </span>
              conflicts
            </div>
          )}
        </div>
        <InputComponent
          type="input"
          value={searchInput}
          prefix={<SearchIcon />}
          placeholder="Search employee..."
          onChange={(e) => onFilterTextChange(e.target.value)}
        />
      </section>
      <section
        className={`payroll-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            gridApi,
            sideBar,
            getRowId,
            columnDefs,
            onGridReady,
            defaultColDef,
            pagination: true,
            animateRows: true,
            enableRangeSelection: true,
            rowData: uploadedEmployees,
            rowGroupPanelShow: "always",
            paginationAutoPageSize: true,
            paginationPageSize: Math.floor((window.innerHeight - 370) / 45),
          }}
        />
      </section>
      {conflictsTableVisible && (
        <CompareTable
          employeeType={employeeType}
          open={conflictsTableVisible}
          onCancel={() => setConflictsTableVisible(false)}
        />
      )}
    </main>
  );
}

export default EmployeesTable;
