import React from "react";
import "./ForecastingCards.scss";
import {
  CalendarDateIcon,
  CalendarDatesIcon,
  HoursIcon,
  MembersIcon,
  TruckIcon,
} from "../../../../../../assets";
import {
  calcData,
  firstServiceDay,
} from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { typeOfWorkColors } from "../../../../../Header/forms/Scheduling/DataAgGrid/columnDefs";
const ForecastingCards = ({ workType, newServices }) => {
  const cardData = [
    {
      icon: <CalendarDateIcon />,
      value: firstServiceDay(Object.values(newServices).flat(), workType),
      label: "Potential Start Date",
    },

    {
      icon: <CalendarDatesIcon />,
      value: calcData(
        Object.values(newServices).flat(),
        workType + "plannedNrOfDays"
      ),
      label: "Planned days",
    },
    {
      icon: <HoursIcon />,
      value: calcData(
        Object.values(newServices).flat(),
        workType + "plannedNrOfHours"
      ),
      label: "Planned hours",
    },
    {
      icon: <MembersIcon />,
      value: calcData(
        Object.values(newServices).flat(),
        workType + "plannedNrOfCrews"
      ),
      label: "Planned Crews",
    },
    {
      icon: <TruckIcon />,
      value: calcData(
        Object.values(newServices).flat(),
        workType + "plannedNrOfTrucks"
      ),
      label: "Planned Trucks",
    },
  ];

  return (
    <div className="forecastingCards">
      {cardData.map((card) => {
        return (
          <div className="forecastingCardData">
            <span>{card.icon}</span>
            <b>{card.value}</b>
            <span>{card.label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ForecastingCards;
