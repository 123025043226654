import { Tooltip } from "antd";
import { MondaySwitch, StarCheckbox } from "../../../../../commonComponents";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { categoriesIcons } from "../../reportsConfigurationData";
import { DeleteCategory } from "../CategoriesView/DeleteCategory/DeleteCategory";

export const excelColumnsConfig = [
  "categoryName",
  "reportsAvailable",
  "department",
  "createdAt",
  "lastModifiedBy",
];

export const formatCellValueHandler = (data) =>
  data?.map(({ categoryName }) => categoryName).join("- ");

export const columnDefs = ({
  updateState,
  onOpenConfiguration,
  setReportModalVisible,
  onDeleteConfiguration,
  isDarkMode,
  setSelectedCategoryName,
  onFavoriteClick,
  identityId,
}) => {
  const centerItem = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return [
    {
      headerName: "Configuration Title",
      field: "categoryName",
      filter: "agMultiColumnFilter",
      cellClass: "reportCategoryName cell-left-aligned",
      cellRenderer: ({ data }) => {
        const CategoryIcon =
          categoriesIcons[data?.categoryName] || categoriesIcons.default;

        const isFavorite = !!data?.reportsAvailable?.every((el) =>
          el?.hasSelectedFavorite?.includes(identityId)
        );
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <CategoryIcon
              width={20}
              height={20}
              fill={isDarkMode ? "#fff" : "#323338"}
            />{" "}
            {data?.reportsAvailable?.length ? (
              <StarCheckbox
                {...{
                  checked: isFavorite,
                  onChange: (e) => {
                    onFavoriteClick(e, data);
                  },
                }}
              />
            ) : null}
            <span onClick={() => onOpenConfiguration(data?.categoryName)}>
              {data?.categoryName}
            </span>
          </div>
        );
      },
    },
    {
      headerName: "Reports",
      field: "reportsAvailable",
      filter: "agMultiColumnFilter",
      cellClass: "reportCategoryName",
      cellRenderer: ({ data }) => {
        return <div style={centerItem}>{data?.reportsAvailable?.length}</div>;
      },
    },
    {
      headerName: "Category",
      field: "department",
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Report State",
      field: "reportState",
      filter: "agMultiColumnFilter",
      cellRendererFramework: ({ data }) => (
        <div style={{ margin: "auto 0" }}>
          <MondaySwitch
            {...{
              checked: data?.reportState,
              onToggleSwitch: () => {
                updateState(data);
              },
              onText: "Enabled",
              offText: "Disabled",
              removeIcon: false,
            }}
          />
        </div>
      ),
      getQuickFilterText: ({ value }) => (value ? "Enabled" : "Disabled"),
      pdfExportOptions: {
        modifyCellValue: (data) => {
          return data ? "Enabled" : "Disabled";
        },
      },
    },
    {
      headerName: "Last Report Created At",
      field: "createdAt",
      filter: "agDateColumnFilter",
      valueGetter: ({ data }) => {
        if (data?.hasOwnProperty("createdAt")) {
          return dayjsNY(data?.createdAt).format("MM/DD/YYYY - hh:mm a");
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Last Report Modified By",
      field: "lastModifiedBy",
      filter: "agDateColumnFilter",
      valueGetter: ({ data }) => {
        return data?.lastModifiedBy?.name || "";
      },
    },
    {
      headerName: "Clone Report",
      filter: "agMultiColumnFilter",
      cellRenderer: ({ data }) => {
        return (
          <div style={centerItem}>
            <span
              className="cloneReportClick"
              onClick={() => {
                setReportModalVisible(data);
                setSelectedCategoryName(data?.categoryName);
              }}
            >
              Clone Report
            </span>
          </div>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Delete",
      cellRenderer: ({ data }) => {
        return (
          <Tooltip title="Delete">
            <div style={centerItem}>
              <DeleteCategory {...{ data, onDeleteConfiguration }} />
            </div>
          </Tooltip>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
  ];
};
