import { InfoCircleFilled } from "@ant-design/icons";
import { Form, message, Modal, Tooltip } from "antd";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import uniq from "lodash/uniq";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import {
  useAccessRights,
  useEditLogs,
  useProgramFields,
  useUploadedFiles,
} from "../../../../../../hooks";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { documentationWS } from "../../../../../../sockets/documentationWS";
import {
  antdModalProps,
  filterTables,
  getCognitosForNotification,
  getObjectChanges,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
  updateDocumentTitle,
  updateTeamsConfiguration,
} from "../../../../../../utils";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import {
  MondayButton,
  MondaySwitch,
  Stepper,
} from "../../../../../commonComponents";
import WarningModal from "../../../../../commonComponents/WarningModal/WarningModal";
import { expirationDateObject } from "../../../../../pages/Settings/settingsComponents/DocumentationConfiguration/View/utils";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { getChangedData } from "../../../../Accounting/components/utilities";
import getDocWarnings from "../DocumentationListCard/components/DocWarningsModal/helpers/getDocWarnings";
import { XIcon } from "../../../../Communication/assets/index";
import { InputComponent } from "../../../../Fleet/components";
import { apiRoutes, fetchData, validateForm } from "../../../../Fleet/utils";
import { deleteAllRelatedToDos } from "../../../../ToDos/forms/helpers";
import { compareIncluding } from "../../../../utils";
import { filterPeopleList } from "../../../../utils/filterPeopleList";
import "./DocumentationModal.scss";
import { ActionButtons, DocUploader } from "./components";
import DocMembers from "./components/DocMembers/DocMembers";
import { generatePdf } from "./generatePdf";
import { formatTeamFormField } from "./helpers";
import { useCustomerWebsocketContext } from "../../../../../../contexts/WebsocketProvider/useCustomerWebsocket";
import { parseInTz } from "../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const CONFIRMATION_MODAL_TEXT =
  "This action will delete all the uploaded Google Drive files. Are you sure you want to continue?";

const servicesToNotShow = ["Services", "Fleet", "Fleet-Drivers"];

/**
 *
 * @param {boolean} visible - value of the modal visibility
 * @param {function} setVisible - function to set the visibility of the modal
 * @param {string} viewTitle - title of the record
 * @param {string} accessToken - access token for google drive
 * @param {string} categoryName - category name of the record
 * @param {string} docType - type of the documentation
 * @param {string} proppedFolderId - edit mode -> docObject id from the record , edit mode -> folder id of the current documentation
 * @param {dayjs | boolean} proppedConfigExpirationDate - expiration date configuration
 * @param {string} recordFolderId - create mode -> docObject id from the record
 * @param {string} recordId - id of the record
 * @param {string} recordValue - create mode -> name of the record
 * @param {string} recordName - edit mode -> name of the record
 * @param {string} docId - id of the documentation
 * @param {array} relatedDocId - related documentation ids
 * @param {string} docStatus - status of the documentation
 * @param {array} docObject - array of documentation dynamic fields
 * @param {array} docStatuses - array of documentation statuses
 * @param {array} notes - notes of the documentation
 * @param {string} renewalId - id of the renewal documentation
 * @param {array} docLogs - array of documentation logs
 * @param {dayjs} expirationDate - ??expiration date of the documentation
 * @param {dayjs} requestedDate - requested date of the documentation
 * @param {array} googleDriveUploads - array with files previously uploaded to google drive
 * @param {function} setDocumentation - set the saved documentation to the state on the parent component and shows it on the list
 * @param {string} createdBy - user who created the documentation
 * @param {dayjs | moment} createdAt - date when the documentation was created
 * @param {boolean} forceEnableWrite - force enable write
 * @param {array} isIncludedIn - array of categories where the documentation is included
 * @param {object} categoryConfiguration - current category configuration object
 * @param {array} singleDocumentation - array with all documentations of the record
 * @param {boolean} isOpenedByButton - flag to check if the modal was opened by clicking the record or the new documentation button
 * @param {object} record - current record object to autocomplete the documentation dynamic fields with the record data if connected
 * @param {array} allDocumentation - array with all documentations from DB
 * @param {boolean} isCreateDocumentEnabled = false - Params to make the create Documentation icon visible or not
 * @param {boolean} onSaveParentModal = function to make a save request to modal from where DocumentationModal was triggered
 * @returns {JSX.Element} - DocumentationModal
 */

const DocumentationModal = ({
  visible,
  setVisible,
  viewTitle,
  categoryName,
  docType,
  saveAsType,
  folderId: proppedFolderId,
  configExpirationDate: proppedConfigExpirationDate = false,
  recordFolderId,
  recordId,
  recordValue,
  docId = "",
  relatedDocId = "",
  docStatus,
  docObject = [],
  docStatuses = [],
  notes,
  renewalId, // This is the id of the document that can be connected
  docLogs = [],
  expirationDate,
  requestedDate,
  googleDriveUploads = [],
  setDocumentation,
  createdBy = "No Data",
  createdAt = "No Data",
  recordName = "",
  forceEnableWrite = true,
  isIncludedIn: isIncludedInProp = [],
  categoryConfiguration,
  documentation: singleDocumentation,
  isOpenedByButton = false,
  record,
  allDocumentation = [],
  requestId = "",
  afterSaveRequest = async () => {},
  teamsConfiguration = [],
  enableOnField = false,
  enableOnCustomerPortal = false,
  isModalOpenedFromFileItem = false,
  onSaveParentModal = async () => {},
}) => {
  const [form] = Form.useForm();
  const [formCheckBox] = Form.useForm();
  let location = useLocation();
  let navigate = useNavigate();
  let path = location?.pathname;
  let pathTab = path?.split("/")[1];

  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { ["Documentation Extra Folders"]: extraFolders } = useProgramFields();
  const [currentStep, setCurrentStep] = useState(0);
  const [_, writeRight] = useAccessRights({ title: "Documentations" });
  const { sendWsMessage } = useCustomerWebsocketContext();
  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [
    uploadedFiles,
    setUploadedFiles,
    formattedDriveFiles,
    onPickerSuccess,
  ] = uploadHandlers;

  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const [folderId, setFolderId] = useState();
  const dynamicDocumentations =
    programFields?.find(({ fieldId }) => fieldId === "Documentation Mapping")
      ?.fieldOptions ?? {};

  const [confirmationModalVisible, setConfirmationModalVisible] = useState();
  const [configExpirationDate, setConfigExpirationDate] = useState(
    proppedConfigExpirationDate
  );
  const [users, setUsers] = useState([]);
  // const [originalModal, setOriginalModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [docConfig, setDocConfig] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const [enableOnFieldToggle, setEnableOnFieldToggle] = useState(enableOnField);
  const [isEnableOnCustomerPortal, setIsEnableOnCustomerPortal] = useState(
    enableOnCustomerPortal
  );
  const [dynamicFields, setDynamicFields] = useState(docObject || []);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const { base64 } = useSelector((state) => state.base64);

  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const driveRequest = driveApi({ accessToken });
  const { saveAddedLogs } = useEditLogs();

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const fieldsJSON = !!configExpirationDate
    ? [expirationDateObject, ...dynamicFields]
    : dynamicFields;

  const documentation = {
    folderId: proppedFolderId,
    googleDriveUploads,
    expirationDate,
    requestedDate,
    docObject: dynamicFields,
    docStatus,
    docLogs,
    notes,
    enableOnField,
    enableOnCustomerPortal,
  };

  const resetForm = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    form.resetFields();
    setCurrentStep(0);
    setConfigExpirationDate("");
    setUploadedFiles([]);
    setFolderId("");
    setVisible(false);
    navigate({ pathname: path, search: `?tab=Documentation` });
  };

  async function updateRelatedDocs(docsToUpdate) {
    await docsToUpdate.forEach(async (doc) => {
      try {
        await API.patch(
          apiRoutes.documentation,
          `/${apiRoutes.documentation}/${doc.docId}`,
          { body: { renewalId: null, revisedId: null } }
        ).catch((error) => console.log("Error updating related docs", error));
      } catch (error) {
        console.error(error);
        showErrorMsg();
      }
    });
  }

  //region Delete Doc
  const deleteDocumentation = async () => {
    showLoadingMsg({ content: "Deleting..." });

    const deleteSingleDoc = async (doc) => {
      setIsDeleting(true);

      try {
        await API.del(
          apiRoutes.documentation,
          `/${apiRoutes.documentation}/${doc.docId}`
        ).catch((error) => console.log("Error deleting documentation", error));
        setIsDeleting(false);
        showSuccessMsg({ content: "Deleted!" });
        await deleteAllRelatedToDos({ recordId: doc.docId }).catch((e) =>
          console.log("Error deleting todos: ", e)
        );

        // Delete uploaded files
        const allDriveToDelete = uploadedFiles
          ?.map((d) => d?.id)
          ?.concat(filesToBeDeleted);
        await Promise.all(
          allDriveToDelete.map((id) => driveRequest.deleteDriveItem(id))
        ).catch((e) => {
          console.log("Error uploading files: ", e);
        });

        // Create edit log
        await saveAddedLogs({
          recordId: doc.docId,
          recordName: `${doc.docType} ${!!renewalId ? "(Renewal)" : ""}`,
          category: "Documentation",
          actionType: "Delete",
          topic: recordName,
        });

        const docsToUpdate = singleDocumentation?.filter(
          (doc) => doc?.renewalId === docId || doc?.revisedId === docId
        );
        if (docsToUpdate.length > 0) {
          await updateRelatedDocs(docsToUpdate);
        }

        // Update state
        setDocumentation((prev) => {
          return prev
            .filter((d) => d?.docId !== doc.docId)
            .map((el) => {
              if (el?.renewalId === doc.docId) {
                return { ...el, renewalId: null };
              }
              if (el?.revisedId === doc.docId) {
                return { ...el, revisedId: null };
              }
              return el;
            });
        });

        if (!isDeleting) {
          resetForm();
        }
      } catch (error) {
        console.error(error);
        showErrorMsg();
      }
    };
    const docsToDelete = singleDocumentation?.filter(
      (document) => document.docId === docId
    );

    try {
      await Promise.all(docsToDelete.map(deleteSingleDoc));
    } catch (error) {
      console.error({ error });
      showErrorMsg();
    }
  };

  const steps = docStatuses?.map(({ statusName }) => ({
    title: statusName,
    disabled: !writeRight,
  }));

  const formattedDocObject = () =>
    dynamicFields?.map((obj = {}) => {
      const { formItemName, type } = obj;
      const fieldValue = form.getFieldValue(formItemName);
      return {
        ...obj,
        value:
          type !== "datepicker" && type !== "timepicker"
            ? fieldValue
            : !!fieldValue
            ? type === "datepicker"
              ? parseInTz(fieldValue).valueOf()
              : parseInTz(fieldValue).valueOf()
            : fieldValue,
      };
    });

  const sendCustomerLiveDashboardUpdate = (newDocStatus) => {
    sendWsMessage({
      type: "liveDashboard",
      request: "live-update",
      body: {
        category: "Documentations",
        record: { docId, docType, docStatus: newDocStatus },
      },
    });
  };

  //region Save Doc
  const saveDocumentation = async (typeString = "") => {
    setSaveDisabled(true);
    let res;
    const newDocLogs = [
      {
        nameOfUser: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
        updatedAt: Date.now(),
        updatedKeys: [{ label: "Created" }],
      },
    ];

    const isIncludedIn = form.getFieldValue("isIncludedIn");

    const newTeam = updateTeamsConfiguration(userConfiguration, selectedTeam);

    showLoadingMsg();

    const categoriesToIncludeIds = await getDocumentToShowIds(
      isIncludedIn
    ).catch((e) => {
      console.log("Error getting categories: ", e);
    });

    const bodyObjectToSave = {
      categoryName: categoryName,
      recordName: pathTab === "permitdrawings" ? viewTitle : recordValue,
      docType,
      docLogs: newDocLogs,
      docObject: formattedDocObject(),
      docStatus: docStatuses[currentStep]?.statusName,
      googleDriveUploads: formattedDriveFiles,
      expirationDate: !!form.getFieldValue("expirationDate")
        ? parseInTz(form.getFieldValue("expirationDate")).valueOf()
        : "",
      requestedDate: !!form.getFieldValue("requestedDate")
        ? parseInTz(form.getFieldValue("requestedDate")).valueOf()
        : "",
      revisedId: saveAsType === "revised" ? renewalId : null,
      renewalId:
        !!renewalId && saveAsType === "original"
          ? null
          : !!renewalId && saveAsType === "renewal"
          ? renewalId
          : saveAsType === "revised"
          ? null
          : renewalId,
      folderId: folderId || proppedFolderId,
      isIncludedIn,
      teamsConfiguration: newTeam,
      enableOnField: enableOnFieldToggle,
      enableOnCustomerPortal: isEnableOnCustomerPortal,
    };

    await API.post("documentation", "/documentation", {
      body: {
        ...bodyObjectToSave,
        recordId: recordId,
        enableOnCustomerPortal: true,
        relatedDocId: Object.keys(categoriesToIncludeIds).filter(Boolean),
      },
    })
      .then(async (r) => {
        filesToBeDeleted.forEach(
          async (id) => await driveRequest.deleteDriveItem(id)
        );

        saveAddedLogs({
          category: "Documentation",
          recordId: r?.docId,
          recordName: `${r?.docType} ${!!r?.renewalId && "(Renewal)"}`,
          topic: r?.recordName,
        });

        let handleCategoryName =
          categoryName === "Clients"
            ? "Accounts"
            : categoryName === "Fleet"
            ? "Fleets"
            : categoryName === "Drivers"
            ? "Fleet/Drivers"
            : categoryName;
        broadcastNotification(
          "12",
          "onProjectsDocumentationCreation",
          [
            {
              common: user,
              topicName:
                categoryName === "Fleet" || categoryName === "Safety"
                  ? categoryName
                  : categoryName === "Opportunities"
                  ? "Opportunity"
                  : categoryName?.substring?.(0, categoryName?.length - 1),
              address: recordValue,
            },
            {
              userName: user,
              currentUser: authenticatedUser?.sub,
              tab: "Documentation",
            },
          ],
          `${handleCategoryName?.toLowerCase?.()}/${recordId}`
        );
        res = r;
        const updatedRes = r.recordId === recordId && r;
        showSuccessMsg();
        if (updatedRes) {
          setDocumentation((prev) => [...prev, updatedRes]);
        }
        if (requestId) {
          afterSaveRequest({
            newRecordId: r?.docId,
            path: null,
            moveFileParams: {
              filesToMove: googleDriveUploads,
              newParentId: folderId,
              accessToken,
            },
          });
        }

        // Trigger the save function on the parent modal (the modal who called the Documentation Modal) to update the google files fields
        if (isModalOpenedFromFileItem) {
          googleDriveUploads.docId = r?.docId;
          await onSaveParentModal();
        }

        resetForm();
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });

    return res;
  };

  //region Update Doc
  const updateDocumentation = async () => {
    setSaveDisabled(true);
    showLoadingMsg();

    const isIncludedIn = form.getFieldValue("isIncludedIn") || isIncludedInProp;

    const categoriesToIncludeIds = await getDocumentToShowIds();

    const updatedDocumentation = {
      folderId,
      docObject: formattedDocObject(),
      docStatus: docStatuses[currentStep]?.statusName,
      googleDriveUploads: formattedDriveFiles,
      expirationDate: !!form.getFieldValue("expirationDate")
        ? parseInTz(form.getFieldValue("expirationDate")).valueOf()
        : null,
      relatedDocId: Object.keys(categoriesToIncludeIds).filter(Boolean),
      requestedDate: !!form.getFieldValue("requestedDate")
        ? parseInTz(form.getFieldValue("requestedDate")).valueOf()
        : null,
      isIncludedIn,
      enableOnField: enableOnFieldToggle,
      enableOnCustomerPortal: isEnableOnCustomerPortal,
      teamsConfiguration: selectedTeam,
    };

    const updatedKeys = getObjectChanges(documentation, updatedDocumentation);

    //we destructure the individual elements of the docObject
    //to make it easier to the logs
    let tmpNewObj = {
        ...updatedDocumentation,
        enableOnField: !!enableOnFieldToggle ? "Enabled" : "Disabled",
        enableOnCustomerPortal: !!isEnableOnCustomerPortal
          ? "Enabled"
          : "Disabled",
      },
      tmpOldObj = {
        ...documentation,
        enableOnField: !!enableOnField ? "Enabled" : "Disabled",
        enableOnCustomerPortal: !!enableOnCustomerPortal
          ? "Enabled"
          : "Disabled",
      };

    delete tmpNewObj["docObject"];
    delete tmpOldObj["docLogs"];
    delete tmpOldObj["docObject"];

    //current data
    updatedDocumentation?.docObject?.forEach((el) => {
      if (el?.hasOwnProperty("value") && !!el?.value) {
        tmpNewObj[el.label] = el.value;
      } else {
        tmpNewObj[el.label] = "Not Set";
      }
    });

    //original data
    documentation?.docObject?.forEach((el) => {
      if (el?.hasOwnProperty("value") && !!el?.value) {
        tmpOldObj[el.label] = el.value;
      } else {
        tmpOldObj[el.label] = "Not Set";
      }
    });

    let newLogsObject = {
      recordId: docId,
      recordName: `${docType} ${!!renewalId ? "(Renewal)" : ""}`,
      category: "Documentation",
      actionType: "Edit",
      topic: recordName,
      currentData: {},
      previousData: {},
      updatedKeys: [],
    };

    for (let key in tmpOldObj) {
      let result;
      if (!!tmpOldObj[key] && !!tmpNewObj[key]) {
        result = getChangedData(tmpNewObj[key], tmpOldObj[key]);
      } else {
        continue;
      }
      if (result !== false) {
        newLogsObject.currentData[key] = result.curr;
        newLogsObject.previousData[key] = result.prev;
        newLogsObject.updatedKeys.push(key);
      }
    }
    if (Object.keys(newLogsObject?.currentData)?.length > 0) {
      saveAddedLogs(newLogsObject);
    }

    await API.patch(
      apiRoutes.documentation,
      `/${apiRoutes.documentation}/${docId}`,
      { body: updatedKeys }
    )
      .then(async (e) => {
        if (docStatus !== updatedDocumentation.docStatus) {
          sendCustomerLiveDashboardUpdate(updatedDocumentation.docStatus);
        }

        // Trigger the save function on the parent modal (the modal who called the Documentation Modal) to update the google files fields
        if (isModalOpenedFromFileItem) {
          await onSaveParentModal();
        }

        showSuccessMsg();
        filesToBeDeleted.forEach(
          async (id) => await driveRequest.deleteDriveItem(id)
        );
        setDocumentation((prev) =>
          prev?.map((item) =>
            item?.docId === docId ? { ...item, ...updatedKeys } : item
          )
        );
        resetForm();
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
    const catName = categoryName.toLowerCase();
    broadcastNotification(
      "12",
      "onDocUpdate",
      [
        { common: user },
        {
          userName: user,
          currentUser: authenticatedUser?.sub,
          cognitos: getCognitosForNotification(userConfiguration),
        },
        users,
      ],
      `${catName}/${recordId}?tab=Documentation&docId=${docId}`
    );
  };

  const originalOrRenewal = async (string) => {
    const checkValues = formCheckBox.getFieldsValue();
    const fields = Object.keys(form.getFieldsValue()).filter(
      (el) => el !== "documentationTeam"
    );
    await formCheckBox.validateFields().then(() => {
      validateForm(
        form,
        () =>
          saveDocumentation(
            checkValues.renewal
              ? "Renewal"
              : checkValues.original
              ? "Original"
              : ""
          ),
        () => {},
        fields
      ).catch((e) => console.error(e));
    });
  };

  const onSave = async () => {
    const fields = Object.keys(form.getFieldsValue()).filter(
      (el) => el !== "documentationTeam"
    );

    const lastModifiedBy = {
      name: userConfiguration?.nameOfUser,
      date: Date.now(),
      id: userConfiguration?.cognitoUserId,
    };

    let rowData = singleDocumentation?.find((el) => el.docId === docId);

    const updatedRowData = {
      ...rowData,
      lastModifiedBy,
      docStatus: docStatuses[currentStep]?.statusName,
    };

    try {
      documentationWS.send(
        JSON.stringify({
          request: "documentationEdited",
          nodeEnv: process.env.NODE_ENV,
          body: { ...updatedRowData },
        })
      );
    } catch (error) {
      console.error("documentationEdited, WebSocket send failed:", error);
    }

    if (docId) {
      await validateForm(form, updateDocumentation, () => {}, fields);
    } else {
      await validateForm(form, saveDocumentation, () => {}, fields);
    }
  };

  const onCancel = async () => {
    resetForm();
    //? not handled correctly
    // if (!!accessToken && !!recordFolderId && !proppedFolderId && !!folderId) {
    //   driveRequest.deleteDriveItem(folderId).catch((e) => console.error(e));
    // }

    //? DELETE NEW ITEMS AND DELETED ITEMS
    const allDriveItemsToDelete = uploadedFiles
      ?.filter(({ newDocFile }) => !!newDocFile)
      ?.map((d) => d?.id)
      ?.concat(filesToBeDeleted);

    await Promise.all(
      allDriveItemsToDelete.map((id) => driveRequest.deleteDriveItem(id))
    ).catch((e) => {
      console.log("Error uploading files: ", e);
    });
  };

  const submitButtonOpenHandler = useCallback(() => {
    const formValues = formCheckBox.getFieldsValue();
    const formKeys = Object.keys(formValues);

    setDisabled(
      formKeys?.some((el) => formValues[el] === true) &&
        !(formValues.original === true && formValues.renewal === true)
    );
  }, [disabled]);

  const categories =
    docConfig?.find((el) => el?.categoryName === categoryName)
      ?.documentationsAvailable || [];

  const thisDocNameIsShownInCategory = [
    ...new Set([
      ...isIncludedInProp,
      ...categories
        ?.filter(
          ({ isIncludedIn = [], docName }) =>
            docName === docType && !isIncludedIn.includes(categoryName)
        )
        .flatMap(({ isIncludedIn }) => isIncludedIn),
    ]),
  ].filter(Boolean);

  const thisDocNameComesFrom = isOpenedByButton
    ? ""
    : categoryConfiguration?.relatedDocs?.find(
        ({ categoryFrom, docName }) => docName === docType && categoryFrom
      )?.categoryFrom || "";

  async function getDocumentToShowIds(optionsToShowDoc = []) {
    const listOfEndpoints = [];
    let unfoundKeys = [];
    let listOfIds = {};

    if (categoryConfiguration !== undefined) {
      for (const endpointName of optionsToShowDoc.concat(categoryName)) {
        listOfEndpoints.push({
          endpoint: dynamicDocumentations[endpointName]?.endpoint,
          idKey: dynamicDocumentations[endpointName]?.primaryKey,
        });
      }
      const currTOPIC = dynamicDocumentations[categoryName];

      const [record] =
        (await filterTables(
          `${currTOPIC?.endpoint}`,
          `${currTOPIC?.primaryKey}`,
          recordId
        ).catch((error) => {
          console.error(
            "error while filtering table of " + currTOPIC?.primaryKey,
            error
          );
        })) || [];

      if (record) {
        listOfEndpoints.forEach(({ endpoint, idKey }) => {
          if (!!record?.[idKey]) {
            Object.assign(listOfIds, {
              [record?.[idKey]]: record?.[idKey],
            });
          } else {
            unfoundKeys.push({ endpoint, idKey });
          }
        });
      }

      await Promise.all(
        unfoundKeys.map(async ({ endpoint, idKey }) => {
          const [otherRecord] = await filterTables(
            endpoint,
            currTOPIC?.primaryKey,
            recordId
          ).catch((e) => {
            console.log("Error in filterTables: ", e);
          });
          if (otherRecord && otherRecord[idKey]) {
            Object.assign(listOfIds, {
              [otherRecord?.[idKey]]: otherRecord?.[idKey],
            });
          }
        })
      );
    }
    return listOfIds;
  }

  useEffect(() => {
    if (!!docId || isModalOpenedFromFileItem) {
      form.setFieldsValue({
        ...dynamicFields?.reduce(
          (acc, { formItemName, value, type }) => ({
            ...acc,
            [formItemName]:
              type !== "datepicker" && type !== "timepicker"
                ? value
                : !!value
                ? parseInTz(value)
                : value,
          }),
          {}
        ),
        expirationDate: !!expirationDate ? parseInTz(expirationDate) : "",
      });
      setUploadedFiles((prev) => uniq([...prev, ...googleDriveUploads]));
      !!expirationDate && setConfigExpirationDate(true);
    }

    //This is the case when we are opening the documentation from the request
    if (
      googleDriveUploads?.length > 0 &&
      !!recordValue &&
      !docId &&
      !!requestId
    ) {
      setUploadedFiles((prev) =>
        uniq([...(prev || []), ...googleDriveUploads])
      );
    }
  }, [docId]);

  useEffect(() => {
    !!docStatus &&
      setCurrentStep(steps.map(({ title }) => title).indexOf(docStatus));
  }, [docStatus, docStatuses]);

  useEffect(() => {
    if (!!accessToken && !!recordFolderId) {
      if (!!proppedFolderId) {
        setFolderId(proppedFolderId);
      } else {
        (async () => {
          try {
            showLoadingMsg({ content: "Creating Folder", key: "docs" });
            const folderId = await driveRequest.getFolderIdOrCreate({
              name: docType,
              parents: [recordFolderId],
            });

            // Creates extra folders if there is any configuration for this doc type
            !!extraFolders?.[docType?.toLowerCase?.()] &&
              (await driveRequest
                .createFolders({
                  folderNames: extraFolders[docType?.toLowerCase?.()],
                  parents: [folderId],
                })
                .catch((e) => {
                  console.log("Error: ", e);
                }));

            setFolderId(folderId);
            showSuccessMsg({
              content: "Folder Created Successfully",
              key: "docs",
            });
          } catch {
            showErrorMsg({ content: "Error Creating Folder", key: "docs" });
          }
        })();
      }
    }
  }, [accessToken, recordFolderId, proppedFolderId]);

  // useEffect(() => {
  //   steps[currentStep]?.title === "Requested" &&
  //     form.setFieldsValue({ requestedDate: dayjs() });
  // }, [currentStep]);

  function validateVal(val, type) {
    let newVal = "";
    if (type === "datepicker" || type === "timepicker") {
      newVal = !!val ? parseInTz(val) : val;
    } else {
      newVal = val;
    }
    return newVal;
  }

  function handleDynamicFields() {
    if (dynamicFields.length) {
      let allFields = dynamicFields;
      allFields.map((el, i) => {
        if (el.recordKey) {
          let recValue = record?.[el.recordKey];
          if (recValue) {
            if (typeof recValue === "object") {
              if (
                el.recordKey === "versionServices" &&
                Object.keys(recValue).length > 0
              ) {
                allFields[i].type = "select";
                allFields[i].options = Object.keys(recValue).map((key) => {
                  return recValue[key].versionName;
                });
                allFields[i].mode = "multiple";
              } else {
                return;
              }
            } else {
              if (!el.value) {
                //don't overwrite the value if it already exists
                form.setFieldValue(
                  el.formItemName,
                  validateVal(recValue, el.type)
                );
              } else {
                form.setFieldValue(
                  el.formItemName,
                  validateVal(el.value, el.type)
                );
              }
              allFields[i].disabled = true;
            }
          }
        }
      });
      setDynamicFields(allFields);
    }
  }

  useEffect(() => {
    const data = location?.state?.data;
    if (data?.allGoogleDriveUploads) {
      setUploadedFiles((prev) =>
        uniq([...prev, ...data?.allGoogleDriveUploads[0]?.googleDriveUploads])
      );
    } else {
      if (!Array.isArray(data?.docObject)) return;
      data?.docObject?.map((object) => {
        if (object.type === "datepicker") {
          form.setFieldValue(object.formItemName, parseInTz(object.value));
        } else {
          form.setFieldValue(object.formItemName, object.value);
        }
      });
      setCurrentStep(steps.map(({ title }) => title).indexOf(data.docStatus));
      setUploadedFiles((prev) => uniq([...prev, ...data.googleDriveUploads]));
    }
  }, [location?.state?.data]);

  useEffect(() => {
    // console.log("apiRoutes: ", apiRoutes);
    setUsers(filterPeopleList(userConfiguration?.allUsers?.Items));
    handleDynamicFields();
    fetchData(apiRoutes?.docConfiguration)
      .then((docConfigRes) => setDocConfig(docConfigRes))
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const isMobileView = useMediaQuery({ query: "(max-width: 575px)" });

  const onGeneratePdf = () => {
    generatePdf({
      companyLogo,
      title: docType || recordValue,
      data: fieldsJSON,
      images: googleDriveUploads,
      driveRequest,
    });
  };

  return (
    <>
      <Modal
        {...{
          ...antdModalProps,
          open: visible,
          onCancel,
          title: docType,
          afterOpenChange: (event) => {
            event && updateDocumentTitle({ newTitle: docType });
          },
          closeIcon: <XIcon />,
          wrapClassName: `cardLayoutModal dynamicDocumentationModal ${
            isDarkMode && "dynamicDocumentationModalDark"
          }`,
          "data-testid": "documentation-modal",
        }}
        footer={
          <ActionButtons
            {...{
              onCancel,
              onDelete: () => setConfirmationModalVisible(true),
              onSave,
              isEditing: !!docId,
              writeRight,
              onGeneratePdf,
              disabled: thisDocNameComesFrom !== "",
              docId,
              docType,
              saveDisabled,
            }}
          />
        }
      >
        <Form form={form}>
          <div className="dynamicDocModalBodyContainer">
            {isMobileView ? (
              <div className="stepper-section-mobile">
                <InputComponent
                  {...{
                    label: "Status",
                    type: "select",
                    formItemName: "stepper",
                    dropdownClassName: isDarkMode && "darkDropDown",
                    customOptions: docStatuses?.map(({ statusName }, i) => ({
                      value: i,
                      label: statusName,
                    })),
                    onSelect(e) {
                      setCurrentStep(e);
                    },
                    defaultValue: currentStep,
                    disabled: thisDocNameComesFrom !== "",
                  }}
                />
              </div>
            ) : (
              <Stepper
                {...{
                  className: "documentationModalStepper",
                  currentStep,
                  setCurrentStep,
                  steps,
                  stepRenderer: false,
                  stepperClassName: "docStatusStepper",
                  statusesColors: docStatuses,
                  disabled: thisDocNameComesFrom !== "",
                }}
              />
            )}
            <div className="docContent">
              <div className="configSection">
                <DocUploader
                  {...{
                    documentation,
                    setDocumentation,
                    folderId,
                    onPickerSuccess,
                    uploadedFiles,
                    onDelete,
                    disabled: thisDocNameComesFrom !== "",
                    checkWarnings: false,
                  }}
                />

                <DocMembers
                  {...{
                    form,
                    docConfig,
                    docType,
                    categoryName,
                    thisDocNameComesFrom,
                    teamsConfiguration,
                    selectedTeam,
                    setSelectedTeam,
                    docId,
                    folderId,
                  }}
                />

                <div className="docShown">
                  <InputComponent
                    key={thisDocNameIsShownInCategory.length}
                    label="This document is included in"
                    formItemName="isIncludedIn"
                    className="doc-select"
                    type="select"
                    mode="multiple"
                    tooltipCategory="Documentations"
                    tooltipKey="docIncludedIn"
                    defaultValue={thisDocNameIsShownInCategory}
                    customOptions={docConfig
                      ?.filter(
                        (el) =>
                          !servicesToNotShow.includes(el?.categoryName) &&
                          el?.categoryName !== categoryName
                      )
                      .map(({ categoryName, categoryId }) => ({
                        key: categoryId,
                        label: categoryName,
                        value: categoryName,
                      }))}
                    disabled={thisDocNameComesFrom !== ""}
                    maxTagPlaceholder={(e) => {
                      return (
                        <Tooltip title={e.map(({ label }) => label).join(", ")}>
                          <span>{`+ ${e.length} more`}</span>
                        </Tooltip>
                      );
                    }}
                    maxTagCount={2}
                  />
                </div>
                <div className="enable-switches">
                  {(categoryName === "Estimations" ||
                    categoryName === "Permit-Drawings") && (
                    <div className="switch-group">
                      <label className="switch-group-label">
                        Enable on Field
                      </label>
                      <MondaySwitch
                        {...{
                          checked: enableOnFieldToggle,
                          onToggleSwitch: (e) => {
                            setEnableOnFieldToggle(e);
                          },
                          onText: "Enabled",
                          offText: "Disabled",
                        }}
                      />
                    </div>
                  )}
                  <div className="switch-group">
                    <label className="switch-group-label">
                      Enable on Customer Portal
                    </label>
                    <MondaySwitch
                      {...{
                        checked: isEnableOnCustomerPortal,
                        onToggleSwitch: (e) => {
                          setIsEnableOnCustomerPortal(e);
                        },
                        onText: "Enabled",
                        offText: "Disabled",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="formSection">
                {thisDocNameComesFrom !== "" && (
                  <div className="docConfigTable infoText">
                    <span className="docCofingTableText">
                      <InfoCircleFilled />
                      <p>
                        This document comes from
                        <span style={{ fontWeight: 600 }}>
                          {thisDocNameComesFrom}
                        </span>
                        . You can't make any changes for this document.
                      </p>
                    </span>
                  </div>
                )}

                <div className="formContainer">
                  {RenderDynamicComponents(
                    fieldsJSON?.filter(
                      (el) => el?.formItemName?.toLowerCase() !== "notes"
                    ),
                    {
                      form,
                      disabled: !writeRight || thisDocNameComesFrom !== "",
                    }
                  )}
                  {fieldsJSON?.find(
                    (el) => el.formItemName.toLowerCase() === "notes"
                  ) &&
                    RenderDynamicComponents(
                      fieldsJSON
                        ?.filter(
                          (el) => el?.formItemName?.toLowerCase() === "notes"
                        )
                        .map((el) => ({
                          ...el,
                          className: "notesFormItem",
                          type: "textarea",
                        })) || [],
                      {
                        form,
                        disabled: !writeRight || thisDocNameComesFrom !== "",
                      }
                    )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>

      {/* <WarningModal
        visible={originalModal}
        setVisible={setOriginalModal}
        title="Renewal or Original"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
        // onKeyPress={(e) => onEnterPress(e)}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Do you want to save this a renewal or original?</p>
          <Form form={formCheckBox}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <AnimatedCheckBox
                {...{
                  label: (
                    <div className="checkboxLabel">
                      <div className="templateLabel">Renewal</div>
                      <Tooltip
                        {...{
                          placement: "right",
                          color: "#71CF48",
                          mouseEnterDelay: 0.5,
                          mouseLeaveDelay: 0,
                        }}
                      />
                    </div>
                  ),
                  form: formCheckBox,
                  formItemName: "renewal",
                  containerClassName: "templateCheckbox",
                  tooltipVisible: false,
                  primaryStyle: { backgroundColor: "#71CF48" },
                  secondaryStyle: { backgroundColor: "#68C142" },
                  onButtonReveal: submitButtonOpenHandler,
                }}
              />

              <AnimatedCheckBox
                {...{
                  label: (
                    <div className="checkboxLabel">
                      <div className="templateLabel">Original</div>
                      <Tooltip
                        {...{
                          placement: "right",
                          color: "#1264A3",
                          mouseEnterDelay: 0.5,
                          mouseLeaveDelay: 0,
                        }}
                      />
                    </div>
                  ),
                  form: formCheckBox,
                  formItemName: "original",
                  containerClassName: "templateCheckbox",
                  tooltipVisible: false,
                  primaryStyle: { backgroundColor: "#1264A3" },
                  secondaryStyle: { backgroundColor: "#0F5C97" },
                  onButtonReveal: submitButtonOpenHandler,
                }}
              />
              {disabled && (
                <MondayButton
                  onClick={() => originalOrRenewal()}
                  Icon={<TickIcon />}
                  disabled={saveDisabled}
                >
                  Save
                </MondayButton>
              )}
            </div>
          </Form>
        </div>
      </WarningModal> */}

      {confirmationModalVisible && (
        <WarningModal
          visible={confirmationModalVisible}
          setVisible={setConfirmationModalVisible}
          title="Delete"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>{CONFIRMATION_MODAL_TEXT}</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setConfirmationModalVisible(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={deleteDocumentation}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </>
  );
};

export default DocumentationModal;
