import "./WithTooltip.scss";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Popconfirm, Popover, Radio, Tooltip } from "antd";
import {
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getCategoryFromPath, getTooltipCat, updatedTopics } from "./helpers";
import { recursivelyReplaceObj } from "../../../utils";
import { InputComponent } from "../../SidebarPages/Fleet/components";
import MondayButton from "../MondayButton/MondayButton";
import { TickIcon } from "../../pages/Settings/settingsComponents/Roles/src";
import { saveDynamicTooltips } from "../../pages/Settings/settingsComponents/Configurations/Tooltips/utils";
import { useEditLogs } from "../../../hooks";

const GENERAL_CATEGORY = "GENERAL";

const WithTooltip = ({
  children,
  tooltipCategory,
  tooltipKey,
  noTooltip = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);

  const [showInputTooltipTitle, setShowInputTooltipTitle] = useState(false);
  const [ownTooltipValue, setOwnTooltipValue] = useState("");
  const [categoryToConfig, setCategoryToConfig] = useState();

  const { saveAddedLogs } = useEditLogs();

  const isAdmin = userConfiguration?.groupName?.toLowerCase?.() === "admin";

  const tooltipConfiguration =
    (programFields || [])?.find(
      ({ fieldName }) => fieldName === "Tooltip Configuration"
    )?.fieldOptions || {};

  const tooltipsData = tooltipConfiguration?.tooltipCategories || {};

  let currSelectedKeys = []; //keys used when navigated to current tooltip title settings
  let tooltipConfig = null; // current tooltip for wrapped element
  let toConfigTooltip = null; //categories to save new tooltip it does not exists
  let pathnameConfig = null; //pathname categories to save new tooltip, this config is found from location pathname

  // if we found tooltip with given tooltipCategory return it
  if (!!tooltipCategory) {
    const tooltipCat = Array.isArray(tooltipCategory)
      ? tooltipCategory
      : [tooltipCategory];

    tooltipConfig = getTooltipCat(tooltipsData, updatedTopics(tooltipCategory));
    currSelectedKeys = [...tooltipCat, tooltipKey];
    toConfigTooltip = tooltipCat;
  }

  //if there is no tooltip with tooltipCategory get category fom location pathname and check if it exists
  if (!tooltipConfig?.[tooltipKey]) {
    const { config, category } = getCategoryFromPath({
      location,
      tooltipsData,
      userConfiguration,
    });
    tooltipConfig = config;
    currSelectedKeys = [...category, tooltipKey];
    pathnameConfig = category;

    if (!toConfigTooltip) toConfigTooltip = category;
  }

  //if filled tooltip  from propped category or pathname make toConfigTooltip as null, that means that tooltip already exists
  //else get GENERAL category as last category
  if (tooltipConfig?.[tooltipKey]) {
    toConfigTooltip = null;
  } else {
    tooltipConfig = tooltipsData?.[GENERAL_CATEGORY];
    currSelectedKeys = [GENERAL_CATEGORY, tooltipKey];
  }

  const finalCategoryToConfig = categoryToConfig || toConfigTooltip;

  const {
    title,
    placement,
    type = "primary",
    disabled,
    hiddenUsers = [],
  } = tooltipConfig?.[tooltipKey] || {};

  const isUserDisabled = hiddenUsers.includes(userConfiguration?.cognitoUserId);

  //this validate users that can see inline creation of tooltipKey with given category
  //admin should check if category is correct before submitting!
  const isUserEligible = tooltipConfiguration?.eligibleUsersToConfig?.includes(
    userConfiguration?.cognitoUserId
  );

  const handleNavigateToConfig = (e) => {
    e.stopPropagation();
    navigate("/settings/tooltips", {
      state: { currSelectedKeys },
    });
  };

  // function to save own tooltip config for curr category
  const handleSaveOwnTooltip = () => {
    const newValue = { title: ownTooltipValue, hiddenUsers: [] };

    const newTooltipChanges = recursivelyReplaceObj({
      key: tooltipKey,
      value: {
        ...newValue,
        createdBy: userConfiguration.cognitoUserId,
        createdAt: Date.now(),
      },
      keys: finalCategoryToConfig,
      obj: tooltipsData,
    });

    saveDynamicTooltips({
      dispatch,
      fieldOptions: {
        ...tooltipConfiguration,
        tooltipCategories: newTooltipChanges,
      },
    }).then(() => {
      setShowInputTooltipTitle(false);
      setOwnTooltipValue("");
      categoryToConfig && setCategoryToConfig(null);

      saveAddedLogs({
        recordId: "Tooltip Categories",
        recordName: finalCategoryToConfig.join(" | "),
        category: "Tooltips",
        topic: finalCategoryToConfig.join(" | "),
        label: tooltipKey,
        currentData: newValue,
        updatedKeys: finalCategoryToConfig,
      });
    });
  };

  const dynamicTooltipContent = (
    <div
      className="dynamic-tooltip"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* <InfoCircleOutlined
        onClick={(e) => {
          console.log({
            tooltipCategory,
            tooltipKey,
            pathnameConfig,
            userConfiguration,
          });
        }}
      /> */}
      <div className="tooltip-title">{title}</div>
      {/* display tooltip icon to go to tooltip configs if user is admin */}
      {isUserEligible && isAdmin && (
        <div className="tooltip-icon" onClick={handleNavigateToConfig}>
          <Tooltip
            {...{
              overlayClassName: `dynamic-tooltip-setting-icon ${type}`,
              mouseEnterDelay: 2,
              title: (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {currSelectedKeys.join(" | ")}
                </span>
              ),
            }}
          >
            <EditOutlined />
          </Tooltip>
        </div>
      )}
    </div>
  );

  const createNewTooltipContent = (
    <>
      {showInputTooltipTitle ? (
        <div
          className="input-container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* if we have tooltipCategory(propped category) it means we can have two array with categories  */}
          {tooltipCategory &&
            !toConfigTooltip?.every((cat) => pathnameConfig.includes(cat)) && (
              <Radio.Group
                options={[
                  { label: toConfigTooltip?.join("|"), value: "propped" },
                  { label: pathnameConfig?.join("|"), value: "pathname" },
                ]}
                defaultValue="propped"
                onChange={(e) => {
                  setCategoryToConfig(
                    e.target.value === "pathname"
                      ? pathnameConfig
                      : toConfigTooltip
                  );
                }}
              />
            )}
          <InputComponent
            label={
              <>
                <InfoCircleOutlined
                  style={{ color: "#1264A3", fontSize: "16px" }}
                />
                Don't click yes if category isn't correct!
              </>
            }
            noFormItem={true}
            placeholder={`${finalCategoryToConfig?.join("|")} - own tooltip!`}
            onChange={(e) => setOwnTooltipValue(e.target.value)}
            inputAllowClear={true}
          />
          <Popconfirm
            zIndex={999999999999999}
            okText="Yes"
            cancelText="No"
            title={
              <>
                <div>
                  Key:&nbsp;<b>{tooltipKey}</b>
                </div>

                <div>
                  Is category: &nbsp;
                  <b>{finalCategoryToConfig?.join(" | ")}</b>
                  &nbsp; correct?
                </div>
              </>
            }
            onConfirm={handleSaveOwnTooltip}
          >
            <MondayButton Icon={<TickIcon />} disabled={!ownTooltipValue} />
          </Popconfirm>
        </div>
      ) : (
        <PlusOutlined
          data-testid="plus-icon"
          onClick={(e) => {
            e.stopPropagation();
            setShowInputTooltipTitle(true);
          }}
          style={{ cursor: "pointer", fill: "#323338" }}
        />
      )}
    </>
  );

  // console.log({ userConfiguration });

  return (
    <Popover
      zIndex={99999999999999}
      overlayClassName="with-tooltip-popover"
      {...{ ...(showInputTooltipTitle && { trigger: "click" }) }} //let it like this
      onOpenChange={() => setShowInputTooltipTitle(false)}
      //This content is displayed if element has tooltipKey but its not saved in program fields
      content={
        isUserEligible && //check if user is eligible
        isAdmin && //it should be admin
        tooltipKey && //check if a key is provided
        !!finalCategoryToConfig && //and finally exist a category to config
        createNewTooltipContent
      }
      arrow={false}
      placement={placement === "left" ? "right" : "left"}
    >
      <Tooltip
        {...{
          overlayClassName: `dynamic-with-tooltip ${type}`,
          placement,
          title:
            !noTooltip &&
            title &&
            !isUserDisabled &&
            !disabled &&
            dynamicTooltipContent,
        }}
      >
        {children}
      </Tooltip>
    </Popover>
  );
};

export default WithTooltip;
