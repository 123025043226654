import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";

import {
  formatDuration,
  FleetsLiveContext,
  findPaginationSize,
  saveLocalPreferences,
} from "../../utils";
import { columnDefs, gridFilters, columnKeys } from "./gridData";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import PayrollLayout from "src/components/pages/Payroll/Layout/PayrollLayout";
import { MondayButton } from "src/components/commonComponents";
import { ExcelIconWhite } from "src/assets";

import "../LiveLocationsView/LiveLocationsView.scss";

function LiveReasonsView() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { allStops, locations, globalAudits, changeMenuKey, selectedDate } =
    useContext(FleetsLiveContext);

  const [gridApi, setGridApi] = useState();
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (!locations?.length) {
      return;
    }

    const data = [];
    const locationDictionary = locations.reduce(
      (acc, val) => ({ ...acc, [val?.fleetId]: { ...val } }),
      {}
    );

    for (const fleetId in globalAudits?.alertChanges || {}) {
      const stopReasons =
        globalAudits["alertChanges"][fleetId]["stopReasons"] || [];

      for (const stopReason of stopReasons) {
        let relatedStop = (allStops?.[fleetId] || []).find(
          ({ beginDate, endDate }) =>
            beginDate === stopReason.beginDate && endDate === stopReason.endDate
        );

        if (relatedStop) {
          data.push({
            fleetName: locationDictionary[fleetId]["fleetName"],
            fleetId: locationDictionary[fleetId]["fleetId"],
            stopType: relatedStop["stopType"],
            beginDate: relatedStop["beginDate"],
            endDate: relatedStop["endDate"],
            duration: relatedStop["duration"],
            reason: stopReason["reason"],
            address: relatedStop["address"],
          });
        }
      }
    }

    setRowData(data);
  }, [allStops, locations, globalAudits]);

  function onVehicleClick(data) {
    saveLocalPreferences({
      auditExternalRedirect: {
        fleetId: data.fleetId,
        fleetName: data.fleetName,
      },
    });
    changeMenuKey("AUDIT");
  }

  function excelExportHandler() {
    if (!gridApi) {
      return;
    }

    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (headerName.includes("Date")) {
          return dayjsNY(value).format("MM/DD/YYYY HH:mm");
        } else if (headerName.includes("Duration")) {
          return formatDuration(value, "minutes").text;
        }

        return value === undefined ? "" : `${value}`;
      },
    });
  }

  return (
    <div
      className={`live-locations-view ${
        isDarkMode ? "live-locations-dark" : ""
      }`}
      id="main-reasons-view"
    >
      <div
        className={`main-payroll-view ${
          isDarkMode && "main-payroll-view-dark"
        }`}
      >
        <PayrollLayout
          {...{
            rowData,
            title: "",
            columnDefs,
            gridFilters,
            hasNew: false,
            rowHeight: 38,
            headerHeight: 40,
            getGridApi: setGridApi,
            suppressDoubleClickEdit: true,
            paginationPageSize: findPaginationSize("main-reasons-view", 38),
            context: {
              onVehicleClick,
            },
            additionalGridProps: {
              defaultExcelExportParams: {
                columnKeys,
                sheetName: "Stop Reasons",
                fileName: `Stop Reasons Report - ${dayjsNY(selectedDate).format(
                  "MM/DD/YYYY"
                )}`,
              },
            },
            additionalActionsButtons: [
              <Tooltip
                title="Excel Export"
                placement="bottom"
                key="excelExport"
              >
                <MondayButton
                  Icon={<ExcelIconWhite />}
                  className="mondayButtonBlue"
                  onClick={() => {
                    excelExportHandler();
                  }}
                >
                  {null}
                </MondayButton>
              </Tooltip>,
            ],
          }}
        />
      </div>
    </div>
  );
}

export default LiveReasonsView;
