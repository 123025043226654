import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { Modal, Form, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { HistoryOutlined, InfoCircleFilled } from "@ant-design/icons";

import {
  Stepper,
  MondayButton,
  WarningModal,
  MultiLevelTreeLogs,
} from "../../../../../../commonComponents";
import { TickIcon } from "../../../Roles/src";
import { useEditLogs } from "../../../../../../../hooks";
import EmployeeUploadContext from "./EmployeeUploadContext";
import PayrollContext from "../../../../../Payroll/PayrollContext";
import { fetchAllData, lazyFetch } from "../../../../../../../utils";
import { UploadEmployeeStep, EmployeesTable } from "./stepsComponents";
import { LeftArrow } from "../../../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { WarningTriangle } from "../../../../../../SidebarPages/DynamicView/src";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { getChangedData } from "../../../../../../SidebarPages/Accounting/components/utilities";
import { downloadExcelTemplate } from "../../../../../../SidebarPages/HrManagement/utils/downloadExcel";

import "./UploadedEmployeesModal.scss";

const Steps = { 0: UploadEmployeeStep, 1: EmployeesTable };

function UploadEmployeesModal({
  open,
  onCancel,
  employeesList,
  setEmployeesList,
}) {
  const { crewTeams: teams, setCrewTeams } = useContext(PayrollContext);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [roles, setRoles] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [gridApi, setGridApi] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [uploadInfo, setUploadInfo] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [logsVisible, setLogsVisible] = useState(false);
  const [employeeType, setEmployeeType] = useState(null);
  const [cancelWarning, setCancelWarning] = useState(false);
  const [uploadedEmployees, setUploadedEmployees] = useState([]);
  const [addEmployeesWarning, setAddEmployeesWarning] = useState(false);
  const [employeesConflicts, setEmployeesConflicts] = useState({
    existingEmployees: [],
    newEmployees: [],
  });

  const modalSteps = [
    { title: "Upload", disabled: true },
    { title: employeeType?.capitalize(), disabled: true },
  ];

  const { saveAddedLogs } = useEditLogs();

  const [form] = Form.useForm();
  const Component = Steps[`${currentStep}`];

  function logsGenerator({ uploadedEmployees = [] }) {
    let logObject = {
      recordId: "",
      recordName: "",
      currentData: {},
      updatedKeys: [],
      previousData: {},
      topic: "Employees",
      actionType: "Upload",
      category: "Crews Upload",
      label: "Uploaded Employees",
    };
    let logsList = [];

    if (!uploadedEmployees?.length) {
      return;
    }

    for (let employee of uploadedEmployees) {
      let existingIndex = employeesList.indexOf(
        ({ crewId }) => crewId === employee?.crewId
      );

      let previousEmployee = employeesList?.[existingIndex];

      let newEditLog = {
        currentData: {},
        updatedKeys: [],
        previousData: {},
        recordId: employee?.crewId,
        recordName: employee.crewName,
      };
      for (let key in employee) {
        let result;
        if (!!employee?.[key] && key !== "crewId" && key !== "foreman") {
          result = getChangedData(employee?.[key], previousEmployee?.[key]);

          if (result !== false) {
            newEditLog.currentData[key] = result.curr;
            newEditLog.previousData[key] = result.prev || "Does not exist";
            newEditLog.updatedKeys.push(key);
          }
        } else {
          continue;
        }
      }
      if (!!newEditLog?.updatedKeys?.length) {
        logsList.push({ ...logObject, ...newEditLog });
      }
    }
    setLogsData((prev) => prev.concat(logsList));
    return logsList;
  }

  function generateCrewTeams({ updatedRows }) {
    const accountName = form.getFieldValue("accountName");
    const crewTeams = _.groupBy(
      updatedRows.filter((row) => !!row?.teamGroup),
      ({ teamGroup }) => teamGroup
    );

    let newTeams = [];
    for (let i = 0; i < Object.keys(crewTeams)?.length; i++) {
      const crewTeamName = Object.keys(crewTeams)[i]; // every key in crewTeams is the crewTeamName
      const teamMembers = crewTeams[crewTeamName];

      // Supervisors is not a team name
      const foreman = teamMembers.filter(({ foreman }) => foreman === true); // in team members find the foremans

      // if there are multiple foremans than we separate the team to multiple teams one per each foreman
      if (foreman.length > 1) {
        let lastIndex = 0;
        foreman.forEach((fEmp, index) => {
          const indexOfForeman =
            teamMembers.findIndex(
              ({ foreman }, i) => foreman === true && i > lastIndex // find next foreman index
            ) || teamMembers.length;

          const crewMembers = teamMembers
            .slice(lastIndex, indexOfForeman)
            .flatMap((member) =>
              member.foreman !== true
                ? {
                    crewId: member?.crewId,
                    crewName: member?.crewName,
                    employeeId: member?.employeeId,
                  }
                : []
            );

          const teamObj = {
            crewMembers,
            crewTeamId: uuidv4(),
            company: accountName,
            crewTeamStatus: "Active",
            createdAt: new Date().valueOf(),
            crewTeamName: `${crewTeamName}${!!index ? " " + index : ""}`,
            createdBy: {
              identityId: userConfiguration?.identityId,
              nameOfUser: userConfiguration?.nameOfUser,
            },
            crewForeman: fEmp?.crewId
              ? {
                  crewId: fEmp?.crewId,
                  crewName: fEmp?.crewName,
                  employeeId: fEmp?.employeeId,
                }
              : {},
          };
          newTeams.push(teamObj); // add new team to newTeams array

          for (let member of crewMembers) {
            // update each member with the foreman assigned in the team
            let memberIndex = updatedRows.find(
              ({ crewId }) => crewId === member?.crewId
            );
            if (memberIndex) {
              // using Object.assign to change the reference of the object in array
              Object.assign(memberIndex, {
                foreman: fEmp?.crewId,
              });
            }
          }

          let foremanIndex = updatedRows.find(
            // update members in foreman Object
            ({ crewId }) => crewId === fEmp?.crewId
          );
          if (foremanIndex) {
            // using Object.assign to change the reference of the object in array
            Object.assign(foremanIndex, { members: crewMembers });
          }

          lastIndex = indexOfForeman || teamMembers.length; // change the last index of the foreman we created team to
        });
      } else {
        // create list of members for the crewTeam
        const crewMembers = teamMembers.flatMap((member) =>
          member.foreman !== true
            ? {
                crewId: member?.crewId,
                crewName: member?.crewName,
                employeeId: member?.employeeId,
              }
            : []
        );
        // create crewTeam obj
        const teamObj = {
          crewMembers,
          crewTeamName,
          crewTeamId: uuidv4(),
          company: accountName,
          crewTeamStatus: "Active",
          createdAt: new Date().valueOf(),
          createdBy: {
            identityId: userConfiguration?.identityId,
            nameOfUser: userConfiguration?.nameOfUser,
          },
          crewForeman: foreman[0]?.crewId
            ? {
                crewId: foreman[0]?.crewId,
                crewName: foreman[0]?.crewName,
                employeeId: foreman[0]?.employeeId,
              }
            : {},
        };
        newTeams.push(teamObj); // add the teamObj to the newTeams array

        for (let member of crewMembers) {
          // update foreman for each member in the team
          let memberIndex = updatedRows.find(
            ({ crewId }) => crewId === member?.crewId
          );

          if (memberIndex) {
            // using Object.assign to change the reference of the object in array
            Object.assign(memberIndex, {
              foreman: foreman[0]?.crewId,
            });
          }
        }

        let foremanIndex = updatedRows.find(
          // update members in foreman
          ({ crewId }) => crewId === foreman[0]?.crewId
        );
        if (foremanIndex) {
          // using Object.assign to change the reference of the object in array
          Object.assign(foremanIndex, { members: crewMembers });
        }
      }
    }
    return newTeams;
  }

  async function addEmployees() {
    message.loading({
      duration: 0,
      key: "uploadEmployees",
      content: "Uploading Employees...",
    });
    let updatedRows = [];
    gridApi.forEachNode(({ data }) => updatedRows.push(data));
    let employeesToPost = updatedRows.flatMap((emp) => {
      // delete keys without losing the reference value in newCrewTeams
      let bodyObj = { ...emp };
      delete bodyObj.crewTeam;
      delete bodyObj?.newRowIndex;
      if (bodyObj?.userId) {
        delete bodyObj?.userId;
        delete bodyObj?.existingRowIndex;
      }
      return bodyObj;
    });

    // Add Engineers or Architects ToDo: Create the Service
    if (["engineers", "architects"].includes(employeeType)) {
      const newEmployees = employeesToPost.map((employee) => ({
        nameOfUser: `${employee.firstName} ${employee.lastName}`,
        userName: employee.email,
        salaryType: employee.salaryType,
        employeeRate: employee.employeeRate,
        createdBy: {
          nameOfUser: userConfiguration?.nameOfUser,
          cognitoId: userConfiguration?.cognitoUserId,
        },
      }));

      let path = employeeType;

      API.post(path, `/${path}`, {
        body: newEmployees,
      })
        .then(async (res) => {
          employeesList.push(res);

          //Save logs
          const employeesLogs = logsGenerator({
            uploadedEmployees: employeesToPost,
          });
          saveAddedLogs(employeesLogs);

          message.success({
            duration: 3,
            key: "uploadEmployees",
            content: "Employees uploaded successfully!",
          });
          setAddEmployeesWarning(false);
          onCancel();
        })
        .catch((error) => {
          message.error({
            key: "uploadEmployees",
            content: "There was a problem uploading Employees!",
          });
          console.log("Error uploading employees: ", error);
          setAddEmployeesWarning(false);
        });

      return;
    }

    const newTeams = generateCrewTeams({ updatedRows }).filter(
      (team) => team?.crewTeamName !== "undefined"
    );
    let employeesToUpdate = updatedRows.flatMap((emp) =>
      employeesList.findIndex((el) => el?.crewId === emp?.crewId) > -1
        ? emp?.crewId
        : []
    );

    let membersUpdatedFromTeam = [];
    teams.forEach(
      ({ crewTeamName = "", crewMembers = [], crewForeman = {} }) => {
        const teamConflict = newTeams.find(
          (team) =>
            team?.crewTeamName === crewTeamName &&
            team?.company === form.getFieldValue("accountName")
        );

        if (teamConflict) {
          let membersToUpdate =
            crewMembers &&
            crewMembers.filter(
              (member) =>
                !teamConflict?.crewMembers &&
                !teamConflict?.crewMembers.some(
                  ({ crewId }) => member?.crewId === crewId
                )
            );
          membersUpdatedFromTeam =
            membersUpdatedFromTeam.concat(membersToUpdate);
          membersUpdatedFromTeam.push({ ...crewForeman, foreman: true });
        }
      }
    );

    if (membersUpdatedFromTeam?.length) {
      const updatedMembers = employeesList.filter(
        ({ crewId }) =>
          membersUpdatedFromTeam.some((member) => member?.crewId === crewId) &&
          !updatedRows.some((member) => member?.crewId === crewId)
      );

      updatedMembers.forEach((member) => {
        const bodyObj = { ...member };

        delete bodyObj.userId;
        delete bodyObj.createdAt;
        delete bodyObj.createdBy;

        if (member.foreman === true) {
          Object.assign(member, { members: [] });
        } else {
          Object.assign(member, { foreman: false });
        }
      });

      employeesToPost = employeesToPost.concat(updatedMembers);

      employeesToUpdate = employeesToUpdate.concat(
        updatedMembers.map(({ crewId }) => crewId)
      );
    }

    try {
      // Delete employees to be updated ( they will be recreated with the updated values)
      await API.del("crews", "/crews/1111", { body: employeesToUpdate });

      await API.post("crews", "/crews", {
        body: employeesToPost,
      }).then((res) => {
        const employeesLogs = logsGenerator({
          uploadedEmployees: employeesToPost,
        });
        const newEmployees = res.map(({ crewId }) => crewId);
        const newEmployeesList = employeesList
          .filter(({ crewId }) => !newEmployees.includes(crewId))
          .concat(res);
        setEmployeesList(newEmployeesList);
        saveAddedLogs(employeesLogs);
      });

      for (let i = 0; i < newTeams.length; i++) {
        const newCreatedTeam = newTeams[i];
        const existingTeam = teams.findIndex(
          (tm) =>
            tm?.crewTeamName === newCreatedTeam?.crewTeamName &&
            tm?.company === form.getFieldValue("accountName")
        );
        if (existingTeam > -1) {
          API.put(
            "crewTeams",
            `/crewTeams/${teams[existingTeam]?.crewTeamId}`,
            {
              body: {
                company: newCreatedTeam?.company,
                crewForeman: newCreatedTeam?.crewForeman,
                crewMembers: newCreatedTeam?.crewMembers,
                crewTeamStatus: newCreatedTeam?.crewTeamStatus,
              },
            }
          );
        } else {
          API.post("crewTeams", "/crewTeams", {
            body: newCreatedTeam,
          });
        }
      }
      setCrewTeams((prev) =>
        prev
          .filter((team) => {
            return !newTeams.some(
              ({ crewTeamName, company }) =>
                crewTeamName === team?.crewTeamName && company === team?.company
            );
          })
          .concat(newTeams)
      );
      message.success({
        duration: 3,
        key: "uploadEmployees",
        content: "Employees uploaded successfully!",
      });
      setAddEmployeesWarning(false);
      onCancel();
    } catch (err) {
      console.log("Error uploading employees: ", err);
      message.error({
        duration: 3,
        key: "uploadEmployees",
        content: "There was a problem uploading Employees!",
      });
      setAddEmployeesWarning(false);
    }
  }

  useEffect(() => {
    if (!!programFields) {
      lazyFetch({
        tableName: "accounts",
        filterValue: "Subcontractors",
        filterKey: "accountRecordType",
        listOfKeys: ["accountName", "accountRecordType"],
      })
        .then((res) => {
          setCompanies(res);
        })
        .catch((error) => {
          message.error("There was a problem getting Companies");
          console.log("Error: ", error);
        });

      fetchAllData({
        resultId: "logId",
        endpoint: "editLogs",
        resultPosition: "editLogs",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  formula: "is",
                  column: "category",
                  value: "Crews Upload",
                },
              ],
            },
          ]),
        },
      }).then((res) => setLogsData(res));

      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        })
      );
      setRoles(fieldObj?.["Crew Position"]);
    }
  }, [programFields]);

  return (
    <Modal
      open={open}
      closable={true}
      centered={true}
      closeIcon={<XIcon />}
      destroyOnClose={true}
      data-testid="upload-employees-modal"
      rootClassName={currentStep === 1 ? "uploadEmployeesModalRoot" : ""}
      onCancel={() => (currentStep !== 0 ? setCancelWarning(true) : onCancel())}
      className={`uploadEmployeesModal ${
        isDarkMode && "uploadEmployeesModalDark"
      } ${currentStep === 1 ? "uploadEmployeesModalTable" : ""}`}
      title={`Upload ${employeeType?.capitalize() || "Employees"} ${
        uploadedEmployees?.[0]?.accountName
          ? " " + uploadedEmployees?.[0]?.accountName
          : ""
      }`}
      footer={[
        <div className="uploadEmployeesFooterDiv">
          <div className="uploadEmployeesFooter">
            <MondayButton
              Icon={<XIcon />}
              className="mondayButtonRed"
              onClick={() =>
                currentStep !== 0 ? setCancelWarning(true) : onCancel()
              }
            >
              Cancel
            </MondayButton>
            {!!currentStep && (
              <MondayButton
                Icon={<LeftArrow />}
                className="mondayButtonBlue"
                onClick={() => setCurrentStep(0)}
              >
                Go Back
              </MondayButton>
            )}
          </div>
          <div className="uploadEmployeesFooter">
            <MondayButton
              className="mondayButtonBlue uploadEmployeeLogs"
              Icon={<HistoryOutlined style={{ width: "1.2rem" }} />}
              onClick={() => setLogsVisible(true)}
            />
            {currentStep === 0 ? (
              <MondayButton
                onClick={() => setCurrentStep(1)}
                Icon={<TickIcon width={17} height={17} />}
                disabled={
                  (uploadedEmployees?.length === 0 &&
                    employeesConflicts?.newEmployees?.length === 0) ||
                  uploading
                }
              >
                Next
              </MondayButton>
            ) : (
              <MondayButton
                Icon={<TickIcon width={17} height={17} />}
                onClick={() => setAddEmployeesWarning(true)}
                disabled={!!employeesConflicts?.newEmployees?.length}
              >
                Save Changes
              </MondayButton>
            )}
          </div>
        </div>,
      ]}
    >
      <EmployeeUploadContext.Provider
        value={{
          form,
          roles,
          gridApi,
          companies,
          uploading,
          uploadInfo,
          setGridApi,
          fetchedData,
          setUploading,
          employeeType,
          setUploadInfo,
          employeesList,
          setFetchedData,
          setCurrentStep,
          setEmployeeType,
          setEmployeesList,
          uploadedEmployees,
          employeesConflicts,
          setUploadedEmployees,
          setEmployeesConflicts,
        }}
      >
        {/* ToDo: Create e separate Component */}
        {employeeType === null && (
          <InputComponent
            type="select"
            label="Employee type"
            formItemName={"employeeType"}
            placeholder="Select employee type..."
            onChange={(value) => setEmployeeType(value)}
            dropdownClassName={isDarkMode && "darkDropDown"}
            customOptions={[
              {
                label: "Crew",
                value: "crews",
              },
              {
                label: "Engineer",
                value: "engineers",
              },
              {
                label: "Architect",
                value: "architects",
              },
            ]}
          />
        )}
        {employeeType && !currentStep && (
          <div className="uploadEmployeeInfo">
            <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
            <p className="uploadEmployeeText">
              Adjust the filters, selecting specific criteria to customize the
              displayed data.
              <br></br>
              {`Get the spreadsheet template for ${employeeType} from `}
              <a onClick={() => downloadExcelTemplate(employeeType)}>here</a>.
            </p>
          </div>
        )}
        {employeeType && (
          <div
            className="uploadEmployeeDiv"
            style={{ width: !!currentStep ? "100%" : "83.5%" }}
          >
            <Stepper
              {...{
                currentStep,
                setCurrentStep,
                steps: modalSteps,
                stepRenderer: false,
                stepperClassName: "employee-upload-stepper",
              }}
            />
            <section style={{ width: "100%" }}>
              <Component />
            </section>
          </div>
        )}
        {addEmployeesWarning && (
          <WarningModal
            closable={true}
            title="Warning Message"
            visible={addEmployeesWarning}
            className="logout-warning-modal"
            setVisible={setAddEmployeesWarning}
          >
            <div className="logout-modal-body">
              <span>
                <WarningTriangle />
              </span>
              <p>Are you sure you want to add all the Employees?</p>
              <div className="buttons">
                <MondayButton
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                  onClick={() => setAddEmployeesWarning(false)}
                >
                  No
                </MondayButton>
                <MondayButton
                  onClick={addEmployees}
                  Icon={<TickIcon width={17} height={17} />}
                >
                  Yes
                </MondayButton>
              </div>
            </div>
          </WarningModal>
        )}

        {cancelWarning && (
          <WarningModal
            closable={true}
            visible={cancelWarning}
            title="Warning Message"
            setVisible={setCancelWarning}
            className="logout-warning-modal"
          >
            <div className="logout-modal-body">
              <span>
                <WarningTriangle />
              </span>
              <p>
                Are you sure you want to Cancel? All the uploads will be lost.
              </p>
              <div className="buttons">
                <MondayButton
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                  onClick={() => setCancelWarning(false)}
                >
                  No
                </MondayButton>
                <MondayButton
                  onClick={onCancel}
                  Icon={<TickIcon width={17} height={17} />}
                >
                  Yes
                </MondayButton>
              </div>
            </div>
          </WarningModal>
        )}
        {logsVisible && (
          <MultiLevelTreeLogs
            {...{
              logsData,
              visible: logsVisible,
              setVisible: setLogsVisible,
              title: "Uploaded Employees Logs",
            }}
          />
        )}
      </EmployeeUploadContext.Provider>
    </Modal>
  );
}

export default UploadEmployeesModal;
