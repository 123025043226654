import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProposalHeader.scss";
import { MondayButton } from "../../../commonComponents";
import { Tag, message, Dropdown, Avatar, Tooltip } from "antd";
// import { useRedux } from "../../../SidebarPages/hooks";
// import TakeOffViewModal from "./components/TakeOffViewModal/TakeOffViewModal";
// import RealTimeTakeOffPreview from "./components/RealTimeTakeOffPreview/RealTimeTakeOffPreview";
import { proposalUpdateHandler } from "../../../SidebarPages/Estimations/components/ProposalBuilder/utils/requests";
import Swal from "sweetalert2";
import ProposalDocumentMerger from "../ProposalPages/FirstProposalPage/ProposalDocumentMerger";
import {
  CommentOutlined,
  EyeFilled,
  RollbackOutlined,
} from "@ant-design/icons";
import { ThreeDotsIcon } from "../../../SidebarPages/Communication/assets";
import { useSelector } from "react-redux";
import DocumentInfoDrawer from "./components/DocumentInfoDrawer/DocumentInfoDrawer";
import { Notes } from "./../../../commonComponents/Notes/Notes";
// import DocuSealForm from "./components/DocuSeal/DocuSealForm/DocuSealForm";
import { useProposalContext } from "../../WaitingRoomProposal/ProposalContext";

function ProposalHeader({ editorRef, onViewChange, onPreview }) {
  const navigate = useNavigate();
  const { nameOfUser = "..." } = useSelector(
    (state) => state?.userConfig?.userConfiguration || { nameOfUser: "..." }
  );
  const [notesVisibility, setNotesVisibility] = useState(false);
  const { proposalData, setProposalData } = useProposalContext();

  const onSaveChanges = async () => {
    message.loading("Saving changes...", 1);
    // console.log("editorHtml", { val: editorRef?.current?.value });
    message.destroy();
    await proposalUpdateHandler({
      proposalId: proposalData?.proposalId,
      proposalData: { savedContent: editorRef?.current?.value || "" },
      wrappedHtml: proposalData?.parentTemplateContent || "",
    })
      .then((result) => {
        message.destroy();
        Swal.fire({
          title: "<strong>Proposal Saved</strong>",
          icon: "success",
          html: "Your changes have been saved! ",
        });
        // .then(() => {
        //   return navigate(-1);
        // });
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          title: "<strong>Proposal Not Saved</strong>",
          icon: "error",
          html: "Your changes have not been saved! ",
        });
        localStorage.setItem(
          `proposaldraft${proposalData?.proposalId}`,
          JSON.stringify(editorRef?.current?.currentContent)
        );
      });
  };

  const onDeleteProposal = async () => {};

  const onGoBack = () => {
    // setProposalData({});
    setProposalData({});
    navigate("/proposalBuilder");
  };

  return (
    <>
      <div className="proposal_builder_header_left">
        {!!proposalData?.estimation ? (
          <h1 className="header__title">
            {" "}
            {proposalData?.estimation?.jobSiteAddress || ""} - (
            {proposalData?.takeOff?.versionName || ""})
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tag color="success">
                Estimation #{proposalData?.estimation?.estimationNumber || ""}
              </Tag>
              <Tag color="gold">
                Client: {proposalData?.estimation?.accountName || ""}
              </Tag>
            </div>
          </h1>
        ) : (
          <h2>Loading data...</h2>
        )}
      </div>
      <div className="proposal_builder_header_right">
        <MondayButton
          className="mondayButtonBlue"
          Icon={<RollbackOutlined />}
          onClick={onGoBack}
        >
          Go Back
        </MondayButton>
        <ProposalDocumentMerger editorRef={editorRef} />
        {/* <DocuSealForm /> */}
        <MondayButton
          className="mondayButtonBlue"
          hasIcon={false}
          onClick={onSaveChanges}
        >
          Save Proposal
        </MondayButton>
        <div className="more_options_holder">
          <DocumentInfoDrawer />
        </div>
        <div className="more_options_holder">
          <Tooltip title="Add Notes">
            <div
              className="more_options_icon"
              onClick={() => setNotesVisibility(true)}
            >
              <CommentOutlined />{" "}
            </div>
          </Tooltip>
        </div>
        <div className="more_options_holder">
          <Tooltip title="Preview Proposal">
            <span
              className="more_options_icon"
              onClick={
                () => onPreview(true)
                // onViewChange
              }
            >
              <EyeFilled />
            </span>
          </Tooltip>
        </div>
        <div className="more_options_holder">
          <Dropdown
            menu={{
              items,
            }}
          >
            <div className="more_options_icon">
              <ThreeDotsIcon />
            </div>
          </Dropdown>
        </div>
        {/* <div>
          <ProposalAssistant />
        </div>{" "} */}
        <div className="more_options_holder">
          <div className="more_options_icon">
            <Tooltip title={nameOfUser || ""}>
              <Avatar
                style={{ backgroundColor: "#AFD198", verticalAlign: "middle" }}
                // icon={<UserOutlined />}
              >
                {nameOfUser?.charAt(0) ?? "..."}
              </Avatar>
            </Tooltip>
          </div>
        </div>
      </div>
      {/* <h1 className="header__title">Proposal Builder </h1> */}
      {notesVisibility && (
        <Notes
          noteModalName={`Proposal notes for ${proposalData?.estimation?.accountName}`}
          rowId={proposalData?.proposalId}
          topicCategory="Proposal"
          notesVisibility={notesVisibility}
          setNotesVisibility={setNotesVisibility}
          // customIcon={<CommentOutlined />}
        />
      )}
    </>
  );
}

export default ProposalHeader;
const items = [
  {
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://portal.corescaffold.com/"
      >
        Go back
      </a>
    ),
    key: "0",
  },
];
