import uniq from "lodash/uniq";
import { formatCurrency, getMonthsTimestamps } from "../../../../utils";
import { boroughs, months } from "../../../../Dashboard/addWidgetsModalData";
import {
  categoriesName,
  getSums,
  groupBy,
} from "../../../../Dashboard/logicDataFunctions/calculatingFunctions";
import {
  getBoroughSum,
  repeatedElementSum,
} from "../../../../Dashboard/NewDashboardFunctions";
import { calculatedPercentages } from "../../../../../../utils/calculatePercentages";

//this returns the filtered data for the specified caegory and subcategory (example .Leads.leadStatus)
export const StraightProgressBarFilter = ({
  updatedDynamicStates,
  programFields,
  id,
  dynamicStates,
  // fleetData,
  // inspectionsData,
  // archData,
  // engData,
  // dispatchData,
  // projectsData,
  clear = false,
  userConfiguration,
}) => {
  const dataToFilter = clear ? dynamicStates[id] : updatedDynamicStates[id];
  return {
    Leads: () => ({
      leadAddress: () =>
        boroughs?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter(
              (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
            )?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: status || "Not Selected",
          done: dataToFilter?.filter(
            (a) => a?.borough?.toLowerCase() === status?.toLowerCase()
          ).length,
        })), //LeadAddress
      leadStatus: () =>
        programFields
          ?.find((a) => a?.fieldName === "Lead Status Types")
          ?.fieldOptions?.map((status) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter((a) => a?.leadStatus === status)?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            done: dataToFilter?.filter((el) => el?.leadStatus === status)
              ?.length,
            status: status || "Not Selected",
          })),

      projectExecutive: () =>
        uniq(dataToFilter?.map((a) => a?.projectExecutive))?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: calculatedPercentages(
            dataToFilter?.filter((a) => a?.projectExecutive === status).length,
            0,
            dataToFilter?.length
          ),
          showInfo: true,
          status: status || "Not Selected",
          done: dataToFilter?.filter((a) => a?.projectExecutive === status)
            .length,
        })),
    }), //Leads

    Claims: () => ({
      claimStatus: () =>
        programFields
          ?.find((e) => e?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Claims?.map((status) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: calculatedPercentages(
              dataToFilter?.filter((a) => a?.claimStatus === status?.statusName)
                ?.length,
              0,
              dataToFilter?.length
            ),
            showInfo: true,
            done: dataToFilter?.filter(
              (a) => a?.claimStatus === status?.statusName
            )?.length,
            status: status?.statusName || "Not Selected",
          })),
    }),
    Applications: () => {
      const applicationStatus = programFields
        ?.find(({ fieldName }) => fieldName === "Status colors")
        ?.fieldOptions?.Requisition.reduce((acc, curr) => {
          acc.statusName = [...(acc.statusName || []), curr.statusName];
          acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
          return acc;
        }, {});

      const statusData = applicationStatus?.statusName?.map((status) =>
        dataToFilter?.filter((a) => a?.applicationStatus === status)
      );
      return {
        applicationStatus: () =>
          applicationStatus?.statusName?.map((status, idx) => ({
            color: applicationStatus?.statusColor[idx],
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter(
                (a) =>
                  a?.applicationStatus?.toLowerCase() === status?.toLowerCase()
              ).length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: status || "Not Selected",
            done: dataToFilter?.filter(
              (a) =>
                a?.applicationStatus?.toLowerCase() === status?.toLowerCase()
            ).length,
            custom: groupBy(statusData, "projectName")?.map((el, index) => {
              return [
                {
                  "Total Amount": {
                    "Current Payment": formatCurrency(
                      getSums({ data: statusData, key: "totalAmount" })[index]
                    ),
                    "Total Retainage": formatCurrency(
                      getSums({ data: statusData, key: "totalRetainage" })[
                        index
                      ]
                    ),
                    "Total Rental With Tax": formatCurrency(
                      getSums({
                        data: statusData,
                        key: "totalRentalWithTax",
                      })[index]
                    ),
                  },
                },
                ...Object?.entries(el)?.map(([key, value]) => {
                  return {
                    [key]: {
                      projectId: value?.[0]?.projectId,
                      "Current Payment": formatCurrency(
                        getSums({ data: value, key: "totalAmount" })
                      ),
                      "Total Retainage": formatCurrency(
                        getSums({ data: value, key: "totalRetainage" })
                      ),
                      "Total Rental With Tax": formatCurrency(
                        getSums({ data: value, key: "totalRentalWithTax" })
                      ),
                    },
                  };
                }),
              ];
            })[idx],
          })),
      };
    }, //Applications
    Rentals: () => {
      const rentalStatus = programFields
        ?.find(({ fieldName }) => fieldName === "Status colors")
        ?.fieldOptions?.Rentals.reduce((acc, curr) => {
          acc.statusName = [...(acc.statusName || []), curr.statusName];
          acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
          return acc;
        }, {});

      const statusData = rentalStatus?.statusName?.map((status) =>
        dataToFilter?.filter((a) => a?.rentalStatus === status)
      );

      return {
        rentalStatus: () =>
          rentalStatus?.statusName?.map((status, idx) => ({
            color: rentalStatus?.statusColor[idx],
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter(
                (a) => a?.rentalStatus?.toLowerCase() === status?.toLowerCase()
              ).length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: status || "Not Selected",
            done: dataToFilter?.filter(
              (a) => a?.rentalStatus?.toLowerCase() === status?.toLowerCase()
            ).length,
            custom:
              dataToFilter &&
              groupBy(statusData, "projectAddress").map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Total Price": formatCurrency(
                        getSums({ data: statusData, key: "totalPrice" })[index]
                      ),
                      "Total Applied Rent": formatCurrency(
                        getSums({ data: statusData, key: "totalAppliedRent" })[
                          index
                        ]
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: statusData, key: "totalTax" })[index]
                      ),
                      "Retainage Amount": formatCurrency(
                        getSums({ data: statusData, key: "retainageAmount" })[
                          index
                        ]
                      ),
                    },
                  },
                  ...Object?.entries(el)?.map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Total Price": formatCurrency(
                          getSums({ data: value, key: "totalPrice" })
                        ),
                        "Total Applied Rent": formatCurrency(
                          getSums({ data: value, key: "totalAppliedRent" })
                        ),
                        "Total Tax": formatCurrency(
                          getSums({ data: value, key: "totalTax" })
                        ),
                        "Retainage Amount": formatCurrency(
                          getSums({ data: value, key: "retainageAmount" })
                        ),
                      },
                    };
                  }),
                ];
              })[idx],
          })),
      };
    },
    Charges: () => {
      const chargeStatus = programFields
        ?.find(({ fieldName }) => fieldName === "Status colors")
        ?.fieldOptions?.Charge.reduce((acc, curr) => {
          acc.statusName = [...(acc.statusName || []), curr.statusName];
          acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
          return acc;
        }, {});
      const statusData = chargeStatus?.statusName?.map((status) =>
        dataToFilter?.filter((a) => a?.categoryFrom === status)
      );

      return {
        categoryFrom: () =>
          chargeStatus?.statusName?.map((status, idx) => ({
            color: chargeStatus?.statusColor[idx],
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter((a) => a?.categoryFrom === status)?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: status || "Not Selected",
            done: dataToFilter?.filter(
              (a) => a?.categoryFrom?.toLowerCase() === status?.toLowerCase()
            ).length,
            custom:
              dataToFilter &&
              groupBy(statusData, "projectAddress")?.map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Charge Amount With Tax": formatCurrency(
                        getSums({ data: statusData, key: "chargeAmount" })[
                          index
                        ] || 0
                      ),
                      "Total Tax": formatCurrency(
                        getSums({ data: statusData, key: "taxAmount" })[
                          index
                        ] || 0
                      ),
                      "Charge Amount Without Tax": formatCurrency(
                        (getSums({ data: statusData, key: "chargeAmount" })[
                          index
                        ] || 0) -
                          (getSums({ data: statusData, key: "taxAmount" })[
                            index
                          ] || 0) || 0
                      ),
                    },
                  },
                  ...Object.entries(el).map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Charge Amount With Tax": formatCurrency(
                          getSums({ data: value, key: "chargeAmount" })
                        ),
                        "Total Tax": formatCurrency(
                          getSums({ data: value, key: "taxAmount" })
                        ),
                        "Charge Amount Without Tax": formatCurrency(
                          getSums({ data: value, key: "chargeAmount" }) -
                            getSums({ data: value, key: "taxAmount" })
                        ),
                      },
                    };
                  }),
                ];
              })[idx],
          })),
      };
    },
    Invoices: () => {
      const invoiceStatus = programFields
        ?.find(({ fieldName }) => fieldName === "Status of Invoices")
        ?.fieldOptions?.reduce((acc, curr) => {
          acc.statusName = [...(acc.statusName || []), curr.statusName];
          acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
          return acc;
        }, {});

      const statusData = invoiceStatus?.statusName?.map((status) =>
        calculateInvoiceStatus({
          table: dataToFilter,
          status,
        })
      );

      return {
        invoiceStatus: () =>
          invoiceStatus?.statusName?.map((status, idx) => ({
            color: invoiceStatus?.statusColor[idx],
            unfilledColor: "#F5F5F5",
            percent: (
              (calculateInvoiceStatus({
                table: dataToFilter,
                status,
              })?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: status || "Not Selected",
            done: calculateInvoiceStatus({
              table: dataToFilter,
              status,
            }).length,
            custom:
              dataToFilter &&
              groupBy(statusData, "projectName")?.map((el, index) => {
                return [
                  {
                    "Total Amount": {
                      "Invoice Amount With Tax": formatCurrency(
                        getSums({
                          data: statusData,
                          key: "totalInvoiceAmount",
                        })[index] || 0
                      ),
                      "Invoice Amount Without Tax": formatCurrency(
                        getSums({ data: statusData, key: "subtotal" })[index] ||
                          0
                      ),
                      "Total Tax": formatCurrency(
                        (getSums({
                          data: statusData,
                          key: "totalInvoiceAmount",
                        })[index] || 0) -
                          (getSums({ data: statusData, key: "subtotal" })[
                            index
                          ] || 0)
                      ),
                    },
                  },
                  ...Object.entries(el).map(([key, value]) => {
                    return {
                      [key]: {
                        projectId: value?.[0]?.projectId,
                        "Invoice Amount With Tax": formatCurrency(
                          getSums({ data: value, key: "totalInvoiceAmount" })
                        ),
                        "Invoice Amount Without Tax": formatCurrency(
                          getSums({ data: value, key: "subtotal" })
                        ),
                        "Total Tax": formatCurrency(
                          getSums({ data: value, key: "totalInvoiceAmount" }) -
                            getSums({ data: value, key: "subtotal" })
                        ),
                      },
                    };
                  }),
                ];
              })[idx],
          })),
      };
    },
    Opportunities: () => ({
      opportunityStatus: () =>
        programFields
          ?.find((a) => a?.fieldName === "Status of opportunities")
          ?.fieldOptions?.map(({ statusName }) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter((a) => a?.opportunityStatus === statusName)
                ?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: statusName || "Not Selected",
            done: dataToFilter?.filter(
              (a) => a?.opportunityStatus === statusName
            )?.length,
          })),

      opportunityStage: () =>
        programFields
          ?.find((a) => a?.fieldName === "Opportunity Stage")
          ?.fieldOptions?.map(({ stageName }) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter((a) => a?.opportunityStage === stageName)
                ?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: stageName || "Not Selected",
            done: dataToFilter?.filter((a) => a?.opportunityStage === stageName)
              ?.length,
          })),

      projectExecutive: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0).toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      projectManager: () =>
        Object.entries(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "label"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),
    }), //opportunities
    Projects: () => ({
      projectStatus: () =>
        programFields
          ?.find((a) => a?.fieldName === "Project Status")
          ?.fieldOptions?.map(({ statusName }) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter((a) => a?.projectStatus === statusName)
                ?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: statusName || "Not Selected",
            done: dataToFilter?.filter((a) => a?.projectStatus === statusName)
              ?.length,
          })),

      attorney: () =>
        Object.entries(repeatedElementSum(dataToFilter || [], "attorney")).map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / (dataToFilter || [])?.length) * 100).toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          })
        ),

      expeditor: () =>
        Object.entries(repeatedElementSum(dataToFilter || [], "expeditor")).map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / (dataToFilter || [])?.length) * 100).toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          })
        ),

      projectExecutive: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0).toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      projectManager: () =>
        Object.entries(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.projectManager)?.flat(),
            "nameOfUser"
          )
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0).toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),
    }), //Projects

    Documentation: () => ({
      docType: () => {
        const data = repeatedElementSum(dataToFilter || [], "docType");

        return Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100).toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        }));
      },

      docStatus: () => {
        const data = repeatedElementSum(dataToFilter || [], "docStatus");

        return Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100).toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        }));
      },
    }), //Documentation
    Scheduling: () => ({
      typeOfWork: () =>
        programFields
          ?.find((a) => a?.fieldName === "Scheduling Types")
          ?.fieldOptions?.map(({ typeName }) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter((a) => a?.typeOfWork === typeName)?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: typeName || "Not Selected",
            done: dataToFilter?.filter((a) => a?.typeOfWork === typeName)
              ?.length,
          })),

      projectExecutive: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "projectExecutive")
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      projectManager: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "projectManagers")
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      scheduleAddress: () =>
        Object.entries(getBoroughSum(dataToFilter, "scheduleAddress")).map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          })
        ),
    }), //schaduling

    Dispatching: () => ({
      fleetName: () =>
        uniq(dataToFilter?.map((a) => a?.fleetName))?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter((a) => a?.fleetName === status)?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: status || "Not Selected",
          done: dataToFilter?.filter((a) => a?.fleetName === status)?.length,
        })),

      driverName: () =>
        uniq(
          dataToFilter?.map((a) => a?.routes?.map((e) => e?.driverName))?.flat()
        )?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter
              ?.map((a) => a?.routes?.map((e) => e?.driverName))
              ?.flat()
              ?.filter((a) => a === status)?.length /
              dataToFilter
                ?.map((a) => a?.routes?.map((e) => e?.driverName))
                ?.flat()?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: status || "Not Selected",
          done: dataToFilter
            ?.map((a) => a?.routes?.map((e) => e?.driverName))
            ?.flat()
            ?.filter((a) => a === status)?.length,
        })),

      dispatchedBy: () =>
        uniq(dataToFilter?.map((a) => a?.dispatchedBy))?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter((a) => a?.dispatchedBy === status)?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: status || "Not Selected",
          done: dataToFilter?.filter((a) => a?.dispatchedBy === status)?.length,
        })),
    }), //Dispatching

    Fleet: () => ({
      fleetYear: () =>
        uniq(dataToFilter?.map((a) => a?.fleetYear))?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter((a) => a?.fleetYear === status)?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: status || "Not Selected",
          done: dataToFilter?.filter((a) => a?.fleetYear === status)?.length,
        })),

      registrationExpDate: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                percent: (
                  (dataToFilter
                    ?.filter(
                      (b) => b?.registrationExpDate >= getMonthsTimestamps()[0]
                    )
                    ?.map((a) => a?.registrationExpDate)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    dataToFilter?.filter(
                      (b) => b?.registrationExpDate >= getMonthsTimestamps()[0]
                    )?.length) *
                    100 || 0
                )?.toFixed(1),
                showInfo: true,
                status: months[i] || "Not Selected",
                done: dataToFilter
                  ?.filter(
                    (b) => b?.registrationExpDate >= getMonthsTimestamps()[0]
                  )
                  ?.map((a) => a?.registrationExpDate)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return data;
        })(),

      inspectionExpDate: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                percent: (
                  (dataToFilter
                    ?.filter(
                      (b) => b?.inspectionExpDate >= getMonthsTimestamps()[0]
                    )
                    ?.map((a) => a?.inspectionExpDate)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    dataToFilter?.filter(
                      (b) => b?.inspectionExpDate >= getMonthsTimestamps()[0]
                    )?.length) *
                    100 || 0
                ).toFixed(1),
                showInfo: true,
                status: months[i] || "Not Selected",
                done: dataToFilter
                  ?.filter(
                    (b) => b?.inspectionExpDate >= getMonthsTimestamps()[0]
                  )
                  ?.map((a) => a?.inspectionExpDate)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return data;
        })(),
    }), //Fleet
    "Fleet Inspections": () => ({
      inspectedBy: () =>
        Object.entries(
          repeatedElementSum(
            dataToFilter?.map((el) => el?.inspectedBy),
            "name"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      driverName: () =>
        Object.entries(
          repeatedElementSum(
            dataToFilter?.map((el) => el?.driverInfo),
            "driver"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      createdAt: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",

                percent: (
                  (dataToFilter
                    ?.filter((b) => b?.createdAt >= getMonthsTimestamps()[0])
                    ?.map((a) => a?.createdAt)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    dataToFilter?.filter(
                      (b) => b?.createdAt >= getMonthsTimestamps()[0]
                    )?.length) *
                    100 || 0
                )?.toFixed(1),
                showInfo: true,
                status: months[i] || "Not Selected",
                done: dataToFilter
                  ?.filter((b) => b?.createdAt >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.createdAt)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return data;
        })(),

      fleetName: () =>
        Object.entries(
          repeatedElementSum(dataToFilter || [], "fleetName")
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / (dataToFilter || [])?.length) * 100 || 0)?.toFixed(
            1
          ),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      inspectionStatus: () =>
        (
          programFields?.find(
            (el) => el?.fieldName === "Statuses Of Categories"
          )?.fieldOptions?.Inspections || []
        )?.map((el) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            ((dataToFilter || [])?.filter(
              (c) => c?.inspectionStatus === el?.statusName
            )?.length /
              (dataToFilter || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          done: (dataToFilter || [])?.filter(
            (c) => c?.inspectionStatus === el?.statusName
          )?.length,
          status: el?.statusName || "Not Selected",
        })),

      inspectionType: () =>
        Object.entries(
          repeatedElementSum(dataToFilter || [], "inspectionType")
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / (dataToFilter || [])?.length) * 100 || 0)?.toFixed(
            1
          ),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      mechanic: () =>
        Object.entries(repeatedElementSum(dataToFilter || [], "mechanic")).map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (value / (dataToFilter || [])?.length) * 100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          })
        ),
    }), //Inspections

    Maintenance: () => ({
      createdAt: () =>
        (() => {
          let data = [];
          for (let i = 0; i < 12; i++) {
            data = [
              ...data,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                percent: (
                  (dataToFilter
                    ?.filter((b) => b?.createdAt >= getMonthsTimestamps()[0])
                    ?.map((a) => a?.createdAt)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    dataToFilter?.filter(
                      (b) => b?.createdAt >= getMonthsTimestamps()[0]
                    )?.length) *
                    100 || 0
                )?.toFixed(1),
                showInfo: true,
                status: months[i] || "Not Selected",
                done: dataToFilter
                  ?.filter((b) => b?.createdAt >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.createdAt)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return data;
        })(),
    }), //Maintenance

    Violations: () => ({
      amount_due: () =>
        (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",
                percent: (
                  ((() => {
                    const filteredArray = dataToFilter
                      ?.filter((b) => b?.issue_date >= getMonthsTimestamps()[0])
                      ?.filter(
                        (b) =>
                          b?.issue_date >= getMonthsTimestamps()[i] &&
                          b?.issue_date <= getMonthsTimestamps("end")[i]
                      )
                      ?.map((a) => a?.amount_due);

                    return !!filteredArray?.length
                      ? filteredArray?.reduce(
                          (previousValue, currentValue) =>
                            parseInt(previousValue) + parseInt(currentValue)
                        )
                      : 0;
                  })() /
                    (() => {
                      const filteredArray = dataToFilter
                        ?.filter(
                          (b) => b?.issue_date >= getMonthsTimestamps()[0]
                        )
                        ?.map((a) => a?.amount_due);

                      return !!filteredArray?.length
                        ? filteredArray?.reduce(
                            (previousValue, currentValue) =>
                              parseInt(previousValue) + parseInt(currentValue)
                          )
                        : 0;
                    })()) *
                    100 || 0
                )?.toFixed(1),
                showInfo: true,
                status: months[i] || "Not Selected",
              },
            ];
          }
          return violations;
        })(),

      issue_date: () =>
        (() => {
          let violations = [];
          for (let i = 0; i < 12; i++) {
            violations = [
              ...violations,
              {
                color: "#71CF48",
                unfilledColor: "#F5F5F5",

                percent: (
                  (dataToFilter
                    ?.filter((b) => b?.issue_date >= getMonthsTimestamps()[0])
                    ?.map((a) => a?.issue_date)
                    ?.filter(
                      (b) =>
                        b >= getMonthsTimestamps()[i] &&
                        b <= getMonthsTimestamps("end")[i]
                    )?.length /
                    dataToFilter?.filter(
                      (b) => b?.issue_date >= getMonthsTimestamps()[0]
                    )?.length) *
                    100 || 0
                )?.toFixed(1),
                showInfo: true,
                status: months[i] || "Not Selected",
                done: dataToFilter
                  ?.filter((b) => b?.issue_date >= getMonthsTimestamps()[0])
                  ?.map((a) => a?.issue_date)
                  ?.filter(
                    (b) =>
                      b >= getMonthsTimestamps()[i] &&
                      b <= getMonthsTimestamps("end")[i]
                  )?.length,
              },
            ];
          }
          return violations;
        })(),
    }), //Violations

    Estimations: () => ({
      engProgress: () =>
        dataToFilter?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: parseInt(a?.engProgress),
          showInfo: true,
          status: a?.engAssignedTo || "Not Selected",
          done: a?.engProgress,
        })),

      archProgress: () =>
        dataToFilter?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: parseInt(a?.engProgress),
          showInfo: true,
          status: a?.archAssignedTo || "Not Selected",
          done: a?.engProgress,
        })),

      estSTATUS: () =>
        programFields
          ?.find((b) => b?.fieldName === "Estimation Status")
          ?.fieldOptions?.map((a) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter(
                (c) =>
                  c?.estSTATUS?.toLowerCase() === a?.statusName?.toLowerCase()
              )?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: a?.statusName || "Not Selected",
            done: dataToFilter?.filter(
              (c) =>
                c?.estSTATUS?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length,
          })),

      archAssignedTo: () =>
        Object.entries(
          repeatedElementSum(dataToFilter || [], "archAssignedTo")
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / (dataToFilter || [])?.length) * 100).toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      engAssignedTo: () =>
        Object.entries(
          repeatedElementSum(dataToFilter || [], "engAssignedTo")
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / (dataToFilter || [])?.length) * 100).toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      statusByArch: () =>
        programFields
          ?.find((b) => b?.fieldName === "Status by Architecture")
          ?.fieldOptions?.map((a) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter(
                (c) =>
                  c?.statusByArch?.toLowerCase() ===
                  a?.statusName?.toLowerCase()
              )?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: a?.statusName || "Not Selected",
            done: dataToFilter?.filter(
              (c) =>
                c?.statusByArch?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length,
          })),

      statusByEng: () =>
        programFields
          ?.find((b) => b?.fieldName === "Status by Engineering")
          ?.fieldOptions?.map((a) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter(
                (c) =>
                  c?.statusByEng?.toLowerCase() === a?.statusName?.toLowerCase()
              )?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: a?.statusName || "Not Selected",
            done: dataToFilter?.filter(
              (c) =>
                c?.statusByEng?.toLowerCase() === a?.statusName?.toLowerCase()
            )?.length,
          })),

      projectExecutive: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "projectExecutive")
        ).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      projectManager: () =>
        Object.entries(
          repeatedElementSum(
            dataToFilter?.map((a) => a.projectManager)?.flat(),
            "nameOfUser"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),
    }), //Estimations

    Safety: () => ({
      safetyApplicationStatus: () =>
        programFields
          ?.find((a) => a?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Safety?.map((status) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter(
                (a) => a?.safetyApplicationStatus === status?.statusName
              )?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: status?.statusName || "Not Selected",
            done: dataToFilter?.filter(
              (a) => a?.safetyApplicationStatus === status?.statusName
            )?.length,
          })),

      safetyName: () =>
        uniq(dataToFilter?.map((safety) => safety?.safetyName))?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter((e) => e?.safetyName === a)?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: a || "Not Selected",
          done: dataToFilter?.filter((e) => e?.safetyName === a)?.length,
        })),

      accountName: () =>
        uniq(
          dataToFilter?.map(
            (safety) => safety?.safetyApplicationObject?.accountName
          )
        )?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter(
              (e) => e?.safetyApplicationObject?.accountName === a
            )?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: a || "Not Selected",
          done: dataToFilter?.filter(
            (e) => e?.safetyApplicationObject?.accountName === a
          )?.length,
        })),

      projectName: () =>
        uniq(
          dataToFilter?.map(
            (safety) => safety?.safetyApplicationObject?.projectName
          )
        )?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter(
              (e) => e?.safetyApplicationObject?.projectName === a
            )?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: a || "Not Selected",
          done: dataToFilter?.filter(
            (e) => e?.safetyApplicationObject?.projectName === a
          )?.length,
        })),

      scheduleAddress: () =>
        uniq(
          dataToFilter?.map(
            (safety) => safety?.safetyApplicationObject?.scheduleAddress
          )
        )?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter(
              (e) => e?.safetyApplicationObject?.scheduleAddress === a
            )?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: a || "Not Selected",
          done: dataToFilter?.filter(
            (e) => e?.safetyApplicationObject?.scheduleAddress === a
          )?.length,
        })),

      safetyApplicationCategory: () =>
        uniq(
          dataToFilter?.map((safety) => safety?.safetyApplicationCategory)
        )?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter((e) => e?.safetyApplicationCategory === a)
              ?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: a || "Not Selected",
          done: dataToFilter?.filter((e) => e?.safetyApplicationCategory === a)
            ?.length,
        })),
    }),
    Citations: () => ({
      citationStatus: () =>
        programFields
          ?.find((a) => a?.fieldName === "Statuses Of Categories")
          ?.fieldOptions?.Safety?.map((status) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter(
                (a) => a?.citationStatus === status?.statusName
              )?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: status?.statusName || "Not Selected",
            done: dataToFilter?.filter(
              (a) => a?.citationStatus === status?.statusName
            )?.length,
          })),

      citationName: () =>
        uniq(dataToFilter?.map((safety) => safety?.citationName))?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter((e) => e?.citationName === a)?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: a || "Not Selected",
          done: dataToFilter?.filter((e) => e?.citationName === a)?.length,
        })),

      employeeName: () =>
        uniq(
          dataToFilter?.map((safety) => safety?.citationObject?.employeeName)
        )?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (dataToFilter?.filter((e) => e?.citationObject?.employeeName === a)
              ?.length /
              dataToFilter?.length) *
              100 || 0
          )?.toFixed(1),
          showInfo: true,
          status: a || "Not Selected",
          done: dataToFilter?.filter(
            (e) => e?.citationObject?.employeeName === a
          )?.length,
        })),
    }),

    Incidents: () => ({
      incidentStatus: () =>
        Object.entries(repeatedElementSum(dataToFilter, "incidentStatus"))?.map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          })
        ),

      incidentCategory: () =>
        Object.entries(
          repeatedElementSum(dataToFilter, "incidentCategory")
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),

      incidentName: () =>
        Object.entries(repeatedElementSum(dataToFilter, "incidentName"))?.map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          })
        ),

      projectName: () =>
        Object.entries(
          repeatedElementSum(
            dataToFilter?.map((a) => a?.incidentObject),
            "projectName"
          )
        )?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        })),
    }),

    "To Do": () => ({
      todoStatus: () =>
        programFields
          ?.find?.(({ fieldName }) => fieldName === "Statuses Of Categories")
          ?.fieldOptions?.["To Do"]?.map?.((status) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              (dataToFilter?.filter?.(
                (a) => a?.todoStatus?.toLowerCase() === status?.statusName
              )?.length /
                dataToFilter?.length) *
                100 || 0
            )?.toFixed(1),
            showInfo: true,
            status: status?.statusName || "Not Selected",
            done: dataToFilter?.filter?.(
              (a) => a?.todoStatus?.toLowerCase() === status?.statusName
            )?.length,
          })),

      todoCategory: () =>
        Object.entries(repeatedElementSum(dataToFilter, "todoCategory"))?.map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: key,
            done: value,
          })
        ),

      relatedTo: () =>
        Object.entries(repeatedElementSum(dataToFilter, "relatedTo"))?.map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          })
        ),

      todoUser: () =>
        Object.entries(repeatedElementSum(dataToFilter, "todoUser"))?.map(
          ([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          })
        ),
    }),

    Approvals: () => {
      const reducedUsers = userConfiguration?.allUsers?.Items?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.cognitoUserId]: curr,
        }),
        {}
      );

      return {
        userRequesting: () => {
          const data = repeatedElementSum(dataToFilter, "userRequesting");

          return Object.entries(data).map(([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: reducedUsers[key]?.nameOfUser || key || "Not Selected",
            done: value,
          }));
        },

        assignedTo: () => {
          const data = repeatedElementSum(dataToFilter, "assignedTo");

          return Object.entries(data).map(([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: reducedUsers[key]?.nameOfUser || key || "Not Selected",
            done: value,
          }));
        },

        category: () => {
          const data = repeatedElementSum(dataToFilter, "category");

          return Object.entries(data).map(([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: key?.capitalize() || key || "Not Selected",
            done: value,
          }));
        },

        responseFrom: () => {
          const data = repeatedElementSum(dataToFilter, "responseFrom");

          return Object.entries(data).map(([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: reducedUsers[key]?.nameOfUser || key || "Not Selected",
            done: value,
          }));
        },

        requestType: () => {
          const data = repeatedElementSum(dataToFilter, "requestType");

          return Object.entries(data).map(([key, value]) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
            showInfo: true,
            status: key || "Not Selected",
            done: value,
          }));
        },
      };
    },

    Inspections: () => ({
      inspectionType: () => {
        const data = repeatedElementSum(dataToFilter, "inspectionType");

        return Object.entries(data).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        }));
      },

      inspectedBy: () => {
        const data = repeatedElementSum(
          dataToFilter?.map((el) => el?.inspectedBy),
          "nameOfUser"
        );

        return Object.entries(data).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        }));
      },

      inspectionStatus: () => {
        const data = repeatedElementSum(dataToFilter, "inspectionStatus");

        return Object.entries(data).map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: ((value / dataToFilter?.length) * 100 || 0)?.toFixed(1),
          showInfo: true,
          status: key || "Not Selected",
          done: value,
        }));
      },
    }),
  }; //StraightProgressLine
};
