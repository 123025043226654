import uniq from "lodash/uniq";

export const citations = (IdHandler, programFields, updatedDynamicStates) => {
  const citationStatus = programFields?.find(
    (e) => e?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Safety;

  return {
    citationStatus: () => ({
      title: "Safety Citation status",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Citations",
        field: "citationStatus",
        title: "Safety Citation status",
        subtitle: "",
        content: citationStatus?.map((status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (e) => e?.citationStatus === status?.statusName
            ).length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status?.statusName,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (e) => e?.citationStatus === status?.statusName
          )?.length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
    citationName: () => ({
      title: "Safety Citation Name",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Citations",
        field: "citationName",
        title: "Safety Citation Name",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (safety) => safety?.citationName
          )
        )
          ?.filter(Boolean)
          ?.map((a) => ({
            color: "#71CF48",
            unfilledColor: "#F5F5F5",
            percent: (
              ((updatedDynamicStates?.[IdHandler] || [])?.filter(
                (e) => e?.citationName === a
              )?.length /
                (updatedDynamicStates?.[IdHandler] || [])?.length) *
              100
            )?.toFixed(1),
            showInfo: true,
            status: a,
            done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
              (e) => e?.citationName === a
            )?.length,
          })),
      },
      id: IdHandler,
      width: "390px",
    }),

    employeeName: () => ({
      title: "Safety Citation Employee",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Citations",
        field: "employeeName",
        title: "Safety Citation Employee",
        subtitle: "",
        content: uniq(
          (updatedDynamicStates?.[IdHandler] || [])?.map(
            (safety) => safety?.citationObject?.employeeName
          )
        )?.map((a) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            ((updatedDynamicStates?.[IdHandler] || [])?.filter(
              (e) => e?.citationObject?.employeeName === a
            )?.length /
              (updatedDynamicStates?.[IdHandler] || [])?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: a,
          done: (updatedDynamicStates?.[IdHandler] || [])?.filter(
            (e) => e?.citationObject?.employeeName === a
          )?.length,
        })),
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
