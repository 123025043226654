import { useState } from "react";
import "./ToggleSwitch.scss";
import {  useSelector } from "react-redux";


//VARIANTS: default, primary, secondary, filled, light
const ToggleSwitch = ({
  label,
  checked,
  onChange = () => {},
  icon,
  className = "",
  dataTestId = "",
  variant = "default",
  disabled = false,
  secondVariant = "", // usage: #ffffff use this prop to show another bg color of switch if provided variants are outside your need
}) => {
  const [isActiveState, setIsActiveState] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const handleToggle = (e) => {
    e.stopPropagation();
    if (disabled) return;
    onChange(!checked);
    setIsActiveState((prev) => !prev);
  };

  const isActive = checked ?? isActiveState;

  return (
    <div
      className={`toggle-switch-container ${className} ${variant} ${
        isActive ? "active" : ""
      } ${disabled ? "disabled" : ""}`}
      data-testid={dataTestId || "toggle-switch-testid"}
      onClick={handleToggle}
      style={secondVariant ? { backgroundColor: secondVariant } : {}}
    >
      <div  className={`toggle-text ${isActive ? "active" : ""}`}>
       <p  style={{ color: isDarkMode ? '#12131B' : '#12131B' }}>
         {!!label ? label : isActive ? "On" : "Off"}
       </p>
      </div>
      <div className={`toggle-ball ${isActive ? "active" : ""}`}>
        {icon && <div className="toggle-ball-icon">{icon}</div>}
      </div>
    </div>
  );
};

export default ToggleSwitch;
