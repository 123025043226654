import { PropTypes } from "prop-types";
import { Modal, Form, message } from "antd";
import { uniq, uniqBy, groupBy } from "lodash";
import React, { useState, useEffect, useMemo, useContext, useRef } from "react";

import {
  lazyFetch,
  filterTables,
  updateDocumentTitle,
} from "../../../../../../utils";
import {
  withinRadius,
  footerButtons,
  GOOGLE_API_KEY,
  activityModalFields,
} from "./payrollActivityModalData";
import {
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../commonComponents";
import JobsiteRatesModal from "./JobsiteRatesModal";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import PayrollActivityMap from "./PayrollActivityMap/PayrollActivityMap";
import { getCoordinatesAndZip } from "../../../../../SidebarPages/Fleet/utils";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import DegModalContext from "../../DEG/components/modalComponents/DegModalContext";
import { DEG_DATE_FORMAT } from "../../DEG/components/modalComponents/utils/cellFunctions";
import {
  parseInTz,
  setDateMonth,
  setHourMinute,
} from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { pointsInsideAPolygon } from "../../../../../SidebarPages/Fleet/fleetsLive/components/Map/utilities/pointsInsideAPolygon";

import "./PayrollActivityModal.scss";

function PayrollActivityModal({
  open,
  onCancel,
  jobsites,
  selectedRow,
  allowEdit = true,
  massEntry = false,
  selectedNodes = [],
  onSave: saveHandler,
  crews: proppedCrews,
  nameEditAllow = false,
  onDelete: deleteHandler,
  accounts: proppedAccounts,
}) {
  const {
    crewTeams,
    emptyData,
    isDarkMode,
    entriesLogs,
    incorrectData,
    degAccessRights,
    jobsiteServices,
    scheduleMatching,
    allServiceOptions,
    setJobsiteServices,
    crews: contextCrews,
    setScheduleMatching,
    defaultServicesPerProject,
    setDefaultServicesPerProject,
  } = useContext(DegModalContext);

  const [crews, setCrews] = useState([]);
  const [sowList, setSowList] = useState([]);
  const [changes, setChanges] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [position, setPosition] = useState(null);
  const [distance, setDistance] = useState(false);
  const [showRoute, setShowRoute] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  const [jobsiteRates, setJobsiteRates] = useState(false);
  const [scheduleMatch, setScheduleMatch] = useState(false);
  const [selectedJobsite, setSelectedJobsite] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(false);

  const modalMapRef = useRef();

  const [form] = Form.useForm();
  const punchType = Form.useWatch("punchType", form);

  useEffect(() => {
    if (!proppedCrews) {
      setCrews(contextCrews);
    } else {
      setCrews(proppedCrews);
    }

    if (!proppedAccounts) {
      lazyFetch({
        tableName: "accounts",
        filterValue: "Subcontractors",
        filterKey: "accountRecordType",
        listOfKeys: ["accountName", "accountId", "accountRecordType"],
      })
        .then((res) => {
          setCompanies(res);
        })
        .catch((err) => {
          console.log("Could not get companies: ", err);
        });
    } else {
      setCompanies(proppedAccounts);
    }
  }, []);

  const getCoordinates = async (address) => {
    const locInfo = await getCoordinatesAndZip(address);
    setPosition(locInfo);
  };

  const getAddressFromLatLng = async (coords) => {
    let lat = coords?.lat || 0;
    let lng = coords?.lng || 0;
    try {
      if (!lat || !lng) {
        throw new Error("No coordinates");
      }
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const address = data?.results[0];
        return address?.formatted_address || null;
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  console.log("selectedRow: ", selectedRow);
  function updateSowList({ currentJobsite }) {
    // schedule service data or project services
    const servicesData =
      jobsiteServices?.[currentJobsite?.jobsiteId] ||
      defaultServicesPerProject?.[currentJobsite?.jobsiteId];

    // services of schedule day in timeStamp or project services
    const servicesInTimeStamp =
      servicesData?.find?.(
        ({ dateRange }) =>
          dateRange?.[0] <= selectedRow?.punchTimeStamp &&
          selectedRow?.punchTimeStamp <= dateRange?.[1]
      )?.scheduleServices ||
      defaultServicesPerProject?.[currentJobsite?.jobsiteId];

    if (servicesData && servicesInTimeStamp) {
      // if services of this jobsite are found setSowList
      let updatedSowList = uniq(
        servicesInTimeStamp.concat(currentJobsite?.services || [])
      );
      setSowList(updatedSowList);
    } else if (
      currentJobsite?.projectId &&
      !jobsiteServices?.[currentJobsite?.jobsiteId]
    ) {
      // if schedule services of this jobsite are not found fetch schedule for this jobsite
      message.loading({ content: "Getting Services...", duration: 0 });
      filterTables("scheduling", "projectId", currentJobsite?.projectId)
        .then((res) => {
          let jobsiteSchData = [];
          for (let schedule of res) {
            let dateRange = [];
            let scheduleServices = [];
            let estimationNumber;
            const { scheduleDays, toBeScheduled } = schedule;
            for (let day of scheduleDays) {
              dateRange.push(dayjsNY(day?.startDate).valueOf());
              dateRange.push(dayjsNY(day?.endDate).valueOf());
            }
            for (let estimation in toBeScheduled) {
              scheduleServices = scheduleServices.concat(
                toBeScheduled[estimation]?.map(({ label }) => label)
              );
              estimationNumber =
                toBeScheduled[estimation]?.[0]?.estimationNumber;
            }

            dateRange = [Math.min(...dateRange), Math.max(...dateRange)];
            if (
              dateRange[0] <= selectedRow?.punchTimeStamp &&
              selectedRow.punchTimeStamp <= dateRange[1]
            ) {
              let updatedSowList = uniqBy(
                scheduleServices.concat(currentJobsite?.services),
                (service) => service
              );
              setSowList(updatedSowList);
            }
            if (
              !(jobsiteServices?.[currentJobsite?.jobsiteId] || [])?.some(
                (services) =>
                  services?.dateRange?.[0] === dateRange?.[0] &&
                  services?.dateRange?.[1] === dateRange?.[1]
              )
            ) {
              jobsiteSchData.push({
                dateRange,
                scheduleServices,
                estimationNumber,
              });
            }
          }

          setJobsiteServices((prev) => ({
            ...prev,
            [currentJobsite?.jobsiteId]: servicesData?.length
              ? [...servicesData, ...jobsiteSchData]
              : jobsiteSchData,
          }));

          message.destroy();
          if (res?.length) {
            message.success("Services fetched successfully!");
          }
          if (res?.length === 0) {
            message.error("There are no services for this job site!");
          }
        })
        .catch((err) => {
          console.log("error getting schedules of project: ", err);
          message.error("There are no services for this job site!");
        });
    } else if (currentJobsite?.projectId) {
      // if we don t find schedule services for this jobsite fetch services from projects

      lazyFetch({
        tableName: "projects",
        listOfKeys: ["projectId", "services"],
        filterKey: "projectId",
        filterValue: currentJobsite?.projectId,
      })
        .then((res) => {
          const project = res?.[0];
          if (project) {
            let projectJobsiteId = currentJobsite?.jobsiteId;
            if (Object.keys(project?.services || {})?.length) {
              let tmpServices = [];
              Object.keys(project?.services)?.forEach((estimation) => {
                let estServices = project?.services?.[estimation]?.map(
                  ({ label }) => label
                );
                tmpServices = tmpServices.concat(estServices || []);
              });
              setSowList(uniq(tmpServices));
              setDefaultServicesPerProject((prev) => ({
                ...prev,
                [projectJobsiteId]: uniq(tmpServices),
              }));
            } else {
              setSowList([]);
              setDefaultServicesPerProject((prev) => ({
                ...prev,
                [projectJobsiteId]: [],
              }));
            }
          }
        })
        .catch((err) =>
          console.log("error getting project for services: ", err)
        );
    } else {
      setSowList([]);
    }
  }

  function onClearJobsiteChange(e) {
    if (!e) {
      setSelectedJobsite(null);
      setSowList([]);
      form.setFieldValue("employeeRate", selectedRow?.employeeRate || 0);
    }
  }

  function onClearPositionChange(e) {
    if (!e) {
      setPosition(null);
      // setJobsiteMatchOptions(jobsites);
      setSelectedJobsite(null);
      setSowList([]);
      form.setFieldValue("jobsiteMatch", null);
    }
  }

  function showAllServices() {
    setSowList(allServiceOptions.map(({ serviceName }) => serviceName));
  }

  const fieldsJSON = useMemo(() => {
    return activityModalFields({
      form,
      crews,
      sowList,
      position,
      jobsites,
      companies,
      emptyData,
      massEntry,
      punchType,
      selectedRow,
      onNameSelect,
      incorrectData,
      selectedNodes,
      nameEditAllow,
      getCoordinates,
      selectedJobsite,
      onJobsiteSelect,
      degAccessRights,
      showAllServices,
      selectedEmployee,
      onPunchTypeSelect,
      onClearJobsiteChange,
      onClearPositionChange,
    });
  }, [
    form,
    crews,
    sowList,
    jobsites,
    position,
    massEntry,
    punchType,
    companies,
    emptyData,
    selectedRow,
    selectedNodes,
    incorrectData,
    degAccessRights,
    selectedJobsite,
    selectedEmployee,
  ]);

  useEffect(() => {
    if (selectedRow && fieldsJSON?.length && !!crews?.length) {
      // const jobsiteOptions = jobsites
      //   .map((jobsite) => {
      //     const distance = withinRadius(
      //       jobsite?.addressPosition,
      //       selectedRow?.punchCoordinates,
      //       parseFloat(jobsite.locationRadius)
      //     );

      //     if (distance?.withinRange) {
      //       return Object.assign(jobsite, {
      //         distance: distance.distanceInFeet,
      //       });
      //     }
      //   })
      //   .filter(Boolean)
      //   .sort((a, b) => b.distance - a.distance);

      const selectedCrew = selectedRow?.employeeId?.length
        ? crews.find(({ employeeId }) => {
            let employeeIdSplit = employeeId.split("-");
            let modifiedEmployeeId = `${employeeIdSplit[0]}-${parseFloat(
              employeeIdSplit[1]
            )}`;
            const employeeMatch =
              selectedRow?.employeeId === employeeId ||
              selectedRow?.employeeId === modifiedEmployeeId;

            return employeeMatch;
          })
        : crews.find(({ crewId }) => crewId === selectedRow?.crewId);

      const jobsiteToSelect = jobsites.find(
        ({ jobsiteId }) => jobsiteId === selectedRow?.jobsiteMatch?.jobsiteId
      );
      const distanceFromJobsite = withinRadius(
        jobsiteToSelect?.addressPosition,
        selectedRow?.punchCoordinates
      ).distanceInFeet;

      // if (jobsiteOptions?.length) {
      //   setJobsiteMatchOptions(jobsiteOptions);
      // }

      let punchDate = selectedRow?.punchDate;
      let punchTime = selectedRow?.punchTime;

      if (punchDate && typeof punchDate === "string") {
        punchDate = parseInTz(punchDate);
        if (punchTime) {
          punchTime = setDateMonth(
            parseInTz(selectedRow?.punchTime),
            punchDate
          );
        }
      }

      updateSowList({ currentJobsite: jobsiteToSelect });
      setSelectedJobsite(jobsiteToSelect);
      setSelectedEmployee(selectedCrew);
      form.setFieldValue("punchType", selectedRow?.punchType);
      form.setFieldValue("companyName", selectedCrew?.accountName);
      // form.setFieldValue("distance", distanceFromJobsite);
      setDistance(distanceFromJobsite);
      form.setFieldsValue({
        ...selectedRow,
        activityStatus: selectedRow?.activityStatus || "Draft",
        salaryType: selectedCrew?.salaryType,
        punchDate,
        punchTime,
      });

      form.setFieldValue("jobsiteMatch", selectedRow?.jobsiteMatch?.jobsiteId);
      if (
        jobsiteToSelect?.payrollType === "Certified Payroll" ||
        jobsiteToSelect?.payrollType === "Prevailing Wage"
      ) {
        form.setFieldValue(
          "employeeRate",
          jobsiteToSelect?.rates?.[selectedCrew?.crewPosition] ||
            selectedCrew?.employeeRate
        );
      } else {
        form.setFieldValue("employeeRate", selectedCrew?.employeeRate || 0);
      }
      form.setFieldValue("employeeFullName", selectedRow?.crewId);
      if (!selectedRow?.punchLocation?.trim()?.length) {
        getAddressFromLatLng(selectedRow?.punchCoordinates).then((address) =>
          form.setFieldValue("punchLocation", address)
        );
        setPosition({ coordinates: selectedRow?.punchCoordinates });
      }
    }
  }, [JSON.stringify(selectedRow), crews]);

  function onPunchTypeSelect(e) {
    if (e === "HR") {
      const hour8 = dayjsNY().set("hour", 8).set("minute", 0).set("second", 0);
      form.setFieldValue("punchTime", hour8);
    }
  }

  function onJobsiteSelect(e) {
    // const directionServices = new window.google.maps.DirectionsService();
    const jobsiteToSelect = jobsites.find(({ jobsiteId }) => jobsiteId === e);
    if (position?.coordinates) {
      const distanceFromJobsite = withinRadius(
        jobsiteToSelect.addressPosition,
        position?.coordinates
      ).distanceInFeet;

      const travelTime = Math.round(distanceFromJobsite / 3.5);
      form.setFieldValue("duration: ", travelTime);

      form.setFieldValue("distanceFromJob", distanceFromJobsite);
      setDistance(distanceFromJobsite);
    } else {
      const distanceFromJobsite = withinRadius(
        jobsiteToSelect.addressPosition,
        selectedRow?.punchCoordinates
      ).distanceInFeet;
      const travelTime = Math.round(distanceFromJobsite / 3.5);
      form.setFieldValue("duration: ", travelTime);

      form.setFieldValue("distanceFromJob", distanceFromJobsite);
      setDistance(distanceFromJobsite);
    }

    if (
      jobsiteToSelect?.payrollType === "Certified Payroll" ||
      jobsiteToSelect?.payrollType === "Prevailing Wage"
    ) {
      form.setFieldValue(
        "employeeRate",
        jobsiteToSelect?.rates?.[selectedEmployee?.crewPosition] ||
          selectedEmployee?.employeeRate
      );
    } else {
      form.setFieldValue("employeeRate", selectedEmployee?.employeeRate);
    }
    const tmpScheduleMatch = scheduleMatching?.[selectedJobsite?.jobsiteId];
    if (tmpScheduleMatch) {
      setScheduleMatch(tmpScheduleMatch);
    } else {
      if (jobsiteToSelect?.projectId) {
        filterTables("scheduling", "projectId", jobsiteToSelect?.projectId)
          .then((res) => {
            if (res?.length) {
              setScheduleMatch(res[0]);
            }
          })
          .catch((error) =>
            console.log("Error getting schedule match for jobsite: ", error)
          );
      }
    }
    setSelectedJobsite(jobsiteToSelect);
    updateSowList({ currentJobsite: jobsiteToSelect });
  }

  function onSave() {
    let tmpObject = form.getFieldsValue();
    if (saveHandler) {
      let punchDate = undefined;
      let punchTime = undefined;
      let punchTimeStamp = 0;
      if (tmpObject?.["punchDate"]) {
        punchDate = parseInTz(tmpObject?.["punchDate"]);

        if (tmpObject?.["punchTime"]) {
          punchTime = setHourMinute(
            punchDate,
            parseInTz(tmpObject?.["punchTime"])
          );
          punchTimeStamp = punchTime.valueOf();
        }
      }

      const scheduleDay = (scheduleMatch?.scheduleDays || [])?.find(
        (el) =>
          dayjsNY(el?.startDate).format(DEG_DATE_FORMAT) ===
          punchDate.format(DEG_DATE_FORMAT)
      );

      const employeeTeam = crewTeams.find((team) => {
        let isForeman;
        if (team?.crewForeman?.crewId) {
          isForeman = team?.crewForeman?.crewId === selectedEmployee?.crewId;
        }

        const isMember =
          team?.crewMembers?.findIndex(
            (member) => member?.crewId === selectedEmployee?.crewId
          ) > -1;
        return isForeman || isMember;
      });

      const distanceFromJob = withinRadius(
        selectedJobsite?.addressPosition,
        selectedRow?.punchCoordinates
      ).distanceInFeet;
      const duration = distanceFromJob / 3.5;

      const objectToSave = {
        ...selectedRow,
        ...tmpObject,
        salaryType: selectedEmployee?.salaryType || "Hourly",
        punchDate,
        punchTime,
        punchTimeStamp,
        employeeId: selectedEmployee?.employeeId || selectedRow?.employeeId,
        employeeFullName:
          selectedEmployee?.crewName || selectedRow?.employeeFullName,
        activityStatus: tmpObject?.activityStatus,
        payrollType: selectedJobsite?.payrollType,
        jobsiteId: selectedJobsite?.jobsiteId,
        scheduleId: scheduleMatch?.scheduleId,
        scheduleAddress: scheduleMatch?.scheduleAddress,
        scheduleDayId: scheduleDay?.id,
        jobsiteMatch: {
          jobAddress: selectedJobsite?.jobAddress,
          jobName: selectedJobsite?.jobName,
          jobsiteId: selectedJobsite?.jobsiteId,
          services: selectedJobsite?.services || [],
        },
        crewName: selectedEmployee?.crewName,
        crewId: selectedEmployee?.crewId,
        crewTeamName: employeeTeam?.crewTeamName,
        crewTeamId: employeeTeam?.crewTeamId,
        distanceFromJob,
        duration,
        punchCoordinates: selectedRow?.punchCoordinates?.lat
          ? selectedRow?.punchCoordinates
          : selectedJobsite?.addressPosition,
      };

      updateDocumentTitle(); // reset document title to "Lead Manager";
      saveHandler(objectToSave);
      setScheduleMatching((prev) => ({
        ...prev,
        [selectedJobsite?.jobsiteId]: scheduleMatch,
      }));
    }
    updateDocumentTitle(); // reset document title to "Lead Manager";
    onCancel();
  }

  function onDelete() {
    if (deleteHandler) {
      deleteHandler(selectedRow);
    }
    updateDocumentTitle(); // reset document title to "Lead Manager";
    onCancel();
  }

  function onNameSelect(crewId) {
    let selectedCrew = crews?.find((crew) => crew?.crewId === crewId);
    if (selectedCrew) {
      let foreman = selectedCrew?.foreman;
      const rate = selectedCrew?.employeeRate || 0;
      if (
        selectedJobsite?.payrollType !== "Prevailing Wage" ||
        !selectedJobsite
      ) {
        form.setFieldValue("employeeRate", rate);
      }
      form.setFieldValue("employeeRole", selectedCrew?.crewPosition);
      if (foreman) {
        if (typeof foreman === "string") {
          const crewTeamOfEmployee = crewTeams.find(
            (team) =>
              !!team?.crewMembers?.find(
                ({ crewId }) => selectedCrew?.crewId === crewId
              )
          );
          form.setFieldValue("crew", crewTeamOfEmployee?.crewTeamName);
          form.setFieldValue("companyName", crewTeamOfEmployee?.companyName);
        } else {
          const crewTeamOfForeman = crewTeams?.find(
            ({ crewForeman }) =>
              crewForeman?.crewId &&
              crewForeman?.crewId === selectedCrew?.crewId
          );
          form.setFieldValue("crew", crewTeamOfForeman?.crewTeamName);
          form.setFieldValue("companyName", crewTeamOfForeman?.companyName);
        }
      } else {
        form.setFieldValue("crew", "");
      }

      let company = selectedCrew?.accountName;
      if (company) {
        let relatedCompany = companies?.find(
          ({ accountName }) => accountName === company
        );
        if (relatedCompany) {
          form.setFieldValue("companyName", relatedCompany?.accountName);
        } else {
          form.setFieldValue("companyName", "");
        }
      } else {
        form.setFieldValue("companyName", "");
      }
      setSelectedEmployee(selectedCrew);
    }
  }

  function onEmployeeLocationClick({ lat, lng }) {
    if (lat && lng) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
        "_blank"
      );
    }
  }

  function onJobsiteClick({ lat, lng }) {
    if (lat && lng) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
        "_blank"
      );
    }
  }

  useEffect(() => {
    if (position) {
      const { coordinates } = position;

      // const jobsitesCloseToPunchIn = jobsites?.filter(
      //   ({ addressPosition, locationRadius }) =>
      //     withinRadius(addressPosition, coordinates, parseFloat(locationRadius))
      //       ?.withinRange
      // );

      const chosenJobsite =
        jobsites?.find(
          ({ geofenceInfo }) =>
            !!geofenceInfo?.find(({ geoFenceInfo }) => {
              if (geoFenceInfo) {
                return pointsInsideAPolygon(
                  [coordinates?.lat || 0, coordinates?.lng || 0],
                  geoFenceInfo?.map(({ lat, lng }) => [lat, lng])
                );
              } else {
                return false;
              }
            })
        ) ||
        jobsites
          ?.map((jobsite) => {
            const distance = withinRadius(
              jobsite?.addressPosition,
              coordinates,
              parseFloat(jobsite?.locationRadius || 0)
            );

            if (distance?.withinRange) {
              return Object.assign(jobsite, {
                distance: distance.distanceInFeet,
              });
            }
          })
          .filter(Boolean)
          .sort((a, b) => b?.distance - a?.distance)[0];

      if (chosenJobsite) {
        const { addressPosition } = chosenJobsite;

        const distanceFromJobsite = withinRadius(
          addressPosition,
          coordinates
        )?.distanceInFeet;

        form.setFieldValue("jobsiteMatch", chosenJobsite?.jobName);
        form.setFieldValue("geofence", chosenJobsite?.jobName);
        setDistance(distanceFromJobsite);
        updateSowList({ currentJobsite: chosenJobsite });
        setSelectedJobsite(chosenJobsite);
        // setJobsiteMatchOptions(jobsitesCloseToPunchIn);
      } else {
        form.setFieldValue("jobsiteMatch", null);
        // form.setFieldValue("distance", null);
        setDistance(false);
        updateSowList({ currentJobsite: undefined });
        message.error("There is no job site on this punch in location!");
        // setJobsiteMatchOptions(jobsites);
      }
    } else {
      form.setFieldValue("jobsiteMatch", null);
      // form.setFieldValue("distance", null);
      updateSowList({ currentJobsite: undefined });
      // setJobsiteMatchOptions(jobsites);
    }
  }, [position, jobsites]);

  useEffect(() => {
    if (selectedNodes?.length) {
      let services = [];
      let typeList = [];
      const uniqueJobsites = groupBy(
        selectedNodes,
        ({ jobsiteId }) => jobsiteId
      );
      for (const jobsiteId in uniqueJobsites) {
        const scheduleDataOfJob = jobsiteServices?.[jobsiteId];
        const nodes = uniqueJobsites[jobsiteId];

        for (const entry of nodes) {
          typeList.push(entry?.punchType);
          if (scheduleDataOfJob) {
            const servicesOfJob = scheduleDataOfJob.find(
              ({ dateRange }) =>
                dateRange[0] <= entry?.punchTimeStamp &&
                entry?.punchTimeStamp <= dateRange[1]
            );
            const jobsiteDefaultServices = entry?.jobsiteMatch?.services || [];
            if (servicesOfJob?.scheduleServices?.length) {
              services = services
                .concat(servicesOfJob?.scheduleServices)
                .concat(jobsiteDefaultServices);
            }
          }
        }
      }

      if (uniq(typeList)?.length === 1) {
        form.setFieldValue("punchType", typeList?.[0]);
      }
      const tmpSowList = uniq(services);
      setSowList(tmpSowList);
    }
  }, [selectedNodes]);

  async function getDirections() {
    if (showRoute) {
      setShowRoute(false);
      return;
    }
    const direction = new google.maps.DirectionsService();
    await direction
      .route(
        {
          origin: position?.coordinates || selectedRow?.punchCoordinates,
          destination: selectedJobsite?.addressPosition,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (directionRes) => {
          message.destroy();
          setShowRoute(directionRes);
        }
      )
      .catch((err) => {
        message.error({
          content: "Something went wrong while getting directions",
          key: "directionService",
        });
        console.log("Error getting directions: ", err);
      });
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        closeIcon: <XIcon />,
        title: "Activity Modal",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Activity Modal" });
        },
        wrapClassName: "payroll-activity-modal-wrap",
        className: `payroll-activity-modal-container ${
          isDarkMode && "payroll-activity-modal-container-dark"
        }`,
        closable: true,
        centered: true,
        destroyOnClose: true,
        footer: footerButtons({
          onCancel: () => {
            onCancel();
            updateDocumentTitle(); // reset document title to "Lead Manager";
          },
          onSave,
          onDelete,
          allowEdit,
          editMode: !!selectedRow,
          onShowLogs: () => setLogsVisible(selectedRow?.entryId),
        }),
      }}
    >
      <Form form={form} onFieldsChange={() => !changes && setChanges(true)}>
        {RenderDynamicComponents(fieldsJSON, { form })}
        {selectedJobsite?.googleSheetLink && (
          <a onClick={() => window.open(selectedJobsite?.googleSheetLink)}>
            {selectedJobsite?.googleSheetLink}
          </a>
        )}
        <div className="positionInfo">
          {selectedJobsite?.payrollType === "Prevailing Wage" && (
            <MondayButton
              {...{
                onClick() {
                  setJobsiteRates(true);
                },
                hasIcon: false,
                className: "mondayButtonBlue",
              }}
            >
              Show Jobsite Rates
            </MondayButton>
          )}
          <MondayButton
            className="mondayButtonBlue"
            hasIcon={false}
            onClick={getDirections}
          >
            Show Route
          </MondayButton>
          {typeof distance === "number" && (
            <div className="distanceContainer">
              <label className="labeledInputLabel">Distance:</label>
              <div>
                {distance > 5280
                  ? (parseFloat(distance / 5280).toFixed(2) || 0) + " miles"
                  : (distance || 0) + " .ft"}
              </div>
            </div>
          )}
        </div>
      </Form>
      <div className="geofenceContainer">
        <div
          style={{
            gap: 20,
            display: "flex",
          }}
        >
          <label className="labeledInputLabel">Geofence</label>
          {!!selectedJobsite && (
            <label
              style={{
                color: "#1264A3",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => onJobsiteClick(selectedJobsite?.addressPosition)}
            >
              Go to Jobsite location
            </label>
          )}
          {(!!position?.coordinates?.lat ||
            !!selectedRow?.punchCoordinates?.lat) && (
            <label
              style={{
                color: "#1264A3",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() =>
                onEmployeeLocationClick(
                  position?.coordinates
                    ? position?.coordinates
                    : selectedRow?.punchCoordinates
                )
              }
            >
              Go to Employee location
            </label>
          )}
        </div>
        {!!selectedJobsite ||
        !!selectedRow?.punchCoordinates?.lat ||
        !!position?.coordinates?.lat ? (
          <PayrollActivityMap
            ref={modalMapRef}
            defaultMarker={selectedJobsite?.addressPosition}
            defaultAddress={selectedJobsite?.jobsiteAddress}
            geoFenceInfo={selectedJobsite?.geofenceInfo}
            employeePosition={
              position?.coordinates?.lat
                ? position?.coordinates
                : selectedRow?.punchCoordinates?.lat
                ? selectedRow?.punchCoordinates
                : undefined
            }
            radius={
              selectedRow?.searchRadius
                ? selectedRow?.searchRadius
                : selectedJobsite?.locationRadius
            }
            directions={showRoute}
          />
        ) : (
          <div className="modalInfo">Add Jobsite Match or Punch Location.</div>
        )}
      </div>
      {jobsiteRates && (
        <JobsiteRatesModal
          {...{
            open: jobsiteRates,
            onCancel() {
              updateDocumentTitle(); // reset document title to "Lead Manager";
              setJobsiteRates(false);
            },
            jobsite: selectedJobsite,
          }}
        />
      )}
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: entriesLogs[logsVisible],
            title: `Entry Logs`,
          }}
        />
      )}
    </Modal>
  );
}

PayrollActivityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  jobsites: PropTypes.array,
  allowEdit: PropTypes.bool,
  massEntry: PropTypes.bool,
  selectedNodes: PropTypes.array,
  onSave: PropTypes.func,
  crews: PropTypes.array,
  nameEditAllow: PropTypes.bool,
  onDelete: PropTypes.func,
  accounts: PropTypes.array,
  selectedRow: PropTypes.objectOf({
    activityStatus: PropTypes.string,
    company: PropTypes.string,
    createdAt: PropTypes.number,
    crewId: PropTypes.string,
    crewName: PropTypes.string,
    crewTeamId: PropTypes.string,
    crewTeamName: PropTypes.string,
    degId: PropTypes.string,
    distanceFromJob: PropTypes.number,
    duration: PropTypes.number,
    employeeFullName: PropTypes.string,
    employeeId: PropTypes.string,
    employeeRate: PropTypes.number,
    employeeRole: PropTypes.number,
    employeeType: PropTypes.instanceOf(Date),
    entryId: PropTypes.string,
    jobsiteId: PropTypes.string,
    jobsiteMatch: {
      jobName: PropTypes.string,
      jobsiteId: PropTypes.string,
      services: PropTypes.arrayOf(PropTypes.string),
      jobAddress: PropTypes.string,
    },
    payrollCategory: PropTypes.string,
    payrollType: PropTypes.string,
    punchCoordinates: PropTypes.objectOf({
      lng: PropTypes.number,
      lat: PropTypes.number,
    }),
    punchDate: PropTypes.instanceOf(Date),
    punchLocation: PropTypes.string,
    punchTime: PropTypes.instanceOf(Date),
    punchTimeStamp: PropTypes.number,
    punchType: PropTypes.string,
    reason: PropTypes.string,
    removed: PropTypes.bool,
    salaryType: PropTypes.string,
    serviceOptions: PropTypes.arrayOf(PropTypes.string),
    sow: PropTypes.arrayOf(PropTypes.string),
    totalOvh: PropTypes.number,
    uploadId: PropTypes.string,
    uploadName: PropTypes.string,
  }),
};

export default PayrollActivityModal;
