import "./ProgressLine.scss";
import { useSelector } from "react-redux";

const ProgressLine = ({ label, value, background, onClick = () => {} }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      className="progress-line-item"
      style={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="line-label"
        style={{
          background: `transparent linear-gradient(270deg, ${background} 10%, ${
            isDarkMode ? "#12131b" : "#ffffff"
          } 100%)`,
          ...(isDarkMode && { color: "#f2f2f2" }),
        }}
      >
        {label}
      </span>
      <span className="line-value" style={{ background }}>
        {value}
      </span>
    </div>
  );
};

export default ProgressLine;
