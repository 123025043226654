import JoditEditor, { Jodit } from "jodit-react";
import React, { useState, forwardRef, useMemo } from "react";
import { useProposalContext } from "../../WaitingRoomProposal/ProposalContext";
import { uniq, uniqBy } from "lodash";
// function preparePaste(jodit) {
//   jodit.e.on(
//     "paste",
//     (e) => {
//       if (confirm("Change pasted content?")) {
//         jodit.e.stopPropagation("paste");
//         jodit.s.insertHTML("<p>Changed content</p>");
//         return false;
//       }
//     },
//     { top: true }
//   );
// }

// Jodit.plugins.add("preparePaste", preparePaste);

const JoditEditorCustom = forwardRef((props, ref) => {
  const [content, setContent] = useState({
    initialized: false,
    content: "",
  });

  const { proposalData, setProposalData } = useProposalContext();

  // const [proposalData, setProposalData] = useRedux(" proposalData");
  const savedContent = proposalData?.savedContent ?? "";

  // const onInsert = () => {
  //   console.log("editor", ref);

  //   if (ref.current) {
  //     // Get Jodit editor instance
  //     // const editor = ref.current;
  //     console.log("editor", ref);
  //     // Insert HTMfL content programmatically
  //     ref.current.component.selection.insertHTML(
  //       "<p>This is inserted HTML content</p>"
  //     );
  //   }
  // };

  const config = useMemo(() => {
    return {
      // theme: "dark",
      // Jodit Editor configuration options
      readonly: false, // Ensure it's set to false if you want to insert content
      toolbarButtonSize: "large",
      height: 720,
      allowResizeY: false,
      allowResizeX: false,
      // add page break
      enter: "BR",
      mode: "document",
      buttons: [
        ...Jodit.defaultOptions.buttons,
        {
          name: "Insert Page Break",
          tooltip: "Insert Page Break",
          icon: "plus",
          exec: (editor) => {
            editor.s.insertHTML(
              `<div style="page-break-after: always;"></div>`
            );
          },
        },
        {
          name: "Insert Date",
          tooltip: "Insert current Date",
          exec: (editor) => {
            editor.s.insertHTML(new Date().toDateString());
          },
        },
        {
          name: "Insert Time",
          tooltip: "Insert current Time",
          exec: (editor) => {
            editor.s.insertHTML(new Date().toLocaleTimeString());
          },
        },
      ],

      // Other configurations as needed
    };
  }, []);

  const handleChange = (newContent) => {
    console.log("handleChange ~ newContent:", { newContent });
    // Process content to add borders to tables (if needed)
    let processedContent = newContent.replace(
      /<table(.*?)>/g,
      '<table style="border: 1px solid #ccc; border-collapse: collapse;"$1>'
    );
    processedContent = processedContent.replace(
      /<td(.*?)>/g,
      '<td style="border: 1px solid #ccc; padding: 8px;"$1>'
    );

    processedContent = processedContent.replace(
      /<th(.*?)>/g,
      '<th style="border: 1px solid #ccc; padding: 8px;"$1>'
    );

    // Update the state with processed content
    setContent({ initialized: true, content: processedContent });
  };

  return (
    <div id={"editor"}>
      <JoditEditor
        ref={ref}
        theme="dark"
        value={savedContent || content.content}
        config={config}
        // onChange={handleChange}
      />
    </div>
  );
});

export default JoditEditorCustom;
