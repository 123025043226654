import { useState } from "react";
import { Button, message } from "antd";
import _ from "lodash";
import { getNewId } from "../../../tools/polyfillers";
import {
  createPLIAddon,
  createSideWalkShedPLIAddon,
} from "../../../tools/polyfillers/AddonsCreator";
import { MandatoryFields } from "../../../models/MandatoryFields";
import { useRedux } from "../../../../../hooks";
import { MatrixIcon } from "../../../../../../../assets";
import useTakeOffAccessRights from "../../../tools/controllers/useTakeOffAccessRights";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
const SidewalkShedAddonsRenderer = (props) => {
  const [data, setData] = useState({
    value: props.value,
    length: props.value,
  });
  const [isWritable] = useRedux("estimationsIsWritable");
  const [gridData] = useRedux("takeOffTableData");
  const { canAddOrRemoveAddons = false } = useTakeOffAccessRights("Take-Off");

  /**
   * This method calls parent method saveSidewalkShedAddonsData. It comes from dataEntryGrid and it is defined as prop in ServiceColumnDefiner.
   * @param addons {Object[]} Takes the modified addons array as parameter to pass them to dataEntryGrid which handles saving in database
   * Other props used in this function are defined in ServiceColumnDefiner too.
   * */
  const saveNewAddonData = (temp) => {
    const addonsData = structuredClone(temp); // props.api
    const gridApi = props.api;
    const {
      serviceIndex = "",
      optionIndex = "",
      elevationIndex = "",
      data = {},
    } = props;
    const { id = "" } = data;
    const itemId = id;

    let addonAPI = false;
    let newGridData = _.cloneDeep(gridData);
    let items;
    // if(gridData[serviceIndex].serviceId === 3) {
    //   items = gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].item.test.items
    // } else {
    //   items = gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex].items
    // }
    items =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
        .items;

    for (const pli of items) {
      if (pli.id === itemId) {
        if (newGridData[serviceIndex].serviceId !== 1) {
          //if not in sidewalk shed, manipulate data
          for (let i = 0; i < addonsData.length; i++) {
            let generatedAddon = {};
            for (const key in addonsData[i]) {
              generatedAddon[key.toLowerCase()] = addonsData[i][key];
            }
            addonsData[i] = generatedAddon;
          }
        }

        pli.addons = addonsData;

        gridApi.applyTransaction({ update: [pli] });
        gridApi.refreshCells({ rowNodes: [pli], force: true });
        break;
      }
    }
    // gridApi.setRowData(items)
    // gridApi.redrawRows()
    // updateStateAndSave(newGridData);
    gridApi.getRowNode(itemId).setExpanded(true);
    if (addonAPI) {
      const { addonId, api } = addonAPI;
      api.forEachNode((n) => {
        if (n.data.id === addonId) {
          n.setExpanded(true);
        }
      });
    }

    // props.saveSidewalkShedAddonsData(
    //   props.api,
    //   props.serviceIndex,
    //   props.optionIndex,
    //   props.elevationIndex,
    //   props.data.id,
    //   addons
    // );
  };

  /**
   * Insert an empty addon object inside the corresponding service
   * It is not saved into database. It will be saved in database after there is any edit in any cell of it.
   * */
  const addAddon = () => {
    console.log("ledjoaddAddon", props.data);
    if (!canAddOrRemoveAddons) {
      message.error("You have no clearance to add an addon.");
    } else {
      if (isWritable) {
        // message.info("Please, fill the PLI with data then create an addon.");
        //it comes from dataEntryGrid component. It is attached to columnDefinitions by some manipulations
        let pli = _.cloneDeep(props.data);

        //check if pli has enough data
        let pliHasEnoughData = true;
        if (!!MandatoryFields[pli.serviceId]) {
          for (const field of MandatoryFields[pli.serviceId]) {
            //field must exist, it must be a valid number (not infinity)
            if (
              pli[field] === undefined ||
              pli[field] === Infinity ||
              isNaN(parseFloat(pli[field]))
            ) {
              pliHasEnoughData = false;
              break;
            }
          }
        }

        if (!pliHasEnoughData) {
          message.error("Please, fill the PLI with data then create an addon.");
          return;
        }

        const highestId = getNewId(pli.addons);

        let addon;
        if (props.data.serviceId === 1) {
          addon = createSideWalkShedPLIAddon(
            highestId,
            pli.length,
            pli.height,
            pli.width
          );
        } else {
          addon = createPLIAddon(highestId);
        }

        pli.addons.push(addon);

        console.log("pli", pli);
        // this.props.onCellValueChanged();
        // const res = this.props.api.applyTransaction({update: [pli]});
        // this.props.api.setExpanded(true);
        // this.props.api.getRowNode(pli.id).setExpanded(false)

        //TODO: Uncomment this line
        // saveNewAddonData(pli.addons);
        props.data.addons = pli.addons;

        props?.api?.applyTransaction({
          update: [props.data],
        });

        // let temp = [];
        // props.api.forEachNode((node) => {
        //   temp.push(node.data);
        // });
        // props.api.setRowData(temp);
        // props?.api?.getRowNode(props?.data?.id).setExpanded(true);
        props.handleSave();
      } else {
        message.error("Please enable write mode.");
      }
    }
  };

  const removeLastAddon = () => {
    if (!canAddOrRemoveAddons) {
      message.error("You have no clearance to remove an addon.");
    } else {
      if (isWritable) {
        //it comes from dataEntryGrid component. It is attached to columnDefinitions by some manipulations
        let pli = _.cloneDeep(props.data);
        if (pli.addons.pop()) {
          // const res = this.props.api.applyTransaction({update: [pli]});
          saveNewAddonData(pli.addons);
        } else {
          message.error("There is no addon to remove.");
        }
      } else {
        message.error("Please enable write mode.");
      }
    }
  };

  let addonCount = data?.value?.length || 0;
  let addonDisplay;
  if (addonCount === 0) {
    addonDisplay = <span className="addons-count-zero">{addonCount}</span>;
  } else {
    addonDisplay = <span className="addons-count-number">{addonCount}</span>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {addonDisplay}
      <Button
        size="small"
        shape="circle"
        className="rowModifierAdd"
        onClick={addAddon}
        // disabled={!this.state?.isWritable}
      >
        <AddIcon />
      </Button>
      {/* <Button
        size="small"
        shape="circle"
        className="rowModifierRemove"
        onClick={removeLastAddon}
        // disabled={!this.state?.isWritable}
      >
        <RemoveCircleOutlined />
      </Button>{" "} */}
    </div>
  );
};

export default SidewalkShedAddonsRenderer;
