import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useSyncExternalStore,
} from "react";
import { wsUserSessions } from "../AppData";
import { useSelector } from "react-redux";
import { receiveSessionNotification } from "../components/Header/utils/devUsers";
import { apiRoutes, fetchData } from "../components/SidebarPages/Fleet/utils";
import dayjs from "dayjs";

// let globalSocket = new WebSocket(wsUserSessions.locale);
let globalSocket = new WebSocket(wsUserSessions.online);
let online = true;

async function sleep(ms = 1500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

let listeners = [];
let onlineListeners = [];

const socketStore = {
  onSocketStatusChange() {
    for (const listener of listeners) {
      listener();
    }
  },
  subscribe(listener) {
    listeners = [...listeners, listener];

    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return globalSocket;
  },
};

const onlineStore = {
  onOnlineChange() {
    for (const listener of onlineListeners) {
      listener();
    }
  },
  subscribe(listener) {
    onlineListeners = [...onlineListeners, listener];

    return () => {
      onlineListeners = onlineListeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return online;
  },
};

export function useGlobalSessionSocket({
  retryTimeout = 2000,
  maxRetries = 900,
  sessionTimerFn = {},
  isSessionIdle = { idle: false, socket: true },
}) {
  const {
    start = () => {},
    pause = () => {},
    resume = () => {},
    getRemainingTime = () => {},
    getLastActiveTime = () => {},
    isIdle = () => {},
  } = sessionTimerFn || {};
  const [retries, setRetries] = useState(0);
  const [initMessageSent, setInitMessageSent] = useState(false);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const sessionId = JSON.parse(window.localStorage.getItem("sessionId"));

  const wasIdleRef = useRef(isSessionIdle.idle);

  const filteredCognitos =
    userConfiguration?.allUsers?.Items?.filter((el) =>
      receiveSessionNotification?.includes(el?.nameOfUser)
    )?.map((el) => el?.cognitoUserId) || [];

  const retryHandler = useCallback(async () => {
    if (
      retries >= maxRetries ||
      retries === Number.MAX_SAFE_INTEGER ||
      !!isSessionIdle.idle
    ) {
      return;
    }

    online = false;
    onlineStore.onOnlineChange();

    await sleep(retryTimeout);
    // let newSocket = new WebSocket(wsUserSessions.locale);
    let newSocket = new WebSocket(wsUserSessions.online);

    setRetries(retries + 1);
    let idleOffline = false;
    if (!isSessionIdle.idle && wasIdleRef.current && !isSessionIdle.socket) {
      wasIdleRef.current = isSessionIdle.idle;
      idleOffline = true;
    }

    newSocket.onopen = function () {
      online = true;
      onlineStore.onOnlineChange();

      setRetries(0);

      try {
        newSocket.send(
          JSON.stringify({
            request: "create-session",
            body: {
              userId: userConfiguration?.identityId,
              sessionId,
              config: process.env.NODE_ENV === "production" ? "prod" : "dev",
              newSessionBody: userConfiguration?.activeSessions?.find(
                (session) => session?.sessionId === sessionId
              ),
              usersWithAccess: filteredCognitos,
              userDetails: {
                nameOfUser: userConfiguration?.nameOfUser,
                userDepartment: userConfiguration?.departmentName,
                userRole: userConfiguration?.groupName,
                cognitoUserId: userConfiguration?.cognitoUserId,
              },
              retry: retries + 1,
              isIdle: idleOffline
                ? {
                    idle: true,
                    time: dayjs(getLastActiveTime()).valueOf(),
                  }
                : false,
            },
          })
        );
      } catch (err) {
        console.error("Error recreating session: ", err?.message);
      }
      socketStore.onSocketStatusChange();
    };
    globalSocket = newSocket;
  }, [
    retryTimeout,
    maxRetries,
    retries,
    userConfiguration,
    sessionId,
    isSessionIdle,
  ]);

  useEffect(() => {
    if (!isSessionIdle.idle && wasIdleRef.current && !isSessionIdle.socket) {
      retryHandler();
    } else {
      wasIdleRef.current = isSessionIdle.idle;
    }
  }, [isSessionIdle]);

  useEffect(() => {
    if (!userConfiguration || !sessionId || initMessageSent) {
      return;
    }
    const newSessionBody =
      userConfiguration?.activeSessions?.find(
        (session) => session?.sessionId === sessionId
      ) || null;
    if (!newSessionBody) {
      return;
    }

    const whitelist = userConfiguration?.ipAddressWhitelist?.list;
    const isIpAddressEnabled =
      whitelist && whitelist.length
        ? whitelist.every((option) => option.isEnabled === true)
        : null;

    function sendSocketMessage() {
      try {
        globalSocket.send(
          JSON.stringify({
            request: "create-session",
            body: {
              userId: userConfiguration?.identityId,
              sessionId,
              config: process.env.NODE_ENV === "production" ? "prod" : "dev",
              newSessionBody: userConfiguration?.activeSessions?.find(
                (session) => session?.sessionId === sessionId
              ),
              usersWithAccess: filteredCognitos,
              userDetails: {
                nameOfUser: userConfiguration?.nameOfUser,
                userDepartment: userConfiguration?.departmentName,
                userRole: userConfiguration?.groupName,
                cognitoUserId: userConfiguration?.cognitoUserId,
              },
            },
          })
        );
        setInitMessageSent(true);
      } catch (err) {
        console.error("Could not send create session message: ", err?.message);
      }
    }
    if (isIpAddressEnabled && whitelist && whitelist?.length > 0) {
      fetchData(apiRoutes.getIp).then((res) => {
        const currIp = res?.split(",")[0];
        const isAllowedToCheck = userConfiguration?.ipAddressWhitelist?.find(
          ({ ipv4 }) => ipv4 === currIp
        );

        const checkDeviceAllowance = isAllowedToCheck?.devices?.some(
          ({ deviceLabel }) => deviceLabel === currentDevice
        );

        if (!checkDeviceAllowance) {
          return;
        } else {
          sendSocketMessage();
        }
      });
    } else {
      sendSocketMessage();
    }
  }, [userConfiguration, sessionId, initMessageSent]);

  useEffect(() => {
    globalSocket.onclose = retryHandler;
  }, [retryHandler]);

  return globalSocket;
}

export function useSessionSocket() {
  const socket = useSyncExternalStore(
    socketStore.subscribe,
    socketStore.getSnapshot
  );
  const online = useSyncExternalStore(
    onlineStore.subscribe,
    onlineStore.getSnapshot
  );

  return { socket, online };
}
