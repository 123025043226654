import { gsiQueryTable, lazyFetch } from "../../../../../../../../utils";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

/**
 * This property filters the result of getCostAnalytics function
 * @typedef filter
 * @property {any} value - the filter value
 * @property {string} key - specified key that will filter the result
 * @property {string} type - type of the column that will filter the result
 *
 * Payroll object
 * @typedef payroll
 * @property {string} degId
 * @property {number} toDate
 * @property {number} fromDate
 * @property {string} degStatus
 */

/**
 *  Get cost analytics in a specified date range.
 * @param {{company: string, filters:filter[],  listOfPayrolls: payroll[], dateRange: number[] | Date[]}} param
 */
async function getCostAnalytics({
  company,
  filters,
  dateRange,
  listOfPayrolls = [],
}) {
  let entries = [];
  try {
    const [dateStart, dateEnd] = dateRange.map((el, i) =>
      i ? dayjsNY(el).endOf("D").valueOf() : dayjsNY(el).startOf("D").valueOf()
    );

    // Get only Completed payrolls
    let completedPayrolls = [];

    if (listOfPayrolls?.length) {
      completedPayrolls = listOfPayrolls.filter(
        (el) => el?.degStatus === "Completed"
      );
    } else {
      completedPayrolls = await lazyFetch({
        tableName: "deg",
        filterKey: "degStatus",
        filterValue: "Completed",
        listOfKeys: ["degId", "fromDate", "toDate", "degStatus", "companyName"],
      });
    }

    // Filter payrolls by date range
    const payrolls = completedPayrolls.filter((el) => {
      const toDate = dayjsNY(el?.toDate).startOf("D").valueOf();
      const fromDate = dayjsNY(el?.fromDate).startOf("D").valueOf();

      const isBetweenDateRange = dateStart >= fromDate && dateEnd <= toDate;

      const isStartBetweenDateRange =
        dateStart <= fromDate && fromDate <= dateEnd;

      const isEndBetweenDateRange = dateStart <= toDate && toDate <= dateEnd;

      let filterCompany = true;

      if (!!company && !!el?.companyName) {
        filterCompany = el?.companyName === company;
      }
      const timeCondition =
        isBetweenDateRange || isEndBetweenDateRange || isStartBetweenDateRange;

      return timeCondition && filterCompany;
    });

    for (let i = 0; i < payrolls.length; i++) {
      const payroll = payrolls[i];
      try {
        let result = await gsiQueryTable({
          filterKey: "degId",
          tableName: "degEntries",
          indexName: "degId-index",
          filterValue: payroll?.degId,
        });

        if (result?.length) {
          result = result.filter((el) => {
            let pass = true;
            if (company) {
              pass = el?.company === company || el?.companyName === company;
            }
            if (filters?.length) {
              filters.forEach((filter) => {
                if (pass) {
                  pass = el?.[filter?.key] === filter?.value;
                }
              });
            }
            return pass;
          });
        }

        if (result?.length) {
          entries = entries.concat(result || []);
        }
      } catch (fetchError) {
        continue;
      }
    }

    return entries;
  } catch (error) {
    console.log(
      "Error getting costAnalytics: ",
      JSON.stringify(error, null, 4)
    );
    return entries;
  }
}

export default getCostAnalytics;
