import { RedWarningModal } from "../../../../../commonComponents";
import { useState } from "react";
import { InputComponent } from "../../../../Fleet/components";
import { useSelector } from "react-redux";
import "./SaveReportModal.scss";
import { message } from "antd";
import { saveReportHandler } from "../helpers";

function SaveReportModal({
  visible,
  onCancel,
  newReport,
  oldReport,
  exportsSettings,
  setReport,
  selectedCategory,
  setReportConfiguration,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [modalStep, setModalStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [details, setDetails] = useState({});
  function onFinish() {
    setModalStep(1);
    setSelectedOption(null);
    setSaveDisabled(false);
    setDetails({});
    onCancel();
  }
  return (
    <RedWarningModal
      {...{
        visible: visible,
        onCancel: onFinish,
        titleText: "Save Report",
        modalClassName: `saveReportModal ${
          isDarkMode ? "saveReportModalDark" : ""
        }`,
        confirmModal: true,
        disableOnConfirm: false,
        footerProps: {
          onConfirm: async () => {
            if (selectedOption === "new" && modalStep === 1) {
              setModalStep(2);
              return;
            } else if (selectedOption === "new" && modalStep === 2) {
              if (!details?.name.trim()) {
                message.error({
                  content: "Please enter a name for the report",
                  duration: 3,
                  key: "reportNameError",
                });
                return;
              } else {
                setSaveDisabled(true);
                await saveReportHandler({
                  newReport,
                  oldReport,
                  update: false,
                  exportsSettings,
                  userConfiguration,
                  setReport,
                  newDetails: details,
                  selectedCategory,
                  setReportConfiguration,
                })
                  .then(() => {
                    onFinish();
                  })
                  .catch((error) => {
                    console.error("Error saving report", error);
                  });
              }
            } else if (selectedOption === "update") {
              setSaveDisabled(true);
              await saveReportHandler({
                newReport,
                oldReport,
                update: true,
                exportsSettings,
                userConfiguration,
                setReport,
                selectedCategory,
                setReportConfiguration,
              })
                .then(() => {
                  onFinish();
                })
                .catch((error) => {
                  console.error("Error updating report", error);
                });
            }
          },
          saveDisabled: !selectedOption || saveDisabled,
          saveText:
            selectedOption === "new" && modalStep === 1
              ? "Continue"
              : "Confirm",
          saveClassName:
            selectedOption === "new" && modalStep === 1
              ? "mondayButtonBlue"
              : "mondayButtonGreen",
        },
      }}
    >
      {modalStep === 1 ? (
        <div className="select-save">
          <div
            className={
              "save-option" + (selectedOption === "update" ? " selected" : "")
            }
            onClick={() => {
              setSelectedOption("update");
            }}
          >
            Update Existing
          </div>
          <div
            className={
              "save-option" + (selectedOption === "new" ? " selected" : "")
            }
            onClick={() => {
              setSelectedOption("new");
            }}
          >
            Save As New
          </div>
        </div>
      ) : (
        <div>
          <InputComponent
            {...{
              label: "Report Name",
              onChange: (e) => setDetails({ ...details, name: e.target.value }),
              placeholder: "Enter Report Name",
            }}
          />
          <InputComponent
            {...{
              label: "Report Description",
              onChange: (e) =>
                setDetails({ ...details, description: e.target.value }),
              placeholder: "Enter Report Description",
              type: "textarea",
            }}
          />
        </div>
      )}
    </RedWarningModal>
  );
}

export default SaveReportModal;
